import { environment } from '../../environments/environment';

export class ImageUtils {
  static getProductImageUrl(product: any): string {
    if (!product.attributes?.imageSets?.length) {
      return environment.defaultImg;
    }
    return product.attributes.imageSets[0].images.find(smallImage => smallImage.externalUrlSmall).externalUrlSmall;
  }
}
