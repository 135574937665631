import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerFacade } from '../../facades/customer.facade';
import { Subscription } from 'rxjs';
import { AuthorizationFacade } from '../../facades/authorization.facade';

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss'],
})
export class AccessControlComponent implements OnInit, OnDestroy {
  isGuest: boolean;
  isViewer: boolean;
  isAdmin: boolean;
  isApprover: boolean;
  isLoggedIn: boolean;
  accessControlDataSubscription$: Subscription;
  isAuthenticatedSubscription$: Subscription;
  isViewerSubscription$: Subscription;
  isAdminSubscription$: Subscription;
  isApproverSubscription$: Subscription;
  companyRolesLoading: boolean;
  @Input() description = false;
  @Input() notForViewer = false;

  constructor(
    private customerFacade: CustomerFacade,
    private authorizationFacade: AuthorizationFacade,
  ) {
  }

  ngOnInit(): void {
    this.accessControlDataSubscription$ = this.selectIsGuest();
    this.isViewerSubscription$ = this.selectIsViewer();
    this.isAdminSubscription$ = this.selectIsAdmin();
    this.isApproverSubscription$ = this.selectIsApprover();
    this.isAuthenticatedSubscription$ = this.selectIsAuthenticated();
  }

  ngOnDestroy(): void {
    this.accessControlDataSubscription$.unsubscribe();
    this.isViewerSubscription$.unsubscribe();
    this.isAdminSubscription$.unsubscribe();
    this.isAuthenticatedSubscription$.unsubscribe();
    this.isApproverSubscription$.unsubscribe();
  }

  selectIsGuest(): Subscription {
    return this.customerFacade.selectAccessControlData().subscribe(state => {
      this.companyRolesLoading = state.companyRolesLoading;
      this.isGuest = state.businessUnitActive && !state.businessUnitBlockUsers ? state.isGuest : true;
    });
  }

  selectIsViewer(): Subscription {
    return this.customerFacade.selectAccessControlData().subscribe(state => {
      this.companyRolesLoading = state.companyRolesLoading;
      this.isViewer = state.isViewer;
    });
  }

  selectIsAdmin(): Subscription {
    return this.customerFacade.selectAccessControlData().subscribe(state => {
      this.companyRolesLoading = state.companyRolesLoading;
      this.isAdmin = state.isAdmin;
    });
  }

  selectIsApprover(): Subscription {
    return this.customerFacade.selectAccessControlData().subscribe(state => {
      this.companyRolesLoading = state.companyRolesLoading;
      this.isApprover = state.isApprover;
    });
  }

  selectIsAuthenticated(): Subscription {
    return this.authorizationFacade.selectIsUserLoggedIn().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }
}
