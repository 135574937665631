<div class="row">
  <div class="col-xl-10">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <h1>
          {{ 'error.title' | translate }}
        </h1>
        <div class="home-page-link">
          <a [routerLink]="['/']" class="button button--link">
            <span class="icon-arrow-right-small"></span>
            {{ 'error-404.home-page-btn' | translate }}
          </a>
        </div>
      </div>
      <div class="col-sm-5">
        <img src="assets/img/error.png"
             alt="Error."
             class="error-img"
        />
      </div>
    </div>
  </div>
</div>

