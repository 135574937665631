import { EGlueResource } from '../configurations/common';
import { IAddress } from './common.models';

export interface IBusinessRoleAddressResourceAttributes {
  name: string;
  address1: string;
  address2: string;
  address3: string;
  zipCode: string;
  city: string;
  country: string;
  iso2Code: string;
  state: string;
  sapId: string;
}

export interface IBusinessRoleAddressResource<T> {
  id: string;
  type: T;
  attributes: IBusinessRoleAddressResourceAttributes;
}

export interface ISoldToAddressResource extends IBusinessRoleAddressResource<EGlueResource.SOLD_TO_ADDRESSES> {
}

export interface IShipToAddressResource extends IBusinessRoleAddressResource<EGlueResource.SHIP_TO_ADDRESSES> {
}

export interface IBillToAddressResource extends IBusinessRoleAddressResource<EGlueResource.BILL_TO_ADDRESSES> {
}

export interface IPayerAddressResource extends IBusinessRoleAddressResource<EGlueResource.PAYER_ADDRESSES> {
}

export type SoldToIncludedResource = IShipToAddressResource|IBillToAddressResource|IPayerAddressResource;

export interface ISoldToAddressResponse {
  data: ISoldToAddressResource;
  included?: SoldToIncludedResource[];
}

export interface ISoldToAddressesResponse {
  data: ISoldToAddressResource[];
  included?: SoldToIncludedResource[];
}

export interface ISelectSoldTo {
  name: string;
  id: string;
  value: string;
  correct?: boolean;
}


export const ISoldToToISelectSoldTo = (soldTo: IAddress, message: string): ISelectSoldTo => {
  const name = soldTo.name ?
    `${soldTo.name} (${getAddressForSoldToAccount(soldTo)}, ${soldTo.city}, ${soldTo.state ? (soldTo.state) + ',' : ''} ${soldTo.zipCode})` :
    message;
  return {
    name: `${soldTo.sapId} - ${name}`,
    id: soldTo.id,
    value: soldTo.sapId,
    correct: !!soldTo.name,
  };
};

export const getAddressForSoldToAccount =(attributes) :string=>{
  return !!attributes?.address1
    ? attributes?.address1
    : !!attributes?.address2
      ? attributes?.address2
      : attributes?.address3
}
