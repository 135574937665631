<div class="row border-black cart-item-title-bar">
  <div class="border-green cart-item-quantity-title" [ngClass]="displayPrices ? 'col-10' : 'col-12'">
    <label for="quantity">{{ 'shop-cart.quantity' | translate }}</label>
  </div>
  <div class="col-2 border-green cart-item-price-title" *ngIf="displayPrices">
    {{ 'shop-cart.price' | translate }}
  </div>
</div>
<ng-container *ngIf="items">
  <div *ngFor="let item of items">
    <div class="row border-orange cart-item-container">
      <div class="col-12 col-md-4 border-green cart-item-image-container">
        <img [src]="getItemPicture(item)" [alt]="item.attributes.name" class="cart-item-image"
             [attr.data-test]="'img-productImage-' + item.attributes.name">
      </div>
      <div class="col-12 col-md-8 cart-item__body">
        <div class="row border-hotpink">
          <div
              class="border-blue cart-item-item-title"
              [ngClass]="displayPrices ? 'col-10 col-md-6' : 'col-10 col-md-10'">
            <div class="row border-black">
              <div class="col-12 border-green"
                   [attr.data-test]="'desc-cartItemName-' + item.attributes.name">
                {{ item.attributes.name }}
              </div>
            </div>
          </div>
          <div class="border-blue" [ngClass]="displayPrices ? 'col-4 col-md-2' : 'col-2 col-md-2'">
            <input
                #quantity
                type="number"
                id="quantity"
                name="quantity"
                [value]="item.attributes.quantity"
                min="1"
                step="1"
                class="col-12 cart-item-quantity"
                required
                (change)="updateCartItemLoadCartItems(item.attributes.quantity, quantity.value, item)"
                [attr.data-test]="'input-cartItemQuantity-'+item.attributes.name"
            />
          </div>
          <div class="col-8 col-md-4 border-blue cart-item-price" *ngIf="displayPrices && !!item.attributes.calculations.unitPrice"
               [attr.data-test]="'desc-cartItemPrice-'+item.attributes.name">
            {{ item.attributes.calculations.unitPrice | currency: currency }}
          </div>
        </div>
        <div class="row border-black">
          <div class="col-12 col-lg-6 border-blue" *ngIf="!rfqActive">
            <div class="row border-black">
              <div class="col-12 border-green cart-item-stock-info" *ngIf="item.attributes.deliveryAvailability">
                {{ item.attributes.deliveryAvailability }}
              </div>
              <div class="col-12 border-green cart-item-arrival-info" *ngIf="item.attributes.deliveryTime">
                {{ 'shop-cart.estimatedArrivalLabel' | translate}}{{ item.attributes.deliveryTime }}
              </div>
            </div>
          </div>
        </div>
        <div class="row cart-item__bottom">
          <div class="wishlist__btn">
            <app-add-to-wishlist
                [wishlists]="wishlists"
                [sku]="item.id"
                [productName]="item.attributes.name"
                [productKeywords]="item.attributes.attributes.mcm_keywords"
                [addNewModalActive]="addNewModalActive"
                [textNextToIcon]="true"
                (createNew)="addNewWishlist()">
            </app-add-to-wishlist>
          </div>
          <div>
            <button class="remove-item__btn" (click)="removeFromCart(item)"
                    [attr.data-test]="'button-removeItem-'+item.attributes.name">
              <span class="remove-item__desc">{{ 'shop-cart.removeItem' | translate }}</span>
              <span class="icon-close"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
