import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EProfileTabs, EStoreFeatures, EUserRoles } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { ICustomer, ICustomerExtended } from '../../models/customer.models';
import { IconType } from '../../models/settings.model';
import { CanComponentDeactivate } from '../../shared/interfaces/can-component-deactivate';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';

@Component({
  selector: 'app-page-my-profile',
  templateUrl: './page-my-profile.component.html',
  styleUrls: ['./page-my-profile.component.scss'],
})
export class PageMyProfileComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  customerData: ICustomer & ICustomerExtended;
  customerId: string;
  companyRoles: EUserRoles[];
  businessUnitName: string;
  currentTab: string = EProfileTabs.PERSONAL;
  profileTabs = EProfileTabs;
  iconType = IconType;
  canClose: boolean = true;
  isLoading: boolean = true;
  showModal: boolean = false;
  shipToAddressesLoaded: boolean = false;
  pageReadyEventFired: boolean = false;

  subject$: Subject<boolean> = new Subject<boolean>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
  }

  @HostListener('window:beforeunload')
  OnBeforeUnload(): boolean {
    return this.canClose;
  }

  ngOnInit(): void {
    if (!this.configurationFacade.isFeatureAvailable(EStoreFeatures.MY_PROFILE)) {
      this.router.navigate([this.configurationFacade.getCurrentParam()]);
    }

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.currentTab = params.tab
          ? params.tab
          : this.currentTab;
      });

    this.selectCustomerData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.canClose) {
      return true;
    }
    this.showModal = true;

    return this.subject$.asObservable();
  }

  discardChangesAndLeavePage(): void {
    this.showModal = false;
    this.subject$.next(true);
  }

  changeTab(tab: string): void {
    this.currentTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab: tab},
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onShipToAddressesLoaded(event: boolean): void {
    this.shipToAddressesLoaded = event;
    this.trackPageReady()
  }

  private selectCustomerData(): void {
    this.customerFacade.selectCustomerData().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: customerData => {
        if (customerData) {
          this.customerData = customerData.customerData;
          this.customerId = customerData.customerId;
          this.companyRoles = customerData.companyRoles;
          this.businessUnitName = customerData.businessUnitName;
          this.isLoading = false;
          this.trackPageReady();
        }
      }
    });
  }

  private trackPageReady(): void {
    if (!this.pageReadyEventFired && !this.isLoading && this.shipToAddressesLoaded) {
      this.analyticsService.trackPageReady('My Profile', PageTypes.MY_PROFILE);
      // Necessary as switching tabs causes customer data to be reloaded and we want the event
      // to be triggered only once in the component lifecycle
      this.pageReadyEventFired = true;
    }
  }
}
