import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RouteCustomData } from '../models/route-custom-data';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public customData = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  getCustomRouteData(): void {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).pipe(
      map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data) {
              return child.snapshot.data;
            } else {
              return null;
            }
          }
          return null;
        }
      )
    ).subscribe((data: RouteCustomData) => this.customData = data);
  }
}
