import { Directive, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomerFacade } from '../../facades/customer.facade';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { EFeatureToggles, FEATURE_SETTING_WALK_ME_SCRIPT } from '../../configurations/common';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Directive({
  selector: '[appWalkMe]',
})
export class WalkMeDirective implements OnInit, OnDestroy {

  script: any;
  WINDOW_LANGUAGE_PARAM = 'language';
  WINDOW_COUNTRY_PARAM = 'country';
  WINDOW_WALK_ME_LANGUAGE_PARAM = 'walkMeLanguage';
  private unsubscribe$ = new Subject<void>();
  private callWalkMeSnipped = false;

  constructor(
    private translateService: TranslateService,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document) {
  }

  ngOnInit() {
    combineLatest([
      this.customerFacade.selectCustomerData(),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.WALK_ME),
      this.translateService.get(FEATURE_SETTING_WALK_ME_SCRIPT),
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([userData, walkMeEnabled, walkMeScript]) => {
        if (userData && userData.customerId && !this.callWalkMeSnipped && walkMeEnabled) {
          window['gid'] = userData.customerId;
          this.showWalkMe(walkMeScript);
          this.callWalkMeSnipped = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.renderer2.removeChild(this.document.body, this.script);
    this.callWalkMeSnipped = false;
  }

  showWalkMe(walkMe: string) {
    const localeSplit = LocalStorageUtils.getKeyValue('lang').split('-');
    const languageCode = localeSplit[0];
    const languageCountryCode = localeSplit[1];
    this.setLanguageAndCountry(window, languageCode, languageCountryCode);
    this.addWalkMe(walkMe);
  }

  addWalkMe(walkMeIdentifier: string) {
    this.script = this.renderer2.createElement('script');
    this.script.type = `text/javascript`;
    this.script.text = this.getWalkMeScript(walkMeIdentifier);
    this.renderer2.appendChild(this.document.body, this.script);
  }

  getWalkMeScript(walkMeIdentifier: string) {
    let WALK_ME_SCRIPT = '(function() {var walkme = document.createElement(\'script\'); ';
    WALK_ME_SCRIPT += this.getWalkMeLanguageConfigurationScript();
    WALK_ME_SCRIPT += 'walkme.type = \'text/javascript\'; walkme.async = true; ';
    WALK_ME_SCRIPT += 'walkme.src = \'' + walkMeIdentifier + '\'; ';
    WALK_ME_SCRIPT += 'var s = document.getElementsByTagName(\'script\')[0]; ';
    WALK_ME_SCRIPT += 's.parentNode.insertBefore(walkme, s); ';
    WALK_ME_SCRIPT += 'window._walkmeConfig = {smartLoad:true}; })();';
    return WALK_ME_SCRIPT;
  }

  /**
   * Language setting
   */
  getWalkMeLanguageConfigurationScript() {
    let WALK_ME_GET_LANGUAGE = 'window.walkme_get_language = function (){ ';
    WALK_ME_GET_LANGUAGE += 'if (window.walkMeLanguage) { return window.walkMeLanguage }';
    WALK_ME_GET_LANGUAGE += ' else {  return \'\' }';
    WALK_ME_GET_LANGUAGE += '}; ';
    return WALK_ME_GET_LANGUAGE;
  }

  /**
   * Configures WalkMe widget (window.country and window.language)
   * @param window
   * @param language
   * @param country
   */
  setLanguageAndCountry(window: Window, language: string, country: string) {
    window[this.WINDOW_LANGUAGE_PARAM] = language;
    window[this.WINDOW_COUNTRY_PARAM] = country;
    if (language && country) {
      this.setWalkMeLanguage(language);
    }
  }

  private setWalkMeLanguage(walkMeLanguage: string) {
    if (walkMeLanguage.toUpperCase() === 'EN') {
      delete window[this.WINDOW_WALK_ME_LANGUAGE_PARAM];
    } else {
      window[this.WINDOW_WALK_ME_LANGUAGE_PARAM] = walkMeLanguage;
    }
  }
}
