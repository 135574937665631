import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, format: string, delimiter: string = ' ', prefix: string = ''): string {
    const formatSegments = format.split('|').map(f => parseInt(f, 10));
    let formattedPhone = '';
    for (let i = 0; i < formatSegments.length; i++) {
      const position = i === 0 ? 0 : formatSegments.slice(0, i).reduce((acc, curr) => acc + curr);
      formattedPhone += `${value.slice(position, position + formatSegments[i])}${i < formatSegments.length - 1 ? delimiter : ''}`;
    }
    if (prefix) {
      return `${prefix} ${formattedPhone}`;
    }
    return formattedPhone;
  }

}
