<ng-container>
  <app-modal
      [open]="showModalConfirmRemove"
      title="{{ 'order-approve.remove-modal-title' | translate }}"
      (crossClick)="crossClick()">
    <ng-container ngProjectAs="[slot][body]">
      <ng-container *ngIf="!deleteLastItemAndDeclineOrderFlag">
        <h5 class="modal-h5-title">
          {{ 'order-approve.remove-modal-content' | translate:{name: clickedItemDataName} }} </h5>
      </ng-container>
      <ng-container *ngIf="deleteLastItemAndDeclineOrderFlag">
        <h5 class="modal-h5-title">
          {{ 'order-approve.last-item-decline-order-modal-content' | translate:{name: clickedItemDataName} }} </h5>
      </ng-container>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button type="button" (click)="crossClick()" class="button button" data-dismiss="modal-dialog"
              [disabled]="setButtonDisabledAttribute">
        {{ 'order-approve.keep-item' | translate}}
      </button>
      <button *ngIf="!deleteLastItemAndDeclineOrderFlag" type="button" (click)="clickRemoveItemFromCart()"
              class="button button--primary" data-dismiss="modal-dialog" [disabled]="setButtonDisabledAttribute">
        {{ 'order-approve.remove-item' | translate}}
      </button>
      <button *ngIf="deleteLastItemAndDeclineOrderFlag" type="button" (click)="clickDeclineOrder()"
              class="button button--primary"
              data-dismiss="modal-dialog"
              [disabled]="setButtonDisabledAttribute">
        {{ 'order-approve.decline-order-button' | translate }}
      </button>
    </ng-container>
  </app-modal>
</ng-container>
