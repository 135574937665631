import { Injectable } from '@angular/core';
import createAuth0Client, { User } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, Observable, throwError, of } from 'rxjs';
import { catchError, concatMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static instance: AuthService;
  AUTH0_COOKIE = `auth0.${environment.auth0Config.client_id}.is.authenticated`;
  requestedScopes = 'openid profile email';
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth0Config.domain,
      client_id: environment.auth0Config.client_id,
      redirect_uri: `${window.location.origin}`,
      useRefreshTokens: environment.auth0Config.useRefreshTokens,
      audience: environment.auth0Config.audience,
      responseType: 'token id_token',
      scope: this.requestedScopes,
      cacheLocation: 'localstorage',
    }),
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err)),
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );

  getTokenSilently$(options?): Promise<any> {
    return of(null).toPromise();
  }

  constructor(private i18nService: I18nService) {
  }

  getUser$(): Observable<User> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
    );
  }

  login(redirectPath: string = '/', queryParameters = {}): void {
    LocalStorageUtils.clearKey(`lang`);
    LocalStorageUtils.clearKey(`storeId`);
    LocalStorageUtils.clearKey(`previousPage`);

    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: {target: redirectPath, queryParams: queryParameters},
      }).then();
    });
  }

  logout(skipRedirect: boolean): void {
    if (skipRedirect) {
      LocalStorageUtils.clearKey(`lang`);
      LocalStorageUtils.clearKey(`storeId`);
      LocalStorageUtils.clearKey(`previousPage`);
    }

    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: environment.auth0Config.client_id,
        returnTo: `${window.location.origin}${skipRedirect ? '' : '?logoutRedirect=1'}`
      });
    });
  }

  getAuthenticatedCookie(): boolean {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${this.AUTH0_COOKIE}=`) && item.includes(`${this.AUTH0_COOKIE}=true`));
  }

}
