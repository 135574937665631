import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-configuration-summary-list',
  templateUrl: './configuration-summary-list.component.html',
  styleUrls: ['./configuration-summary-list.component.scss'],
})
export class ConfigurationSummaryListComponent {
  openedTabsIndex = 0;
  tabsOpened = [];

  @Input() products: Array<any>;

  menageTabs(openedTabsIndex: number): void {
    return openedTabsIndex === this.products.length ? this.closeTabs() : this.openTabs();
  }

  toggleTab(name: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === name);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
      this.openedTabsIndex = this.tabsOpened.length;
    } else {
      this.tabsOpened.push(name);
      this.openedTabsIndex = this.tabsOpened.length;
    }
  }

  private openTabs(): void {
    this.tabsOpened = this.products.map(item => item.name);
    this.openedTabsIndex = this.tabsOpened.length;
  }

  private closeTabs(): void {
    this.tabsOpened.splice(0, this.tabsOpened.length);
    this.openedTabsIndex = this.tabsOpened.length;
  }
}
