import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as ValidationPatterns from '../../../configurations/validations';
import { WishlistFacade } from '../../../facades/wishlist.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-wishlist-edit',
  templateUrl: './wishlist-edit.component.html',
  styleUrls: ['./wishlist-edit.component.scss'],
})
export class WishlistEditComponent implements OnInit, OnChanges {
  form: UntypedFormGroup;
  maxNameLength = 20;
  @Input() showModal: boolean;
  @Input() name: string;
  @Input() id: string;
  @Output() closeModal = new EventEmitter<any>();
  @Output() edited: EventEmitter<any> = new EventEmitter<any>();
  @Output() inProgress: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private wishlistFacade: WishlistFacade,
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal?.currentValue) {
      this.initializeForm();
    }
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      name: [this.name, [Validators.required, Validators.maxLength(this.maxNameLength), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  resetForm(): void {
    this.closeModal.emit();
    this.form.reset();
  }

  formIsValid(): boolean {
    return this.form.status === 'VALID';
  }

  editWishlist(): void {
    this.inProgress.emit();
    this.wishlistFacade.changeWishlistName(this.id, this.form.value.name).then( result => {
      this.edited.emit(result);
      this.resetForm();
    }).catch(
      err => {
        const message = err?.error.errors?.[0]?.detail ? err.error.errors[0].detail : err.mesage;
        this.configFacade.setAlert({type: 'warning', message});
        this.edited.emit('error');
      }
    );
  }

  validateField(name: string): boolean {
    return this.form.get(name).touched && this.form.get(name).hasError('maxlength');
  }
}
