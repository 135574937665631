import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shouldDisplayPrice'
})
export class ShouldDisplayPricePipe implements PipeTransform {

  transform(value: number): boolean {
    return !!value;
  }
}
