import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { ISelectEvent } from '../../../models/common.models';
import { I18nService } from '../../../services';
import { notFoundValue, notInstallBaseRelated } from '../../../shared/ivk-selection-form/IvkSelectionFormConstants';
import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { AddressUtils } from '../../../utils/address.utils';
import { AppUtils } from '../../../utils/app.utils';
import { ArrayUtils } from '../../../utils/array.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { InstallBaseFacade } from '../../../facades/install-base.facade';
import { EUserRoles } from '../../../configurations/common';

@Component({
  selector: 'app-request-details-section',
  templateUrl: 'request-details-section.component.html',
  styleUrls: ['request-details-section.component.scss'],
})
export class RequestDetailsSectionComponent implements OnInit, OnDestroy {
  @Input() loggedUserRoles: EUserRoles[];
  @Input() cartItems;
  @Input() isCartEmpty;
  @Input() isRfqOnly;
  @Input() minimumOrderValue;
  @Input() currency;
  @Input() currentCart;

  @Output() formSubmitted = new EventEmitter<any>();

  customerAddresses: any;
  businessAddresses: any;
  ivkForm: UntypedFormGroup;
  deliveryForm: UntypedFormGroup;
  businessUnitLoading = false;
  businessAddressesLoading = false;
  systems = [];
  businessUnits = [] as Array<{name: string, value: string}>;
  addresses: Array<{name: string, value: any, addressFromSystem: boolean}> = [];
  formsAreValid = false;
  showModalAddressWrong = false;
  showModalAddress = false;
  showPriceDisclaimer = false;
  additionalItemLevelFields = [] as Array<{name: string, getValFunc: (item: any) => string, shouldDisplayFunc: (item: any) => boolean}>;
  installBaseLoading: boolean = false;

  private itemFormMap = new Map();
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private i18nService: I18nService,
              private catalogFacade: CatalogFacade,
              private customerFacade: CustomerFacade,
              private installBaseFacade: InstallBaseFacade,
              private customerCurrencyPipe: CustomerCurrencyPipe) {
  }

  ngOnInit(): void {
    this.getNotFoundLabels();
    this.beginCustomerAddressesAction();
    this.beginGetBusinessUnitsAction();

    this.initializeForm();

    this.selectInstallBaseProducts();
    this.selectBusinessUnitLoading();
    this.selectBusinessAddresses();
    this.selectBusinessUnits();
    this.selectCustomerAddresses();

    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;

    this.prepareAdditionalFields();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): void {
    this.deliveryForm = this.formBuilder.group({
      deliveryAddress: ['', [Validators.required]],
    });

    this.revalidateForms();
  }

  ivkFormChanged(form: UntypedFormGroup): void {
    this.ivkForm = form;

    this.revalidateForms();
  }

  selectInstallBaseLoading(): void {
    this.installBaseFacade.selectInstalledBaseProductDataLoading()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        this.installBaseLoading = loading;
      });
  }

  systemIdValueChanged(event: ISelectEvent): void {
    let addressData;
    if (event.additional?.companyBusinessUnitAddress) {
      const addressFromSystemIndex = this.addresses.findIndex(address => address.addressFromSystem);
      addressData = {
        name: AddressUtils.createAddressString(event.additional.companyBusinessUnitAddress),
        value: {
          ...event.additional.companyBusinessUnitAddress,
          idCompanyUnitAddress: null,
          idCustomerAddress: null,
        },
        addressFromSystem: true,
      };

      if (AddressUtils.isAddressValid(addressData?.value)) {
        if (addressFromSystemIndex === -1) {
          this.addresses.push(addressData);
        } else {
          this.addresses[addressFromSystemIndex] = addressData;
        }
      }
    } else {
      addressData = '';
    }
    if (AddressUtils.isAddressValid(addressData?.value)) {
      this.deliveryForm.patchValue({
        deliveryAddress: addressData,
      });
    }

    this.revalidateForms();
  }

  selectDeliveryAddress(event: ISelectEvent): void {
    this.deliveryForm.patchValue({
      deliveryAddress: event,
    });

    this.revalidateForms();
  }

  showModalNewAddress(): void {
    this.showModalAddress = true;
  }

  showModalWrongAddress(): void {
    this.showModalAddressWrong = true;
  }

  itemFormChanged(itemForm: any): void {
    this.itemFormMap.set(itemForm.itemId, itemForm.formGroup);

    this.revalidateForms();
  }

  itemFormHidden(itemId: any): void {
    this.itemFormMap.delete(itemId);

    this.revalidateForms();
  }

  revalidateForms(): void {
    this.formsAreValid = this.isFormValid(this.ivkForm) &&
      this.isFormValid(this.deliveryForm) &&
      this.areItemFormsValid();
  }

  proceedToNextStep(): void {
    const formData = {
      deliveryAddress: this.deliveryForm.value.deliveryAddress?.value,
      systemDetails: {
        serialNumber: this.ivkForm.value.serialNumber,
        siemensEquipmentId: this.ivkForm.value.siemensEquipmentId,
        materialNumber: this.ivkForm.value.materialNumber,
        namePtBr: this.ivkForm.value.namePtBr,
        nameEnUs: this.ivkForm.value.nameEnUs,
        company: this.ivkForm.value.company,
        companyBusinessUnit: this.ivkForm.value.companyBusinessUnit,
        companyBusinessUnitNumber: this.ivkForm.value.companyBusinessUnitNumber,
      },
      systemDetailsPerItem: [],
    };

    for (const [id, form] of this.itemFormMap) {
      formData.systemDetailsPerItem.push({
        itemId: id,
        systemDetails: {
          serialNumber: form.value.serialNumber,
          siemensEquipmentId: form.value.siemensEquipmentId,
          materialNumber: form.value.materialNumber,
          namePtBr: form.value.namePtBr,
          nameEnUs: form.value.nameEnUs,
          company: form.value.company,
          companyBusinessUnit: form.value.companyBusinessUnit,
          companyBusinessUnitNumber: form.value.companyBusinessUnitNumber,
        },
      });
    }

    this.formSubmitted.emit(formData);
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form?.status === 'VALID';
  }

  beginCustomerAddressesAction(): void {
    this.customerFacade.beginCustomerAddressesAction();
  }

  selectAddressesFromInstallBase(sortedSystems: any[]): any[] {
    return ArrayUtils.removeDuplicates(sortedSystems
      .map(installBase => ({...installBase?.attributes?.companyBusinessUnitAddress}))).filter((el) => {
      return el.address1 != null;
    });
  }

  getTotalPrice(): number {
    return CartUtils.getTotalPrice(this.isCartEmpty, this.currentCart);
  }

  getPageTitle(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'request-quote.page-title-rfq-only' : 'request-quote.page-title';
  }

  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.cartItems);
  }

  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, this.isCartEmpty, this.currentCart, this.cartItems);
  }

  private getNotFoundLabels(): void {
    this.i18nService.getTranslationByKey([
      'request-details.system-not-found',
      'request-details.unit-not-found',
      'request-details.item-not-install-base-related',
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(labels => {
      this.systems.unshift({value: notFoundValue, name: labels['request-details.system-not-found']});
      this.systems.unshift({
        value: notInstallBaseRelated,
        name: labels['request-details.item-not-install-base-related'],
      });
      this.businessUnits.unshift({value: notFoundValue, name: labels['request-details.unit-not-found']});
    });
  }

  private beginGetBusinessUnitsAction(): void {
    this.customerFacade.beginGetBusinessUnitsAction();
  }

  private selectInstallBaseProducts(): void {
    this.installBaseFacade.selectInstalledBaseSystemData()
      .pipe(takeUntil(this.unsubscribe$),
      ).subscribe({
      next: data => {
        if (data?.length) {
          const sortedSystems = ArrayUtils.sortByAttribute([...data], 'attributes.nameEnUs');
          this.systems = this.systems.concat(sortedSystems.map(installBase => {
            return {
              name: installBase.attributes.nameEnUs + (installBase.attributes.siemensEquipmentId ? ` (${installBase.attributes.siemensEquipmentId})` : ''),
              value: installBase.attributes.siemensEquipmentId,
              additional: {...installBase.attributes},
            };
          }));
          this.businessUnits = this.businessUnits.concat(sortedSystems.reduce((acc, installBase) => {
            if (installBase?.attributes?.companyBusinessUnit && installBase.attributes.companyBusinessUnitNumber) {
              acc.push({
                name: installBase.attributes.companyBusinessUnit,
                value: installBase.attributes.companyBusinessUnitNumber,
                additional: {
                  institutionName: installBase.attributes.company,
                },
              });
            }
            return acc;
          }, []));
          AddressUtils.appendAddresses(this.addresses, this.selectAddressesFromInstallBase(sortedSystems), 'business', true);
          this.prepareBusinessUnits();
        }
      }, error: () => {
      },
    });
  }

  private selectBusinessUnitLoading(): void {
    this.customerFacade.selectBusinessUnitLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoading => {
      this.businessUnitLoading = isLoading;
    });
  }

  selectBusinessUnits(): void {
    this.customerFacade.selectBusinessUnits().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(units => {
      if (units) {
        this.businessUnits = this.businessUnits.concat(units.map(unit => {
          return {
            name: unit.name,
            value: unit.businessUnitNumber,
            additional: {
              institutionName: unit.institutionName,
            },
          };
        }));
        this.prepareBusinessUnits();
      }
    });
  }

  private selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: addresses => {
          if (addresses) {
            this.businessAddresses = addresses;
            AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
            this.businessAddressesLoading = false;
          }
        },
        error: () => {
          this.businessAddressesLoading = false;
        },
      },
    );
  }

  private selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  private prepareBusinessUnits(): void {
    this.businessUnits = ArrayUtils.removeDuplicates(this.businessUnits);
    // Skip first unit as it is not found option
    const sortedUnits = ArrayUtils.sortByAttribute(this.businessUnits.slice(1, this.businessUnits.length), 'name');
    this.businessUnits = [this.businessUnits[0], ...sortedUnits];
  }

  private areItemFormsValid(): boolean {
    for (const form of this.itemFormMap.values()) {
      if (form.status === 'INVALID') {
        return false;
      }
    }

    return true;
  }

  private prepareAdditionalFields(): void {
    this.additionalItemLevelFields = [{
      name: 'shop-cart.quantity',
      getValFunc: (item) => item.attributes.quantity,
      shouldDisplayFunc: (_) => true,
    }];
    if (this.isRfqOnly && this.isSetPricesForAllItems()) {
      this.additionalItemLevelFields.push({
        name: 'shop-cart.item-price',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.unitPrice,
          this.currency,
        ),
        shouldDisplayFunc: (item) => !!item.attributes.calculations.unitPrice,
      });
    }
  }

}
