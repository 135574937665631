import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { StoreConfigurationActions } from '../actions';

export const storeConfigurationStoreName = 'storeConfiguration';

export interface StoreConfigurationState {
  error: any;
  isRfqOnly: boolean;
  minimumOrderValue: number;
}

export const initialState: StoreConfigurationState = {
  error: null,
  isRfqOnly: false,
  minimumOrderValue: 0,
};

const getStoreConfigurationFeatureState = createFeatureSelector<StoreConfigurationState>(storeConfigurationStoreName);

export const getIsRfqOnly = createSelector(
  getStoreConfigurationFeatureState,
  state => state.isRfqOnly
);

export const getMinimumOrderValue = createSelector(
  getStoreConfigurationFeatureState,
  state => state.minimumOrderValue
);

export const StoreConfigurationReducer = createReducer(
  initialState,

  on(StoreConfigurationActions.retrieveStoreConfigurationSuccess, (state, action): StoreConfigurationState => {
    return {
      ...state,
      isRfqOnly: action.storeConfiguration.data.attributes.isRfqOnly,
      minimumOrderValue: action.storeConfiguration.data.attributes.minimumOrderValue,
    };
  }),

  on(StoreConfigurationActions.retrieveStoreConfigurationFail, (state, action): StoreConfigurationState => {
    return {
      ...state,
      error: action.error
    };
  })
);
