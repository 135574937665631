import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlueConfigurationService } from './glue-configuration.service';
import { ISocialMediaFooter } from '../models/footers.models';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  baseUrl = this.glueConfiguration.getEndpointUrl() + '/navigations/';

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService
  ) { }

  getFooterNav(): Observable<any> {
    const url = this.baseUrl + 'FOOTER_NAVIGATION';
    return this.http.get(url);
  }

  getSocialMediaFooter(): Observable<ISocialMediaFooter> {
    const url = this.baseUrl + 'SOCIAL_MEDIA_FOOTER';
    return this.http.get<ISocialMediaFooter>(url);
  }
}
