<div [appLoadingAnimation]="!isLoaded" class="cms-page__content">
  <div class="container">
    <div class="row">
      <ng-container *ngIf="isLoaded">
        <div [innerHTML]="cmsPageData.attributes.title"></div>
        <div [innerHTML]="cmsPageData.attributes.content"></div>
      </ng-container>
    </div>
  </div>
</div>
