<div class="shop-categories">
  <h4 [attr.data-test]="'title-ShopByCategories'">{{ 'shop-categories.title' | translate }}</h4>
  <app-access-control [description]="true">
    <ng-container ngProjectAs="[slot][content]">
      <div [appLoadingAnimation]="shopCategoryLoading">
        <div class="row margin-top-1">
          <ng-container *ngFor="let option of shopCategoryTiles">
            <div class="card-grid col-6 col-md-4 col-lg-3" *ngIf="option.isActive">
              <a [attr.data-test]="'link-shopByCategory'" class="card card--linked"
                 [routerLink]="[(isBusinessPartnerRole &&
                                (option.title.includes('parts') || option.title.includes('contracts')))
                                ? '/equipment-selection' : option.url]"
                 [queryParams]="isBusinessPartnerRole ? null : option.queryParams">
                <div class="card__content">
                  <div class="card__image">
                    <img src="{{ option.imageUrl }}" alt="{{ option.title | translate }}">
                  </div>
                  <div class="card__body">
                    <h2 [attr.data-test]="'title-category'" class="card__title">
                      {{ option.title | translate }}
                    </h2>
                    <p [attr.data-test]="'desc-category'" class="card__desc">
                      {{ option.description | translate }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="no-access-container">
        <p *ngIf="shopCategoryLoading === false && (shopCategoryTiles.length === 0 && hasAnyTile)"
           class="no-access">
          {{ 'customer.no-permission' | translate }}
        </p>
      </div>
    </ng-container>
  </app-access-control>
</div>
