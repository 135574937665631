<div class="row">
  <div class="col-xl-10">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <h1>
          {{ 'maintenance.title' | translate }}
        </h1>
        <div class="subtitle">
          {{ 'maintenance.subtitle' | translate }}
        </div>
      </div>
      <div class="col-sm-5">
        <img src="assets/img/error.png"
             alt="Maintenance."
             class="maintenance"
        />
      </div>
    </div>
  </div>
</div>

