import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  isActive = false;
  isInvalid = false;
  delayTimer = null;
  styles: any = {
    wrapper: '',
  };

  @Input() cssStyles: any;
  @Input() modelValue: any;
  @Input() icon = '';
  @Input() inputName: string;
  @Input() maxLength: number;
  @Input() isRequired = false;
  @Input() label: string;
  @Input() rows = 1;
  @Input() public form: UntypedFormGroup;
  @Input() readOnly = false;

  @Output() areaFilled = new EventEmitter<{value: any, key: string}>();

  ngOnInit(): void {
    this.styles = { ...this.styles, ...this.cssStyles };
  }

  activate(): void {
    this.isActive = !this.isActive;
  }

  onKey(event: any): void {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.isInvalid = false;
      this.areaFilled.emit({value: event.target.value, key: this.inputName});
    }, 250);
  }

  getLabel(): string {
    return `${this.label}${this.isRequired ? '*' : ''}`;
  }

  onBlur(): void {
    if (!this.modelValue && this.isRequired) {
      this.isInvalid = true;
    }
    this.isActive = false;
  }
}
