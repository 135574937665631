import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MarketingFacade } from '../../../../../facades/marketing.facade';
import * as ShopCartActions from '../../../../../actions/shop-cart.actions';
import { Store } from '@ngrx/store';
import { ICart } from '../../../../../models/cart.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMessage, IPriceDisputing } from '../../../../../models/common.models';
import { EMessageType } from '../../../../../configurations/common';

@Component({
  selector: 'app-price-disputing',
  templateUrl: './price-disputing.component.html',
  styleUrls: ['./price-disputing.component.scss'],
})
export class PriceDisputingComponent implements OnInit {

  @Input() currentCart: ICart;
  @Input() currentItem: any;
  @Input() isSaturdayShipment: boolean = false;

  @Output() removeCartItem = new EventEmitter<string>();

  isOpened: boolean = false;
  priceDisputingForm: UntypedFormGroup;
  maxLengthMessage: number = 1000;
  priceDisputing: IPriceDisputing;
  itemDisputedPricingMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private marketingFacade: MarketingFacade,
    private store: Store,
  ) {
  }

  ngOnInit() {
    this.initializeForm();
    this.selectPriceDisputingPerItem();
  }

  /**
   * Get price disputing data for current item
   * and patch obtained price disputing message (to related form element - textarea).
   *
   *
   */
  selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItem => {
        this.priceDisputing = priceDisputingPerItem?.find(priceDisputing => {
          return priceDisputing?.itemId === this.currentItem.id;
        })?.priceDisputing;

        this.priceDisputingForm.patchValue({
          message: this.priceDisputing?.message,
        });
      });
  }

  /**
   * Set new price disputing for current item (from form element - textarea)
   * and store it (to ShopCart state).
   *
   *
   */
  setPriceDisputing(): void {
    //Set price disputing
    this.priceDisputing = {
      isSet: !!this.priceDisputingForm?.value?.message,
      message: this.priceDisputingForm.value.message,
    };

    //Store price disputing for related item (in ShopCartState)
    this.store.dispatch(ShopCartActions.addOrUpdatePriceDisputingPerItemInCartAction({
      updatedPriceDisputing: {
        itemId: this.currentItem.id,
        priceDisputing: this.priceDisputing,
      },
    }));

    //Close price disputing component
    this.toggle();
  }

  /**
   * Close price disputing component for current item
   * and clear its textarea if no price disputing was confirmed.
   *
   *
   */
  cancelPriceDisputing(): void {
    this.toggle();
  }

  /**
   * Revoke price disputing for current item, clear textarea
   * and close teh price disputing component.
   *
   *
   */
  clearPriceDisputing(): void {
    //Clear price disputing form
    this.priceDisputingForm.patchValue({
      message: '',
    });

    //Deactivate price disputing
    this.setPriceDisputing();
  }

  /**
   * Opening/Closing price disputing component
   * and setting confirmed price disputing to textarea.
   *
   *
   */
  toggle(): void {
    this.isOpened = !this.isOpened;

    if (!this.isOpened) {
      this.priceDisputingForm.patchValue({
        message: this.priceDisputing?.message,
      });
    }
  }

  /**
   * Emit event to remove selected item from cart.
   *
   *
   * @param {string} itemId
   */
  removeCartItemEmit(itemId: string): void {
    this.removeCartItem.emit(itemId);
  }

  private initializeForm(): void {
    this.priceDisputingForm = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
  }
}
