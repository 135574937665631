<div class="wishlist-remove__modal" *ngIf="wishlist">
  <app-modal
      [open]="showModal"
      [id]="'wishlistsAddNew'"
      title="{{'wishlists.remove' | translate}}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="row">
        <div class="checkout__field col-12">
          {{'wishlists.confirm-remove-desc' | translate: {name: wishlist.attributes.name } }}
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button type="button" class="button button--primary" data-dismiss="modal-dialog" (click)="remove()">
        {{'wishlists.confirm-remove' | translate}}
      </button>
    </ng-container>
  </app-modal>
</div>
