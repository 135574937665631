import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IOrderDataAttributes, IOrderItem, IOrderResponseWithMessages } from '../../models/order.models';
import { OrdersFacade } from '../../facades/orders.facade';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ShopCartActions } from '../../actions';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../analytics/analytics.service';
import { EFeatureToggles } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';

@Component({
  selector: 'app-order-thank-you-page',
  templateUrl: './order-thank-you-page.component.html',
  styleUrls: ['./order-thank-you-page.component.scss'],
})
export class OrderThankYouPageComponent implements OnInit {
  order: any = {
    orderNumber: '',
  };
  orderedItemsAccordion: boolean = false;
  questionEmail: string = '';
  hasExchangableItem: boolean = false;
  isReturnableDocumentEmailLinkEnable: boolean = false;
  orderData: IOrderDataAttributes = {
    sapDetails: undefined,
    approverDetails: undefined,
    calculatedDiscounts: [],
    contractNumber: '',
    createdAt: '',
    currencyIsoCode: '',
    customer: undefined,
    expenses: [],
    items: [],
    itemsTotalNumber: 0,
    sapMessages: [],
    payments: [],
    sapPoNumber: '',
    pointOfContact: undefined,
    priceMode: '',
    sapId: 0,
    shipments: [],
    stateComment: '',
    stateDisplayName: '',
    taxNumber: '',
    totals: undefined,
    trackingNumber: 0,
  };
  returnDocumentUrl: string = '';
  sparePartsReturnDocumentUrlKey: string = environment.sparePartsReturnDocumentUrlKey;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private ordersFacade: OrdersFacade,
    private store: Store<State>,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.orderId) {
        this.order.orderNumber = params.orderId;
        this.ordersFacade.recallAndClearOrdersWithDetailsDataList();
      }
    });

    this.translate.get('order-thank-you-page.question.email').pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.questionEmail = value;
    });


    this.ordersFacade.getOrderById(this.order.orderNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderResponseWithMessages: IOrderResponseWithMessages) => {
        this.orderData = orderResponseWithMessages.data.attributes;

        // US-Clickdummy relevant: get items from localStorage and add them to the orderData
        if (localStorage.hasOwnProperty('items')) {
          const valueFromStorage = LocalStorageUtils.getKeyValue('items');
          let items = valueFromStorage && JSON.parse(valueFromStorage);

          const itemsWithImage = items.map(item => {
            const metadata = {
              "superAttributes": [],
              "image": item.attributes.imageSets[0].images[0].externalUrlSmall
            }
            item.attributes.metadata = metadata;
            item.attributes.sumPrice = item.attributes.calculations.sumPrice;
            return item.attributes;
          });
          
          this.orderData.items = itemsWithImage;

          // US-Clickdummy relevant: get totals from localStorage
          if (localStorage.hasOwnProperty('totals')) {
            const totalsFromStorage = LocalStorageUtils.getKeyValue('totals');
            let totals = valueFromStorage && JSON.parse(totalsFromStorage);
            this.orderData.totals = totals;
          }
        }

        this.searchExchangeableItem();
        this.analyticsService.setProducts(this.orderData.items);
        this.analyticsService.trackPageReady('Order Thank You', PageTypes.ORDER_THANK_YOU_PAGE, 'thank-you-page');
      });
    this.store.dispatch(ShopCartActions.updateCartDataSuccess(this.order.orderNumber));
    this.configurationFacade.isFeatureEnabled(
      EFeatureToggles.RETURNABLE_DOCUMENT_EMAIL_LINK_FROM_PURCHASE_ACTIVITY_PAGE,
    ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isReturnableDocumentEmailLinkEnable = value;
    });
    this._getReturnDocumentUrl();
  }

  toggleOrderedItemsAccordion(): void {
    this.orderedItemsAccordion = !this.orderedItemsAccordion;
  }

  downloadDocumentation(): void {
    window.open(this.returnDocumentUrl, '_blank');
  }

  getTotalItemTranslationValue(item): string {
    return item <= 1 ? 'order-thank-you-page.total-item-number-singular' : 'order-thank-you-page.total-item-number-plural';
  }

  searchExchangeableItem(): void {
    this.orderData.items.forEach((item: IOrderItem) => {
      if (item.sapDetails.exchangeableItem) {
        this.hasExchangableItem = true;
      }
    });
  }

  private _getReturnDocumentUrl(): void {
    this.translateService.get(this.sparePartsReturnDocumentUrlKey).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.returnDocumentUrl = value;
    });
  }
}
