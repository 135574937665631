import { IAbstractProduct } from '../models/abstract-product.models';
import { StringUtils } from '../utils/string.utils';

export const BREADCRUMB_SCHEME_CLASS = 'structured-data-breadcrumb';
export const PRODUCT_SCHEME_CLASS = 'structured-data-product';

export const BreadCrumbSchema = (breadcrumbElements: IitemListElement[]): Record<string, any> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbElements
  };
};

export const ProductSchema = (product: IAbstractProduct, images: string[]): Record<string, any> => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    image: images,
    description: StringUtils.removeTags(product.description),
    sku: product.sku,
    brand: {
      '@type': 'Brand',
      name: 'Siemens Healthineers'
    }
  };
};

export interface IitemListElement {
  '@type': string;
  position: number;
  name: string;
  item?: string;
}
