import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBaseWishlist, IWishlistProductDetails } from '../../models/wishlist.models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WishlistFacade } from '../../facades/wishlist.facade';
import { ArrayUtils } from '../../utils/array.utils';
import { I18nService } from '../../services';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { ICustomerAccessControlData } from '../../models/customer.models';
import { CustomerFacade } from '../../facades/customer.facade';
import { PriceUtils } from '../../utils/price.utils';
import { ISortOptions } from '../../models/settings.model';

@Component({
  selector: 'app-wishlists',
  templateUrl: './wishlists.component.html',
  styleUrls: ['./wishlists.component.scss'],
})
export class WishlistsComponent implements OnInit, OnDestroy {
  wishlists: IBaseWishlist[];
  loaded: boolean = false;
  pageReadyEventFired: boolean = false;
  currency: string = 'BRL';
  addNewModalActive: boolean = false;
  removeModalActive: boolean = false;
  wishlistToRemove: IBaseWishlist;
  customerState$: Observable<ICustomerAccessControlData> = new Observable<ICustomerAccessControlData>();

  sortOptions: ISortOptions[] = [
    {
      name: '',
      value: 'name',
      reversed: false,
      translation: 'sort-name-asc'
    },
    {
      name: '',
      value: 'name',
      reversed: true,
      translation: 'sort-name-desc'
    },
    {
      name: 'newest',
      value: 'updatedAt',
      reversed: true,
      translation: 'sort-updated-desc',
      time: true
    }
  ];
  currentSort: ISortOptions = this.sortOptions[2];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private wishlistFacade: WishlistFacade,
    private i18nService: I18nService,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
  ) {}

  ngOnInit(): void {
    this.currency = PriceUtils.getStoreCurrencyCode();
    this.customerState$ = this.customerFacade.selectAccessControlData();
    this.getWishlistsFromStore();
    this.dispatchActionToGetWishlists();
    this.getSortLabels();
    this.selectLoaded();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSortLabels(): void {
    this.i18nService.getTranslationByKey([
      'wishlists.sort-name-asc',
      'wishlists.sort-name-desc',
      'wishlists.sort-updated-desc',
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(labels => {
      this.sortOptions = this.sortOptions.map( (sort: ISortOptions) => {
        return {
          ...sort,
          name: labels['wishlists.' + sort.translation]
        };
      });
    });
  }

  dispatchActionToGetWishlists(): void {
    this.wishlistFacade.dispatchUsersWishlistRetrieve();
  }

  getWishlistsFromStore(): void {
    this.wishlistFacade.selectUsersWishlists().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      (wishlists: IBaseWishlist[]) => {
        this.wishlists = wishlists.length > 0
          ? ArrayUtils.sortByAttribute(
            [...wishlists], 'attributes.' + this.currentSort.value, this.currentSort.reversed, this.currentSort?.time) : [];
      }
    );
  }

  selectLoaded(): void {
    this.wishlistFacade.selectLoaded().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      loaded => {
        this.loaded = loaded;
        this.trackPageReady();
      }
    );
  }

  wishlistCreated(wishlistName: string): void {
    this.wishlistFacade.createNewWishlist(wishlistName);
  }

  wishlistRemoved(): void {
    this.wishlistFacade.deleteList(this.wishlistToRemove.id);
    this.wishlistToRemove = null;
  }

  remove(wishlist: IBaseWishlist): void {
    this.wishlistToRemove = wishlist;
    this.removeModalActive = true;
  }

  getTotalPrice(wishlist: IBaseWishlist): number {
    if (!wishlist.items || wishlist.items.length === 0 ||
        !wishlist.items[0].defaultPrice) {
      return 0;
    }
    return wishlist.items.reduce((prev: number, curr: IWishlistProductDetails) => {
      return prev + PriceUtils.getPriceValue(curr.defaultPrice);
    }, 0);
  }

  sort(option: any): void {
    this.currentSort = option;
    this.wishlists = ArrayUtils.sortByAttribute(
      [...this.wishlists], 'attributes.' + option.value, option.reversed, this.currentSort?.time);
  }

  private trackPageReady(): void {
    if (!this.pageReadyEventFired && this.loaded) {
      this.analyticsService.trackPageReady('Wishlists', PageTypes.WISHLISTS_PAGE);
      this.pageReadyEventFired = true;
    }
  }
}
