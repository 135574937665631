import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { IRfqCartItem } from '../../../models/rfq.models';
import { AppUtils } from '../../../utils/app.utils';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-quote-details',
  templateUrl: './my-quote-details.component.html',
  styleUrls: ['./my-quote-details.component.scss'],
})
export class MyQuoteDetailsComponent implements OnInit, OnDestroy {
  requestItems: IRfqCartItem[];
  itemsInQueueToCart: Array<string>;
  itemsInQueueToCartSubscription: Subscription;
  taxNumber: string;
  currency: string;

  private unsubscribe$ = new Subject<void>();

  @Input() request;
  @Input() isOpen: boolean;

  constructor(
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
  ) {
  }

  ngOnInit(): void {
    this.itemsInQueueToCartSubscription = this.selectItemsInQueueToCart();
    this.requestItems = this.request.attributes.shownVersion.cart.items;
    this.taxNumber = this.request.attributes.taxNumber;
    this.currency = AppUtils.getCurrentStore().storeDefaultCurrency;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectItemsInQueueToCart(): Subscription {
    return this.marketingFacade.selectItemsInQueueToCart().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  addProductToCart(product: string, productName: string): void {
    this.marketingFacade.addProductToCart({sku: product, name: productName}, true);
  }

  renderEmptyAddressField(field: string): string {
    return field === 'empty' ? '' : field;
  }

  isAddressFieldEmpty(field: string): boolean {
    return field === 'empty' || field === '' || !field;
  }

  isItemInProgress(sku): boolean {
    return this.itemsInQueueToCart.includes(sku);
  }

  downloadPdfFile(quoteId: string, createdAt?: string): void {
    this.checkoutFacade.getQuotePdfFile(quoteId).pipe(take(1))
      .subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_details_${quoteId}`, createdAt);
      });
  }

}
