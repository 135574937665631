<ng-container *ngIf="displayBreadCrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <ng-container *ngIf="!breadcrumbsLoading; else loader">
        <ol class="bare-list breadcrumbs">
          <li *ngFor="let breadcrumb of breadcrumbs;" aria-current="page" class="breadcrumbs__item"
              property="itemListElement" typeof="ListItem">
            <a *ngIf="!breadcrumb.breadcrumbDisabled; else disabledLink"
               class="breadcrumbs__link" [routerLink]="breadcrumb.url"
               property="item" typeof="WebPage">
              <span property="name">
                {{ breadcrumbLabelDecoded(breadcrumb.label) }}
              </span>
            </a>
            <ng-template #disabledLink>
              <span class="breadcrumbs__link">
                <span property="name">
                  {{ breadcrumbLabelDecoded(breadcrumb.label) }}
                </span>
              </span>
            </ng-template>
          </li>
        </ol>
      </ng-container>
      <ng-template #loader>
        <ol class="bare-list breadcrumbs">
          <li class="breadcrumbs__item">
            <a class="breadcrumbs__link" [routerLink]="breadcrumbs[0].url">
              {{ 'app-breadcrumb.home' | translate }}
            </a>
          </li>
          <li class="loading-breadcrumbs">
            <span class="stripe"></span>
          </li>
        </ol>
      </ng-template>
    </div>
  </div>
</ng-container>
