<div class="add-to-cart">
  <h6 [attr.data-test]="'title-add-to-cart-add-another-item-to-selected-cart'">{{ 'add-parts-to-cart.add-another-item-to-selected-cart' | translate }}</h6>
  <div class="cart-items-list-container">
    <div class="list-header">
      <div class="header-col flex-3" [attr.data-test]="'title-add-to-cart-item-name-or-material-number'">{{ 'add-part-to-cart.item-name-or-material-number' | translate }}</div>
      <div class="header-col flex-1" [attr.data-test]="'title-add-to-cart-quantity'">{{ 'add-part-to-cart.quantity' | translate }}</div>
      <div class="header-col flex-1 blank"></div>
    </div>
    <div class="item-row">
      <div class="search-bar item-col flex-3">
        <app-search-bar (product)="product = $event" [disabled]="isItemInProgress()" [cartSearch]="true"></app-search-bar>
      </div>
      <div class="quantity item-col flex-1">
        <form (submit)="updateProductQuantity(itemQuantity.value)">
          <input #itemQuantity
                 type="number"
                 name="itemQuantity"
                 class="item-quantity"
                 [value]="quantity"
                 min="1"
                 max="99"
                 step="1"
                 oninput="this.value=this.value.slice(0,2)"
                 required
                 (change)="updateProductQuantity(itemQuantity.value)"
                 [attr.data-test]="'desc-itemQuantity-add-to-cart'"/>
        </form>
      </div>
      <div class="add-to-cart-button item-col flex-1">
        <ng-container *ngIf="!isItemInProgress(); else loadingSpinner;">
          <button class="button button--secondary icon-basket button--cart icon__mobile"
                  [disabled]="!product || isItemInProgress() || disabledAddButton"
                  [attr.data-test]="'button-addToCart-add-to-cart'"
                  (click)="addProductToCart()">
            {{ 'app-product-page.add-to-cart' | translate }}
          </button>
        </ng-container>
        <ng-template #loadingSpinner>
          <div class="btn-spinner">
            <div class="loading-animation"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
