import { ICatalogProduct } from '../models/abstract-product.models';

export class ProductUtils {
  static isConfigurableAvailable(product: ICatalogProduct): boolean {
    return product.attributes.available === '1';
  }

  static getDetailAttribute(attributeValue: string | null): string {
    if (!attributeValue || ['', 'no'].includes(attributeValue.toLowerCase())) {
      return '—';
    } else if (['yes'].includes(attributeValue.toLowerCase())) {
      return '<span class="icon-check icon-check__circle"></span>';
    } else {
      return attributeValue;
    }
  }
}
