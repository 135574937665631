<div class="accordion service-agreements__table">
  <div class="accordion__title service-agreements__header">
    <div *ngFor="let header of headers" class="width-{{header.width}} service-agreements__title">
      {{ header.label | translate }}
      <app-sort-by
          [sort]="header.sortOptions"
          [current]="currentSort"
          [hasTitle]=false
          (selected)="sort($event)">
      </app-sort-by>
    </div>
  </div>
  <div class="agreements__table">
    <div class="table-items">
      <app-accordion
          *ngFor="let purchase of purchaseData | searchFilter: searchValue:searchExcludedAttributes"
          [manualOpen]="tabsOpened.includes(purchase.id)">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__title icon-arrow-down" (click)="toggleTab(purchase.id)">
            <ng-container *ngFor="let header of headers; index as i">
              <span class="agreement-info width-{{ getHeaderWidth(header.propName) }} {{ i === 0 ? 'functional-location-id' : ''}} {{ header.propName === 'stateDisplayName' ? 'status-value' : ''}}">
              <span class="header__label">{{ 'my-contracts.functional-location' | translate }}</span>
                {{ renderValue(header, purchase) }}
            </span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content" *ngIf="tabsOpened.includes(purchase.id)">
            <app-service-details [quoteID]="purchase.id"></app-service-details>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
