<nav class="navigation-bar"
     style="position: relative;z-index: 9;display: block;">
  <div class="navigation-bar__container">
    <div class="navigation-bar__panel">
      <div *ngIf="!hideNavigation()" class="navigation-bar__wrapper" #navigationBarListElem>
        <button class="navigation-bar__toggle-button" (click)="activeDropdown()">
          <span class="icon-burger"></span>
        </button>
      </div>
    </div>
    <ul *ngIf="!hideNavigation()" class="bare-list navigation-bar__list" [ngClass]="[active ? 'is-open':  '']">
      <li class="navigation-bar__item" *ngFor="let navigationBarLink of navigationBarList">
        <a (click)="tracking(navigationBarLink.title)" [routerLink]="navigationBarLink.url"
           class="navigation-bar__link"
           [attr.data-test]="'nav-item-' + navigationBarLink.cssClass">
          {{
            navigationBarLink.url?.includes('equipment-selection')
              ? ('my-equipment-selection.tab-title' | translate)
              : navigationBarLink.title
          }}
        </a>
      </li>
    </ul>
  </div>
</nav>
