import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsService } from '../services/terms.service';

@Injectable({
  providedIn: 'root',
})
export class TermsFacade {
  private termsService: TermsService;

  public get getTermsService(): TermsService {
    if (!this.termsService) {
      this.termsService = this.injector.get(TermsService);
    }
    return this.termsService;
  }

  constructor(private injector: Injector) {
  }

  postAcceptTermsOfUse(data: any): Observable<any> {
    return this.getTermsService.postAcceptTerms(data);
  }
}
