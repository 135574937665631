import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CatalogSearch } from '../models/catalog.models';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  getSuggestions(query = ''): Promise<any> {
    const catalogSearchSuggestionUrl = this.glueUrl + '/catalog-search-suggestions?q=' + query;
    return this.http.get<CatalogSearch>(catalogSearchSuggestionUrl)
      .toPromise()
      .then((response) => {
        return response;
      });
  }
}
