import { DatePipe } from '@angular/common';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { environment } from '../../../../../environments/environment.defaults';
import { ICpqOrder } from '../../../../models/order.models';
import { IGenericHeader } from '../../../../models/settings.model';
import { SortByComponent } from '../../../../shared/sort-by/sort-by.component';
import { ArrayUtils } from '../../../../utils/array.utils';
import { MathUtils } from '../../../../utils/math.utils';
import { RemoveLeadingZeroPipe } from '../../../../shared/pipes/remove-leading-zero.pipe';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent {
  tabsOpened = [] as Array<string>;
  searchExcludedAttributes = environment.searchExcludedAttributes.filter(s => s !== 'id');
  currentSort = {};

  @Input() loading: boolean;
  @Input() purchaseData: ICpqOrder[];
  @Input() searchValue: string;
  @Input() cartItems = [];
  @Input() productSku = '';
  @Input() headers: IGenericHeader[];

  @ViewChildren(SortByComponent)
  sortByComponent: QueryList<SortByComponent>;

  constructor(private datePipe: DatePipe, private removeLeadingZero: RemoveLeadingZeroPipe) {
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  sort(option: any): void {
    const attributes = option.value === 'id' ? '' : 'attributes.';

    this.purchaseData = ArrayUtils.sortByAttribute(
      [...this.purchaseData],
      attributes + option.value,
      option.reversed,
      option?.time,
      option?.numeric,
    );

    this.sortByComponent.forEach(component => component.active = component.isOpen ? option : '');
  }

  getHeaderWidth(name: string): string {
    return this.headers.find(h => h.propName === name).width;
  }

  renderValue(header: IGenericHeader, purchase: ICpqOrder): string {
    switch (header.propName) {
      case 'id': {
        return this.removeLeadingZero.transform(purchase.id);
      }
      case 'quoteName': {
        return MathUtils.checkIfNumeric(purchase.attributes[header.propName]) ? '-' : purchase.attributes[header.propName];
      }
      case 'createdAt': {
        return this.datePipe.transform(new Date(purchase.attributes[header.propName]), 'MM/dd/yyyy', '', 'en-US');
      }
      default: {
        return purchase.attributes[header.propName];
      }
    }
  }
}
