import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { State } from '../../reducers';
import { EUserRoles } from '../../configurations/common';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { I18nService } from '../../services';
import { AnalyticsService } from '../../analytics/analytics.service';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../models/cart.models';
import { IAddress, IPriceDisputingPerItem, ISystemDetails } from '../../models/common.models';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { CartUtils } from '../../utils/cart.utils';
import { ObjectUtils } from '../../utils/object.utils';
import { CustomerFacade } from '../../facades/customer.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';

@Component({
  selector: 'app-spare-part-delivery-details',
  templateUrl: './spare-part-delivery-details.component.html',
  styleUrls: ['./spare-part-delivery-details.component.scss'],
})
export class SparePartDeliveryDetailsComponent implements OnInit, OnDestroy {
  userData: any;
  isUserDataLoading: boolean = true;

  loadingCartDataInProgress: boolean = true;
  isCartEmpty: boolean = true;
  cartDataLoaded: boolean = false;
  cartItemsDataLoaded: boolean = false;
  creatingOrderInProgress: boolean = false;
  cartId: string;
  cartAttributes: ICartAttributes;
  cartItemsWithDetails: Array<ICartItemWithDetail>;
  cartRules: Array<ICartRule>;
  systemDetails: ISystemDetails;
  priceDisputingPerItem: IPriceDisputingPerItem[];

  isOnContactDetailsSection: boolean = false;
  checkoutUpdateData: ICheckoutUpdate;
  orderDetailsData: any;
  isSaturdayShipment: boolean = false;
  addressChanged: boolean = false;
  isCustomerBusinessPartner: boolean = false;
  isPriceDisputingActive: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private cartIncludedResources: any[];

  constructor(
    private router: Router,
    private store: Store<State>,
    private i18nService: I18nService,
    private analyticsService: AnalyticsService,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCustomerData();
    this.selectCustomerDataLoading();
    this.selectLoadingCartDataInProgress();
    this.selectIsCartEmpty();
    this.loadCartData();
    this.selectCartItemsWithDetails();
    this.selectIsCustomerBusinessPartner();
    this.selectPriceDisputingPerItem();
    this.selectIsPriceDisputingActive();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backToHomepage(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()]).then();
  }

  proceedToOrderDetails(): void {
    this.isOnContactDetailsSection = false;
  }

  proceedToContactDetails(orderDetailsData: any): void {
    this.orderDetailsData = orderDetailsData;
    this.isOnContactDetailsSection = true;
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isCustomerBusinessPartner => {
      this.isCustomerBusinessPartner = isCustomerBusinessPartner;
    });
  }

  createOrder(contactDetailsData: any): void {
    this.creatingOrderInProgress = true;

    this.checkoutUpdateData = {
      type: 'checkout-update',
      attributes: {
        idCart: this.cartId,
        isAddressSavingSkipped: true,
        pointOfContact: {...contactDetailsData.pointOfContact},
        priceDisputingPerItem: this.priceDisputingPerItem,
      },
    };

    if (!!this.orderDetailsData?.billToAddress) {
      const billingAddress = this.updateAddress(this.orderDetailsData?.billToAddress);
      this.checkoutUpdateData = {
        ...this.checkoutUpdateData,
        attributes: {
          ...this.checkoutUpdateData.attributes,
          billingAddress: billingAddress,
        },
      };
    }

    if ((this.cartAttributes?.shippingAddress && this.orderDetailsData?.shipToAddress &&
      !ObjectUtils.isEqual(
        this.cartAttributes.shippingAddress,
        this.orderDetailsData.shipToAddress,
      )) || this.isCustomerBusinessPartner) {
      // set new shipTo address if it is different from quote shipTo address
      const shippingAddress = this.updateAddress(this.orderDetailsData?.shipToAddress);
      this.checkoutUpdateData = {
        ...this.checkoutUpdateData,
        attributes: {
          ...this.checkoutUpdateData.attributes,
          shippingAddress: shippingAddress,
        },
      };
    }

    this.checkoutFacade.postCheckoutData({data: this.checkoutUpdateData}, this.cartId)
      .then(() => {
        this.creatingOrderInProgress = false;
        this.checkoutFacade.actionPutCartIdOrderApprove(this.cartId);
        this.router.navigate(['/order-review/', this.cartId]).then();
      }).catch(() => {
      this.creatingOrderInProgress = false;
    });
  }

  updateAddress(address: IAddress): IAddress {
    return {
      salutation: address.salutation || 'Ms',
      firstName: address?.firstName || 'empty',
      lastName: address?.lastName || 'empty',
      address1: address?.address1 || 'empty',
      address2: address?.address2 || 'empty',
      address3: '',
      zipCode: address?.zipCode || 'empty',
      city: address?.city || 'empty',
      country: address?.country || 'United States',
      iso2Code: address?.iso2Code || 'US',
      phone: address?.phone || 'empty',
      company: address?.company || 'empty',
      state: address?.state || 'empty',
      sapId: address?.sapId,
      idCustomerAddress: address?.idCustomerAddress,
      idCompanyUnitAddress: address?.idCompanyUnitAddress,
      isDefaultBilling: false,
      isDefaultShipping: false,
    };
  }

  updateIsSaturdayShipment(isSaturdayShipment: boolean): void {
    this.isSaturdayShipment = isSaturdayShipment;
  }

  private selectCustomerData(): void {
    this.customerFacade.selectCustomerData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(data => {
      this.userData = data;
      if (data.companyRoles.includes(EUserRoles.Viewer)) {
        this.backToHomepage();
      }
    });
  }

  private selectCustomerDataLoading(): void {
    this.customerFacade.selectCustomerDataLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isDataLoading => {
      this.isUserDataLoading = isDataLoading;
    });
  }

  private selectIsCartEmpty(): void {
    this.marketingFacade.selectIsCartEmpty().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCartEmpty = state;
    });
  }

  private loadCartData(): void {
    this.marketingFacade.getCurrentCartItems().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartData => {
      if (!cartData) {
        return;
      }

      this.cartId = cartData.data.id;
      this.cartAttributes = cartData.data.attributes;
      this.cartIncludedResources = cartData.included;
      this.cartRules = this.cartIncludedResources.filter(include => include.type === 'cart-rules');
      this.systemDetails = cartData.data.attributes.systemDetails;
      
      // US-Clickdummy relevant: get equipment from localStorage
      if (localStorage.hasOwnProperty('selected-equipment')) {
        const valueFromStorage = LocalStorageUtils.getKeyValue('selected-equipment');
        let equipment = valueFromStorage && JSON.parse(valueFromStorage);
        this.systemDetails = equipment;
      }
      this.cartDataLoaded = true;

      this.analyticsService.setCartId(this.cartId);
      this.analyticsService.setProducts(cartData);
    });

    this.analyticsService.trackPageReady('spare part - delivery details', PageTypes.DELIVERY_DETAILS_PAGE, 'concrete-products');
  }

  private selectCartItemsWithDetails(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItemsWithDetails => {
      if (cartItemsWithDetails?.length > 0) {
        this.cartItemsWithDetails = cartItemsWithDetails;
        this.cartItemsDataLoaded = true;
        this.cartItemsWithDetails = CartUtils.mapAvailabilitiesToCartItems(
          this.cartItemsWithDetails, this.cartIncludedResources,
        );
      }
    });
  }

  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
      this.loadingCartDataInProgress = loadingCartDataInProgress;
    });
  }

  /**
   * Select price disputing as array of all items
   * which had price disputing set (from ShopCart state).
   *
   * @private
   *
   */
  private selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$)).subscribe(priceDisputingPerItem => {
      this.priceDisputingPerItem = priceDisputingPerItem;
    });
  }

  /**
   * Select if price disputing functionality is active (based on the company roles).
   *
   * @private
   *
   */
  private selectIsPriceDisputingActive(): void {
    this.customerFacade.isPriceDisputingActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPriceDisputingActive => {
      this.isPriceDisputingActive = isPriceDisputingActive;
    });
  }
}
