import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-drag-drop-upload',
  templateUrl: './drag-drop-file-upload.component.html',
  styleUrls: ['drag-drop-file-upload.component.scss'],
})

export class DragDropFileUploadComponent {
  @Input() supportedMaxAmountOfFiles: number;
  @Input() supportedFileFormat: string;
  @Input() disabledAddButton: boolean = false;
  @Input() uploadingFileInProgress: boolean;

  /*
  Component emits to parent component array of uploaded files.
   */
  @Output() uploadedFiles: EventEmitter<any> = new EventEmitter<any>();

  files: any[] | undefined;
  maxAmountOfFilesExceededError: string = '';
  notSupportedFilesFormatError: string = '';


  handleDragDropFiles(files: any): void {
    this.files = files;
    this.maxAmountOfFilesExceededError = this.files.length > this.supportedMaxAmountOfFiles ? 'maxAmountOfFilesExceeded' : '';
    this.notSupportedFilesFormatError = '';
    for (let file of this.files) {
      if (!this.isCSVFile(file.name)) {
        this.notSupportedFilesFormatError = 'notSupportedFilesFormatError';
        this.files = undefined;
      }
    }
  }

  isCSVFile(fileName: string): boolean {
    return fileName.toLowerCase().endsWith(this.supportedFileFormat);
  }

  uploadFiles(): void {
    this.uploadedFiles.emit(this.files);
    this.files = undefined;
  }

  deleteUploadedFiles(): void {
    this.files = undefined;
    this.notSupportedFilesFormatError = '';
    this.maxAmountOfFilesExceededError = '';
  }

  /**
   * Functionality supports downloading only one file at a time
   */

  createCsv(filename: string): void {
    const csvContent = this.convertToCsv();
    this.downloadCsv(csvContent, filename);
  }

  private convertToCsv(): string {
    const headers = ['Material_Number', 'Quantity'];
    return headers.join(',') + '\n';
  }

  private downloadCsv(content: string, filename: string): void {
    const blob = new Blob([content], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename + '.csv';
    a.click();

    window.URL.revokeObjectURL(url);
  }
}
