import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { MathUtils } from '../../../utils/math.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { EProductDetails } from '../../../configurations/product-details';
import { ProductUtils } from '../../../utils/product.utils';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { IPrice, ISystemDetails } from '../../../models/common.models';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { EFeatureToggles, EGlueResource, EUserRoles } from '../../../configurations/common';
import { IInstalledBaseSystemData, ISimpleInstallBaseProduct } from '../../../models/installedbase.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { IAbstractProductAvailabilityResource, IAbstractProductPrices } from '../../../models/abstract-product.models';
import { PriceUtils } from '../../../utils/price.utils';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { ICart } from '../../../models/cart.models';
import { CustomerFacade } from '../../../facades/customer.facade';
import { SparePartsUtils } from '../../../utils/spare-parts.utils';

@Component({
  selector: 'app-spare-part-product-pdp',
  templateUrl: './spare-part-product-pdp.component.html',
  styleUrls: ['./spare-part-product-pdp.component.scss'],
})
export class SparePartProductPdpComponent implements OnInit, OnChanges, OnDestroy {
  openZoomedPicture: boolean = false;
  isQuantityValid: boolean = true;
  currentlyInStock: boolean = false;
  closeModal: boolean = false;
  isEligible: boolean = false;
  isExchangeable: boolean = false;
  pricesLoaded: boolean = false;
  emptyAttribute: string = '-';
  attributes: IBaseConfig[] = [];
  guestConst: EUserRoles = EUserRoles.Guest;
  isBusinessPartner: boolean = false;
  priceUtils = PriceUtils;
  canCartItemsBeChanged: (currentCart: ICart, isBusinessPartner: boolean) => boolean = SparePartsUtils.canCartItemsBeChanged;

  @Input() systemDetails: ISystemDetails;
  @Input() equipmentFlNumber: string;
  @Input() stepNumber;
  @Input() concreteProduct;
  @Input() wishlists;
  @Input() sku;
  @Input() yourPrice: IPrice;
  @Input() listPrice: IPrice;
  @Input() productInfoLoaded;
  @Input() concreteSku;
  @Input() abstractProduct;
  @Input() labels;
  @Input() defaultImg;
  @Input() largeUrls;
  @Input() addNewModalActive;
  @Input() productQuantity;
  @Input() isAddToCartInProgress$;
  @Input() smallUrls;
  @Input() mainPicture;
  @Input() productDetailsEnum;
  @Input() installBaseProducts;
  @Input() displayConfig;
  @Input() loadingCartDataInProgress: boolean;
  @Input() wasRedirectedFromEquipmentPage: boolean;
  @Input() companyRoles: EUserRoles[];
  @Input() agreement: IInstalledBaseSystemData;
  @Input() currentCart: ICart;

  @Output() addNewModalWishlist = new EventEmitter<any>();
  @Output() addProductToCartSubmit = new EventEmitter<any>();
  @Output() productQuantityChange = new EventEmitter<number>();

  @ViewChild(SlickCarouselComponent) slickCarouselComponent: SlickCarouselComponent;

  isSapP40Enabled = false;
  loadedSuccessfully = true;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();
  private unsubscribe$ = new Subject<void>();
  
  // relevant for US-Clickdummy, show only 'material_number'

  sparePartsDetails = [
    'material_number'
  ];

  constructor(
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private translate: TranslateService,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => this.isSapP40Enabled = value);

    this.getAttributes();
    this.selectIsCustomerBusinessPartner();
    this.shouldShowWarning();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.shouldRefreshProductAbstractData(changes)) {
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(value => {
        this.isSapP40Enabled = value;
        this.setPricesAndAvailabilities();
      });
    }
  }

  /**
   * Method for showing warning message
   */
  shouldShowWarning(): void {
    if (!this.currentCart?.attributes?.soldToAddress && this.isBusinessPartner && (!!this.systemDetails?.siemensEquipmentId && !!this.systemDetails?.materialNumber && !!this.systemDetails?.nameEnUs)) {
      this.showWarning();
    }
  }

  isAddToCartButtonDisabled(): boolean {
    return !this.isSapP40Enabled
      || !this.isQuantityValid
      || this.loadingCartDataInProgress
      || (!this.pricesLoaded && !!this.equipmentFlNumber)
      || !this.loadedSuccessfully;
  }

  addToWishlist(): void {
    this.addNewModalWishlist.emit();
  }

  setLargeUrl(index: number): void {
    this.mainPicture = this.largeUrls[index];
    this.slickCarouselComponent.slickGoTo(index);
  }

  isConfigurable(): boolean {
    return this.concreteProduct ? this.concreteProduct.attributes.productConfigurationInstance !== null : false;
  }

  changeProductQuantity(productQuantity: number): void {
    this.checkIfPositiveInteger(productQuantity);
    if (this.isQuantityValid) {
      this.productQuantityChange.emit(productQuantity);
    }
  }

  private checkIfPositiveInteger(productQuantity: number): void {
    this.isQuantityValid = MathUtils.checkIfNumeric(productQuantity) && productQuantity > 0;
  }

  addProductToCart(): void {
    this.addProductToCartSubmit.emit();
  }

  openZoomPicture(): void {
    if (this.mainPicture !== this.defaultImg) {
      this.openZoomedPicture = !this.openZoomedPicture;
      if (this.openZoomedPicture) {
        this.analyticsService.setProducts(this.abstractProduct);
        this.analyticsService.trackProduct('image.enlarge', this.abstractProduct);
      }
    }
  }

  getDetailAttribute(attributeValue: string | null): string {
    return ProductUtils.getDetailAttribute(attributeValue);
  }

  tracking(name: string): void {
    this.analyticsService.setProducts(this.abstractProduct);
    this.analyticsService.trackAccordion(name);
  }

  trackInstalledBase(installedBase: ISimpleInstallBaseProduct): void {
    this.catalogFacade.setInstalledBase(installedBase);
    this.analyticsService.trackInstalledBase(installedBase.attributes.nameEnUs, installedBase.id);
  }

  displayProductDetail(section: EProductDetails): boolean {
    return this.displayConfig.includes(section);
  }

  hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    return !!translation && translation !== key;
  }

  getWeightDetails(): string {
    const weightInLbs = this.abstractProduct.attributes['sap_p40_gross_weight']
      ? `${this.abstractProduct.attributes['sap_p40_gross_weight']} LBS`
      : '';
    const weightInKg = this.abstractProduct.attributes['sap_gross_weight']
      ? `${this.abstractProduct.attributes['sap_gross_weight']} KG`
      : '';

    if (!weightInLbs && !weightInKg) {
      return '-';
    } else {
      return weightInLbs && weightInKg
        ? `${weightInLbs} / ${weightInKg}`
        : weightInLbs || weightInKg;
    }
  }

  openSparePartsViewer(): void {
    const productMaterialNumber = this.abstractProduct.attributes.material_number;
    const sysIvk = this.systemDetails?.materialNumber;
    const sparePartsLink = sysIvk
      ? `${environment.sparePartsViewerUrl}&sysIVK=${sysIvk}&matnum=${productMaterialNumber}`
      : `${environment.sparePartsViewerUrl}&matnum=${productMaterialNumber}`;
    window.open(sparePartsLink, '_blank');
  }

  toggleAttribute(attribute: string): boolean {
    return this.attributes.find((attr) => attr.name === attribute).value === 'true';
  }

  private getAttributes(): void {
    this.configurationFacade.getTranslationByKey(['spare-parts.attributes'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        Object.keys(data['spare-parts.attributes']).forEach((key) => {
          const attribute = data['spare-parts.attributes'][key];
          this.attributes.push({
            name: key,
            value: attribute,
          });
        });
      });
  }

  private shouldRefreshProductAbstractData(changes: SimpleChanges): boolean {
    return (
        changes.sku?.currentValue !== changes.sku?.previousValue ||
        changes.equipmentFlNumber?.currentValue !== changes.equipmentFlNumber?.previousValue
      ) &&
      this.equipmentFlNumber &&
      this.sku;
  }

  public updatedPricesAfterChangeSoldTo(value: boolean) {
    if (value) {
      this.pricesLoaded = false;
      this.setPricesAndAvailabilities();
    }
  }

  private setPricesAndAvailabilities(): void {
    if (this.isSapP40Enabled) {
      let productPrices: IAbstractProductPrices;
      let productAvailabilities: IAbstractProductAvailabilityResource;
      if (this.currentCart?.id) {
        this.catalogFacade.getAbstractProductPricesAndAvailabilitiesFromSap(this.sku, this.currentCart.id)
          .pipe(take(1))
          .subscribe({
            next: ({included}) => {
              this.pricesLoaded = true;
              productPrices = included.find(item => item.type === EGlueResource.ABSTRACT_PRODUCT_PRICES);
              productAvailabilities = included.find(item => item.type === EGlueResource.ABSTRACT_PRODUCT_AVAILABILITIES);
              if (
                !!productPrices && productPrices.attributes.loadedSuccessfully
                && !!productAvailabilities && productAvailabilities.attributes.loadedSuccessfully
              ) {
                this.loadedSuccessfully = true;

                this.setListPrice(productPrices);
                this.setYourPrice(productPrices);
                this.setProductAvailabilityAttributes(productAvailabilities);
              } else {
                this.handlePriceOrAvailabilityLoadingFailure(productPrices, productAvailabilities);
              }
            },
            error: () => {
              this.pricesLoaded = true;
              this.handlePriceOrAvailabilityLoadingFailure(productPrices, productAvailabilities);
            },
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isUserSparePartsViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.SPCViewer);
  }

  private handlePriceOrAvailabilityLoadingFailure(
    productPrices: IAbstractProductPrices,
    productAvailabilities: IAbstractProductAvailabilityResource,
  ): void {
    this.loadedSuccessfully = false;

    if (productPrices) {
      this.setListPrice(productPrices);
      this.setYourPrice(productPrices);
    }
    if (productAvailabilities) {
      this.setProductAvailabilityAttributes(productAvailabilities);
    }
    this.translate.get([
      'spare-parts.pdp-pricing-availability-failed-loading-msg',
      'spare-parts.pdp-pricing-availability-failed-loading-msg-type',
    ]).pipe(take(1))
      .subscribe((translations) => {
        const message = translations['spare-parts.pdp-pricing-availability-failed-loading-msg'];
        const type = translations['spare-parts.pdp-pricing-availability-failed-loading-msg-type'];
        this.configurationFacade.setAlert({message, type});
      });
  }

  private setListPrice(productPrices: IAbstractProductPrices): void {
    const prices = productPrices.attributes.prices;
    const guestPrice = this.priceUtils.getGuestPrice(prices);
    const defaultPrice = this.priceUtils.getDefaultPrice(prices);
    // assume the default price === list price if it's the only price returned in the response
    this.listPrice = guestPrice ?? defaultPrice;
  }

  private setYourPrice(productPrices: IAbstractProductPrices): void {
    const prices = productPrices.attributes.prices;
    const defaultPrice = this.priceUtils.getDefaultPrice(prices);
    this.yourPrice = defaultPrice ?? {} as IPrice;
  }

  private setProductAvailabilityAttributes(productAvailabilities: IAbstractProductAvailabilityResource): void {
    this.isExchangeable = productAvailabilities.attributes.exchangeableItem;
    this.isEligible = productAvailabilities.attributes.eligible;
    this.currentlyInStock = productAvailabilities.attributes.availability;
  }

  /**
   *
   * @private
   * Method for creating warning window
   */
  private showWarning(): void {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'issue-with-data.title',
      messages: [{
        key: 'issue-with-data.message',
      }],
    });
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Check if product is discontinued (sap_p40_sales_status is '01' or '02')
   *
   * @return {boolean}
   */
  isProductDiscontinued(): boolean {
    switch (this.abstractProduct?.attributes?.sap_p40_sales_status) {
      case '01':
        return true;
      case '02':
        return true;
      default:
        return false;
    }
  }

  /**
   * Check if product has subsequent materials
   *
   * @return {boolean}
   */
  areSubsequentMaterialsAvailable(): boolean {
    return !!(this.abstractProduct?.attributes?.sap_p40_sales_status === '02'
      && this.abstractProduct?.attributes?.sap_p40_subsequent_material);
  }

  /**
   * Get subsequent materials for product
   * Handle multiple cases: no subsequent material, one subsequent material, multiple subsequent materials
   * Add leading zeros to subsequent material number if needed
   *
   * @return {string[]}
   */
  getSubsequentMaterials(): string[] {
    if (this.areSubsequentMaterialsAvailable()) {
      if (this.abstractProduct.attributes.sap_p40_subsequent_material.includes(',')) {
        return this.abstractProduct.attributes.sap_p40_subsequent_material.split(',').map(subsequentMaterial =>
          subsequentMaterial.padStart(8, '0'),
        );
      }

      return [this.abstractProduct.attributes.sap_p40_subsequent_material.padStart(8, '0')];
    }

    return [];
  }
}
