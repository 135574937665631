import {
  IFeatureProductPrices,
  IFeatureProductsData,
  IFeatureProductWithImage,
} from '../models/featured-products.models';
import { IProductImage, IProductLabel } from '../models/abstract-product.models';

export class FeaturedProductsUtils {
  static getDisplayableProduct(
    featuredProduct: IFeatureProductsData,
    featuredProductInclude: Array<IProductImage | IFeatureProductPrices | IProductLabel>): IFeatureProductWithImage {
    {
      const imageSetComplete = featuredProductInclude.find(includeData => includeData.id === featuredProduct.id && includeData.type === 'abstract-product-image-sets');
      let imageUrlSmall = '';
      let imageUrlLarge = '';
      if (imageSetComplete) {
        const imgSet = (imageSetComplete as IProductImage).attributes.imageSets[0].images[0];
        imageUrlSmall = imgSet.externalUrlSmall;
        imageUrlLarge = imgSet.externalUrlLarge;
      }
      const priceSetComplete = featuredProductInclude.find(includeData => includeData.id === featuredProduct.id && includeData.type === 'abstract-product-prices');
      let priceProduct = 0;
      let pricesProduct = [];
      if (priceSetComplete) {
        const priceToAssign = (priceSetComplete as IFeatureProductPrices);
        priceProduct = priceToAssign.attributes.price;
        pricesProduct = priceToAssign.attributes.prices;
      }
      return {
        type: featuredProduct.type,
        id: featuredProduct.id,
        attributes: {
          sku: featuredProduct.attributes.sku,
          concreteSku: featuredProduct.attributes?.attributeMap?.product_concrete_ids,
          externalUrlLarge: imageUrlLarge,
          externalUrlSmall: imageUrlSmall,
          name: featuredProduct.attributes.name,
          price: priceProduct,
          prices: pricesProduct,
          keywords: featuredProduct.attributes?.attributes.mcm_keywords as string,
        },
        relationships: featuredProduct.relationships
      };
    }
  }
}
