import { Component, Input } from '@angular/core';
import { IContractParams } from 'src/app/models/catalog.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help-me-choose',
  templateUrl: './help-me-choose.component.html',
  styleUrls: ['./help-me-choose.component.scss'],
})
export class HelpMeChooseComponent {
  helpMeChooseImg: string = environment.helpMeChooseImg;

  @Input() layoutType: string
  @Input() contractParams: IContractParams;

  /**
   * Method to get contract params
   * @returns {IContractParams | null}
   */
  getContractParams(): IContractParams | null {
    if (!this.contractParams || Object.keys(this.contractParams).find(key => this.contractParams[key] === '')) {
      return null;
    }

    return this.contractParams;
  }
}
