import { Component, OnDestroy, OnInit } from '@angular/core';
import { ILanguage } from '../../models/settings.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { takeUntil } from 'rxjs/operators';
import { IContractParams } from '../../models/catalog.models';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
  title: string;
  languages: ILanguage[];
  store: any;
  currentLang: string;
  showModalConfirmStoreSelection: boolean;
  contractParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': ''
  };

  private unsubscribe$ = new Subject<void>();
  private isLoggedIn: boolean;

  constructor(
    private configFacade: ConfigurationFacade,
    private router: Router,
    private translate: TranslateService,
    private customerFacade: CustomerFacade,
    private authorizationFacade: AuthorizationFacade,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      this.contractParams['fl-number'] = params['fl-number'];
      this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'];
    });

    this.isLoggedIn = false;
    this.currentLang = this.configFacade.getCurrentLanguage();
    this.showModalConfirmStoreSelection = false;

    this.selectStoreSubscription();
    this.selectUserLoggedIn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToStoreSelection(): void {
    if (this.isLoggedIn) {
      this.showModalConfirmStoreSelection = true;
    } else {
      this.confirmStoreSelection();
    }
  }

  useLanguage(language: ILanguage): void {
    // parse the current URL minus the :lang key at the beginning
    const reg = this.configFacade.getLanguageRegex(false);
    const url = this.router.url;
    const parsedUrl = this.router.parseUrl(url);
    const primary = parsedUrl.root.children.primary;
    if (primary && primary.segments[0].path.match(reg)) {
      primary.segments.splice(0, 1);
    }
    const serializedUrl = this.router.serializeUrl(parsedUrl);

    // set the current location URL with the newly selected locale code at the start
    this.translate.use(language.id).subscribe(() => {
      const langParam = this.configFacade.buildLangParam(this.store.marketCode, language.code);
      const catalogParam: string = 'catalog';
      if(serializedUrl.includes(catalogParam)) {
        let url = `${window.location.origin}/${langParam}/${catalogParam}`;
        this.setWindowHref(
          this.contractParams['fl-number'] && this.contractParams['rel-product-sysivk']
            ? `${url}?fl-number=${this.contractParams['fl-number']}&rel-product-sysivk=${this.contractParams['rel-product-sysivk']}`
            : url
        );
      } else {
        this.setWindowHref(`${window.location.origin}/${langParam + serializedUrl}`);
      }
    });
  }

  confirmStoreSelection(): void {
    this.router.navigate(['/']).then();
  }

  setWindowHref(newHref: string): void {
    window.location.href = newHref;
  }

  // private methods

  private selectStoreSubscription(): void {
    this.customerFacade.selectStore().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(store => {
      if (store) {
        this.store = store;
        this.languages = store.languages;
      }
    });
  }

  private selectUserLoggedIn(): void {
    this.authorizationFacade.selectIsUserLoggedIn().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
  }
}
