<ng-container *ngIf="config.isGroup; else configNoGroup">
  <div class="configuration group" *ngIf="config.hasVisibleParameters">
    <span class="configuration__title" [ngClass]="{'top': config.hasVisibleParameters}">{{ config.description }}</span>
    <ng-container *ngFor="let member of config.members">
      <app-configuration
          [config]="member"
          [disableInput]="disableInput"
          (changed)="changed.emit($event)">
      </app-configuration>
    </ng-container>
  </div>
</ng-container>
<ng-template #configNoGroup>
  <div class="configuration no-group" *ngIf="config.properties.hidden === 'no'">
    <span class="configuration__title">
      {{ config.description }}
      <app-tooltip
          class="accordion__category-details__info-icon"
          link="icon"
          text="{{ ('config-tooltip.' + config.name | translate) !== 'config-tooltip.' + config.name ? ('config-tooltip.' + config.name | translate) : '' }}">
      </app-tooltip>
    </span>
    <app-select-box
        *ngIf="['dropdown', 'radio'].includes(config.properties.guitype)"
        class="configuration__option"
        [attr.data-test]="'dropdown-' + config.name"
        [options]="config.domain.elements"
        [inputName]="config.name"
        [isSelectedFromOptions]="true"
        [optionLabelKey]="'description'"
        [inputDescription]="config.description"
        [disabled]="disableInput"
        (selected)="changed.emit($event)">
    </app-select-box>
    <app-textfield
        *ngIf="config.properties.guitype === 'text'"
        class="configuration__option" [attr.data-test]="'text-' + config.description"
        [domain]="config.domain"
        [inputName]="config.name"
        [modelValue]="config.value"
        [inputDescription]="config.description"
        [readOnly]="disableInput"
        [onKeyTimeout]="1000"
        (fieldFilled)="changed.emit($event)">
    </app-textfield>
  </div>
</ng-template>
