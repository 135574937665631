<ng-template #loadingTemplate>
  <div class="loading-animation position-center"></div>
</ng-template>
<div class="accordion service-agreements__table" [attr.data-test]="'desc-loadingForAccordion'" *ngIf="!loading else loadingTemplate">
  <div class="accordion__title service-agreements__header">
    <div
        *ngFor="let header of headers"
        class="{{header.class}} service-agreements__title"
        [ngClass]="!header.showOnMobile ? 'hidden-mobile': ''">
      {{ header.label | translate }}
      <app-sort-by
          [sort]="header.sortOptions"
          [cssClass]="'margin-0'"
          [current]="currentSort"
          [hasTitle]=false
          (selected)="sort($event)">
      </app-sort-by>
    </div>
  </div>
  <div class="agreements__table">
    <div class="table-items">
      <app-accordion
          *ngFor="let agreement of agreementsToWorkWith | filterByAttributes: filters | searchFilter: searchValue:searchExcludedAttributes"
          [manualOpen]="tabsOpened.includes(agreement.attributes?.siemensEquipmentId)">
        <ng-container ngProjectAs="[slot][header]">
          <div
              class="accordion__title icon-arrow-down"
              [ngClass]="{'disabled': isSoldToItemInArray(agreement.attributes?.siemensEquipmentId)}"
              (click)="toggleTab(agreement.attributes?.siemensEquipmentId)"
              [attr.data-test]="'desc-iconArrowDown'">
            <span class="agreement-info functional-location-id">
              <span class="header__label" [attr.data-test]="'desc-myContractsFunctionalLocation'">{{ 'my-contracts.functional-location' | translate }}</span>
              {{ getAttribute(agreement, 'siemensEquipmentId') }}
            </span>
            <span class="agreement-info modality">
              <span class="header__label" [attr.data-test]="'desc-myContractsModality'">{{ 'my-contracts.modality' | translate }}</span>
              {{ getAttribute(agreement, 'category') }}
            </span>
            <span class="agreement-info nameEnUs">
              <span class="header__label" [attr.data-test]="'desc-myContractsProductName'">{{ 'my-contracts.product-name' | translate }}</span>
              {{ getAttribute(agreement, 'nameEnUs') }}
            </span>
            <div class="agreement-info street">
              <span class="header__label" [attr.data-test]="'desc-myContractsEquipmentLocation'">{{ 'my-contracts.equipment-location' | translate }}</span>
              {{ getAttribute(agreement, 'siemensEquipmentAddress', 'street') }}
            </div>
            <div class="agreement-info state">
              <span class="header__label" [attr.data-test]="'desc-myContractsState'">{{ 'my-contracts.state' | translate }}</span>
              {{ getAttribute(agreement, 'siemensEquipmentAddress', 'city') }}
            </div>
            <span *ngIf="!isEquipment" class="agreement-info contractExpirationDate">
              <span class="header__label" [attr.data-test]="'desc-myContractsEnd'">{{ 'my-contracts.contract-end' | translate }}</span>
              {{ getAttribute(agreement, 'contractExpirationDate') }}
            </span>
            <span *ngIf="isEquipment" class="agreement-info contractName">
              <span class="header__label" [attr.data-test]="'desc-myContractsContractName'">{{ 'my-contracts.contract-name' | translate }}</span>
              {{ getAttribute(agreement, 'contractName') }}
            </span>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content"
               *ngIf="tabsOpened.includes(agreement.attributes?.siemensEquipmentId)">
            <app-contract-detail
                [isCartOperationInProgress]="isCartOperationInProgress"
                [loadingCartDataInProgress]="loadingCartDataInProgress"
                [agreement]="agreement"
                [currentCartId]="currentCartId"
                [cartItems]="cartItems"
                [productSku]="productSku"
                [needsAttention]="needsAttention"
                [soldToIdFromParams]="soldToId"
                [soldToInactive]="isSoldToItemInArray(agreement.attributes?.siemensEquipmentId)"
                [additionalData]="getAdditionalData(agreement.attributes?.siemensEquipmentId)"
                [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
                [isEquipment]="isEquipment"
                [selectableEquipment]="selectableEquipment"
                [isReorderPending]="isReorderPending"
                [companyRoles]="companyRoles">
            </app-contract-detail>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
