import { Component, Input } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';

import { StringUtils } from '../../../utils/string.utils';
import { ICategoryNode, ICurrentParams } from '../../../models/catalog.models';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { ETitleSources, ISeoPageData } from '../../../configurations/seo';
import { SeoFacade } from '../../../facades/seo.facade';

@Component({
  selector: 'app-category-tree-child',
  templateUrl: './category-tree-child.component.html',
  styleUrls: ['./category-tree-child.component.scss'],
})
export class CategoryTreeChildComponent {
  @Input() category: ICategoryNode;
  @Input() useNavigate: boolean = false;
  @Input() params: ICurrentParams;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private seoFacade: SeoFacade,
  ) {
  }

  /**
   * Method to select category
   * @param {ICategoryNode} category
   */
  selectCategory(category: ICategoryNode): void {
    this.analyticsService.setCategory(category.name);
    this.setSeoData(category);

    if (this.useNavigate) {
      const pageUrl: string = decodeURIComponent(this.getCurrentUrl());
      this.router.navigate([pageUrl], {
        queryParams: CategoryTreeChildComponent.setQueryParamsForUrlTree(this.parseCurrentUrlAddress(), category),
      }).then();
    }
  }

  /**
   * Method to get page URL
   * @param {ICategoryNode} category
   * @returns {string}
   */
  pageUrl(category: ICategoryNode): string {
    const currentUrl: string = this.getCurrentUrl();
    return (currentUrl.includes('/catalog') && category) ?
      `/catalog/${(CategoryTreeChildComponent.setUrlAndReplaceUrlName(category.name))}` : currentUrl;
  }

  /**
   * Method to check if category is selected
   * @param {ICategoryNode} category
   * @returns {boolean}
   */
  isSelected(category: ICategoryNode): boolean {
    return this.params.category === category.nodeId;
  }

  /**
   * Method to get category URL
   * @param {ICategoryNode} category
   * @returns {Params}
   */
  categoryUrl(category: ICategoryNode): Params {
    const urlTree: UrlTree = this.parseCurrentUrlAddress();
    if (this.getCurrentUrl().includes('/catalog')) {
      urlTree.queryParams.page = 1;
    } else {
      CategoryTreeChildComponent.setQueryParamsForUrlTree(urlTree, category);
    }
    return urlTree.queryParams;
  }

  /**
   * Method to set SEO data
   * @param {ICategoryNode} category
   */
  setSeoData(category: ICategoryNode): void {
    this.seoFacade.setMetaFromComponent({
      titleSource: ETitleSources.BREADCRUMBS,
      canonicalUrl: {
        rel: 'canonical',
        href: category?.canonicalUrl ? category.canonicalUrl : StringUtils.substringFromChar(this.router.url, '?'),
      },
    } as ISeoPageData);
  }

  /**
   * Method to set query params for URL tree
   * @param {UrlTree} urlTree
   * @param {ICategoryNode} category
   * @returns {Params}
   * @private
   */
  private static setQueryParamsForUrlTree(urlTree: UrlTree, category: ICategoryNode): Params {
    urlTree.queryParams.category = CategoryTreeChildComponent.setUrlAndReplaceUrlName(category?.name || '');
    urlTree.queryParams.page = 1;
    return urlTree.queryParams;
  }

  /**
   * Method to set URL and replace URL name
   * @param {string} name
   * @returns {string}
   * @private
   */
  private static setUrlAndReplaceUrlName(name: string): string {
    return StringUtils.toLowerCaseWithoutWhiteSpaceOrSlash(name, '-');
  }

  /**
   * Methot to get current URL
   * @returns {string}
   * @private
   */
  private getCurrentUrl(): string {
    return this.router.url.split('?')[0];
  }

  /**
   * Method to parse current URL address
   * @returns {UrlTree}
   * @private
   */
  private parseCurrentUrlAddress(): UrlTree {
    return this.router.parseUrl(this.router.url);
  }
}
