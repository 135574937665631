import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as ShopCartActions from '../../actions/shop-cart.actions';
import { State } from '../../reducers';
import { I18nService } from '../../services';
import { MarketingFacade } from '../../facades/marketing.facade';
import { StoreConfigurationFacade } from '../../facades/store-configuration.facade';
import {
  ICart,
  ICartBaseInformation,
  ICartRule,
  ICartUpdateItemData
} from '../../models/cart.models';
import { AppUtils } from '../../utils/app.utils';
import { CartUtils } from '../../utils/cart.utils';
import { CpqUtils } from '../../utils/cpq.utils';
import { MathUtils } from '../../utils/math.utils';
import { IconType } from '../../models/settings.model';
import { EFeatureToggles, EStoreFeatures, EStoreTypes } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { AnalyticsService } from '../../analytics/analytics.service';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
})
export class MiniCartComponent implements OnInit, OnDestroy {
  @Input() haveAccess: boolean;
  @Input() rfqActive: boolean;
  @Input() isRfqOnly: boolean;
  @Input() isSapP40Enable: boolean;

  isOpen: boolean;
  iconType = IconType;

  isMultiCartActive: boolean = false;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isCpqEnabled: boolean = false;
  isSparePartsEnabled: boolean = false;
  isExcludeTaxActive: boolean = false;

  isConfigurationPage: boolean = false;
  isCartOperationInProgress: boolean = false;
  isLastAddedItemLoaded: boolean;
  showNewItemAddedNotification: boolean;
  isDeliveryDetailsOrCheckoutPage: boolean = false;
  isOrderReviewPage: boolean = false;
  inProcessCartId: string;

  addNicknameModalActive: boolean = false;
  deleteQuoteModalActive: boolean = false;
  deleteCartModalActive: boolean = false;
  showPriceDisclaimer: boolean;

  isCartEmpty: boolean = true;
  cartRules: Array<ICartRule>;
  currentCart: ICart;
  usersCarts: ICartBaseInformation[];
  itemsInCart: any[];
  itemName: string;

  updateItemInCartData: any;
  updateItemInCartData$: Observable<ICartUpdateItemData>;

  currency: string;
  totalPrice: number;
  priceToPay: number;
  minimumOrderValue = 0;

  hasNickname: boolean = false;
  currentCartName: string = '';
  currentCartName$ = new ReplaySubject<string>();

  redirectToHomepageOnCartOperationFinish: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private miniCartRef: ElementRef,
    private router: Router,
    private store: Store<State>,
    private marketingFacade: MarketingFacade,
    private i18nService: I18nService,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private configurationFacade: ConfigurationFacade,
    private analyticsService: AnalyticsService,
  ) {
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (!this.miniCartRef.nativeElement.contains(event.target)) {
      this.closeMiniCart();
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((_: NavigationEnd) => {
      this.isConfigurationPage = this.router.url.includes('/service-configuration');
      this.isDeliveryDetailsOrCheckoutPage = this.router.url.includes('delivery-details') || this.router.url.includes('order-approval');
      this.isOrderReviewPage = this.router.url.includes('order-review');
      if (this.router.url.includes('lastCartId')) {
        this.inProcessCartId = this.router.parseUrl(this.router.url).toString().split('=')[1];
        this.usersCarts.forEach(cart => {
          if (cart.id === this.inProcessCartId) {
            cart.approverId = 'notNull';
          }
        });
      }
    });

    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isMultiCartActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.MULTI_CART);
    this.isExcludeTaxActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;

    this.selectMinimumOrderValue();
    this.selectUsFeatures();
    this.selectMiniCartState();
    this.selectCart();
    this.selectCartItemsWithDetails();
    this.selectCartRules();
    this.selectLastAddedItemName();
    this.selectUserCartsBaseInformation();
    this.selectCartOperationInProgress();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
    });
  }

  closeMiniCart(): void {
    this.isOpen = false;
    this.marketingFacade.toggleMiniCart(this.isOpen);
  }

  isContractInCart(): boolean {
    return this.currentCart?.attributes?.hasContractInCart;
  }

  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, this.isCartEmpty, this.currentCart, this.itemsInCart);
  }

  checkWorkflowInformation(): boolean {
    if (this.isUsStore || this.isCaStore) {
      return this.isContractInCart();
    } else {
      return !this.rfqActive || (this.isRfqOnly && CartUtils.isSetPricesForAllItems(this.itemsInCart));
    }
  }

  getLabelForCartButton(): string {
    return this.isContractInCart() ? 'mini-cart.go-to-quote' : 'mini-cart.go-to-cart';
  }

  deleteQuote(): void {
    this.marketingFacade.deleteCpqCart(this.currentCart.id);

    if (this.isConfigurationPage) {
      this.router.navigate([this.i18nService.getCurrentLocale()]);
    }
  }

  /**
   * This method is for deleting cart.
   * At first, this method is checking if this cart had a approverId via getNotInProcessCartsOrNull().
   */
  deleteCart(): void {
    const deletingCurrentCartId = this.currentCart.id;
    this.marketingFacade.deleteCart(this.currentCart.id);
    const newDefaultCartId = this.getNotInProcessCartsOrNull()
    if (!newDefaultCartId) {
      this.marketingFacade.createEmptyCart();
    } else if (!(deletingCurrentCartId === this.currentCart.id && this.usersCarts.length === 1)) {
      this.usersCarts = this.usersCarts.filter(cart => cart.id !== this.currentCart.id);
      this.marketingFacade.switchDefaultCart(newDefaultCartId);
    }

    if (this.isDeliveryDetailsOrCheckoutPage || this.isOrderReviewPage) {
      this.router.navigate(['/shop-cart']);
      this.closeMiniCart();
    }
  }

  /**
   * This method is for now only for JP store. Only JP store has a approverId, because every cart need to be approved.
   * @returns {string}
   */
  getNotInProcessCartsOrNull(): string {
    for (let cart of this.usersCarts) {
      if ((cart.approverId === null) && (cart.id !== this.currentCart.id)) {
        return cart.id;
      }
    }
    return null;
  }

  updateCartItemQuantity(data: any): void {
    const {oldQuantity, newQuantity, itemId, itemName} = data;
    const updatedQty = parseInt(newQuantity, 10);

    if (!MathUtils.checkIfNumeric(updatedQty)) {
      return;
    }

    const quantityDiff = +newQuantity - +oldQuantity;
    if (quantityDiff > 0) {
      this.trackIncreaseQuantity(itemId, itemName, quantityDiff);
    } else if (quantityDiff < 0) {
      this.trackDecreaseQuantity(itemId, itemName, -quantityDiff);
    }

    this.store.dispatch(ShopCartActions.setUpdateItemInCartData({quantity: updatedQty}));

    this.store.dispatch(ShopCartActions.updateItemInCartLoadCartItems({
      cartId: this.currentCart.id,
      itemId: itemId,
      updateItemInCartData: {
        'data': {
          'type': 'items',
          'attributes': {
            'quantity': newQuantity,
          },
        },
      },
    }));
  }

  private selectMinimumOrderValue(): void {
    this.storeConfigurationFacade.selectMinimumOrderValue().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.minimumOrderValue = state;
    });
  }

  private selectCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      if (!state) {
        this.totalPrice = 0;
        this.currency = AppUtils.getCurrentStore().storeDefaultCurrency;
        return;
      }

      this.currentCart = state;
      this.totalPrice = this.isCpqEnabled
        ? this.currentCart.attributes.totals.cpqNetPriceTotal
        : this.currentCart.attributes.totals.subtotal;
      this.priceToPay = this.currentCart.attributes.totals.priceToPay;
      this.currency = this.currentCart.attributes.currency;
      this.currentCartName = CartUtils.getCartName(this.currentCart);
      this.hasNickname = !MathUtils.checkIfNumeric(this.currentCart.attributes.name);
      this.currentCartName$.next(this.hasNickname ? this.currentCartName : '');
    });
  }

  selectUserCartsBaseInformation(): void {
    this.marketingFacade.selectCartsBaseInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(baseCartsInfo => {
        this.usersCarts = baseCartsInfo;
      });
  }

  private selectCartItemsWithDetails(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(items => {
      this.itemsInCart = this.isCpqEnabled && this.isContractInCart() && items
        ? CpqUtils.mergeCpqItems(items)
        : items;
    });
  }

  private selectCartRules(): void {
    this.marketingFacade.selectCartRules().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartRules => {
      this.cartRules = cartRules;
    });
  }

  private selectMiniCartState(): void {
    this.marketingFacade.selectMiniCartState().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isOpen = this.router.url.includes('shop-cart') ? false : state.isMiniCartOpen;
      this.isCartEmpty = state.isCartEmpty;
      this.showNewItemAddedNotification = state.showNewItemAddedNotification;

      if (this.showNewItemAddedNotification) {
        this.configurationFacade.clearNotifications();
      }
    });
  }

  private selectLastAddedItemName(): void {
    this.marketingFacade.selectLastAddedItemName().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(item => {
      this.itemName = item;
      this.isLastAddedItemLoaded = true;
    });
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.isCartOperationInProgress = inProgress;

      if (this.redirectToHomepageOnCartOperationFinish && !this.isCartOperationInProgress) {
        this.redirectToHomepageOnCartOperationFinish = false;
        this.router.navigate([this.i18nService.getCurrentLocale()]);
        this.closeMiniCart();
      }
    });
  }

  isMinicartNotAccessible(): boolean {
    return !this.haveAccess || ((this.isUsStore || this.isCaStore) && !this.isSapP40Enable);
  }

  isMinicartAccessible(): boolean {
    return this.haveAccess
      && (((this.isUsStore || this.isCaStore) && this.isSapP40Enable) || (!this.isUsStore && !this.isCaStore));
  }


  private trackIncreaseQuantity(itemId: string, itemName: string, quantityDiff: number): void {
    this.analyticsService.setProducts({
      sku: itemId,
      name: itemName,
      quantity: quantityDiff,
    });

    this.analyticsService.trackCart('cart.add');
  }

  private trackDecreaseQuantity(itemId: string, itemName: string, quantityDiff: number): void {
    this.analyticsService.setProducts({
      sku: itemId,
      name: itemName,
      quantity: quantityDiff,
    });

    this.analyticsService.trackCart('cart.remove');
  }
}
