<div class="container">
  <div class="row">
    <ng-container *ngIf="!successPageDataLoaded && successPageRefreshed">
      <div class="col-12 content__center">
        <button class="button button-back" routerLink="/my-orders">{{ 'order-summary.back-button' | translate }}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="!successPageDataLoaded && !successPageRefreshed">
      <div class="col-12 content__center">
        <div class="loading-animation"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="successPageDataLoaded">
      <div class="col-12 col-lg-7 order-summary">
        <h3 class="order-summary__title" [attr.data-test]="'title-orderSummary'">
          {{ 'order-summary.summary' | translate }}
        </h3>
        <h6 class="summary-step__title" [attr.data-test]="'title-payment'">
          {{ 'order-summary.payment' | translate }}
        </h6>
        <div class="summary-step__content">
          <hr/>
          <img class="summary-payment__img" src="assets/img/invoice_ico.png" alt="invoice-icon"/>
          <p class="summary-payment__text" [attr.data-test]="'desc-payment'">
            {{ 'order-summary.invoice' | translate }}
          </p>
          <div class="summary-payment__address">
            <ng-container *ngIf="billingAddress">
              <p [attr.data-test]="'desc-paymentAddress'">
                {{ billingAddress.address1 }}
              </p>
              <p [attr.data-test]="'desc-paymentZipCodeAndCity'">
                {{ billingAddress.zipCode }} {{ billingAddress.city }}
              </p>
              <p [attr.data-test]="'desc-paymentCountry'">
                {{ billingAddress.country }}
              </p>
            </ng-container>
          </div>
          <div *ngIf="isInStore">
            <strong>
              {{ 'order-summary.payment_conditions' | translate }}:
            </strong>
            {{ 'order-summary.payment_info' | translate }}
          </div>
          <hr/>
        </div>
        <h6 *ngIf="taxNumber" class="summary-step__title" [attr.data-test]="'title-taxNumber'">
          {{ 'order-summary.billing-tax-number' | translate }}
        </h6>
        <div *ngIf="taxNumber" class="summary-step__content">
          <hr/>
          <p [attr.data-test]="'desc-taxNumber'">
            {{ taxNumber }}
          </p>
          <hr/>
        </div>
        <h6 class="summary-step__title" [attr.data-test]="'title-delivery'">
          {{ taxNumber ? '3. ' : '2. '}}{{ isExcludeTaxActive() ? ('order-summary.review-delivery-without-shipping' | translate) : ('order-summary.review-delivery' | translate) }}
        </h6>
        <div class="summary-step__content">
          <table class="order-summary__table">
            <tr>
              <th scope="col">
                <div class="delivery-address">
                  <div class="row">
                    <div class="col">
                      <p [attr.data-test]="'desc-customerName'">
                        {{ shippingAddress.firstName }} {{ shippingAddress.lastName }}
                      </p>
                      <p [attr.data-test]="'desc-shippingAddress1'">
                        {{ shippingAddress.address1 }}
                      </p>
                      <p [attr.data-test]="'desc-shippingAddress2'">
                        {{ shippingAddress.zipCode + ' ' + shippingAddress.city +
                           (shippingAddress.address2 !== '' ? ', ' + shippingAddress.address2 : '') }}
                      </p>
                      <p [attr.data-test]="'desc-shippingCountry'">
                        {{ shippingAddress.country }}
                      </p>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr *ngFor="let item of items; let i = index" [attr.data-test]="'desc-item-' + item.attributes.name">
              <td class="delivery-address__items">
                <span *ngIf="i === 0" class="order-summary__subtitle" [attr.data-test]="'desc-orderSummary'">
                  {{ 'order-summary.summary' | translate }}
                </span>
                <div class="row">
                  <div class="delivery__item col-md-3">
                    <div class="aspect-ratio-1x1 aspect-ratio--cover">
                      <img src="{{ getItemPicture(item.id) }}" alt="{{ item.attributes.name }}"/>
                    </div>
                  </div>
                  <div class="delivery__item col-md-3">
                    <p [attr.data-test]="'desc-name'">
                      {{ item.attributes.name }}
                    </p>
                    <p [attr.data-test]="'desc-price'">
                      {{ getItemPrice(item.id) | currency: currency }}
                      <span *ngIf="isExcludeTaxActive()" class="product-price-ex-gst-container">
                        ({{ 'order-summary.ex_gst' | translate }})
                      </span>
                    </p>
                    <p class="items__quantity">
                      <span [attr.data-test]="'label-quantity'">
                        {{ 'order-summary.quantity' | translate }}
                      </span>
                      <span [attr.data-test]="'desc-quantity'">
                        {{ itemsQuantity[i].attributes.quantity }}
                      </span>
                    </p>
                  </div>
                  <div class="delivery__item col-md-2 justify-content-end"></div>
                  <div *ngIf="!isExcludeTaxActive()" class="delivery__item col-md-4 justify-content-end">
                    <p class="delivery__item--normal-delivery"
                       id="{{ 'method-'+(i) }}"
                       for="{{ 'method-'+(i) }}"
                       [attr.data-test]="'desc-normalDelivery'">
                      {{ 'order-approve.normal-delivery' | translate }}
                    </p>
                    <p class="delivery__item--free-of-charge" [attr.data-test]="'desc-freeOfCharge'">
                      {{ 'order-approve.free-of-charge' | translate }}
                    </p>
                    <p class="delivery__item--days" *ngIf="item.attributes.attributes.delivery_time">
                      <span [attr.data-test]="'label-deliveryTime'">
                        {{ 'shop-cart.estimatedArrivalLabel' | translate }}
                      </span>
                      <span [attr.data-test]="'desc-deliveryTime'">
                        {{ item.attributes.attributes.delivery_time }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12 col-lg-4 offset-md-1 summary__side-menu">
        <span class="icon-check icon-check__circle"></span>
        <ng-container *ngIf="showApproverPersonalMessage()">
          <p class="summary__text" [attr.data-test]="'desc-personalMessage'">
            {{ 'order-summary.approver-personal-success' | translate }}
            <span [attr.data-test]="'desc-orderId'">{{ orderId }}.</span>
          </p>
        </ng-container>
        <ng-container *ngIf="showApproverApprovalMessage()">
          <p class="summary__text" [attr.data-test]="'desc-approvalMessage'">
            {{ 'order-summary.approver-approval-success'| translate: {name: customerName} }}
            <span [attr.data-test]="'desc-orderId'">{{ orderId }}.</span>
          </p>
        </ng-container>
        <div class="row">
          <div *ngIf="!isExcludeTaxActive(); else cartTotalsExcludeTax" class="col-12 summary__order-total">
            <h6 class="summary__order-total--title" [attr.data-test]="'title-orderTotal'">
              {{ 'order-approve.order-total' | translate }}
            </h6>
            <p class="summary__order-total--text">
              <span [attr.data-test]="'desc-priceInfo'">
                {{ 'checkout-delivery-details.price-info' | translate }}
              </span>
              <br/>
              <span [attr.data-test]="'desc-freeShipping'">
                {{ 'checkout-delivery-details.free-shipping' | translate }}
              </span>
            </p>
            <label class="summary__order-total--price" [attr.data-test]="'label-totalPrice'">
              {{ getTotalPrice() | currency: currency }}
            </label>
          </div>
          <ng-template #cartTotalsExcludeTax>
            <div class="totals-container">
              <p class="totals-labels">
                {{ 'order-approve.sub_total' | translate }}<br>
                {{ 'order-approve.shipping' | translate }}<br>
                {{ 'order-approve.gst' | translate: {tax: getTaxPercent()} }}
              </p>
              <p class="totals-prices">
                {{ getSubTotal() | currency:currency }}<br>
                {{ getShipmentTotal() | currency:currency }}<br>
                {{ getTaxTotal() | currency:currency }}
              </p>
            </div>
            <hr style="width: 100%; margin: 0 30px; border: 1px solid #3B3B3B; opacity: 0.3">
            <div class="totals-container order-total">
              <p class="totals-labels">
                <span class="totals-total-value">{{ 'order-approve.total' | translate }}</span><span class="totals-include-gst"> ({{ 'order-approve.include_gst' | translate }})</span>
              </p>
              <p class="totals-prices">
                <span class="totals-total-value">{{ getGrandTotal() | currency:currency }}</span>
              </p>
            </div>
          </ng-template>
        </div>
        <button class="button button-back" routerLink="/my-orders" [attr.data-test]="'button-backToOrders'">
          {{ 'order-summary.back-button' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
