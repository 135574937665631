import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform
{
  transform(
    address1?: string,
    address2?: string,
    zipCode?: string,
    city?: string,
  ): string {
    const formattedAddress2 = address2 ? `, ${address2}` : '';

    return address1 ? `${address1}, ${zipCode} ${city}${formattedAddress2}` : '';
  }
}
