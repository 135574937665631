import { Component, Input, OnInit } from '@angular/core';
import { IHybrisOrder } from '../../../../../../models/order.models';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { CatalogFacade } from '../../../../../../facades/catalog.facade';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConfigurationFacade } from '../../../../../../facades/configuration.facade';
import { TranslateService } from '@ngx-translate/core';
import { AppUtils } from '../../../../../../utils/app.utils';
import { CustomerFacade } from '../../../../../../facades/customer.facade';

@Component({
  selector: 'app-spare-part-hybris-item-details',
  templateUrl: './spare-part-hybris-item-details.component.html',
  styleUrls: ['./spare-part-hybris-item-details.component.scss'],
})
export class SparePartHybrisItemDetailsComponent implements OnInit {
  @Input() orderDetails: IHybrisOrder;
  @Input() isOpen: boolean;

  teamplayFleetDataLoading: boolean = false;
  isBusinessPartner$: Observable<boolean> = new Observable<boolean>();

  addresses: any[] = [
    {
      type: 'shipTo',
      translationKey: 'ship-to-address',
    },
    {
      type: 'soldTo',
      translationKey: 'sold-to-address',
    },
    {
      type: 'billTo',
      translationKey: 'bill-to-address',
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private catalogFacade: CatalogFacade,
    private configurationFacade: ConfigurationFacade,
    private translate: TranslateService,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.isBusinessPartner$ = this.customerFacade.isBusinessPartner();
  }

  redirectToOrderDetails(orderId: string): void {
    this.router.navigate([`/hybris-order-details/${orderId}`]);
  }

  formatIfMissingValue(attributeValue: string): string {
    return !attributeValue || attributeValue === 'empty' ? '' : attributeValue;
  }

  openInTeamplayFleet(): void {
    this.teamplayFleetDataLoading = true;
    this.catalogFacade.getInstallBaseSystem(this.orderDetails.attributes.equipment).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: (installBaseSystem) => {
        let url = environment.teamplayFleetUrl + '/equipmentDetails.html' +
          '?materialNo=' + installBaseSystem.data[0].attributes.materialNumber +
          '&serialNo=' + installBaseSystem.data[0].attributes.serialNumber +
          '&country=' + AppUtils.getCurrentStore().storeId +
          '&tab=info' +
          '&search=' + installBaseSystem.data[0].attributes.contractNumber;
        window.open(url, '_blank');
        this.teamplayFleetDataLoading = false;
      },
      error: err => {
        if (err === 404) {
          this.configurationFacade.setAlert({
            message: this.translate.instant('spare-part-item-details.select-teamplay-fleet-error'),
            type: 'error',
          });
        }
        this.teamplayFleetDataLoading = false;
      },
    });
  }
}
