<div class="container order-details">
  <div [appLoadingAnimation]="!isLoaded" class="app-loading">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <ng-container *ngIf="orderDetails">
          <div class="page-header">
            <h3 class="page-title">
              {{ 'order-tracking-history.order-details' | translate }}
            </h3>
            <div class="page-row">
              <h6 class="order-id">
                {{ 'order-tracking-history.status-of-order' | translate }} {{ orderDetails.id | removeLeadingZero }}
              </h6>
              <div class="action-link">
                <a class="button button--link button--back-to-purchase-activity"
                   (click)="redirectBackToPurchaseActivity()">
                  <span class="icon-arrow-left color-orange"></span>
                  {{ 'spare-parts-order-details-page.back-to-purchase-activity' | translate }}
                </a>
              </div>
            </div>
            <div class="order-status">
              <span class="order-icon" [ngClass]="getIcon()"></span>
              <strong>
                {{ 'spare-parts-order-details-step-bar.status-bar-order-'
              + getStatus(orderDetails.attributes?.stateDisplayName) + '-title' | translate }}
              </strong>
            </div>
          </div>
          <div class="order-steps-bar">
            <app-steps-bar
                [scenarioType]="scenarioType"
                [currentStatus]="orderDetails.attributes?.stateDisplayName"
                [comment]="orderDetails.attributes?.cancelReason">
            </app-steps-bar>
          </div>
          <div class="order-accordion">
            <app-accordion [manualOpen]="orderDetailsAccordion">
              <ng-container ngProjectAs="[slot][header]">
                <div class="accordion__header accordion__title icon-arrow-down"
                     (click)="toggleOrderDetailsAccordion()"
                     [attr.data-test]="'desc-toggleOrderedItemsAccordion'">
                  <div class="accordion__header--table">
                    <table class="table table--ordered-items">
                      <thead>
                        <tr>
                          <th>{{ 'order-thank-you-page.order' | translate }}</th>
                          <th>{{ 'order-thank-you-page.ordered-on' | translate }}</th>
                          <th>{{ 'order-thank-you-page.total-quantity' | translate }}</th>
                          <th>{{ 'order-thank-you-page.shipping-cost' | translate }}</th>
                          <th>{{ 'order-thank-you-page.total-sum' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="orderDetails">
                          <td>{{ orderDetails.id | removeLeadingZero }}</td>
                          <td>{{ orderDetails.attributes?.createdAt | formatDate }}</td>
                          <td>
                            {{ orderDetails.attributes?.itemsTotalNumber }}
                            {{ getTotalItemTranslation(orderDetails.attributes.itemsTotalNumber) | translate }}
                          </td>
                          <td *ngIf="orderDetails.attributes.shipments?.length > 0">
                            {{ orderDetails.attributes.shipments[0].defaultGrossPrice | currency:orderDetails.attributes?.currencyIsoCode }}
                          </td>
                          <td>
                            {{ orderDetails.attributes.totals?.grandTotal | currency: orderDetails.attributes?.currencyIsoCode }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-container>
              <ng-container ngProjectAs="[slot][panel]">
                <div class="accordion__content">
                  <div class="row margin-0">
                    <div class="item-section col-12 col-md-7 col-lg-8">
                      <ng-container *ngFor="let item of orderDetails.attributes?.items">
                        <div class="item-details--row">
                          <div class="item-details--content">
                            <div class="item--image">
                              <img [src]="item.metadata?.image" [alt]="item.name">
                            </div>
                            <div class="item--info">
                              <div class="item--header">
                                <h5 class="item-name">{{ item.name }}</h5>
                                <span class="label item-exchange-material" *ngIf="item.sapDetails?.exchangeableItem">
                                  {{ 'order-thank-you-page.exchangeable-item' | translate }}
                                </span>
                                <span class="label item-heavyweight-material" *ngIf="item?.heavyweight">
                                  {{ 'order-thank-you-page.heavyweight-item' | translate }}
                                </span>
                              </div>
                              <p class="item-material-number">
                                {{ item.materialNumber }}
                              </p>
                              <div class="content-row">
                                <div class="column column-1">
                                  <div class="item-quantity">
                                    <span>{{ 'order-thank-you-page.quantity' | translate }}</span>
                                    <span class="quantity">{{ item.quantity }}</span>
                                  </div>
                                  <div class="item-price">
                                    <span>{{ 'order-thank-you-page.total-price' | translate }}</span>
                                    <span class="price">
                                      {{ item.sumPrice | currency: orderDetails.attributes?.currencyIsoCode }}
                                    </span>
                                  </div>
                                </div>
                                <div class="column column-2">
                                  <div class="item-status">
                                    <span class="wrap-span">{{ 'spare-parts-order-details-page.item-shipping-status' | translate }}</span>
                                    <div class="status">
                                      {{ 'spare-parts-order-details-step-bar.' + getStatus(item.state?.name) + '-status' | translate }}
                                    </div>
                                  </div>
                                  <div class="item-tracking-number">
                                    <span class="wrap-span">{{ 'spare-parts-order-details-page.item-tracking-number' | translate }}</span>
                                    <div class="tracking-number">
                                      <ng-container *ngIf="getTrackingNumbers(item)?.length > 0; else noTrackingNumber">
                                        <ng-container *ngFor="let trackingNumber of getTrackingNumbers(item)">
                                          <a href="{{ ('order-tracking-history.tracking-number-sp-link' | translate) + trackingNumber }}"
                                             class="tracking-number--link"
                                             target="_blank" rel="noopener" [attr.data-test]="'link-trackingNumber'">
                                            <span class="icon-arrow-right color-orange"></span>
                                            <span>{{ trackingNumber }}</span>
                                          </a>
                                        </ng-container>
                                      </ng-container>
                                      <ng-template #noTrackingNumber>
                                        <span>-</span>
                                      </ng-template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="address-section col-12 col-md-5 col-lg-4">
                      <div class="address-section--delivery-address" *ngIf="orderDetails.attributes?.shippingAddress">
                        <h5 class="title">
                          {{ 'order-thank-you-page.delivery-address' | translate }}
                        </h5>
                        <p>
                          <ng-container *ngIf="orderDetails.attributes.pointOfContact?.attentionTo">
                            {{ orderDetails.attributes.pointOfContact?.attentionTo }}<br>
                          </ng-container>
                          <ng-container *ngIf="orderDetails.attributes.shippingAddress.sapId">
                            {{ orderDetails.attributes.shippingAddress.sapId }} -
                          </ng-container>
                          {{ orderDetails.attributes.shippingAddress.company }}<br>
                          {{ orderDetails.attributes.shippingAddress.address1 }}<br>
                          {{ orderDetails.attributes.shippingAddress.city }}
                          {{ orderDetails.attributes.shippingAddress.zipCode }}
                        </p>
                      </div>
                      <div class="address-section--shipping-method"
                           *ngIf="orderDetails.attributes.shipments?.length > 0">
                        <h5 class="title">
                          {{ 'order-thank-you-page.shipping-method' | translate }}
                        </h5>
                        <p>{{ orderDetails.attributes.shipments[0].shipmentMethodName }}</p>
                      </div>
                      <div class="address-section--purchase-order">
                        <strong class="text-uppercase">
                          {{ 'order-thank-you-page.purchase-order' | translate }}
                        </strong>
                        <span>{{ orderDetails.attributes?.sapPoNumber }}</span>
                      </div>
                      <div class="address-section--return-authorization-number"
                           *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber">
                        <strong class="text-uppercase">
                          {{ 'order-thank-you-page.return-order-no' | translate }}
                        </strong>
                        <span>{{ orderDetails.attributes.sapDetails?.returnAuthorizationNumber }}</span>
                      </div>
                      <div class="address-section--download-documentation"
                           *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber">
                        <a class="download-return-doc"
                           (click)="downloadDocumentation()"
                           [attr.data-test]="'button-downloadDocumentation'">
                          <span class="icon-arrow-right color-orange"></span>
                          <span>{{ 'order-thank-you-page.download-document' | translate }}</span>
                        </a>
                        <span class="submit-return-doc">
                          <a class="submit-return-doc__link"
                             *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable"
                             [attr.data-test]="'link-submitReturnDocument'"
                             href="mailto: {{ 'service-purchase-activity.return-document-recipient-email' | translate }}
                           ?Subject=Return Document {{ orderDetails.attributes.sapDetails.returnAuthorizationNumber }}">
                            <span class="icon-arrow-right color-orange"></span>
                            <span>{{ 'service-purchase-activity.submit-email-document' | translate }}</span>
                          </a>
                          <a class="submit-return-doc__msg"
                             *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable">
                            <div>
                              {{ 'service-purchase-activity.submit-return-document-text-line1' | translate }}<br>
                              {{ 'service-purchase-activity.submit-return-document-text-line2' | translate }}
                            </div>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </app-accordion>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
