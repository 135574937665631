import { IBaseModel, IGeneralMapTable, IPrice } from './common.models';
import { IAbstractProduct, IAbstractProductPrices, IProductImage, IProductLabel } from './abstract-product.models';
import { IConcreteProduct } from './concrete-product.models';

export const WISHLIST_TYPE = 'shopping-lists';
export const WISHLIST_ITEMS_TYPE = 'shopping-list-items';

export interface ICreateWishlistRequest {
  data: {
    type: string;
    id?: string;
    attributes: {
      name?: string;
    };
  };
}

export interface IAddNewItemToWishlistRequest {
  data: {
    type: string;
    id?: string;
    attributes: {
      sku: string;
      quantity?: number;
    }
  };
}

export interface IBaseWishlist extends IBaseModel {
  attributes: {
    name: string;
    owner?: string;
    numberOfItems?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  items?: IWishlistProductDetails[];
  relationships?: IBaseWishlistRelation;
}

export interface IWishlistProductDetails {
  sku: string;
  abstractSku?: string;
  name?: string;
  materialNumber?: string;
  imgUrl?: string;
  qty: number;
  shoppingListItemId?: string;
  defaultPrice?: IPrice;
  secondPrice?: IPrice;
}

export interface IBaseWishlistRelation {
  'wishlist-items'?: IGeneralMapTable;
  'shopping-list-items'?: IGeneralMapTable;
  'abstract-products'?: IGeneralMapTable;
  'abstract-product-prices'?: IGeneralMapTable;
  'abstract-product-image-sets'?: IGeneralMapTable;
  'product-labels'?: IGeneralMapTable;
}

export interface IWishlistItemInclude {
  type: string;
  id?: string;
  attributes: {
    sku: string;
  };
}

export interface IWishlist {
  data: IBaseWishlist;
  included?: (IWishlistItemInclude |
    IConcreteProduct |
    IProductLabel |
    IProductImage |
    IAbstractProduct |
    IAbstractProductPrices |
    ICreateWishlistRequest)[];
}

export interface IWishlists {
  data: IBaseWishlist[];
  included: any[];
}
