<ng-container *ngIf="checkoutDataLoaded" class="change-address__form">
  <form [formGroup]="changeAddressForm">
    <app-modal
      [open]="showModal"
      [id]="'ddChangeAddressModal'"
      title="{{'order-approve.change-address' | translate}}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
      <ng-container ngProjectAs="[slot][body]">
        <div class="row">
          <app-textfield
            class="col-12"
            [form]="changeAddressForm"
            [modelValue]="changeAddressForm.value.search"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-search'}"
            [inputName]="'search'"
            label="{{'order-approve.search' | translate}}"
            [isRequired]="false">
          </app-textfield>
        </div>
        <div class="row">
          <div class="col-12">
            <hr class="col-12" style=" margin-top: 1px; margin-bottom: 0.8rem">
            <div *ngFor="let address of addresses | searchFilter: changeAddressForm.value.search">
              <div class="col-12">
                <div  class="row">
                  <p style="padding-left: 1rem">
                    <label (click)="selectNewAddress(address.value)" 
                        class="address-label"
                        [ngClass]="{'selected': currentClickedAddress === address.value}" 
                        [attr.data-test]="'label-address'">
                      {{ address.name }}
                    </label>
                  </p>
                </div>
              </div>
              <hr class="col-12" style="margin-top: -1.5rem; margin-bottom: 0.8rem">
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container ngProjectAs="[slot][footer]">
        <button type="cancel" 
            class="button" 
            (click)="resetForm()" 
            data-dismiss="modal-dialog">
          {{ 'order-approve.cancel-modal' | translate }}
        </button>
        <button type="submit" 
            *ngIf="!updateAddressIsInProgress; else loadingSpinner"
            class="button button--primary" 
            (click)="saveNewAddress()" 
            data-dismiss="modal-dialog">
          {{ 'order-approve.save-changes-modal' | translate }}
        </button>
        <ng-template #loadingSpinner>
          <div class="spinner">
            <div class="loading-animation"></div>
          </div>
        </ng-template>
      </ng-container>
    </app-modal>
  </form>
</ng-container>
