<div class="steps-bar">
  <ng-container *ngFor="let step of activeScenario.scenarioSteps; index as i">
    <div
      class="steps-bar__item"
      [class.is-completed]="completedSteps.includes(step)"
      [class.is-current]="step === currentStatus"
      [attr.data-test]="'stepBarItem'"
    >
      <ng-container *ngIf="scenarioType === 'cpq'; else hospital">
        <ng-container *ngFor="let hStep of activeScenario.organizationSteps; index as i">
          <ng-container *ngIf="hStep === step">
            <div
                class="steps-bar__hospital"
                [class.first]="i === 0"
                [class.last]="i === activeScenario.organizationSteps.length - 1">
              <div
                  *ngIf="getTitlePosition() === i"
                  class="steps-bar__hospital-title icon-home"
                  [class.steps-bar__hospital-even]="activeScenario.organizationSteps.length%2 === 0">
                {{ 'cpq-step-bar.organization-steps' | translate }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #hospital>
        <ng-container *ngFor="let hStep of activeScenario.hospitalSteps; index as i">
          <ng-container *ngIf="hStep === step">
            <div
                class="steps-bar__hospital"
                [class.first]="i === 0"
                [class.last]="i === activeScenario.hospitalSteps.length - 1"
            >
              <div
                  *ngIf="getTitlePosition() === i"
                  class="steps-bar__hospital-title icon-home"
                  [class.steps-bar__hospital-even]="activeScenario.hospitalSteps.length%2 === 0"
              >
                {{ 'orders-step-bar.hospital-steps' | translate }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
      <div class="steps-bar__siemens"
           *ngIf="step === activeScenario.siemensStep">{{ 'orders-step-bar.siemens-steps' | translate }}</div>
      <div class="steps-bar__number {{step.includes('issue with the order') ? 'steps-bar__issue': ''}}" [attr.data-test]="'desc-stepBarNumber'">
        <span class="icon-check" *ngIf="completedSteps.includes(step) && step !== cpqOrderDeclinedStatus"></span>
        <span class="icon-exclamationmark" *ngIf="step.includes('issue with the order')"></span>
        <span *ngIf="!completedSteps.includes(step) && !step.includes('issue with the order')">{{i + 1}}</span>
      </div>
      <div class="steps-bar__title" [attr.data-test]="'desc-stepBarTitle'">{{ getTranslation(step, eTranslationTypes.LABEL) | translate }}</div>
      <div *ngIf="currentDesc && currentStatus === step" class="steps-bar__text">{{ currentDesc }}</div>
      <div class="steps-bar__text" [attr.data-test]="'desc-stepBarText'">
        <ng-container *ngIf="comment && declinedStatues.includes(getStepName(step)); else defaultComment">
          {{ comment }}
        </ng-container>
        <ng-template #defaultComment>
          {{ getComment(step, eTranslationTypes.DESCRIPTION) | translate }}
        </ng-template>
      </div>
    </div>
  </ng-container>

</div>
