<div class="system-preview" *ngIf="item">
  <div class="functional-location width-45">
    <div class="image width-20">
      <img alt="{{ item.attributes.systemDetails.nameEnUs }}"
           [src]="item.attributes.systemDetails.externalUrlSmall">
    </div>
    <div class="text width-80">
      <p class="font-weight-bold">{{ item.attributes.systemDetails.siemensEquipmentId }}</p>
      <p>{{ item.attributes.systemDetails.myEquipmentName }}</p>
      <p>{{ item.attributes.systemDetails.customerDesc }}</p>
      <p>{{ item.attributes.systemDetails.department }}</p>
      <p>{{ item.attributes.systemDetails.nameEnUs }}</p>
    </div>
  </div>
  <div class="selected-service width-35">
    <span>{{ item.attributes.name }}</span>
    <strong>{{ item.attributes.systemDetails.contractStartDate | configurationStatus | translate }}</strong>
    <div class="selected-service__actions">
      <button class="button button--link text-decoration-underline" [attr.data-test]="'button-editConfiguration'"
              (click)="goToEditConfiguration()">
        {{ item.attributes.systemDetails.contractStartDate | editConfigurationLabel | translate }}
      </button>
      <span class="separator"></span>
      <button class="button button--link text-decoration-underline" [attr.data-test]="'button-viewSummary'"
              (click)="goToConfigurationSummary()">
        {{ 'quote-summary.view-summary' | translate }}
      </button>
    </div>
  </div>
  <div class="annual-price width-20">
    <div class="annual-price__prices">
      <p class="font-weight-bold">{{ item.attributes.cpqNetPrice | currency: currency }}</p>
      <p class="strikethrough" *ngIf="item.attributes.cpqNetPrice < item.attributes.cpqListPrice">
        {{ item.attributes.cpqListPrice | currency: currency }}
      </p>
    </div>
    <div class="annual-price__delete" [attr.data-test]="'button-deleteConfiguration'">
      <div class="action" (click)="deleteConfiguration()">
        <span>{{ 'quote-summary.delete' | translate }}</span>
        <span class="icon-close"></span>
      </div>
    </div>
  </div>
</div>
