import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-configuration-steps-bar',
  templateUrl: './configuration-steps-bar.component.html',
  styleUrls: ['./configuration-steps-bar.component.scss'],
})
export class ConfigurationStepsBarComponent {
  @Input() currentStep: number;
  @Input() steps: [{key: number, value: string}];
  @Output() selectStep = new EventEmitter<number>();
}
