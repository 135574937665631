import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  postContactUsData(data: any): Promise<any> {
    const newBody = JSON.stringify(data);
    const url = this.glueUrl + '/contact-us';

    return this.http.post<any>(url, newBody)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(ContactUsService.handleError);
  }

  postContactUsGeneralData(data: any): Promise<any> {
    const newBody = JSON.stringify(data);
    const url = this.glueUrl + '/contact-us-general';

    return this.http.post<any>(url, newBody)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(ContactUsService.handleError);
  }
}
