import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appLoadingAnimation]',
})
export class LoadingAnimationDirective implements OnInit, OnChanges {
  @HostBinding('style.position') hostPosition = 'relative';

  @Input() appLoadingAnimation = false;

  constructor(private targetEl: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    const loadingContainer = this.renderer.createElement('div');
    this.renderer.addClass(loadingContainer, 'loading-container');
    this.renderer.setStyle(loadingContainer, 'display', this.appLoadingAnimation ? 'flex' : 'none');
    this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
    this.renderer.setStyle(loadingContainer, 'align-items', 'center');
    this.renderer.setStyle(loadingContainer, 'position', 'absolute');
    this.renderer.setStyle(loadingContainer, 'top', '0');
    this.renderer.setStyle(loadingContainer, 'bottom', '0');
    this.renderer.setStyle(loadingContainer, 'left', '0');
    this.renderer.setStyle(loadingContainer, 'right', '0');
    this.renderer.setStyle(loadingContainer, 'background', 'white');

    const spinnerContainer = this.renderer.createElement('div');
    this.renderer.addClass(spinnerContainer, 'loading-animation');

    this.renderer.appendChild(loadingContainer, spinnerContainer);

    this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appLoadingAnimation) {
      const container = this.targetEl.nativeElement;
      const div = container.querySelector('.loading-container');
      if (div) {
        this.renderer.setStyle(div, 'display', this.appLoadingAnimation ? 'flex' : 'none');
      }
    }
  }

}
