import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs';
import * as OrdersActions from '../actions/orders.actions';
import { map } from 'rxjs/operators';
import { OrdersFacade } from '../facades/orders.facade';
import { ICpqOrdersResponse, IOrdersSapDetails } from '../models/order.models';
import { EFeatureToggles, EGlueResource, EOrderWorkflows } from '../configurations/common';
import { CustomerActions } from '../actions';
import { AppUtils } from '../utils/app.utils';
import { ConfigurationFacade } from '../facades/configuration.facade';

@Injectable({
  providedIn: 'root',
})
export class OrdersEffects {

  constructor(
    private actions$: Actions,
    private ordersFacade: OrdersFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  initOrdersDataAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      switchMap(() => this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
        switchMap((isSparePartsEnabled) => {
          if (isSparePartsEnabled) {
            // NB: This effect is the initiator for loading spare part orders,
            // so it is appropriate for it to be aware of the current pagination
            // settings
            const currentStore = AppUtils.getCurrentStore();
            const pagination = currentStore.paginationSettingsPerFeature;
            const sparePartOrdersLimit = pagination.sparePartOrders.limitPerPage;
            return [
              OrdersActions.OrdersWithSapDetailsStartDataAction(0, sparePartOrdersLimit),
            ];
          } else {
            // do not trigger loading of CPQ and "normal" orders (currently triggered within
            // their respective component - to be addressed later)
            return [];
          }
        }),
      )),
    )
  });

  GetOrdersWithSapDetailsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.OrdersWithSapDetailsStartDataAction),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          [EGlueResource.SAP_ORDER_DETAILS], action.offset, action.limit, {includeApproverOrders: true},
        ).pipe(
          map((data: IOrdersSapDetails) =>
            OrdersActions.SuccessGetOrdersWithSapDetailsDataAction({
              ordersWithSapDetails: data,
            }),
          ),
        ),
      ),
    ));

  GetCpqUnderReviewOrdersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.CpqOrdersUnderReviewStartDataAction),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          [], action.offset, action.limit, {
            includeApproverOrders: false,
            isFinalized: false,
            workflow: EOrderWorkflows.CPQ_ORDER,
          },
        ).pipe(
          map((data: ICpqOrdersResponse) =>
            OrdersActions.SuccessGetCpqOrdersUnderReviewDataAction({
              cpqOrders: data,
            }),
          ),
        ),
      ),
    ));

  GetCpqFulFilledOrdersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.CpqOrdersFulfilledStartDataAction),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          [], action.offset, action.limit, {
            includeApproverOrders: false,
            isFinalized: true,
            workflow: EOrderWorkflows.CPQ_ORDER,
          },
        ).pipe(
          map((data: ICpqOrdersResponse) =>
            OrdersActions.SuccessGetCpqOrdersFulfilledDataAction({
              cpqOrders: data,
            }),
          ),
        ),
      ),
    ));

  GetOrdersWithCartsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.OrdersWithCartsStartDataAction),
      mergeMap(
        () => this.ordersFacade.getCarts()
          .pipe(
            map((data: any) =>
              OrdersActions.SuccessGetOrdersWithCartsDataAction({
                carts: data,
              }),
            ),
          ),
      ),
    ));

  GetOrdersWithCartsApprovalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.OrdersWithCartApprovalStartDataAction),
      mergeMap(
        () => this.ordersFacade.getCartsApproval()
          .pipe(
            map((carts: any) =>
              OrdersActions.SuccessGetCartOrdersWithApprovalDataAction({
                carts: carts,
              }),
            ),
          ),
      ),
    ));

  GetOrdersHistoryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.OrdersHistoryStartDataAction),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          [], action.offset, action.limit, {includeApproverOrders: true},
        ).pipe(
          map((orders: any) =>
            OrdersActions.SuccessGetOrdersHistoryDataAction({
              ordersHistory: orders,
            }),
          ),
        ),
      ),
    ));
}
