import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cart-rules',
  templateUrl: './cart-rules.component.html',
  styleUrls: ['./cart-rules.component.scss']
})
export class CartRulesComponent {
  @Input() cartRules: any;
  @Input() currency: string;
  @Input() priceToPay: number;
}
