import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { CheckoutActions } from '../../actions';
import { State } from '../../reducers';
import * as OrderApproveSelectors from '../../reducers/order-approve.reducer';
import * as ValidationPatterns from '../../configurations/validations';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { IPointOfContact } from 'src/app/models/common.models';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { CustomerFacade } from '../../facades/customer.facade';

@Component({
  selector: 'app-delivery-instructions-modal',
  templateUrl: './delivery-instructions-modal.component.html',
  styleUrls: ['./delivery-instructions-modal.component.scss'],
})
export class DeliveryInstructionsModalComponent implements OnInit {
  @Input() showModal: boolean;
  @Input() isSaturdayShipment: boolean = false;

  @Output() closeModal = new EventEmitter<any>();
  @Output() reloadCheckoutData = new EventEmitter<any>();

  cartId: string;
  pocForm: UntypedFormGroup;
  pocData: IPointOfContact;
  checkoutData: ICheckoutUpdate;
  updatePOCDataIsInProgress: boolean = false;

  maxLengthPhone: number = 20;
  maxLengthName: number = 35;
  maxLengthComment: number = 1000;
  maxLengthAttentionTo: number = 35;

  constructor(
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.getCartId();
  }

  formIsValid(): boolean {
    return this.pocForm.status === 'VALID';
  }

  resetForm(): void {
    this.pocForm.reset();
    this.closeModal.emit();
  }

  setFormValue(event: any): void {
    this.pocForm.patchValue({
      [event.key]: event.value
    });
  }

  initializeForm(): void {
    this.pocForm = this.formBuilder.group({
      attentionTo: [this.pocData.attentionTo, [Validators.maxLength(this.maxLengthAttentionTo)]],
      firstName: [this.pocData.firstName, [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: [this.pocData.lastName, [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      email: [this.pocData.email, [Validators.required, Validators.email]],
      phone: [this.pocData.phone, [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      comment: [this.pocData.comment, [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]]
    });
  }

  createPointOfContactFromFormValues(): {pointOfContact: IPointOfContact} {
    return {
      pointOfContact: {
        attentionTo: this.pocForm.value.attentionTo,
        firstName: this.pocForm.value.firstName,
        lastName: this.pocForm.value.lastName,
        email: this.pocForm.value.email,
        phone: this.pocForm.value.phone,
        comment: this.pocForm.value.comment
      }
    }
  }

  updatePOCData(): void {
    if (this.formIsValid()) {
      this.updatePOCDataIsInProgress = true;

      this.checkoutData = {
        type: 'checkout-update',
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          pointOfContact: {
            pointOfContactId: 'pointOfContact',
            attentionTo: this.pocForm.value.attentionTo,
            firstName: this.pocForm.value.firstName,
            lastName: this.pocForm.value.lastName,
            email: this.pocForm.value.email,
            phone: this.pocForm.value.phone,
            comment: this.pocForm.value.comment
          }
        }
      };

      this.checkoutFacade.postCheckoutData({ data: this.checkoutData }, this.cartId).then(() => {
        this.store.dispatch(CheckoutActions.UpdatePOCData({
          payload: {
            attentionTo: this.pocForm.value.attentionTo,
            firstName: this.pocForm.value.firstName,
            lastName: this.pocForm.value.lastName,
            email: this.pocForm.value.email,
            phone: this.pocForm.value.phone,
            comment: this.pocForm.value.comment
          }
        }));

        this.reloadCheckoutData.emit();
        this.closeModal.emit();
        this.updatePOCDataIsInProgress = false;
      });

      this.customerFacade.setPointOfContact(this.createPointOfContactFromFormValues().pointOfContact);
    }
  }

  private getCartId(): void {
    this.store.select(OrderApproveSelectors.getCartId)
      .pipe(take(1))
      .subscribe(cartId => {
        this.cartId = cartId;
      });
  }
}
