<div class="contact-us-footer">
  <div *ngIf="isSparePartsEnabled" class="parts">
    <p class="parts__title">{{ 'app-footer.contact-parts' | translate }}</p>
    <address>
      <p class="parts__item" *ngIf="address.needHelp?.questionsAboutParts?.phoneNumber" [attr.data-test]="'desc-contactUs-phone'">
        <span class="icon-phone"></span>
        <a href="tel:{{address.needHelp.questionsAboutParts.phoneNumber}}">
          {{ address.needHelp.questionsAboutParts.phoneNumber
            | phoneNumber: address.phoneNumberFormat : address.delimiter : address.prefix }}
        </a>
        {{ address.needHelp.questionsAboutParts.phoneNumberAdditionalInfo }}
      </p>
      <p class="parts__item" *ngIf="address.needHelp?.questionsAboutParts?.directSalesEmailAddress" [attr.data-test]="'desc-contactUs-email'">
        <span class="icon-email"></span>
        <a href="mailto:{{address.needHelp.questionsAboutParts.directSalesEmailAddress}}">
          {{ address.needHelp.questionsAboutParts.directSalesEmailAddress }}
        </a>
      </p>
      <p class="parts__item" *ngIf="address.needHelp?.questionsAboutParts?.partsClarificationEmailAddress">
        <span class="icon-email"></span>
        <a href="mailto:{{address.needHelp.questionsAboutParts.partsClarificationEmailAddress}}">
          {{ address.needHelp.questionsAboutParts.partsClarificationEmailAddress }}
        </a>
      </p>
    </address>
  </div>
  <div *ngIf="isCpqEnabled" class="contracts">
    <p class="contracts__title">{{ 'app-footer.contact-contracts' | translate }}</p>
    <p class="contracts__item" *ngIf="address.needHelp?.questionsAboutContracts?.phoneNumber">
      <span class="icon-phone"></span>
      <a href="tel:{{address.needHelp.questionsAboutContracts.phoneNumber}}">
        {{ address.needHelp.questionsAboutContracts.phoneNumber
          | phoneNumber: address.phoneNumberFormat : address.delimiter : address.prefix }}
      </a>
      {{ address.needHelp.questionsAboutContracts.phoneNumberAdditionalInfo }}
    </p>
    <p class="contracts__item" *ngIf="address.needHelp?.questionsAboutContracts?.emailAddress">
      <span class="icon-email"></span>
      <a href="mailto:{{address.needHelp.questionsAboutContracts.emailAddress}}">
        {{ address.needHelp.questionsAboutContracts.emailAddress }}
      </a>
    </p>
  </div>
  <div class="support">
    <p class="support__title">{{ 'app-footer.contact-support' | translate }}</p>
    <p class="support__item" *ngIf="address.needHelp?.websiteAndTechnicalSupport?.phoneNumber">
      <span class="icon-phone"></span>
      <a href="tel:{{address.needHelp.websiteAndTechnicalSupport.phoneNumber}}">
        {{ address.needHelp.websiteAndTechnicalSupport.phoneNumber
          | phoneNumber: address.phoneNumberFormat : address.delimiter : address.prefix }}
      </a>
      {{ address.needHelp.websiteAndTechnicalSupport.phoneNumberAdditionalInfo }}
    </p>
    <p class="support__item" *ngIf="address.needHelp?.websiteAndTechnicalSupport?.emailAddress" [attr.data-test]="'desc-support-email'">
      <span class="icon-email"></span>
      <a href="mailto:{{address.needHelp.websiteAndTechnicalSupport.emailAddress}}">
        {{ address.needHelp.websiteAndTechnicalSupport.emailAddress }}
      </a>
    </p>
  </div>
</div>
