import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ImageUtils } from '../../../utils/image.utils';

@Component({
  selector: 'app-configurable-mini-cart',
  templateUrl: './configurable-mini-cart.component.html',
  styleUrls: ['./configurable-mini-cart.component.scss'],
})
export class ConfigurableMiniCartComponent {
  @Input() currency: string;
  @Input() currentCartId: string;
  @Input() currentCartName: string;
  @Input() usersCarts: any[];
  @Input() itemsInCart: any[];
  @Input() hasNickname: boolean;

  @Output() showAddNicknameModal = new EventEmitter<boolean>();
  @Output() showDeleteQuoteModal = new EventEmitter<boolean>();
  @Output() redirectToHomepage = new EventEmitter<void>();

  constructor(
    private marketingFacade: MarketingFacade,
  ) {
  }

  showAddNicknameModalEmit(): void {
    this.showAddNicknameModal.emit(true);
  }

  showDeleteQuoteModalEmit(): void {
    this.showDeleteQuoteModal.emit(true);
  }

  switchDefaultCart(cartId: string): void {
    this.marketingFacade.switchDefaultCart(cartId);
    this.redirectToHomepage.emit();
  }

  getItemPicture(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  getItemPrice(item: any): number {
    const cpqNetPrices = item.items.reduce((acc, data) => {
      acc.push(data.attributes.cpqNetPrice);
      return acc;
    }, []);

    return cpqNetPrices.reduce((total, price) => {
      return total + price;
    }, 0);
  }

  getEquipmentCount(item: any): number {
    const materialNumbers = [
      ...new Set(
        item.items.map(item => item.attributes.systemDetails.materialNumber)
      )
    ];

    return materialNumbers ? materialNumbers.length : 0;
  }

  getAddOrEditNicknameLabel(): string {
    return this.hasNickname ? 'mini-cart.edit-nickname-label' : 'mini-cart.add-nickname-label';
  }
}
