<ng-template #loadingTemplate>
  <div [attr.data-test]="'loader-MyEquipmentSelection'" class="loading-animation position-center"></div>
</ng-template>
<div class="my-equipment-selection">
  <h4 [attr.data-test]="'title-myEquipmentSelection'">{{ 'my-equipment-selection.title' | translate }}</h4>
  <p>
    {{'my-equipment-selection.paragraph' | translate}}
  </p>
  <div class="functional-location-number__desc">
    <p class="text" [attr.data-test]="'desc-MyEquipmentSelection'">
      <app-tooltip
          [useBothTogglers]="true"
          linkCss="fl-text"
          link="{{ 'my-equipment-selection.functional-location-number' | translate }}"
          text="{{ 'my-equipment-selection.functional-location-number-tooltip-text' | translate }}">
      </app-tooltip>
    </p>
  </div>
  <div class="my-equipment-selection__container">
    <div class="textfield--with-prefix  my-equipment-selection__search_field"
         [class.my-equipment-selection__search_field-error]="error">
      <label>
        <span *ngIf="!error" class="icon-search"></span>
        <input
            id="search"
            name="search"
            type="search"
            class="textfield__input search-field__input"
            spellcheck="false"
            autocomplete="off"
            placeholder=" "
            [attr.data-test]="'input-search'"
            [disabled]="searchLoading"
            [(ngModel)]="searchValue"
            (keyup.enter)="searchFL()"
            (ngModelChange)="searchValueChange($event)"
            (change)="inputChange(searchValue)"
        >
        <span>{{ 'my-equipment-selection.search-field' | translate }}</span>
      </label>
    </div>
    <div *ngIf="errorSearchLimit" [attr.data-test]="'errorSearchLimit-MyEquipmentSelection'"
         class="my-equipment-selection__error-paragraph">
      <p>
        {{"my-equipment-selection.error-search-limit" | translate}}
      </p>
    </div>
    <div *ngIf="errorFLValidity" [attr.data-test]="'errorFLValidity-MyEquipmentSelection'"
         class="my-equipment-selection__error-paragraph">
      <p>
        {{"my-equipment-selection.error-wrong-fl" | translate}}
      </p>
    </div>
    <div *ngIf="errorEndpointNoFL" [attr.data-test]="'errorEndpointNoFL-MyEquipmentSelection'"
         class="my-equipment-selection__error-paragraph">
      <p>
        {{"my-equipment-selection.error-endpoint-wrong-fl" | translate}}
      </p>
    </div>
    <div *ngIf="errorEndpointNotAccessible" [attr.data-test]="'errorEndpointNotAccesible-MyEquipmentSelection'"
         class="my-equipment-selection__error-paragraph">
      <p>
        {{"my-equipment-selection.error-endpoint-wrong-country" | translate}}
      </p>
    </div>
    <div *ngIf="errorFLContractDoesNotMatchTheList" [attr.data-test]="'errorFLContractDoesNotMatchTheList-MyEquipmentSelection'"
         class="my-equipment-selection__error-paragraph">
      <p>
        {{"my-equipment-selection.error-equipment-not-available" | translate}}
      </p>
    </div>
    <div>
      <button class="button button--secondary my-equipment-selection__button"
              (click)="searchFL()"
              [disabled]="!inputIsValid"
              data-test="button-search"> {{"my-equipment-selection.search-button" | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="!loading else loadingTemplate">
    <div *ngIf="tableData">
      <div class="valid-functional-location-number">
        <div class="valid-functional-location-number__title">
          <h5 class="text">{{ 'my-equipment-selection.valid-functional-location-number' | translate }}</h5>
          <span class="icon-check icon-check__circle"></span>
        </div>
        <div class="valid-functional-location-number__desc">
          <p class="text" [attr.data-test]="'desc-selectableEquipment1'">
            {{ 'my-equipment-selection.valid-functional-location-number-desc1' | translate }}
            <app-tooltip
                [useBothTogglers]="true"
                linkCss="fl-text"
                link="{{ 'my-equipment-selection.valid-functional-location-number-location' | translate }}"
                text="{{ 'my-equipment-selection.valid-functional-location-number-tooltip-text' | translate }}">
            </app-tooltip>
            {{ 'my-equipment-selection.valid-functional-location-number-desc2' | translate }}
          </p>
        </div>
      </div>
      <div>
        <ng-container>
          <app-my-equipment-selection-list
              [isCartOperationInProgress]="isCartOperationInProgress"
              [loadingCartDataInProgress]="loadingCartDataInProgress"
              [agreements]="tableData"
              [loading]="loading"
              [searchValue]="searchValue"
              [filters]="contractFilters"
              [userCarts]="userCarts"
              [currentCartId]="currentCartId"
              [cartItems]="[]"
              [productSku]="productSku"
              [soldToId]="soldTo"
              [selectableEquipment]="true"
              [isReorderPending]="isReorderPending"
              [companyRoles]="companyRoles">
          </app-my-equipment-selection-list>
        </ng-container>
      </div>
    </div>
  </div>
</div>
