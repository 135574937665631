import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, skipWhile, take, takeUntil } from 'rxjs/operators';

import { IInstalledBaseSystemData } from '../../../models/installedbase.models';
import { environment } from '../../../../environments/environment';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { IModalitiesAndMaterialNumbers } from '../../../models/cpq.models';
import { DateUtils } from '../../../utils/date.utils';
import { ICart, ICartUpdateRequestAttributes } from '../../../models/cart.models';
import { ISystemDetails } from '../../../models/common.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { AppActions } from '../../../actions';
import { IconType } from '../../../models/settings.model';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { EMultiCartTabs, ESparePartsOrderHistoryTabs, EUserRoles } from '../../../configurations/common';
import { FormatDatePipe } from '../../../shared/pipes/format-date.pipe';
import { AppUtils } from '../../../utils/app.utils';
import { EquipmentUtils } from '../../../utils/equipment.utils';
import { LocalStorageUtils } from '../../../utils/localStorage.utils';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss'],
})
export class ContractDetailComponent implements OnInit, OnChanges {
  @Input() isCartOperationInProgress: boolean;
  @Input() loadingCartDataInProgress: boolean;
  @Input() agreement: IInstalledBaseSystemData;
  @Input() soldToInactive = false;
  @Input() currentCartId: string;
  @Input() cartItems = [];
  @Input() productSku = '';
  @Input() needsAttention = false;
  @Input() soldToIdFromParams: number;
  @Input() additionalData: any;
  @Input() modalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[];
  @Input() isEquipment: boolean = false;
  @Input() selectableEquipment: boolean = false;
  @Input() isReorderPending: boolean = false;
  @Input() companyRoles: EUserRoles[];

  iconType = IconType;
  hasSupportDateUnder60Days = false;
  hasContractEndDateUnder60DaysOrIsNull = false;
  supportAndContractEndsSameDay = false;
  inactive = false;
  daysUntilEndOfContract: number;
  daysTillEndOfSupport: number;

  isAddQuoteModalOpen = false;
  showModalChangeEquipmentOrCreateNewCart = false;
  isLoading = true;
  equipmentSelectionInProgress = false;
  addItemOperationInProgress = false;
  lastSku: string;
  orderId: string;
  redirect: string;
  isEquipmentCompatible: boolean = true;
  userCarts: Array<ICart> = [];

  attributesToHide: string[] = [
    'contractsStartDate',
    'contractExpirationDate',
    'contractNumber',
  ];

  attributes = [
    {
      attribute: 'siemensEquipmentId',
      label: 'my-contracts.functional-location',
    },
    {
      attribute: 'category',
      label: 'my-contracts.modality',
    },
    {
      attribute: 'nameEnUs',
      label: 'my-contracts.product-name',
    },
    {
      attribute: 'siemensEquipmentAddress',
      nestedAttribute: 'street',
      label: 'my-contracts.equipment-location',
    },
    {
      attribute: 'siemensEquipmentAddress',
      nestedAttribute: 'city',
      label: 'my-contracts.city-state',
    },
    {
      attribute: 'contractName',
      label: 'my-contracts.contract-name',
    },
    {
      attribute: 'contractsStartDate',
      label: 'my-contracts.contract-start',
    },
    {
      attribute: 'contractExpirationDate',
      label: 'my-contracts.contract-end',
    },
    {
      attribute: 'startupDate',
      label: 'my-contracts.startup-date',
    },
    {
      attribute: 'endOfSupport',
      label: 'my-contracts.end-of-support',
      tooltip: 'my-contracts.end-of-support-description-tooltip-text',
    },
    {
      attribute: 'contractNumber',
      label: 'my-contracts.contract-number',
    },
    {
      attribute: 'customerDesc',
      label: 'my-contracts.customer-description',
      tooltip: 'my-contracts.edit-customer-description-tooltip-text',
    },
  ];

  additionalAttributes = [{
    attribute: 'soldToId',
    label: 'my-contracts.sold-to-id',
    tooltip: 'my-contracts.sold-to-id-description-tooltip-text',
  },
    {
      attribute: 'soldToAddress',
      label: 'my-contracts.sold-to-address',
    },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private catalogFacade: CatalogFacade,
    private formatDatePipe: FormatDatePipe,
  ) {
  }

  ngOnInit(): void {
    this.selectUserCarts();
    const hasEndOfContract = ![null, ''].includes(this.agreement.attributes.contractExpirationDate);
    this.daysUntilEndOfContract = DateUtils.countDaysFromToday(this.agreement.attributes.contractExpirationDate);
    this.daysTillEndOfSupport = DateUtils.countDaysFromToday(this.agreement.attributes.endOfSupport);
    this.hasSupportDateUnder60Days = this.daysTillEndOfSupport <= 60 && this.daysTillEndOfSupport >= 0;
    this.hasContractEndDateUnder60DaysOrIsNull =
      this.daysUntilEndOfContract <= 60 || [null, ''].includes(this.agreement.attributes.contractExpirationDate);
    this.supportAndContractEndsSameDay =
      (this.agreement.attributes.contractExpirationDate === this.agreement.attributes.endOfSupport) && hasEndOfContract;
    this.isAddItemOperationInProgress();
    if (this.soldToIdFromParams) {
      this.attributes = [...this.attributes, ...this.additionalAttributes];
    }
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.lastSku = params['lastSku'];
      this.orderId = params['orderId'];
      this.redirect = params['redirect'];
      this.selectEquipmentCompatibility();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {agreement, cartItems} = changes;
    if (agreement?.currentValue || cartItems?.currentValue) {
      this.checkHasItemInCart();
    }
  }

  isAddItemOperationInProgress(): void {
    this.marketingFacade.isAddItemOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addItemOperationInProgress => this.addItemOperationInProgress = addItemOperationInProgress);
  }

  getAttribute(attribute: string, nestedAttribute?: string): string {
    if (this.attributesToHide.includes(attribute) && this.daysUntilEndOfContract < 0) {
      return '-';
    }

    const datesAttributes = ['contractsStartDate', 'contractExpirationDate', 'startupDate', 'endOfSupport'];
    let attributeValue = this.additionalAttributes.some(item => item.attribute === attribute) ?
      this.additionalData[attribute] : EquipmentUtils.getAttribute(this.agreement, attribute, nestedAttribute);

    if (datesAttributes.includes(attribute) && attributeValue !== '-') {
      attributeValue = DateUtils.isValidDate(attributeValue) ? this.formatDatePipe.transform(attributeValue) : '-';
    }
    return attributeValue;
  }

  getTeamplayFleetContractUrl(): string {
    return environment.teamplayFleetUrl + '/equipmentDetails.html' +
      '?materialNo=' + this.agreement.attributes.materialNumber +
      '&serialNo=' + this.agreement.attributes.serialNumber +
      '&country=' + AppUtils.getCurrentStore().storeId +
      '&tab=contractModel' +
      '&search=' + this.agreement.attributes.contractNumber;
  }

  disableAgreement(): boolean {
    return this.soldToInactive || (this.agreement && this.agreement.hasOwnProperty('compatible') && !this.agreement.compatible);
  }

  openSparePartsViewer(): void {
    window.open(`${environment.sparePartsViewerUrl}&matnum=${this.agreement.attributes.materialNumber}`, '_blank');
  }

  checkHasItemInCart(): void {
    this.inactive = false;
    if (!this.agreement || !this.cartItems || this.cartItems?.length === 0) {
      return;
    }
    this.inactive = !!this.cartItems.find(item =>
      item.attributes.systemDetails?.siemensEquipmentId === this.agreement.attributes?.siemensEquipmentId);
  }

  startConfiguration(): void {
    const {materialNumber, siemensEquipmentId} = this.agreement.attributes;

    if (!this.cartItems) {
      this.cartItems = [];
    }

    if (this.cartItems?.length > 0) {
      if (!this.soldToInactive && this.soldToIdFromParams) {
        this.router.navigate(['/catalog/service-contracts'], {
          queryParams: {
            'fl-number': siemensEquipmentId,
            'rel-product-sysivk': materialNumber,
          },
        }).then();
      } else {
        this.isAddQuoteModalOpen = true;
      }
    } else {
      if (!this.productSku) {
        this.router.navigate(['/catalog/service-contracts'], {
          queryParams: {
            'fl-number': siemensEquipmentId,
            'rel-product-sysivk': materialNumber,
          },
        }).then();
      } else {
        this.addItemOperationInProgress = true;
        this.marketingFacade.startContractConfiguration(this.productSku, siemensEquipmentId, materialNumber);
      }
    }
  }

  startEquipmentSelection(): void {
    const {siemensEquipmentId} = this.agreement.attributes;
    this.equipmentSelectionInProgress = true;

    if (!!this.userCarts && this.currentCartId) {
      let showNotification = false;
      const currentCart = this.userCarts.find(cart => cart.id === this.currentCartId);
      const cartWithSystemDetails = this.userCarts.find(
        cart => cart.attributes.systemDetails?.siemensEquipmentId === siemensEquipmentId,
      );

      if (this.isReorderPending) {
        this.updateReorderWithSystemDetails();
        this.updateCartDataWithSystemDetails(this.currentCartId);
        return;
      }

      if (!!cartWithSystemDetails && !!currentCart) {
        if (currentCart.attributes.systemDetails?.siemensEquipmentId === siemensEquipmentId) {
          this.redirectToCatalogOrPDP();
          return;
        } else {
          if (currentCart.attributes.totalItemsQuantity !== 0 || currentCart.attributes.hasContractInCart) {
            showNotification = true;
          }
          this.switchDefaultCart(cartWithSystemDetails.id, false, showNotification);
          return;
        }
      } else {
        if (!!currentCart) {
          if (!currentCart.attributes?.totalItemsQuantity) {
            this.updateCartDataWithSystemDetails(currentCart.id);
            return;
          }
          if (currentCart.attributes.hasContractInCart) {
            this.createNewCartForEquipment();
            return;
          }
          if (!currentCart.attributes.hasContractInCart && currentCart.attributes.totalItemsQuantity !== 0) {
            this.showModalChangeEquipmentOrCreateNewCart = true;
          }
        } else {  // current cart does not exist and my installed base was selected
          this.createNewCartForEquipment();
          return;
        }
      }
    }
  }

  closeModalChangeEquipmentOrCreateNewCart(): void {
    this.showModalChangeEquipmentOrCreateNewCart = false;
    this.equipmentSelectionInProgress = false;
  }

  createNewCartForEquipment(): void {
    this.createNewCartAndUpdateDataWithSystemDetails(true);
  }

  changeEquipmentForCurrentCart(): void {
    this.updateCartDataWithSystemDetails(this.currentCartId);
  }

  private createNewCartAndUpdateDataWithSystemDetails(showNotification: boolean = false): void {
    const systemDetails = {
      ...this.agreement.attributes,
    } as ISystemDetails;
    this.marketingFacade.createEmptyCart({systemDetails});
    this.marketingFacade.selectCartId().pipe(
      skipWhile(id => !id || id === this.currentCartId),
      take(1),
    ).subscribe(_ => {
      if (showNotification) {
        this.showNotificationYourCartWasChanged();
      }

      this.redirectToCatalogOrPDP();
    });
  }

  private updateReorderWithSystemDetails(): void {
    const systemDetails = {
      ...this.agreement.attributes,
    } as ISystemDetails;

    this.marketingFacade.updateReorderWithSystemDetails(systemDetails);
  }

  private updateCartDataWithSystemDetails(cartId: string): void {
    const systemDetails = {
      ...this.agreement.attributes,
    } as ISystemDetails;
    this.marketingFacade.updateCartById(cartId, {systemDetails});

    // relevant for custom endpoints: save systemDetails to use it later in clickdummy
    LocalStorageUtils.setKeyValue('selected-equipment', JSON.stringify(systemDetails));

    this.isReorderPending
      ? this.redirectToPurchaseActivity()
      : this.redirectToCatalogOrPDP();
  }

  private switchDefaultCart(
    cartId: string,
    updateCartDataWithSystemDetails: boolean = false,
    showNotification: boolean                = false,
  ): void {
    let updateRequestAttributes: ICartUpdateRequestAttributes = {};
    if (updateCartDataWithSystemDetails) {
      updateRequestAttributes.systemDetails = {
        ...this.agreement.attributes,
      };
    }

    this.marketingFacade.switchDefaultCart(cartId, updateRequestAttributes);
    this.marketingFacade.selectCartId().pipe(
      skipWhile(currentCartId => cartId != currentCartId),
      take(1),
    ).subscribe(_ => {
      if (showNotification) {
        this.showNotificationYourCartWasChanged();
      }

      this.redirectToCatalogOrPDP();
    })
  }

  private showNotificationYourCartWasChanged(): void {
    this.configurationFacade.appendNotification({
      type: 'success',
      title: 'shop-cart.your-cart-was-changed-title',
      messages: [{
        key: 'shop-cart.your-cart-was-changed-text',
      }],
      actions: [
        {
          type: '',
          label: 'shop-cart.close',
          css: ['button', 'button--secondary'],
        },
        {
          type: AppActions.redirectToShopCart.type,
          label: 'shop-cart.go-to-cart',
          css: ['button', 'button--primary'],
        },
      ],
    });
  }

  private redirectToCatalogOrPDP(): void {
    const {materialNumber, siemensEquipmentId} = this.agreement.attributes;

    this.router.navigate(
      [!!this.redirect ? `/${this.redirect}` : (!!this.lastSku ? `/product/${this.lastSku}` : '/catalog/parts')],
      {
        queryParams: !!this.redirect ? {} : {
          'fl-number': siemensEquipmentId,
          'rel-product-sysivk': materialNumber,
        },
      }).then();
  }

  private redirectToPurchaseActivity(): void {
    this.marketingFacade.confirmReorder();
    this.router.navigate(
      ['/purchase-activity'],
      {
        queryParams: {
          tab: EMultiCartTabs.PARTS,
          subtab: ESparePartsOrderHistoryTabs.ORDERS,
          orderId: this.orderId,
        },
      },
    ).then();
  }

  selectEquipmentCompatibility(): void {
    if (this.isEquipment && this.selectableEquipment && this.lastSku) {
      this.catalogFacade.getAbstractProductDataFromApi(this.lastSku).pipe(take(1)).subscribe(product => {
        this.isEquipmentCompatible =
          product.data.attributes.attributes?.sap_p40_modality_id === this.agreement.attributes?.modalityCode;
      });
    }
  }

  isUserSparePartsViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.SPCViewer);
  }

  private selectUserCarts(): void {
    this.marketingFacade.selectCarts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userCarts => {
        this.userCarts = userCarts;
      })
  }
}
