<div class="catalog row" [ngClass]="[activeRoute]">
  <div *ngIf="!firstPageLoad && (isUsStore || isCaStore)" class="col-12">
    <ng-container *ngIf="isCpqEnabled && containsConfigurableProducts()">
      <ng-container *ngIf="hasContractParams">
        <app-access-control [notForViewer]="true" [description]="false">
          <ng-container ngProjectAs="[slot][nonViewerContent]">
            <app-contract-steps-bar [stepNo]="1"></app-contract-steps-bar>
          </ng-container>
        </app-access-control>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSparePartsEnabled && !containsConfigurableProducts()">
      <app-access-control [notForViewer]="true" [description]="false">
        <ng-container ngProjectAs="[slot][nonViewerContent]">
          <app-equipment-selection
              [currentCart]="currentCart"
              [systemDetails]="systemDetails" 
              [loading]="loadingCartDataInProgress">
          </app-equipment-selection>
        </ng-container>
      </app-access-control>
    </ng-container>
  </div>
  <div class="col-12 col-lg-3 catalog-navigation">
    <div class="catalog__header">
      <ng-container *ngIf="isRouteActive('catalog')">
        <h1 class="catalog__title" [attr.data-test]="'title-catalogTitle'">{{ catalogTitle | translate }}</h1>
      </ng-container>
      <ng-container *ngIf="isRouteActive('search-results')">
        <div class="stripe catalog__title-stripe" *ngIf="firstPageLoad"></div>
        <div class="catalog__title" *ngIf="showResultsMessage()">
          {{ 'search-results.search-summary' | translate:{count: pagination.numFound} }}
          <span [attr.data-test]="'desc-searchKeywords'" class="search__keyword">{{ '“' + params.q + '”' }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isRouteActive('installed-base')">
        <div class="stripe catalog__title-stripe" *ngIf="firstPageLoad"></div>
        <div class="catalog__title" *ngIf="!firstPageLoad && installBaseProduct">
          <h5 class="margin-0">{{ 'install-base.products-for' | translate }}</h5>
          <h5 class="margin-0">{{ installBaseProduct.attributes.nameEnUs }}</h5>
        </div>
      </ng-container>
      <app-access-control>
        <ng-container ngProjectAs="[slot][content]">
          <div class="sticker__container" *ngIf="getBlackFridayLabel()">
            <img alt="{{ 'black-friday.sticker' | translate }}" [src]="blackFridaySticker">
          </div>
        </ng-container>
      </app-access-control>
    </div>
    <button [attr.data-test]="'button-categoryTreeMobile'"
        class="button icon-arrow-down-small catalog-navigation-filter__button"
        (click)="filtersDropdownOpened = !filtersDropdownOpened">
      {{ 'category-tree.mobile-button-name' | translate }}
    </button>
    <div class="search-filters" [ngClass]="{'is-open': filtersDropdownOpened}">
      <app-category-tree
          [params]="params"
          [fetchedCategories]="fetchedCategories"
          [showLoader]="loading"
          [useNavigate]="isRouteActive('search-results') || isRouteActive('installed-base')">
      </app-category-tree>
      <app-access-control class="margin-bottom-0">
        <ng-container ngProjectAs="[slot][content]">
          <app-equipment-filter
              *ngIf="!firstPageLoad && this.equipmentTypeFilter.isApplicable && !noProductFound"
              [filter]="equipmentTypeFilter">
          </app-equipment-filter>
        </ng-container>
      </app-access-control>
      <app-filters 
          [filters]="filters" 
          [activeFilters]="params">
      </app-filters>
    </div>
  </div>
  <div class="col-12 col-lg-9">
    <div class="row" *ngIf="!noProductFound">
      <div class="col-sm-12 col-md-6 marked-filters__container">
        <app-marked-filters 
            [filters]="filters"
            [equipmentFilter]="equipmentTypeFilter"
            [systemDetails]="systemDetails"
            [isBusinessPartner]="isBusinessPartner">
        </app-marked-filters>
      </div>
      <div class="col-sm-12 col-md-6 display-flex f-align-items-center width-50 justify-content-end sort__container">
        <div class='stripe stripe-half stripe-tall' *ngIf="firstPageLoad"></div>
        <ng-container *ngIf="!firstPageLoad">
          <app-sort-by [isCatalogPage]=true [sort]="sort" [params]="params"></app-sort-by>
          <app-layout-options class="desktop"
              [currentLayoutType]="currentLayoutType"
              (selected)="selectLayoutOption($event)">
          </app-layout-options>
        </ng-container>
      </div>
    </div>
    <div class="row" *ngIf="firstPageLoad">
      <div class="col-4">
        <app-product-loading-tile></app-product-loading-tile>
      </div>
      <div class="col-4">
        <app-product-loading-tile></app-product-loading-tile>
      </div>
      <div class="col-4">
        <app-product-loading-tile></app-product-loading-tile>
      </div>
    </div>
    <ng-container *ngIf="isRouteActive('search-results') && noProductFound">
      <div class="no-product-found">
        <ng-container *ngIf="isRouteActive('installed-base'); else catalogNoResults">
          <h5 [attr.data-test]="'desc-noPartsAvailable'">{{ 'install-base.no-parts-available' | translate }}</h5>
          <button [attr.data-test]="'button-proceedToHomepage'"
                  type=button class="button button--primary" (click)="proceedToHomepage()">
            {{ 'install-base.proceed-to-homepage' | translate }}
          </button>
        </ng-container>
        <ng-template #catalogNoResults>
          <h5 [attr.data-test]="'desc-noSearchResults'">{{ 'search-results.no-results' | translate }}</h5>
          <button [attr.data-test]="'button-proceedToHomepage'"
                  type=button class="button button--primary" (click)="proceedToCatalog()">
            {{ 'search-results.proceed-to-catalog' | translate }}
          </button>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="showNoProductFound()">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <app-marked-filters
              [filters]="filters"
              [equipmentFilter]="equipmentTypeFilter"
              [systemDetails]="systemDetails"
              [isBusinessPartner]="isBusinessPartner">
          </app-marked-filters>
        </div>
      </div>
      <div class="no-product-found">
        <h5 [attr.data-test]="'desc-noProducts'">{{ 'product-list.no-products' | translate }}</h5>
      </div>
    </ng-container>
    <ng-container *ngIf="!noProductFound">
      <app-catalog-products
          [isUsStore]="isUsStore"
          [isCaStore]="isCaStore"
          [isSparePartsEnabled]="isSparePartsEnabled"
          [isCpqEnabled]="isCpqEnabled"
          [currentCart]="currentCart"
          [currentCartId]="currentCartId"
          [systemDetails]="systemDetails"
          [products]="abstractProducts"
          [layoutType]="currentLayoutType"
          [labels]="labels"
          [productsLoading]="loading"
          [activeRoute]="activeRoute"
          [contractParams]="contractParams"
          [loadingCartDataInProgress]="loadingCartDataInProgress">
      </app-catalog-products>
    </ng-container>
    <div class="row" *ngIf="pagination && !noProductFound">
      <app-pagination
          class="col-12"
          [numberOfPages]="pagination.maxPage"
          [itemsPerPage]="pagination.currentItemsPerPage">
      </app-pagination>
    </div>
    <div *ngIf="showNoConfigurationAvailableInfo()" class="no-configuration">
      {{ 'catalog.not-yet-configuration' | translate }}
    </div>
    <app-access-control>
      <ng-container ngProjectAs="[slot][content]">
        <div *ngIf="isSparePartsCatalogAndHasProducts()" class="spare-parts-price-disclaimer">
          {{ 'catalog.spare-parts-price-disclaimer' | translate }}
        </div>
      </ng-container>
    </app-access-control>
  </div>
</div>
<div class="loader__overlay" *ngIf="!firstPageLoad && loading">
  <div class="loader__content">
    <div class="loading-animation"></div>
  </div>
</div>
