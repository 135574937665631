<div class="order-details">
  <ng-container *ngIf="orderDetails">
    <div class="order-details__header">
      <div class="column">
        <div class="base-info">
          <p>
            <span class="base-info__label" [attr.data-test]="'label-purchaseOrder'">
              {{ 'spare-part-item-details.purchase-order' | translate }}:
            </span>
            <span class="base-info__text" [attr.data-test]="'desc-purchaseOrder'">
              {{ orderDetails.attributes?.sapPoNumber }}
            </span>
          </p>
          <p *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber">
            <span class="base-info__label" [attr.data-test]="'label-returnOrderNumber'">
              {{ 'spare-part-item-details.return-order-number' | translate }}:
            </span>
            <span class="base-info__text" [attr.data-test]="'desc-returnOrderNumber'">
              {{ orderDetails.attributes.sapDetails?.returnAuthorizationNumber }}
            </span>
          </p>
        </div>
        <a *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber"
           [attr.data-test]="'link-downloadReturnDocument'"
           (click)="downloadReturnDocument()">
          <span class="icon-arrow-right"></span>
          <span>
            {{ 'spare-part-item-details.download-return-doc' | translate }}
          </span>
        </a>
      </div>
      <div class="column">
        <a (click)="generateOrderDetailPdf()" 
           [attr.data-test]="'link-pdfExport'">
          <ng-container *ngIf="!pdfGenerationInProgress; else loadingSpinner">
            <span class="icon-arrow-right"></span>
            <span>
              {{ 'spare-part-item-details.pdf-export' | translate }}
            </span>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="btn-spinner">
              <div class="loading-animation"></div>
            </div>
          </ng-template>
        </a>
        <a *ngIf="!isBusinessPartner" [ngClass]="{'disabled': isAddToCartDisabled() && !isAddToCartInProgress()}"
           [attr.data-test]="'link-reorderAllItems'"
           (click)="addItemsToCart(orderDetails.attributes?.items)">
          <ng-container *ngIf="!isAddToCartInProgress(); else loadingSpinner">
            <span class="icon-arrow-right"></span>
            <span>
            {{ 'spare-part-item-details.reorder-all-items' | translate }}
          </span>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="btn-spinner">
              <div class="loading-animation"></div>
            </div>
          </ng-template>
        </a>
        <a [attr.data-test]="'link-showOrderDetails'" (click)="redirectToOrderDetails(orderDetails.id)">
          <span class="icon-arrow-right"></span>
          <span>
            {{ 'spare-part-item-details.show-order-details' | translate }}
          </span>
        </a>
      </div>
    </div>
    <span class="submit-return-doc">
      <a class="submit-return-doc__link" *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable"
         [attr.data-test]="'link-submitReturnDocument'"
         href="mailto: {{ 'service-purchase-activity.return-document-recipient-email' | translate }}
       ?Subject=Return Document {{ orderDetails.attributes.sapDetails.returnAuthorizationNumber }}">
        <span class="icon-arrow-right"></span> {{ 'service-purchase-activity.submit-email-document' | translate }}
      </a>
      <a class="submit-return-doc__msg" *ngIf="orderDetails.attributes.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable">
        <div>
          {{ 'service-purchase-activity.submit-return-document-text-line1' | translate }}<br>
          {{ 'service-purchase-activity.submit-return-document-text-line2' | translate }}
        </div>
     </a>
    </span>
    <div class="address-information">
      <p class="address-information__title">{{ 'spare-part-item-details.address-info' | translate }}:</p>
      <div class="address-information__container">
        <div class="ordered-by">
          <span class="greyed-out" [attr.data-test]="'label-orderedBy'">
            {{ 'spare-part-item-details.ordered-by' | translate }}:
          </span>
          <span [attr.data-test]="'label-customerName'">
            {{ orderDetails.attributes.pointOfContact?.firstName }} {{ orderDetails.attributes.pointOfContact?.lastName }}
          </span>
          <span [attr.data-test]="'label-customerEmail'">
            {{ orderDetails.attributes.pointOfContact?.email }}
          </span>
          <span [attr.data-test]="'label-customerPhone'">
            {{ orderDetails.attributes.pointOfContact?.phone }}
          </span>
        </div>
        <div class="address" *ngFor="let address of addresses" [attr.data-test]="'div-' + address.type">
          <span class="greyed-out" [attr.data-test]="'label-address'">
            {{ 'spare-part-item-details.' + address.translationKey | translate }}:
          </span>
          <span *ngIf="address.type === 'shippingAddress'" [attr.data-test]="'desc-attentionTo'">
            {{ formatIfMissingValue(orderDetails.attributes.pointOfContact?.attentionTo) }}
          </span>
          <span [attr.data-test]="'desc-addressName'">
            <ng-container *ngIf="orderDetails.attributes[address.type]?.sapId">
              {{ orderDetails.attributes[address.type]?.sapId }} -
            </ng-container>
            {{ formatIfMissingValue(orderDetails.attributes[address.type]?.company) }}
          </span>
          <span [attr.data-test]="'desc-addressStreet'">
            {{ formatIfMissingValue(orderDetails.attributes[address.type]?.address1) }}
          </span>
          <span [attr.data-test]="'desc-addressCity'">
            {{ formatIfMissingValue(orderDetails.attributes[address.type]?.city) + ' ' +
            formatIfMissingValue(orderDetails.attributes[address.type]?.zipCode) }}
          </span>
        </div>
      </div>
      <div *ngIf="isBusinessPartner" class="customer-information__container">
        <div class="customer">
         <span class="greyed-out" [attr.data-test]="'label-customer'">
            {{ 'purchase-activity.customer' | translate }}
          </span>
          <div [attr.data-test]="'desc-customer'">
            {{ orderDetails.attributes?.systemDetails.companyBusinessUnit }}
          </div>
        </div>
      </div>
    </div>
    <div class="ordered-items">
      <p class="ordered-items__title">{{ 'spare-part-item-details.ordered-items' | translate }}:</p>
      <div class="product__wrapper"
           *ngFor="let item of orderDetails.attributes?.items">
        <div class="product__image">
          <img [src]="item.metadata?.image" [alt]="item.name" [attr.data-test]="'img-productImage'">
        </div>
        <div class="product__details">
          <div class="product__header">
            <h5 class="product__name" [attr.data-test]="'desc-productName'">
              {{ item.name }}
            </h5>
            <span class="label item-exchange-material" *ngIf="item.sapDetails?.exchangeableItem">
              {{ 'spare-parts.exchangeable-item' | translate }}
            </span>
            <span class="label heavyweight-item" *ngIf="item.heavyweight">
              {{ 'spare-parts.heavyweight-item' | translate }}
            </span>
          </div>
          <p class="product__materialNumber" [attr.data-test]="'desc-productMaterialNumber'">
            {{ item.materialNumber }}
          </p>
          <div class="product__footer">
            <div class="product__info">
              <div class="display-flex flex-column product__info--label">
                <span [attr.data-test]="'label-quantity'">
                  {{ 'spare-part-item-details.quantity' | translate }}:
                </span>
                <span [attr.data-test]="'label-price'">
                  {{ 'spare-part-item-details.total-price' | translate }}:
                </span>
              </div>
              <div class="display-flex flex-column product__info--value">
                <span class="product__qty" [attr.data-test]="'desc-quantity'">
                {{ item.quantity }}
                </span>
                <span class="product__price" [attr.data-test]="'desc-price'">
                {{ item.sumPrice | currency: orderDetails.attributes?.currencyIsoCode }}
                </span>
              </div>
            </div>
            <div *ngIf="!isBusinessPartner" class="product__btn">
              <button
                  class="button button--small button--primary buy-again-button"
                  appDebounceClick
                  [debounceTime]="300"
                  (debounceClick)="addItemToCart(item)"
                  [disabled]="isAddToCartDisabled()"
                  [attr.data-test]="'button-buyAgain'">
                <span class="buy-again__icon icon-reload"></span>
                {{ 'spare-part-item-details.buy-again' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
