<div class="order-details">
  <ng-container *ngIf="orderDetails">
    <div class="order-details__header">
      <div class="column">
        <div class="base-info">
          <p>
            <span class="base-info__label" [attr.data-test]="'label-purchaseOrder'">
              {{ 'spare-part-item-details.purchase-order' | translate }}:
            </span>
            <span class="base-info__text" [attr.data-test]="'desc-purchaseOrder'">
              {{ orderDetails.attributes?.poNumber }}
            </span>
          </p>
        </div>
      </div>
      <div class="column">
        <a *ngIf="(this.isBusinessPartner$ | async) === false"
           [attr.data-test]="'link-openTeamplayFleet'"
           (click)="openInTeamplayFleet()">
          <ng-container *ngIf="!teamplayFleetDataLoading; else loadingSpinner">
            <span class="icon-arrow-right"></span>
            <span>
              {{ 'spare-part-item-details.select-teamplay-fleet' | translate }}
            </span>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="btn-spinner">
              <div class="loading-animation"></div>
            </div>
          </ng-template>
        </a>
        <a [attr.data-test]="'link-showOrderDetails'" (click)="redirectToOrderDetails(orderDetails.id)">
          <span class="icon-arrow-right"></span>
          <span>
            {{ 'spare-part-item-details.show-order-details' | translate }}
          </span>
        </a>
      </div>
    </div>
    <div class="address-information">
      <p class="address-information__title">{{ 'spare-part-item-details.address-info' | translate }}:</p>
      <div class="address-information__container">
        <div class="ordered-by">
          <span class="greyed-out" [attr.data-test]="'label-orderedBy'">
            {{ 'spare-part-item-details.ordered-by' | translate }}:
          </span>
          <span [attr.data-test]="'label-customerName'">
            {{ orderDetails.attributes?.userName }}
          </span>
          <span [attr.data-test]="'label-customerEmail'">
            {{ orderDetails.attributes?.userEmail }}
          </span>
          <span [attr.data-test]="'label-customerPhone'">
            {{ orderDetails.attributes?.phoneNumber }}
          </span>
        </div>
        <div class="address" *ngFor="let address of addresses" [attr.data-test]="'div-' + address.type">
          <span class="greyed-out" [attr.data-test]="'label-address'">
            {{ 'spare-part-item-details.' + address.translationKey | translate }}:
          </span>
          <span [attr.data-test]="'desc-addressName'">
            {{ formatIfMissingValue(orderDetails.attributes?.[address.type + 'Name']) }}
          </span>
          <span [attr.data-test]="'desc-addressStreet'">
            {{ formatIfMissingValue(orderDetails.attributes?.[address.type + 'Address']) }}
          </span>
          <span [attr.data-test]="'desc-addressCity'">
            {{ formatIfMissingValue(orderDetails.attributes?.[address.type + 'Town']) + ' ' +
          formatIfMissingValue(orderDetails.attributes?.[address.type + 'PostalOffice']) }}
          </span>
        </div>
      </div>
    </div>
    <div class="ordered-items">
      <p class="ordered-items__title">{{ 'spare-part-item-details.ordered-items' | translate }}:</p>
      <div class="product__wrapper"
           *ngFor="let item of orderDetails.attributes?.items">
        <div class="product__details">
          <div class="product__header">
            <h6 class="product__name" [attr.data-test]="'desc-productName'">
              {{ item.productName }}
            </h6>
          </div>
          <p class="product__materialNumber" [attr.data-test]="'desc-productMaterialNumber'">
            {{ item.productNumber }}
          </p>
          <div class="product__footer">
            <div class="product__info">
              <div class="display-flex flex-column product__info--label">
                <span [attr.data-test]="'label-quantity'">
                  {{ 'spare-part-item-details.quantity' | translate }}:
                </span>
                <span [attr.data-test]="'label-price'">
                  {{ 'spare-part-item-details.total-price' | translate }}:
                </span>
              </div>
              <div class="display-flex flex-column product__info--value">
                <span class="product__qty" [attr.data-test]="'desc-quantity'">
                  {{ item.quantity }}
                </span>
                <span class="product__price" [attr.data-test]="'desc-price'">
                  {{ item.itemPrice | currency: 'USD' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
