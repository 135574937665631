import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  pure: false
})
export class SearchFieldPipe implements PipeTransform{


  static checkInside(item: any, term: string, toCompare: string, excludes: any): boolean {

    if (typeof item === 'string' && item.toString().toLowerCase().includes(toCompare)) {
      return true;
    }

    for (const property in item) {
      if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
        continue;
      }
      if (typeof item[property] === 'object') {
        if (this.checkInside(item[property], term, toCompare, excludes)) {
          return true;
        }
      }
      else if (item[property].toString().toLowerCase().includes(toCompare)) {
        return true;
      }
    }
    return false;
  }

  static filter(items: Array<{ [key: string]: any }>, term: string, excludes: any): Array<{ [key: string]: any }> {

  const toCompare = term.toLowerCase();

  return items.filter( (item) => {
      return this.checkInside(item, term, toCompare, excludes);
    });
  }

  transform(items: any, term: string, excludes: any = []): any {
    if (!term || !items) { return items; }

    return SearchFieldPipe.filter(items, term, excludes);
  }
}
