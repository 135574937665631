import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
class HttpInterceptorCd implements HttpInterceptor {
  
  // Rename page[offset] and page[limit] in the request due to a inability to parse them in Directus
  sanitizeParams(req) {
    if (req.params.has('page[offset]') || req.params.has('page[limit]')) {
      const newParams = req.params
        .append('page_offset', req.params.get('page[offset]'))
        .append('page_limit', req.params.get('page[limit]'))
        .delete('page[offset]')
        .delete('page[limit]');
      req = req.clone({
        params: newParams
      });
    }

    try {
      const url = new URL(req.urlWithParams);
      if (url.searchParams.has('page[offset]') || url.searchParams.has('page[limit]')) {
        url.searchParams.set('page_offset', url.searchParams.get('page[offset]'));
        url.searchParams.set('page_limit', url.searchParams.get('page[limit]'));
        url.searchParams.delete('page[offset]');
        url.searchParams.delete('page[limit]');
        req = req.clone({
          url: url.toString()
        });
      }
    } catch (e) {
      // do nothing
    }

    return req;
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.sanitizeParams(req);
    return next.handle(req);
  }
}

export const HttpInterceptorCdProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpInterceptorCd,
  multi: true
};
