import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { ISocialMediaFooter, ISocialMediaFooterNode } from '../../models/footers.models';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-social-media-footer',
  templateUrl: './social-media-footer.component.html',
  styleUrls: ['./social-media-footer.component.scss'],
})
export class SocialMediaFooterComponent implements OnInit {

  content: ISocialMediaFooter;
  links: ISocialMediaFooterNode[] = [];
  nodes: ISocialMediaFooterNode[] = [];

  constructor(private configFacade: ConfigurationFacade) {
  }

  ngOnInit(): void {
    this.getSocialMediaFooter();
  }

  isIcon(cssClass: string): boolean {
    return cssClass.includes('icon');
  }

  private getSocialMediaFooter(): void {
    this.configFacade.getSocialMediaFooter().pipe(take(1)).subscribe(data => {
      this.content = data;
      this.nodes = this.content.data.attributes.nodes;
      for (const node of this.nodes) {
        if (node.isActive) {
          this.links.push(node);
        }
      }
      return this.links;
    });
  }
}
