<div class="card configurable-product" 
     [ngClass]="{'in-progress': isItemInProgress(product), 'card-col': layoutType !== 'grid'}">
  <div class="card__content">
    <div class="card__image">
      <ng-container *ngIf="hasImages(product); else defaultImage">
        <img alt="{{ product.abstractName }}" [src]="product.images[0].externalUrlSmall" />
      </ng-container>
      <ng-template #defaultImage>
        <img alt="{{ product.abstractName }}" [src]="defaultImg" />
      </ng-template>
    </div>
    <div class="card__body">
      <h2 [attr.data-test]="'title-productName'" class="card__title">
        {{ getConfigurableName(product) }}
      </h2>
      <p [attr.data-test]="'desc-productDescription'" class="card__desc">
        {{ product.description }}
      </p>
    </div>
    <app-dropdown [dropDownName]="'actions-menu'" class="mobile-dropdown-menu">
      <ng-container ngProjectAs="[slot][toggle]">
        <button class="button button--link">
          <span class="icon-arrow-down"></span>
        </button>
      </ng-container>
      <ng-container ngProjectAs="[slot][panel]">
        <app-access-control [notForViewer]="true">
          <ng-container ngProjectAs="[slot][nonViewerContent]">
            <ng-container *ngIf="!isConfigurableAvailable(product); else configurableServiceItem">
              <span [attr.data-test]="'link-contactUs'" class="menu__item"
                  [routerLink]="['/contact-us']"
                  [queryParams]="getExtendedContractParams(product)">
                {{ 'catalog.purchase-offline' | translate }}
              </span>
            </ng-container>
            <ng-template #configurableServiceItem>
              <span [attr.data-test]="'button-selectContract'" class="menu__item" (click)="handleSelectContract(product)">
                {{ 'catalog.configure-online' | translate }}
              </span>
            </ng-template>
          </ng-container>
        </app-access-control>
        <span class="menu__item"
              [attr.data-test]="'link-product-' + product.abstractSku"
            [routerLink]="['/product/', product.abstractSku]" 
            [queryParams]="getContractParams()"
            [title]="product.abstractName || product.abstractSku">
          {{ 'catalog.view-more' | translate }}
        </span>
      </ng-container>
    </app-dropdown>
    <div class="action-buttons">
      <app-access-control [notForViewer]="true">
        <ng-container ngProjectAs="[slot][nonViewerContent]">
          <ng-container *ngIf="!isConfigurableAvailable(product); else configurableServiceButton">
            <a class="button button--primary"
                [attr.data-test]="'link-contactUs'"
                [routerLink]="['/contact-us']"
                [queryParams]="getExtendedContractParams(product)">
              <span>
                {{ 'catalog.purchase-offline' | translate }}
              </span>
            </a>
          </ng-container>
          <ng-template #configurableServiceButton>
            <button [attr.data-test]="'button-selectContract'" type="button" class="button button--primary"
                    (click)="handleSelectContract(product)">
              {{ 'catalog.configure-online' | translate }}
            </button>
          </ng-template>
        </ng-container>
      </app-access-control>
      <button 
          class="button button--link icon-arrow-right button-details"
          [attr.data-test]="'link-product-' + product.abstractSku"
          [routerLink]="['/product/', product.abstractSku]" 
          [queryParams]="getContractParams()"
          [title]="product.abstractName || product.abstractSku">
        {{ 'catalog.view-more' | translate }}
      </button>
    </div>
  </div>
  <div class="loader__overlay">
    <span class="loading-animation"></span>
  </div>
</div>
