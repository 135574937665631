<div class="configurable-product-cart">
  <ng-container *ngIf="!isQuoteEmpty(); else emptyQuoteTemplate">
    <div class="multi-cart__current-cart">
      <div class="disclaimer">
        <span class="icon icon-exclamationmark icon-warning__circle"></span>
        {{ 'multi-cart.valid-warning' | translate }}
      </div>
      <h5>{{ 'multi-cart.selected-quote' | translate }}</h5>
      <div class="selected-quote">
        <div class="selected-quote-loader" *ngIf="loadingQuote">
          <div class="loading-animation"></div>
        </div>
        <div class="selected-quote-container">
          <div class="selected-quote-header">
            <div class="selected-quote-details">
              <h6 class="selected-quote-name">
                {{ getCurrentCartName() }}
              </h6>
              <button type="submit" class="button button--link button--add-nickname" (click)="showAddNicknameModalEmit()">
                {{ getLabelForAddNicknameButton() | translate }}
              </button>
              <p *ngIf="validUntilDate" class="valid-until-date">
                {{ 'multi-cart.valid-until-date' | translate: { validUntilDate: validUntilDate } }}
              </p>
            </div>
            <button type="submit" class="button button--link button--delete-quote" (click)="showDeleteQuoteModalEmit()">
              {{ 'multi-cart.delete-quote' | translate }} <span class="icon-close"></span>
            </button>
          </div>
          <div class="quote-items-list">
            <div class="list-header">
              <div class="header-col item-image blank"></div>
              <div class="header-col item-system-details">{{ 'multi-cart.functional-location' | translate }}</div>
              <div class="header-col item-configuration-status">{{ 'multi-cart.configuration-status' | translate }}</div>
              <div class="header-col item-price">{{ 'multi-cart.your-price' | translate }}</div>
            </div>
            <ng-container *ngFor="let item of currentCartItems">
              <div class="item-row">
                <div class="mobile-item-header hidden-lg-up">
                  <strong class="system-name-label">{{ 'multi-cart.functional-location' | translate }}</strong>
                  <button class="button button--link button--delete-item" 
                        (click)="removeItemOrDeleteQuoteEmit(item.attributes.sku, item.attributes.cpqConfigId)">
                    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
                  </button>
                </div>
                <div class="item-col item-image">
                  <img alt="{{ item.attributes.systemDetails?.nameEnUs }}" [src]="item.attributes.systemDetails?.externalUrlSmall">
                </div>
                <div class="item-col item-system-details">
                  <strong class="system-number">{{ item.attributes.systemDetails?.siemensEquipmentId }}</strong>
                  <span class="system-name">{{ item.attributes.systemDetails?.nameEnUs }}</span>
                  <span class="item-name hidden-lg-up">{{ item.attributes.name }}</span>
                </div>
                <div class="item-col item-configuration-status">
                  <span class="item-name hidden-md-down">{{ item.attributes.name }}</span>
                  <p class="configuration-status hidden-lg-up">
                    <strong>{{ 'multi-cart.configuration-status' | translate }}: </strong>
                    <span>{{ item.attributes.systemDetails?.contractStartDate | configurationStatus | translate }}</span>
                  </p>
                  <strong class="hidden-md-down">
                    {{ item.attributes.systemDetails?.contractStartDate | configurationStatus | translate }}
                  </strong>
                  <div class="configuration-status-action-buttons">
                    <button class="button button--link text-decoration-underline"
                        (click)="redirectWithParam('/service-configuration', item)">
                      {{ item.attributes.systemDetails.contractStartDate | editConfigurationLabel | translate }}
                    </button>
                    <span class="divider"></span>
                    <button class="button button--link text-decoration-underline"
                        (click)="redirectWithParam('/configuration-summary', item)">
                      {{ 'multi-cart.view' | translate }}
                    </button>
                  </div>
                </div>
                <div class="item-col item-price">
                  <p class="mobile-item-price">
                    <span class="hidden-lg-up">{{ 'multi-cart.your-price' | translate }}: </span>
                    <strong>{{ item.attributes.cpqNetPrice | currency: currency }}</strong>
                  </p>
                  <button class="button button--link button--delete-item hidden-md-down" 
                      (click)="removeItemOrDeleteQuoteEmit(item.attributes.sku, item.attributes.cpqConfigId)">
                    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="selected-quote-summary">
          <span class="service-contract-info">{{ 'multi-cart.review' | translate }}</span>
          <button type="submit" class="button button--primary" (click)="redirectWithParam('/quote-summary')">
            {{ 'multi-cart.continue' | translate }}
          </button>
          <div class="selected-quote-pricelist">
            <div class="pricelist-title">
              <strong>{{ 'multi-cart.list-price' | translate }}</strong>
            </div>
            <div class="pricelist-price">
              <strong>{{ totals.cpqListPriceTotal | currency: currency }}</strong>
            </div>
            <div class="pricelist-title" *ngIf="totals.cpqContractReductionTotal">
              {{ 'multi-cart.your-discount' | translate }}
            </div>
            <div class="pricelist-price" *ngIf="totals.cpqContractReductionTotal">
              <span class="pricelist-price-green">
                {{ -totals.cpqContractReductionTotal | currency: currency }}
              </span>
            </div>
            <div class="pricelist-title" *ngIf="totals.cpqPromocodeReductionTotal">
              {{ 'multi-cart.promo-code' | translate }}
            </div>
            <div class="pricelist-price" *ngIf="totals.cpqPromocodeReductionTotal">
              <span class="pricelist-price-green">
                {{ -totals.cpqPromocodeReductionTotal | currency: currency }}
              </span>
            </div>
            <div class="pricelist-total">
              <div class="pricelist-title">
                <strong>{{ 'multi-cart.your-annual-price' | translate }}</strong>
              </div>
              <div class="pricelist-price">
                <strong>{{ totals.cpqNetPriceTotal | currency: currency }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyQuoteTemplate>
    <div class="multi-cart__empty-quote">
      <div class="empty-quote-wrapper">
        <h6 class="empty-quote-header">{{ 'multi-cart.empty-quote-title' | translate }}</h6>
        <p class="empty-quote-text">{{ 'multi-cart.empty-quote-text' | translate }}</p>
        <button class="button" [routerLink]="['/catalog/service-contracts']">
          {{ 'multi-cart.catalog-link' | translate }}
        </button>
        <button class="button" [routerLink]="['/my-installed-base']" [queryParams]="{tab : EInstalledBaseTabs.CONTRACTS}">
          {{ 'multi-cart.contracts-link' | translate }}
        </button>
      </div>
      <div class="empty-quote-loader" *ngIf="loadingQuote">
        <div class="loading-animation"></div>
      </div>
    </div>
  </ng-template>
</div>
