<div class="textfield-wrapper">
  <div
      *ngIf="form; else noFormInput"
      [formGroup]="form"
      class="textfield width-100"
      [ngClass]="[
        isActive || modelValue ? 'is-active' : '',
        isInvalid || markInvalid ? 'is-invalid': '',
        readOnly ? 'read-only' : '',
        styles.wrapper
      ]"
      [attr.data-test]="'textfield-' + inputName">
    <div class="textfield-loader" *ngIf="loading">
      <div class="loading-animation"></div>
    </div>
    <input
        [attr.data-test]="dataTestClass"
        [type]="inputType"
        class="textfield__input"
        formControlName="{{ inputName }}"
        [min]="domain && domain.name === 'int' ? domain.min : ''"
        [max]="domain && domain.name === 'int' ? domain.max : ''"
        [id]="'textfield'+inputName"
        [value]="modelValue"
        [name]="inputName"
        [required]="isRequired"
        (keyup)="onKey($event)"
        (click)="activate()"
        (blur)="onBlur()"
        (change)="onKey($event)"
        [readonly]="readOnly"
        [trim]="trim"
        [trimWhitespaces]="trimWhitespaces"
        [multiCharToTrim]="multiCharToTrim"
        [maxlength]="maxLength"
    >
    <span *ngIf="icon" [ngClass]="icon"></span>
    <label *ngIf="!!label" class="textfield__label" [for]="'textfield'+inputName">
      {{ getLabel() }}
    </label>
  </div>
  <ng-template #noFormInput>
    <div
        class="textfield width-100"
        [ngClass]="[
        isActive || modelValue ? 'is-active' : '',
        isInvalid ? 'is-invalid': '',
        readOnly ? 'read-only' : '',
        styles.wrapper
      ]"
        [attr.data-test]="'textfield-' + inputName">
      <input
          [attr.data-test]="'input-' + inputType"
          [type]="inputType"
          class="textfield__input"
          [min]="domain && domain.name === 'int' ? domain.min : ''"
          [max]="domain && domain.name === 'int' ? domain.max : ''"
          [id]="'textfield'+inputName"
          [value]="modelValue"
          [name]="inputName"
          [required]="isRequired"
          (keyup)="onKey($event)"
          (click)="activate()"
          (blur)="onBlur()"
          (change)="onKey($event)"
          [readonly]="readOnly"
          [trim]="trim"
          [trimWhitespaces]="trimWhitespaces"
          [multiCharToTrim]="multiCharToTrim">
      <span *ngIf="icon" [ngClass]="icon"></span>
      <label *ngIf="!!label" class="textfield__label" [for]="'textfield'+inputName">
        {{ getLabel() }}
      </label>
    </div>
  </ng-template>
  <small *ngIf="hintText.length > 0 && hintOnError" class="textfield__hint">{{hintText}}</small>
</div>
