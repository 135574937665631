import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { DateUtils } from '../../utils/date.utils';
import { formatDate } from '@angular/common';
import { FlatpickrInstance } from 'ng2-flatpickr/src/flatpickr-instance';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  instance: FlatpickrInstance;
  isActive: boolean = false;
  isInvalid: boolean = false;
  isSelected: boolean = false;
  datepickerOptions: FlatpickrOptions = {
    monthSelectorType: 'static',
    altInput: true,
    altInputClass: 'textfield__input date-time-picker__input flatpickr-input',
    static: true,
    onOpen: () => {
      this.onOpen();
    },
    onClose: (selectedDates) => {
      this.onClose(selectedDates);
    },
    onChange: (selectedDates) => {
      this.onChange(selectedDates);
    },
    onReady: (_selectedDates, _dateStr, instance) => {
      this.onReady(instance);
    }
  };

  @Input() label: string;
  @Input() isRequired = false;
  @Input() readOnly = false;
  @Input() inputName: string;
  @Input() minimumDaysFromToday = 0;
  @Input() maxDate: string | Date;
  @Input() form: UntypedFormGroup;
  @Input() modelValue = '';
  @Input() dateFormat = 'M-d-Y';
  @Input() disabled = false;
  @Output() selected = new EventEmitter<{value: any, key: string}>();

  ngOnInit(): void {
    if (this.form) {
      this.isInvalid = this.form.controls[this.inputName].status === 'INVALID';
    }
    this.isActive = !!this.modelValue;
    this.datepickerOptions.altFormat = this.dateFormat;
    this.datepickerOptions.defaultDate = this.modelValue;
    this.datepickerOptions.minDate = this.minimumDaysFromToday ? DateUtils.getCurrentDate(this.minimumDaysFromToday) : null;
    this.datepickerOptions.maxDate = this.maxDate ? formatDate(this.maxDate, 'yyyy-MM-dd', 'en') : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.modelValue?.previousValue && !changes.modelValue?.currentValue) {
        this.resetDatepicker();
      }
    }, 0);
  }

  onOpen(): void {
    this.isActive = true;
  }

  onClose(selectedDates: Array<string>): void {
    if (selectedDates.length === 0) {
      this.isActive = false;
    }
    this.isSelected = selectedDates.length > 0;
  }

  onChange(selectedDates: any): void {
    this.selected.emit({value: selectedDates[0], key: this.inputName});
  }

  onReady(instance: FlatpickrInstance): void {
    this.instance = instance;
  }

  resetDatepicker(): void {
    this.instance.clear();
    this.instance.close();
  }

  getLabel(): string {
    return `${this.label}${this.isRequired ? '*' : ''}`;
  }
}
