<div *ngIf="!isLoading else loadingTemplate" class="details-container">
  <ng-container *ngIf="data.attributes.contractNumber === null">
    <h5>{{ 'service-purchase-activity.quote-status' | translate }}</h5>
    <div class="steps-bar">
      <app-steps-bar
          [currentStatus]="data.attributes.stateDisplayName"
          [comment]="data.id"
          [scenarioType]="scenarioType"
      ></app-steps-bar>
    </div>
    <button *ngIf="displayDownloadButton()" type="button" class="download-documentation" (click)="downloadDocumentation()">
      <span class="icon-download"></span>
      {{ 'service-purchase-activity.download-quote' | translate }}
    </button>
  </ng-container>
  <div class="additional-details">
    <app-service-additional-details [additionalData]="data" [flData]="functionalLocationData"></app-service-additional-details>
  </div>
  <ng-container *ngIf="data.attributes.stateDisplayName === orderStatus.PENDING_DOCUMENTATION">
    <div class="send-purchase-order">
      <p>{{ 'service-purchase-activity.send-purchase-order' | translate }}
        <a [href]="'mailto:' + ecommerceTeamEmailAddress" class="contracts"><span class="icon-email"></span><u>{{ ecommerceTeamEmailAddress }}</u></a><br />
        <app-tooltip
            class="what-if"
            link="{{ 'service-purchase-activity.what-if' | translate }}"
            text="{{ 'service-purchase-activity.my-org-purchase-order-tooltip' | translate }}">
        </app-tooltip>
      </p>
      <a [href]="'mailto:' + ecommerceTeamEmailAddress" class="button button--primary">
        {{ 'service-purchase-activity.email-documentation' | translate }}
      </a>
    </div>
  </ng-container>
</div>
<ng-template #loadingTemplate>
  <div class="display-flex f-align-items-center flex-column margin-top-1 margin-bottom-1">
    <div class="loading-animation"></div>
  </div>
</ng-template>

