<div class="sort-by" [ngClass]="cssClass" (click)="showOptions()" [attr.data-test]="'link-sort'">
  <div class="sort__title" *ngIf="hasTitle">
    <span *ngIf="showTitle()" [attr.data-test]="'desc-sortTitle'">
      {{ 'search-results.sorted-by' | translate }}
    </span>
    <span *ngIf="isActive()" [attr.data-test]="'desc-activeSortTitle'">
      {{ activeSortLabel() }}
    </span>
  </div>
  <div class="selectbox width-100" [ngClass]="{'is-open has-focus': isOpen}">
    <ul class="menu selectbox__option-list">
      <li class="selectbox__option" *ngFor="let option of sort">
        <ng-container *ngIf="current; else query">
          <span class="menu__item" 
              [ngClass]="[isSelected(option.name) ? 'is-selected' : '']" 
              (click)="selectSort(option)"
              [attr.data-test]="'desc-sort-' + option.value">
            {{ option.name | translate }}
          </span>
        </ng-container>
        <ng-template #query>
          <a class="menu__item" 
              [ngClass]="[isSelected(option.value) ? 'is-selected' : '' ]" 
              [routerLink]="pageUrl()" 
              [queryParams]="queryParams(option)"
              [attr.data-test]="'desc-sort-' + option.value">
            {{ option.name | translate }}
          </a>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
