import { Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { IHybrisOrder } from '../../../../../models/order.models';
import { AdvancedFilter } from '../../../../../models/advanced-filter';
import { SortByComponent } from '../../../../../shared/sort-by/sort-by.component';
import {
  genericDateSortOption,
  genericNumberSortOption,
  genericTextSortOption,
} from '../../../../../configurations/sort-options';
import { Subject } from 'rxjs';
import { ArrayUtils } from '../../../../../utils/array.utils';
import { IconUtils } from '../../../../../utils/icon.utils';
import { StringUtils } from '../../../../../utils/string.utils';

@Component({
  selector: 'app-spare-part-hybris-list',
  templateUrl: './spare-part-hybris-list.component.html',
  styleUrls: ['./spare-part-hybris-list.component.scss']
})
export class SparePartHybrisListComponent implements OnDestroy {
  tabsOpened = [] as Array<string>;

  @Input() hybrisOrders: IHybrisOrder[];
  @Input() filters: AdvancedFilter[];
  @Input() searchValue: string;

  @ViewChildren(SortByComponent)
  sortByComponent: QueryList<SortByComponent>;
  currentSort: any = {};

  headers = [
    {
      label: 'spare-part-list.order-id',
      class: 'order-id width-15',
      sortOptions: genericTextSortOption('id'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.date-ordered-by',
      class: 'order-date width-20',
      sortOptions: genericDateSortOption('attributes.orderCreationDate'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.equipment',
      class: 'order-equipment width-25',
      sortOptions: genericTextSortOption('attributes.equipment'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.order-status',
      class: 'order-status width-15',
      sortOptions: genericTextSortOption('attributes.orderStatus'),
      showOnMobile: true,
    },
    {
      label: 'spare-part-list.total',
      class: 'order-total-price width-15',
      sortOptions: genericNumberSortOption('attributes.orderTotal'),
      showOnMobile: true,
    },
  ];

  private unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sort(option: any): void {
    this.hybrisOrders = ArrayUtils.sortByAttribute(
      [...this.hybrisOrders],
      option.value,
      option.reversed,
      option?.time,
      option?.numeric,
    );

    this.sortByComponent.forEach(component => component.active = component.isOpen ? option : '');
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex(tab => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getStatusIcon(status: string): string {
    return IconUtils.getHybrisStatusIcon(status);
  }

  getFormattedStatus(status: string): string {
    return StringUtils.replaceUnderscores(status, ' ').split(' ').map(statusPart => {
      return statusPart.charAt(0).toUpperCase() + statusPart.slice(1).toLowerCase();
    }).join(' ');
  }

  getOrderQuantity(order: IHybrisOrder): number {
    let quantity = 0;

    order.attributes?.items.forEach(item => {
      quantity += item.quantity;
    });

    return quantity;
  }
}
