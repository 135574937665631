import { Iiso2Codes } from '../models/enviroment-delivery-details.model';
import { AppUtils } from './app.utils';
import { IAddressExtended } from '../models/customer.models';
import { IAddress } from '../models/common.models';

export class AddressUtils {

  static createAddressString(address: any): string {
    if (!address) return '';

    const street: string = address.address1 || '';
    const zipCode: string = address.zipCode || '';
    const city: string = address.city || '';
    const state: string = address.address2 || '';
    const country: string = address.country || '';

    const newAddressFormat: string = [street, `${zipCode},`, city, state, country]
                                      .filter(item => (item && item !== 'empty' && item !== 'empty,'))
                                      .join(', ')
                                      .replace(',,', '');

    if (newAddressFormat.endsWith(',') || newAddressFormat.endsWith(' ')) {
      return newAddressFormat.substring(0, newAddressFormat.length - 1);
    } else {
      return newAddressFormat;
    }
  }

  static createAddressStringForCpq(address: any): string {
    if (!address) return '';

    const company: string = address.name || '';
    const street: string = address.address1 || '';
    const city: string = address.city || '';
    const state: string = address.address2 || '';
    const country: string = address.state || '';
    const zipCode: string = address.zipCode || '';

    const newAddressFormat: string = [company, street, city, state, country, zipCode]
      .filter(item => (item && item !== 'empty' && item !== 'empty,')).join(', ');

    if (newAddressFormat.endsWith(',') || newAddressFormat.endsWith(' ')) {
      return newAddressFormat.substring(0, newAddressFormat.length - 1);
    } else {
      return newAddressFormat;
    }
  }

  static createAddressStringForCpqSelectedBillOrPayer(address: any): string {
    return !!address.name ? `${address.name}` : '';
  }

  static getBodyRequiredAddress(address: any): any {
    return {
      sapId: address.sapId,
      name: address.company ?? address.name,
      address1: address.address1,
      address2: address.address2,
      address3: address.address3,
      city: address.city,
      phone: address.phone,
      state: address.state,
      zipCode: address.zipCode,
    };
  }

  static changeAddressName(address: IAddressExtended): IAddressExtended {
    return {
      ...address,
      company: address.name,
      name: `${address.name} (${address.address1}, ${address.city}, ${address.state}, ${address.zipCode})`,
    } as IAddressExtended;
  }

  static getCountriesForDeliveryAddress(): Iiso2Codes[] {
    return AppUtils.getCurrentStore().deliveryAddressCountries;
  }

  static getDeliveryCountryByIsoCode(isoCode: string): Iiso2Codes {
    return AppUtils.getCurrentStore().deliveryAddressCountries.find(country => country.value === isoCode);
  }

  static appendAddresses(target: Array<any>, newAddresses: Array<any>, type: string, addressFromSystem = false): void {
    newAddresses.forEach(data => {
      const newAddress = {
        name: AddressUtils.createAddressString(data),
        value: {
          ...data,
          idCustomerAddress: type === 'customer' ? data.id : null,
          idCompanyUnitAddress: type === 'business' ? data.id : null,
        },
        addressFromSystem,
      };

      const addressFound = target.find(address => address.name === newAddress.name);
      if (!addressFound) {
        target.push(newAddress);
      }
    });
  }

  static appendAddressesForCpq(target: Array<any>, newAddresses: Array<any>, billOrPayer: boolean): void {
    newAddresses.forEach(data => {
      const newAddress = {
        name: billOrPayer ? AddressUtils.createAddressStringForCpqSelectedBillOrPayer(data) : AddressUtils.createAddressStringForCpq(data),
        value: {
          ...data
        },
      };

      const addressFound = target.find(address => address.name === newAddress.name);
      if (!addressFound) {
        target.push(newAddress);
      }
    });
  }

  static isAddressValid(address: IAddress, checkState: boolean = false): boolean {
    if (!address) {
      return false;
    }

    const isValid = !!(address.address1 && address.city && address.zipCode);

    return checkState ? isValid && !!address.state : isValid;
  }
}
