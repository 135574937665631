import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IFeatureProducts } from '../models/featured-products.models';

@Injectable({
  providedIn: 'root',
})
export class FeaturedProductsService {
  private featuredProductsEndpoint = '/content-product-abstract-lists/';
  private contentProducts = '/content-product-abstract';
  private glueUrl = this.glueConfiguration.getEndpointUrl();

  private httpParamsForFeatureProductItems = {
    params: new HttpParams()
      .set('include', 'abstract-product-image-sets,abstract-product-prices,product-labels'),
  };

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleError(err): Observable<any> {
    return throwError(
      err.error instanceof ErrorEvent ? `An error occurred: ${err.error.message}` : err.status,
    );
  }

  getFeaturedProducts(featureProductsKey: any): Observable<IFeatureProducts> {
    const url = this.glueUrl + this.featuredProductsEndpoint + featureProductsKey + this.contentProducts;
    return this.httpClient.get<IFeatureProducts>(url, this.httpParamsForFeatureProductItems)
      .pipe(
        catchError(error => FeaturedProductsService.handleError(error)),
      );
  }

}
