<div class="row">
  <div class="col-xl-10">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
        <h1>
          {{ 'error-404.title' | translate }}
        </h1>
        <div class="subtitle">
          {{ 'error-404.subtitle-1' | translate }}
        </div>
        <div>
        <ul>
          <li>
            <span class="text">
              {{ 'error-404.point-1' | translate }}
            </span>
          </li>
          <li>
            <span class="text">
              {{ 'error-404.point-2' | translate }}
            </span>
          </li>
        </ul>
        </div>
        <div class="subtitle">
          {{ 'error-404.recommendation-msg' | translate }}
        </div>
        <div class="home-page-link">
          <a [routerLink]="['/']" class="button button--link">
            <span class="icon-arrow-right-small"></span>
            {{ 'error-404.home-page-btn' | translate }}
          </a>
        </div>
      </div>
      <div class="col-sm-5">
        <img src="assets/img/error.png"
             alt="Error - page not found."
             class="error-img"
        />
      </div>
    </div>
  </div>
</div>

