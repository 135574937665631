import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { IContractParams } from '../../models/catalog.models';
import { MarketingFacade } from '../../facades/marketing.facade';
import { CpqFacade } from '../../facades/cpq.facade';
import { IGuidedQuestion } from '../../models/cpq.models';

@Component({
  selector: 'app-guided-questions',
  templateUrl: './guided-questions.component.html',
  styleUrls: ['./guided-questions.component.scss'],
})
export class GuidedQuestionsComponent implements OnInit, OnDestroy {
  currentQuestionIndex = 0;
  contractParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
  };
  cartId = '';
  questionsQuantity = 0;
  currentQuestion: IGuidedQuestion;
  showLoader = true;
  answerExplanation = '';
  currentAnswer: any;
  configId = '';
  questions: any;
  skipAnswer = false;
  recommendations: Array<string> = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private marketingFacade: MarketingFacade,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.contractParams['fl-number'] = params['fl-number'] ?? '';
      this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
      this.selectCartId();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleAnswer(answer: any): void {
    this.currentAnswer = this.currentAnswer !== answer ? answer : undefined;
    this.getExplanation();
  }

  private getExplanation(): void {
    if (!this.currentAnswer || !this.currentQuestion.properties.hasOwnProperty(this.currentAnswer.name)) {
      this.answerExplanation = '';
      return;
    }
    this.answerExplanation = this.currentQuestion.properties[this.currentAnswer.name];
  }

  goToTheNextQuestion(): void {
    this.commitQuestion(this.currentQuestion.name, this.currentAnswer.name);
  }

  skipQuestion(): void {
    this.commitQuestion(this.currentQuestion.name, 'skip');
  }

  goBackToPreviousQuestion(): void {
    this.postCpqSetStep(this.questions[this.currentQuestionIndex - 1]);
  }

  goBackToStart(): void {
    this.postCpqGuidedInitialize();
  }

  selectContract(contractSku: string): void {
    this.marketingFacade.startContractConfiguration(
      contractSku,
      this.contractParams['fl-number'],
      this.contractParams['rel-product-sysivk'],
    );
  }

  showQuestion(): boolean {
    return this.currentQuestion && this.currentQuestionIndex < this.questionsQuantity - 1;
  }

  isRecommendationStep(): boolean {
    return this.currentQuestion && this.currentQuestionIndex === this.questionsQuantity - 1;
  }

  showNextButton(): boolean {
    return (!this.skipAnswer || (this.skipAnswer && this.currentAnswer)) && !this.isRecommendationStep();
  }

  showSkipButton(): boolean {
    return this.skipAnswer && !this.currentAnswer && !this.isRecommendationStep();
  }

  showBackButton(): boolean {
    return this.currentQuestionIndex > 0 && !this.isRecommendationStep();
  }

  private selectCartId(): void {
    this.marketingFacade.selectCartId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartId => {
        if (cartId) {
          this.cartId = cartId;
          this.postCpqGuidedInitialize();
        }
      },
    );
  }

  private postCpqGuidedInitialize(): void {
    this.showLoader = true;
    this.cpqFacade.postCpqGuidedInitialize(this.cartId, this.contractParams['fl-number'])
      .pipe(take(1)).subscribe(response => {
      const {steps, configId} = response.data.attributes.cpqResponse;
      this.configId = configId;
      this.setQuestions(steps);
    });
  }

  private commitQuestion(parameter: string, value: string): void {
    this.showLoader = true;
    this.cpqFacade.postCqpCommit(this.cartId, '', this.configId, parameter, value)
      .pipe(take(1)).subscribe(response => {
      const {steps} = response.data.attributes.cpqResponse;
      const questions = steps.splice(1, steps.length).filter(question => question.available);
      const currentQuestionIndex = questions.findIndex(question => question.current);
      this.postCpqSetStep(questions[currentQuestionIndex + 1]);
    });
  }

  private postCpqSetStep(step: any): void {
    this.showLoader = true;
    this.cpqFacade.postCpqSetStep(this.cartId, this.configId, step.name)
      .pipe(take(1)).subscribe(response => {
      const {steps} = response.data.attributes.cpqResponse;
      this.setQuestions(steps);
    });
  }

  private setQuestions(steps: any): void {
    this.questions = steps.splice(1, steps.length).filter(question => question.available);
    this.questionsQuantity = this.questions.length;
    this.currentQuestionIndex = this.questions.findIndex(step => step.current);
    const currentQuestion = this.questions[this.currentQuestionIndex].rootGroup.members[0].members
      .filter(m => m.properties.hidden === 'no')[0];
    this.currentQuestion = this.filterQuestion(currentQuestion);
    this.currentAnswer = this.currentQuestion.committed
      ? this.currentQuestion.domain.elements.find(answer => answer.selected) : undefined;
    this.getExplanation();

    if (this.currentQuestionIndex + 1 === this.questions.length) {
      const recommendations = currentQuestion.domain.elements
        .filter(e => e.state === 'green')
        .filter(e => e.name !== 'noRecomendation')
        .map(e => e.name);
      this.recommendations = recommendations.length < 3 ? recommendations : recommendations.splice(0, 3);
    }
    this.showLoader = false;
  }

  private filterQuestion(question: any): any {
    this.skipAnswer = !!question.domain.elements.find(element => element.name.toLowerCase() === 'skip');
    if (!this.skipAnswer) {
      return question;
    }

    const elements = question.domain.elements.filter(element => element.name.toLowerCase() !== 'skip');
    return {...question, domain: {...question.domain, elements}};
  }
}
