<div [appLoadingAnimation]="isCartEmpty" class="container">
  <app-request-details-section
      [ngClass]="!isOnDetailsPage ? 'hidden' : ''"
      [cartItems]="cartItems"
      [isCartEmpty]="isCartEmpty"
      [isRfqOnly]="isRfqOnly"
      [minimumOrderValue]="minimumOrderValue"
      [currency]="currency"
      [currentCart]="currentCart"
      [loggedUserRoles]="loggedUserRoles"
      (formSubmitted)="proceedToContactDetails($event)">
  </app-request-details-section>
  <app-contact-details-section
      [ngClass]="isOnDetailsPage ? 'hidden' : ''"
      [creatingOrderInProgress]="creatingOrderInProgress"
      [cartItems]="cartItems"
      [isCartEmpty]="isCartEmpty"
      [isRfqOnly]="isRfqOnly"
      [minimumOrderValue]="minimumOrderValue"
      [currency]="currency"
      [currentCart]="currentCart"
      [isAuStore]="isAuStore"
      (backToDetailsClicked)="proceedToRequestDetails()"
      (formSubmitted)="createRequestQuote($event)">
  </app-contact-details-section>
</div>
<app-modal
    [open]="showApprovalModal"
    title="{{ getModalTitle() | translate }}"
    (crossClick)="backToHomepage()"
    (outsideClick)="backToHomepage()">
  <ng-container ngProjectAs="[slot][body]">
    <h5>{{ getModalBody() | translate }}</h5>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <a type="submit" class="button button--primary" data-dismiss="modal-dialog"
       (click)="backToHomepage()">Ok</a>
  </ng-container>
</app-modal>
