import {
  IOrderAddress,
  IOrderCustomer, IOrderDataAttributes,
  IOrderResponseWithMessages, IOrderSapDetails,
  IOrderWithMessages,
} from '../../../models/order.models';
import { ITotals } from '../../../models/common.models';

export const addVoucherResponseSuccess = {
  data: {
    type: 'carts',
    id: '1819d5a9-b7cf-568e-bff3-13bdb46333d6',
    attributes: {
      priceMode: 'GROSS_MODE',
      currency: 'BRL',
      store: 'BR',
      name: '1621243137156',
      isDefault: true,
      totalItemsQuantity: 1,
      createdAt: '2021-05-17 09:18:58.000000',
      orderedAt: '2021-05-17 09:21:41.000000',
      totals: {
        expenseTotal: 0,
        discountTotal: 303058,
        taxTotal: 0,
        subtotal: 3030580,
        grandTotal: 2727522,
        priceToPay: 2727522,
      },
      customer: {
        email: 'j.bialon@accenture.com',
        salutation: 'Mr',
        firstName: 'Jaroslaw',
        lastName: 'Bialon',
        idCustomer: 9,
        customerReference: 'Z00482PZ',
        idCompanyBusinessUnit: null,
        uuidCompanyUser: null,
      },
      pointOfContact: {
        department: 'Accounting / Finance',
        firstName: 'jarosalw',
        lastName: 'bialon',
        phone: '1231421',
        deliveryTime: '',
        comment: '',
        email: null,
      },
      approverDetails: {
        uuidQuoteApproval: null,
        uuidQuote: null,
        status: null,
        approverId: null,
        firstName: null,
        lastName: null,
        comment: null,
        dueDate: null,
        companyUserId: null,
      },
      shippingAddress: {
        id: null,
        salutation: 'Ms',
        firstName: 'empty',
        lastName: 'empty',
        address1: 'Avenida Juruá, 434',
        address2: 'empty',
        address3: '',
        zipCode: 'empty',
        city: 'BARUERI',
        country: 'empty',
        iso2Code: 'BR',
        company: 'empty',
        phone: 'empty',
        isDefaultBilling: false,
        isDefaultShipping: false,
        idCompanyBusinessUnitAddress: null,
        idCustomerAddress: null,
        idCompanyUnitAddress: 'edd39476-76d2-5317-a10c-ac56cdfdd094',
      },
      discounts: [
        {
          displayName: 'Voucher free products',
          amount: 303058,
          code: 'va2ta',
        },
      ],
    },
    links: {
      self: '',
    },
    relationships: {
      vouchers: {
        data: [
          {
            type: 'vouchers',
            id: 'va2ta',
          },
        ],
      },
    },
  },
  included: [
    {
      type: 'vouchers',
      id: 'va2ta',
      attributes: {
        amount: 303058,
        code: 'va2ta',
        discountType: 'voucher',
        displayName: 'Voucher free products',
        isExclusive: false,
        expirationDateTime: '2021-06-25 00:00:00.000000',
        discountPromotionAbstractSku: null,
        discountPromotionQuantity: null,
      },
      links: {
        self: '',
      },
    },
  ],
};

const customerMockTest: IOrderCustomer = {
  'email': 'test@siemens-healthineers.com',
  'salutation': 'Mr\/Mrs',
  'firstName': 'test',
  'lastName': 'test',
  'idCustomer': 21,
  'customerReference': 'z13zz',
  'idCompanyBusinessUnit': null,
  'uuidCompanyUser': 'null',
};

const billingAddress: IOrderAddress = {
  phone: '+421 000 000',
  salutation: 'salutation',
  firstName: 'Santa Clara',
  lastName: 'Hostpital',
  address1: 'Rua das Araucarias',
  address2: 'Rua das Araucarias',
  company: 'Company',
  city: 'Brazil',
  zipCode: '0850 25',
  iso2Code: '0580 52',
};

const shippingAddress: IOrderAddress = {
  salutation: 'Ms',
  firstName: 'Test',
  lastName: 'empty',
  address1: 'Address',
  address2: 'New',
  address3: '',
  zipCode: '123',
  city: 'Qwerty',
  country: 'Brazil',
  iso2Code: 'BR',
  company: 'empty',
  phone: 'empty',
};

export const totals: ITotals = {
  discountTotal: 0,
  expenseTotal: 0,
  grandTotal: 123456,
  subtotal: 0,
  taxTotal: 0
}

const sapDetailsData : IOrderSapDetails = {
  availability: '',
  exchangeCredit: '',
  exchangeableItem: false,
  returnAuthorizationNumber: '12356'
}

export const orderData: IOrderWithMessages = {
  type: 'orders',
  id: 'BR--1',
  attributes: {
    createdAt: '2022-07-18 10:49:13.000000',
    currencyIsoCode: 'BRL',
    priceMode: 'GROSS_MODE',
    stateDisplayName: 'new',
    stateComment: null,
    itemsTotalNumber: 1,
    sapId: null,
    trackingNumber: null,
    taxNumber: '',
    quoteName: '1658141173656',
    contractNumber: null,
    totals: totals,
    billingAddress: billingAddress,
    shippingAddress: shippingAddress,
    customer: customerMockTest,
    pointOfContact: null,
    approverDetails: null,
    soldToAddress: null,
    payerAddress: null,
    items: [
      {
        'name': 'Head holder screw',
        'sku': '08879595',
        'sumPrice': 42870,
        'quantity': 1,
        'unitGrossPrice': 42870,
        'sumGrossPrice': 42870,
        'taxRate': '0.00',
        'unitNetPrice': 0,
        'sumNetPrice': 0,
        'unitPrice': 42870,
        'unitTaxAmountFullAggregation': 0,
        'sumTaxAmountFullAggregation': 0,
        'refundableAmount': 42870,
        'canceledAmount': 0,
        'sumSubtotalAggregation': 42870,
        'unitSubtotalAggregation': 42870,
        'unitProductOptionPriceAggregation': 0,
        'sumProductOptionPriceAggregation': 0,
        'unitExpensePriceAggregation': 0,
        'sumExpensePriceAggregation': null,
        'unitDiscountAmountAggregation': 0,
        'sumDiscountAmountAggregation': 0,
        'unitDiscountAmountFullAggregation': 0,
        'sumDiscountAmountFullAggregation': 0,
        'unitPriceToPayAggregation': 42870,
        'sumPriceToPayAggregation': 42870,
        'taxRateAverageAggregation': '0.00',
        'taxAmountAfterCancellation': null,
        'uuid': null,
        'idShipment': 1,
        'deliveryAvailability': 'Currently in stock',
        'deliveryTime': 'Up to 12 days',
        'metadata': {
          'superAttributes': [],
          'image': 'https:\/\/digitalmedianp.shop.siemens-healthineers.com\/catalog\/products\/08879595_1_1280x1280.jpg',
        },
        'salesOrderItemConfiguration': null,
        'systemDetails': null,
        'cpqDetails': {
          'cpqConfigId': null,
          'modality': null,
        },
        'calculatedDiscounts': [],
        'productOptions': []
      },
    ],
    expenses: [
      {
        'type': 'SHIPMENT_EXPENSE_TYPE',
        'name': 'Standard',
        'sumPrice': 0,
        'unitGrossPrice': 0,
        'sumGrossPrice': 0,
        'taxRate': '0.00',
        'unitNetPrice': 0,
        'sumNetPrice': 0,
        'canceledAmount': null,
        'unitDiscountAmountAggregation': null,
        'sumDiscountAmountAggregation': null,
        'unitTaxAmount': 0,
        'sumTaxAmount': 0,
        'unitPriceToPayAggregation': 0,
        'sumPriceToPayAggregation': 0,
        'taxAmountAfterCancellation': null,
        'idShipment': 1,
        'idSalesExpense': 1,
      },
    ],
    payments: [
      {
        'amount': 42870,
        'paymentProvider': 'DummyPayment',
        'paymentMethod': 'invoice',
      },
    ],
    shipments: [
      {
        'shipmentMethodName': 'Standard',
        'carrierName': 'DHL',
        'deliveryTime': null,
        'defaultGrossPrice': 0,
        'defaultNetPrice': 0,
        'currencyIsoCode': 'BRL',
      },
    ],
    calculatedDiscounts: [],
    sapMessages: [
      {
        materialNumber: '07580900',
        type: 'I',
        message: 'Part selected does not match equipment modality, please double check if correct part was selected.'
      },
      {
        materialNumber: '',
        type: 'E',
        message: 'There seems to be an error with your order. Please call us at 1-800-888-7436.'
      },
      {
        materialNumber: '',
        type: 'W',
        message: 'Part is discontinued, please contact Parts Clarification team  1-800-888-7436 option 2.'
      },
      {
        materialNumber: '',
        type: 'E',
        message: 'There seems to be an error with your order. Please call us at 1-800-888-7436.'
      },
      {
        materialNumber: '07580900',
        type: 'I',
        message: 'Part is considered a consumable. Part is not returnable and not covered by contracts.'
      },
      {
        materialNumber: '',
        type: 'S',
        message: 'We are unable to retrieve availability at the moment. Please try again later or call us at 1-800-888-7436.'
      },
      {
        materialNumber: '10185346',
        type: 'E',
        message: 'Part number is invalid.'
      },
    ],
    sapPoNumber: '1234567',
    sapDetails: sapDetailsData
  },
};

export const orderReponse: IOrderResponseWithMessages = {
  data: orderData,
  links: {
    self: 'https:\/\/glue.br.shs-ecommerce.local:443\/checkout\/BR--1',
  },
};

const orderDataAttributes: IOrderDataAttributes = {
  createdAt: '2022-07-27 09:00:46.000000',
  currencyIsoCode: 'USD',
  priceMode: 'GROSS_MODE',
  stateDisplayName: 'new',
  stateComment: null,
  itemsTotalNumber: 1,
  sapId: '0037189585',
  trackingNumber: null,
  taxNumber: null,
  quoteName: '1658911852366',
  contractNumber: null,
  sapPoNumber: '987654321',
  totals: {
    'expenseTotal': 7000,
    'discountTotal': 0,
    'taxTotal': 0,
    'subtotal': 19500,
    'grandTotal': 26500,
    'canceledTotal': 0,
  },
  shippingAddress: {
    'salutation': 'Ms',
    'firstName': 'SUTTER HEALTH',
    'middleName': null,
    'lastName': 'empty',
    'address1': '3321 POWER INN RD STE 300',
    'address2': 'CA',
    'address3': '',
    'company': 'empty',
    'city': 'SACRAMENTO',
    'zipCode': '95826-3890',
    'poBox': null,
    'phone': 'empty',
    'cellPhone': null,
    'description': null,
    'comment': null,
    'email': null,
    'country': 'United States',
    'iso2Code': 'US',
  },
  customer: {
    'email': 'test.test@siemens-healthineers.com',
    'salutation': null,
    'firstName': 'test',
    'lastName': 'test',
    'idCustomer': 68,
    'customerReference': 'Z00444XX',
    'idCompanyBusinessUnit': null,
    'uuidCompanyUser': '591e1410-23f4-5df9-8279-585c95dd4d10',
  },
  pointOfContact: {
    'department': null,
    'firstName': 'test',
    'lastName': 'etst',
    'phone': '4756',
    'deliveryTime': null,
    'comment': '',
    'email': 'test.test@siemens-healthineers.com',
  },
  approverDetails: {
    'uuidQuoteApproval': null,
    'uuidQuote': null,
    'status': null,
    'approverId': null,
    'firstName': null,
    'lastName': null,
    'comment': null,
    'dueDate': null,
    'companyUserId': null,
  },
  systemDetails: {
    'serialNumber': null,
    'siemensEquipmentId': '400-373595',
    'materialNumber': '10143408',
    'namePtBr': null,
    'nameEnUs': 'ARCADIS Avantic Gen2',
    'company': null,
    'companyBusinessUnit': null,
    'companyBusinessUnitNumber': null,
    'contractStartDate': null,
    'endOfSupport': null,
    'contractExpirationDate': null,
    'contractDurationYears': null,
    'myEquipmentName': null,
    'customerDesc': null,
    'department': null,
    'externalUrlSmall': 'https://a1152ecommdigitalmedia.blob.core.windows.net/catalog/products/10143408.jpg',
    'contractName': null,
    'contractNumber': null,
  },
  soldToAddress: {
    'salutation': null,
    'firstName': 'empty',
    'middleName': null,
    'lastName': 'empty',
    'address1': 'empty',
    'address2': null,
    'address3': null,
    'company': null,
    'city': 'empty',
    'zipCode': 'empty',
    'poBox': null,
    'phone': null,
    'cellPhone': null,
    'description': null,
    'comment': null,
    'email': null,
    'country': {
      'id_country': 238,
      'name': 'United States',
      'iso2_code': 'US',
      'iso3_code': 'USA',
      'postal_code_mandatory': true,
      'postal_code_regex': '\\d{5}([ \\-]\\d{4})?',
      'regions': [],
    },
    'iso2Code': 'US',
  },
  payerAddress: null,
  sapDetails: {
    'returnAuthorizationNumber': '123456',
    'exchangeCredit': null,
    'availability': null,
    'exchangeableItem': null,
  },
  items: [
    {
      'name': 'iPAT cushion flex (small)',
      'sku': '10185346',
      'sumPrice': 19500,
      'quantity': 1,
      'unitGrossPrice': 19500,
      'sumGrossPrice': 19500,
      'taxRate': '0.00',
      'unitNetPrice': 0,
      'sumNetPrice': 0,
      'unitPrice': 19500,
      'unitTaxAmountFullAggregation': 0,
      'sumTaxAmountFullAggregation': 0,
      'refundableAmount': 19500,
      'canceledAmount': 0,
      'sumSubtotalAggregation': 19500,
      'unitSubtotalAggregation': 19500,
      'unitProductOptionPriceAggregation': 0,
      'sumProductOptionPriceAggregation': 0,
      'unitExpensePriceAggregation': 0,
      'sumExpensePriceAggregation': null,
      'unitDiscountAmountAggregation': 0,
      'sumDiscountAmountAggregation': 0,
      'unitDiscountAmountFullAggregation': 0,
      'sumDiscountAmountFullAggregation': 0,
      'unitPriceToPayAggregation': 19500,
      'sumPriceToPayAggregation': 19500,
      'taxRateAverageAggregation': '0.00',
      'taxAmountAfterCancellation': null,
      'orderReference': null,
      'uuid': null,
      'isReturnable': true,
      'idShipment': 218,
      'deliveryAvailability': 'Currently in stock',
      'deliveryTime': 'Up to 12 days',
      'materialNumber': '10185346',
      'metadata': {
        'superAttributes': [],
        'image': 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
      },
      'salesOrderItemConfiguration': null,
      'systemDetails': {
        'serialNumber': null,
        'siemensEquipmentId': '400-373595',
        'materialNumber': '10143408',
        'namePtBr': null,
        'nameEnUs': 'ARCADIS Avantic Gen2',
        'company': null,
        'companyBusinessUnit': null,
        'companyBusinessUnitNumber': null,
        'contractStartDate': null,
        'endOfSupport': null,
        'contractExpirationDate': null,
        'contractDurationYears': null,
        'myEquipmentName': null,
        'customerDesc': null,
        'department': null,
        'externalUrlSmall': null,
        'contractName': null,
        'contractNumber': null,
      },
      'cpqDetails': {
        'cpqConfigId': null,
        'modality': null,
      },
      'sapDetails': {
        'returnAuthorizationNumber': null,
        'exchangeCredit': null,
        'availability': true,
        'exchangeableItem': true,
      },
      'calculatedDiscounts': [],
      'productOptions': [],
      trackingInformation: [{
        trackingNumber: '111222333',
        trackingLink: 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
      },
        {
          trackingNumber: '4455333',
          trackingLink: 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
        }],
    },
  ],
  expenses: [
    {
      'type': 'SHIPMENT_EXPENSE_TYPE',
      'name': 'Standard',
      'sumPrice': 7000,
      'unitGrossPrice': 7000,
      'sumGrossPrice': 7000,
      'taxRate': '0.00',
      'unitNetPrice': 0,
      'sumNetPrice': 0,
      'canceledAmount': null,
      'unitDiscountAmountAggregation': null,
      'sumDiscountAmountAggregation': null,
      'unitTaxAmount': 0,
      'sumTaxAmount': 0,
      'unitPriceToPayAggregation': 7000,
      'sumPriceToPayAggregation': 7000,
      'taxAmountAfterCancellation': null,
      'idShipment': 218,
      'idSalesExpense': 218,
    },
  ],
  payments: [
    {
      'amount': 19500,
      'paymentProvider': 'InvoicePayment',
      'paymentMethod': 'Pay by invoice',
    },
  ],
  shipments: [
    {
      'shipmentMethodName': 'Standard',
      'carrierName': 'DHL',
      'deliveryTime': null,
      'defaultGrossPrice': 7000,
      'defaultNetPrice': 0,
      'currencyIsoCode': 'USD',
      'sapShippingConditionCode': '',
    },
  ],
  calculatedDiscounts: [],
  sapMessages: [],
};

const orderDetailsData: IOrderWithMessages = {
  attributes: orderDataAttributes,
  type: 'order',
  id: '123456789',
};

export const orderDetails: IOrderResponseWithMessages = {
  data: orderDetailsData,
  links: {
    self: 'https://dev.br.glue.backoffice.siemens-healthineers.com:443/orders/0037189585',
  },
};

export const postCheckoutData = {
  type: 'checkout',
  id: 'BR--359',
  attributes: {
    orderReference: 'BR--359',
    redirectUrl: null,
    isExternalRedirect: null,
    taxNumber: null,
    customer: {
      email: 'j.bialon@accenture.com',
      salutation: 'Mr',
      firstName: 'Jaroslaw',
      lastName: 'Bialon',
      idCustomer: 9,
      customerReference: 'Z00482PZ',
      idCompanyBusinessUnit: null,
      uuidCompanyUser: null,
    },
    billingAddress: {
      id: null,
      salutation: 'Mr',
      firstName: 'empty',
      lastName: 'empty',
      address1: 'R. Marselhesa, 500',
      address2: 'empty',
      address3: null,
      zipCode: 'empty',
      city: 'São Paulo',
      country: 'Brazil',
      iso2Code: 'BR',
      company: 'empty',
      phone: 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
      idCompanyBusinessUnitAddress: null,
      idCustomerAddress: null,
      idCompanyUnitAddress: '6cf1c8d3-65b9-5318-8e5a-f75af216a702',
    },
    shippingAddress: {
      id: null,
      salutation: 'Ms',
      firstName: 'Test',
      lastName: 'empty',
      address1: 'Address',
      address2: 'New',
      address3: '',
      zipCode: '123',
      city: 'Qwerty',
      country: 'Brazil',
      iso2Code: 'BR',
      company: 'empty',
      phone: 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
      idCompanyBusinessUnitAddress: null,
      idCustomerAddress: 'ea2d3fa9-74eb-5758-87bc-c59b24b17fb8',
      idCompanyUnitAddress: null,
    },
    shipment: {
      idShipmentMethod: 1,
    },
    pointOfContact: {
      department: 'Accident & Emergency',
      firstName: 'Jaroslaw',
      lastName: 'Bialon',
      phone: '123',
      deliveryTime: '',
      comment: '',
      email: 'j.bialon@accenture.com',
    },
    approverDetails: null,
    payments: [
      {
        paymentProviderName: 'DummyPayment',
        paymentMethodName: 'invoice',
        paymentSelection: 'dummyPaymentInvoice',
      },
    ],
  },
  links: {
    self: 'https://dev.br.glue.backoffice.siemens-healthineers.com:443/checkout/BR--359',
  },
};

export const completedOrderItems1 = [
  {
    'name': 'iPAT cushion flex (small)',
    'sku': '10185346',
    'sumPrice': 19500,
    'quantity': 1,
    'unitGrossPrice': 19500,
    'sumGrossPrice': 19500,
    'taxRate': '0.00',
    'unitNetPrice': 0,
    'sumNetPrice': 0,
    'unitPrice': 19500,
    'unitTaxAmountFullAggregation': 0,
    'sumTaxAmountFullAggregation': 0,
    'refundableAmount': 19500,
    'canceledAmount': 0,
    'sumSubtotalAggregation': 19500,
    'unitSubtotalAggregation': 19500,
    'unitProductOptionPriceAggregation': 0,
    'sumProductOptionPriceAggregation': 0,
    'unitExpensePriceAggregation': 0,
    'sumExpensePriceAggregation': null,
    'unitDiscountAmountAggregation': 0,
    'sumDiscountAmountAggregation': 0,
    'unitDiscountAmountFullAggregation': 0,
    'sumDiscountAmountFullAggregation': 0,
    'unitPriceToPayAggregation': 19500,
    'sumPriceToPayAggregation': 19500,
    'taxRateAverageAggregation': '0.00',
    'taxAmountAfterCancellation': null,
    'orderReference': null,
    'uuid': null,
    'isReturnable': true,
    'idShipment': 218,
    'deliveryAvailability': 'Currently in stock',
    'deliveryTime': 'Up to 12 days',
    'materialNumber': '10185346',
    'metadata': {
      'superAttributes': [],
      'image': 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
    },
    'salesOrderItemConfiguration': null,
    'systemDetails': {
      'serialNumber': null,
      'siemensEquipmentId': '400-373595',
      'materialNumber': '10143408',
      'namePtBr': null,
      'nameEnUs': 'ARCADIS Avantic Gen2',
      'company': null,
      'companyBusinessUnit': null,
      'companyBusinessUnitNumber': null,
      'contractStartDate': null,
      'endOfSupport': null,
      'contractExpirationDate': null,
      'contractDurationYears': null,
      'myEquipmentName': null,
      'customerDesc': null,
      'department': null,
      'externalUrlSmall': null,
      'contractName': null,
      'contractNumber': null,
    },
    'cpqDetails': {
      'cpqConfigId': null,
      'modality': null,
    },
    'sapDetails': {
      'returnAuthorizationNumber': null,
      'exchangeCredit': null,
      'availability': true,
      'exchangeableItem': true,
    },
    'calculatedDiscounts': [],
    'productOptions': [],
  },
];

export const completedOrderItems2 = [
  {
    'name': 'iPAT cushion flex (small)',
    'sku': '10185346',
    'sumPrice': 19500,
    'quantity': 183,
    'unitGrossPrice': 19500,
    'sumGrossPrice': 19500,
    'taxRate': '0.00',
    'unitNetPrice': 0,
    'sumNetPrice': 0,
    'unitPrice': 19500,
    'unitTaxAmountFullAggregation': 0,
    'sumTaxAmountFullAggregation': 0,
    'refundableAmount': 19500,
    'canceledAmount': 0,
    'sumSubtotalAggregation': 19500,
    'unitSubtotalAggregation': 19500,
    'unitProductOptionPriceAggregation': 0,
    'sumProductOptionPriceAggregation': 0,
    'unitExpensePriceAggregation': 0,
    'sumExpensePriceAggregation': null,
    'unitDiscountAmountAggregation': 0,
    'sumDiscountAmountAggregation': 0,
    'unitDiscountAmountFullAggregation': 0,
    'sumDiscountAmountFullAggregation': 0,
    'unitPriceToPayAggregation': 19500,
    'sumPriceToPayAggregation': 19500,
    'taxRateAverageAggregation': '0.00',
    'taxAmountAfterCancellation': null,
    'orderReference': null,
    'uuid': null,
    'isReturnable': true,
    'idShipment': 218,
    'deliveryAvailability': 'Currently in stock',
    'deliveryTime': 'Up to 12 days',
    'materialNumber': '10185346',
    'metadata': {
      'superAttributes': [],
      'image': 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
    },
    'salesOrderItemConfiguration': null,
    'systemDetails': {
      'serialNumber': null,
      'siemensEquipmentId': '400-373595',
      'materialNumber': '10143408',
      'namePtBr': null,
      'nameEnUs': 'ARCADIS Avantic Gen2',
      'company': null,
      'companyBusinessUnit': null,
      'companyBusinessUnitNumber': null,
      'contractStartDate': null,
      'endOfSupport': null,
      'contractExpirationDate': null,
      'contractDurationYears': null,
      'myEquipmentName': null,
      'customerDesc': null,
      'department': null,
      'externalUrlSmall': null,
      'contractName': null,
      'contractNumber': null,
    },
    'cpqDetails': {
      'cpqConfigId': null,
      'modality': null,
    },
    'sapDetails': {
      'returnAuthorizationNumber': null,
      'exchangeCredit': null,
      'availability': true,
      'exchangeableItem': false,
    },
    'calculatedDiscounts': [],
    'productOptions': [],
  },
  {
    'name': 'iPAT cushion flex (small)',
    'sku': '10185346',
    'sumPrice': 25500,
    'quantity': 1,
    'unitGrossPrice': 25500,
    'sumGrossPrice': 25500,
    'taxRate': '0.00',
    'unitNetPrice': 0,
    'sumNetPrice': 0,
    'unitPrice': 25500,
    'unitTaxAmountFullAggregation': 0,
    'sumTaxAmountFullAggregation': 0,
    'refundableAmount': 25500,
    'canceledAmount': 0,
    'sumSubtotalAggregation': 25500,
    'unitSubtotalAggregation': 25500,
    'unitProductOptionPriceAggregation': 0,
    'sumProductOptionPriceAggregation': 0,
    'unitExpensePriceAggregation': 0,
    'sumExpensePriceAggregation': null,
    'unitDiscountAmountAggregation': 0,
    'sumDiscountAmountAggregation': 0,
    'unitDiscountAmountFullAggregation': 0,
    'sumDiscountAmountFullAggregation': 0,
    'unitPriceToPayAggregation': 25500,
    'sumPriceToPayAggregation': 25500,
    'taxRateAverageAggregation': '0.00',
    'taxAmountAfterCancellation': null,
    'orderReference': null,
    'uuid': null,
    'isReturnable': true,
    'idShipment': 218,
    'deliveryAvailability': 'Currently in stock',
    'deliveryTime': 'Up to 12 days',
    'materialNumber': '10185346',
    'metadata': {
      'superAttributes': [],
      'image': 'https://digitalmedianp.shop.siemens-healthineers.com/catalog/products/10185346_1_1280x1280.jpg',
    },
    'salesOrderItemConfiguration': null,
    'systemDetails': {
      'serialNumber': null,
      'siemensEquipmentId': '400-373595',
      'materialNumber': '10143408',
      'namePtBr': null,
      'nameEnUs': 'ARCADIS Avantic Gen2',
      'company': null,
      'companyBusinessUnit': null,
      'companyBusinessUnitNumber': null,
      'contractStartDate': null,
      'endOfSupport': null,
      'contractExpirationDate': null,
      'contractDurationYears': null,
      'myEquipmentName': null,
      'customerDesc': null,
      'department': null,
      'externalUrlSmall': null,
      'contractName': null,
      'contractNumber': null,
    },
    'cpqDetails': {
      'cpqConfigId': null,
      'modality': null,
    },
    'sapDetails': {
      'returnAuthorizationNumber': null,
      'exchangeCredit': null,
      'availability': true,
      'exchangeableItem': false,
    },
    'calculatedDiscounts': [],
    'productOptions': [],
  },
];

export const mockedVoucherList: any[] = [{
  'type': 'vouchers',
  'id': 'test_voucher_1',
  'attributes': {
    'amount': 100000,
    'code': 'test_voucher_1',
    'discountType': 'voucher',
    'displayName': 'test_voucher_1',
    'isExclusive': false,
    'expirationDateTime': '2099-12-31 00:00:00.000000',
    'discountPromotionAbstractSku': null,
    'discountPromotionQuantity': null
    },
    'links': {
      'self': ''
    }
}, {
    'type': 'vouchers',
    'id': 'test_voucher_2',
    'attributes': {
      'amount': 50000,
      'code': 'test_voucher_2',
      'discountType': 'voucher',
      'displayName': 'test_voucher_2',
      'isExclusive': false,
      'expirationDateTime': '2099-12-31 00:00:00.000000',
      'discountPromotionAbstractSku': null,
      'discountPromotionQuantity': null
    },
    'links': {
      'self': ''
    }
}];
