import { Injectable, Injector } from '@angular/core';
import { StoreSelectionService } from '../services/store-selection.service';

@Injectable({
  providedIn: 'root',
})
export class StoreSelectionFacade {
  private storeSelectionService: StoreSelectionService;

  public get getStoreSelectionService(): StoreSelectionService {
    if (!this.storeSelectionService) {
      this.storeSelectionService = this.injector.get(StoreSelectionService);
    }
    return this.storeSelectionService;
  }

  constructor(private injector: Injector) {
  }

  public isOnStoreSelectionPage(): boolean {
    return this.getStoreSelectionService.isOnStoreSelectionPage();
  }
}
