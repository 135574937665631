<div class="row">
  <div class="col-12 col-md-7 col-lg-8 order-1 order-md-0">
    <form [formGroup]="customerDataForm">
      <div class="row">
        <div class="col-6">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.firstName"
              [inputName]="'firstName'"
              [readOnly]="true"
              isRequired="true"
              label="{{ 'my-profile.form.first-name' | translate }}">
          </app-textfield>
        </div>
        <div class="col-6">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.lastName"
              [inputName]="'lastName'"
              [readOnly]="true"
              isRequired="true"
              label="{{ 'my-profile.form.last-name' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.address"
              [inputName]="'address'"
              [readOnly]="true"
              isRequired="true"
              label="{{ 'my-profile.form.address' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.city"
              [inputName]="'city'"
              [readOnly]="true"
              label="{{ 'my-profile.form.city' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.zip"
              [inputName]="'zip'"
              [readOnly]="true"
              label="{{ 'my-profile.form.zip' | translate }}">
          </app-textfield>
        </div>
        <div class="col-6">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.state"
              [inputName]="'state'"
              [readOnly]="true"
              label="{{ 'my-profile.form.state' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.email"
              [inputName]="'email'"
              [readOnly]="true"
              isRequired="true"
              label="{{ 'my-profile.form.email' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-textfield
              [form]="customerDataForm"
              [modelValue]="customerDataForm.value.phone"
              [inputName]="'phone'"
              [readOnly]="true"
              isRequired="true"
              label="{{ 'my-profile.form.phone' | translate }}">
          </app-textfield>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-select-box
            [form]="customerDataForm"
            [options]="languages"
            [modelValue]="customerDataForm.value.preferredLanguage"
            [inputName]="'preferredLanguage'"
            [disabled]="true"
            label="{{ 'my-profile.form.preferred-language' | translate }}"
            (selected)=setFormValue($event)>
          </app-select-box>
        </div>
      </div>
      <a href="{{ onlineServiceUrl }}" target="_blank" rel="noopener" class="button button--primary">
        {{ 'my-profile.button.edit-online-service' | translate }}
      </a>
    </form>
  </div>
  <div class="col-12 col-md-5 col-lg-4 order-0 order-md-1">
      <p class="border-accent border-accent--yellow paragraph-small">
        {{ 'my-profile.online-service-info-text' | translate }} 
        <a href="{{ onlineServiceUrl }}" target="_blank" rel="noopener" class="text-decoration-underline">Online Services</a>.
      </p>
  </div>
</div>
