import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IPrices } from '../../models/common.models';
import { ILabelAttributes, IProductLabel } from '../../models/abstract-product.models';
import { PriceUtils } from '../../utils/price.utils';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss'],
})
export class StickerComponent implements OnInit {
  label: ILabelAttributes;
  labelId = '';
  hasLabel = false;
  @Input() product: any;
  @Input() isCatalog: boolean;
  @Input() prices: IPrices[];
  @Input() labels: IProductLabel[];

  hasProductLabels: boolean;

  ngOnInit(): void {
    this.hasProductLabels = this.product.hasOwnProperty('product-labels');

    if (this.labelsExists()) {
      this.setLabel();
    }
  }

  setLabel(): void {
    if (this.isCatalog && this.hasProductLabels) {
      this.labelId = this.product['product-labels'].data[0].id;
    } else {
      this.labelId = this.product.relationships['product-labels'].data[0].id;
    }
    this.label = this.labels.find(label => label.id === this.labelId).attributes;

    if (environment.discountStickers.includes(this.label?.frontEndReference?.toLocaleUpperCase())) {
      this.label = {
        ...this.label,
        name: this.getDiscountLabel()
      };
    }

    this.hasLabel = !!this.label?.name;
  }

  private getDiscountLabel(): string {
    if (!this.prices) {
      return '';
    }
    const discountPrice = PriceUtils.getPriceValue(PriceUtils.getDefaultPrice(this.prices));
    const standardPrice = PriceUtils.getPriceValue(PriceUtils.getGuestPrice(this.prices));

    return this.getDiscountValue(discountPrice, standardPrice);
  }

  labelsExists(): boolean {
    if (this.isCatalog && this.product && this.hasProductLabels) {
      return this.product['product-labels'].data.length > 0;
    }

    if (!this.product?.relationships || !this.product.relationships['product-labels']) {
      return false;
    }

    return this.product.relationships['product-labels']?.data.length > 0;
  }

  private getDiscountValue(discountPrice?: number, standardPrice?: number): string {
    if (discountPrice === undefined || standardPrice === undefined) {
      return '';
    }
    const percentage = (discountPrice / standardPrice) * 100;
    const rounded = Math.round(percentage);

    return rounded <= 95 ? `${100 - rounded} %` : '';
  }
}
