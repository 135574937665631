<div class="modal-dialog" [ngClass]="[open ? 'is-open':  '', cssStyles.wrapper]" role="dialog">
  <div class="modal-dialog__container" role="document" #modal>
    <div class="modal-dialog__header">
      <span aria-hidden="true" class="modal-dialog__indicator"></span>
      <div class="modal-dialog__title" [id]="'modalTitle'+id">
        <span *ngIf="type" class="modal-dialog__title--icon icon-{{ type }}__circle icon-{{ type }}"></span>
        <span class="modal-dialog__title--text">{{ title }}</span>
      </div>
      <button
          *ngIf="showCrossCloseBtn"
          type="button"
          class="modal-dialog__close"
          data-dismiss="modal-dialog"
          aria-label="Close"
          (click)="hideModal()">
        <span aria-hidden="true" class="icon-close"></span>
      </button>
    </div>
    <div [id]="'modalBody'+id" class="modal-dialog__body">
      <ng-content select="[slot][body]"></ng-content>
    </div>
    <div class="modal-dialog__footer">
      <ng-content select="[slot][footer]"></ng-content>
    </div>
  </div>
</div>
