<div class="recommendations" [appLoadingAnimation]="!isLoaded">
  <div class="recommendations__selection">
    <div class="recommendation__info">
      <h4 class="recommendation__info__title"
          *ngIf="abstractProducts.length === 1">{{ 'contract-recommendations.your-option' | translate }}</h4>
      <h4 class="recommendation__info__title"
          *ngIf="abstractProducts.length > 1">{{ 'contract-recommendations.your-options' | translate }}</h4>
      <p class="recommendation__info--desc">{{ 'contract-recommendations.your-options-desc' | translate }}</p>
      <button
          type="button"
          class="button" [attr.data-test]="'button-startOver'"
          (click)="goBackToStart()">
        {{ 'contract-recommendations.start-over' | translate }}
      </button>
      <button
          type="button"
          class="button recommendations__back-btn" [attr.data-test]="'button-back'"
          (click)="goBackOneStep.emit()">
        {{ 'guided-questions.answer-back-btn' | translate }}
      </button>
    </div>
    <div class="recommendation__options">
      <div class="recommendation__option" *ngFor="let abstractProduct of abstractProducts">
        <h5 class="recommendation__option__title">{{ abstractProduct.attributes.name }}</h5>
        <h4 class="recommendation__option__price-scale">
          <ng-container *ngFor="let expensiveness of [1,2,3,4]">
            <span [class.price]="abstractProduct.attributes.attributes.expensiveness >= expensiveness">$</span>
          </ng-container>
        </h4>
        <div class="recommendation__option__desc">{{ abstractProduct.attributes.description }}</div>
        <div class="recommendation__option__additional-desc">{{ abstractProduct.attributes.attributes.second_description }}</div>
        <div class="recommendation__option__joined">
          <div *ngIf="abstractProduct.attributes.attributes.available === '0'"
               class="recommendation__option__availability">
            {{ 'contract-recommendations.not-available-online' | translate }}
          </div>
          <button
              *ngIf="abstractProduct.attributes.attributes.available === '1'"
              type="button"
              class="recommendation__option__button button button--primary" [attr.data-test]="'button-select'"
              (click)="selectContract(abstractProduct.attributes.sku)">
            {{ 'contract-recommendations.select' | translate }}
          </button>
          <button
              *ngIf="abstractProduct.attributes.attributes.available !== '1'"
              type="button"
              class="recommendation__option__button button button--primary" [attr.data-test]="'button-contactUs'"
              (click)="contactUs(abstractProduct)">
            {{ 'contract-recommendations.contact-us' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="recommendations__info">
    <div class="recommendation__info">
      <div class="item" *ngFor="let attribute of infoItems">
        <p>{{ attribute.value }}</p>
        <app-tooltip
            *ngIf="hasTranslation('contract-recommendations.' + attribute.key)"
            class="accordion__category-details__info-icon"
            link="icon"
            text="{{ 'contract-recommendations.' + attribute.key | translate }}"></app-tooltip>
      </div>
    </div>
    <div class="recommendation__details">
      <div class="recommendation__detail" *ngFor="let abstractProduct of abstractProducts">
        <ng-container *ngFor="let attribute of infoItems">
          <p [innerHTML]="getDetailAttribute(abstractProduct.attributes.attributes[attribute.key])"></p>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="addItemInProgress" class="recommendations__loader loader__overlay">
    <span class="loading-animation"></span>
  </div>
</div>
