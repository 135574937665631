import { Component, OnInit } from '@angular/core';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { IStoreAddress } from '../../models/settings.model';
import { AppUtils } from '../../utils/app.utils';
import { EStoreTypes } from '../../configurations/common';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  content: any;
  nodes: any;
  links = [];
  storeAddress: IStoreAddress;
  phone: string;
  email: string;
  phoneNumberFormat: string;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isInStore: boolean = false;
  emailGrievancesOfficer: string;
  prefix: string;
  delimiter: string;

  constructor(
    private configFacade: ConfigurationFacade,
    private utils: UtilsService,
  ) {
  }

  ngOnInit(): void {
    this.getNavigation();
    this.getStoreAddressInfo();
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.isInStore = AppUtils.isStoreActive(EStoreTypes.IN);
    this.emailGrievancesOfficer = AppUtils.getCurrentStore().emailGrievancesOfficer;
    this.utils.getCustomRouteData();
  }

  private getStoreAddressInfo(): void{
    this.storeAddress = this.configFacade.getCurrentStoreAddress();
    if (this.storeAddress) {
      this.phone = this.storeAddress.phoneNumber;
      this.email = this.storeAddress.emailAddress;
      this.phoneNumberFormat = this.storeAddress.phoneNumberFormat;
      this.prefix = this.storeAddress.prefix;
      this.delimiter = this.storeAddress.delimiter;
    }
  }

  public getAddress(): string {
    if (this.storeAddress){
      const address1 = this.storeAddress.address1 || '';
      const address2 = this.storeAddress.address2 || '';
      const address3 = this.storeAddress.address3 || '';
      return `${this.storeAddress.companyName} ${address1}${address2}${address3}`;
    }
    return '';
  }

  public getNavigation(): void {
    this.configFacade.getFooterNavigation().subscribe(
      (x) => {
        this.content = x;
        this.nodes = this.content.data.attributes.nodes;
        for (const node of this.nodes) {
          if (node.isActive) {
            this.links.push(node);
          }
        }
        return this.links;
      });
  }

  hideSocialMedia(): boolean {
    return !!this.utils.customData['hideSocialMedia'];
  }

  showExtendedContactUs(): boolean {
    return this.isUsStore || this.isCaStore;
  }
}
