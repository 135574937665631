import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EStoreFeatures, EStoreTypes, EUserRoles } from '../../configurations/common';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { ICartRule } from '../../models/cart.models';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { IAddress } from '../../models/common.models';
import { State } from '../../reducers';
import * as ShopCartSelectors from '../../reducers/shop-cart.reducer';
import { I18nService } from '../../services';
import { AddressUtils } from '../../utils/address.utils';
import { AppUtils } from '../../utils/app.utils';
import { FileType, FileUtils } from '../../utils/file.utils';
import { ObjectUtils } from '../../utils/object.utils';
import { IAddressSelectBox } from '../../models/customer.models';
import { OrdersFacade } from '../../facades/orders.facade';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss'],
})
export class DeliveryDetailsComponent implements OnInit, OnDestroy {
  userRoles: EUserRoles[];
  isUserRolesLoading: boolean = true;
  hasApproverAccess: boolean = false;
  isJpStore: boolean = false;
  isAuStore: boolean = false;
  customerAddresses: IAddressSelectBox[] = [];
  addresses: IAddressSelectBox[] = [];
  businessAddresses: IAddressSelectBox[] = [];
  creatingOrderInProgress: boolean = false;
  creatingPreviewInProgress: boolean = false;
  cartItems: any;
  isCartEmpty: boolean = true;
  cartId: string;
  showApprovalModal: boolean = false;
  checkoutUpdateData: ICheckoutUpdate;
  cartRules: Array<ICartRule>;
  inProcessCartId: string;
  businessAddressesLoading: boolean = false;
  isQuoteRequestActive: boolean = false;
  itemWithoutPrice: boolean = false;
  excludeTax: boolean = false;

  isOnContactDetailsSection: boolean = false;
  orderDetailsData: any;
  contactDetailsData: any;
  cartItemsWithDetails: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private checkoutFacade: CheckoutFacade,
    private store: Store<State>,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService,
    private configurationFacade: ConfigurationFacade,
    private ordersFacade: OrdersFacade,
  ) {
  }

  ngOnInit(): void {
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
    this.isAuStore = AppUtils.isStoreActive(EStoreTypes.AU);
    this.isQuoteRequestActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.QUOTE_REQUEST);

    this.selectCustomerCompanyRoles();
    this.selectCompanyRolesLoading();
    this.selectIsCartEmpty();
    this.beginCustomerAddressesAction();
    this.loadCartData();
    this.customerFacade.beginGetBusinessUnitsAction();
    this.selectCustomerAddresses();
    this.selectBusinessAddresses();
    this.selectCartItemsSubscription();
    this.selectExcludeTax();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCustomerCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(roles => {
      this.userRoles = roles;
      this.hasApproverAccess = this.checkRolesForAccess(roles);
    });
  }

  selectCompanyRolesLoading(): void {
    this.customerFacade.selectCompanyRolesLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isRolesLoading => {
      this.isUserRolesLoading = isRolesLoading;
    });
  }

  selectIsCartEmpty(): void {
    this.marketingFacade.selectIsCartEmpty().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isCartEmpty = state;
    });
  }

  beginCustomerAddressesAction(): void {
    this.customerFacade.beginCustomerAddressesAction();
  }

  checkRolesForAccess(roles: EUserRoles[]): boolean {
    if (roles.includes(EUserRoles.Viewer) && roles.length === 1) {
      this.router.navigate([this.i18nService.getCurrentLocale()]);
    }

    return (roles.includes(EUserRoles.Approver) || roles.includes(EUserRoles.Admin));
  }

  selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.businessAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, addresses, 'business');
        this.businessAddressesLoading = false;
      }
    });
  }

  selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  createOrder(contactDetailsData: any): void {
    this.contactDetailsData = contactDetailsData;
    const shippingAddress: IAddress = this.getShippingAddress();

    this.checkoutUpdateData = {
      type: 'checkout-update',
      attributes: {
        idCart: this.cartId,
        isAddressSavingSkipped: true,
        systemDetails: {
          ...this.orderDetailsData.systemDetails,
        },
        systemDetailsPerItem: [
          ...this.orderDetailsData.systemDetailsPerItem,
        ],
        pointOfContact: {
          ...this.contactDetailsData.pointOfContact,
        },
        approverDetails: {
          approverId: this.contactDetailsData.approverData.approver.value,
          comment: this.contactDetailsData.approverData.commentForApprover,
          dueDate: null,
          firstName: this.contactDetailsData.approverData.approver.firstName,
          lastName: this.contactDetailsData.approverData.approver.lastName,
        },
        shippingAddress,
      },
    };

    this.creatingOrderInProgress = true;
    this.checkoutFacade.postCheckoutData({data: this.checkoutUpdateData}, this.cartId).then(() => {
      this.generateQuotePdf();
      this.creatingOrderInProgress = false;
      this.showApprovalModal = true;
      if (this.userRoles.includes(EUserRoles.Buyer) || this.isJpStore) {
        this.analyticsService.trackCart('order.placed');
        this.inProcessCartId = this.cartId;
        // TODO: logic duplication - move to one effect
        this.marketingFacade.createEmptyCart();
        return;
      }
      this.marketingFacade.createEmptyCart();
      this.ordersFacade.loadCarts();

      this.checkoutFacade.actionPutCartIdOrderApprove(this.cartId);
      this.router.navigate(['/order-approval/', this.cartId]).then();
    }).catch(() => {
      this.creatingOrderInProgress = false;
    });
  }

  getPreviewQuotePdf(): void {
    const shippingAddress: IAddress = this.getShippingAddress();

    const checkoutUpdateData = {
      type: 'checkout-update',
      attributes: {
        idCart: this.cartId,
        shippingAddress,
      },
    };

    this.creatingPreviewInProgress = true;
    this.checkoutFacade.postCheckoutData({data: checkoutUpdateData}, this.cartId).then(() => {
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, true).pipe(take(1))
        .subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(file, FileType.PDF, `PREVIEW_details_${this.cartId}`);
          },
          complete: () => this.creatingPreviewInProgress = false,
        });
    }).catch(() => {
      this.creatingOrderInProgress = false;
    });
  }

  backToHomepage(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()], {queryParams: {lastCartId: this.inProcessCartId}}).then();
  }

  proceedToContactDetails(orderDetailsData: any): void {
    this.orderDetailsData = orderDetailsData;
    this.isOnContactDetailsSection = true;
  }

  proceedToOrderDetails(): void {
    this.isOnContactDetailsSection = false;
  }

  requestQuoteFeatureActive(): boolean {
    return this.isQuoteRequestActive && this.itemWithoutPrice;
  }

  private getShippingAddress(): IAddress {
    const deliveryAddress = this.orderDetailsData.deliveryAddress;

    const shippingAddress: IAddress = {
      idCustomerAddress: deliveryAddress.idCustomerAddress,
      idCompanyUnitAddress: deliveryAddress.idCompanyUnitAddress,
      salutation: 'Ms',
      firstName: (deliveryAddress && deliveryAddress.firstName) ? deliveryAddress.firstName : 'empty',
      lastName: (deliveryAddress && deliveryAddress.lastName) ? deliveryAddress.lastName : 'empty',
      address1: (deliveryAddress && deliveryAddress.address1) ? deliveryAddress.address1 : 'empty',
      address2: (deliveryAddress && deliveryAddress.address2) ? deliveryAddress.address2 : 'empty',
      address3: '',
      zipCode: (deliveryAddress && deliveryAddress.zipCode) ? deliveryAddress.zipCode : 'empty',
      city: (deliveryAddress && deliveryAddress.city) ? deliveryAddress.city : 'empty',
      iso2Code: (deliveryAddress && deliveryAddress.iso2Code) ? deliveryAddress.iso2Code : 'DE',
      company: (deliveryAddress && deliveryAddress.company) ? deliveryAddress.company : 'empty',
      phone: (deliveryAddress && deliveryAddress.phone) ? deliveryAddress.phone : 'empty',
      country: (deliveryAddress && deliveryAddress.country) ? deliveryAddress.country : 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };

    // handle BU address iso2Code
    if (shippingAddress.country === 'empty') {
      shippingAddress.country = AddressUtils.getDeliveryCountryByIsoCode(shippingAddress.iso2Code)?.name || 'empty';
    }

    return shippingAddress;
  }

  private generateQuotePdf(): void {
    if (AppUtils.isStoreActive(EStoreTypes.JP) && this.cartId) {
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, false).pipe(take(1))
        .subscribe(file => {
          FileUtils.saveAndOpenFile(file, FileType.PDF, `Cart_details_${this.cartId}`);
        });
    }
  }

  private selectCartItemsSubscription(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
      if (cartItems && cartItems.length > 0 && !this.cartItemsWithDetails?.length) {
        this.cartItemsWithDetails = cartItems;
      }
    });
  }

  private selectExcludeTax() {
    this.excludeTax = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
  }

  private loadCartData(): void {
    this.store.select(ShopCartSelectors.selectCartItems).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
      if (!cartItems) {
        return;
      }

      this.cartItems = ObjectUtils.deepClone(cartItems.data.attributes);
      this.cartRules = cartItems.included.filter(include => include.type === 'cart-rules');
      this.analyticsService.setProducts(cartItems);
    });

    this.marketingFacade.selectCartId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cartId => {
      this.cartId = cartId;
      this.analyticsService.setCartId(this.cartId);
    });
    this.analyticsService.trackPageReady('delivery details', PageTypes.DELIVERY_DETAILS_PAGE, 'concrete-products');
  }
}
