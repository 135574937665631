import { Component, Input } from '@angular/core';
import { EMessageType } from '../../configurations/common';
import { IMessage } from '../../models/common.models';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  EMessageType = EMessageType;
  @Input() message: IMessage;

  /**
   * @param {EMessageType} type
   * @returns {string}
   */
  getMessageColor(type: EMessageType): string{
    switch(type) {
      case EMessageType.INFO:
        return 'gray';
      case EMessageType.WARNING:
        return 'yellow';
      case EMessageType.ERROR:
        return 'red';
      default:
        return 'gray';
    }
  }

  /**
   * Returns scss class names for specific icon regarding EMessageType.
   *
   * @param {EMessageType} type
   * @returns {string}
   */
  getSpecificIcon(type?: EMessageType): string {
    switch (type) {
      case EMessageType.ERROR:
        return 'icon-exclamationmark icon-exclamationmark__circle';
      case EMessageType.WARNING:
        return 'icon-warning icon-warning__circle';
      case EMessageType.INFO:
        return 'icon-info icon-info__circle';
      default:
        return 'no icon';
    }
  }
}
