import { Component } from '@angular/core';

@Component({
  selector: 'app-page-quote-review-finalize',
  templateUrl: './page-quote-review-finalize.component.html',
  styleUrls: ['./page-quote-review-finalize.component.scss']
})
export class PageQuoteReviewFinalizeComponent {
  showAlmostDone: boolean;
  showQuoteSummary: boolean;
}

