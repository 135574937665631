<div class="container display-flex justify-content-center f-align-items-center">
  <div class="pagination">
    <span class="icon-arrow-left"
          *ngIf="currentPage > 1"
          (click)="goToPage(currentPage - 1)">
    </span>
    <ng-container *ngFor="let page of pages">
      <span class="pagination__item"
            [ngClass]="{'is-active': currentPage === page}"
            (click)="goToPage(page)"
            *ngIf="page else compressedPages">
        {{ page }}
      </span>
      <ng-template #compressedPages>
        <span>
          ...
        </span>
      </ng-template>
    </ng-container>
    <span class="icon-arrow-right"
          *ngIf="currentPage < pageCount"
          (click)="goToPage(currentPage + 1)">
    </span>
  </div>
</div>
