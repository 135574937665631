import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectUtils } from '../../utils/object.utils';
import { SearchFieldPipe } from '../pipes/search-field.pipe';
import { IEquipmentFilter } from '../../models/equipment-filter.model';

@Component({
  selector: 'app-equipment-filter',
  templateUrl: './equipment-filter.component.html',
  styleUrls: ['./equipment-filter.component.scss'],
  providers: [SearchFieldPipe]
})
export class EquipmentFilterComponent {
  isFilterOpened: boolean = false;
  filterSearchValue: any;

  @Input() filter: IEquipmentFilter;

  constructor(
    private router: Router,
    private searchFieldPipe: SearchFieldPipe
  ) {
  }

  toggleFilterOptions(): void {
    this.isFilterOpened = true;

    const filterContainer = document.querySelector('.equipment-filter');
    filterContainer.classList.toggle('is-open');
  }

  selectOptionsOrClear(): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);
    if (this.areOptionsSelected()) {
      this.filter.activeValue = [];
      this.router.navigateByUrl(pageUrl).then();
    } else {
      let filteredOptions = this.searchFieldPipe.transform(this.filter.values, this.filterSearchValue);
      let queryParams = {
        'rel-product-sysivk': [],
        page: 1
      };

      filteredOptions.forEach(option => {
        queryParams['rel-product-sysivk'].push(option['rel-product-sysivk']);
      });

      const url = this.router.createUrlTree(
        [pageUrl],
        {queryParams},
      ).toString();

      this.router.navigateByUrl(url).then();
    }
  }

  selectOption(option: any): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);
    const queryParams = this.createQueryParams('rel-product-sysivk', option['rel-product-sysivk']);

    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams},
    ).toString();

    this.router.navigateByUrl(url).then();
  }

  areOptionsSelected(): boolean {
    const filteredOptions = this.searchFieldPipe.transform(this.filter.values, this.filterSearchValue);
    let areOptionsSelected = true;

    filteredOptions.forEach(option => {
      if (!this.filter.activeValue?.find(value => value === option['rel-product-sysivk'])) {
        areOptionsSelected = false;
      }
    });

    return areOptionsSelected;
  }

  isOptionSelected(option: any): boolean {
    return !!this.filter.activeValue?.find(value => value === option['rel-product-sysivk']);
  }

  createQueryParams(name: string, value: string | number): any {
    let urlTree = this.router.parseUrl(this.router.url);
    if (!urlTree.queryParams.hasOwnProperty(name)) {
      urlTree.queryParams[name] = value;
      urlTree.queryParams.page = 1;

      return urlTree.queryParams;
    }

    if (Array.isArray(urlTree.queryParams[name])) {
      if (urlTree.queryParams[name].find(param => param === value)) {
        urlTree.queryParams[name] = urlTree.queryParams[name].filter(param => param !== value);
      } else {
        urlTree.queryParams[name].push(value);
      }
    } else if (urlTree.queryParams[name] === value) {
      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, name);
    } else {
      urlTree.queryParams[name] = [urlTree.queryParams[name], value];
    }

    urlTree.queryParams.page = 1;

    return urlTree.queryParams;
  }
}
