import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICatalogProduct, IProductLabel } from 'src/app/models/abstract-product.models';
import { environment } from '../../../../../environments/environment';
import { AppUtils } from '../../../../utils/app.utils';
import { PriceUtils } from '../../../../utils/price.utils';
import { IStore } from '../../../../models/settings.model';
import { IBaseWishlist } from '../../../../models/wishlist.models';

@Component({
  selector: 'app-basic-product',
  templateUrl: './basic-product.component.html',
  styleUrls: ['./basic-product.component.scss'],
})
export class BasicProductComponent {
  defaultImg: string = environment.defaultImg;
  priceUtils = PriceUtils;

  @Input() layoutType: string;
  @Input() product: ICatalogProduct;
  @Input() labels: IProductLabel[];
  @Input() wishlists: IBaseWishlist[];
  @Input() addNewModalActive: boolean;
  @Input() itemsInQueueToCart: Array<string>;
  @Input() loadingCartDataInProgress: boolean;

  @Output() addProductToCart: EventEmitter<ICatalogProduct> = new EventEmitter<ICatalogProduct>();
  @Output() addNewWishlistModalActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Method to emit add to cart
   * @param {ICatalogProduct} product
   */
  addToCart(product: ICatalogProduct): void {
    this.addProductToCart.emit(product);
  }

  /**
   * Method to open add new wishlist modal
   */
  openAddNewWishlistModal(): void {
    this.addNewWishlistModalActive.emit(this.addNewModalActive);
  }

  /**
   * Method to check if product has guest price
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  hasGuestPrice(product: ICatalogProduct): boolean {
    return this.priceUtils.hasGuestPrice(product.prices);
  }

  /**
   * Method to get guest price from product
   * @param {ICatalogProduct} product
   * @returns {number}
   */
  getGuestPrice(product: ICatalogProduct): number {
    return this.priceUtils.getPriceValue(this.priceUtils.getGuestPrice(product.prices));
  }

  /**
   * Method to get item concrete sku from product
   * @param {ICatalogProduct} product
   * @returns {string | undefined}
   */
  getItemConcreteSku(product: ICatalogProduct): string | undefined {
    if (!product?.attributeMap?.product_concrete_ids) {
      return undefined;
    }

    return product.attributeMap.product_concrete_ids[0];
  }

  /**
   * Method to check if item is in progress
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  isItemInProgress(product: ICatalogProduct): boolean {
    const concreteSku: string = this.getItemConcreteSku(product);

    return this.itemsInQueueToCart.includes(concreteSku) || this.itemsInQueueToCart.includes(product.abstractSku);
  }

  /**
   * Method to check if product contains image
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  hasImages(product: ICatalogProduct): boolean {
    if (!product?.images) {
      return false;
    }
    
    return !!product.images && product.images.length > 0;
  }

  /**
   * Method to check if exclude tax is active by storeId
   * @returns {boolean}
   */
  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
