import { Injectable } from '@angular/core';
import { UrlUtils } from '../utils/url.utils';
import { AppUtils } from '../utils/app.utils';


@Injectable({
  providedIn: 'root'
})
export class GlueConfigurationService {

  getEndpointUrl(): string {
    return UrlUtils.getGlueUrlForStore(AppUtils.getCurrentStore().storeId);
  }
}
