import { IBaseModel } from './common.models';

export interface IInstalledBaseSystemData extends IBaseModel {
  attributes: {
    serialNumber: string;
    siemensEquipmentId: string;
    materialNumber: string;
    namePtBr: string;
    nameEnUs: string;
    company: string;
    companyBusinessUnit: string;
    companyBusinessUnitNumber: string;
    category?: string,
    externalUrlLarge?: string,
    externalUrlSmall?: string,
    modalityCode?: string;
    companyBusinessUnitAddress: {
      address1: string;
      address2: string;
      address3: string;
      city: string;
      comment: string;
      phone: string;
      zipCode: string;
      iso2Code: string;
      country: string;
      state?: string;
    },
    siemensEquipmentAddress?: {
      street?: string;
      city?: string;
      zipCode?: string;
      state?: string;
    },
    myEquipmentName?: string;
    customerDesc?: string;
    department?: string;
    contractType?: string;
    contractTypeId?: string;
    contractsStartDate?: string;
    contractExpirationDate?: string;
    endOfSupport?: string;
    startupDate?: string;
    contractNumber?: string;
    contractName?: string;
    isCpqAvailable?: boolean;
    isSparePartsAvailable?: boolean;
  };
  links: {
    self: string;
  };
  compatible?: boolean;
}

export interface ISimpleInstallBaseProduct extends IBaseModel {
  attributes: {
    materialNumber: string;
    namePtBr: string;
    nameEnUs: string;
    category: string;
    categoryJaJp?: string;
    externalUrlLarge: string;
    externalUrlSmall: string;
  };
  links: {
    self: string;
  };
}

export interface IInstalledBaseSystems {
  data: IInstalledBaseSystemData[];
  links: {
    self: string;
  };
}

export interface IInstalledBaseSystemsSoldTo extends IInstalledBaseSystems {
  included: {
    attributes: {
      customerSoldToId: string;
      customerSoldToName: string;
    };
    id: string;
    links: {
      self: string;
    };
    type: string;
  }[];
}

export interface IInstallBaseProducts {
  data: ISimpleInstallBaseProduct[];
  links: {
    self: string;
  };
}

export enum EInstalledBaseWorkflow {
  SPARE_PARTS = 'spare-parts',
  CPQ = 'cpq',
}

export interface IInstalledBaseSystemsFilter {
  functionalLocationNumber?: string;
  materialNumber?: string;
  workflow?: EInstalledBaseWorkflow;
}
