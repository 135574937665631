import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img',
})
export class ImagePreloadDirective {
  private noImage = environment.defaultImg;
  private readonly nativeEl: HTMLImageElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.nativeEl = this.el.nativeElement;

    if ('loading' in HTMLImageElement.prototype) {
      this.renderer.setAttribute(this.nativeEl, 'loading', 'lazy');
    }
  }

  @HostListener('error') replaceImage(): void {
    const defaultSrc = this.nativeEl.getAttribute('data-default-src');
    let src = defaultSrc || this.noImage;
    if (this.nativeEl.src === this.noImage) {
      src = environment.defaultNoImg;
    }
    this.renderer.setAttribute(this.nativeEl, 'src', src);
  }
}
