import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService
  ) { }

  private categoryTreeUri = '/category-trees';


  private static handleError(error: any): Promise<any> {
    console.error('An error occurred in getNavigationBar()', error);
    return Promise.reject(error.message || error);
  }

  getCategoryTree(): Observable<any>{
    const url = this.glueUrl + this.categoryTreeUri;
    return this.httpClient.get(url).pipe(
        catchError(CategoriesService.handleError)
    );
  }
}
