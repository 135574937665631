import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { HomepageActions } from '../actions';
import { INavigationNodes } from '../models/settings.model';
import { IContentBannerAttributes } from '../models/cms';
import { environment } from '../../environments/environment';

/**
 * Homepage state's interfaces
 */
export interface IHomepageShopCategoryState {
  shopCategoryTiles: INavigationNodes[],
  shopCategoryOptionsLoading: boolean,
  shopCategoryLoadingError: string,
  isAnyTileInActiveMode: boolean, // used for deciding if shop categories component should be shown
  shopHomepageContentBannerData: IContentBannerAttributes,
  shopStoreSelectionContentBannerData: IContentBannerAttributes,
  isContentBannerDataLoading: boolean,
  shopContentBannerDataError: string,
}

/**
 * Homepage state's selectors
 */
export const homepageShopCategoryBaseState = createFeatureSelector<IHomepageShopCategoryState>('homepageShopCategory');

export const selectShopCategoryOptions = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopCategoryTiles,
);

export const selectShopCategoryOptionsLoading = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopCategoryOptionsLoading,
);

export const selectShopCategoryLoadingError = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopCategoryLoadingError,
);

export const selectShopCategoryIsAnyTileActive = createSelector(
  homepageShopCategoryBaseState,
  state => state.isAnyTileInActiveMode,
);

export const selectShopHomepageContentBannerData = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopHomepageContentBannerData,
);

export const selectShopStoreSelectionContentBannerData = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopStoreSelectionContentBannerData,
);

export const selectShopContentBannerDataLoading = createSelector(
  homepageShopCategoryBaseState,
  state => state.isContentBannerDataLoading,
);

export const selectShopContentBannerDataError = createSelector(
  homepageShopCategoryBaseState,
  state => state.shopContentBannerDataError,
);

/**
 * Homepage initialization states
 */
export const initialShopCategoryState: IHomepageShopCategoryState = {
  shopCategoryTiles: [],
  shopCategoryOptionsLoading: false,
  shopCategoryLoadingError: '',
  isAnyTileInActiveMode: true,
  shopHomepageContentBannerData: undefined,
  shopStoreSelectionContentBannerData: undefined,
  isContentBannerDataLoading: false,
  shopContentBannerDataError: '',
};

/**
 * Homepage reducers
 */
export const HomepageShopCategoryReducer = createReducer(
  initialShopCategoryState,

  on(HomepageActions.loadShopCategoryNavigation, (state): IHomepageShopCategoryState => {
    return {
      ...state,
      shopCategoryOptionsLoading: true,
    };
  }),
  on(HomepageActions.loadShopCategoryNavigationSuccess, (state, action): IHomepageShopCategoryState => {
    const hasAnyTileIsActiveState = action.navigationResponseNodes.some(tile => tile.isActive === true);
    return {
      ...state,
      shopCategoryTiles: action.navigationResponseNodes,
      shopCategoryOptionsLoading: false,
      shopCategoryLoadingError: '',
      isAnyTileInActiveMode: hasAnyTileIsActiveState,
    };
  }),
  on(HomepageActions.loadShopCategoryNavigationFail, (state, action): IHomepageShopCategoryState => {
    return {
      ...state,
      shopCategoryTiles: [],
      shopCategoryOptionsLoading: false,
      shopCategoryLoadingError: action.resError,
    };
  }),
  on(HomepageActions.loadShopContentBanner, (state): IHomepageShopCategoryState => {
    return {
      ...state,
      isContentBannerDataLoading: true,
      shopHomepageContentBannerData: undefined,
    };
  }),
  on(HomepageActions.loadShopContentBannerSuccess, (state, action): IHomepageShopCategoryState => {
    let homepageContentBanner: IContentBannerAttributes = state.shopHomepageContentBannerData;
    let storeSelectionContentBanner: IContentBannerAttributes = state.shopHomepageContentBannerData;
    if (action.contentBannerResponse.id === environment.homePageBannerKey) {
      homepageContentBanner = action.contentBannerResponse.attributes;
    }
    if (action.contentBannerResponse.id === environment.storeSelectionBannerKey) {
      storeSelectionContentBanner = action.contentBannerResponse.attributes;
    }
    return {
      ...state,
      isContentBannerDataLoading: false,
      shopHomepageContentBannerData: homepageContentBanner,
      shopStoreSelectionContentBannerData: storeSelectionContentBanner,
      shopContentBannerDataError: '',
    };
  }),
  on(HomepageActions.loadShopCategoryNavigationFail, (state, action): IHomepageShopCategoryState => {
    return {
      ...state,
      isContentBannerDataLoading: false,
      shopHomepageContentBannerData: null,
      shopContentBannerDataError: action.resError,
    };
  }),
);
