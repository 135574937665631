import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeLeadingZero',
})
export class RemoveLeadingZeroPipe implements PipeTransform {

  transform(orderNumber?: string): string {
    return orderNumber ? orderNumber.replace(/^0+/, '') : '';
  }
}
