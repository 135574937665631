import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { CpqFacade } from '../../facades/cpq.facade';

@Component({
  selector: 'app-product-base-info',
  templateUrl: './product-base-info.component.html',
  styleUrls: ['./product-base-info.component.scss'],
})
export class ProductBaseInfoComponent implements OnInit, OnDestroy {
  product: any;

  private unsubscribe$ = new Subject<void>();

  @Input() productSku: string;

  constructor(
    private cpqFacade: CpqFacade,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.selectSelectedSystem();
  }

  private selectSelectedSystem(): void {
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.cpqFacade.selectSelectedSystem().pipe(takeUntil(this.unsubscribe$)).subscribe(selectedSystem => {
        if (!selectedSystem || params.selectedService !== selectedSystem.productAbstractSku) {
          this.cpqFacade.loadSelectedSystem(params.selectedService);
        } else {
          this.product = selectedSystem;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getProductName(): string {
    return this.product.name;
  }

  getProductImage(): string {
    return this.product.imageSets[0]?.images[0]?.externalUrlSmall;
  }
}
