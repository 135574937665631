import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  cityAndStateSortOptions,
  contractEndDateSortOptions,
  equipmentLocationSortOptions,
  functionalLocationSortOptions,
  modalitySortOptions,
  productNameSortOptions,
} from '../../configurations/sort-options';
import { AdvancedFilter } from '../../models/advanced-filter';
import { EUserRoles, FEATURE_SETTING_EQUIPMENT_SELECTION_SESSION_LIMIT } from '../../configurations/common';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MarketingFacade } from '../../facades/marketing.facade';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFacade } from '../../facades/customer.facade';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { OpalFacade } from '../../facades/opal.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { ICart } from '../../models/cart.models';
import {
  IEquipmentSelectionHeaders,
  IInstalledBaseSystemDataEquipmentSelection,
} from '../../models/equipmentselection.models';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-my-equipment-selection',
  templateUrl: './my-equipment-selection.component.html',
  styleUrls: ['./my-equipment-selection.component.scss'],
})
export class MyEquipmentSelectionComponent implements OnInit, OnDestroy {
  searchValue: string;
  searchLoading: boolean = false;
  errorEndpointNotAccessible: boolean = false;
  errorEndpointNoFL: boolean = false;
  errorSearchLimit: boolean = false;
  errorFLValidity: boolean = false;
  errorFLContractDoesNotMatchTheList: boolean = false;
  error: boolean = false;
  validFunctionalLocationNumber: boolean = false;
  inputIsValid: boolean = false;

  tabsOpened: string[] = [] as Array<string>;
  headers: IEquipmentSelectionHeaders[] = [
    {
      label: 'my-contracts.functional-location',
      class: 'functional-location-id',
      sortOptions: functionalLocationSortOptions,
      showOnMobile: true,
    },
    {
      label: 'my-contracts.modality',
      class: 'modality',
      sortOptions: modalitySortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.product-name',
      class: 'nameEnUs',
      sortOptions: productNameSortOptions,
      showOnMobile: true,
    },
    {
      label: 'my-contracts.equipment-location',
      class: 'street',
      sortOptions: equipmentLocationSortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.city-state',
      class: 'state',
      sortOptions: cityAndStateSortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.contract-end',
      class: 'contractExpirationDate',
      sortOptions: contractEndDateSortOptions,
      showOnMobile: false,
    },
  ];

  filters: AdvancedFilter[];
  agreementsToWorkWith: IInstalledBaseSystemDataEquipmentSelection[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  tableData: IInstalledBaseSystemDataEquipmentSelection[];
  sessionLimit: number = 0;
  loading: boolean = false;

  userCarts: Array<ICart> = [];
  currentCartId: string;
  cartItems = [];
  contractFilters: AdvancedFilter[] = [
    {
      name: 'modality',
      attribute: 'attributes',
      firstAdditionalAttribute: 'category',
      options: [],
      selected: [],
    },
    {
      name: 'equipment-name',
      attribute: 'attributes',
      firstAdditionalAttribute: 'nameEnUs',
      options: [],
      selected: [],
    },
    {
      name: 'city-and-state',
      attribute: 'attributes',
      firstAdditionalAttribute: 'siemensEquipmentAddress',
      secondAdditionalAttribute: 'city',
      options: [],
      selected: [],
    },
    {
      name: 'contract-name',
      attribute: 'attributes',
      firstAdditionalAttribute: 'contractName',
      options: [],
      selected: [],
    },
  ];
  isCartOperationInProgress: boolean;
  loadingCartDataInProgress: boolean;
  isReorderPending: boolean = false;
  companyRoles: EUserRoles[];

  productSku: string;
  soldTo: number;

  constructor(
    private route: ActivatedRoute,
    private marketingFacade: MarketingFacade,
    private translateService: TranslateService,
    private customerFacade: CustomerFacade,
    private opalFacade: OpalFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCartOperationInProgress();
    this.sessionLimit = this.getSessionLimit();
    this.selectCartsData();
    this.selectLoadingCartDataInProgress();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.productSku = params.selectedService;
        this.soldTo = +params.soldTo;
      });
    this.selectReorderPending();
    this.getCustomerCompanyRoles();
  }

  ngOnDestroy(): void {
    if (this.isReorderPending) {
      this.marketingFacade.cancelReorder();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  searchFL(): void {
    this.resetErrors();
    this.error = false;
    this.loading = false;
    const functionalLocation: string = this.searchValue;
    if (functionalLocation) {
      if (this.checkSearchValueValidity(functionalLocation) && !this.loading) {
        if (this.checkSessionLimit()) {
          this.loading = true;
          this.opalFacade.getOpal(functionalLocation)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value) => {
              this.tableData = value?.data?.attributes ? [value.data] : null;
              this.validFunctionalLocationNumber = true;
              this.loading = false;
            }, error => {
              this.handleError(error);
            });
          this.incrementOpalLocalStorage();
        } else {
          this.resetErrors();
          this.errorSearchLimit = true;
          this.error = true;
          this.tableData = null;
          this.validFunctionalLocationNumber = false;
        }
      } else {
        this.resetErrors();
        this.errorFLValidity = true;
        this.error = true;
        this.tableData = null;
        this.validFunctionalLocationNumber = false;
      }
    }
  }

  resetErrors(): void {
    this.errorEndpointNotAccessible = false;
    this.errorEndpointNoFL = false;
    this.errorSearchLimit = false;
    this.errorFLValidity = false;
    this.errorFLContractDoesNotMatchTheList = false;
  }

  handleError(httpError: HttpErrorResponse): void {
    const errorCodes = httpError?.error?.errors?.map(err => err.code);
    if (errorCodes?.includes('199')) {
      this.errorEndpointNotAccessible = true;
      this.error = true;
    } else if(errorCodes?.includes('200')) {
      this.errorFLContractDoesNotMatchTheList = true;
      this.error = true;
    } else {
      this.errorEndpointNoFL = true;
      this.error = true;
    }
    this.loading = false;
    this.tableData = null;
  }

  selectCartsData(): void {
    combineLatest([
      this.marketingFacade.getCarts(),
      this.marketingFacade.selectCartId(),
      this.marketingFacade.selectCartItemsWithDetails(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([userCarts, currentCartId, cartItems]) => ({userCarts, currentCartId, cartItems}),
      )).subscribe({
      next: data => {
        if (data) {
          this.userCarts = data.userCarts.data;
          this.currentCartId = data.currentCartId;
          this.cartItems = data.cartItems;

          if (!this.findCurrentCart()) {
            this.getUserCarts();
          }
        }
      },
    });
  }

  /**
   * @returns void
   */
  getUserCarts(): void {
    this.marketingFacade.getCarts().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      {
        next: data => {
          this.userCarts = data.data;
        }
      }
    )
  }

  /**
   * @returns {ICart}
   */
  findCurrentCart(): ICart {
    return this.userCarts.find((cart: ICart): boolean => cart.id === this.currentCartId);
  }

  inputChange(input): void {
    if (!input) {
      this.resetErrors();
      this.error = false;
      this.loading = false;
    }
  }

  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
      this.loadingCartDataInProgress = loadingCartDataInProgress;
    });
  }

  selectReorderPending(): void {
    this.marketingFacade.isReorderPending()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isReorderPending => {
        this.isReorderPending = isReorderPending;
      });
  }

  searchValueChange(newValue: string) {
    if (this.checkSearchValueValidity(newValue)) {
      this.resetErrors();
      this.inputIsValid = true;
      this.error = false;
      this.errorFLValidity=false;
    }
    if(!!newValue && !this.checkSearchValueValidity(newValue)) {
      this.resetErrors();
      this.inputIsValid = false;
      this.error = true;
      this.errorFLValidity = true;
    }
    if(!newValue){
      this.resetErrors();
      this.error = false;
      this.errorFLValidity=false;
    }
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.isCartOperationInProgress = inProgress;
    });
  }

  getCustomerCompanyRoles(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(companyRoles => {
        this.companyRoles = companyRoles;
      });
  }

  checkSessionLimit(): boolean {
    const opal: string = this.getOpalLocalStorage();
    if (!opal) {
      const newOpalValue: string = '1';
      this.setOpalKeyValue(newOpalValue);
      return true;
    } else return Number(opal) <= this.sessionLimit;
  }

  setOpalKeyValue(value: string): void {
    LocalStorageUtils.setKeyValue('opal', value);
  }

  getOpalLocalStorage(): string {
    return LocalStorageUtils.getKeyValue('opal');
  }

  incrementOpalLocalStorage(): void {
    this.setOpalKeyValue((Number(this.getOpalLocalStorage()) + 1).toString());
  }

  checkSearchValueValidity(value: string): boolean {
    const regex: RegExp = /^\d{3}-\d{6}$/;
    return regex.test(value);
  }

  private getSessionLimit(): number {
    let limit: number = 1;
    this.translateService.get(FEATURE_SETTING_EQUIPMENT_SELECTION_SESSION_LIMIT).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      limit = value;
    });
    return Number(limit) ? Number(limit) : 10;
  }
}
