<div class="sap-message-controller" *ngIf="messages?.length > 0">
  <app-accordion [manualOpen]="messagesOpened">
    <ng-container ngProjectAs="[slot][header]">
      <div class="accordion__title icon-arrow-down" (click)="toogleMessages()">
        <div class="sap-message-controller__header">
          <div class="sap-message-controller__title">
            <span *ngIf="this.messages.length > 0" class="icon" [ngClass]="this.getSpecificIcon(this.messages[0].type, true)">
            </span>
            <span class="text">
              <span [attr.data-test]="'label-sapMessageControllerTitle'">
                {{ 'sap-message-controller.title' | translate }}
              </span>
              <span [attr.data-test]="'desc-sapMessagesLength'">
                ({{ messages.length }})
              </span>
            </span>
          </div>
          <div class="sap-message-controller__subtitle"
               [attr.data-test]="'label-sapMessageControllerSubtitle'">
            {{ 'sap-message-controller.subtitle' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][panel]">
      <div class="container accordion__content">
        <div class="message"
             *ngFor="let message of messages; let messageOrder = index"
             [attr.data-test]="'desc-message-' + message">
          <span class="message__label"
                [ngClass]="getColoredLabel(message.type)"
                [attr.data-test]="'label-messageColor'">
          </span>
          <div class="message__content">
            <div class="name">
              <span class="text" [attr.data-test]="'label-messageName'">
                {{ messageOrder + 1 }}. {{ getName(message.materialNumber) }}
              </span>
              <span class="icon"
                    [ngClass]="getSpecificIcon(message.type)"
                    [attr.data-test]="'label-messageIcon'">
              </span>
            </div>
            <span class="description" [attr.data-test]="'label-messageDescription'">
              {{ message.message }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </app-accordion>
</div>
