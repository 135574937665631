import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import * as ShopCartActions from '../actions/shop-cart.actions';
import { shopName } from '../utils/localStorage.utils';
import { ICart, ICartBaseInformation, ICartItem, ICartItemListError, ICartRule } from '../models/cart.models';
import { PayloadUtils } from '../utils/payload.utils';
import { ISapMessage } from '../models/sap.model';
import { EGlueResource } from '../configurations/common';
import { IBaseModel, IPriceDisputingPerItem } from '../models/common.models';
import { CartUtils } from '../utils/cart.utils';

export interface ShopCartState {
  carts: Array<ICart>;
  currentCartId: string | null;
  cartItems: any | null;
  cart: any; //TODO: always in the carts array, can be removed, store just id of the current cart
  currentDefaultCartEtag: string | null;
  updateItemInCartData: {
    'data': {
      'type': string,
      'attributes': {
        'quantity': number | null;
      }
    }
  };
  isCartEmpty: boolean;  //TODO: do not store calculable values, create selector with logic related to this
  cartItemsWithDetails: Array<any>; //TODO: also stored in cartItems.included[i] ('items'), has to be check if data are equal
  rfqActive: boolean; //TODO: can be countable in selector
  cartRules: Array<ICartRule>; //TODO: stored in cart included
  sapMessages: Array<ISapMessage>; //TODO: also stored in cartItems.included[i] (sap-messages).attributes.messages[i], can be identified by materialNumber
  included: Array<any>; // Contains includes (e.g. cart items, product image sets for OTHER carts) -> needs to be kept
  priceDisputingPerItem: IPriceDisputingPerItem[];
  //Operations
  itemsInQueueToCart: Array<string>;
  lastAddedItemName: string;
  cartOperationInProgress: boolean;
  isMiniCartOpen: boolean;
  showNewItemAddedNotification: boolean;
  addItemToCartOperationInProgress: boolean;
  loadingCartDataInProgress: boolean;
  addingToCartViaCsvInProgress: boolean;
  //Errors
  error: any | null;
  itemListAddErrors: ICartItemListError[];
}

// Strong Typing end

// Initialize State start
export const initialState: ShopCartState = {
  carts: [],
  currentCartId: null,
  cartItems: null,
  error: null,
  cart: null,
  currentDefaultCartEtag: null,
  updateItemInCartData: {
    data: {
      type: 'items',
      attributes: {
        quantity: null,
      },
    },
  },
  isMiniCartOpen: false,
  showNewItemAddedNotification: false,
  lastAddedItemName: null,
  isCartEmpty: true,
  addItemToCartOperationInProgress: false,
  loadingCartDataInProgress: false,
  itemsInQueueToCart: [],
  cartItemsWithDetails: [],
  rfqActive: false,
  cartRules: [],
  cartOperationInProgress: false,
  sapMessages: [],
  included: [],
  addingToCartViaCsvInProgress: false,
  itemListAddErrors: undefined,
  priceDisputingPerItem: [],
};
// Initialize State end

// Selectors start
const getShopCartFeatureState = createFeatureSelector<ShopCartState>(shopName);

export const selectMiniCartState = createSelector(
  getShopCartFeatureState,
  state => {
    return {
      isCartEmpty: state.isCartEmpty,
      isMiniCartOpen: state.isMiniCartOpen,
      showNewItemAddedNotification: state.showNewItemAddedNotification,
    };
  },
);

export const getShopCartPageData = createSelector(
  getShopCartFeatureState,
  state => {
    return {
      cartItems: state.cartItems,
      loadingCartDataInProgress: state.loadingCartDataInProgress,
      cartId: state.currentCartId,
      addItemToCartOperationInProgress: state.addItemToCartOperationInProgress,
    };
  },
);

export const getCarts = createSelector(
  getShopCartFeatureState,
  state => state.carts,
);

export const selectCartsBaseInfo = createSelector(
  getShopCartFeatureState,
  state => {
    return state.carts.map((cart: ICart): ICartBaseInformation => ({
      id: cart.id,
      name: CartUtils.getCartName(cart),
      approverId: cart.attributes?.approverDetails?.approverId,
    }));
  },
);

export const selectDefaultCartNameAndETag = createSelector(
  getShopCartFeatureState,
  state => ({
    eTag: state.currentDefaultCartEtag,
    name: state.carts.find(cart => cart.id === state.currentCartId).attributes.name,
  }),
);

//prepared for future usage

// export const selectCurrentCart = createSelector(
//   getShopCartFeatureState,
//   state => {
//     return state.carts.find(cart => cart.id === state.currentCartId)
//   }
// );

export const selectCurrentCart = createSelector(
  getShopCartFeatureState,
  state => state.cart,
);

export const selectCurrentCartId = createSelector(
  getShopCartFeatureState,
  state => state.currentCartId,
);


export const selectCartItems = createSelector(
  getShopCartFeatureState,
  state => state.cartItems,
);

// export const getError = createSelector(
//   getShopCartFeatureState,
//   state => state.error,
// );

export const getCartsItems = createSelector(
  getShopCartFeatureState,
  state => {
    return state.cartItems?.included ? state.included : [];
  },
);

export const getAllCartsItems = createSelector(
  getShopCartFeatureState,
  state => {
    return state.included;
  },
);

export const getUpdateItemInCartData = createSelector(
  getShopCartFeatureState,
  state => state.updateItemInCartData,
);

export const lastAddedItemName = createSelector(
  getShopCartFeatureState,
  state => state.lastAddedItemName,
);

export const isCartEmpty = createSelector(
  getShopCartFeatureState,
  state => state.isCartEmpty,
);

export const isAddItemToCartOperationInProgress = createSelector(
  getShopCartFeatureState,
  state => state.addItemToCartOperationInProgress,
);

export const itemsInQueueToCart = createSelector(
  getShopCartFeatureState,
  state => state.itemsInQueueToCart,
);

export const cartItemsWithDetails = createSelector(
  getShopCartFeatureState,
  state => state.cartItemsWithDetails,
);

export const selectCurrentCartCurrency = createSelector(
  getShopCartFeatureState,
  state => state.carts.find(cart => cart.id === state.currentCartId).attributes.currency,
);

export const selectRfqActive = createSelector(
  getShopCartFeatureState,
  state => state.rfqActive,
);

export const selectCartRules = createSelector(
  getShopCartFeatureState,
  state => state.cartRules,
);

export const selectCartOperationInProgress = createSelector(
  getShopCartFeatureState,
  state => state.cartOperationInProgress,
);

export const selectLoadingCartDataInProgress = createSelector(
  getShopCartFeatureState,
  state => state.loadingCartDataInProgress,
);

export const selectSapMessages = createSelector(
  getShopCartFeatureState,
  state => state.sapMessages,
);

export const selectAddToCartViaCsvProgress = createSelector(
  getShopCartFeatureState,
  state => state.addingToCartViaCsvInProgress,
);

export const selectAddToCartViaCsvErrors = createSelector(
  getShopCartFeatureState,
  state => state.itemListAddErrors,
);

export const selectPrefilledBillToAddressId = createSelector(
  getShopCartFeatureState,
  state => state.carts.find(cart => cart.id === state.currentCartId)?.attributes.bpPrefilledBillToAddressId,
);

export const selectPrefilledShipToAddressId = createSelector(
  getShopCartFeatureState,
  state => state.carts.find(cart => cart.id === state.currentCartId)?.attributes.bpPrefilledShipToAddressId,
);

export const selectPriceDisputingPerItem = createSelector(
  getShopCartFeatureState,
  state => state.priceDisputingPerItem,
);

export const selectIsPriceDisputingSetInCart = createSelector(
  getShopCartFeatureState,
  state => state.cart?.attributes?.isPriceDisputingSet,
);
// Selectors end

// Reducer start
export const ShopCartReducer = createReducer(
  initialState,
  on(ShopCartActions.clearCart, (): ShopCartState => {
    localStorage.removeItem(shopName);
    return {
      ...initialState,
    };
  }),
  on(ShopCartActions.loadCurrentCartAndCartItems, (state): ShopCartState => {
    return {
      ...state,
      loadingCartDataInProgress: true,
    };
  }),
  on(ShopCartActions.loadCurrentCart, (state): ShopCartState => {
    return {
      ...state,
      loadingCartDataInProgress: true,
    };
  }),
  on(ShopCartActions.loadCurrentCartItemsSuccess, (state): ShopCartState => {
    return {
      ...state,
      loadingCartDataInProgress: false,
    };
  }),
  on(ShopCartActions.loadCartItemsSuccess, (state, action): ShopCartState => {
    //merge locally stored price disputing data with BE price disputing data
    const mergedPriceDisputingData = getMergedPriceDisputingData(state.priceDisputingPerItem, action.cartItems?.included?.filter(item => item.type === 'items'));
    return {
      ...state,
      cart: {
        ...action.cartItems.data,
        attributes: {
          ...action.cartItems.data.attributes,
          isPriceDisputingSet: isPriceDisputingSetInCart(mergedPriceDisputingData),
        },
      },
      cartItems: action.cartItems,
      isCartEmpty: !action.cartItems?.included,
      error: null,
      addItemToCartOperationInProgress: false,
      sapMessages: extractSapMessages(action.cartItems.included),
      priceDisputingPerItem: mergedPriceDisputingData,
      currentDefaultCartEtag: action.cartItems.eTag,
    };
  }),
  on(ShopCartActions.loadCartItemsFail, (state, action): ShopCartState => {
    return {
      ...state,
      cartItems: null,
      error: 'State - Error: ' + action.error,
      addItemToCartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.loadCartsSuccess, (state, action): ShopCartState => {
    return {
      ...state,
      carts: action.carts.data,
      included: getUpdatedIncludesList(state.included, action.carts.included ?? []),
      error: null,
    };
  }),
  on(ShopCartActions.loadCartsFail, (state, action): ShopCartState => {
    return {
      ...state,
      carts: null,
      error: 'State - Error: ' + action.error,
      itemsInQueueToCart: [],
    };
  }),
  on(ShopCartActions.postCartSuccess, (state, action): ShopCartState => {
    return {
      ...state,
      currentCartId: action.newCartData.data.id,
      currentDefaultCartEtag: action.newCartData.eTag,
      included: getUpdatedIncludesList(state.included, action.newCartData.included),
      cart: action.newCartData.data,
      carts: getUpdatedCartsList(state.carts, action.newCartData.data, true),
      sapMessages: extractSapMessages(action.newCartData.included),
      cartItemsWithDetails: null,
      cartItems: null,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.postCartFail, (state, action): ShopCartState => {
    return {
      ...state,
      currentCartId: null,
      error: 'State - Error: ' + action.error,
      itemsInQueueToCart: [],
    };
  }),
  on(ShopCartActions.removeItemFromQueue, (state, action): ShopCartState => {
    const newQueue = state.itemsInQueueToCart.filter(item => item !== action.sku);
    return {
      ...state,
      error: action.error,
      itemsInQueueToCart: newQueue,
    };
  }),
  on(ShopCartActions.loadCartSuccess, (state, action): ShopCartState => {
    return {
      ...state,
      cart: action.cart,
      currentCartId: action.cart.id,
      error: null,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.loadCartFail, (state, action): ShopCartState => {
    return {
      ...state,
      cart: null,
      error: 'State - Error: ' + action.error,
    };
  }),
  on(ShopCartActions.deleteCart, (state: ShopCartState): ShopCartState => {
    return {
      ...state,
      showNewItemAddedNotification: false,
      priceDisputingPerItem: [],
    };
  }),
  on(ShopCartActions.deleteCartSuccess, (state, action): ShopCartState => {
    const cartIncludes: string[] = [EGlueResource.SAP_MESSAGES, EGlueResource.SAP_ITEM_AVAILABILITIES];
    const isDeletedCartInclude = function (include: IBaseModel) {
      return cartIncludes.includes(include.type) && include.id === action.cartId;
    };
    return {
      ...state,
      carts: state.carts.filter((cart) => cart.id !== action.cartId),
      included: state.included.filter((include: IBaseModel) => !isDeletedCartInclude(include)),
      currentCartId: action.isDefault ? null : state.currentCartId,
      sapMessages: action.isDefault ? [] : state.sapMessages,
      error: null,
      addItemToCartOperationInProgress: false,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.deleteCartFail, (state, action): ShopCartState => {
    return {
      ...state,
      addItemToCartOperationInProgress: false,
      cartOperationInProgress: false,
      error: 'State - Error: ' + action.error,
    };
  }),
  on(ShopCartActions.addItemToCartSuccess, (state, action): ShopCartState => {
    const newQueue = state.itemsInQueueToCart.filter(item => item !== action.product.sku);
    return {
      ...state,
      error: null,
      addItemToCartOperationInProgress: false,
      included: getUpdatedIncludesList(state.included, action.cartResponse.included),
      currentDefaultCartEtag: action.cartResponse.eTag,
      itemsInQueueToCart: newQueue,
      lastAddedItemName: action.product.name,
      isCartEmpty: false,
      carts: getUpdatedCartsList(state.carts, action.cartResponse.data),
      sapMessages: extractSapMessages(action.cartResponse.included),
    };
  }),
  on(ShopCartActions.addItemToCartError, (state, action): ShopCartState => {
    return {
      ...state,
      error: action.error,
      addItemToCartOperationInProgress: false,
      itemsInQueueToCart: [],
    };
  }),
  on(ShopCartActions.deleteItemFromCart, (state, {itemId}): ShopCartState => {
    //remove price disputing data for deleted item
    const priceDisputingDataAfterItemDeletion = removePriceDisputingForDeletedItem(state.priceDisputingPerItem, itemId);

    return {
      ...state,
      addItemToCartOperationInProgress: true,
      cartOperationInProgress: true,
      priceDisputingPerItem: priceDisputingDataAfterItemDeletion,
      cart: {
        ...state.cart,
        attributes: {
          ...state.cart.attributes,
          isPriceDisputingSet: isPriceDisputingSetInCart(priceDisputingDataAfterItemDeletion),
        },
      },
    };
  }),
  on(ShopCartActions.deleteItemFromCartSuccess, (state): ShopCartState => {
    return {
      ...state,
      cartOperationInProgress: true,
      error: null,
    };
  }),
  on(ShopCartActions.deleteItemFromCartFail, (state, action): ShopCartState => {
    return {
      ...state,
      cartOperationInProgress: false,
      error: 'State - Error: ' + action.error,
    };
  }),
  on(ShopCartActions.setUpdateItemInCartData, (state, action): ShopCartState => {
    return {
      ...state,
      updateItemInCartData: {
        data: {
          type: 'items',
          attributes: {
            quantity: action.quantity,
          },
        },
      },
      error: null,
      addItemToCartOperationInProgress: true,
      cartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.updateItemInCartLoadCartItems, (state): ShopCartState => {
    return {
      ...state,
      addItemToCartOperationInProgress: true,
      cartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.updateItemInCartLoadCartItemsSuccess, (state, action): ShopCartState => {
    return {
      ...state,
      cart: action.updatedItemInCartAndLoadedCartItems.data,
      cartItems: action.updatedItemInCartAndLoadedCartItems,
      error: null,
      addItemToCartOperationInProgress: false,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.updateItemInCartLoadCartItemsFail, (state, action): ShopCartState => {
    return {
      ...state,
      error: 'State - Error: ' + action.error,
      addItemToCartOperationInProgress: false,
      cartOperationInProgress: false,
      itemsInQueueToCart: [],
    };
  }),
  on(ShopCartActions.updateShopCartAfterItemUpdate, (state, action): ShopCartState => {
    const mergedPriceDisputingData = getMergedPriceDisputingData(state.priceDisputingPerItem, action.payload?.included?.filter(item => item.type === 'items'));
    const cartRules = action.payload.included.filter(include => include.type === EGlueResource.CART_RULES);
    const cartDetails = PayloadUtils.getItemsWithDetailsFromInclude(action.payload.included, true);
    const rfqProducts = action.payload.included.filter(include => include.type === EGlueResource.RFQ_PRODUCTS);

    return {
      ...state,
      cartItemsWithDetails: cartDetails,
      error: null,
      cartRules: cartRules || [],
      rfqActive: rfqProducts !== undefined && rfqProducts?.length > 0,
      cart: {
        ...action.payload.data,
        attributes: {
          ...action.payload.data.attributes,
          isPriceDisputingSet: isPriceDisputingSetInCart(mergedPriceDisputingData),
        },
      },
      priceDisputingPerItem: mergedPriceDisputingData,
      cartItems: action.payload,
      sapMessages: extractSapMessages(action.payload.included ?? []),
      included: getUpdatedIncludesList(state.included, action.payload.included ?? []),
      carts: getUpdatedCartsList(state.carts, action.payload.data),
      loadingCartDataInProgress: false,
      currentDefaultCartEtag: action.payload.eTag,
      addItemToCartOperationInProgress: false,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.updateCartSystemDetailsSuccess, (state, action) => {
    const mergedPriceDisputingData = getMergedPriceDisputingData(
      state.priceDisputingPerItem,
      action.cart?.included?.filter(item => item.type === EGlueResource.CART_ITEMS),
    );
    return {
      ...state,
      cart: action.cart.data,
      cartItems: action.cart,
      priceDisputingPerItem: mergedPriceDisputingData,
      carts: getUpdatedCartsList(state.carts, action.cart.data),
      loadingCartDataInProgress: false,
      cartOperationInProgress: false,
      error: null,
      currentDefaultCartEtag: action.cart.eTag,
      sapMessages: extractSapMessages(action.cart.included),
    };
  }),
  on(ShopCartActions.toggleMiniCart, (state, action) => {
    return {
      ...state,
      isMiniCartOpen: action.isOpen,
      showNewItemAddedNotification: false,
    };
  }),
  on(ShopCartActions.openMiniCartAndShowNotificationAfterAddToCart, (state) => {
    return {
      ...state,
      isMiniCartOpen: true,
      showNewItemAddedNotification: true,
    };
  }),
  on(ShopCartActions.addLastAddedItemName, (state, action) => {
    return {
      ...state,
      lastAddedItemName: action.itemName,
    };
  }),
  on(ShopCartActions.setCartStatus, (state, action) => {
    return {
      ...state,
      isCartEmpty: action.status,
    };
  }),
  on(ShopCartActions.postNewCartWithItem, (state) => {
    return {
      ...state,
      addItemToCartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.addItemToCart, (state) => {
    return {
      ...state,
      addItemToCartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.addItemToCartInProgress, (state, action?) => {
    return {
      ...state,
      addItemToCartOperationInProgress: action.status,
    };
  }),
  on(ShopCartActions.addItemFailAfterCartLoad, (state, action) => {
    return {
      ...state,
      error: action.error,
      itemsInQueueToCart: [],
    };
  }),
  on(ShopCartActions.addItemToQueue, (state, action) => {
    if (!!state.itemsInQueueToCart.find(sku => sku === action.sku)) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      addItemToCartOperationInProgress: true,
      itemsInQueueToCart: [...state.itemsInQueueToCart, action.sku],
    };
  }),
  on(ShopCartActions.setDefaultCartItems, (state, action) => {
    let cartDetails = [];
    if (!action.cartItems?.included) {
      const emptyCart = action.cartItems.data.attributes.totalItemsQuantity === 0;
      return {
        ...state,
        isCartEmpty: emptyCart,
        cartItemsWithDetails: emptyCart ? [] : state.cartItemsWithDetails,
        rfqActive: false,
      };
    }
    cartDetails = PayloadUtils.getItemsWithDetailsFromInclude(action.cartItems.included, true);
    const rfqProducts = action.cartItems.included.filter(include => include.type === EGlueResource.RFQ_PRODUCTS);
    const cartRules = action.cartItems.included.filter(include => include.type === EGlueResource.CART_RULES);
    return {
      ...state,
      cartItemsWithDetails: cartDetails,
      rfqActive: rfqProducts !== undefined && rfqProducts?.length > 0,
      cartRules: cartRules || [],
    };
  }),
  on(ShopCartActions.switchDefaultCart, (state, _): ShopCartState => {
    return {
      ...state,
      cartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.switchDefaultCartSuccess, (state, action): ShopCartState => {
    const oldDefaultCartId = state.currentCartId;
    let carts = getUpdatedCartsList(state.carts, action.cart.data);
    if (carts.length > 1) {
      carts = carts.map(
        (cart) => cart.id !== oldDefaultCartId ? cart : {...cart, attributes: {...cart.attributes, isDefault: false}},
      );
    }
    return {
      ...state,
      carts,
      sapMessages: extractSapMessages(action.cart.included),
      currentCartId: action.cart.data.id,
      currentDefaultCartEtag: action.cart.eTag,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.switchDefaultCartFail, (state, action): ShopCartState => {
    return {
      ...state,
      error: 'State - Error: ' + action.error,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.handleAddToCartResetState, (state, action): ShopCartState => {
    return {
      ...state,
      error: 'State - Error: ' + action.error.detail,
      itemsInQueueToCart: [],
      addItemToCartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.startCartOperation, (state) => {
    return {
      ...state,
      cartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.stopCartOperation, (state) => {
    return {
      ...state,
      cartOperationInProgress: false,
    };
  }),
  on(ShopCartActions.deleteCpqCart, ShopCartActions.quoteSummaryPageDeleteQuote, (state) => {
    return {
      ...state,
      cartOperationInProgress: true,
    };
  }),
  on(ShopCartActions.deleteCpqCartFail, (state, action) => {
    return {
      ...state,
      cartOperationInProgress: false,
      error: action.error,
    };
  }),
  on(ShopCartActions.addToCartFromCsvFile, (state) => {
    return {
      ...state,
      addingToCartViaCsvInProgress: true,
      itemListAddErrors: undefined,
    };
  }),
  on(ShopCartActions.addToCartFromCsvFileSuccess, (state, action) => {
    return {
      ...state,
      itemListAddErrors: action.cart.data.attributes.itemListAddErrors,
      addingToCartViaCsvInProgress: false,
    };
  }),
  on(ShopCartActions.addToCartFromCsvFileFail, (state) => {
    return {
      ...state,
      addingToCartViaCsvInProgress: false,
    };
  }),
  on(ShopCartActions.setBillToAddressIdAction, (state: ShopCartState, {billToAddressSapId}) => {
    const updatedCarts = state.carts.map(cart => {
      if (cart.id === state.currentCartId) {
        cart.attributes.bpPrefilledBillToAddressId = billToAddressSapId;
      }
      return cart;
    });
    return {
      ...state,
      carts: updatedCarts,
    };
  }),
  on(ShopCartActions.clearBillToAddressIdAction, (state) => {
    const updatedCarts = state.carts.map(cart => {
      if (cart.id === state.currentCartId) {
        cart.attributes.bpPrefilledBillToAddressId = null;
      }
      return cart;
    });
    return {
      ...state,
      carts: updatedCarts,
    };
  }),
  on(ShopCartActions.setShipToAddressIdAction, (state: ShopCartState, {shipToAddressSapId}) => {
    const updatedCarts = state.carts.map(cart => {
      if (cart.id === state.currentCartId) {
        cart.attributes.bpPrefilledShipToAddressId = shipToAddressSapId;
      }
      return cart;
    });
    return {
      ...state,
      carts: updatedCarts,
    };
  }),
  on(ShopCartActions.clearShipToAddressIdAction, (state) => {
    const updatedCarts = state.carts.map(cart => {
      if (cart.id === state.currentCartId) {
        cart.attributes.bpPrefilledShipToAddressId = null;
      }
      return cart;
    });
    return {
      ...state,
      carts: updatedCarts,
    };
  }),
  on(ShopCartActions.addOrUpdatePriceDisputingPerItemInCartAction, (state, {updatedPriceDisputing}) => {
    if (!state?.priceDisputingPerItem) {
      state.priceDisputingPerItem = [];
      state.priceDisputingPerItem.push(updatedPriceDisputing);
    } else {
      const index = state?.priceDisputingPerItem?.findIndex(priceDisputing => {
        return priceDisputing?.itemId === updatedPriceDisputing.itemId;
      });

      if (index === -1) {
        state.priceDisputingPerItem.push(updatedPriceDisputing);
      } else {
        state.priceDisputingPerItem[index] = updatedPriceDisputing;
      }
    }

    return {
      ...state,
      priceDisputingPerItem: state.priceDisputingPerItem,
      cart: {
        ...state.cart,
        attributes: {
          ...state.cart.attributes,
          isPriceDisputingSet: isPriceDisputingSetInCart(state.priceDisputingPerItem),
        },
      },
    };
  }),
  on(ShopCartActions.clearPriceDisputing, (state) => {
    return {
      ...state,
      priceDisputingPerItem: [],
    };
  }),
);

// Reducer end

/**
 * Remove price disputing data for previously deleted item.
 *
 *
 * @param {IPriceDisputingPerItem[]} priceDisputingPerItem
 * @param {string} itemId
 * @return {IPriceDisputingPerItem[]}
 */
function removePriceDisputingForDeletedItem(priceDisputingPerItem: IPriceDisputingPerItem[], itemId: string): IPriceDisputingPerItem[] {
  return priceDisputingPerItem.filter(itemPriceDisputing => itemPriceDisputing?.itemId !== itemId);
}

/**
 * Merge FE and BE price disputing data.
 *
 *
 * @param {IPriceDisputingPerItem[]} priceDisputingPerItem
 * @param {ICartItem[]} items
 * @return {IPriceDisputingPerItem[]}
 */
function getMergedPriceDisputingData(priceDisputingPerItem: IPriceDisputingPerItem[], items: ICartItem[]): IPriceDisputingPerItem[] {
  if (priceDisputingPerItem?.length > 0) {
    return priceDisputingPerItem;
  }

  return items.map(item => {
    if (item?.attributes?.priceDisputing?.isSet) {
      return {
        itemId: item.id,
        priceDisputing: {
          isSet: item.attributes.priceDisputing?.isSet ?? false,
          message: item.attributes.priceDisputing?.message ?? '',
        },
      };
    }
  }).filter(item => item !== undefined);
}

/**
 * Determine if price disputing is set on the cart level.
 *
 *
 * @param {IPriceDisputingPerItem[]} items
 * @return {boolean}
 */
function isPriceDisputingSetInCart(items: IPriceDisputingPerItem[]): boolean {
  if (!items) {
    return false;
  }

  return items.some(item => item?.priceDisputing?.isSet);
}

/**
 * Update list of carts with data from actions modifying a single cart.
 *
 * @param {Array<ICart>} carts
 * @param {ICart} updatedCartData
 * @param {boolean} isNewCart
 *
 * @returns {Array<ICart>}
 */
function getUpdatedCartsList(
  carts: Array<ICart>,
  updatedCartData: ICart,
  isNewCart: boolean = false,
): Array<ICart> {
  if (isNewCart && updatedCartData.attributes.isDefault) {
    return [
      updatedCartData,
      ...carts.map(cart => (({...cart, attributes: {...cart.attributes, isDefault: false}}))),
    ];
  } else {
    return carts.map(cart => cart.id === updatedCartData.id ? updatedCartData : cart);
  }
}

/**
 * Return previous list of included resources with new includes from incoming
 * response. "New" in this case means includes objects having a combination
 * of type and id values that have not been returned previously;.
 *
 * @param {Array<IBaseModel>} included
 * @param {Array<IBaseModel>} updatedIncludes
 * @returns {Array<IBaseModel>}
 */
function getUpdatedIncludesList(
  included: Array<IBaseModel>,
  updatedIncludes: Array<IBaseModel>,
): Array<IBaseModel> {
  const newIncludes = updatedIncludes.filter((includedResource) =>
    !included.find(
      (resource) => resource.type === includedResource.type && resource.id === includedResource.id,
    ),
  );
  return [...included, ...newIncludes];
}

/**
 * Extract SAP messages from included cart resources.
 *
 * @param {Array<IBaseModel>} cartIncludes
 * @returns {Array<ISapMessage>}
 */
function extractSapMessages(
  cartIncludes: Array<IBaseModel>,
): Array<ISapMessage> {
  const messageIncludes = cartIncludes.filter(item => item.type === EGlueResource.SAP_MESSAGES);
  // @ts-ignore
  return messageIncludes[0]?.attributes.messages || [];
}
