import { CmsService } from '../services';
import { Injectable, Injector } from '@angular/core';
import { ICmsPage, IContentBanner, IContentBannerAttributes } from '../models/cms';
import { Observable } from 'rxjs';
import {
  selectShopHomepageContentBannerData,
  selectShopContentBannerDataError,
  selectShopContentBannerDataLoading, selectShopStoreSelectionContentBannerData,
} from '../reducers/homepage.reducer';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { HomepageActions } from '../actions';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CmsFacade {
  private pCmsService: CmsService;

  public get cmsService(): CmsService {
    if (!this.pCmsService) {
      this.pCmsService = this.injector.get(CmsService);
    }
    return this.pCmsService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>) {
  }

  getCmsPageData(key: string): Observable<ICmsPage> {
    return this.cmsService.getCMSPage(key);
  }

  getContentBannerByKey(key: string): Observable<IContentBanner> {
    return this.cmsService.getContentBannerByKey(key);
  }

  selectShopHomepageBannerLoading(): Observable<boolean> {
    return this.store.select(selectShopContentBannerDataLoading);
  }

  selectShopHomepageBannerData(bannerKey: string): Observable<IContentBannerAttributes> {
    if (bannerKey === environment.homePageBannerKey) {
      return this.store.select(selectShopHomepageContentBannerData);
    }else {
      return this.store.select(selectShopStoreSelectionContentBannerData);
    }
  }

  selectShopHomepageBannerError(): Observable<string> {
    return this.store.select(selectShopContentBannerDataError);
  }

  getHomepageContentBannerData(bannerKey: string): void {
    this.store.dispatch(HomepageActions.loadShopContentBanner({bannerKey: bannerKey}));
  }

  getStoreSelectionContentBannerData(bannerKey: string): void {
    this.store.dispatch(HomepageActions.loadShopContentBanner({bannerKey: bannerKey}));
  }

}


