<div class="selection-container col-lg-5 col-md-8"
     [ngClass]="{ 'selection-container-with-margin': isInCurrentCartComponent === false }">
  <ng-container *ngIf="!loading; else loadingAnimation">
    <ng-container *ngIf="isEquipmentSelected(); else equipmentNotSelected">
      <p *ngIf="!isInCurrentCartComponent" class="selected-equipment--title">
        {{ "equipment-selection.selected-equipment" | translate }}
      </p>
      <div class="selected-equipment"
           [ngClass]="{ 'selected-equipment-with-shadow': isInCurrentCartComponent === false }">
        <div class="image">
          <img alt="{{ systemDetails.nameEnUs }}" [src]="systemDetails.externalUrlSmall"/>
        </div>
        <div class="description" [ngClass]="{'non-business-partner': !isBusinessPartner}">
          <p class="name" [attr.data-test]="'desc-systemName'">
            {{ systemDetails.nameEnUs }}
          </p>
          <p class="fl-number" [attr.data-test]="'desc-functionalLocationNumber'">
            {{ "equipment-selection.fl" | translate }} {{ systemDetails.siemensEquipmentId }}
          </p>
          <p class="sold-to uppercase" *ngIf="isBusinessPartner && !!activeSoldToAccount"
             [attr.data-test]="'desc-functionalLocationAddress'">
            {{ "equipment-selection.sold-to" | translate }} {{ activeSoldToAccount.id }} -
            {{ activeSoldToAccount.company }}
          </p>
          <p class="customer uppercase" *ngIf="isBusinessPartner && !!systemDetails.companyBusinessUnit"
             [attr.data-test]="'desc-systemDetailsCompanyBusinessUnit'">
            {{ "equipment-selection.customer" | translate }} {{ systemDetails.companyBusinessUnit }}
          </p>
        </div>
        <app-dropdown class="icon-kebab-dropdown" [ngClass]="{'non-business-partner': !isBusinessPartner}"
                      [dropDownName]="'selected-equipment'">
          <ng-container ngProjectAs="[slot][toggle]">
            <span class="icon-more"></span>
          </ng-container>
          <ng-container ngProjectAs="[slot][panel]">
            <a *ngIf="currentCart?.attributes.totalItemsQuantity === 0" class="change-equipment menu__item"
               [attr.data-test]="'link-removeEquipmentSelection'" (click)="removeEquipmentSelection()">
              {{ "equipment-selection.remove-equipment" | translate }}
            </a>
            <a class="change-equipment menu__item" [attr.data-test]="'link-changeEquipment'"
               (click)="redirectToMyEquipmentPage()">
              {{ "equipment-selection.change-equipment" | translate }}
            </a>
            <a *ngIf="isAnySoldToAccount && isBusinessPartner" class="change-equipment menu__item"
               [attr.data-test]="'link-changeSoldto address'" (click)="togglePopupForChangeSoldToAddress(true)">
              {{ "equipment-selection.change-sold-to" | translate }}
            </a>
          </ng-container>
        </app-dropdown>
      </div>
    </ng-container>
    <ng-template #equipmentNotSelected>
      <a class="button" [attr.data-test]="'link-redirectToMyEquipment'" (click)="redirectToMyEquipmentPage()">
        {{ "equipment-selection.select-equipment" | translate }}
      </a>
      <p class="selection-message" [attr.data-test]="'desc-selectionMessage'">
        {{ "equipment-selection.equipment-not-selected" | translate }}
      </p>
    </ng-template>
  </ng-container>
  <ng-template #loadingAnimation>
    <div class="equipment-loader">
      <div class="image__thumbnail stripe"></div>
      <div class="description">
        <p class="stripe"></p>
        <p class="stripe"></p>
        <p class="stripe"></p>
      </div>
    </div>
  </ng-template>
  <app-modal-select-sold-to-account
      [showModalConfirm]="showChangeSoldToPopup" [soldToAccounts]="soldToAccounts"
      (closeModalConfirm)="showChangeSoldToPopup = false"
      [currentCart]="currentCart"
      (primaryActionConfirm)="selectedSoldToAccountFromModal($event)"
      (updatedPricesAfterChangeSoldTo)="handleChangeSoldTo($event)">
  </app-modal-select-sold-to-account>
</div>
