import { createAction, props } from '@ngrx/store';
import {
  ICompanyBusinessUnits,
  ICustomerAddressData,
  ICustomerPreferences,
} from '../models/customer.models';
import { IAddress, IPointOfContact } from '../models/common.models';
import { ISoldToAddressesResponse } from '../models/soldTo-selection.models';

export const CompanyUsersActionStart = createAction(
  '[Customer] - Start Get Company Users',
);

// IMPORTANT: This action serves as the point at which the user has successfully logged in
// and the company user data has been loaded from Spryker. Catching this action in an effect
// is the way how to load additional data for the customer in the background (orders history,
// homepage banner installed base from fleet)
export const CompanyUsersActionSuccess = createAction(
  '[Customer] - Success Get Company Users',
  props<{payload: any}>(),
);

export const CompanyUsersActionError = createAction(
  '[Customer] - Error Get Company Users',
  props<{error: any}>(),
);

export const updateCustomerPreferences = createAction(
  '[Customer] - Update customer preferences',
  props<{data: ICustomerPreferences}>(),
);

export const setStore = createAction(
  '[Customer] - Set store',
  props<{store: any}>(),
);

export const loadCustomerData = createAction(
  '[Customer] - Load role and cart',
);

export const loadCustomerDataError = createAction(
  '[Customer] - Error Load role and cart',
  props<{error: any}>(),
);

export const setConsentedTerms = createAction(
  '[Customer] - Set consented terms',
  props<{newConsentedTerms: string[]}>(),
);

export const startGetBusinessUnitAction = createAction(
  '[Customer] - Begin Get Business Unit',
);

export const successGetBusinessUnitAction = createAction(
  '[Customer] - Success Get Business Unit',
  props<{payload: ICompanyBusinessUnits}>(),
);

export const errorGetBusinessUnitAction = createAction(
  '[Customer] - Error Get Business Unit',
  props<{error: any}>(),
);

export const beginCustomerAddressAction = createAction(
  '[Customer] - Begin Get Customer Address',
  props<{payload: string}>(),
);

export const successGetCustomerAddressAction = createAction(
  '[Customer] - Success Get Customer Address',
  props<{payload: ICustomerAddressData}>(),
);

export const errorGetCustomerAddressAction = createAction(
  '[Customer] - Error Get Customer Address',
  props<{error: any}>(),
);

export const loadCustomerShipToAddresses = createAction(
  '[Customer] - Load customer ShipTo addresses',
);

export const loadCustomerShipToAddressesSuccess = createAction(
  '[Customer] - Success Load customer ShipTo addresses',
  props<{payload: any}>(),
);

export const loadCustomerShipToAddressesError = createAction(
  '[Customer] - Error Load customer ShipTo addresses',
  props<{error: any}>(),
);

export const loadCustomerSoldToAddresses = createAction(
  '[Customer] - Load customer SoldTo addresses',
);

export const loadCustomerSoldToAddressesSuccess = createAction(
  '[Customer] - Success Load customer SoldTo addresses',
  props<{payload: ISoldToAddressesResponse}>(),
);

export const loadCustomerSoldToAddressesError = createAction(
  '[Customer] - Error Load customer SoldTo addresses',
  props<{error: any}>(),
);

export const setPointOfContact = createAction(
  `[Customer] Set Point Of Contact`,
  props<{data: IPointOfContact}>(),
);

/**
 * @Description Used for clearing a Point Of Contact on the 2. delivery page when switching or deleting the current cart.
 */
export const clearPointOfContact = createAction(
  `[Customer] Clear Point Of Contact`
);

export const setCustomShipToAddress = createAction(
  `[Customer] Set Custom Ship To Address`,
  props<{data: IAddress}>(),
);

/**
 * @Description Used for clearing a customer's custom ship address on the 1. delivery page when switching or deleting
 * the current cart.
 */
export const clearCustomShipToAddress = createAction(
  `[Customer] Clear Custom Ship To Address`
);
