<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <h3 class="page-title" [attr.data-test]="'title-2ndDetailsPage'">
          {{ 'checkout-delivery-details.title' | translate }}
        </h3>
        <h6 class="page-subtitle" [attr.data-test]="'subtitle-2ndDetailsPage'">
          {{ 'checkout-delivery-details.select-point-of-contact-and-shipping' | translate }}
        </h6>
        <section class="contact-details-form width-100">
          <div class="width-100">
            <app-textfield
                label="{{ 'checkout-delivery-details.attention-to' | translate: {maxNumberOfChars: maxLengthAttentionTo} }}"
                [form]="contactDetailsForm"
                [modelValue]="contactDetailsForm.value.attentionTo"
                [inputName]="'attentionTo'"
                [maxLength]="maxLengthAttentionTo"
                [ngClass]="{'is-invalid': contactDetailsForm.get('attentionTo').touched && contactDetailsForm.get('attentionTo').errors}"
                (fieldFilled)="setContactFormValue($event)"
                [readOnly]="isSaturdayShipment">
            </app-textfield>
            <div class="alert-controls textarea-alert" *ngIf="contactDetailsForm.value.attentionTo">
              <small class="alert-text">
                {{ contactDetailsForm.value.attentionTo.length }}/{{ maxLengthAttentionTo }}
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="textfield-with-controls width-50">
              <app-textfield
                  label="{{ 'checkout-delivery-details.select-first-name' | translate }}"
                  [form]="contactDetailsForm"
                  [modelValue]="contactDetailsForm.value.firstName"
                  [inputName]="'firstName'"
                  [isRequired]="true"
                  [ngClass]="{'is-invalid': contactDetailsForm.get('firstName').touched && contactDetailsForm.get('firstName').errors}"
                  (fieldFilled)="setContactFormValue($event)">
              </app-textfield>
              <div class="alert-controls" *ngIf="contactDetailsForm.get('firstName').touched">
                <ng-container *ngIf="contactDetailsForm.get('firstName').hasError('maxlength')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                  </small>
                </ng-container>
                <ng-container *ngIf="contactDetailsForm.get('firstName').hasError('pattern')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.only-letters' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="textfield-with-controls width-50">
              <app-textfield
                  label="{{ 'checkout-delivery-details.select-last-name' | translate }}"
                  [form]="contactDetailsForm"
                  [modelValue]="contactDetailsForm.value.lastName"
                  [inputName]="'lastName'"
                  [isRequired]="true"
                  [ngClass]="{'is-invalid': contactDetailsForm.get('lastName').touched && contactDetailsForm.get('lastName').errors}"
                  (fieldFilled)="setContactFormValue($event)">
              </app-textfield>
              <div class="alert-controls" *ngIf="contactDetailsForm.get('lastName').touched">
                <ng-container *ngIf="contactDetailsForm.get('lastName').hasError('maxlength')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
                  </small>
                </ng-container>
                <ng-container *ngIf="contactDetailsForm.get('lastName').hasError('pattern')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.only-letters' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="textfield-with-controls width-100">
            <app-textfield
                label="{{ 'request-details.input-email' | translate }}"
                [inputType]="'email'"
                [form]="contactDetailsForm"
                [modelValue]="contactDetailsForm.value.email"
                [inputName]="'email'"
                [isRequired]="true"
                [ngClass]="{'is-invalid': contactDetailsForm.get('email').touched && contactDetailsForm.get('email').errors}"
                (fieldFilled)="setContactFormValue($event)">
            </app-textfield>
            <div class="alert-controls" *ngIf="contactDetailsForm.get('email').touched">
              <ng-container *ngIf="contactDetailsForm.get('email').hasError('email')">
                <small class="alert-text">
                  {{ 'request-details.email-pattern' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
          <div class="form-row">
            <div class="textfield-with-controls width-50">
              <app-textfield
                  label="{{ 'checkout-delivery-details.select-phone-number' | translate }}"
                  [form]="contactDetailsForm"
                  [modelValue]="contactDetailsForm.value.phone"
                  [inputName]="'phone'"
                  [isRequired]="true"
                  trim
                  [multiCharToTrim]="['-']"
                  trimWhitespaces
                  [ngClass]="{'is-invalid': contactDetailsForm.get('phone').touched && contactDetailsForm.get('phone').errors}"
                  (fieldFilled)="setContactFormValue($event)">
              </app-textfield>
              <div class="alert-controls" *ngIf="contactDetailsForm.get('phone').touched">
                <ng-container *ngIf="contactDetailsForm.get('phone').hasError('maxlength')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}
                  </small>
                </ng-container>
                <ng-container *ngIf="contactDetailsForm.get('phone').hasError('pattern')">
                  <small class="alert-text">
                    {{ 'checkout-delivery-details.only-numbers' | translate }}
                  </small>
                </ng-container>
              </div>
            </div>
            <div class="width-50">
              <app-select-box
                  label="{{ 'checkout-delivery-details.shipping-method' | translate }}"
                  [form]="contactDetailsForm"
                  [modelValue]="contactDetailsForm.value.shipmentMethod"
                  [options]="editedShipmentMethodOptions"
                  [inputName]="'shipmentMethod'"
                  [isRequired]="true"
                  [ngClass]="{'is-invalid': contactDetailsForm.get('shipmentMethod').errors}"
                  [disabled]="shipmentMethodsLoading"
                  [loading]="shipmentMethodsLoading"
                  (selected)="setShipmentMethod($event)"
                  (click)="checkContactFormValues()">
              </app-select-box>
            </div>
          </div>
          <p class="disclaimer--shipment-method">
            {{ 'checkout-delivery-details.shipment-method-disclaimer' | translate }}
            <a class="button button--link" target="_blank" [routerLink]="['/terms/terms-and-conditions']"
               [attr.data-test]="'link-viewShippingPolicy'">
              <span class="icon-arrow-right color-orange"></span>
              <span class="underline">{{ 'checkout-delivery-details.view-shipping-policy-btn' | translate }}</span>
            </a>
          </p>
          <div class="textarea-with-controls width-100">
            <app-textarea
                label="{{ 'checkout-delivery-details.special-shipping-instructions' | translate }}"
                [form]="contactDetailsForm"
                [modelValue]="contactDetailsForm.value.comment"
                [rows]="5"
                [cssStyles]="{wrapper: 'text-area-comment'}"
                [inputName]="'comment'"
                [maxLength]="maxLengthComment"
                trim
                trimWhitespaces
                [ngClass]="{'is-invalid': contactDetailsForm.get('comment').touched && contactDetailsForm.get('comment').errors}"
                (areaFilled)="setContactFormValue($event)"
                [readOnly]="isSaturdayShipment">
            </app-textarea>
            <p class="disclaimer--shipment-method" *ngIf="isSaturdayShipment">
              {{ 'checkout-delivery-details.saturday-delivery-method-disclaimer' | translate }}
            </p>
            <div class="alert-controls textarea-alert" *ngIf="contactDetailsForm.value.comment">
              <small class="alert-text">
                {{ contactDetailsForm.value.comment.length }}/{{ maxLengthComment }}
              </small>
            </div>
          </div>
          <p class="disclaimer--mandatory-fields">
            <small>
              {{ 'checkout-delivery-details.mandatory-fields-required' | translate }}
            </small>
          </p>
        </section>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 order-summary">
    <div class="row">
      <div class="col-12">
        <p class="disclaimer--finalize-order">
          {{ 'checkout-delivery-details.continue-to-finalize-order' | translate }}
        </p>
        <div class="buttons-group">
          <button
              appDebounceClick
              class="button button--primary"
              type="button"
              [disabled]="!isFormValid() || creatingOrderInProgress || recalculatingNewPricesInProgress"
              [debounceTime]="300"
              (debounceClick)="proceedToCreateOrder()"
              [attr.data-test]="'button-proceedToCreateOrder'">
            {{ 'checkout-delivery-details.continue-btn' | translate }}
          </button>
          <button
              class="button button-previous-page"
              type="button"
              (click)="backToOrderDetailsEmit()"
              [attr.data-test]="'button-previousPage'">
            {{ 'request-details.previous-page' | translate }}
          </button>
        </div>
        <section class="cart-pricelist">
          <div class="cart--subtotal-price">
            <span [attr.data-test]="'label-subtotalPrice'">{{ 'checkout-delivery-details.subtotal' | translate }}</span>
            <span [attr.data-test]="'desc-subtotalPrice'">{{ cartAttributes.totals.subtotal | currency: cartAttributes.currency }}</span>
          </div>
          <div class="cart--shipping-price">
            <span [attr.data-test]="'label-shippingAndHandlingPrice'">{{ 'checkout-delivery-details.shipping-and-handling' | translate }}</span>
            <span [attr.data-test]="'desc-shippingAndHandlingPrice'" *ngIf="!recalculatingNewPricesInProgress; else loadingAnimation">
              {{ contactDetailsForm.value.shipmentMethod ? (totals.expenseTotal | currency: cartAttributes.currency) : '-' }}
            </span>
            <ng-template #loadingAnimation>
              <span class="loading-animation"></span>
            </ng-template>
          </div>
          <div class="cart--order-total-price">
            <strong [attr.data-test]="'label-orderTotalPrice'">{{ 'checkout-delivery-details.order-total' | translate }}</strong>
            <strong [attr.data-test]="'desc-orderTotalPrice'" *ngIf="!recalculatingNewPricesInProgress; else loadingAnimation">
              {{ cartAttributes.totals.priceToPay | currency: cartAttributes.currency }}
            </strong>
            <ng-template #loadingAnimation>
              <span class="loading-animation"></span>
            </ng-template>
          </div>
        </section>
        <app-cart-rules
            *ngIf="cartRules?.length > 0"
            [cartRules]="cartRules"
            [priceToPay]="totals.priceToPay"
            [currency]="cartAttributes.currency">
        </app-cart-rules>
        <p class="small" [attr.data-test]="'label-taxDisclaimer'">
          {{ 'checkout-delivery-details.tax-disclaimer' | translate }}
        </p>
        <hr class="bottom-line">
        <a class="button button--link button-back-to-cart" [routerLink]="['/shop-cart']"
           [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left color-orange"></span>
          {{ 'checkout-delivery-details.back-to-cart' | translate }}
        </a>
        <h6 class="section-title">
          {{ 'checkout-delivery-details.your-cart' | translate }}
        </h6>
        <section class="cart-items">
          <ng-container *ngFor="let item of cartItemsWithDetails">
            <div class="cart-item">
              <div class="cart-item--name">
                <h5 class="item-name" [attr.data-test]="'desc-itemName'">
                  {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                </h5>
                <span class="label item-exchange-material" *ngIf="item.attributes.availability?.isExchangeable"
                      [attr.data-test]="'label-exchangeableItem'">
                    {{ 'spare-parts.exchangeable-item' | translate }}
                </span>
                <span class="label heavyweight-item" *ngIf="item.attributes.heavyweight"
                      [attr.data-test]="'label-heavyWeightItem'">
                    {{ 'spare-parts.heavyweight-item' | translate }}
                </span>
              </div>
              <div class="cart-item--body">
                <div class="cart-item--image">
                  <img [src]="getProductImageUrl(item)" [alt]="item.attributes.name" [attr.data-test]="'img-itemImage'">
                </div>
                <div class="cart-item--info">
                  <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                    {{ item.attributes.materialNumber }}
                  </div>
                  <div class="cart-item--additional-fields">
                    <ng-container *ngFor="let additionalField of itemDetailsAdditionalFields">
                      <ng-container *ngIf="additionalField.shouldDisplayFunc(item)">
                        <div class="additional-field-row">
                          <div [attr.data-test]="'label-' + additionalField.name">
                            {{ additionalField.name | translate }}:
                          </div>
                          <div [attr.data-test]="'value-' + additionalField.name">
                            {{ additionalField.getValFunc(item) }}
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <app-message *ngIf="isPriceDisputingActive && isPriceDisputingSetForItem(item.id)"
                           [message]="itemDisputedPricingMessage">
              </app-message>
            </div>
          </ng-container>
        </section>
      </div>
    </div>
  </div>
</div>
