import { AdvancedFilter } from '../../../../models/advanced-filter';

export let sparePartHybrisOrderHistoryFilters: AdvancedFilter[] = [
  {
    name: 'soldToAddress',
    attribute: 'attributes',
    firstAdditionalAttribute: 'soldTo',
    options: [],
    selected: []
  },
  {
    name: 'shipToAddress',
    attribute: 'attributes',
    firstAdditionalAttribute: 'shipTo',
    options: [],
    selected: []
  },
  {
    name: 'orderDateFrom',
    attribute: 'attributes',
    firstAdditionalAttribute: 'orderCreationDate',
    options: [],
    selected: []
  },
  {
    name: 'orderDateTo',
    attribute: 'attributes',
    firstAdditionalAttribute: 'orderCreationDate',
    options: [],
    selected: []
  },
  {
    name: 'orderStatus',
    attribute: 'attributes',
    firstAdditionalAttribute: 'orderStatus',
    options: [],
    selected: []
  },
  {
    name: 'flNumber',
    attribute: 'attributes',
    firstAdditionalAttribute: 'equipment',
    options: [],
    selected: []
  },
  {
    name: 'materialNumber',
    attribute: 'attributes',
    firstAdditionalAttribute: 'items',
    options: [],
    selected: []
  },
  {
    name: 'orderNumber',
    attribute: 'id',
    options: [],
    selected: []
  },
  {
    name: 'purchaseOrder',
    attribute: 'attributes',
    firstAdditionalAttribute: 'poNumber',
    options: [],
    selected: []
  }
];
