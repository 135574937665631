<ng-container>
  <ng-container>
    <div class="contract-details" *ngIf="agreement">
      <div class="details" [ngClass]="{'disabled': disableAgreement()}">
        <div class="details__info">
          <div class="details__list">
            <div
                *ngFor="let attribute of attributes"
                class="details__list-item {{attribute.attribute}}"
                [ngClass]="attribute.attribute === 'contractNumber' ? 'hidden' : ''">
              <div class="details__list-item--title">
                {{ attribute.label | translate }}
                <app-tooltip
                    *ngIf="attribute.tooltip"
                    link="icon"
                    text="{{ attribute.tooltip | translate }}">
                </app-tooltip>
              </div>
              <div class="details__list-item--data">
                {{ getAttribute(attribute.attribute, attribute.nestedAttribute ?? '') }}
              </div>
            </div>
          </div>
        </div>
        <app-access-control [notForViewer]="true">
          <ng-container ngProjectAs="[slot][nonViewerContent]">
            <div class="details__buttons" *ngIf="disableAgreement() else activeButtons">
              <span class="new-quote-desc">{{ 'my-contracts.start-new-quote' | translate }}</span>
            </div>
            <ng-template #activeButtons>
              <div class="details__buttons">
                <span class="equipment-buttons" *ngIf="selectableEquipment">
                  <button
                      *ngIf="!equipmentSelectionInProgress; else loadingSpinner"
                      type="button"
                      class="button button--primary action-button"
                      [attr.data-test]="'button-selectFunctionalLocation'"
                      [disabled]="disableSelectEquipmentButton()"
                      (click)="selectFlWithEndOfSupportCheck()">
                    <span>{{ 'my-equipment.select-equipment' | translate }}</span>
                  </button>
                  <ng-template #loadingSpinner>
                    <div class="spinner">
                      <div class="loading-animation"></div>
                    </div>
                  </ng-template>
                  <div class="additional-options" *ngIf="isUserSparePartsViewer()">
                    <a class="spare-parts-viewer"
                       (click)="openSparePartsViewer()"
                       [attr.data-test]="'link-sparePartsViewer'">
                      <span class="icon-arrow-long-right"></span>
                      <span>{{ 'my-equipment.select-spare-parts-viewer' | translate }}</span>
                    </a>
                  </div>
                </span>
              </div>
            </ng-template>
          </ng-container>
        </app-access-control>
      </div>
      <app-modal
          *ngIf="isAddQuoteModalOpen"
          [id]="'add-quote-modal'"
          [open]="isAddQuoteModalOpen"
          [title]="'my-contracts.add-quote-modal-title' | translate"
          (crossClick)="isAddQuoteModalOpen = false"
          (outsideClick)="isAddQuoteModalOpen = false">
        <ng-container ngProjectAs="[slot][body]">
          <app-add-quote-modal
              [flNumber]="agreement.attributes?.functionalLocation"
              [materialNumber]="agreement.attributes?.materialNumber"
              [soldToIdFromParams]="soldToIdFromParams"
              [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
              [productSku]="productSku">
          </app-add-quote-modal>
        </ng-container>
      </app-modal>
    </div>
  </ng-container>
</ng-container>
<app-modal-confirm
    class="modal-confirm"
    [type]="iconType.WARNING"
    modalTitle="{{ 'equipment-selection.you-have-items-in-cart' | translate }}"
    notifyText="{{ 'equipment-selection.change-equipment-or-create-cart-info-text' | translate }}"
    secondaryBtnText="{{ 'equipment-selection.new-cart' | translate }}"
    primaryBtnText="{{ 'equipment-selection.change-equipment' | translate }}"
    [showModalConfirm]="showModalChangeEquipmentOrCreateNewCart"
    (closeModalConfirm)="closeModalChangeEquipmentOrCreateNewCart()"
    (secondaryActionConfirm)="createNewCartForEquipment()"
    (primaryActionConfirm)="changeEquipmentForCurrentCart()">
</app-modal-confirm>
<app-modal-select-sold-to-account
    [isEquipmentSelectionPage]="true"
    [showModalConfirm]="showModalSelectSoldToAccount"
    (closeModalConfirm)="showModalSelectSoldToAccount = false"
    (primaryActionConfirm)="selectedSoldToAccountFromModal($event)"
    [currentCart]=getCurrentCart()
    [isMissingSoldTo]="this.showModalBecauseMissingSoldTo"
    [soldToAccounts]="soldToAccounts"
/>
<app-modal-eos-equipment
    [showModalEosEquipment]="showModalEosEquipment"
    (closeModalConfirm)="showModalEosEquipment = $event"
    (primaryActionConfirm)="showModalEosEquipment = $event; selectSoldToAccountAfterSelectedFl()"
/>

