import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IAbstractProduct } from '../models/abstract-product.models';
import { catchError } from 'rxjs/operators';
import { GlueConfigurationService } from './glue-configuration.service';
import { GlueUtils } from '../utils/glue.utils';
import { EGlueResource } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  baseUrl = this.glueConfiguration.getEndpointUrl();

  private httpParamsForProductsCategoryTree = {
    params: new HttpParams()
      .set('include', 'category-nodes'),
  };

  private httpParamsForInstalledBase = {
    params: new HttpParams()
      .set('include', 'install-base'),
  };

  private httpParamsForAbstractProduct = {
    params: new HttpParams()
      .set('include', 'install-base,abstract-product-image-sets,abstract-product-prices,product-labels,concrete-products'),
  };

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred ', error);
    return Promise.reject(error.message || error);
  }

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  getAbstractProductDataFromApi(sku: string): Observable<IAbstractProduct> {
    return this.http.get<IAbstractProduct>(this.baseUrl + '/abstract-products/' + sku,
      this.httpParamsForAbstractProduct).pipe(catchError(this.handleError));
  }

  getProductBySku(sku: string): Observable<IAbstractProduct> {
    return this.http.get<IAbstractProduct>(this.baseUrl + '/abstract-products/' + sku)
      .pipe(catchError(this.handleError));
  }

  getAbstractProductWithInstalledBase(id: string): Observable<IAbstractProduct> {
    return this.http.get<IAbstractProduct>(this.baseUrl + '/abstract-products/' + id,
      this.httpParamsForInstalledBase);
  }

  getProductWithCategoryTree(id: string): Promise<any> {
    return this.http.get<IAbstractProduct>(this.baseUrl + '/abstract-products/' + id,
      this.httpParamsForProductsCategoryTree)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(ProductsService.handleError);
  }

  getProductPhotos(id: string): Observable<any> {
    return this.http.get(this.baseUrl + '/abstract-products/' + id + '/abstract-product-image-sets')
      .pipe(catchError(this.handleError));
  }

  getProductPrices(id: string): Observable<any> {
    return this.http.get(this.baseUrl + '/abstract-products/' + id + '/abstract-product-prices')
      .pipe(catchError(this.handleError));
  }

  getConcreteProductForWishlistFromApi(sku: string): Observable<IAbstractProduct> {
    const include = '?include=concrete-product-prices,concrete-product-image-sets&fields[concrete-products]=sku,name&fields[concrete-product-prices]=prices';
    return this.http.get<IAbstractProduct>(this.baseUrl + '/concrete-products/' + sku + include)
      .pipe(catchError(this.handleError));
  }

  getAbstractProductPricesAndAvailabilitiesFromSap(sku: string, quoteUuid: string): Observable<any> {
    const params = GlueUtils.addIncludeParam(
      new HttpParams(),
      [EGlueResource.ABSTRACT_PRODUCT_PRICES, EGlueResource.ABSTRACT_PRODUCT_AVAILABILITIES],
    ).set('quote-uuid', quoteUuid);
    return this.http.get(this.baseUrl + '/abstract-products/' + sku, {params})
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }
}
