import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EFeatureToggles } from '../../configurations/common';
import { Subject } from 'rxjs';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { IStoreAddress } from '../../models/settings.model';

@Component({
  selector: 'app-contact-us-footer',
  templateUrl: './contact-us-footer.component.html',
  styleUrls: ['./contact-us-footer.component.scss']
})
export class ContactUsFooterComponent implements OnInit {

  address: IStoreAddress;
  isCpqEnabled: boolean = false;
  isSparePartsEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configurationFacade: ConfigurationFacade,
  ) {}

  ngOnInit(): void {
    this.address = this.configurationFacade.getCurrentStoreAddress();
    this.selectUsFeatures();
  }

  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
    });
  }
}
