<app-alert></app-alert>
<div class="header__fixed" appWalkMe>
  <app-application-bar></app-application-bar>
  <app-navigation-bar></app-navigation-bar>
  <div *ngIf="!isOnStoreSelection()" class="container mini-cart__container">
    <div class="mini-cart" app-mini-cart></div>
  </div>
</div>
<div class="container main-content">
  <app-modal-sap-p40></app-modal-sap-p40>
  <app-breadcrumbs *ngIf="!isOnStoreSelection()"></app-breadcrumbs>
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="!isOnStoreSelection()"></app-footer>
<app-full-page-loader *ngIf="showFullPageLoader" [waitingList]="fullPageLoaderList"></app-full-page-loader>
