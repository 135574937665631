import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ISapMessage } from '../../models/sap.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sap-message-controller',
  templateUrl: './sap-message-controller.component.html',
  styleUrls: ['./sap-message-controller.component.scss'],
})
export class SapMessageControllerComponent implements OnInit, OnChanges {

  @Input() messages: ISapMessage[] = [];
  messagesOpened: boolean;

  constructor(private translate: TranslateService) {
  }

  ngOnChanges(): void {
    this.sortMessagesByTypes();
  }

  ngOnInit(): void {
    this.sortMessagesByTypes();
    this.messagesOpened = this.messages.length > 0;
  }

  toogleMessages(): void {
    this.messagesOpened = !this.messagesOpened;
  }

  sortMessagesByTypes(): void {
    const priorityOfTypes = ['E', 'W', 'S', 'I', ''];
    this.messages.sort((message1, message2) =>
      priorityOfTypes.indexOf(message1.type) - priorityOfTypes.indexOf(message2.type),
    );
  }

  getName(materialNumber: string): string {
    return materialNumber
      ? `${this.translate.instant('sap-message-controller.specific-message-name')} ${materialNumber}`
      : this.translate.instant('sap-message-controller.default-message-name');
  }

  getColoredLabel(messageType: string): string {
    switch (messageType) {
      case 'E':
        return 'message__label--red';
      case 'W':
      case 'S':
        return 'message__label--yellow';
      default:
        return 'message__label--gray';
    }
  }

  getSpecificIcon(messageType: string, isHeader = false): string {
    switch (messageType) {
      case 'E':
        return 'icon-exclamationmark icon-exclamationmark__circle';
      case 'W':
      case 'S':
        return 'icon-warning icon-warning__circle';
      case 'I':
        return 'icon-info icon-info__circle';
      case '':
        return SapMessageControllerComponent.getInfoIcon(isHeader);
      default:
        return 'no icon';
    }
  }

  private static getInfoIcon(isHeader) {
    return isHeader ? 'icon-info icon-info__circle' : 'no icon'
  }
}
