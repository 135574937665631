import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ObjectUtils } from '../../utils/object.utils';
import { ICurrentParams } from '../../models/catalog.models';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  openedFilters = [];
  @Input() filters: Array<any>;
  @Input() activeFilters: ICurrentParams;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.openActiveFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters?.currentValue.length > 0) {
      this.openActiveFilters();
    }
  }

  openActiveFilters(): void {
    if (this.filters.length > 0) {
      this.filters.forEach(filter => {
        if (filter.activeValue) {
          this.openedFilters.push(filter.name);
        }
      });
    }
  }

  showOptions(name: string): void {
    const exists = this.openedFilters.findIndex(filter => filter === name);
    if (exists !== -1) {
      this.openedFilters.splice(exists, 1);
      return;
    }
    this.openedFilters.push(name);
  }

  isOptionSelected(name: string, value: string | number): boolean {
    if (!this.activeFilters.hasOwnProperty(name)) {
      return false;
    }

    if (Array.isArray(this.activeFilters[name])) {
      return this.activeFilters[name].find(param => param === value);
    } else {
      return this.activeFilters[name] === value;
    }
  }

  queryParamsForMultiSelect(name: string, value: string | number): any {
    // eslint-disable-next-line prefer-const
    let urlTree = this.router.parseUrl(this.router.url);

    if (!urlTree.queryParams.hasOwnProperty(name)) {
      urlTree.queryParams[name] = value;
      urlTree.queryParams.page = 1;
      return urlTree.queryParams;
    }

    if (Array.isArray(urlTree.queryParams[name])) {
      if (urlTree.queryParams[name].find(param => param === value)) {
        urlTree.queryParams[name] = urlTree.queryParams[name].filter(param => param !== value);
      } else {
        urlTree.queryParams[name].push(value);
      }
    } else if (urlTree.queryParams[name] === value) {
      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, name);
    } else {
      urlTree.queryParams[name] = [urlTree.queryParams[name], value];
    }

    urlTree.queryParams.page = 1;
    return urlTree.queryParams;
  }

  queryParamsForSingle(name: string, value: string | number): any {
    // eslint-disable-next-line prefer-const
    let urlTree = this.router.parseUrl(this.router.url);

    if (urlTree.queryParams.hasOwnProperty(name) && urlTree.queryParams[name] === value) {
      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, name);
    } else {
      urlTree.queryParams[name] = value;
    }

    return urlTree.queryParams;
  }

  filterResults(filter, option): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);

    const queryParams = filter.config.isMultiValued
      ? this.queryParamsForMultiSelect(filter.name, option.value)
      : this.queryParamsForSingle(filter.name, option.value);

    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams},
    ).toString();

    this.router.navigateByUrl(url).then();
  }
}
