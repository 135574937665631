<ng-container>
  <div class="mini-cart__actions" *ngIf="currentCartId">
    <div class="mini-cart__name">
      <h5 *ngIf="usersCarts?.length === 1" class="current-cart-name">
        {{ currentCartName }}
      </h5>
      <app-dropdown [dropDownName]="'miniCart-name'" *ngIf="usersCarts?.length > 1">
        <ng-container ngProjectAs="[slot][toggle]">
          <button class="button button--link mini-cart__btn-name">
            <h5>{{ currentCartName }}</h5>
            <span class="icon-arrow-down"></span>
          </button>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <ng-container>
            <span *ngFor="let cart of usersCarts"
                class="menu__item"
                [ngClass]="{'hidden': cart.id === currentCartId}"
                (click)="switchDefaultCart(cart.id)">
              {{ cart.name }}
            </span>
          </ng-container>
        </ng-container>
      </app-dropdown>
    </div>
    <div class="mini-cart__settings">
      <app-dropdown [dropDownName]="'miniCart-settings'">
        <ng-container ngProjectAs="[slot][toggle]">
          <button class="button button--link mini-cart__btn-settings">
            <h5>{{ 'mini-cart.quote-settings' | translate }}</h5>
            <span class="icon-arrow-down"></span>
          </button>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <span class="menu__item" (click)="showAddNicknameModalEmit()">
            {{ getAddOrEditNicknameLabel() | translate }}
          </span>
          <span class="menu__item" (click)="showDeleteQuoteModalEmit()">
            {{ 'mini-cart.delete-quote' | translate }}
          </span>
        </ng-container>
      </app-dropdown>
    </div>
  </div>
  <div class="mini-cart__items" *ngIf="itemsInCart?.length > 0">
    <div class="overflow-gradient"></div>
    <div class="items-list">
      <div class="item-row" *ngFor="let item of itemsInCart">
        <div class="item-col-img">
          <img [src]="getItemPicture(item)" [alt]="item.attributes.name">
        </div>
        <div class="item-col-desc" [attr.data-test]="'desc-minicartItemName-'+item.attributes.name">
          {{ item.attributes.name }}
          <div class="item-col-desc__items">
            {{ 'mini-cart.equType' | translate:{count: getEquipmentCount(item)} }}<br>
            {{ 'mini-cart.fls' | translate:{count: item.items.length} }}
            <span class="item-col-price" *ngIf="getItemPrice(item)">
              {{ getItemPrice(item) | currency:currency }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
