import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  private termsAcceptUrl = this.glueConfiguration.getEndpointUrl() + `/terms`;

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleOrderError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  postAcceptTerms(data: any): Observable<any> {
    return this.httpClient.post<any>(this.termsAcceptUrl, data).pipe(
      catchError(TermsService.handleOrderError));
  }
}
