export enum PageTypes {
  HOME_PAGE = 'Home Page',
  PRODUCT_LIST_PAGE = 'Product List Page',
  PRODUCT_PAGE = 'Product Page',
  SHOPPING_CART_PAGE = 'Shopping Cart Page',
  CHECKOUT_PAGE = 'Checkout Page',
  ORDER_CONFIRMATION_PAGE = 'Order Confirmation Page',
  DELIVERY_DETAILS_PAGE = 'Delivery details Page',
  ORDER_APPROVAL_CONFIRMATION_PAGE = 'Order Approval Confirmation Page',
  ERROR_PAGE = 'Error Page',
  SEARCH_RESULT_PAGE = 'Search Result Page',
  INSTALL_BASE_PAGE = 'Install Base Page',
  CART_PAGE = 'Cart Page',
  ORDER_SUMMARY = 'Order Summary Page',
  ORDER_REVIEW = 'Order Review Page',
  ORDER_THANK_YOU_PAGE = "Order Thank You Page",
  ORDER_DETAILS = 'Order Details Page',
  REQUEST_QUOTE_PAGE = 'Request Quote Page',
  GLOBAL_HOME_PAGE = 'Store Selection Page',
  MY_PROFILE = 'My Profile Page',
  WISHLISTS_PAGE = 'Wishlists Page',
  MY_PURCHASE_ACTIVITY_PAGE = 'My Purchase Activity Page'
}
