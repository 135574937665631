import { ObjectUtils } from './object.utils';

export class ArrayUtils {
  static uniqueObjects(arr: any[], compareKey: string): any[] {
    return Array.from(new Set(arr.map(a => a[compareKey])))
      .map(key => {
        return arr.filter(a => a[compareKey] === key).reduce((prev, curr) => {
          return {
            ...curr,
            quantity: prev.quantity + curr.quantity,
          };
        });
      });
  }

  static getNumberOfEachElements(arr: object[], objectKey: string): {} {
    return arr.reduce((acc, o) => (acc[o[objectKey]] = (acc[o[objectKey]] || 0) + 1, acc), {});
  }

  static arrayOfNumbers(count: number): Array<number> {
    return Array.from(Array(count), (_, i) => i + 1);
  }

  static searchForHighestPageHeader(elements: NodeListOf<HTMLHeadingElement>): HTMLHeadingElement | boolean {
    return elements !== null && elements.length > 0 ? elements[0] : false;
  }

  static sortByAttribute(values, attribute, revert = false, time = false, numeric = false): Array<any> {
    const sorted = values.sort((a, b) => {
      let attrA = ObjectUtils.getObjectbyString(a, attribute) ?? '';
      let attrB = ObjectUtils.getObjectbyString(b, attribute) ?? '';

      if (numeric && typeof attrA === 'number' && typeof attrB === 'number') {
        return attrA - attrB;
      }

      if (time) {
        const dateA = new Date(attrA).getTime() || 0;
        const dateB = new Date(attrB).getTime() || 0;
        return dateA - dateB;
      }

      if (typeof attrA === 'number') {
        attrA = attrA.toString();
      }
      if (typeof attrB === 'number') {
        attrB = attrB.toString();
      }

      attrA = attrA.toUpperCase();
      attrB = attrB.toUpperCase();

      if (attrA < attrB) {
        return -1;
      }
      if (attrA > attrB) {
        return 1;
      }

      return 0;
    });

    if (revert) {
      sorted.reverse();
    }

    return sorted;
  }

  static removeDuplicates(listWithDuplicates): Array<any> {
    return listWithDuplicates.filter((element, index) => {
      const elementClone = JSON.stringify(element);
      return index === listWithDuplicates.findIndex(obj => {
        return JSON.stringify(obj) === elementClone;
      });
    });
  }

  static concatArrays = (...arrays) => [].concat(...arrays.filter(Array.isArray));
}
