import { TermConstants } from '../shared/terms/TermConstants';
import { IStore } from '../models/settings.model';

export class TermsUtils {

  static getTermsUrl(
    termsKey: string,
    lang: string,
    storeConfig: IStore,
  ): any {
    const term = Object.values(TermConstants.termKeys)
      .find(t => t.termsKey === termsKey.toLowerCase());
    if (!term) {
      throw new Error(`Unknown terms key: ${termsKey}`);
    }
    const externalUrl = this.getExternalUrl(termsKey, lang, storeConfig);

    if (!!externalUrl && !!externalUrl.value) {
      return externalUrl;
    } else if (term.urlKey) {
      // terms content hosted in a CMS page in ZED
      return {
        value: `/terms/${term.urlKey.toLowerCase()}`,
        type: 'internal'
      };
    } else {
      throw new Error(
        `URL for term '${termsKey}' could not be resolved for store '${storeConfig.storeId}' and lang '${lang}'`
      );
    }
  }

  private static getExternalUrl(
    termsKey: string,
    lang: string,
    storeConfig: IStore,
  ): any {
    // Tmp workaround: lookup by code instead of ID to handle locale IDs in BR store.
    const langConfig = storeConfig.languages
      .find(l => lang === l.code);
    if (!langConfig) {
      throw new Error(`Unknown ${storeConfig.storeId} store language: ${lang}`);
    }

    const externalUrlConfig = storeConfig.externalUrls
      .find(u => termsKey === u.id && lang === u.lang);
    if (!externalUrlConfig) {
      return {}
    }

    return  {
      value: externalUrlConfig.value,
      type: 'external'
    };
  }
}
