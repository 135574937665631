<div class="category-tree-child">
  <ng-container *ngIf="useNavigate; else queryParams">
    <span
        class="category-tree-child__title" [attr.data-test]="'desc-' + category.name"
        [ngClass]="{'selected': isSelected(category)}"
        (click)="selectCategory(category)">
      {{ category.name }}
      <span *ngIf="category.itemCount" [attr.data-test]="'desc-categoryItemCount-' + category.nodeId"
            class="category-tree-child__count">({{ category.itemCount }})</span>
    </span>
  </ng-container>
  <ng-template #queryParams>
    <a
        [routerLink]="pageUrl(category)"
        [queryParams]="categoryUrl(category)"
        class="category-tree-child__title"
        [ngClass]="{'selected': isSelected(category)}"
        (click)="selectCategory(category)"
        [attr.data-test]="'category-' + category.name">
      {{ category.name }}
      <span *ngIf="category.itemCount" [attr.data-test]="'desc-categoryItemCount-' + category.nodeId"
            class="category-tree-child__count">({{ category.itemCount }})</span>
    </a>
  </ng-template>
</div>
