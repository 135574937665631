import { ArrayUtils } from './array.utils';
import { ICategoryNode } from '../models/catalog.models';

export class CategoryUtils {
  static filterCategoryChildrens(data, current: Array<any>, activeCategoryId): Array<any> {
    return data.filter(o => {
      if (o.children && o.children.length > 0) {
        o.children = this.filterCategoryChildrens(o.children, current, activeCategoryId);
        o.children = ArrayUtils.sortByAttribute(o.children, 'name');
      }
      return current.find(item => {
        if (item.value === o.nodeId) {
          o.itemCount = item.doc_count;
          o.isActive = item.value === activeCategoryId;
          return o;
        }
      });
    });
  }

  static isParentCategoryActive(categories: any[]): boolean {
    return categories.some(category => {
      if (category.isActive) {
        return true;
      }

      if (category.children?.length) {
        return this.isParentCategoryActive(category.children);
      }

      return false;
    })
  }

  static getCategoryByIdFromTree(categoryTree, categoryId): ICategoryNode | null {
    for (const branch of categoryTree) {
      const category = this.getCategoryByIdFromBranch(branch, categoryId);
      if (category !== null) {
        return category;
      }
    }

    return null;
  }

  static getCategoryByIdFromBranch(categoryTree, categoryId): ICategoryNode | null {
    if (categoryTree.nodeId === categoryId) {
      return categoryTree;
    }

    for (const children of categoryTree.children) {
      const category = this.getCategoryByIdFromBranch(children, categoryId);
      if (category !== null) {
        return category;
      }
    }

    return null;
  }

  static getCategoryByParamFromTree(categoryTree, param: string): ICategoryNode | null {
    for (const branch of categoryTree) {
      const category = this.getCategoryByParamFromBranch(branch, param);
      if (category !== null) {
        return category;
      }
    }

    return null;
  }

  static getCategoryByParamFromBranch(categoryTree, param: string): ICategoryNode | null {
    const params = categoryTree?.url?.split('/');
    if (params === null || params === undefined) {
      return null;
    }
    if (params[params.length - 1] === param) {
      return categoryTree;
    }

    for (const children of categoryTree.children) {
      const category = this.getCategoryByParamFromBranch(children, param);
      if (category !== null) {
        return category;
      }
    }

    return null;
  }
}
