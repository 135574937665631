<ng-container>
  <form>
    <app-modal
        [open]="showModalConfirm"
        [id]="'modalConfirm'"
        [title]="modalTitle"
        [type]="type"
        (crossClick)="cancelOnClick()"
        (outsideClick)="cancelOnClick()">
      <ng-container ngProjectAs="[slot][body]">
        <p [attr.data-test]="'desc-notifyText'" [innerText]="notifyText"></p>
      </ng-container>
      <ng-container ngProjectAs="[slot][footer]">
        <button
            [attr.data-test]="'button-cancel'"
            *ngIf="!hasSecondaryActionBtn"
            type="reset"
            [innerText]="cancelBtnText"
            class="button button"
            data-dismiss="modal-dialog"
            (click)="cancelOnClick()">
        </button>
        <button
            [attr.data-test]="'button-secondaryAction'"
            *ngIf="hasSecondaryActionBtn"
            type="button"
            [innerText]="secondaryBtnText"
            class="button button--secondary"
            data-dismiss="modal-dialog"
            (click)="secondaryActionOnClick()">
        </button>
        <button
            [attr.data-test]="'button-primaryAction'"
            type="button"
            [innerText]="primaryBtnText"
            class="button button--primary"
            data-dismiss="modal-dialog"
            (click)="primaryActionOnClick()">
        </button>
      </ng-container>
    </app-modal>
  </form>
</ng-container>
