import { Component, OnInit } from '@angular/core';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  alert: any;
  alertSubscription$: Subscription;

  constructor(private configurationFacade: ConfigurationFacade, private store: Store<State>) {
  }

  ngOnInit(): void {
    this.alertSubscription$ = this.alertSubscription();
  }

  alertSubscription(): Subscription {
    return this.configurationFacade.getAlert().subscribe(alert => {
      this.alert = alert;
    });
  }

  isAlert(): boolean {
    return this.alert?.message;
  }

  getTitle(): string {
    switch (this.alert.type) {
      case 'error':
        return `alert.error`;
      case 'warning':
        return `alert.warning`;
      default:
        return 'alert.success';
    }
  }

  handleAction(): void {
    if (this.alert?.action) {
      this.store.dispatch({type: this.alert.action, ...this.alert.props});
    }

    this.configurationFacade.clearAlert();
  }
}
