import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArrayUtils } from 'src/app/utils/array.utils';
import {
  cityAndStateSortOptions,
  contractNameSortOptions,
  equipmentLocationSortOptions,
  functionalLocationSortOptions,
  modalitySortOptions,
  productNameSortOptions,
} from '../../../configurations/sort-options';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { AdvancedFilter } from '../../../models/advanced-filter';
import { IModalitiesAndMaterialNumbers } from '../../../models/cpq.models';
import { SortByComponent } from '../../../shared/sort-by/sort-by.component';
import { ICart } from '../../../models/cart.models';
import { EUserRoles } from '../../../configurations/common';
import {
  IEquipmentSelectionHeaders,
  IInstalledBaseSystemDataEquipmentSelection,
} from '../../../models/equipmentselection.models';
import { CustomerFacade } from '../../../facades/customer.facade';
import { EquipmentUtils } from '../../../utils/equipment.utils';


@Component({
  selector: 'app-my-equipment-selection-list',
  templateUrl: './my-equipment-selection-list.component.html',
  styleUrls: ['../../page-my-installed-base/functional-locations-list/functional-locations-list.component.scss'],
})
export class MyEquipmentSelectionListComponent implements OnInit, OnChanges, OnDestroy {
  tabsOpened: string[] = [] as Array<string>;
  headers: IEquipmentSelectionHeaders[] = [
    {
      label: 'my-contracts.functional-location',
      class: 'functional-location-id',
      sortOptions: functionalLocationSortOptions,
      showOnMobile: true,
    },
    {
      label: 'my-contracts.modality',
      class: 'modality',
      sortOptions: modalitySortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.product-name',
      class: 'nameEnUs',
      sortOptions: productNameSortOptions,
      showOnMobile: true,
    },
    {
      label: 'my-contracts.equipment-location',
      class: 'street',
      sortOptions: equipmentLocationSortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.city-state',
      class: 'state',
      sortOptions: cityAndStateSortOptions,
      showOnMobile: false,
    },
    {
      label: 'my-contracts.contract-name',
      class: 'contractName',
      sortOptions: contractNameSortOptions,
      showOnMobile: false,
    },
  ];

  currentSort = {};
  isLoading: boolean = true;
  agreementsToWorkWith: IInstalledBaseSystemDataEquipmentSelection[];
  soldToItems: {
    isInactive: boolean;
    flNumber: string;
    soldToId: string;
    soldToAddress: string;
  }[] = [];
  isBusinessPartnerRole: boolean = false;

  @Input() isCartOperationInProgress: boolean;
  @Input() loadingCartDataInProgress: boolean;
  @Input() loading: boolean;
  @Input() agreements: IInstalledBaseSystemDataEquipmentSelection[];
  @Input() searchValue: string;
  @Input() filters: AdvancedFilter[];
  @Input() userCarts: Array<ICart> = [];
  @Input() currentCartId: string;
  @Input() cartItems = [];
  @Input() productSku: string = '';
  @Input() soldToId: number;
  @Input() modalitiesAndMaterialNumbers: IModalitiesAndMaterialNumbers[];
  @Input() selectableEquipment: boolean = false;
  @Input() isReorderPending: boolean = false;
  @Input() companyRoles: EUserRoles[];

  @ViewChildren(SortByComponent)
  sortByComponent: QueryList<SortByComponent>;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private catalogFacade: CatalogFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    if (this.soldToId) {
      this.checkSoldToId();
    }

    this.selectIsBPRole();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.soldToId) {
      this.agreementsToWorkWith = this.filterAgreements();
    } else {
      this.agreementsToWorkWith = this.agreements;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getAttribute(agreement: IInstalledBaseSystemDataEquipmentSelection, attribute: string, nestedAttribute?: string): string {
    let attributeValue = EquipmentUtils.getAttribute(agreement, attribute, nestedAttribute);
    if (attribute === 'city') {
      const state = EquipmentUtils.getAttribute(agreement, 'state', nestedAttribute)
      return (attributeValue !=="-" && state!=="-")
          ? `${attributeValue}, ${state}`
          : attributeValue !=="-"
            ? attributeValue
          : state !=="-"
            ? state
            : "-"
    } else {
      return attributeValue;
    }
  }

  sort(option: any): void {
    this.agreementsToWorkWith = ArrayUtils.sortByAttribute(
      [...this.agreementsToWorkWith],
      `attributes.${option.value}`,
      option.reversed,
      option?.time,
      option?.numeric,
    );

    this.sortByComponent.forEach(component => component.active = component.isOpen ? option : '');
  }

  filterAgreements(): IInstalledBaseSystemDataEquipmentSelection[] {
    return this.agreements.reduce((acc, next) => {
      const isTheSameFL: boolean = !!this.cartItems.find(item =>
        item.attributes.systemDetails?.siemensEquipmentId === next.attributes?.functionalLocation);
      if (!isTheSameFL) {
        acc.push(next);
      }
      return acc;
    }, []);
  }

  isSoldToItemInArray(siemensEquipmentId: string): boolean {
    return this.soldToId ? this.soldToItems.find(item => item.flNumber === siemensEquipmentId)?.isInactive : false;
  }

  getAdditionalData(siemensEquipmentId: string): any {
    if (this.soldToId) {
      const data = this.soldToItems.find(item => item.flNumber === siemensEquipmentId);
      return {
        soldToId: data?.soldToId,
        soldToAddress: data?.soldToAddress,
      };
    }
    return {soldToId: null, soldToAddress: ''};
  }

  checkSoldToId(): void {
    this.loading = true;
    forkJoin(
      this.agreementsToWorkWith.map(agreement => this.catalogFacade.getInstalledBaseWithSoldTo(agreement.attributes.functionalLocation)),
    ).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: ([...args]) => {
        const includes: Array<any> = args.map(arg => arg.included[0]);
        this.soldToItems = includes.map(include => {
          return ({
            flNumber: include.id,
            isInactive: +include.attributes.customerSoldToId !== this.soldToId,
            soldToId: include.attributes.customerSoldToId,
            soldToAddress: include.attributes.customerSoldToName,
          });
        });
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isBPRole => {
        this.isBusinessPartnerRole = isBPRole;
      })
  }
}
