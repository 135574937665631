import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistFacade } from '../../../facades/wishlist.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IBaseWishlist, IWishlistProductDetails } from '../../../models/wishlist.models';
import { PayloadUtils } from '../../../utils/payload.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { MathUtils } from '../../../utils/math.utils';
import { ICart } from '../../../models/cart.models';
import { IconType } from '../../../models/settings.model';
import { EFeatureToggles, EInstalledBaseTabs, EStoreFeatures, EStoreTypes } from 'src/app/configurations/common';
import { AppUtils } from '../../../utils/app.utils';
import { PriceUtils } from '../../../utils/price.utils';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit, OnDestroy {
  iconType = IconType;
  selectedProducts = [];
  selectAllValue = false;
  editModalActive = false;
  removeModalActive = false;
  addItemToCartModalActive = false;
  showModalSelectEquipment = false;
  currency: string;
  wishlistId: string;
  wishlist: IBaseWishlist;
  wishlistProducts: IWishlistProductDetails[] = [];
  itemsLoaded = false;
  itemsInQueueToCart = [];
  itemsInQueueToRemove = [];
  editInProgress = false;
  wishlistInProgress = [];
  loadingCartDataInProgress: boolean = true;
  isSapP40Enable = false;
  delayTimer: any;
  unsubscribe$ = new Subject();
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  currentCart: ICart;
  currentCartId: string;
  hasCartContract: boolean = false;
  items: any;
  excludeTax: boolean = false;
  priceUtils = PriceUtils;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private wishlistFacade: WishlistFacade,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.currency = PriceUtils.getStoreCurrencyCode();
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.excludeTax = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });

    if (this.isUsStore) {
      this.selectHasCartContract();
    }

    this.route.params.subscribe(params => {
      if (params.id) {
        this.wishlistId = params.id;
        this.getWishlist(this.wishlistId);
      }
    });

    this.selectCurrentCart();
    this.selectLoadingCartDataInProgress();
    this.selectItemsInQueueToCart();
    this.getLastUpdatedProduct();
    this.selectWishlistInProgress();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }

  getWishlist(id: string): void {
    this.wishlistFacade.getWishlist(id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      result => {
        this.wishlist = result.data;
        if (result?.included) {
          this.getItems(result.included);
        }
        this.itemsLoaded = true;
      },
    );
  }

  getLastUpdatedProduct(): void {
    this.wishlistFacade.selectLastUpdatedProduct().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      updatedProduct => {
        if (updatedProduct) {
          this.wishlistProducts = this.wishlistProducts.reduce((prev, curr) => {
            const product = {...curr};
            if (curr.sku === updatedProduct.sku) {
              product.qty = updatedProduct.qty;
            }
            prev.push(product);
            return prev;
          }, []);
        }
      },
    );
  }

  getItems(included: any): void {
    const items = included.filter(item => item.type === 'shopping-list-items');

    this.wishlistProducts = items.reduce((acc, next) => {
      acc.push({
        ...PayloadUtils.getConcreteProductDetails(included, next.attributes.sku),
        qty: next.attributes.quantity,
        shoppingListItemId: next.id,
      });
      return acc;
    }, []);
  }

  getProductsQty(): number {
    return this.wishlistProducts.reduce((prev, curr) => {
      return prev + curr.qty;
    }, 0);
  }

  selectProduct(product: any): void {
    const index = this.selectedProducts.indexOf(product.sku);
    if (index > -1) {
      this.selectedProducts.splice(index, 1);
    } else {
      this.selectedProducts.push(product.sku);
    }

    if (this.selectedProducts.length === 0) {
      this.selectAllValue = false;
    }
  }

  selectAll(event): void {
    if (event.target.checked) {
      this.selectedProducts = this.wishlistProducts.map(product => product.sku);
    } else {
      this.selectedProducts = [];
    }
  }

  updateProductQty(sku: string, qty: any): void {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      const updatedQty = parseInt(qty, 10);
      if (!MathUtils.checkIfNumeric(updatedQty) || qty === 0) {
        return;
      }
      this.wishlistFacade.updateItem(this.wishlistId, this.getShoppingListId(sku), sku, updatedQty);
    }, 500);
  }

  getTotalPrice(): number {
    return this.wishlistProducts.reduce(
      (prev, curr) =>
        prev + (this.priceUtils.getPriceValue(curr.defaultPrice)) * curr.qty,
      0,
    );
  }

  isAllProductsSelected(): boolean {
    return this.selectedProducts.length === this.wishlistProducts.length;
  }

  wishlistEdited(event: any): void {
    if (event !== 'error') {
      this.wishlist = {
        ...this.wishlist,
        attributes: {
          ...event.attributes,
        },
      };
    }
    this.editInProgress = false;
  }

  wishlistRemoved(): void {
    this.wishlistFacade.deleteList(this.wishlistId);
    this.router.navigate(['/wishlists']).then();
  }

  selectEquipment(): void {
    this.router.navigate(['/my-installed-base'], {
      queryParams: {
        tab: EInstalledBaseTabs.EQUIPMENT,
      },
    }).then();
  }

  selectCurrentCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      if (cart) {
        this.currentCart = cart;
        this.currentCartId = cart.id;
      }
    });
  }

  selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
        this.loadingCartDataInProgress = loadingCartDataInProgress;
      },
    );
  }

  selectWishlistInProgress(): void {
    this.wishlistFacade.selectListInProgress()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.wishlistInProgress = state;
      });
  }

  selectHasCartContract(): void {
    this.marketingFacade.selectHasCartContract().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
        this.hasCartContract = value;
      },
    );
  }

  isItemInProgress(sku: string): boolean {
    return this.itemsInQueueToCart.includes(sku)
      || this.itemsInQueueToRemove.includes(sku)
      || this.wishlistInProgress.find(list => list.sku === sku);
  }

  addProductToCart(item: any): void {
    if (this.isItemInProgress(item.sku) || this.loadingCartDataInProgress) {
      return;
    }

    const quantity = this.wishlistProducts.find(product => product.sku === item.sku).qty;
    const systemDetails = this.currentCart.attributes?.systemDetails;

    if ((this.isUsStore || this.isCaStore) && !systemDetails?.siemensEquipmentId) {
      this.showModalSelectEquipment = true;
      return;
    }

    if (this.hasCartContract) {
      this.items = [{
        data: item,
        isConcrete: true,
        quantity: quantity,
      }];
      this.addItemToCartModalActive = true;
      return;
    }

    this.addToCart(item, quantity);
  }

  private addToCart(item: any, quantity: number): void {
    this.analyticsService.setProducts(item);
    this.analyticsService.trackCart('cart.add');
    this.marketingFacade.addProductToCart({sku: item.sku, name: item.name}, true, quantity);
  }

  removeItemFromWishlist(sku: string): void {
    if (this.isItemInProgress(sku)) {
      return;
    }
    this.itemsInQueueToRemove.push(sku);
    this.removeItem(sku);
  }

  removeAllSelectedItems(): void {
    if (this.selectedProducts.length > 0) {
      this.selectedProducts.forEach(sku => {
        this.itemsInQueueToRemove.push(sku);
        this.removeItem(sku);
      });
    }
  }

  addAllSelectedToCart(): void {
    if (this.selectedProducts.length === 0 || this.loadingCartDataInProgress) {
      return;
    }
    const systemDetails = this.currentCart.attributes?.systemDetails;

    if ((this.isUsStore || this.isCaStore) && !systemDetails?.siemensEquipmentId) {
      this.showModalSelectEquipment = true;
      return;
    }

    if (this.hasCartContract) {
      this.items = [];
      this.selectedProducts.forEach(sku => {
        const item = this.wishlistProducts.find(product => product.sku === sku);
        this.items.push({
          data: item,
          isConcrete: true,
          quantity: item.qty,
        });
      });
      this.addItemToCartModalActive = true;
      return;
    }

    this.selectedProducts.forEach(sku => {
      const item = this.wishlistProducts.find(product => product.sku === sku);
      this.addToCart(item, item.qty);
    });
  }

  isAddToCartDisabled(): boolean {
    return this.loadingCartDataInProgress || (!this.isSapP40Enable && (this.isUsStore || this.isCaStore));
  }

  getShoppingListId(sku: string): string {
    return this.wishlistProducts.find(product => product.sku === sku).shoppingListItemId;
  }

  private removeItem(sku: string): void {
    this.wishlistFacade.removeItemFromWishlist(this.wishlistId, this.getShoppingListId(sku)).then(
      () => {
        this.wishlistProducts = this.wishlistProducts.filter(product => product.sku !== sku);
      },
    ).catch(
      err => {
        const message = err?.error.errors?.[0]?.detail ? err.error.errors[0].detail : err.mesage;
        this.configurationFacade.setAlert({type: 'error', message});
      },
    );
  }

  edit(): void {
    if (!this.editInProgress) {
      this.editModalActive = true;
    }
  }

  redirectToProductLists(): void {
    this.router.navigate(['/catalog']).then();
  }

}
