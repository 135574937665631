import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent {
  @Input() max = 100;
  @Input() current = 0;
  @Input() message: string;
  @Input() showPercentProgress = true;

  getProgressInPercent(): number {
    return Math.round((100 * this.current) / this.max);
  }
}
