<form [formGroup]="wrongAddressForm" (ngSubmit)="reportAddress()" *ngIf="showModal">
  <app-modal
      [attr.data-test]="'button-reportWrongAddress'"
      [open]="showModal"
      [id]="'wrongAddressModal'"
      title="{{ 'checkout-delivery-details.report-wrong-address' | translate }}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="loader__overlay" *ngIf="loading">
        <div class="loader__content">
          <div class="loading-animation"></div>
        </div>
      </div>
      <div class="row">
        <app-select-box
            class="col-12"
            [form]="wrongAddressForm"
            [inputName]="'addressType'"
            [modelValue]="wrongAddressForm.value.addressType"
            [options]="addressTypes"
            label="{{ 'addresses.type-of-address' | translate }}"
            (selected)="setAddressTypeFormValue($event)">
        </app-select-box>
      </div>
      <div class="row">
        <app-select-box
            class="col-12"
            [form]="wrongAddressForm"
            [modelValue]="wrongAddressForm.value.address"
            [options]="addressesByType"
            [inputName]="'address'"
            label="{{ addressSelectBoxPlaceholder | translate }}"
            [isRequired]="true"
            [loading]="addressesLoading"
            [disabled]="addressesLoading || !wrongAddressForm.value.addressType"
            (selected)="setFormValue($event)">
        </app-select-box>
      </div>
      <div class="row">
        <app-textarea
            class="col-12"
            [form]="wrongAddressForm"
            [modelValue]="wrongAddressForm.value.comment"
            [rows]="3"
            [cssStyles]="{wrapper: 'text-area-comment'}"
            [inputName]="'comment'"
            [maxLength]="maxLengthComment"
            label="{{ 'checkout-delivery-details.comment' | translate }}"
            [isRequired]="true"
            trim
            trimWhitespaces
            (areaFilled)="setFormValue($event)">
        </app-textarea>
        <div class="form-alerts">
          <ng-container *ngIf="wrongAddressForm.value.comment">
            <small class="textfield__hint">
              {{ wrongAddressForm.value.comment.length }}/{{ maxLengthComment }}
            </small>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button type="reset"
          class="button button"
          data-dismiss="modal-dialog"
          (click)="resetForm()">
        {{ 'checkout-delivery-details.cancel' | translate }}
      </button>
      <button type="submit"
          class="button button--primary"
          data-dismiss="modal-dialog"
          [disabled]="!formIsValid()"
          (click)="loading = true">
        {{ 'checkout-delivery-details.send-wrong-address' | translate }}
      </button>
    </ng-container>
  </app-modal>
</form>
