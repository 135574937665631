import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IOrder, IOrderItem, IOrderResponse, IOrderResponseWithMessages } from '../../../models/order.models';
import { OrdersFacade } from '../../../facades/orders.facade';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ArrayUtils } from '../../../utils/array.utils';
import { Subject } from 'rxjs';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { IRfqBaseInfo } from '../../../models/rfq.models';
import { takeUntil } from 'rxjs/operators';
import { AppUtils } from '../../../utils/app.utils';
import { environment } from '../../../../environments/environment';
import { IStore } from '../../../models/settings.model';

@Component({
  selector: 'app-my-order-hist-detail',
  templateUrl: './my-order-hist-detail.component.html',
  styleUrls: ['./my-order-hist-detail.component.scss'],
})
export class MyOrderHistDetailComponent implements OnInit, OnChanges, OnDestroy {
  orderDetails: IOrder | IRfqBaseInfo;
  cartDetails: any;
  cartItems = [];
  itemsLoaded: boolean = false;
  itemsInQueueToCart: Array<string>;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() order;
  @Input() isOpen: boolean;
  @Input() type: string = 'order';

  constructor(
    private ordersFacade: OrdersFacade,
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectItemsInQueueToCart();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen.currentValue && !this.itemsLoaded) {
      if (this.order.type === 'orders') {
        this.retrieveOrderDetails(this.order.id);
      } else if (this.order.type === 'quote-requests') {
        this.retrieveRequestQuoteDetails(this.order.id);
      } else {
        this.retrieveCartOrderDetails(this.order.id);
      }
    }
  }

  combineSameItems(order: IOrder): IOrderItem[] {
    const items: Object[] = Array.from(Object.create(order.attributes.items)) as object[];
    return ArrayUtils.uniqueObjects(items, 'sku');
  }

  retrieveCartOrderDetails(id: string): void {
    this.ordersFacade.getCartForApprovalData(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
      this.itemsLoaded = true;
        if (data) {
          this.cartDetails = data;
          this.cartDetails.data.relationships.items.data.forEach((item) => {
            if (this.cartItems.indexOf(item.id) === -1) {
              this.cartItems.push(item.id);
            }
          });
          this.orderDetails = this.cartDetails.data;
        }
      });
  }

  retrieveOrderDetails(id: string): void {
    this.ordersFacade.getOrderById(id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((data: IOrderResponse|IOrderResponseWithMessages) => {
        this.itemsLoaded = true;
        if (data) {
          this.orderDetails = data.data as IOrder;
          this.orderDetails.attributes.items = this.combineSameItems(data.data as IOrder);
        }
    });
  }

  retrieveRequestQuoteDetails(id: string): void {
    this.checkoutFacade.selectQuoteDetails(id).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(data => {
      this.itemsLoaded = true;
      if (data) {
        this.orderDetails = data.data as IRfqBaseInfo;
      }
    });
  }

  addProductToCart(product: string, productName: string): void {
    this.marketingFacade.addProductToCart({sku: product, name: productName}, true);
  }

  getItemPrice(id: string): number {
    return this.cartDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes.calculations.unitPrice;
  }

  getItemQuantity(id: string): number {
    return this.cartDetails.included?.filter(tmp => tmp.type === 'items' && tmp.id === id)[0].attributes.quantity;
  }

  getItemName(id: string): string {
    return this.cartDetails.included?.filter(tmp => tmp.type === 'concrete-products' && tmp.id === id)[0].attributes.name;
  }

  getItemPicture(id: string): string {
    return this.cartDetails.included?.filter(tmp => tmp.type === 'concrete-product-image-sets' && tmp.id === id)[0]
      .attributes.imageSets.find(
        img => img.name === 'default')
      .images.find(smallImage => smallImage.externalUrlSmall)
      .externalUrlSmall;
  }

  renderEmptyAddressField(field: string): string {
    return field === 'empty' ? '' : field;
  }

  isAddressFieldEmpty(field: string): boolean {
    return field === 'empty' || field === '' || !field;
  }

  selectItemsInQueueToCart(){
    this.marketingFacade.selectItemsInQueueToCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  isItemInProgress(sku): boolean {
    return this.itemsInQueueToCart.includes(sku);
  }

  buyButtonLabel(): string {
    return this.type === 'order' ? 'orders-page.buy-again' : 'orders-page.request-again';
  }

  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
