import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IInstallBaseProducts,
  IInstalledBaseSystems,
  IInstalledBaseSystemsFilter,
} from '../models/installedbase.models';
import { GlueConfigurationService } from './glue-configuration.service';
import { EGlueResource } from '../configurations/common';
import { GlueUtils } from '../utils/glue.utils';

@Injectable({
  providedIn: 'root',
})
export class InstallBaseService {

  private installBaseEndpoint = `/${EGlueResource.INSTALL_BASE}`;
  private installBaseSystemsEndpoint = `/${EGlueResource.INSTALL_BASE_SYSTEMS}`;
  private url = this.glueConfiguration.getEndpointUrl() + this.installBaseEndpoint;
  private installedBaseSystemUrl = `${this.glueConfiguration.getEndpointUrl()}${this.installBaseSystemsEndpoint}`;

  constructor(private httpClient: HttpClient,
              private glueConfiguration: GlueConfigurationService) {
  }

  private static handleError(err): Observable<any> {
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      return throwError(err);
    }
    return throwError(errorMessage);
  }

  getInstalledBaseProducts(): Observable<IInstallBaseProducts> {
    return this.httpClient.get<IInstallBaseProducts>(this.url)
      .pipe(
        catchError(error => InstallBaseService.handleError(error)),
      );
  }

  getInstalledBaseSystems(
    installedBaseSystemFilter: IInstalledBaseSystemsFilter = null,
    includes: EGlueResource[]                              = [],
  ): Observable<IInstalledBaseSystems> {
    let params = new HttpParams();
    installedBaseSystemFilter = !!installedBaseSystemFilter ? installedBaseSystemFilter : {};
    for (const key in installedBaseSystemFilter) {
      params = params.set(key, installedBaseSystemFilter[key]);
    }
    params = GlueUtils.addIncludeParam(params, includes);

    return this.httpClient.get<IInstalledBaseSystems>(this.installedBaseSystemUrl, {params})
      .pipe(
        catchError(err => InstallBaseService.handleError(err)),
      );
  }
}
