import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';
import { IconType } from '../../models/settings.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  defaultCssStyles: object = {
    wrapper: '',
  };

  @Input() cssStyles: any;
  @Input() type?: IconType;
  @Input() title: any;
  @Input() id: any;
  @Input() open = false;
  @Input() showCrossCloseBtn = true;
  @Output() crossClick = new EventEmitter<any>();
  @Output() outsideClick = new EventEmitter<any>();
  @ViewChild('modal') modal;

  ngOnInit(): void {
    this.cssStyles = {...this.defaultCssStyles, ...this.cssStyles};
  }

  hideModal(): void {
    this.crossClick.emit();
  }

  @HostListener('mousedown', ['$event'])
  onClick(event): void {
    if (!this.modal.nativeElement.contains(event.target)) {
      if (!this.open) {
        return;
      }
      this.outsideClick.emit();
    }
  }
}
