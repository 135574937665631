import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { Observable, of, map } from 'rxjs';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { GlueConfigurationService } from '../services/glue-configuration.service';
@Injectable({
  providedIn: 'root',
})
class AuthServiceCd {
  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,) {
  }

  glueUrl = this.glueConfiguration.getEndpointUrl();

  AUTH0_COOKIE = `auth0.clickdummy.is.authenticated`;

  isAuthenticated$ = of(this.isAuthenticated());

  handleRedirectCallback$ = () => {
    return Promise.resolve({
      appState: {},
    });
  }

  getTokenSilently$(options?): Promise<any> {
    return of(null).toPromise();
  }

  getUser$(options?): Observable<any> {
    return this.http.get<any>(this.glueUrl + '/items/customer').pipe(
      map((response: any) => {
        return this.generateUserData(response.data);
      })
    );
  }

  generateUserData(data) {
    return {
      "https://login-shs.siemens-healthineers.com/gid": "XHZ001RS",
      "https://login-shs.siemens-healthineers.com/aal": "1",
      "https://login-shs.siemens-healthineers.com/ial": "ial1",
      "https://login-shs.siemens-healthineers.com/SsoProvider": "Siemens Healthineers",
      "https://login-shs.siemens-healthineers.com/IdProvider": "Healthineers Auth0",
      "https://login-shs.siemens-healthineers.com/email": data.email,
      "https://login-shs.siemens-healthineers.com/name": `${data.first_name} ${data.last_name}`,
      "https://login-shs.siemens-healthineers.com/given_name": data.first_name,
      "https://login-shs.siemens-healthineers.com/family_name": data.last_name,
      "https://login-shs.siemens-healthineers.com/country": "DE",
      "https://login-shs.siemens-healthineers.com/preferredLanguage": "en",
      "https://login-shs.siemens-healthineers.com/tenant_connection": "main-tenant-oidc",
      "https://login-shs.siemens-healthineers.com/email_verified": true,
      "given_name": data.first_name,
      "family_name": data.last_name,
      "nickname": "",
      "name": `${data.first_name} ${data.last_name}`,
      "picture": `${this.glueUrl}/assets/${data.picture}`,
      "updated_at": "2023-09-04T11:35:36.349Z",
      "email": data.email,
      "email_verified": true,
      "sub": "oauth2|main-tenant-oidc|XHZ001RS"
    }
  }

  isAuthenticated() {
    return LocalStorageUtils.getKeyValue('mockUser') ? true : false;
  }

  login(redirectPath: string = '/', queryParameters = {}): void {
    console.log('login', redirectPath, queryParameters)
    this.http.get<any>(this.glueUrl + '/items/customer').subscribe(
      (response: any) => {
        console.log("response", response);
        LocalStorageUtils.clearLocalStorage(['lang', 'storeId', 'previousPage']);
        LocalStorageUtils.setKeyValue('mockUser', JSON.stringify(this.generateUserData(response.data)));
        LocalStorageUtils.clearKey('selected-equipment');
        LocalStorageUtils.clearKey('relationships');
        LocalStorageUtils.clearKey('items');
        LocalStorageUtils.clearKey('totals');
        window.location.reload();
      }
    );
  }

  logout(skipRedirect: boolean): void {
    LocalStorageUtils.clearLocalStorage(['lang', 'storeId', 'previousPage']);
    LocalStorageUtils.clearKey('mockUser');
    LocalStorageUtils.clearKey('selected-equipment');
    LocalStorageUtils.clearKey('relationships');
    LocalStorageUtils.clearKey('items');
    LocalStorageUtils.clearKey('totals');
    window.location.reload();
  }

  getAuthenticatedCookie(): boolean {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${this.AUTH0_COOKIE}=`) && item.includes(`${this.AUTH0_COOKIE}=true`));
  }
}

export const AuthServiceCdProvider = {
  provide: AuthService,
  useClass: AuthServiceCd
};
