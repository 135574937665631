<div role="alertdialog"
     aria-labelledby="alert1Title"
     aria-describedby="alert1Text"
     class="alert main-alert is-open"
     *ngIf="alertDialogActive && !alertClosedByUser && (isCustomerDataLoaded || (userLoggedIn$ | async) === false)">
  <div class="alert__container">
    <button type="button"
            class="alert__close"
            data-dismiss="alert"
            aria-label="Close alert"
            (click)="toggleAlertDialog()">
      <i aria-hidden="true" class="icon-close"></i>
    </button>
    <ng-container
        *ngIf="((userLoggedIn$ | async) && isGuest && isAuthenticatedGuest()) || isNotLoggedMessage(); else loggedMessage">
      <p class="alert__text font-size-small">
        <a (click)="register()"
        >{{ 'app-bar.register' | translate }}</a><span *ngIf="(userLoggedIn$ | async) === false"> {{ 'app-bar.tooltip-conj' | translate }} </span>
        <a (click)="login()" *ngIf="(userLoggedIn$ | async) === false">{{ 'app-bar.login' | translate }}</a>
        {{ 'app-bar.tooltip-text' | translate }}
      </p>
    </ng-container>
    <ng-template #loggedMessage>
      <div class="alert__body wysiwyg">
        <p class="alert__text font-size-small">
          {{alertText}}
        </p>
      </div>
    </ng-template>
  </div>
</div>
