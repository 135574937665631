import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ICart } from 'src/app/models/cart.models';
import { DataSharedFacade } from 'src/app/facades/data-shared.facade';
import { IContractParams } from 'src/app/models/catalog.models';
import { ITotals } from 'src/app/models/common.models';
import { EInstalledBaseTabs } from '../../../../configurations/common';

@Component({
  selector: 'app-current-quote',
  templateUrl: './current-quote.component.html',
  styleUrls: ['./current-quote.component.scss'],
})
export class CurrentQuoteComponent {
  @Input() loadingQuote: boolean;
  @Input() currency: string;
  @Input() totals: ITotals;
  @Input() currentCart: ICart;
  @Input() currentCartItems: Array<any>;
  @Input() hasNickname: boolean;
  @Input() validUntilDate: string;

  @Output() showAddNicknameModal = new EventEmitter<boolean>();
  @Output() showDeleteQuoteModal = new EventEmitter<boolean>();
  @Output() showRemoveConfigurationModal = new EventEmitter<boolean>();
  @Output() removeQuoteItem = new EventEmitter<{sku: string, configId: string}>();

  EInstalledBaseTabs = EInstalledBaseTabs;

  constructor(
    private router: Router,
    private dataSharedFacade: DataSharedFacade,
  ) {
  }

  showAddNicknameModalEmit(): void {
    this.showAddNicknameModal.emit(true);
  }

  showDeleteQuoteModalEmit(): void {
    this.showDeleteQuoteModal.emit(true);
  }

  removeItemOrDeleteQuoteEmit(sku: string, configId: string): void {
    if (this.currentCartItems.length > 1) {
      this.showRemoveConfigurationModal.emit(true);
      this.removeQuoteItem.emit({sku, configId});
    } else {
      this.showDeleteQuoteModal.emit(true);
    }
  }

  isQuoteEmpty(): boolean {
    return this.currentCartItems?.length === 0 || !this.currentCart.attributes?.totalItemsQuantity;
  }

  redirectWithParam(path: string, item?: any): void {
    const cartItem = item || this.currentCartItems[0];
    const serviceParams = this.buildServiceParams(cartItem);

    this.dataSharedFacade.setCartId(this.currentCart.id);
    this.router.navigate([path], {queryParams: serviceParams}).then();
  }

  getCurrentCartName(): string {
    return this.hasNickname ? this.currentCart.attributes.name : this.currentCart.id;
  }

  getLabelForAddNicknameButton(): string {
    return this.hasNickname ? 'multi-cart.edit-nickname' : 'multi-cart.add-nickname';
  }

  private buildServiceParams(cartItem: any): IContractParams {
    return {
      'fl-number': cartItem.attributes.systemDetails.siemensEquipmentId,
      'rel-product-sysivk': cartItem.attributes.systemDetails.materialNumber,
      selectedService: cartItem.attributes.abstractSku,
      configId: cartItem.attributes.cpqConfigId,
    };
  }
}
