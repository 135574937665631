<div class="accordion orders__table">
  <div class="accordion__title orders-table__header">
    <ng-container *ngIf="roleAccess; else buyerBlock">
      <span class="width-15 order-id__label">{{ 'orders-page.order' | translate }}</span>
      <div class="width-20 text-align-left">{{ 'orders-page.ordered-by' | translate }}</div>
      <div class="width-15">{{ 'orders-page.date' | translate }}</div>
      <span class="width-20 to-approve__label">{{ 'orders-page.to-approve' | translate }}</span>
      <div class="width-15 text-align-right">{{ 'orders-page.total' | translate }}</div>
      <div class="width-15 text-align-center">{{ 'orders-page.quantity' | translate }}</div>
    </ng-container>
    <ng-template #buyerBlock>
      <span class="width-15 order-id__label">{{ 'orders-page.order' | translate }}</span>
      <div class="width-25 text-align-left">{{ 'orders-page.ordered-by' | translate }}</div>
      <div class="width-30">{{ 'orders-page.date' | translate }}</div>
      <div class="width-15 text-align-right">{{ 'orders-page.total' | translate }}</div>
      <div class="width-15 text-align-center">{{ 'orders-page.quantity' | translate }}</div>
    </ng-template>
  </div>
  <div class="order-history__table">
    <div class="table-items">
      <app-accordion *ngFor="let cart of orders" [manualOpen]="tabsOpened.includes(cart.id)">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__title icon-arrow-down" [attr.data-test]="'desc-pendingOrder-' + cart.id" (click)="toggleTab(cart.id)">
            <span class="order-info width-15 order-id">
              <span class="header__label" [attr.data-test]="'label-orderId'">{{ 'orders-page.order' | translate }}
                :</span>
              <span [attr.data-test]="'desc-orderId'">#{{ cart.id.substr(0, cart.id.indexOf('-')) }}</span>
            </span>
            <span class="order-info user-identifier__avatar width-20">
              <span class="header__label"
                    [attr.data-test]="'label-orderedBy'">{{ 'orders-page.ordered-by' | translate }}:</span>
              <span [attr.data-test]="'desc-orderedBy'">
                {{ getUserFullName(cart.attributes.customer.firstName, cart.attributes.customer.lastName) }}
              </span>
            </span>
            <span class="order-info order-date" [ngClass]="roleAccess ? 'width-15' : 'width-35'">
              <span class="header__label" [attr.data-test]="'label-orderDate'">{{ 'orders-page.date' | translate }}
                :</span>
              <span [attr.data-test]="'desc-orderDate'">
                {{ (cart.attributes.orderedAt !== null ? cart.attributes.orderedAt : cart.attributes.createdAt) | formatDate }}
              </span>
            </span>
            <span class="order-info width-20 order-to-approve" *ngIf="roleAccess">
              <small class="user-name">
                <span class="header__label"
                      [attr.data-test]="'label-approver'">{{ 'orders-page.to-approve' | translate }}:</span>
                <span [attr.data-test]="'desc-approver'">
                  {{ getUserFullName(cart.attributes.approverDetails.firstName, cart.attributes.approverDetails.lastName) }}
                </span>
              </small>
            </span>
            <span class="order-info width-15 price">
              <span class="header__label" [attr.data-test]="'label-totalPrice'">{{ 'orders-page.total' | translate }}
                :</span>
              <span [attr.data-test]="'desc-totalPrice'">
                {{ cart.attributes.totals.grandTotal | currency:cart.attributes.currency }}
                <span *ngIf="isExcludeTaxActive()" class="tax-disclaimer">({{ 'app-product-page.incl_gst' | translate }}
                  )</span>
              </span>
            </span>
            <span class="order-info width-15 quantity">
              <span class="header__label" [attr.data-test]="'label-quantity'">{{ 'orders-page.quantity' | translate }}
                :</span>
              <ng-container *ngIf="cart.attributes.totalItemsQuantity > 1">
                <span [attr.data-test]="'desc-quantity'">
                  {{ 'orders-page.item-plural' | translate:{number: cart.attributes.totalItemsQuantity} }}
                </span>
              </ng-container>
              <ng-container *ngIf="cart.attributes.totalItemsQuantity === 1">
                <span [attr.data-test]="'desc-quantity'">
                  {{  'orders-page.item-singular' | translate: {number: cart.attributes.totalItemsQuantity} }}
                </span>
              </ng-container>
            </span>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content">
            <app-my-order-detail
                [cartDetails]="cart"
                [allowApprove]="roleAccess"
                [isOpen]="tabsOpened.includes(cart.id)"
                [pendingOrdersLoading]="loading"
                (updated)="refreshOrders()">
            </app-my-order-detail>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
