<form [formGroup]="form" (ngSubmit)="createWishlist()" class="add-new-wishlist__form">
  <app-modal
      [open]="showModal"
      [id]="'wishlistsAddNew'"
      title="{{'wishlists.add-new-title' | translate}}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="row">
        <div class="checkout__field col-12">
          <app-textfield
              class="checkout__textfield col-12"
              [form]="form"
              [modelValue]="form.value.name"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-name'}"
              [inputName]="'name'"
              label="{{ 'wishlists.name' | translate }}"
              [isRequired]="true"
              trim
              trimWhitespaces>
          </app-textfield>
          <div class="checkout__alerts">
            <small class="textfield__hint" *ngIf="validateField('name')"
                   [attr.data-test]="'hint-modalMaxCharacters'">
              {{ 'checkout-delivery-details-modal.max-characters' | translate: {charNumber: maxNameLength} }}
            </small>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
          [attr.data-test]="'button-submitForm'"
          type="submit"
          class="button button--primary"
          data-dismiss="modal-dialog"
          [disabled]="!formIsValid() || inProgress">
        {{'wishlists.add-new' | translate}}
      </button>
    </ng-container>
  </app-modal>
</form>
