<div class="cart-rules">
    <div class="display-flex justify-content-between" *ngFor="let cartRule of cartRules">
        <span>
            {{cartRule.attributes.displayName}}
        </span>
        <span class="price-2">
            -{{ cartRule.attributes.amount | currency: currency }}
        </span>
    </div>
    <hr style="border: 1px solid #3B3B3B; margin-top: 0.3rem; margin-bottom: 0.4rem">
    <div class="display-flex justify-content-end">
        <strong>
            {{ priceToPay | currency: currency }}
        </strong>
    </div>
</div>
