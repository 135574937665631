import { environment } from '../../environments/environment';
import { EStoreTypes, EUserRoles } from '../configurations/common';
import { INavigationNodes, IStore } from '../models/settings.model';
import { LocalStorageUtils } from './localStorage.utils';

export class AppUtils {

  static jwtGidElementName = 'https://login-shs.siemens-healthineers.com/gid';

  static getStoreIdFromLocalStorage(): string {
    return LocalStorageUtils.getKeyValue('storeId');
  }

  static getCurrentStore(): IStore {
    const currentStoreId = this.getStoreIdFromLocalStorage();
    const defaultStore = this.getDefaultStore();
    if (!currentStoreId) {
      return defaultStore;
    }
    return environment.stores.find(store => store.storeId === currentStoreId) as IStore;
  }

  static getCurrentMarket(): string {
    const currentStore = this.getCurrentStore();
    return this.getMarketByStoreId(currentStore.storeId);
  }

  static getDefaultStore(): IStore {
    const defaultStore = environment.defaultStore;
    return environment.stores.find(store => store.storeId === defaultStore);
  }

  static getMarketByStoreId(storeId: string): string {
    return environment.stores.find(store => store.storeId === storeId).marketCode;
  }

  static isStoreActive(store: EStoreTypes): boolean {
    return AppUtils.getCurrentStore().storeId === store;
  }

  static isSapStore(): boolean {
    switch (AppUtils.getCurrentStore().storeId) {
      case 'US':
      case 'CA':
        return true;
      default:
        return false;
    }
  }

  static filterNodesByRole(navigationNodes: INavigationNodes[], companyRoles: EUserRoles[]): INavigationNodes[] {
    return navigationNodes.filter(node =>
      !node.whitelistedRoles || node.whitelistedRoles?.some(role => companyRoles.includes(role))
    );
  }

  static isProductionEnv(): boolean {
    return environment.production;
  }

  static getEnvName(): string {
    return environment.name;
  }
}
