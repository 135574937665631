import { Pipe, PipeTransform } from '@angular/core';

export const KEY_EDIT_CONFIGURATION = 'cart-configuration-status.edit-configuration';
export const KEY_CONTINUE_CONFIGURATION = 'cart-configuration-status.continue-configuration';

@Pipe({name: 'editConfigurationLabel'})
export class EditConfigurationLabelPipe implements PipeTransform {
  transform(contractStartDate: string): string {
    return contractStartDate ? KEY_EDIT_CONFIGURATION : KEY_CONTINUE_CONFIGURATION;
  }
}
