<div class="row contact-us">
  <div class="col-12 contact-us__header">
    <h1 class="header-title" [attr.data-test]="'title-contactUs'">{{ 'contact-us.page-title' | translate }}</h1>
    <div class="header-subtitle">
      <p>{{ 'contact-us.general-message' | translate }}</p>
      <p>{{ 'contact-us.contact-you-general' | translate }}</p>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <div class="row margin-bottom-1">
          <div class="col-12">
            <span>{{ 'contact-us.topic' | translate }}</span>
            <app-dropdown [dropDownName]="'topic-options'">
              <ng-container ngProjectAs="[slot][toggle]">
                <div class="dropdown">
                  <h6 class="dropdown--name">{{ topicOptionLabel(selectedTopic) | translate }}</h6>
                  <h6 class="icon-arrow-down"></h6>
                </div>
              </ng-container>
              <ng-container ngProjectAs="[slot][panel]">
                <ul class="menu topic-list">
                  <li class="topic-option" *ngFor="let topic of topicOptions">
                    <span class="menu__item"
                          [ngClass]="[isSelectedTopic(topic) ? 'is-selected' : '']"
                          (click)="selectTopicOption(topic)">
                      {{ topicOptionLabel(topic) | translate }}
                    </span>
                  </li>
                </ul>
              </ng-container>
            </app-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>{{ 'contact-us.additional-information-general' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-textarea
                label="{{ 'contact-us.message-input' | translate }}"
                [rows]="3"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.message"
                [cssStyles]="{ wrapper: 'text-area-message' }"
                [inputName]="'message'"
                [maxLength]="maxLengthMessage"
                [isRequired]="true"
                trim
                trimWhitespaces
                (areaFilled)="setContactUsFormValue($event)">
            </app-textarea>
            <div class="contact-us-form__alerts">
              <ng-container *ngIf="contactUsForm.value.message">
                <small class="textfield__hint">
                  {{ contactUsForm.value.message.length }}/{{ maxLengthMessage }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                label="{{ 'contact-us.select-first-name' | translate }}"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.firstName"
                [cssStyles]="{ wrapper: 'textfield-with-prefix textfield-firstname' }"
                [inputName]="'firstName'"
                [isRequired]="true"
                [readOnly]="true"
                [ngClass]="{ 'is-invalid': contactUsForm.get('firstName').errors && contactUsForm.get('firstName').touched }"
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
          </div>
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                label="{{ 'contact-us.select-last-name' | translate }}"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.lastName"
                [cssStyles]="{ wrapper: 'textfield-with-prefix textfield-lastname' }"
                [inputName]="'lastName'"
                [isRequired]="true"
                [readOnly]="true"
                [ngClass]="{ 'is-invalid': contactUsForm.get('lastName').errors && contactUsForm.get('lastName').touched }"
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
          </div>
        </div>
        <div class="row">
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                label="{{ 'contact-us.input-email' | translate }}"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.email"
                [cssStyles]="{ wrapper: 'email-with-prefix email-telephone' }"
                [inputType]="'email'"
                [inputName]="'email'"
                [isRequired]="true"
                [readOnly]="true"
                (fieldFilled)="setContactUsFormValue($event)"
                [dataTestClass]="'input-contactUsEmail'">
            </app-textfield>
          </div>
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                label="{{ 'contact-us.select-phone-number' | translate }}"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.phone"
                [cssStyles]="{ wrapper: 'textfield-with-prefix textfield-telephone' }"
                [inputName]="'phone'"
                [isRequired]="false"
                trim
                trimWhitespaces
                [multiCharToTrim]="['-']"
                (fieldFilled)="setContactUsFormValue($event)"
                [dataTestClass]="'input-contactUsPhoneNumber'">
            </app-textfield>
            <div class="contact-us-form__alerts">
              <ng-container
                  *ngIf="contactUsForm.get('phone').touched &&
                         contactUsForm.get('phone').hasError('maxlength')">
                <small class="textfield__hint">
                  {{ 'contact-us.max-characters' | translate: {charNumber: maxLengthPhone} }}
                </small>
              </ng-container>
              <ng-container *ngIf="contactUsForm.get('phone').hasError('pattern')">
                <small class="textfield__hint">
                  {{ 'contact-us.only-numbers' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12" [formGroup]="contactUsForm">
            <input
                type="checkbox"
                id="receive-mails"
                name="receive-mails"
                class="checkbox"
                [required]="false"
                formControlName="receiveMails">
            <label for="receive-mails">
              {{ 'contact-us.checkbox-receive-mails' | translate }}
            </label>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12" [formGroup]="contactUsForm">
            <input
                type="checkbox"
                id="personal-information-consent"
                name="personal-information-consent"
                class="checkbox"
                [required]="false"
                formControlName="personalInformationConsent">
            <ng-container *ngIf="isJpStore; else otherStores">
              <label class="personal-information-consent-label"
                     for="personal-information-consent"
                     [innerHTML]="'contact-us.checkbox-personal-info-consent' | translate">
              </label>
            </ng-container>
            <ng-template #otherStores>
              <label for="personal-information-consent">
                {{ 'contact-us.checkbox-personal-info-consent' | translate }}
                <span class="contact-email">
                <a href="mailto:{{ 'contact-us.checkbox-personal-info-consent-email' | translate }}">
                  {{ 'contact-us.checkbox-personal-info-consent-email' | translate }}
                </a>
              </span>
                &nbsp;*
              </label>
            </ng-template>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12">
            <span class="label">{{ 'contact-us.required' | translate }}</span>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12 form-buttons">
            <button type="button"
                    class="button"
                    data-dismiss="modal-dialog"
                    (click)="resetFormAndRedirectBack()">
              {{ 'contact-us.button-cancel' | translate }}
            </button>
            <button type="button"
                    class="button button--primary button--submit"
                    data-dismiss="modal-dialog"
                    [disabled]="formIsInvalid()"
                    (click)="submitForm()">
              {{ 'contact-us.button-submit' | translate }}
            </button>
          </div>
        </div>
        <app-modal
            [open]="showModalSubmit"
            [id]="'submit-modal-request'"
            title="{{ 'contact-us.request-submitted' | translate }}"
            (crossClick)="closeModal()"
            (outsideClick)="closeModal()">
          <ng-container ngProjectAs="[slot][body]">
            <div class="row">
              <div class="col-12">
                <span>{{ 'contact-us.contact-you-general' | translate }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container ngProjectAs="[slot][footer]">
            <div class="row">
              <div class="col-12">
                <button type="button"
                        class="button button--primary btn-submit"
                        data-dismiss="modal-dialog"
                        (click)="submitModalAndRedirectBack()">
                  {{ 'contact-us.button-ok' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </app-modal>
      </div>
    </div>
  </div>
</div>
