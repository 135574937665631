import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconType } from '../../models/settings.model';

@Component({
  selector: 'app-modal-eos-equipment',
  templateUrl: './modal-eos-equipment.component.html',
  styleUrls: ['./modal-eos-equipment.component.scss'],
})
export class ModalEosEquipmentComponent {

  @Input() showModalEosEquipment: boolean = false;
  @Input() showCancelBtn: boolean = true;

  @Output() closeModalConfirm = new EventEmitter<boolean>();
  @Output() primaryActionConfirm = new EventEmitter<boolean>();

  iconType = IconType;
  modalTitle: string = 'eos-equipment-modal.title';
  modalText: string = 'eos-equipment-modal.text';
  cancelBtnText: string = 'eos-equipment-modal.cancel';
  confirmBtnText: string = 'eos-equipment-modal.confirm';

  closeModal(): void {
    this.showModalEosEquipment = false;
    this.closeModalConfirm.emit(this.showModalEosEquipment);
  }

  confirmModal(): void {
    this.showModalEosEquipment = false;
    this.primaryActionConfirm.emit(this.showModalEosEquipment);
  }

}
