import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges, SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { options } from '../../testing/mocks/component/select-box.data';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent implements OnInit, OnChanges {
  isOpen: boolean;
  isInvalid = false;
  styles: any = {
    wrapper: '',
  };
  searchText = '';
  selectedOptionLabel = '';

  @ViewChild('searchInput') searchInput;
  @Input() modelValue: any;
  @Input() cssStyles: any;
  @Input() options: any;
  @Input() inputName: string;
  @Input() label: string;
  @Input() isRequired = false;
  @Input() readOnly = false;
  @Input() search = false;
  @Input() searchPlaceholder: string;
  @Input() loading: boolean;
  @Input() form: UntypedFormGroup = null;
  @Input() optionLabelKey = 'name';
  @Input() isSelectedFromOptions = false;
  @Input() inputDescription: string;
  @Input() disabled = false;
  @Input() showLabelWhenSelected = true;
  @Input() margin: boolean = true;
  @Output() selected = new EventEmitter<any>();

  constructor(private select: ElementRef) {
  }

  ngOnInit(): void {
    this.isOpen = false;
    this.styles = {...this.styles, ...this.cssStyles};
    this.selectedOptionLabel = this.getSelectedOptionLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.modelValue?.currentValue || changes?.modelValue?.currentValue === ''
      || changes?.options?.currentValue) {
      this.selectedOptionLabel = this.getSelectedOptionLabel();
    }
  }

  showOptions(event: Event): void {
    const target = event.target as Element;
    if (this.disabled) {
      this.isOpen = false;
    } else if (this.searchInput?.nativeElement.contains(target) || target.classList.contains('disabled')) {
      this.isOpen = true;
    } else {
      this.isOpen = !this.isOpen;
      this.searchText = '';
    }
  }

  selectOption(option): void {
    if (option?.disabled) {
      return;
    }
    const {name, value, ...additional} = option;
    const selected = this.options.find(entity => entity.selected);
    this.selected.emit({
      value,
      key: this.inputName,
      name,
      ...additional,
      inputDescription: this.inputDescription,
      oldValue: selected,
      inputType: 'selectBox',
    });
  }

  isActive(): boolean {
    if (!!this.modelValue && typeof this.modelValue === 'object') {
      return Object.keys(this.modelValue).length !== 0;
    }
    return !!this.modelValue;
  }

  showPlaceholder(): boolean {
    if (!this.showLabelWhenSelected) {
      return !this.isActive();
    }

    return !!this.label;
  }

  private getSelectedOptionLabel(): string {
    if (!!this.isSelectedFromOptions) {
      const selected = this.options.find(entity => entity.selected);
      return selected ? selected.description : '';
    }
    if (!this.modelValue) {
      return '';
    }

    if (typeof this.modelValue === 'object') {
      return this.getLabelForObjectModel();
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const option = this.options.find(option => option.value === this.modelValue);
    return option ? option.name : '';
  }

  private getLabelForObjectModel(): string {
    if (Object.keys(this.modelValue).length === 0) {
      return '';
    }

    if (this.modelValue?.name) {
      return this.modelValue.name;
    }

    return Object.keys(this.modelValue).map(key =>
      (typeof this.modelValue[key] === 'string'
        && this.modelValue[key] !== 'empty')
        ? this.modelValue[key]
        : '')
      .join(' ');
  }

  getLabel(): string {
    return `${this.label}${this.isRequired ? '*' : ''}`;
  }

  updateSearch($event: any): void {
    this.searchText = $event.target.value;
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (!this.select.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.searchText = '';
    }
  }
}
