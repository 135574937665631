import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CatalogFacade } from '../facades/catalog.facade';
import * as CatalogActions from '../actions/catalog.action';

@Injectable()
export class CatalogEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private catalogFacade: CatalogFacade,
  ) {
  }

  loadAbstractProductData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CatalogActions.loadAbstractProductData),
      exhaustMap(action =>
        this.catalogFacade.getAbstractProductDataFromApi(action.sku)
          .pipe(
            map(payload => CatalogActions.loadAbstractProductDataSuccess({payload})),
            catchError(error => of(CatalogActions.loadAbstractProductDataError({error}))),
          )),
    );
  });


  loadCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogActions.loadCategoriesStart),
      mergeMap(
        () => this.catalogFacade.getCategoryTree()
          .pipe(
            map(data => {
              return CatalogActions.loadCategoriesSuccess({payload: data});
            }),
            catchError(error => of(CatalogActions.loadCategoriesFail({error})),
            ),
          ),
      ),
    ),
  );
}
