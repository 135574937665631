import { createAction, props } from '@ngrx/store';
import {
  ICartItemListsRequest,
  ICartPayload,
  ICartUpdateRequest,
  ICartUpdateRequestAttributes,
} from '../models/cart.models';
import { IPriceDisputingPerItem } from '../models/common.models';

export const clearCart = createAction(
  '[Shop-Cart API] Clear Cart',
);

// loadCarts start
export const loadCarts = createAction(
  '[Shop-Cart API] Load Carts',
);
export const loadCartsSuccess = createAction(
  '[Shop-Cart API] Load Carts Success',
  props<{carts: any}>(),
);
export const loadCartsFail = createAction(
  '[Shop-Cart API] Load Carts Fail',
  props<{error: string}>(),
);
// loadCarts end

// loadCartItems start
export const loadCartItems = createAction(
  '[Shop-Cart API] Load Cart Items',
  props<{cartId: string}>(),
);
export const loadCartItemsSuccess = createAction(
  '[Shop-Cart API] Load Cart Items Success',
  props<{cartItems: ICartPayload}>(),
);
export const loadCartItemsFail = createAction(
  '[Shop-Cart API] Load Cart Items Fail',
  props<{error: string}>(),
);
// loadCartItems end

// postCart start
export const createEmptyCart = createAction(
  '[Shop-Cart API] Create empty cart',
  props<{attributes?: ICartUpdateRequestAttributes}>(),
);
export const postNewCartWithItem = createAction(
  '[Shop-cart API] Post Cart with Item',
  props<{requestBody: any, product: any, redirectUrl: string}>(),
);
export const postCartSuccess = createAction(
  '[Shop-Cart API] Post Cart Success',
  props<{newCartData: ICartPayload}>(),
);
export const postCartFail = createAction(
  '[Shop-Cart API] Post Cart Fail',
  props<{error: any}>(),
);
// postCart end

// loadCart start
export const loadCart = createAction(
  '[Shop-Cart API] Load Cart',
  props<{cartId: string}>(),
);
export const loadCartSuccess = createAction(
  '[Shop-Cart API] Load Cart Success',
  props<{cart: any}>(),
);
export const loadCartFail = createAction(
  '[Shop-Cart API] Load Cart Fail',
  props<{error: string}>(),
);
// loadCart end

// switchCart start
export const switchDefaultCart = createAction(
  '[Shop-Cart API] Switch Default Cart',
  props<{cartId: string, attributes?: ICartUpdateRequestAttributes}>(),
);
export const switchDefaultCartSuccess = createAction(
  '[Shop-Cart API] Switch Default Cart Success',
  props<{cart: ICartPayload}>(),
);
export const switchDefaultCartFail = createAction(
  '[Shop-Cart API] Switch Default Cart Fail',
  props<{error: string}>(),
);
// loadCart end

// deleteCart start
export const deleteCart = createAction(
  '[Shop-Cart API] Delete Cart',
  props<{cartId: string, redirectUrl: string}>(),
);
export const deleteCartSuccess = createAction(
  '[Shop-Cart API] Delete Cart Success',
  props<{cartId: string, isDefault: boolean}>(),
);
export const deleteCartFail = createAction(
  '[Shop-Cart API] Delete Cart Fail',
  props<{error: any}>(),
);
// deleteCart end

// deleteCart CPQ start
export const deleteCpqCart = createAction(
  '[Shop-Cart API] Delete Cpq Cart',
  props<{cartId: string, redirectUrl: string}>(),
);
export const deleteCpqCartSuccess = createAction(
  '[Shop-Cart API] Delete Cpq Cart Success',
  props<{cartId: string, redirectUrl: string}>(),
);
export const deleteCpqCartFail = createAction(
  '[Shop-Cart API] Delete Cpq Cart Fail',
  props<{error: any}>(),
);
// deleteCpqCart end

// addItemToCart start
export const addItemToQueue = createAction(
  '[Shop-Cart API] Add item to queue',
  props<{sku: string}>(),
);
export const checkIsCartDefault = createAction(
  '[Shop-Cart API] Check is cart default',
  props<{cartId: string, requestBody: any, product: any, redirectUrl: string}>(),
);
export const checkIsCartDefaultFail = createAction(
  '[Shop-Cart API] Check is cart default fail',
  props<{error}>(),
);
export const addItemToCartLoadCarts = createAction(
  '[Shop-Cart API] Load carts before add to cart',
  props<{cartId: string, requestBody: any, product: any, redirectUrl: string}>(),
);
export const addItemToCart = createAction(
  '[Shop-Cart API] Add Item to Cart',
  props<{cartId: string, requestBody: any, product: any, redirectUrl: string, retryWithNewCart: boolean}>(),
);
export const addItemToCartSuccess = createAction(
  '[Shop-Cart API] Add Item to Cart Success',
  props<{cartResponse: ICartPayload, itemData: any, product: any}>(),
);
export const addItemToCartError = createAction(
  '[Shop-Cart API] Add item to cart error',
  props<{error: string}>(),
);
export const createCartThanAddItem = createAction(
  '[Shop-Cart API] Create cart than add item',
  props<{error: Array<any>, cartId: string, requestBody: any, product: any, redirectUrl: string}>(),
);
export const addItemToCartLoadCartsSuccess = createAction(
  '[Shop-Cart API] Add Item to Cart - Load Carts Success',
  props<{cart: any, product: any, requestBody: any, redirectUrl: string}>(),
);
export const addItemFailAfterCartLoad = createAction(
  '[Shop-Cart API] Fail after load cart on add to cart',
  props<{error: string}>(),
);
export const handleAddToCartFail = createAction(
  '[Shop-Cart API] Handle add to cart fail',
  props<{error: Array<any>, cartId: string, requestBody: any, product: any, redirectUrl: string}>(),
);
export const handleAddToCartResetState = createAction(
  '[Shop-Cart API] Reset state after add to cart fail',
  props<{error: any, messageKey?: string, params?: any}>(),
);
export const removeItemFromQueue = createAction(
  '[Shop-Cart API] Remove item from in progress queue',
  props<{sku: string, error: any}>(),
);
export const addToCartFromCsvFile = createAction(
  '[Shop-Cart API] Add items from CSV file directly to cart and refresh cart',
  props<{cartId: string, requestBody: ICartItemListsRequest}>(),
);
export const addToCartFromCsvFileSuccess = createAction(
  '[Shop-Cart API] Add items from CSV file directly to cart Success',
  props<{cart: ICartPayload}>(),
);
export const addToCartFromCsvFileFail = createAction(
  '[Shop-Cart API] Add items from CSV file directly to cart Fail',
  props<{error: string}>(),
);

// addItemToCart end

export const updateShopCartAfterItemUpdate = createAction(
  '[Shop-Cart API] Update Shop cart after item update',
  props<{payload: ICartPayload}>(),
);

// deleteItemFromCart start
export const deleteItemFromCart = createAction(
  '[Shop-Cart API] Delete Item from Cart',
  props<{cartId: string, itemId: string}>(),
);
export const deleteItemFromCartSuccess = createAction(
  '[Shop-Cart API] Delete Item from Cart Success',
);
export const deleteItemFromCartFail = createAction(
  '[Shop-Cart API] Delete Item from Cart Fail',
  props<{error: string}>(),
);
// deleteItemFromCart end

// Shop-Cart Page start
export const setUpdateItemInCartData = createAction(
  '[Shop-Cart Page] Set Update Item in Cart Data',
  props<{quantity: number}>(),
);

export const loadCurrentCartAndCartItems = createAction(
  '[Shop-cart Page] Load current cart and cart items',
);

export const loadCurrentCart = createAction(
  '[Shop-cart Page] Load current cart',
  props<{id:string}>(),
)

export const loadCurrentCartItems = createAction(
  '[Shop-cart Page] Load current cart items',
  props<{cart: any}>(),
);
export const loadCurrentCartItemsSuccess = createAction(
  '[Shop-cart Page] Load current cart items success',
);
// Shop-Cart Page end

// updateItemInCartLoadCartItems start
export const updateItemInCartLoadCartItems = createAction(
  '[Shop-Cart API] Update Item in Cart and Load Cart Items',
  props<{cartId: string, itemId: string, updateItemInCartData: any}>(),
);

export const updateItemInCartLoadCartItemsSuccess = createAction(
  '[Shop-Cart API] Update Item in Cart and Load Cart Items Success',
  props<{updatedItemInCartAndLoadedCartItems: ICartPayload}>(),
);
export const updateItemInCartLoadCartItemsFail = createAction(
  '[Shop-Cart API] Update Item in Cart and Load Cart Items Fail',
  props<{error: string}>(),
);

// MiniCart start
export const toggleMiniCart = createAction(
  '[Mini-cart] Toggle mini cart',
  props<{isOpen: boolean}>(),
);
export const openMiniCartAndShowNotificationAfterAddToCart = createAction(
  '[Mini-cart] Open mini cart after add to cart action and show notification',
);
// MiniCart end

export const addLastAddedItemName = createAction(
  '[Shop-Cart] Add Last Added Item Name',
  props<{itemName: string}>(),
);
export const setCartStatus = createAction(
  '[Shop-Cart] Set Cart status',
  props<{status: boolean}>(),
);
export const addItemToCartInProgress = createAction(
  '[Shop-Cart] Adding item to cart in progress',
  props<{status: boolean}>(),
);

export const setDefaultCartItems = createAction(
  '[Shop-Cart] - Set current default cart items',
  props<{cartItems: any}>(),
);

export const updateCartData = createAction(
  '[Shop-Cart] Update Cart Data',
  props<{cartId: string, cartData: ICartUpdateRequest, eTag: string}>(),
);

export const updateCartDataSuccess = createAction(
  '[Shop-Cart] Update Cart Data success',
  props<{cartId: string}>(),
);

export const updateCartSystemDetailsSuccess = createAction(
  '[Shop-Cart] Update Cart system details success',
  props<{ cart: ICartPayload }>(),
)

export const updateCartDataFailed = createAction(
  '[Shop-Cart] Update Cart Data failed',
  props<{error: string}>(),
);

export const startCartOperation = createAction(
  '[Shop-Cart] Start Cart operation',
);

export const stopCartOperation = createAction(
  '[Shop-Cart] Stop Cart operation',
);
// Update cart end

export const quoteSummaryPageDeleteQuote = createAction(
  '[Quote Summary Page] Delete quote',
  props<{cartId: string, redirectUrl: string}>(),
);

// Store prefilled bill-to address
export const setBillToAddressIdAction = createAction(
  '[Shop-Cart] - Set selected bill-to address',
  props<{billToAddressSapId: string}>(),
);

export const clearBillToAddressIdAction = createAction(
  '[Shop-Cart] - Clear selected bill-to address',
);

// Store prefilled ship-to address
export const setShipToAddressIdAction = createAction(
  '[Shop-Cart] - Set selected ship-to address',
  props<{shipToAddressSapId: string}>(),
);

export const clearShipToAddressIdAction = createAction(
  '[Shop-Cart] - Clear selected ship-to address',
);

// Price disputing
export const addOrUpdatePriceDisputingPerItemInCartAction = createAction(
  '[Shop-Cart API] Add or update price disputing per item',
  props<{updatedPriceDisputing: IPriceDisputingPerItem}>(),
);

export const clearPriceDisputing = createAction(
  '[Shop-Cart API] Clear price disputing',
);
