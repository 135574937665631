import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { I18nService } from '../../services';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../analytics/analytics.service';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CmsFacade } from '../../facades/cms.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';

@Component({
  selector: 'app-store-selection',
  templateUrl: './store-selection.component.html',
  styleUrls: ['./store-selection.component.scss'],
})
export class StoreSelectionComponent implements OnInit, OnDestroy {
  storeSelectionBannerKey = environment.storeSelectionBannerKey;
  stores: any[];

  private unsubscribe$ = new Subject<void>();

  constructor(private authorizationFacade: AuthorizationFacade,
              private i18nService: I18nService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private configurationFacade: ConfigurationFacade,
              private cmsFacade: CmsFacade,) {
  }

  ngOnInit(): void {
    this.getTranslationByKey();
    this.authorizedUserLoggedIn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTranslationByKey(): any {
    this.configurationFacade.getTranslationByKey(['stores'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(storesArakhConfiq => {
        this.stores = environment.stores.filter(store => store.active).map(store => {
          return {
            name: storesArakhConfiq['stores'][store.storeId]['worldmap-btn'].text,
            path: `${store.languages[0].code}-${store.marketCode}`,
            id: store.storeId,
            redirect: storesArakhConfiq['stores'][store.storeId]['worldmap-btn'].clickable === 'true',
          };
        });
      });
  }

  authorizedUserLoggedIn(): void {
    this.authorizationFacade.selectIsUserLoggedIn().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isUserLoggedIn => {
      if (!this.isOnLoggedInRedirect() && isUserLoggedIn) {
        this.authorizationFacade.logout(true);
      }

      if (this.isOnLoggedOutRedirect()) {
        const currentLocale = this.i18nService.getCurrentLocale();

        this.router.navigate([currentLocale]).then();
      }

      if (!this.isOnLoggedInRedirect() && !this.isOnLoggedOutRedirect()) {
        const lang = LocalStorageUtils.getKeyValue('lang');

        LocalStorageUtils.clearKey(`lang-update-date-${lang}`);
        LocalStorageUtils.clearKey(`lang-data-${lang}`);
        LocalStorageUtils.clearKey(`feature-toggles-data-${lang}`);
        LocalStorageUtils.clearKey(`config-data-${lang}`);
        LocalStorageUtils.clearKey(`lang`);
        LocalStorageUtils.clearKey(`storeId`);
        LocalStorageUtils.clearKey(`previousPage`);

        this.adlyticTrackPage();
      }
    });
  }

  isOnLoggedInRedirect(): boolean {
    const params = window.location.search;

    return params.includes('code=') && params.includes('state=');
  }

  isOnLoggedOutRedirect(): boolean {
    return window.location.search.includes('logoutRedirect=1');
  }

  private adlyticTrackPage(): void {
    this.analyticsService.setProducts(null);
    this.analyticsService.setCartId(null);
    this.analyticsService.trackPageReady('Store Selection', PageTypes.GLOBAL_HOME_PAGE);
  }

}
