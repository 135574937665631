import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MarketingFacade } from '../../../facades/marketing.facade';
import { CpqFacade } from '../../../facades/cpq.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { AppUtils } from '../../../utils/app.utils';
import { ICheckout, IPaymentMethodName, IPaymentProviderName } from '../../../models/checkout.models';
import { IAddress } from '../../../models/common.models';
import { MathUtils } from '../../../utils/math.utils';
import { I18nService } from '../../../services';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-almost-done',
  templateUrl: './almost-done.component.html',
  styleUrls: ['./almost-done.component.scss'],
})
export class AlmostDoneComponent implements OnInit, OnDestroy {
  cart: any;
  cpqQuote: any;
  cartName: string;
  cpqQuoteId: string;
  cpqQuoteItems: any;
  requestQuoteData: ICheckout;
  initializeInProgress = true;
  visible: boolean;
  checked: boolean;
  selected: boolean;
  active: boolean;
  inputTermCode: string;
  radioTermCode: string;
  termsAccessTime: Date;
  customerFullName: string;
  customerEmail: string;
  customerBusinessUnitName: string;
  ecommerceTeamEmailAddress: string;
  totalAnnualPrice: number;
  quoteDocumentInProgress: boolean = false;

  @Input() showModal: boolean;
  @Input() showAlmostDone: boolean;
  @Output() showQuoteSummary = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();
  private docDownloadErrorMsgKey = 'configuration-summary.doc-download-error-message';
  private docDownloadErrorMsg: string;

  constructor(
    private router: Router,
    private marketingFacade: MarketingFacade,
    private translate: I18nService,
    private cpqFacade: CpqFacade,
    private customerFacade: CustomerFacade,
    private checkoutFacade: CheckoutFacade,
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.setDownloadErrorMessage();
    this.selectCart();
    this.getCpqQuote();
    this.getCpqQuoteItems();
    this.getUserData();
    this.getStoreEmailAddress();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backToQuoteSummary(): void {
    this.showAlmostDone = false;
    this.router.navigate(
      ['quote-summary'],
      {queryParamsHandling: 'merge'},
    ).then(() => this.showQuoteSummary.emit(this.showAlmostDone));
  }

  toggleReadMore(): void {
    this.visible = !this.visible;
  }

  downloadDocumentation(): void {
    this.quoteDocumentInProgress = true;
    this.cpqFacade.getCpqQuotePdfFile(this.cart.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_${this.cart.id}.pdf`);
            this.quoteDocumentInProgress = false;
          },
          error: () => {
            this.configFacade.setAlert({
              type: 'error',
              message: this.docDownloadErrorMsg,
            });
            this.quoteDocumentInProgress = false;
          },
        },
      );
  }

  confirmSendingContract(event): void {
    this.checked = false;
    if (event.target.checked) {
      this.checked = true;
      this.inputTermCode = 'signed_contract_sent';
    }
  }

  confirmChoosingAnswer(event): void {
    if (event.target.checked) {
      this.selected = true;
      this.radioTermCode = event.target.value;
    }
  }

  submitOrder(): void {
    if (this.checked && this.selected) {
      this.showModal = true;
    } else {
      this.active = true;
    }
  }

  closeModal(): void {
    this.showModal = false;
  }

  closeModalAndSubmitOrder(): void {
    this.active = false;
    this.closeModal();
    this.initializeInProgress = true;
    this.termsAccessTime = new Date();
    this.createRequest();
  }

  generateQuoteDocumentationUrl(): string {
    return `${window.location.origin}${this.router.createUrlTree(['/cpq-documentation-download'], {
      queryParams: {
        cartId: this.cpqQuote?.quoteId,
      },
    }).toString()}`;
  }

  getCpqQuoteItemsPrice(): number {
    const prices = this.cpqQuoteItems.reduce((acc, item) => {
      acc.push(
        item.pricing.summary.NetPrice,
      );
      return acc;
    }, []);

    return prices.reduce((total, price) => {
      return total + price;
    }, 0);
  }

  private selectCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      this.cart = cart;
      this.cartName = MathUtils.checkIfNumeric(this.cart.attributes.name) ? '' : '(' + this.cart.attributes.name + ')';
    });
  }

  private getCpqQuote(): void {
    this.cpqFacade.getCpqQuote(this.cart.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.cpqQuote = response.data.attributes.cpqResponse;
        this.cpqQuoteId = response.data.id;
      });
  }

  private getCpqQuoteItems(): void {
    this.cpqFacade.getCpqQuoteItems(this.cart.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.cpqQuoteItems = response.data.attributes.cpqResponse.items;
        this.totalAnnualPrice = this.getCpqQuoteItemsPrice();
        this.initializeInProgress = false;
      });
  }

  private getUserData(): void {
    this.customerFacade.selectMyProfileInformation()
      .pipe(take(1))
      .subscribe(userData => {
        this.customerFullName = `${userData.firstName} ${userData.lastName}`;
        this.customerEmail = userData.email;
      });

    this.customerFacade.selectCompanyBusinessUnit()
      .pipe(take(1))
      .subscribe(businessUnit => {
        this.customerBusinessUnitName = businessUnit?.name;
      });
  }

  private setAddress(parameter: string): IAddress {
    return {
      sapId: this.cpqQuote[parameter + '_id'],
      salutation: 'Mr',
      firstName: 'N/A',
      idCompanyUnitAddress: null,
      idCustomerAddress: null,
      lastName: 'N/A',
      address1: this.cpqQuote[parameter + '_address1'] ?? 'empty',
      address2: this.cpqQuote[parameter + '_address2'] ?? 'empty',
      address3: this.cpqQuote[parameter + '_address3'] ?? 'empty',
      zipCode: this.cpqQuote[parameter + '_zipCode'],
      city: this.cpqQuote[parameter + '_city'],
      state: this.cpqQuote[parameter + '_state'],
      iso2Code: 'US',
      phone: this.cpqQuote[parameter + '_phone'],
      company: this.cpqQuote[parameter + '_name'],
      country: 'US',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };
  }

  private getStoreEmailAddress(): void {
    this.ecommerceTeamEmailAddress = AppUtils.getCurrentStore().ecommerceTeamEmailAddress;
  }

  private createRequest(): void {
    const billingAddress = this.setAddress('billTo');
    const soldToAddress = this.setAddress('soldTo');
    const payerAddress = this.setAddress('payer');
    const shippingAddress = {
      sapId: this.cpqQuoteItems[0].shipTo_id,
      salutation: 'Mr',
      firstName: 'N/A',
      idCompanyUnitAddress: null,
      idCustomerAddress: null,
      lastName: 'N/A',
      address1: this.cpqQuoteItems[0].shipTo_address1 ?? 'empty',
      address2: this.cpqQuoteItems[0].shipTo_address2 ?? 'empty',
      address3: this.cpqQuoteItems[0].shipTo_address3 ?? 'empty',
      zipCode: this.cpqQuoteItems[0].shipTo_zipCode ?? 'empty',
      city: this.cpqQuoteItems[0].shipTo_city ?? 'empty',
      state: this.cpqQuoteItems[0].shipTo_state,
      iso2Code: 'US',
      phone: this.cpqQuoteItems[0].shipTo_phone,
      company: this.cpqQuoteItems[0].shipTo_name,
      country: 'US',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };

    this.requestQuoteData = {
      type: 'checkout',
      attributes: {
        idCart: this.cpqQuoteId,
        isAddressSavingSkipped: true,
        customer: {
          email: this.cart.attributes.customer.email,
          salutation: this.cart.attributes.customer.salutation,
          firstName: this.cart.attributes.customer.firstName,
          lastName: this.cart.attributes.customer.lastName,
          customerReference: this.cart.attributes.customer.customerReference,
          uuidCompanyUser: this.cart.attributes.customer.uuidCompanyUser,
        },
        billingAddress,
        shippingAddress,
        soldToAddress,
        payerAddress,
        payments: [
          {
            paymentMethodName: IPaymentMethodName.CONTRACT,
            paymentProviderName: IPaymentProviderName.CONTRACT,
          },
        ],
        shipment: {
          idShipmentMethod: 1,
        },
        termsAccessTime: this.termsAccessTime,
        consentedTerms: [
          'privacy_policy',
          'terms_and_conditions',
          this.inputTermCode,
          this.radioTermCode,
        ],
      },
    };

    this.checkoutFacade.postCheckout({data: this.requestQuoteData}).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(response => {
      this.cpqFacade.quoteSummarySubmittedOrder(response.data.attributes);
      this.initializeInProgress = false;
    });
  }

  private setDownloadErrorMessage(): void {
    this.translate.getTranslationByKey(this.docDownloadErrorMsgKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(message => this.docDownloadErrorMsg = message);
  }
}
