import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconType } from '../../models/settings.model';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html'
})
export class ModalConfirmComponent implements OnInit {
  @Input() showModalConfirm: boolean;
  @Input() type?: IconType;
  @Input() modalTitle: string;
  @Input() notifyText: string;
  @Input() cancelBtnText: string;
  @Input() secondaryBtnText: string;
  @Input() primaryBtnText: string;

  @Output() closeModalConfirm = new EventEmitter<any>();
  @Output() secondaryActionConfirm = new EventEmitter<any>();
  @Output() primaryActionConfirm = new EventEmitter<any>();

  hasSecondaryActionBtn: boolean;

  ngOnInit(): void {
    this.hasSecondaryActionBtn = !!this.secondaryBtnText; 
  }

  cancelOnClick(): void {
    this.closeModalConfirm.emit();
  }

  secondaryActionOnClick(): void {
    this.secondaryActionConfirm.emit();
    this.closeModalConfirm.emit();
  }

  primaryActionOnClick(): void {
    this.primaryActionConfirm.emit();
    this.closeModalConfirm.emit();
  }
}
