import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as ValidationPatterns from '../../configurations/validations';
import { WishlistFacade } from '../../facades/wishlist.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-wishlist',
  templateUrl: './add-new-wishlist.component.html',
  styleUrls: ['./add-new-wishlist.component.scss'],
})
export class AddNewWishlistComponent implements OnInit, OnChanges, OnDestroy {
  form: UntypedFormGroup;
  maxNameLength = 20;
  inProgress = false;

  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Output() created: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private wishlistFacade: WishlistFacade,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.selectAddNewInProgress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal.currentValue) {
      this.initializeForm();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectAddNewInProgress(): void {
    this.wishlistFacade.selectAddNewInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
        this.inProgress = state;
    });
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(this.maxNameLength), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  resetForm(): void{
    this.closeModal.emit();
    this.form.reset();
  }

  closeForm(): void {
    this.wishlistFacade.isNewWishlistCreated().subscribe(
      result => {
        if (result) {
          this.resetForm();
        }
      }
    );
  }

  formIsValid(): boolean {
    return this.form.status === 'VALID';
  }

  createWishlist(): void {
    this.wishlistFacade.createNewWishlist(this.form.value.name);
    this.closeForm();
  }

  validateField(name: string): boolean {
    return this.form.get(name).hasError('maxlength');
  }
}
