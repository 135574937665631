<div *ngIf="filter.values?.length > 0" 
     class="equipment-filter" 
     [ngClass]="{'is-open': filter.activeValue?.length > 0 || isFilterOpened}">
  <button type="button" 
      class="button-filter" 
      [attr.data-test]="'button-filter-' + filter.name"
      (click)="toggleFilterOptions()">
    {{ filter.localizedName }}
  </button>
  <div class="filter-options">
    <div class="search-field textfield textfield--with-prefix">
      <span class="textfield__prefix icon-search"></span>
      <input type="search"
             [attr.data-test]="'input-filterSelect-' + filter.name"
          class="textfield__input search-field__input"
          spellcheck="false" 
          autocomplete="off" 
          [id]="'item_search' + filter.name" 
          [name]="'search' + filter.name" 
          placeholder="{{ 'advanced-filter.search'  | translate }} {{ filter.localizedName }}"
          [(ngModel)]="filterSearchValue"
          (keydown.enter)="$event.preventDefault()">
      <label for="search" class="search-bar__label"></label>
    </div>
    <ul class="filter-options-list">
      <li class="all-options-item" *ngIf="filter.values?.length > 1">
        <input type="checkbox"
               [attr.data-test]="'input-filterCheckbox-' + filter.name"
            class="checkbox"
            [id]="'select-all-' + filter.name"
            [name]="'checkbox-all-' + filter.name"
            [checked]="areOptionsSelected()"
            (click)="selectOptionsOrClear()">
        <label [for]="'select-all-' + filter.name">
          {{ 'filters.equipment-filter.select-all-or-clear' | translate }}
        </label>
      </li>
      <li *ngFor="let option of filter.values | searchFilter: filterSearchValue" 
          class="option-item"
          [attr.data-test]="'desc-option-' + option.value">
        <input type="checkbox"
               [attr.data-test]="'input-filterCheckbox-' + filter.name + '-' + option.value"
            class="checkbox"
            [id]="filter.name + '-' + option.value" 
            [name]="filter.name + '-' + option.value"
            [checked]="isOptionSelected(option)" 
            (click)="selectOption(option)">
        <label [for]="filter.name + '-' + option.value">
          <span [attr.data-test]="'desc-optionName'">
            {{ option.value }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</div>
