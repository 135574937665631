<div class="search-bar" [ngClass]="{'active': active, 'cart': cartSearch}">
  <div class="search-field">
    <span *ngIf="cartSearch" class="icon-search"></span>
    <input
        id="searchInput"
        name="search"
        type="search"
        class="search-bar__input"
        spellcheck="false"
        autocomplete="off"
        [disabled]="disabled"
        [value]="query"
        placeholder="{{ 'app-bar.search-placeholder' + (cartSearch ? '-cart' : '') | translate }}"
        (keyup)="onKey($event)"
        (click)="active = true"
        [attr.data-test]="cartSearch ? 'input-searchBar-in-cartPage' : 'input-searchBar-in-header'"/>
    <label for="searchInput" class="action-icons" *ngIf="hasValue() && !disabled">
      <span class="loading-animation" *ngIf="loading"></span>
      <span class="search-bar__remove icon-close" *ngIf="!loading" (click)="clearQuery()" [attr.data-test]="'button-remove-input-from-searchBar'"></span>
    </label>
    <button *ngIf="!cartSearch" type="button" class="search-bar__btn-search" [disabled]="!hasValue()"
            (click)="proceedToSearchPage(query)"
            [attr.data-test]="'button-searchBar-search'">
      <span class="icon-search"></span>
    </button>
  </div>
  <div class="search-results" [ngClass]="{'cart': cartSearch}" *ngIf="hasValue() && !loading && active">
    <ng-container *ngIf="hasSuggestions()">
      <ng-container *ngFor="let key of suggestionsKeys()">
        <ng-container *ngIf="suggestions[key].length > 0 && visibleResults.includes(key)">
          <span
              class="suggestion__title"
              *ngIf="suggestionTitle.includes(key) && !cartSearch">
            {{ 'app-bar.suggestion-' + key | translate }}
          </span>
          <ul
              class="suggestions__list"
              [ngClass]="{'has-title': suggestionTitle.includes(key)}"
              [attr.data-test]="'search-suggestions-list'">
            <ng-container *ngFor="let suggestion of suggestions[key]; let i = index">
              <ng-container *ngIf="key === 'categories' && !cartSearch">
                <li
                    *ngIf="i < maxResultsPerSuggestion"
                    [ngClass]="['suggestion', 'suggestion-'+key]"
                    [innerHTML]="boldString(suggestion.name, query)"
                    (click)="proceedToCategoryPage(suggestion)"
                    [attr.data-test]="'item-suggestionCategories-' + suggestion.name">
                </li>
              </ng-container>
              <ng-container *ngIf="key === 'completion' && !cartSearch">
                <li
                    *ngIf="i < maxResultsPerSuggestion"
                    [ngClass]="['suggestion', 'suggestion-'+key]"
                    [innerHTML]="boldString(suggestion, query)"
                    (click)="proceedToSearchPage(suggestion)"
                    [attr.data-test]="'item-suggestionCompletion-' + suggestion">
                </li>
              </ng-container>
              <ng-container *ngIf="key === 'abstractProducts'">
                <li
                    *ngIf="suggestion.abstractName && i < maxResultsPerSuggestion"
                    [ngClass]="['suggestion', 'suggestion-'+key]"
                    (click)="proceedToProductPage(suggestion)"
                    [attr.data-test]="'item-suggestionAbstractProduct-' + suggestion.abstractName">
                  <span [innerHTML]="boldString(
                    getCartSearchSuggestion(suggestion), query)"></span>
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <hr *ngIf="!cartSearch" class="suggestions__underline">
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="no-result-found" *ngIf="!hasSuggestions() && hasValue()">
      {{ 'app-bar.no-result-found' | translate }}
    </div>
  </div>
</div>
