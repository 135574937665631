import { Component, Input } from '@angular/core';
import { ImageUtils } from '../../../utils/image.utils';

@Component({
  selector: 'app-basic-mini-cart',
  templateUrl: './basic-mini-cart.component.html',
  styleUrls: ['./basic-mini-cart.component.scss'],
})
export class BasicMiniCartComponent {
  @Input() currency: string;
  @Input() itemsInCart: any[];
  @Input() checkWorkflowInformation: boolean;

  getItemPicture(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }
}
