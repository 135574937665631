import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  @Input() config: any;
  @Input() disableInput: boolean;
  @Output() changed = new EventEmitter<any>();
}
