export class ObjectUtils {
  static isObject(object: any): boolean {
    return object !== null && typeof object === 'object';
  }

  static isEmpty(object: any): boolean {
    if (!this.isObject(object)) {
      return true;
    }
    return Object.keys(object).length === 0 && object.constructor === Object;
  }

  static isEqual(object1: object, object2: object, deepEqual: boolean = true): boolean {
    return deepEqual ? this.deepEqual(object1, object2) : this.shallowEqual(object1, object2);
  }

  private static deepEqual(object1: object, object2: object): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];

      const areObjects = this.isObject(val1) && this.isObject(val2);

      if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
        return false;
      }
    }
    return true;
  }

  private static shallowEqual(object1: object, object2: object): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  static hasProperty(object: object, propertyName: string, deepCheck: boolean = true): boolean {
    return deepCheck ? this.deepHasProperty(object, propertyName) : this.shallowHasProperty(object, propertyName);
  }

  private static shallowHasProperty(object: object, propertyName: string): boolean {
    return object.hasOwnProperty(propertyName);
  }

  private static deepHasProperty(object: object, propertyName: string): boolean {
    const keys = Object.keys(object);

    for (const key of keys) {
      const isObject = this.isObject(object[key]);

      if (isObject) {
        if (this.shallowHasProperty(object[key], propertyName)) {
          return true;
        } else {
          return this.deepHasProperty(object[key], propertyName);
        }
      }
    }
    return false;
  }

  static deepClone(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * @example
   * // returns level3 value
   * getNestedValue(obj, 'level1', 'level2', 'level3');
   * @example
   * // returns null if level3 doesn't exists
   * getNestedValue(obj, 'level1', 'level2', 'level3');
   */
  static getNestedValue(object: object, ...args: any): any | null {
    return args.reduce((obj, level) => obj && obj[level], object) || null;
  }

  static shallowFilterObject(object: object, keyToRemove: string): any | null {
    return Object.keys(object)
        .filter( key => key !== keyToRemove )
        .reduce( (res, key) => (res[key] = object[key], res), {} );
  }

  static getObjectbyString(obj, propsName): any {
    propsName = propsName.replace(/\[(\w+)\]/g, '.$1');
    propsName = propsName.replace(/^\./, '');
    const a = propsName.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in obj) {
        obj = obj[k];
      } else {
        return;
      }
    }
    return obj;
  }
}
