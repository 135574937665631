import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { IAbstractProductIncluded } from '../../../models/abstract-product.models';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { environment } from '../../../../environments/environment';
import { ArrayUtils } from '../../../utils/array.utils';
import { ProductUtils } from '../../../utils/product.utils';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { IContractParams } from '../../../models/catalog.models';
import { Router } from '@angular/router';
import { CpqUtils } from '../../../utils/cpq.utils';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent implements OnInit, OnDestroy {
  isLoaded = false;
  infoItems = [];
  abstractProducts: IAbstractProductIncluded[] = [];
  addItemInProgress = false;

  private unsubscribe$ = new Subject<void>();

  @Input() recommendations: Array<string>;
  @Output() startOver = new EventEmitter<any>();
  @Output() goBackOneStep = new EventEmitter<any>();
  @Output() selectedContract = new EventEmitter<any>();
  @Input() contractParams: IContractParams;

  constructor(
    private catalogFacade: CatalogFacade,
    private translate: TranslateService,
    private marketingFacade: MarketingFacade,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    forkJoin(
      this.recommendations.map(sku => this.catalogFacade.getAbstractProductDataFromApi(`A_${sku}`)),
    ).pipe(takeUntil(this.unsubscribe$))
      .subscribe({
          next: ([...args]) => {
            this.abstractProducts = args.map(arg => arg.data);
          },
          complete: () => {
            this.isLoaded = true;
          },
          error: () => {
            this.isLoaded = true;
            this.abstractProducts = ArrayUtils.sortByAttribute(
              [...this.abstractProducts], 'attributes.attributes.expensiveness');
            this.getCpqItemsAttributesKeyAndValue();
          },
        },
      );
    this.selectAddItemInProgress();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    return !!translation && translation !== key;
  }

  getCpqItemsAttributes(product: IAbstractProductIncluded): string[] {
    return product.attributes.superAttributesDefinition.filter(attribute =>
      !environment.cpqItemAttributesToHide.includes(attribute),
    );
  }

  getCpqItemsAttributesKeyAndValue(): void {
    this.infoItems = this.abstractProducts.reduce((acc, product) => {
      const attributes = this.getCpqItemsAttributes(product).reduce((attributesAcc, attribute) => {
        const options = this.abstractProducts.map(item => item.attributes.attributes).map(option => option[attribute]);
        attributesAcc.push({
          key: attribute,
          value: product.attributes.attributeNames[attribute],
          options,
        });
        return attributesAcc;
      }, []);
      const filteredAttributes = attributes.reduce((optionsAcc, item) => {
        const options = item.options.filter(option => option !== '' && option !== null);
        if (options.length > 0) {
          optionsAcc.push({
            key: item.key,
            value: item.value,
            options,
          });
        }
        return optionsAcc;
      }, []);
      const filteredAttributesWithOptions = filteredAttributes.filter((element) =>
        !acc.find(elem => elem.key === element.key));
      acc.push(...filteredAttributesWithOptions);
      return acc;
    }, []);
  }

  getDetailAttribute(attributeValue: string | null): string {
    return ProductUtils.getDetailAttribute(attributeValue);
  }

  goBackToStart(): void {
    this.startOver.emit();
  }

  selectContract(contractSku: string): void {
    this.selectedContract.emit(contractSku);
  }

  contactUs(product: IAbstractProductIncluded): void {
    const url = this.router.createUrlTree(
      ['/contact-us'],
      {queryParams: this.getExtendedContractParams(product)},
    ).toString();

    this.router.navigateByUrl(url).then();
  }

  getExtendedContractParams(product: IAbstractProductIncluded): IContractParams {
    return {
      ...CpqUtils.filterOutEmptyParams(this.contractParams),
      selectedService: product.attributes.name,
    };
  }

  private selectAddItemInProgress(): void {
    this.marketingFacade.isAddItemOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addItemInProgress => {
        this.addItemInProgress = addItemInProgress;
      },
    );
  }
}
