import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { OrdersFacade } from '../facades/orders.facade';
import { IHybrisOrder, IHybrisOrderResponse } from '../models/order.models';
import { CustomerActions, HybrisOrdersActions } from '../actions';
import { EStoreFeatures } from '../configurations/common';
import { ConfigurationFacade } from '../facades/configuration.facade';

@Injectable({
  providedIn: 'root',
})
export class HybrisOrdersEffects {

  constructor(
    private actions$: Actions,
    private ordersFacade: OrdersFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  initHybrisOrdersAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      concatMap(() => {
        if (this.configurationFacade.isFeatureAvailable(EStoreFeatures.SPARE_PARTS)) {
          return [
            HybrisOrdersActions.retrieveHybrisOrders(),
          ];
        } else {
          return [];
        }
      }),
    );
  });

  retrieveHybrisOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HybrisOrdersActions.retrieveHybrisOrders),
      mergeMap(() => this.ordersFacade.getHybrisOrders().pipe(
        map((data: IHybrisOrderResponse) => HybrisOrdersActions.retrieveHybrisOrdersSuccess({
          hybrisOrders: data.data as IHybrisOrder[],
        })),
      )),
    ));
}
