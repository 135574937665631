<ng-container *ngIf="!isGuest && !companyRolesLoading && isLoggedIn">
  <ng-content select="[slot][content]"></ng-content>
</ng-container>
<ng-container *ngIf="!isGuest && !companyRolesLoading && isLoggedIn && (notForViewer && !isViewer)">
  <ng-content select="[slot][nonViewerContent]"></ng-content>
</ng-container>
<ng-container *ngIf="isGuest && description && !companyRolesLoading">
  <p class="no-access">{{ 'customer.no-permission' | translate }}</p>
</ng-container>
<ng-container *ngIf="(notForViewer && isViewer) && description && !companyRolesLoading">
  <p class="no-access">{{ 'customer.no-permission' | translate }}</p>
</ng-container>
