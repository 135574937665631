import { Action, createFeatureSelector, createReducer, createSelector, MemoizedSelector, on } from '@ngrx/store';
import * as OrdersActions from '../actions/orders.actions';
import { ICpqOrder, IOrder, IOrderHistory, IPendingOrder } from '../models/order.models';
import { GlueUtils } from '../utils/glue.utils';
import { IPaginationCurrent } from '../models/settings.model';
import { OrdersUtils } from '../utils/orders.utils';
import { EGlueResource } from '../configurations/common';

export interface IOrdersWithSapDetails {
  orders: IOrder[] | undefined;
  sapOrdersDetails: IOrder[] | undefined;
}

export interface IOrdersWithSapDetailsState {
  ordersPerPage: IOrdersWithSapDetails[],
  pagination: IPaginationCurrent,
}

export interface IOrdersHistory {
  ordersHistory: IOrderHistory[] | undefined;
}

export interface IOrdersCartsState {
  pendingCarts: IPendingOrder[] | undefined;
  canceledDeclinedCarts: IOrderHistory[];
  ordersHistoryPerPage: IOrdersHistory[];
  ordersHistoryPagination: IPaginationCurrent;
}

export interface ICpqOrders {
  cpqOrders: ICpqOrder[];
}

export interface ICpqOrdersState {
  ordersUnderReviewPerPage: ICpqOrders[];
  ordersFulfilledPerPage: ICpqOrders[];
  paginationUnderReviewOrders: IPaginationCurrent,
  paginationFulfilledOrders: IPaginationCurrent,
}

const initialPagination: IPaginationCurrent = {
  currentPage: 0,
  currentOffset: 0,
  prevPage: 0,
  prevOffset: 0,
  nextPage: 0,
  nextOffset: 0,
  firstPage: 0,
  firstOffset: 0,
  lastPage: 0,
  lastOffset: 0,
  limitPerPage: 0,
};

export const cpqOrdersBaseState = createFeatureSelector<ICpqOrdersState>('cpqOrders');

export const selectCpqOrdersUnderReviewPagination = createSelector(
  cpqOrdersBaseState,
  state => state.paginationUnderReviewOrders,
);

export const selectCpqOrdersFulfilledPagination = createSelector(
  cpqOrdersBaseState,
  state => state.paginationFulfilledOrders,
);

export const selectUnderReviewCpqOrdersHistoryAtPage = (pageNum: number) => {
  return createSelector(
    cpqOrdersBaseState,
    state => {
      return state.ordersUnderReviewPerPage[pageNum] ? state.ordersUnderReviewPerPage[pageNum].cpqOrders : [];
    },
  );
};

export const selectFulfilledCpqOrdersHistoryAtPage = (pageNum: number) => {
  return createSelector(
    cpqOrdersBaseState,
    state => {
      return state.ordersFulfilledPerPage[pageNum] ? state.ordersFulfilledPerPage[pageNum].cpqOrders : [];
    },
  );
};

export const ordersWithDetailsBaseState = createFeatureSelector<IOrdersWithSapDetailsState>('ordersWithDetails');

export const selectLoadedSapOrders = createSelector(
  ordersWithDetailsBaseState,
  state => {
    const loadedPages = state.ordersPerPage.filter(ordersPerPage => !!ordersPerPage);
    return ({
      orders: [].concat(
        ...loadedPages.map(ordersPerPage => ordersPerPage.orders),
      ),
      sapOrdersDetails: [].concat(
        ...loadedPages.map(ordersPerPage => ordersPerPage.sapOrdersDetails),
      ),
    });
  },
);

export const selectSapOrdersPagination = createSelector(
  ordersWithDetailsBaseState,
  state => state.pagination,
);

export const selectSapOrdersAtPage = (pageNum: number) => {
  return createSelector(
    ordersWithDetailsBaseState,
    state => state.ordersPerPage[pageNum] ?? {orders: [], sapOrdersDetails: []},
  );
};

export const ordersCartsBaseState = createFeatureSelector<IOrdersCartsState>('ordersCarts');

//select pending carts from orders application store
export const selectOrdersPendingCarts = createSelector(
  ordersCartsBaseState,
  state => state.pendingCarts,
);

//select canceled/declined carts from orders application store
export const selectOrdersCanceledDeclinedCarts = createSelector(
  ordersCartsBaseState,
  state => state.canceledDeclinedCarts,
);

export interface IOrdersHistoryPage {
  currentPage: number;
  numPages: number;
  limitPerPage: number;
  orders: IOrderHistory[];
  pageLoaded: boolean;
}

/**
 * Returns selector to retrieve a page of the "mixed" orders history consisting of
 * rejected/canceled carts and sales orders
 *
 * @param {number} pageNum
 * @returns {MemoizedSelector<IOrdersCartsState, IOrdersHistoryPage>}
 */
export const selectOrdersHistoryAtPage = (pageNum: number) => {
  return createSelector(
    ordersCartsBaseState,
    ({ordersHistoryPagination, ordersHistoryPerPage, canceledDeclinedCarts}) => {
      const limitPerPage = ordersHistoryPagination.limitPerPage;
      if (ordersHistoryPerPage[pageNum]) {
        const ordersWithCanceledDeclinedCarts = mergeOrdersAndCanceledDeclinedCarts(
          ordersHistoryPerPage, canceledDeclinedCarts
        );
        const startOffsets = recalculateMergedPageOffsets(ordersHistoryPerPage, ordersHistoryPagination)
        const startOffset = startOffsets[pageNum];
        const endOffset = startOffset + limitPerPage;

        return {
          orders: ordersWithCanceledDeclinedCarts.slice(startOffset, endOffset),
          currentPage: pageNum,
          numPages: ordersHistoryPagination.lastPage,
          pageLoaded: true,
          limitPerPage
        }
      } else {
        return {
          orders: [],
          currentPage: pageNum,
          numPages: ordersHistoryPagination.lastPage,
          pageLoaded: false,
          limitPerPage
        };
      }
    },
  );
};

export const initialState: IOrdersWithSapDetailsState = {
  ordersPerPage: [],
  pagination: { ...initialPagination},
};

export const initialOrdersCartsState: IOrdersCartsState = {
  pendingCarts: undefined,
  canceledDeclinedCarts: [],
  ordersHistoryPerPage: [],
  ordersHistoryPagination: { ...initialPagination},
};

const reducer = createReducer(
  initialState,

  on(OrdersActions.SuccessGetOrdersWithSapDetailsDataAction, (state: IOrdersWithSapDetailsState, {ordersWithSapDetails}) => {
    const pagination = GlueUtils.parsePaginationLinks(ordersWithSapDetails.links);
    const ordersPerPage = new Array(...state.ordersPerPage);
    ordersPerPage[pagination.currentPage] = {
      orders: ordersWithSapDetails?.data as IOrder[],
      sapOrdersDetails: ordersWithSapDetails?.included as IOrder[],
    };
    return {
      ...state,
      ordersPerPage,
      pagination,
    };
  }),

  on(OrdersActions.OrdersWithSapDetailsClearData, (state, action): IOrdersWithSapDetailsState => {
    return {
      ...initialState,
    };
  }),

  on(OrdersActions.SuccessUpdateOrdersWithSapDetailsDataAction, (state: IOrdersWithSapDetailsState, {
    orders,
    sapOrdersDetails,
  }) => {
    return {
      ...state,
      orders: orders,
      sapOrdersDetails: sapOrdersDetails,
    };
  }),
);

export function OrdersReducer(state: IOrdersWithSapDetailsState | undefined, action: Action): any {
  return reducer(state, action);
}

export const cpqOrdersInitialState: ICpqOrdersState = {
  ordersUnderReviewPerPage: [],
  ordersFulfilledPerPage: [],
  paginationFulfilledOrders: { ...initialPagination},
  paginationUnderReviewOrders: { ...initialPagination},
};

const cpqOrdersReducer = createReducer(
  cpqOrdersInitialState,

  on(OrdersActions.SuccessGetCpqOrdersUnderReviewDataAction, (state: ICpqOrdersState, {cpqOrders}) => {
    const paginationUnderReviewOrders = GlueUtils.parsePaginationLinks(cpqOrders.links);
    const ordersUnderReviewPerPage = new Array(...state.ordersUnderReviewPerPage);
    ordersUnderReviewPerPage[paginationUnderReviewOrders.currentPage] = {
      cpqOrders: cpqOrders?.data as ICpqOrder[],
    };

    return {
      ...state,
      cpqOrders: cpqOrders?.data as ICpqOrder[],
      ordersUnderReviewPerPage,
      paginationUnderReviewOrders,
    };
  }),

  on(OrdersActions.SuccessGetCpqOrdersFulfilledDataAction, (state: ICpqOrdersState, {cpqOrders}) => {
    const paginationFulfilledOrders = GlueUtils.parsePaginationLinks(cpqOrders.links);
    const ordersFulfilledPerPage = new Array(...state.ordersFulfilledPerPage);
    ordersFulfilledPerPage[paginationFulfilledOrders.currentPage] = {
      cpqOrders: cpqOrders?.data as ICpqOrder[],
    };

    return {
      ...state,
      cpqOrders: cpqOrders?.data as ICpqOrder[],
      ordersFulfilledPerPage,
      paginationFulfilledOrders,
    };
  }),
);

export function CpqOrdersReducer(state: ICpqOrdersState | undefined, action: Action): any {
  return cpqOrdersReducer(state, action);
}


const ordersCartsReducer = createReducer(
  initialOrdersCartsState,

  on(OrdersActions.SuccessGetOrdersWithCartsDataAction, (state: IOrdersCartsState, {carts}) => {
    const canceledDeclinedCarts: IPendingOrder[] = OrdersUtils.filterCanceledDeclinedCarts(carts?.data as IPendingOrder[]);
    const canceledDeclinedOrders: IOrderHistory[] = OrdersUtils.convertDeclinedCanceledOrdersToOrderHistoryObject(canceledDeclinedCarts);
    const pendingCarts = OrdersUtils.filterPendingCarts(carts?.data as IPendingOrder[]);
    return {
      ...state,
      canceledDeclinedCarts: canceledDeclinedOrders,  //array of canceled/declined carts
      pendingCarts: pendingCarts   //array of carts with status waiting
    };
  }),

  on(OrdersActions.SuccessGetCartOrdersWithApprovalDataAction, (state: IOrdersCartsState, {carts}) => {
    const canceledDeclinedCarts: IPendingOrder[] = OrdersUtils.filterCanceledDeclinedCarts(carts?.data as IPendingOrder[]);
    const canceledDeclinedOrders: IOrderHistory[] = OrdersUtils.convertDeclinedCanceledOrdersToOrderHistoryObject(canceledDeclinedCarts);
    const pendingCarts = OrdersUtils.filterPendingCarts(carts?.data as IPendingOrder[]);
    return {
      ...state,
      canceledDeclinedCarts: canceledDeclinedOrders,  //array of canceled/declined carts
      pendingCarts: pendingCarts   //array of carts with status waiting
    };
  }),

  on(OrdersActions.SuccessGetOrdersHistoryDataAction, (state: IOrdersCartsState, {ordersHistory}) => {
    const ordersHistoryPagination = GlueUtils.parsePaginationLinks(ordersHistory?.links);
    const ordersHistoryPerPage = new Array(...state.ordersHistoryPerPage);
    ordersHistoryPerPage[ordersHistoryPagination.currentPage] = {
      ordersHistory: ordersHistory?.data as IOrderHistory[],
    };

    return {
      ...state,
      ordersHistoryPerPage,
      ordersHistoryPagination,
    };
  }),

  on(OrdersActions.OrdersHistoryClearDataAction, (state): IOrdersCartsState => {
    const ordersHistoryPerPage: IOrdersHistory[] = [];
    const ordersHistoryPagination = {...initialPagination};
    return {
      ...state,
      ordersHistoryPerPage,
      ordersHistoryPagination
    };
  }),
);

export function OrdersCartReducer(state: IOrdersCartsState | undefined, action: Action): any {
  return ordersCartsReducer(state, action);
}

/**
 * Utility function to calculate the start offsets of virtual "pages" of the merged array
 * of canceled/declined carts and loaded sales orders as returned by {@see mergeOrdersAndCanceledDeclinedCarts()}.
 *
 * The reason these offsets need to be calculated is that the salesOrdersPerPage are "sparse"
 * due to the ability of users to skip pages instead of sequentially loading them, so for e.g.
 * a limit of 20 the start offsets into the merged list might be
 *
 * [0 => undefined, 1 => orders, 2 => orders, 3 => orders] // upto 60 orders + carts (out of at most 60) loaded
 * => [0 => 0, 1 => 0, 2 => 20, 3 => 40]
 *
 * [0 => undefined, 1=> orders, 2=> undefined, 3 => orders] // upto 40 orders + carts (out of at most 60) loaded
 * => [0 => 0, 1 => 0, 2 => 20, 3 => 20]
 *
 * @param {IOrdersHistory[]} salesOrdersPerPage
 * @param {IPaginationCurrent} ordersPagination
 * @returns {number[]}
 */
function recalculateMergedPageOffsets(
  salesOrdersPerPage: IOrdersHistory[],
  ordersPagination: IPaginationCurrent,
): number[] {
  const pageLimit = ordersPagination.limitPerPage;
  const numPages = ordersPagination.lastPage;
  let nextStartOffset = 0;
  let offsets = [...Array(numPages).keys()]
    .map((value) => value + 1)
    .map((pageNum) => {
      const currentOffset = nextStartOffset;
      if (salesOrdersPerPage[pageNum]) {
        nextStartOffset += pageLimit;
      }
      return currentOffset;
    });
  offsets.unshift(0);
  return offsets;
}

/**
 * Utility function to merge canceled/declined carts and loaded sales orders
 * into a single flat list sorted by the proper date value as displayed in
 * the orders table.
 *
 * @param {IOrdersHistory[]} salesOrdersPerPage
 * @param {IOrderHistory[]} canceledDeclinedCarts
 * @returns {IOrderHistory[]}
 */
function mergeOrdersAndCanceledDeclinedCarts(
  salesOrdersPerPage: IOrdersHistory[],
  canceledDeclinedCarts: IOrderHistory[],
): IOrderHistory[] {
  const loadedSalesOrders = [].concat(
    ...salesOrdersPerPage
      .filter(ordersPerPage => !!ordersPerPage)
      .map(ordersPerPage => ordersPerPage.ordersHistory),
  );

  return loadedSalesOrders
    .concat(canceledDeclinedCarts)
    .map((order) => {
      // temporarily extend sales orders and carts with correct date field for sorting
      const displayDate = order.type === EGlueResource.CARTS ? order.attributes.orderedAt : order.attributes.createdAt;
      return {displayDate, ...order};
    }).sort((a, b) => new Date(b.displayDate).getTime() - new Date(a.displayDate).getTime(), // sort by date
    ).map(extendedOrder => {
      // remove temporary date field
      const {displayDate, ...order} = extendedOrder;
      return order;
    });
}
