import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-layout-options',
  templateUrl: './layout-options.component.html',
  styleUrls: ['./layout-options.component.scss']
})
export class LayoutOptionsComponent {
  @Input() currentLayoutType: string;
  @Output() selected = new EventEmitter<any>();

  selectLayoutOption(type: string): void {
    this.selected.emit(type);
  }
}
