<ng-container *ngIf="disableAgreement()">
  <div class="warnings margin-top-1">
    <p class="warning icon-close">
      {{ 'my-contracts.quote-soldTo-message' | translate }}
      <app-tooltip
          link="{{ 'my-contracts.add-quote-modal-why-tooltip-link' | translate }}"
          text="{{ 'my-contracts.add-quote-modal-why-tooltip-text' | translate }}">
      </app-tooltip>
    </p>
  </div>
</ng-container>
<ng-container>
  <ng-container>
    <div class="contract-details" *ngIf="agreement">
      <div class="details" [ngClass]="{'disabled': disableAgreement()}">
        <div class="details__info">
          <div class="details__list">
            <div
                *ngFor="let attribute of attributes"
                class="details__list-item {{attribute.attribute}}"
                [ngClass]="attribute.attribute === 'contractNumber' && needsAttention ? 'hidden' : ''">
              <div class="details__list-item--title">
                {{ attribute.label | translate }}
                <app-tooltip
                    *ngIf="attribute.tooltip"
                    link="icon"
                    text="{{ attribute.tooltip | translate }}">
                </app-tooltip>
              </div>
              <div class="details__list-item--data">
                {{ getAttribute(attribute.attribute, attribute.nestedAttribute ?? '') }}
              </div>
            </div>
          </div>
          <div class="details__messages">
            <div class="warnings">
              <p class="warning icon-warning"
                 *ngIf="
                  hasSupportDateUnder60Days
                  && hasContractEndDateUnder60DaysOrIsNull
                  && !supportAndContractEndsSameDay
                  && !inactive">
                {{ 'my-contracts.warning-term' | translate }}
              </p>
              <p class="warning icon-warning" *ngIf="inactive && !isEquipment">{{ 'my-contracts.quote-submitted' | translate }}</p>
            </div>
          </div>
        </div>
        <app-access-control [notForViewer]="true">
          <ng-container ngProjectAs="[slot][nonViewerContent]">
            <div class="details__buttons" *ngIf="disableAgreement() else activeButtons">
              <span class="new-quote-desc">{{ 'my-contracts.start-new-quote' | translate }}</span>
            </div>
            <ng-template #activeButtons>
              <div class="details__buttons">
                <button
                    *ngIf="needsAttention && !isEquipment"
                    type="button"
                    class="button button--primary action-button" [attr.data-test]="'button-startConfiguration'"
                    [disabled]="inactive || addItemOperationInProgress"
                    (click)="startConfiguration()">
                  <span>{{ 'my-contracts.start-configuration' | translate }}</span>
                </button>
                <span class="equipment-buttons" *ngIf="isEquipment && selectableEquipment">
                  <button
                      *ngIf="!equipmentSelectionInProgress; else loadingSpinner"
                      type="button"
                      class="button button--primary action-button" 
                      [attr.data-test]="'button-selectEquipment'"
                      [disabled]="isCartOperationInProgress || loadingCartDataInProgress || addItemOperationInProgress"
                      (click)="startEquipmentSelection()">
                    <span>{{ 'my-equipment.select-equipment' | translate }}</span>
                  </button>
                  <ng-template #loadingSpinner>
                    <div class="spinner">
                      <div class="loading-animation"></div>
                    </div>
                  </ng-template>
                  <div class="additional-options">
                    <a *ngIf="isUserSparePartsViewer()" class="spare-parts-viewer"
                       (click)="openSparePartsViewer()"
                       [attr.data-test]="'link-sparePartsViewer'">
                      <span class="icon-arrow-long-right"></span>
                      <span>{{ 'my-equipment.select-spare-parts-viewer' | translate }}</span>
                    </a>
                    <br>
                    <a href="{{ getTeamplayFleetContractUrl() }}" [attr.data-test]="'link-selectTeamPlayFleet'"
                     target="_blank"
                     rel="noopener">
                      <span class="icon-arrow-long-right"></span>
                      <span>{{ 'my-equipment.select-teamplay-fleet' | translate }}</span>
                    </a>
                  </div>
                </span>
                <p *ngIf="!soldToInactive && soldToIdFromParams" class="sold-to-active-info">
                  {{ 'my-contracts.configuration-added-to-existing-quote' | translate }}</p>
                <ng-container *ngIf="!needsAttention && !selectableEquipment">
                  <a class="button button--primary action-button" [attr.data-test]="'link-viewContractDetails'"
                     href="{{ getTeamplayFleetContractUrl() }}"
                     target="_blank"
                     rel="noopener">
                    <span>{{ 'my-contracts.contract-details' | translate }}</span>
                  </a>
                  <p class="details-desc">{{ 'my-contracts.contract-details-desc' | translate }}</p>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </app-access-control>
      </div>
      <div *ngIf="!isEquipmentCompatible" class="equipment-warning">
        <span class="icon-warning icon-warning__circle"></span>
        <span class="equipment-warning__message">
          {{ 'my-equipment.warning-message' | translate }}
        </span>
      </div>
      <app-modal
          *ngIf="isAddQuoteModalOpen"
          [id]="'add-quote-modal'"
          [open]="isAddQuoteModalOpen"
          [title]="'my-contracts.add-quote-modal-title' | translate"
          (crossClick)="isAddQuoteModalOpen = false"
          (outsideClick)="isAddQuoteModalOpen = false">
        <ng-container ngProjectAs="[slot][body]">
          <app-add-quote-modal
              [flNumber]="agreement.attributes?.siemensEquipmentId"
              [materialNumber]="agreement.attributes?.materialNumber"
              [soldToIdFromParams]="soldToIdFromParams"
              [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
              [productSku]="productSku">
          </app-add-quote-modal>
        </ng-container>
      </app-modal>
    </div>
  </ng-container>
</ng-container>
<app-modal-confirm
    class="modal-confirm"
    [type]="iconType.WARNING"
    modalTitle="{{ 'equipment-selection.you-have-items-in-cart' | translate }}"
    notifyText="{{ 'equipment-selection.change-equipment-or-create-cart-info-text' | translate }}"
    secondaryBtnText="{{ 'equipment-selection.new-cart' | translate }}"
    primaryBtnText="{{ 'equipment-selection.change-equipment' | translate }}"
    [showModalConfirm]="showModalChangeEquipmentOrCreateNewCart"
    (closeModalConfirm)="closeModalChangeEquipmentOrCreateNewCart()"
    (secondaryActionConfirm)="createNewCartForEquipment()"
    (primaryActionConfirm)="changeEquipmentForCurrentCart()">
</app-modal-confirm>
