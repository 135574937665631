export enum EConfigurationStatus {
  RESOLVABLE = 'RESOLVABLE',
  OK = 'OK',
}

export enum EAddressType {
  SoldTo = 'sold-to',
  ShipTo = 'ship-to',
  BillTo = 'bill-to',
  Payer = 'payer'
}

export const DAYS_FROM_TODAY_TO_START_CONTRACT = 14;
