import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StoreSelectionService {
  constructor(private readonly location: Location) {
  }

  isOnStoreSelectionPage(): boolean {
    return this.location.path().split('?')[0] === '';
  }
}
