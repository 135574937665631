import { Injectable } from '@angular/core';
import { CanActivate, Router, RoutesRecognized } from '@angular/router';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { filter, map, pairwise } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EFeatureToggles } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  currentRoutePath = '';
  previousRoutePath = '';

  constructor(
    private router: Router,
    private configFacade: ConfigurationFacade,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.configFacade.isFeatureEnabled(EFeatureToggles.MAINTENANCE).pipe(map(maintenanceEnabled => {
      this.router.events.pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      ).subscribe({
        next: (event) => {
          if (event.length >= 1) {
            this.previousRoutePath = event[1].urlAfterRedirects;
          }
          this.currentRoutePath = event[0]['url']?.split('/').findLast((e) => e);
        },
      });

      if (maintenanceEnabled) {
        if (this.previousRoutePath.endsWith('.')) {
          this.router.navigate(['.']);
          return true;
        } else {
          this.router.navigate(['page-maintenance']);
          return false;
        }
      } else {
        if (this.currentRoutePath === 'page-maintenance') this.router.navigate(['.']);
        return true;
      }
    }));
  }
}
