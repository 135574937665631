import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nService } from '../../../services';
import { AddressUtils } from '../../../utils/address.utils';
import { CustomerFacade } from '../../../facades/customer.facade';
import { notFoundValue, notInstallBaseRelated } from '../../../shared/ivk-selection-form/IvkSelectionFormConstants';
import { ArrayUtils } from '../../../utils/array.utils';
import { IAdditionalItemField, ISelectEvent } from '../../../models/common.models';
import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { InstallBaseFacade } from '../../../facades/install-base.facade';
import { EUserRoles } from '../../../configurations/common';
import { IAddressSelectBox } from '../../../models/customer.models';
import { AppUtils } from '../../../utils/app.utils';

@Component({
  selector: 'app-order-details-section',
  templateUrl: 'order-details-section.component.html',
  styleUrls: ['order-details-section.component.scss'],
})
export class OrderDetailsSectionComponent implements OnInit, OnDestroy {
  @Input() loggedUserRoles: EUserRoles[];
  @Input() cartItemsWithDetails;
  @Input() cartRules;
  @Input() isCartEmpty;
  @Input() cartItems;
  @Input() requestQuoteFeatureActive: boolean;
  @Input() excludeTax: boolean;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  customerAddresses: IAddressSelectBox[] = [];
  businessAddresses: IAddressSelectBox[] = [];
  ivkForm: UntypedFormGroup;
  deliveryForm: UntypedFormGroup;

  installBaseLoading: boolean = false;
  businessUnitLoading: boolean = false;
  businessAddressesLoading: boolean = false;
  systems = [];
  businessUnits = [] as Array<{name: string, value: string}>;
  addresses: Array<{name: string, value: any, addressFromSystem: boolean}> = [];
  formsAreValid: boolean = false;
  showModalAddressWrong: boolean = false;
  showModalAddress: boolean = false;
  additionalItemLevelFields: IAdditionalItemField[] = [] as Array<IAdditionalItemField>;
  preferredShipToAddress = null;

  private itemFormMap = new Map();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private i18nService: I18nService,
              private customerFacade: CustomerFacade,
              private installBaseFacade: InstallBaseFacade,
              private customerCurrencyPipe: CustomerCurrencyPipe) {
  }

  ngOnInit(): void {
    this.getNotFoundLabels();
    this.beginCustomerAddressesAction();
    this.beginGetBusinessUnitsAction();

    this.initializeForm();

    this.selectInstallBaseProducts();
    this.selectBusinessUnitLoading();
    this.selectBusinessAddresses();
    this.selectBusinessUnits();
    this.selectCustomerAddresses();

    this.prepareAdditionalFields();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initializeForm(): void {
    this.deliveryForm = this.formBuilder.group({
      deliveryAddress: ['', [Validators.required]],
    });

    this.revalidateForms();
  }

  ivkFormChanged(form: UntypedFormGroup): void {
    this.ivkForm = form;

    this.revalidateForms();
  }

  /**
   * Triggered when user change system in delivery details.
   * If there is address in "addresses", then replace it with "addressData" value. If not, then add this address
   * to "addresses" variable.
   * If user is using SAP store, then also preselect this address in SelectBox.
   *
   * @param {ISelectEvent} event
   */
  systemIdValueChanged(event: ISelectEvent): void {
    let addressData;
    if (event.additional?.companyBusinessUnitAddress) {
      const addressFromSystemIndex = this.addresses.findIndex(address => address.addressFromSystem);
      addressData = {
        name: AddressUtils.createAddressString(event.additional.companyBusinessUnitAddress),
        value: {
          ...event.additional.companyBusinessUnitAddress,
          idCompanyUnitAddress: null,
          idCustomerAddress: null,
        },
        addressFromSystem: true,
      };

      if (addressFromSystemIndex === -1) {
        this.addresses.push(addressData);
      } else {
        this.addresses[addressFromSystemIndex] = addressData;
      }
    } else {
      addressData = '';
    }

    if (AppUtils.isSapStore() || !this.preferredShipToAddress) {
      this.deliveryForm.patchValue({
        deliveryAddress: addressData,
      });
    }

    this.revalidateForms();
  }

  /**
   * @param {ISelectEvent} event
   */
  selectDeliveryAddress(event: ISelectEvent): void {
    this.deliveryForm.patchValue({
      deliveryAddress: event,
    });

    this.revalidateForms();
  }

  showModalNewAddress(): void {
    this.showModalAddress = true;
  }

  showModalWrongAddress(): void {
    this.showModalAddressWrong = true;
  }

  itemFormChanged(itemForm: any): void {
    this.itemFormMap.set(itemForm.itemId, itemForm.formGroup);

    this.revalidateForms();
  }

  itemFormHidden(itemId: any): void {
    this.itemFormMap.delete(itemId);

    this.revalidateForms();
  }

  revalidateForms(): void {
    this.formsAreValid = this.isFormValid(this.ivkForm) &&
      this.isFormValid(this.deliveryForm) &&
      this.areItemFormsValid();
  }

  proceedToNextStep(): void {
    const formData = {
      deliveryAddress: this.deliveryForm.value.deliveryAddress?.value,
      systemDetails: {
        serialNumber: this.ivkForm.value.serialNumber,
        siemensEquipmentId: this.ivkForm.value.siemensEquipmentId,
        materialNumber: this.ivkForm.value.materialNumber,
        namePtBr: this.ivkForm.value.namePtBr,
        nameEnUs: this.ivkForm.value.nameEnUs,
        company: this.ivkForm.value.company,
        companyBusinessUnit: this.ivkForm.value.companyBusinessUnit,
        companyBusinessUnitNumber: this.ivkForm.value.companyBusinessUnitNumber,
      },
      systemDetailsPerItem: [],
    };

    for (const [id, form] of this.itemFormMap) {
      formData.systemDetailsPerItem.push({
        itemId: id,
        systemDetails: {
          serialNumber: form.value.serialNumber,
          siemensEquipmentId: form.value.siemensEquipmentId,
          materialNumber: form.value.materialNumber,
          namePtBr: form.value.namePtBr,
          nameEnUs: form.value.nameEnUs,
          company: form.value.company,
          companyBusinessUnit: form.value.companyBusinessUnit,
          companyBusinessUnitNumber: form.value.companyBusinessUnitNumber,
        },
      });
    }

    this.formSubmitted.emit(formData);
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form?.status === 'VALID';
  }

  beginCustomerAddressesAction(): void {
    this.customerFacade.beginCustomerAddressesAction();
  }

  /**
   * Return array of addresses of systems (in "companyBusinessUnitAddress"). If user is not using SAP store, then set
   * "companyBusinessUnitNumber" as sapId.
   *
   * @param {any[]} sortedSystems
   * @returns {any[]}
   */
  selectAddressesFromInstallBase(sortedSystems: any[]): any[] {
    return ArrayUtils.removeDuplicates(sortedSystems
      .map(installBase => ({
        ...installBase?.attributes?.companyBusinessUnitAddress,
        sapId: AppUtils.isSapStore() ? installBase?.attributes?.companyBusinessUnitAddress.sapId : installBase?.attributes?.companyBusinessUnitNumber
      }))).filter((el) => {
      return el.address1 != null;
    });
  }

  private getNotFoundLabels(): void {
    this.i18nService.getTranslationByKey([
      'request-details.system-not-found',
      'request-details.unit-not-found',
      'request-details.item-not-install-base-related',
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(labels => {
      this.systems.unshift({value: notFoundValue, name: labels['request-details.system-not-found']});
      this.systems.unshift({
        value: notInstallBaseRelated,
        name: labels['request-details.item-not-install-base-related'],
      });
      this.businessUnits.unshift({value: notFoundValue, name: labels['request-details.unit-not-found']});
    });
  }

  private beginGetBusinessUnitsAction(): void {
    this.customerFacade.beginGetBusinessUnitsAction();
  }

  /**
   * Load all installedBaseSystemData. Also get addresses from these systems to fill SelectBox for selecting
   * delivery address. If user is not using SAP store, then preselect preferred ship-to address in SelectBox with
   * one from My Profile page in Account Settings.
   *
   * @private
   */
  private selectInstallBaseProducts(): void {
    this.installBaseLoading = true;
    this.installBaseFacade.selectInstalledBaseSystemData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: data => {
        this.installBaseLoading = false;
        if (data?.length) {
          const sortedSystems = ArrayUtils.sortByAttribute([...data], 'attributes.nameEnUs');
          this.systems = this.systems.concat(sortedSystems.map(installBase => {
            return {
              name: installBase.attributes.nameEnUs + (installBase.attributes.siemensEquipmentId ? ` (${installBase.attributes.siemensEquipmentId})` : ''),
              value: installBase.attributes.siemensEquipmentId,
              additional: {...installBase.attributes},
            };
          }));
          this.businessUnits = this.businessUnits.concat(sortedSystems.reduce((acc, installBase) => {
            if (installBase?.attributes?.companyBusinessUnit && installBase.attributes.companyBusinessUnitNumber) {
              acc.push({
                name: installBase.attributes.companyBusinessUnit,
                value: installBase.attributes.companyBusinessUnitNumber,
                additional: {
                  institutionName: installBase.attributes.company,
                },
              });
            }
            return acc;
          }, []));
          AddressUtils.appendAddresses(this.addresses, this.selectAddressesFromInstallBase(sortedSystems), 'business', true);

          if (!AppUtils.isSapStore()) {
            this.customerFacade.selectCustomerPreferences().pipe(
              skipWhile(customerPreferences => !customerPreferences),
              takeUntil(this.unsubscribe$),
            ).subscribe(customerPreferences => {
              this.preferredShipToAddress = this.addresses.filter((address) =>
                  address.value.sapId === customerPreferences.preferredShipToId)[0];
              this.deliveryForm.patchValue({deliveryAddress: this.preferredShipToAddress});
            });
          }
          this.prepareBusinessUnits();
        }
      }, error: () => {
        this.installBaseLoading = false;
      },
    });
  }

  private selectBusinessUnitLoading(): void {
    this.customerFacade.selectBusinessUnitLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoading => {
      this.businessUnitLoading = isLoading;
    });
  }

  private selectBusinessUnits(): void {
    this.customerFacade.selectBusinessUnits().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(units => {
      if (units) {
        this.businessUnits = this.businessUnits.concat(units.map(unit => {
          return {
            name: unit.name,
            value: unit.businessUnitNumber,
            additional: {
              institutionName: unit.institutionName,
            },
          };
        }));
        this.prepareBusinessUnits();
      }
    });
  }

  private selectBusinessAddresses(): void {
    this.businessAddressesLoading = true;
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: addresses => {
          if (addresses) {
            this.businessAddresses = addresses;
            AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
            this.businessAddressesLoading = false;
          }
        },
        error: () => {
          this.businessAddressesLoading = false;
        },
      },
    );
  }

  private selectCustomerAddresses(): void {
    this.customerFacade.selectCustomerAddresses().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  private prepareBusinessUnits(): void {
    this.businessUnits = ArrayUtils.removeDuplicates(this.businessUnits);
    // Skip first unit as it is not found option
    const sortedUnits = ArrayUtils.sortByAttribute(this.businessUnits.slice(1, this.businessUnits.length), 'name');
    this.businessUnits = [this.businessUnits[0], ...sortedUnits];
  }

  private areItemFormsValid(): boolean {
    for (const form of this.itemFormMap.values()) {
      if (form.status === 'INVALID') {
        return false;
      }
    }

    return true;
  }

  private prepareAdditionalFields(): void {
    this.additionalItemLevelFields = [{
      name: 'shop-cart.quantity',
      getValFunc: (item) => item.attributes.quantity,
      shouldDisplayFunc: (_) => true,
    }, {
      name: 'shop-cart.item-price',
      excludeTax: this.excludeTax,
      getValFunc: (item) => this.customerCurrencyPipe.transform(
        item.attributes.calculations.unitPrice,
        this.cartItems.currency,
      ),
      shouldDisplayFunc: (_) => true,
    }];
  }
}
