<ng-container *ngIf="!isLoading else loading">
  <h4 class="head-title">{{ 'my-contracts.add-quote-modal-include-title' | translate }}</h4>
  <p>{{ 'my-contracts.add-quote-modal-include-text' | translate }}</p>
  <div class="accordion service-agreements__table">
    <div class="accordion__title service-agreements__header">
      <div class="width-5 service-agreements__title">
      </div>
      <div class="width-30 service-agreements__title">
        {{ 'service-purchase-activity.quote-id' | translate }}
      </div>
      <div class="service-agreements__title">
        {{ 'service-purchase-activity.quote-nickname' | translate }}
      </div>
    </div>
    <div class="agreements__table">
      <div class="table-items">
        <app-accordion
            *ngFor="let quote of quotes"
            [manualOpen]="tabsOpened.includes(quote.id)">
          <ng-container ngProjectAs="[slot][header]">
            <div class="accordion__title icon-arrow-down"
                 [ngClass]="{'disabled': checkIfAccordionIsDisabled(quote)}"
                 (click)="toggleTab(quote.id)">
              <span class="agreement-info width-5">
                <input
                    id="radio-{{quote.id}}"
                    type="radio"
                    name="quoteSelected"
                    [value]="quote.id"
                    [disabled]="checkIfAccordionIsDisabled(quote)"
                    class="radio"
                    (change)="quoteSelected = quote.id">
                <label for="radio-{{quote.id}}"></label>
              </span>
              <span class="agreement-info width-30 quote-id">{{ quote.id }}</span>
              <span class="agreement-info width-35 quote-nickname">{{ cartName(quote) }}</span>
              <span class="agreement-info width-30 different-sold-to"
                    *ngIf="!quote.hasTheSameSoldTo && !quote.hasSameFL">
                <span class="icon icon-close icon-close__circle"></span>
                <span>{{ 'my-contracts.different-sold-to' | translate }}</span>
              </span>
              <span class="agreement-info width-30 already-in-quote" *ngIf="quote.hasSameFL">
                <span class="icon icon-close icon-close__circle"></span>
                <span>{{ 'my-contracts.different-fl' | translate }}</span>
              </span>
            </div>
          </ng-container>
          <ng-container ngProjectAs="[slot][panel]">
            <div class="container accordion__content" *ngIf="tabsOpened.includes(quote.id)">
              <ng-container *ngIf="quote.itemDetails.length > 0 else noItems">
                <ng-container *ngIf="!checkIfSparePartsIncluded(quote) else hasSpareParts">
                <div class="web">
                  <table class="fl_table">
                    <caption>{{ quote.id }}</caption>
                    <thead>
                    <tr>
                      <th scope="col">{{ 'my-contracts.functional-location' | translate }}</th>
                      <th scope="col">{{ 'my-contracts.add-quote-modal-equipment' | translate }}</th>
                      <th scope="col">{{ 'my-contracts.add-quote-modal-service' | translate }}</th>
                      <th scope="col">{{ 'my-contracts.add-quote-modal-price' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let detail of quote.itemDetails">
                      <td><strong>{{ detail.attributes.systemDetails?.siemensEquipmentId }}</strong></td>
                      <td>{{ getModality(detail.attributes.systemDetails?.materialNumber) }}</td>
                      <td>{{ serviceType }}</td>
                      <td><strong>{{ detail.attributes.cpqNetPrice | currency: quote.attributes.currency }}</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mobile">
                  <span class="agreement-info different-sold-to"
                        *ngIf="!quote.hasTheSameSoldTo && !quote.hasSameFL">
                    <span class="icon icon-close icon-close__circle"></span>
                    <span>{{ 'my-contracts.different-sold-to' | translate }}</span>
                  </span>
                  <span class="agreement-info already-in-quote" *ngIf="quote.hasSameFL">
                    <span class="icon icon-close icon-close__circle"></span>
                    <span>{{ 'my-contracts.different-fl' | translate }}</span>
                  </span>
                  <p class="agreement-info--title">{{ 'service-purchase-activity.quote-id' | translate }}</p>
                  <span>{{ quote.id }}</span>
                  <p class="agreement-info--title">{{ 'service-purchase-activity.quote-nickname' | translate }}</p>
                  <span>{{ cartName(quote) }}</span>
                  <p class="agreement-info--title">{{ 'my-contracts.functional-locations' | translate }}</p>
                  <div class="agreement-info--details">
                    <ol>
                      <li *ngFor="let detail of quote.itemDetails">
                        <div class="agreement-info--details-info">
                          <p>{{detail.attributes.systemDetails?.siemensEquipmentId}}</p>
                          <p>{{ getModality(detail.attributes.systemDetails?.materialNumber) }}</p>
                          <p>{{ serviceType }}</p>
                        </div>
                        <div class="agreement-info--details-price">
                          <p>{{ 'my-contracts.add-quote-modal-price' | translate }}:
                            <span class="price">{{ detail.attributes.cpqNetPrice | currency: quote.attributes.currency }}</span>
                          </p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
                </ng-container>
                <ng-template #hasSpareParts>
                  <p class="no-items">
                    <span class="icon icon-close icon-close__circle"></span>
                    <span class="text">{{ 'add-item-modal.spare-parts-items' | translate }}</span>
                  </p>
                </ng-template>
              </ng-container>
              <ng-template #noItems>
                <p class="no-items">{{ 'multi-cart.no-items' | translate }}</p>
              </ng-template>
            </div>
          </ng-container>
        </app-accordion>
      </div>
    </div>
  </div>
  <div class="modal-button">
    <button
        type="submit"
        class="button button--primary"
        [disabled]="!quoteSelected"
        (click)="addToCart()">
      {{ 'my-contracts.add-quote-modal-add-button' | translate }}
    </button>
  </div>

  <div class="modal-new-quote">
    <h4>{{ 'my-contracts.add-quote-modal-new-quote' | translate }}</h4>
    <p>{{ 'my-contracts.add-quote-modal-new-quote-text' | translate }}</p>
    <div class="modal-button">
      <button
          type="submit"
          class="button button--primary"
          (click)="startConfiguration()">
        {{ 'my-contracts.add-quote-modal-new-quote-button' | translate }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="flex-container">
    <div class="loading-animation"></div>
  </div>
</ng-template>
