import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ConfigurationFacade } from '../../facades/configuration.facade';

const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(`2s ease-out`, style({
      opacity: 0,
    })),
  ]),
]);

@Component({
  selector: 'app-full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss'],
  animations: [
    fadeOut,
  ],
})
export class FullPageLoaderComponent implements OnInit, OnDestroy {
  isActive: boolean = true;
  currentPageLoadingList = [];
  loaderImageUrl: string = environment.defaultBannerImg;

  @Input() waitingList = [];

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectFullPageLoaderList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectFullPageLoaderList(): void {
    this.configFacade.selectFullPageLoaderList().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.currentPageLoadingList = state;

      if (state.length > 0 && this.waitingList.length > 0 && this.waitingList.every(entity => state.includes(entity))) {
        this.isActive = false;
      }
    });
  }
}
