<div *ngIf="loadingPageData; else body">
  <div class="thank-you__loader">
    <div class="loading-animation"></div>
  </div>
</div>
<ng-template #body>
  <div class="thank-you__content">
    <div class="thank-you__overview">
      <div class="thank-you__description">
        <h4>{{ 'thank-you-page.main-title' | translate }}</h4>
        <h6>{{ 'thank-you-page.your-quote' | translate:{name: orderDetails.orderReference, nickname: nickname} }}</h6>
        <p>{{ 'thank-you-page.first-info' | translate }}</p>
        <p>{{ 'thank-you-page.second-info' | translate }}</p>
      </div>
      <div class="help-section">
        <h5>{{ 'thank-you-page.help-section-title' | translate }}</h5>
        <p>{{ 'thank-you-page.help-section-description' | translate }}</p>
        <span
            *ngIf="phone">{{ 'thank-you-page.phone' | translate:
                {phone: phone | phoneNumber: phoneNumberFormat || '4|3|2|2' : delimiter || ' ' : prefix || ''}
            }}
        </span>
        <span>{{ 'thank-you-page.email' | translate }}<a href="mailto:{{email}}">{{ email }}</a></span>
      </div>
    </div>
    <app-steps-bar
        [scenarioType]="scenarioType"
        [currentStatus]="currentStatus">
    </app-steps-bar>
    <button
        *ngIf="!quoteDocumentGenerationInProgress; else loadingSpinner"
        type="button"
        class="download-documentation"
        (click)="downloadDocumentation()">
      <span class="icon-download"></span>
      {{ 'thank-you-page.download-quote-documentation' | translate }}
    </button>
    <ng-template #loadingSpinner>
      <div class="btn-spinner">
        <div class="loading-animation"></div>
      </div>
    </ng-template>
    <h4>{{ 'thank-you-page.quote-summary-title' | translate }}</h4>
    <div class="thank-you__quote-summary">
      <h4>{{ 'thank-you-page.order-details-title' | translate }}</h4>
      <div class="quote-summary">
        <div class="order-details">
          <p>{{ orderDetails.orderReference }}</p>
          <span class="nickname">{{ nickname }}</span>
          <span *ngIf="details.length > 0">
            <span>{{ 'thank-you-page.equType' | translate:{count: calculateEquType(details)} }}</span>
            <span>{{ 'thank-you-page.fls' | translate:{count: details.length} }}</span>
          </span>
        </div>
        <div class="order-price">
          <span>{{ 'thank-you-page.order-price-title' | translate }}</span>
          <span>{{ cpqQuotePrice | currency }}</span>
        </div>
      </div>
      <h4>{{ 'thank-you-page.address-title' | translate }}</h4>
      <div class="addresses">
        <div class="address-details">
          <span>{{ 'thank-you-page.sold-address' | translate }}</span>
          <span>{{ orderDetails.soldToAddress.company }}</span>
          <span>{{ orderDetails.soldToAddress.address1 }}</span>
          <span>{{ orderDetails.soldToAddress.city }}, {{ orderDetails.soldToAddress.state }} {{ orderDetails.soldToAddress.zipCode }}</span>
        </div>
        <div class="address-details">
          <span>{{ 'thank-you-page.bill-address' | translate }}</span>
          <span>{{ orderDetails.billingAddress.company }}</span>
          <span>{{ orderDetails.billingAddress.address1 }}</span>
          <span>{{ orderDetails.billingAddress.city }}, {{ orderDetails.billingAddress.state }} {{ orderDetails.billingAddress.zipCode }}</span>
        </div>
        <div class="address-details">
          <span>{{ 'thank-you-page.payer-address' | translate }}</span>
          <span>{{ orderDetails.payerAddress.company }}</span>
          <span>{{ orderDetails.payerAddress.address1 }}</span>
          <span>{{ orderDetails.payerAddress.city }}, {{ orderDetails.payerAddress.state }} {{ orderDetails.payerAddress.zipCode }}</span>
        </div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <button
          type="button"
          class="button button--off-content"
          (click)="startNewQuote()" [attr.data-test]="'button-startNewQuote'">
        {{ 'thank-you-page.start-new-quote' | translate }}
      </button>
      <button
          type="button"
          class="button button--primary"
          (click)="viewPurchaseActivity()" [attr.data-test]="'viewPurchaseActivity'">
        {{ 'thank-you-page.view-purchase-activity' | translate }}
      </button>
    </div>
  </div>
</ng-template>
