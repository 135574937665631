import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { DateUtils } from '../utils/date.utils';
import { MathUtils } from '../utils/math.utils';

const japanPattern = '\u3041-\u3096\u30A0-\u30FF\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\uFF5F-\uFF9F\u31F0-\u31FF\u3220-\u3243\u3280-\u337F\uFF01-\uFF5E';
const lettersPattern = `a-zA-Z\u00C0-\u017e${japanPattern}\\s-`;

export const streetAndNumberAndZipCodeRegex = `^(?! +$)[0-9${lettersPattern}/]+$`;
export const canadaZipCodeRegex = `^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$`;
export const puertoRicoZipCodeRegex = /^00\d{3}(-\d{4})?$/;

export const onlyLetters = Validators.pattern(`^(?! +$)[${lettersPattern}]+$`);
export const phonePattern = Validators.pattern(/^(?! )(?=(?:[^()]*\([^()]+\))*[^()]*$)[\d+\-\(\)\s]+(?<! )$/);
export const cityStatePattern = Validators.pattern(`^(?! +$)[${lettersPattern}]+$`);
export const streetAndNumberAndZipCodePattern = Validators.pattern(streetAndNumberAndZipCodeRegex);
export const canadaZipCodePattern = Validators.pattern(canadaZipCodeRegex);
export const puertoRicoZipCodePattern = Validators.pattern(puertoRicoZipCodeRegex);
export const noEmptySpaceOnTheBeginning = Validators.pattern((/^(?! +$)/));
export const noEmptySpace = Validators.pattern((/^\w+( +\w+)*$/));
export const uppercase = Validators.pattern(`^[A-Z][A-Z]`);

export function contractDurationLessThanEndOfSupportDate(contractStartDate: string, endOfSupport: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!!!endOfSupport) {
      return null;
    }

    const dateContractStartDate = new Date(contractStartDate);
    const dateEndOfSupport = new Date(endOfSupport);
    const contractEndDate = new Date(dateContractStartDate.setFullYear(dateContractStartDate.getFullYear() + +control.value));
    const dateDifference = dateEndOfSupport.getTime() - contractEndDate.getTime();
    return dateDifference >= 0
      ? null : {wrongTerm: control.value};
  };
}

export function addressValidation(address: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!address) {
      return null;
    }
    if (!address.address1 || !address.city || !address.state || !address.zipCode) {
      return {
        addressValidation: control.value,
      };
    }
  };
}

export function contractDateValidation(contractStartDate: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (DateUtils.countDaysFromToday(contractStartDate) < 8) {
      return {contractDateValidation: control.value};
    }
    return null;
  };
}

export function valueHasAtLeastOneLetter(abstractControl: AbstractControl): {[key: string]: any} | null {
  if (MathUtils.checkIfNumeric(abstractControl.value)) {
    return {valueHasAtLeastOneLetter: abstractControl.value};
  }
  return null;
}

export function zipCodeValidation(regex: RegExp, validatorName: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (!control.value) {
      return null;
    }

    if (!regex.test(control.value)) {
      return {[validatorName]: control.value};
    }

    return null;
  }
}
