<div class="product-page" [ngClass]="'default-store'">
  <div class="loader__overlay" *ngIf="!productInfoLoaded; else body">
    <div class="loader__content">
      <div class="loading-animation"></div>
    </div>
  </div>
  <ng-template #body>
    <h1 class="product__title mobile" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
    <ng-container *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && isProductDiscontinued()">
      <ng-container *ngIf="areSubsequentMaterialsAvailable(); else noSubsequentMaterial">
        <div class="notification" [attr.data-test]="'warning-discontinued-part-with-sub'">
          <div class="notification__header">
            <span class="icon-warning icon-warning__circle"></span>
            <h5 class="notification__title">
              {{ 'app-product-page.discontinued-part-with-subsequent-material-1' | translate }}
              (<span *ngFor="let subsequentMaterial of getSubsequentMaterials(); let last = last">
                <a [routerLink]="['/product/', 'A_' + subsequentMaterial]">{{ subsequentMaterial }}</a>
                <span *ngIf="!last">, </span>
              </span>){{ 'app-product-page.discontinued-part-with-subsequent-material-2' | translate }}
            </h5>
          </div>
        </div>
      </ng-container>
      <ng-template #noSubsequentMaterial>
        <div class="notification" [attr.data-test]="'warning-discontinued-part'">
          <div class="notification__header">
            <span class="icon-warning icon-warning__circle"></span>
            <h5 class="notification__title">{{ 'app-product-page.discontinued-part' | translate }}</h5>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="row justify-content-center">
      <div class="col-xl-7 product-image__wrapper no-padding">
        <div [appLoadingAnimation]="!productInfoLoaded">
          <div class="carousel-main row">
            <div class="col-sm-12 col-md-2 product-image__thumbnails">
              <div
                  class="image__thumbnail"
                  *ngFor="let small of smallUrls; let indexOfElement = index;"
                  (click)="setLargeUrl(indexOfElement)">
                <img [src]="small" [alt]="productInfoLoaded ? abstractProduct.name : ''"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-10 product-image__main">
              <img [src]="mainPicture"
                   (click)="openZoomPicture()"
                   class="image__main"
                   [alt]="productInfoLoaded ? abstractProduct.name : ''"
                   [attr.data-test]="'img-productImage'"/>
              <app-access-control>
                <ng-container ngProjectAs="[slot][content]">
                  <app-sticker
                      [product]="abstractProduct"
                      [labels]="labels"
                      [prices]="[defaultPrice, secondPrice]">
                  </app-sticker>
                </ng-container>
              </app-access-control>
            </div>
          </div>
          <ng-container *ngIf="openZoomedPicture">
            <div class="modal-dialog is-open"
                 tabindex="-1"
                 id="modal-zoom-picture"
                 role="dialog"
                 aria-modal="true"
                 aria-labelledby="modal-confirm-decline_title"
                 aria-describedby="modal-confirm-decline_desc"
                 aria-hidden="true"
                 data-linked="#modal-zoom-picture" (click)="openZoomPicture()">
              <div class="modal-dialog__container" role="document">
                <img [src]="mainPicture"
                     style="cursor: zoom-out;"
                     [alt]="abstractProduct.name"/>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-xl-3">
        <ng-container *ngIf="productInfoLoaded">
          <app-access-control [notForViewer]="true">
            <ng-container ngProjectAs="[slot][nonViewerContent]">
              <app-add-to-wishlist
                  [wishlists]="wishlists"
                  [sku]="concreteSku"
                  [productName]="abstractProduct.name"
                  [productKeywords]="abstractProduct.attributes.mcm_keywords"
                  [addNewModalActive]="addNewModalActive"
                  [textNextToIcon]="true"
                  (createNew)="addToWishlist()">
              </app-add-to-wishlist>
            </ng-container>
          </app-access-control>
          <h1 class="product__title desktop" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
          <p [innerHTML]="abstractProduct.description" [attr.data-test]="'desc-productDescription'" class="basic-product-link"></p>
          <app-access-control>
            <ng-container ngProjectAs="[slot][content]">
              <ng-container
                  *ngIf="productInfoLoaded && defaultPrice && (priceUtils.getPriceValue(defaultPrice) | shouldDisplayPrice)">
                <div class="price_details">
                  <h4 id="product__description__price" [attr.data-test]="'title-productPrice'">
                    {{ priceUtils.getPriceValue(defaultPrice) | currency: priceUtils.getCurrencyCode(defaultPrice) }}
                    <ng-container *ngIf="secondPrice">
                      <small class="product__description__price-alternative"
                             [attr.data-test]="'desc-productPriceAlternative'">
                        {{ priceUtils.getPriceValue(secondPrice) | currency: priceUtils.getCurrencyCode(secondPrice) }}
                      </small>
                    </ng-container>
                  </h4>
                  <span *ngIf="!shouldShowAvailabilityDetails()"
                        class="ex-gst-container">({{ 'app-product-page.ex_gst' | translate }})</span>
                </div>
              </ng-container>
              <app-access-control [notForViewer]="true">
                <ng-container ngProjectAs="[slot][nonViewerContent]">
                  <ng-container>
                    <form class="button-container" (submit)="addProductToCart()">
                      <label id="label__quantity">
                        <input name="productQuantity"
                               type="number"
                               class="spinbox-quantity"
                               min="1"
                               step="1"
                               [(ngModel)]="productQuantity"
                               required
                               [disabled]="isAddToCartInProgress$ | async"
                               (change)="changeProductQuantity(productQuantity)"/>
                      </label>
                      <div *ngIf="isAddToCartInProgress$ | async; else elseBlock" class="loader__content">
                        <div class="loading-animation"></div>
                      </div>
                      <ng-template #elseBlock>
                        <button class="button button--primary icon-basket button--cart icon__mobile"
                                [disabled]="!isQuantityValid || loadingCartDataInProgress"
                                [attr.data-test]="'button-addToCart'">
                          {{ 'app-product-page.add-to-cart' | translate }}
                        </button>
                      </ng-template>
                      <ng-container *ngIf="!isQuantityValid">
                        <small class="textfield__hint">{{ 'app-product-page.enter-quantity' | translate }}</small>
                      </ng-container>
                    </form>
                  </ng-container>
                </ng-container>
              </app-access-control>
              <div *ngIf="priceUtils.getPriceValue(defaultPrice) | shouldDisplayPrice">
                <p class="product-availability">
                  <ng-container *ngIf="showPriceDisclaimer; else availabilityDetails">
                    {{ 'shop-cart.excludes-vat' | translate }}<br/>
                    {{ 'shop-cart.pricing-validation' | translate }}
                  </ng-container>
                  <ng-template #availabilityDetails>
                    <ng-container *ngIf="shouldShowAvailabilityDetails()">
                      <span *ngIf="abstractProduct.attributes.delivery_availability"
                            [attr.data-test]="'desc-productAvailability'">
                        {{ abstractProduct.attributes.delivery_availability }}
                      </span><br/>
                      <span *ngIf="abstractProduct.attributes.delivery_time"
                            [attr.data-test]="'desc-productDeliveryTime'">
                        {{ 'shop-cart.estimatedArrivalLabel' | translate }}{{ abstractProduct.attributes.delivery_time }}
                      </span>
                    </ng-container>
                  </ng-template>
                </p>
              </div>
            </ng-container>
          </app-access-control>
        </ng-container>
      </div>
      <div class="col-xl-10 product-details-wrapper">
        <div class="product-details">
          <ng-container *ngIf="productInfoLoaded">
            <div class="accordion" role="presentation">
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.PRODUCT_ORIGIN)"
                  (panelOpened)="tracking(productDetailsEnum.PRODUCT_ORIGIN.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-product-origin-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-productOrigin'">
                    {{ 'app-product-page.product-origin' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <ng-container *ngIf="abstractProduct?.attributeNames">
                      <div class="row accordion__panel__text">
                        <p class="accordion__panel__text__category col-4 col-lg-2">{{ abstractProduct.attributeNames.mcm_manufacturer }}</p>
                        <p class="accordion__panel__text__category col-8 col-lg-10"
                           [innerHTML]="abstractProduct.attributes.mcm_manufacturer"></p>
                        <ng-container *ngIf="!isInStore">
                          <p class="accordion__panel__text__category col-4 col-lg-2">{{ abstractProduct.attributeNames.mcm_packer }}</p>
                          <p class="accordion__panel__text__category col-8 col-lg-10"
                             [innerHTML]="abstractProduct.attributes.mcm_packer"></p>
                        </ng-container>
                        <p class="accordion__panel__text__category col-4 col-lg-2">{{ abstractProduct.attributeNames.mcm_importer }}</p>
                        <p class="accordion__panel__text__category col-8 col-lg-10"
                           [innerHTML]="abstractProduct.attributes.mcm_importer"></p>
                        <p class="accordion__panel__text__category col-4 col-lg-2">{{ abstractProduct.attributeNames.sap_country_of_origin }}</p>
                        <p class="accordion__panel__text__category col-8 col-lg-10"
                           [innerHTML]="abstractProduct.attributes.sap_country_of_origin"></p>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.OVERVIEW)"
                             (panelOpened)="tracking(productDetailsEnum.OVERVIEW.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-overview-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-overview'">
                    {{ 'app-product-page.overview' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <p class="accordion__panel__text" [attr.data-test]="'desc-overview'"
                       [innerHTML]="abstractProduct.description"></p>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.DIMENSIONS)"
                  (panelOpened)="tracking(productDetailsEnum.DIMENSIONS.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 (click)="tracking(productDetailsEnum.DIMENSIONS.toString())" id="panel-dimensions-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-dimensions'">
                    {{ 'app-product-page.dimensions' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <ng-container *ngIf="abstractProduct?.attributeNames">
                      <div class="row accordion__panel__text">
                        <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label_length'">
                          {{ abstractProduct.attributeNames.sap_length }}
                        </p>
                        <div class="col-8 col-lg-10">
                          <p class="accordion__panel__text__category spec accordion__panel__dimension"
                             [attr.data-test]="'desc-length'">
                            <ng-container *ngIf="abstractProduct.attributes?.sap_length; else elseBlock">
                              {{ abstractProduct.attributes.sap_length }} cm
                            </ng-container>
                            <ng-template #elseBlock>
                              -
                            </ng-template>
                          </p>
                        </div>
                        <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-height'">
                          {{ abstractProduct.attributeNames.sap_height }}
                        </p>
                        <div class="col-8 col-lg-10">
                          <p class="accordion__panel__text__category spec accordion__panel__dimension"
                             [attr.data-test]="'desc-height'">
                            <ng-container *ngIf="abstractProduct.attributes?.sap_height; else elseBlock">
                              {{ abstractProduct.attributes.sap_height }} cm
                            </ng-container>
                            <ng-template #elseBlock>
                              -
                            </ng-template>
                          </p>
                        </div>
                        <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-width'">
                          {{ abstractProduct.attributeNames.sap_width }}
                        </p>
                        <div class="col-8 col-lg-10">
                          <p class="accordion__panel__text__category spec accordion__panel__dimension"
                             [attr.data-test]="'desc-width'">
                            <ng-container *ngIf="abstractProduct.attributes?.sap_width; else elseBlock">
                              {{ abstractProduct.attributes.sap_width }} cm
                            </ng-container>
                            <ng-template #elseBlock>
                              -
                            </ng-template>
                          </p>
                        </div>
                        <p class="accordion__panel__text__category col-4 col-lg-2" [attr.data-test]="'label-weight'">
                          {{ abstractProduct.attributeNames.sap_gross_weight }}
                        </p>
                        <div class="col-8 col-lg-10">
                          <p class="accordion__panel__text__category spec accordion__panel__dimension"
                             [attr.data-test]="'desc-weight'">
                            <ng-container *ngIf="abstractProduct.attributes?.sap_gross_weight; else elseBlock">
                              {{ abstractProduct.attributes.sap_gross_weight }} kg
                            </ng-container>
                            <ng-template #elseBlock>
                              -
                            </ng-template>
                          </p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.DETAILS)"
                             (panelOpened)="tracking(productDetailsEnum.DETAILS.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-details-button"
                      class="accordion__title icon-arrow-down"
                      aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-details'">
                    {{ 'app-product-page.details' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <ng-container
                        *ngIf="abstractProduct?.attributeNames">
                      <div class="row">
                        <div class="accordion__panel__text col-4 col-xl-2">
                          <p class="accordion__panel__text__category" [attr.data-test]="'label-materialNumber'">
                            {{ abstractProduct.attributeNames.material_number }}
                          </p>
                          <p class="accordion__panel__text__category" [attr.data-test]="'label-materialType'">
                            {{ abstractProduct.attributeNames.Material_Type }}
                          </p>
                        </div>
                        <div class="accordion__panel__text col-8 col-xl-10">
                          <p class="accordion__panel__text__category spec" [attr.data-test]="'desc-materialNumber'">
                            {{ abstractProduct.attributes.material_number }}
                          </p>
                          <p class="accordion__panel__text__category spec" [attr.data-test]="'desc-materialType'">
                            {{ abstractProduct.attributes.Material_Type }}
                          </p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.SUITABLE_FOR)
                    && installBaseProducts
                    && installBaseProducts.length > 0"
                  (panelOpened)="tracking(productDetailsEnum.SUITABLE_FOR.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-suitable-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-suitableFor'">
                    {{ 'app-product-page.suitable-for' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <div class="accordion__panel__text install-base-pointer">
                      <ng-container *ngFor="let installedBase of installBaseProducts">
                        <p>
                          <a [routerLink]="['/installed-base']"
                             [queryParams]="{'rel-product-sysivk': installedBase.attributes.materialNumber}"
                             (click)="trackInstalledBase(installedBase)"
                             [attr.data-test]="'desc-' + installedBase.attributes.nameEnUs">
                            {{ installedBase.attributes.nameEnUs }}
                          </a>
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </app-accordion>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
