import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICategoryNode, ICurrentParams, IPartsCategory } from '../../models/catalog.models';
import { CategoryUtils } from '../../utils/category.utils';
import { CatalogFacade } from '../../facades/catalog.facade';
import { ObjectUtils } from '../../utils/object.utils';
import { AppUtils } from '../../utils/app.utils';
import { EFeatureToggles, EStoreTypes } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss'],
})
export class CategoryTreeComponent implements OnInit, OnChanges, OnDestroy {
  loading: boolean = true;
  categoryTree: ICategoryNode[];
  currentCategories: any[] = [];
  isLoaded: boolean = false;
  visibleCategories: string[] = [];

  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isCpqEnabled: boolean = false;
  isSparePartsEnabled: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() params: ICurrentParams;
  @Input() fetchedCategories: Array<any>;
  @Input() showLoader: boolean = false;
  @Input() useNavigate: boolean;

  constructor(
    private catalogFacade: CatalogFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.selectUsFeatures();

    this.selectIsLoadingCategoriesSubscription();
    this.selectIsCategoriesLoaded();
    this.selectCategoriesSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method to select US features
   */
  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => this.isCpqEnabled = value.toString().toLowerCase() === 'true');

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => this.isSparePartsEnabled = value.toString().toLowerCase() === 'true');
  }

  /**
   * Method to select isLoading categories
   */
  selectIsLoadingCategoriesSubscription(): void {
    this.catalogFacade.selectIsCategoriesLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loading => this.loading = loading);
  }

  /**
   * Method to select categories is loaded
   */
  selectIsCategoriesLoaded(): void {
    this.catalogFacade.selectIsCategoriesLoaded().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isLoaded => this.isLoaded = isLoaded);
  }

  /**
   * Method to select categories
   */
  selectCategoriesSubscription(): void {
    this.catalogFacade.selectCategories().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(categories => {
      this.categoryTree = categories;
      if (!this.categoryTree) {
        this.catalogFacade.loadCategories();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fetchedCategories?.currentValue) {
      const categories = ObjectUtils.deepClone(this.categoryTree);
      this.currentCategories = [];
      categories.forEach(category => {
        const parentCategory = this.fetchedCategories.find(fetchedCategory => fetchedCategory.value === category.nodeId);
        if (parentCategory && parentCategory.doc_count > 0) {
          const filteredCategory: IPartsCategory[] = CategoryUtils.filterCategoryChildrens(
            [category],
            this.fetchedCategories,
            this.params.category,
          );
          this.currentCategories.push(...filteredCategory);
        }
      });

      if ((this.isUsStore || this.isCaStore) && (!this.isSparePartsEnabled || !this.isCpqEnabled)) {
        this.currentCategories = this.filterCategoriesByUsFeatures(this.currentCategories);
      }

      this.currentCategories.forEach(parentCategory => {
        const parentCategoryActive: boolean = CategoryUtils.isParentCategoryActive([parentCategory]);

        if (parentCategoryActive && !this.visibleCategories.includes(parentCategory.name)) {
          this.toggleCategory(parentCategory);
        }
      })
    }
  }

  /**
   * Method to filter categories by features
   * @param {any[]} categories
   * @returns {any[]}
   */
  filterCategoriesByUsFeatures(categories: any[]): any[] {
    let hiddenCategories: string[] = [];

    if (!this.isCpqEnabled) {
      hiddenCategories.push('service contracts');
    }

    if (!this.isSparePartsEnabled) {
      hiddenCategories.push('parts');
    }

    return categories.filter(category => !hiddenCategories.includes(category.name.toLocaleLowerCase()));
  }

  /**
   * Method to show categories
   * @returns {boolean}
   */
  showCategories(): boolean {
    return !this.loading && this.isLoaded && this.currentCategories.length > 0;
  }

  /**
   * Method to show categories loader
   * @returns {boolean}
   */
  showCategoriesLoader(): boolean {
    return this.loading || this.showLoader;
  }

  /**
   * Method to toggle category
   * @param {ICategoryNode} cat
   */
  toggleCategory(cat: ICategoryNode): void {
    const exists: number = this.visibleCategories.findIndex((tab) => tab === cat.name);
    if (exists !== -1) {
      this.visibleCategories.splice(exists, 1);
    } else {
      this.visibleCategories.push(cat.name);
    }
  }
}
