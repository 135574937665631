<form *ngIf="pocForm" [formGroup]="pocForm" class="delivery-instructions-form">
  <app-modal 
      title="{{ 'checkout-delivery-details.delivery-instructions' | translate }}" 
      [open]="showModal" 
      [id]="'ddPOCModal'"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="form-row">
        <div class="width-100">
          <app-textfield
              label="{{ 'checkout-delivery-details.attention-to' | translate: {maxNumberOfChars: maxLengthAttentionTo} }}"
              [form]="pocForm"
              [modelValue]="pocForm.value.attentionTo" 
              [inputName]="'attentionTo'"
              [maxLength]="maxLengthAttentionTo"
              [ngClass]="{'is-invalid': pocForm.get('attentionTo').touched && pocForm.get('attentionTo').errors}"
              (fieldFilled)="setFormValue($event)"
              [readOnly]="isSaturdayShipment">
          </app-textfield>
          <div class="alert-controls textarea-alert" *ngIf="pocForm.value.attentionTo">
            <small class="alert-text">
              {{ pocForm.value.attentionTo.length }}/{{ maxLengthAttentionTo }}
            </small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="textfield-with-controls width-50">
          <app-textfield 
              label="{{ 'checkout-delivery-details.select-first-name' | translate }}"
              [ngClass]="{'is-invalid': pocForm.get('firstName').touched && pocForm.get('firstName').errors}"
              [form]="pocForm" 
              [modelValue]="pocForm.value.firstName"
              [inputName]="'firstName'" 
              [isRequired]="true" 
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
          <div class="alert-controls" *ngIf="pocForm.get('firstName').touched">
            <ng-container *ngIf="pocForm.get('firstName').hasError('maxlength')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
              </small>
            </ng-container>
            <ng-container *ngIf="pocForm.get('firstName').hasError('pattern')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.only-letters' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
        <div class="textfield-with-controls width-50">
          <app-textfield 
              label="{{ 'checkout-delivery-details.select-last-name' | translate }}"
              [ngClass]="{'is-invalid': pocForm.get('lastName').touched && pocForm.get('lastName').errors}"
              [form]="pocForm" 
              [modelValue]="pocForm.value.lastName"
              [inputName]="'lastName'" 
              [isRequired]="true" 
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
          <div class="alert-controls" *ngIf="pocForm.get('lastName').touched">
            <ng-container *ngIf="pocForm.get('lastName').hasError('maxlength')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}
              </small>
            </ng-container>
            <ng-container *ngIf="pocForm.get('lastName').hasError('pattern')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.only-letters' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="textfield-with-controls width-100">
          <app-textfield
              label="{{ 'request-details.input-email' | translate }}"
              [ngClass]="{'is-invalid': pocForm.get('email').touched && pocForm.get('email').errors}"
              [inputType]="'email'"
              [form]="pocForm"
              [modelValue]="pocForm.value.email"
              [inputName]="'email'"
              [isRequired]="true"
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
          <div class="alert-controls" *ngIf="pocForm.get('email').touched">
            <ng-container *ngIf="pocForm.get('email').hasError('email')">
              <small class="alert-text">
                {{ 'request-details.email-pattern' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="textfield-with-controls width-50">
          <app-textfield
              label="{{ 'checkout-delivery-details.select-phone-number' | translate }}"
              [ngClass]="{'is-invalid': pocForm.get('phone').touched && pocForm.get('phone').errors}"
              [form]="pocForm"
              [modelValue]="pocForm.value.phone"
              [inputName]="'phone'"
              [isRequired]="true"
              trim
              [multiCharToTrim]="['-']"
              trimWhitespaces
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
          <div class="alert-controls" *ngIf="pocForm.get('phone').touched">
            <ng-container *ngIf="pocForm.get('phone').hasError('maxlength')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}
              </small>
            </ng-container>
            <ng-container *ngIf="pocForm.get('phone').hasError('pattern')">
              <small class="alert-text">
                {{ 'checkout-delivery-details.only-numbers' | translate }}
              </small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="textarea-with-controls width-100">
          <app-textarea
              label="{{ 'checkout-delivery-details.special-shipping-instructions' | translate }}"
              [ngClass]="{'is-invalid': pocForm.get('comment').touched && pocForm.get('comment').errors}"
              [cssStyles]="{wrapper: 'text-area-comment'}"
              [form]="pocForm"
              [modelValue]="pocForm.value.comment"
              [rows]="5"
              [inputName]="'comment'"
              [maxLength]="maxLengthComment"
              trim
              trimWhitespaces
              (areaFilled)="setFormValue($event)"
              [readOnly]="isSaturdayShipment">
          </app-textarea>
          <div class="alert-controls textarea-alert" *ngIf="pocForm.value.comment">
            <small class="alert-text">
              {{ pocForm.value.comment.length }}/{{ maxLengthComment }}
            </small>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button type="cancel" 
          class="button" 
          (click)="resetForm()" 
          data-dismiss="modal-dialog">
        {{ 'order-approve.cancel-modal' | translate }}
      </button>
      <button type="submit" 
          *ngIf="!updatePOCDataIsInProgress; else loadingSpinner"
          class="button button--primary" 
          [disabled]="!formIsValid() || pocForm.pristine"
          (click)="updatePOCData()" 
          data-dismiss="modal-dialog">
        {{ 'order-approve.save-changes-modal' | translate }}
      </button>
      <ng-template #loadingSpinner>
        <div class="spinner">
          <div class="loading-animation"></div>
        </div>
      </ng-template>
    </ng-container>
  </app-modal>
</form>
