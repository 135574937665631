import { createAction, props } from '@ngrx/store';
import {ICartPayload} from "../models/cart.models";

export const loadCartItems = createAction(
  '[Order-Approve] Load Cart Items',
  props<{ cartId: string }>()
);

export const loadCartItemsSuccess = createAction(
  '[Order-Approve] Load Cart Items Success',
  props<{ cartItems: ICartPayload }>()
);
export const loadCartItemsFail = createAction(
  '[Order-Approve] Load Cart Items Fail',
  props<{ category: string, message: string, action: any }>()
);
export const loadCartItemsReset = createAction(
    '[Order-Approve] Reset cart items state'
);

export const getCheckoutUpdateData = createAction(
  '[Order-Approve] Load Checkout Update Data',
  props<{ cartId: string }>()
);

export const PutCartId = createAction(
  '[Order-Approve] - Post Cart Data',
  props<{ cartId: string }>());

export const getCheckoutUpdateDataSuccess = createAction(
  '[Order-Approve] Load Checkout Update Data Success',
  props<{ checkoutUpdateData: any }>()
);

export const deleteItemFromCart = createAction(
  '[Order-Approve] Delete Item from Cart',
  props<{ cartId: string, itemId: string }>()
);
export const deleteItemFromCartSuccess = createAction(
  '[Order-Approve] Delete Item from Cart Success',
  props<{ latestDeletedItemFromCart: any }>()
);
export const deleteItemFromCartFail = createAction(
  '[Order-Approve] Delete Item from Cart Fail',
  props<{ error: string }>()
);

export const setAddVoucherToCartData = createAction(
  '[Order-Approve] Set Data For Add Voucher',
  props<{ voucherCode: string }>()
);

export const addVoucherToCart = createAction(
  '[Order-Approve] Add Voucher to Cart',
  props<{ cartId: string, voucherCode: string, voucherData: any }>()
);
export const addVoucherToCartSuccess = createAction(
  '[Order-Approve] Add Voucher to Cart Success',
  props<{ latestAddedVoucher: string }>()
);
export const addVoucherToCartFail = createAction(
  '[Order-Approve] Add Voucher to Cart Fail',
  props<{ error: string }>()
);
export const removeVoucherFromCart = createAction(
  '[Order-Approve] Remove Voucher from Cart',
  props<{ cartId: string, voucherCode: string }>()
);
export const removeVoucherFromCartSuccess = createAction(
  '[Order-Approve] Remove Voucher from Cart Success',
  props<{ latestRemovedVoucher: any}>()
);
export const removeVoucherFromCartFail = createAction(
  '[Order-Approve] Remove Voucher from Cart Fail',
  props<{ error: string }>()
);
export const postCheckoutData = createAction(
  '[Order-Approve] Post Checkout Data',
  props<{ postCheckoutData: any }>()
);
export const setPostCheckoutItems = createAction(
  '[Order-Approve] Set Post Checkout Items',
  props<{ items: any }>()
);
export const setUserCartIdDuringCheckout = createAction(
  '[Order-Approve] Set Cart Id of User that was placed order',
  props<{ cartId: string }>()
);
export const postCheckoutDataSuccess = createAction(
  '[Order-Approve] Post Checkout Data',
  props<{ postCheckoutDataResponse: any }>()
);
export const setShippingAddress = createAction(
  '[Order-Approve] Set Shipping Address',
  props<{ address: any }>()
);
export const getOrderDetailsSuccess = createAction(
  '[Order-Approve] Get Order Details - Success',
  props<{ orderDetails: any }>()
);

export const successSummaryPageClearData = createAction(
    '[Order-Approve] Clear last order data'
);
