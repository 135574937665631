<div *ngIf="isNotification()" class="notification-container">
  <div *ngFor="let notification of notifications"
       class="notification"
       [ngClass]="'notification--'+notification.type">
    <div class="notification__header">
      <i aria-hidden="true" class="icon" [ngClass]="getIcon(notification)"></i>
      <h5 class="notification__title" [attr.data-test]="'title-notification-window'">{{ notification.title | translate }}</h5>
      <button
          type="button"
          class="notification__close"
          aria-label="Close notification"
          (click)="closeAction(notification.id)"
          [attr.data-test]="'button-close-notification-window'">
        <i aria-hidden="true" class="icon-close notification-icon-close"></i>
      </button>
    </div>
    <div class="notification__message" *ngIf="notification?.messages">
    <span [ngClass]="message.css" *ngFor="let message of notification.messages">
      {{ message.key | translate: message.params }}
    </span>
    </div>
    <div class="notification__actions" *ngIf="notification?.actions">
      <button
          *ngFor="let action of notification.actions"
          type="button"
          class="button"
          [ngClass]="action.css"
          (click)="handleActions(action.type)">
        {{ action.label | translate }}
      </button>
    </div>
  </div>
</div>
