import { environment as stores } from './environment.stores';
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...stores,
  ...defaultEnvironment,
  name: 'clickdummy',
  production: true,
  glueApiUrl: [
    {store: 'AU', url: 'https://shop.click-solutions.com/directus'},
    {store: 'BR', url: 'https://shop.click-solutions.com/directus'},
    {store: 'CA', url: 'https://shop.click-solutions.com/directus'},
    {store: 'GB', url: 'https://shop.click-solutions.com/directus'},
    {store: 'IN', url: 'https://shop.click-solutions.com/directus'},
    {store: 'US', url: 'https://shop.click-solutions.com/directus'},
    {store: 'JP', url: 'https://shop.click-solutions.com/directus'}
  ],
  arakhUrl: '/assets/clickdummy',
  features: {
    // feature names are defined in EFeatures enum
    quoteRequest: ['GB', 'IN', 'JP', 'AU'],
    exportCart: ['BR', 'IN', 'US', 'AU', 'CA'],
    cpq: ['US'],
    spareParts: ['US', 'CA'],
    myProfile: ['US', 'CA'],
    multiCart: ['US', 'JP', 'AU', 'CA'],
    excludeTax: ['AU'],
    shopByCategory: ['US', 'AU', 'JP', 'GB', 'CA', 'BR', 'IN'],
  },
};
