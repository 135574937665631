import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconUtils } from '../../../utils/icon.utils';

@Component({
  selector: 'app-previous-quotes-list',
  templateUrl: './previous-quotes-list.component.html',
  styleUrls: ['./previous-quotes-list.component.scss'],
})
export class PreviousQuotesListComponent {
  tabsOpened: string[] = [] as Array<string>;
  @Input() loading: boolean;
  @Input() orders: any;
  @Input() roleAccess: boolean;
  @Input() searchValue: string;
  @Output() refreshOrdersEmitter: EventEmitter<any> = new EventEmitter<any>();

  toggleTab(id: string): void {
    const exists: number = this.tabsOpened.findIndex(tab => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getIcon(status: string): string {
    return IconUtils.getStatusIcon(status);
  }
}
