import { Component, Input } from '@angular/core';
import { functionalLocationTable } from '../configuration/configuration';
import { IOrder } from '../../../../models/order.models';

@Component({
  selector: 'app-service-additional-details',
  templateUrl: './service-additional-details.component.html',
  styleUrls: ['./service-additional-details.component.scss']
})
export class ServiceAdditionalDetailsComponent {
  functionalLocationTemplate = functionalLocationTable;
  @Input() additionalData: IOrder;
  @Input() flData: any[];
}
