import { Injectable, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UrlTree } from '@angular/router';

import { Subscription } from 'rxjs';

import { LinkDefinition } from '../configurations/seo';

@Injectable({
  providedIn: 'root',
})
export class LinkService implements OnDestroy {

  private routeListener: Subscription;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document) {
  }

  /**
   * Set Canonical Link to Head
   */
  setCanonicalLink(tree: UrlTree): void {
    const href = this.parseUrlTree(tree);
    this.updateTag({
      rel: 'canonical',
      href,
    });
  }

  setCanonicalLinkFromEndpoint(canonical: LinkDefinition): void {
    this.updateTag(canonical);
  }

  setAlternateLink(tree: UrlTree, hreflang: string): void {
    const href = this.parseUrlTree(tree, hreflang);
    this.updateTag({
      rel: 'alternate',
      href,
      hreflang
    }, true);
  }

  private parseUrlTree(tree: UrlTree, alternateLangSegment?: string): string {
    let url = '';
    if (tree.root.hasChildren()) {
      const segments = tree.root.children.primary.segments;
      if (segments && segments.length > 0) {
        if (alternateLangSegment) {
          segments[0].path = alternateLangSegment;
        }
        url = segments.map(segment => segment.path).join('/');
        if (url.endsWith('.')) {
          url = url.slice(0, url.length - 1);
        }
      }
    }
    return url.length > 0 ? `${window.location.origin}/${url}` : window.location.origin;
  }

  /**
   * Create or update a link tag
   */
  public updateTag(tag: LinkDefinition, withLang?: boolean): void {
    let selector: string;
    if (withLang && tag.hreflang !== '') {
      selector = this._parseSelector(tag, tag.hreflang);
    } else {
      selector = this._parseSelector(tag);
    }
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement
      || this.document.head.appendChild(this.document.createElement('link'));
    if (linkElement) {
      Object.keys(tag).forEach((prop: string) => {
        linkElement[prop] = tag[prop];
      });
    }
  }

  /**
   * Remove a link tag from DOM
   */
  public removeTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement;
    if (linkElement) {
      this.document.head.removeChild(linkElement);
    }
  }

  /**
   * Get link tag
   */
  public getTag(tag: LinkDefinition): HTMLLinkElement {
    const selector = this._parseSelector(tag);
    return this.document.head.querySelector(selector);
  }

  /**
   * Get all link tags
   */
  public getTags(): NodeListOf<HTMLLinkElement> {
    return this.document.head.querySelectorAll('link');
  }

  /**
   * Parse tag to create a selector
   */
  private _parseSelector(tag: LinkDefinition, withLang?: string): string {
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    if (withLang) {
      return `link[${attr}="${tag[attr]}"][hreflang="${withLang}"]`;
    }
    return `link[${attr}="${tag[attr]}"]`;
  }

  /**
   * Destroy route listener when service is destroyed
   */
  ngOnDestroy(): void {
    this.routeListener.unsubscribe();
  }
}
