import { SeoService } from '../services';
import { Injectable, Injector } from '@angular/core';
import { BreadCrumb } from '../models/common.models';
import { ISeoPageData } from '../configurations/seo';
import { StructuredDataService } from '../services/structured-data.service';
import { IAbstractProduct } from '../models/abstract-product.models';
import { ICmsPageAttributes } from '../models/cms';

@Injectable({
  providedIn: 'root',
})
export class SeoFacade {
  private pSeoService: SeoService;
  private pStructuredDataService: StructuredDataService;

  public get seoService(): SeoService {
    if (!this.pSeoService) {
      this.pSeoService = this.injector.get(SeoService);
    }
    return this.pSeoService;
  }

  public get structuredData(): StructuredDataService {
    if (!this.pStructuredDataService) {
      this.pStructuredDataService = this.injector.get(StructuredDataService);
    }
    return this.pStructuredDataService;
  }

  constructor(private injector: Injector) {
  }

  setSeoForPage(): void {
    this.seoService.startRouteListener();
  }

  handleTitleFromHeading(): void {
    this.seoService.handleTitleFromHeading();
  }

  handleTitleFromBreadcrumb(breadcrumbs: BreadCrumb[]): void {
    this.seoService.handleTitleFromBreadcrumb(breadcrumbs);
  }

  setMetaFromComponent(seoPageData: ISeoPageData): void {
    this.seoService.setMetaFromComponent(seoPageData);
  }

  removeStructuredData(className: string): void {
    this.structuredData.removeStructuredData(className);
  }

  handleProductSchema(product: IAbstractProduct, images: string[]): void {
    this.structuredData.handleProductSchema(product, images);
  }

  parseRobots(data: ICmsPageAttributes): string[] {
    return this.seoService.parseRobots(data);
  }

}


