<div class="configuration-summary__table">
  <button
      (click)="menageTabs(tabsOpened.length)">
    {{openedTabsIndex === products.length ? 'Collapse all' : 'View all'}}
  </button>
  <div class="accordion">
    <app-accordion
        *ngFor="let product of products"
        [manualOpen]="tabsOpened.includes(product.name)">
      <ng-container ngProjectAs="[slot][header]">
        <h2
            class="accordion__title icon-arrow-down"
            aria-controls="panel1"
            aria-expanded="false"
            (click)="toggleTab(product.name)">
          {{ product.name }}
        </h2>
      </ng-container>
      <ng-container ngProjectAs="[slot][panel]">
        <div class="container accordion__content">
          <ng-container *ngFor="let item of product.details">
            <div class="details">
              <span>{{ item.name }}</span>
              <span class="dotted-line"></span>
              <ng-container *ngIf="item.price else onlyIcon">
                <span>{{ item.price | currency: undefined: undefined: 0 }}</span>
              </ng-container>
              <ng-template #onlyIcon>
                <span class="icon-check icon-check__circle"></span>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </app-accordion>
  </div>
</div>
