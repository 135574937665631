import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-system-preview',
  templateUrl: './system-preview.component.html',
  styleUrls: ['./system-preview.component.scss'],
})
export class SystemPreviewComponent {
  nickname: string;

  @Input() item: any;
  @Input() cartId: string;
  @Input() currency: string;
  @Output() itemDeleted = new EventEmitter<any>();

  constructor(
    private router: Router,
  ) {
  }

  goToEditConfiguration(): void {
    this.router.navigate(
      ['/service-configuration'],
      {
        queryParams: {
          'fl-number': this.item.attributes.systemDetails.siemensEquipmentId,
          'rel-product-sysivk': this.item.attributes.systemDetails.materialNumber,
          selectedService: this.item.attributes.abstractSku,
          configId: this.item.attributes.cpqConfigId
        },
      }).then();
  }

  goToConfigurationSummary(): void {
    this.router.navigate(
      ['/configuration-summary'],
      {
        queryParams: {
          'fl-number': this.item.attributes.systemDetails.siemensEquipmentId,
          'rel-product-sysivk': this.item.attributes.systemDetails.materialNumber,
          selectedService: this.item.attributes.abstractSku,
          configId: this.item.attributes.cpqConfigId
        },
      }).then();
  }

  deleteConfiguration(): void {
    this.itemDeleted.emit({sku: this.item.attributes.sku, configId: this.item.attributes.cpqConfigId});
  }
}
