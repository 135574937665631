import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as AppActions from '../actions/app.actions';
import { IIndexedNotification } from '../models/cpq.models';

export interface AppState {
  alert: any;
  notifications: IIndexedNotification[];
  fullPageLoaderList: Array<string>;
}

// Initialize State start
export const initialState: AppState = {
  alert: null,
  notifications: [],
  fullPageLoaderList: [],
};
// Initialize State end

// Selectors start
const getAppFeatureState = createFeatureSelector<AppState>('app');

export const getAlert = createSelector(
  getAppFeatureState,
  state => state.alert,
);

export const getNotification = createSelector(
  getAppFeatureState,
  state => state.notifications,
);

export const selectFullPageLoaderList = createSelector(
  getAppFeatureState,
  state => state.fullPageLoaderList,
);
// Selectors end

// Reducer start
export const AppReducer = createReducer(
  initialState,
  on(AppActions.setAlert, (state, {alert}) => {
    return {
      ...state,
      alert,
    };
  }),
  on(AppActions.clearAlert, (state) => {
    return {
      ...state,
      alert: null,
    };
  }),
  on(AppActions.appendNotification, (state, {notification}) => {
    const notifications = state.notifications;
    const nextNotificationId = notifications.length === 0 ? 1 : Math.max(...notifications.map(n => n.id)) + 1;
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {...notification, id: nextNotificationId},
      ],
    };
  }),
  on(AppActions.closeNotification, (state, {notificationId}) => {
    let notifications = [...state.notifications];
    const notificationIdx = notifications.findIndex(notification => notification.id === notificationId);
    if (notificationIdx !== -1) {
      notifications.splice(notificationIdx, 1);
    }

    return {
      ...state,
      notifications,
    };
  }),
  on(AppActions.clearNotifications, (state) => {
    return {
      ...state,
      notifications: [],
    };
  }),
  on(
    AppActions.navBarLoaded,
    AppActions.homePageBannerLoaded,
    (state, {name}) => {
      if (!state.fullPageLoaderList.includes(name)) {
        return {
          ...state,
          fullPageLoaderList: [...state.fullPageLoaderList, name],
        };
      } else {
        return state;
      }
    }),
);
// Reducer end
