import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconType } from '../../models/settings.model';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { FeaturedProductsFacade } from '../../facades/featured-products.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { WishlistFacade } from '../../facades/wishlist.facade';
import { IProductLabel } from '../../models/abstract-product.models';
import { IFeatureProductWithImage } from '../../models/featured-products.models';
import { FeaturedProductsUtils } from '../../utils/featuredProducts.utils';
import { ICart } from '../../models/cart.models';
import { TranslateService } from '@ngx-translate/core';
import { EFeatureToggles, EInstalledBaseTabs, EStoreTypes } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { PriceUtils } from '../../utils/price.utils';

@Component({
  selector: 'app-featured-product',
  templateUrl: './featured-product.component.html',
  styleUrls: ['./featured-product.component.scss'],
})
export class FeaturedProductComponent implements OnInit, OnDestroy {
  iconType = IconType;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  itemsInQueueToCart = [];
  loadingCartDataInProgress = true;
  featuredProductsWithPricesAndImages: IFeatureProductWithImage[];
  isLoaded = false;
  hasProducts = false;
  currentLayoutType = 'grid';
  defaultImg = environment.defaultImg;
  slideConfigHorizontal = {};
  slideConfigVertical = {};
  wishlists = [];
  addNewModalActive = false;
  addItemToCartModalActive = false;
  showModalSelectEquipment = false;
  labels: IProductLabel[];
  currentCart: ICart;
  currentCartId: string;
  hasCartContract: boolean = false;
  itemToAdd: any;
  isSapP40Enable = false;
  priceUtils = PriceUtils;

  @Input() featuredProductsKey: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private featuredProductsFacade: FeaturedProductsFacade,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private wishlistFacade: WishlistFacade,
    private authorizationFacade: AuthorizationFacade,
    private translateService: TranslateService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);

    if (this.isUsStore) {
      this.selectHasCartContract();
    }

    this.selectCurrentCart();
    this.selectLoadingCartDataInProgress();
    this.selectFeaturedProducts();
    this.selectItemsInQueueToCart();

    this.authorizationFacade.selectIsUserLoggedIn().pipe(takeUntil(this.unsubscribe$)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.dispatchActionToGetWishlists();
        this.getWishlistsFromStore();
      }
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectEquipment(): void {
    this.router.navigate(['/my-installed-base'], {
      queryParams: {
        tab: EInstalledBaseTabs.EQUIPMENT,
      },
    });
  }

  selectCurrentCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      if (cart) {
        this.currentCart = cart;
        this.currentCartId = cart.id;
      }
    });
  }

  selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => this.loadingCartDataInProgress = loadingCartDataInProgress);
  }

  selectHasCartContract(): void {
    this.marketingFacade.selectHasCartContract().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => this.hasCartContract = value);
  }

  selectFeaturedProducts(): void {
    this.featuredProductsFacade.getFeaturedProducts(this.featuredProductsKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          const featuredProductsResponse = data;
          const featuredProducts = featuredProductsResponse.data ?? [];
          const featuredProductsCount = featuredProducts.length;
          const featuredProductsIncluded = featuredProductsResponse.included;

          if (featuredProductsIncluded) {
            this.labels = featuredProductsIncluded.filter(item => item.type === 'product-labels') as IProductLabel[];
          }

          this.isLoaded = true;
          this.hasProducts = featuredProducts.length > 0;
          this.slideConfigHorizontal = this.getHorizontalConfig(featuredProductsCount);
          this.slideConfigVertical = this.getVerticalConfig(featuredProductsCount);
          this.featuredProductsWithPricesAndImages = featuredProducts.map(productData => {
            return FeaturedProductsUtils.getDisplayableProduct(productData, featuredProductsIncluded);
          });
        },
      );
  }

  selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.itemsInQueueToCart = state;
      });
  }

  selectLayoutOption(option): void {
    this.currentLayoutType = option;
  }

  hasGuestPrice(product): boolean {
    return this.priceUtils.hasGuestPrice(product.attributes.prices);
  }

  getGuestPrice(product: IFeatureProductWithImage): number {
    return this.priceUtils.getPriceValue(this.priceUtils.getGuestPrice(product.attributes.prices));
  }

  addToCart(data: any): void {
    if (this.isItemInProgress(data.sku) || this.loadingCartDataInProgress) {
      return;
    }

    const systemDetails = this.currentCart.attributes?.systemDetails;

    if ((this.isUsStore || this.isCaStore) && !systemDetails?.siemensEquipmentId) {
      this.showModalSelectEquipment = true;
      return;
    }

    if (this.hasCartContract) {
      this.itemToAdd = [{
        data: {
          sku: data.sku,
          name: data.name,
        },
        isConcrete: false,
        quantity: 1,
      }];
      this.addItemToCartModalActive = true;
      return;
    }

    this.marketingFacade.addProductToCart(data, false);
    this.analyticsService.setProducts(data);
    this.analyticsService.trackCart('cart.add');
  }

  isAddToCartBtnDisabled(): boolean {
    return this.loadingCartDataInProgress || (!this.isSapP40Enable && (this.isUsStore || this.isCaStore));
  }

  isItemInProgress(sku: string): boolean {
    return this.itemsInQueueToCart.includes(sku);
  }

  getHorizontalConfig(productCount: number): any {
    return {
      slidesToShow: 3,
      slidesToScroll: Math.min(productCount, 3),
      dots: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: Math.min(productCount, 2),
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: Math.min(productCount, 2),
            arrows: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: Math.min(productCount, 1),
            arrows: false,
          },
        },
      ],
    };
  }

  getVerticalConfig(productCount: number): any {
    return {
      slidesToShow: Math.min(productCount, 10),
      slidesToScroll: Math.min(productCount, 10),
      dots: true,
      arrows: false,
      vertical: true,
      verticalSwiping: true,
    };
  }

  dispatchActionToGetWishlists(): void {
    this.wishlistFacade.dispatchUsersWishlistRetrieve();
  }

  getWishlistsFromStore(): void {
    this.wishlistFacade.selectUsersWishlists().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(wishlists => this.wishlists = wishlists);
  }

  wishlistCreated(_event): void {
    this.wishlistFacade.isNewWishlistCreated()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.addNewModalActive = !result;
      });
  }
}
