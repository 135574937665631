import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { MarketingFacade } from '../../facades/marketing.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EStoreFeatures } from '../../configurations/common';

@Component({
  selector: 'app-cart-notification-modal',
  templateUrl: './cart-notification-modal.component.html',
  styleUrls: ['./cart-notification-modal.component.scss']
})
export class CartNotificationModalComponent implements OnInit {
  isModalOpen = false;
  isLoggedIn: boolean;
  isSelectCartSubscription$ = new Subject<void>();
  constructor(
    private router: Router,
    private authorizationFacade: AuthorizationFacade,
    private marketingFacade: MarketingFacade,
    private configFacade: ConfigurationFacade
  ) { }

  ngOnInit(): void {
    if (this.configFacade.isFeatureAvailable(EStoreFeatures.CPQ)) {
      this.authorizationFacade.selectIsUserLoggedIn().subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
        this.marketingFacade.selectCart().pipe(
          skipWhile(val => val === null),
          takeUntil(this.isSelectCartSubscription$)
        ).subscribe((cart) => {
          if (cart.attributes.totalItemsQuantity > 0) {
            this.setModal();
          } else {
            LocalStorageUtils.setKeyValue('cart-modal', 'isEmpty');
          }
          this.isSelectCartSubscription$.next();
          this.isSelectCartSubscription$.complete();
        });
      });
    }
  }

  setModal(): void {
    const modalKey = LocalStorageUtils.getKeyValue('cart-modal');
    if ((!modalKey || modalKey !== 'isEmpty' && modalKey !== 'dismiss') && this.isLoggedIn) {
      LocalStorageUtils.setKeyValue('cart-modal', 'true');
      this.isModalOpen = true;
    }
  }

  dismiss(): void {
    this.isModalOpen = false;
    LocalStorageUtils.setKeyValue('cart-modal', 'dismiss');
  }

  gotToQuotes(): void {
    this.isModalOpen = false;
    LocalStorageUtils.setKeyValue('cart-modal', 'dismiss');
    this.router.navigate(['/shop-cart']).then();
  }
}
