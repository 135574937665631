<div class="alert__overlay" *ngIf="isAlert()">
    <div class="alert is-open" [ngClass]="'alert--'+alert.type">
        <div class="alert__container">
            <button
                    type="button"
                    class="alert__close"
                    aria-label="Close alert"
                    (click)="handleAction()">
                <i aria-hidden="true" class="icon-close"></i>
            </button>
            <div class="alert__header">
                <i aria-hidden="true" class="icon alert__indicator"></i>
                <h5 class="alert__title">{{ getTitle() | translate }}</h5>
            </div>
            <div class="alert__body">
                <p class="alert__text" [innerHTML]="alert.message | translate">
                </p>
            </div>
            <div class="alert__footer">
                <button
                        type="button"
                        class="button button--primary"
                        (click)="handleAction()">{{ 'alert.ok' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
