import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination-linkless',
  templateUrl: './pagination-linkless.component.html',
  styleUrls: ['./pagination-linkless.component.scss'],
})
export class PaginationLinklessComponent implements OnInit, OnChanges {
  @Input() pageCount: number = 1;
  @Input() currentPage: number = 1;
  @Input() pageCountBeforeCurrent: number = 2;
  @Input() pageCountAfterCurrent: number = 2;
  @Output() pageChange = new EventEmitter<number>();
  
  pages: number[] = [];

  ngOnInit(): void {
    if (this.pageCountBeforeCurrent < 1 || this.pageCountAfterCurrent < 1) {
      throw Error('count of displayed page links before or after current page cannot be negative');
    }
    this.pages = this.getPagesToRender();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage || changes.pageCount) {
      this.pages = this.getPagesToRender();
    }
  }

  goToPage(pageNumber: number): void {
    if (pageNumber < 1 || pageNumber > this.pageCount || pageNumber === this.currentPage) {
      return;
    } else {
      this.currentPage = pageNumber;
      this.pageChange.emit(this.currentPage);
    }
  }

  getPagesToRender(): number[] {
    const pages: number[] = [];
    if (this.pageCount === 1) {
      return pages;
    }

    const pagesBeforeCurrent = Array.from(
      {length: this.pageCountBeforeCurrent}, (_, idx) => this.currentPage - idx - 1)
      .filter((x) => x >= 1)
      .sort((prev, next) => prev - next);

    const pagesAfterCurrent = Array.from(
      {length: this.pageCountAfterCurrent}, (_, idx) => this.currentPage + idx + 1)
      .filter((x) => x <= this.pageCount)
      .sort((prev, next) => prev - next);

    if (pagesBeforeCurrent.length) {
      const firstOfRange = pagesBeforeCurrent[0];
      if (firstOfRange > 1) {
        pages.push(1);
      }
      if (firstOfRange > 2) {
        pages.push(undefined);
      }
      pages.push(...pagesBeforeCurrent);
    }

    pages.push(this.currentPage);

    if (pagesAfterCurrent.length) {
      pages.push(...pagesAfterCurrent);
      const lastOfRange = pagesAfterCurrent.pop();
      if (lastOfRange < this.pageCount - 1) {
        pages.push(undefined);
      }
      if (lastOfRange < this.pageCount) {
        pages.push(this.pageCount);
      }
    }

    return pages;
  }
}
