import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EUserRoles } from '../configurations/common';
import { AuthorizationFacade } from '../facades/authorization.facade';
import { CustomerFacade } from '../facades/customer.facade';
import { IEndpointConfig } from '../models/settings.model';
import { AppUtils } from '../utils/app.utils';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private static env = environment.protectedEndpoints;
  endpointConfig: IEndpointConfig;

  constructor(
    private authFacade: AuthorizationFacade,
    private customerFacade: CustomerFacade,
    private i18nService: I18nService
  ) {
  }

  private static endpointFromRequest(requestUrl: string): string {
    try {
      const url = new URL(requestUrl);
      const pathName = url.pathname;
      const reg = /\//;
      const endpointPath = pathName.replace(reg, '').split(reg);
      return endpointPath[0];
    } catch (e) {
      return requestUrl;
    }
  }

  private static isAuthorizationHeaderNeeded(endpoint: IEndpointConfig, method: string, name: string): boolean {
    return endpoint && (
      endpoint.name.toLowerCase() === name.toLowerCase() &&
      endpoint.methods.includes(method.toLowerCase()));
  }

  private static isSecurityRequired(endpoint: string, method: string): IEndpointConfig {
    return this.env.find(f => f.name.toLowerCase() === endpoint.toLowerCase() &&
      f.methods.includes(method.toLowerCase()));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqEndpoint = InterceptorService.endpointFromRequest(req.url);
    const isAuthenticated = this.authFacade.getAuthenticatedCookie();
    const companyRoles = this.customerFacade.getCustomerCompanyRolesFromLocalStorage();
    const localStorage = window.localStorage;
    const languageCode = this.i18nService.getCurrentLanguage();
    const store = AppUtils.getCurrentStore();
    
    let token = '';
    let headers = {
      'Accept-Language': this.i18nService.buildLangParam(store.marketCode, languageCode),
      store: store.storeId,
    };

    this.endpointConfig = InterceptorService.isSecurityRequired(reqEndpoint, req.method);

    if (isAuthenticated) {
      for (const [key, value] of Object.entries(localStorage)) {
        if (key.indexOf('@@auth0spajs@@') !== -1) {
          const authStorage = JSON.parse(value);
          token = authStorage.body.access_token;
        }
      }
    }

    if (isAuthenticated && token && !companyRoles.includes(EUserRoles.Guest) || req.url.includes('company-users/mine')) {
      if (InterceptorService.isAuthorizationHeaderNeeded(this.endpointConfig, req.method, reqEndpoint)) {
        headers = Object.assign({ ...headers }, { Authorization: `Bearer ${token}` });
      }
    }

    return next.handle(req.clone({
      setHeaders: headers
    }));
  }

}
