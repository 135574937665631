<div class="card help-me-choose"
     [ngClass]="{'card-col': layoutType !== 'grid'}">
  <div class="card__content">
    <div class="card__image">
      <img [src]="helpMeChooseImg" alt="{{ 'catalog.help-choose-title' | translate }}"/>
    </div>
    <div class="card__body">
      <h2 class="card__title" [attr.data-test]="'title-helpChoose'">
        {{ 'catalog.help-choose-title' | translate }}
      </h2>
      <p class="card__desc" [attr.data-test]="'desc-helpChoose'">
        {{ 'catalog.help-choose-desc' | translate }}
      </p>
      <a [attr.data-test]="'link-guidedQuestions'" class="button button--primary button-help visible-sm"
          [routerLink]="['/guided-questions']" 
          [queryParams]="getContractParams()">
        {{ 'catalog.help-choose-button-title' | translate }}
      </a>
    </div>
    <div class="action-buttons">
      <a class="button button--primary button-help" [attr.data-test]="'button-helpMeChoose'"
          [routerLink]="['/guided-questions']" 
          [queryParams]="getContractParams()">
        {{ 'catalog.help-choose-button-title' | translate }}
      </a>
    </div>
  </div>
</div>
