import { createAction, props } from '@ngrx/store';
import { IInstalledBaseSystems } from '../models/installedbase.models';

export const InstallBaseSystemsActionStartDataAction = createAction(
  '[Install Base Systems] - Start get install base Systems data Action',
);

export const SuccessGetInstallBaseSystemsDataAction = createAction(
  '[Install Base Systems] - Success Get install base Systems data Action',
  props<{payload: IInstalledBaseSystems}>(),
);

export const GetInstallBaseSystemsDataActionFailed = createAction(
  '[Install Base Systems] - Get install base Systems data Action Failed',
  props<{error: any}>()
);

export const GetInstallBaseSystemsSparePartsDataActionStart = createAction(
  '[Install Base Systems] - Start get install base Systems for spare parts data Action',
);

export const GetInstallBaseSystemsSparePartsDataActionSuccess = createAction(
  '[Install Base Systems] - Success Get install base Systems for spare parts data Action',
  props<{payload: IInstalledBaseSystems}>(),
);

export const GetInstallBaseSystemsSparePartsDataActionFailed = createAction(
  '[Install Base Systems] - Get install base Systems for spare parts data Action Failed',
  props<{error: any}>()
);

export const GetInstallBaseSystemsCpqDataActionStart = createAction(
  '[Install Base Systems] - Start get install base Systems for CPQ data Action',
);

export const GetInstallBaseSystemsCpqDataActionSuccess = createAction(
  '[Install Base Systems] - Success Get install base Systems for CPQ data Action',
  props<{payload: IInstalledBaseSystems}>(),
);

export const GetInstallBaseSystemsCpqDataActionFailed = createAction(
  '[Install Base Systems] - Get install base Systems for CPQ data Action Failed',
  props<{error: any}>()
);

export const InstallBaseProductsActionStartDataAction = createAction(
  '[Install Base Products] - Start get install base Products data Action',
);

export const SuccessGetInstallBaseProductsDataAction = createAction(
  '[Install Base Products] - Success Get install base Products data Action',
  props<{product: any}>(),
);

export const GetInstallBaseProductsDataActionFailed = createAction(
  '[Install Base Products] - Get install base Products data Action Failed',
  props<{error: any}>()
);
