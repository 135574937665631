import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CheckoutFacade } from '../facades/checkout.facade';
import { mergeMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckoutActions } from '../actions';
import { IRfqHistory } from '../models/rfq.models';

@Injectable({
  providedIn: 'root',
})
export class CheckoutEffects {

  constructor(
    private actions$: Actions,
    private checkoutFacade: CheckoutFacade,
  ) {
  }

  GetOrdersHistoryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.GetQuoteRfqHistoryStartDataAction),
      mergeMap(
        () => this.checkoutFacade.getQuoteHistory()
          .pipe(
            map((data: IRfqHistory) =>
              CheckoutActions.SuccessGetQuoteRfqHistoryDataAction({
                rfqBaseInfo: data,
              }),
            ),
          ),
      ),
    ));
}
