import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { SoldToAccountsService } from '../services/sold-to.service';
import { Store } from '@ngrx/store';
import { ISoldToAddressesResponse } from '../models/soldTo-selection.models';
import * as CustomerSelectors from '../reducers/customer.reducer';
import { IAddress } from '../models/common.models';
import { EGlueResource } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class SoldToAccountsFacade {
  private pSoldToAccountsService: SoldToAccountsService;

  constructor(
    private injector: Injector,
    private store: Store,
  ) {
  }

  /**
   * @returns {SoldToAccountsService}
   */
  public get soldToService(): SoldToAccountsService {
    if (!this.pSoldToAccountsService) {
      this.pSoldToAccountsService = this.injector.get(SoldToAccountsService);
    }
    return this.pSoldToAccountsService;
  }

  /**
   * @param {EGlueResource[]} includes
   * @returns {Observable<ISoldToAddressesResponse>}
   */
  getSoldToAccounts(includes: EGlueResource[] = []): Observable<ISoldToAddressesResponse> {
    return this.soldToService.getSoldToAccounts(includes);
  }

  /**
   * @param {string} soldToId
   * @param {EGlueResource[]} includes
   * @returns {Observable<ISoldToAddressesResponse>}
   */
  getSoldToAccountsById(soldToId: string, includes: EGlueResource[] = []): Observable<ISoldToAddressesResponse> {
    return this.soldToService.getSoldToAccountsById(soldToId, includes);
  }

  /**
   * @returns {Observable<IAddress[]>}
   */
  selectSoldToAccounts(): Observable<IAddress[]> {
    return this.store.select(CustomerSelectors.selectCustomerSoldToAddresses);
  }
}
