import { Injectable, Injector } from '@angular/core';
import { DataSharedService } from '../services/data-shared.service';

@Injectable({
  providedIn: 'root',
})
export class DataSharedFacade {
  private pDataSharedService: DataSharedService;

  private get dataSharedService(): DataSharedService {
    if (!this.pDataSharedService) {
      this.pDataSharedService = this.injector.get(DataSharedService);
    }
    return this.pDataSharedService;
  }

  constructor(private injector: Injector) {
  }

  setCartId(val: string): void {
    this.dataSharedService.cartId = val;
  }

  getCartId(): string {
    return this.dataSharedService.cartId;
  }
}
