import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { WishlistFacade } from '../../facades/wishlist.facade';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../analytics/analytics.service';

@Component({
  selector: 'app-add-to-wishlist',
  templateUrl: './add-to-wishlist.component.html',
  styleUrls: ['./add-to-wishlist.component.scss'],
})
export class AddToWishlistComponent implements OnInit, OnDestroy {
  active = false;
  wishlistsInProgress = [];
  @Input() wishlists: Array<any>;
  @Input() sku: string;
  @Input() productName: string;
  @Input() productKeywords: string = '';
  @Input() textNextToIcon = false;
  @Input() addNewModalActive: boolean;
  @Input() maxLength: number = 5;
  @Output() createNew = new EventEmitter<any>();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private wishlistFacade: WishlistFacade,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.getWishlistsFromStore();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getWishlistsFromStore(): void {
    this.wishlistFacade.selectListInProgress().pipe(
      takeUntil(this.unsubscribe$)).subscribe(wishlists => {
        this.wishlistsInProgress = wishlists;
      },
    );
  }

  toggle(wishlistId: string, event: any): void {
    if (event.target.checked) {
      this.wishlistFacade.addNewItem(wishlistId, this.sku);
      this.trackProduct()
    } else {
      const shoppingListItemId = this.wishlists
        .find(list => list.id === wishlistId)?.items
        .find(item => '' + item.sku === '' + this.sku).shoppingListItemId;
      this.wishlistFacade.removeItem(wishlistId, shoppingListItemId, this.sku);
    }
  }

  addNew(): void {
    this.createNew.emit();
  }

  inProgress(wishlistId: string): boolean {
    return this.wishlistsInProgress.find(
      wishlist => wishlist.id === wishlistId && wishlist.sku === this.sku);
  }

  wishlistSelected(wishlistName): boolean {
    return this.wishlistsWithProduct().find(wishlist => wishlist.attributes.name === wishlistName);
  }

  wishlistsWithProduct(): any[] {
    return this.wishlists.filter(
      wishlist => wishlist.items?.length > 0 && wishlist.items?.find(
        item => '' + item.sku === '' + this.sku));
  }

  private trackProduct(): void {
    const productData = {
      id: this.sku,
      attributes: {
        name: this.productName,
        metaKeywords: this.productKeywords,
      },
    }
    this.analyticsService.trackProduct('product.favorite', productData)
  }
}
