<div class="add-to-wishlist" [ngClass]="{'selected': wishlistsWithProduct().length > 0}">
  <app-dropdown
      [dropDownName]="'wishlist'"
      [menuDirection]="'left'"
      [hideOnMenuClick]="false"
      [keepActive]="addNewModalActive">
    <ng-container ngProjectAs="[slot][toggle]">
      <span class="add-to-wishlist__icon icon-heart" [attr.data-test]="'icon-wishlist'"></span>
      <span class="add-to-wishlist__desc" *ngIf="textNextToIcon" [attr.data-test]="'desc-addToWishlistTitle'">
        {{ 'wishlists.add-to' | translate }}
      </span>
    </ng-container>
    <ng-container ngProjectAs="[slot][panel]">
      <div class="add-to-wishlist__container">
        <div class="add-to-wishlist__title" *ngIf="!textNextToIcon && wishlists.length > 0" [attr.data-test]="'desc-addToWishlistSubtitle'">
          {{ 'wishlists.add-to' | translate }}
        </div>
        <div class="add-to-wishlist__options" *ngIf="wishlists.length > 0">
          <div
              class="add-to-wishlist__select"
              [ngClass]="{'in-progress': inProgress(wishlist.id)}"
              *ngFor="let wishlist of wishlists"
              [attr.data-test]="'desc-wishlist-' + wishlist.attributes.name">
            <input
                type="checkbox"
                [id]="sku+'-select-'+wishlist.attributes.name"
                [name]="sku+'-checkbox-'+wishlist.attributes.name"
                class="checkbox"
                [checked]="wishlistSelected(wishlist.attributes.name)"
                (change)="toggle(wishlist.id, $event)">
            <label [for]="sku+'-select-'+wishlist.attributes.name">
              <span class="add-to-wishlist__select-name" [attr.data-test]="'desc-wishlistName'">
                {{ wishlist.attributes.name }}
              </span>
            </label>
            <div class="loading-animation"></div>
          </div>
        </div>
        <div class="add-to-wishlist__new" (click)="addNew()" [attr.data-test]="'desc-addNewWishlist'">
          <span class="btn-add__icon icon-plus"></span>
          {{ 'wishlists.add-new' | translate }}
        </div>
      </div>
    </ng-container>
  </app-dropdown>
</div>
