import { environment } from '../../environments/environment';
import { MetaDefinition } from '@angular/platform-browser';
import { ICmsPageAttributes } from '../models/cms';

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};

export enum ERobotsContent {
  INDEX             = 'index',
  NO_INDEX          = 'noindex',
  FOLLOW            = 'follow',
  NO_FOLLOW         = 'nofollow',
  NO_ARCHIVE        = 'noarchive',
  NO_IMAGE_INDEX    = 'noimageindex',
  NO_CACHE          = 'nocache',
  NO_SNIPPET        = 'nosnippet',
  NO_TRANSLATE      = 'notranslate',
  UNAVAILABLE_AFTER = 'unavailable_after:[{date/time}]',
}

export enum EMetaTag {
  DESCRIPTION = 'description',
  KEYWORDS    = 'keywords',
  ROBOTS      = 'robots'
}

export interface ISeoData {
  seoNoCanonical?: boolean;
  seoRobots?: ERobotsContent[];
  seoNonIndexable?: boolean;
  seoTitle?: string;
  seoTitleSource?: TitleSources;
}

export enum ESeoData {
  seoNoCanonical  = 'seoNoCanonical',
  seoRobots       = 'seoRobots',
  seoNonIndexable = 'seoNonIndexable',
  seoTitle        = 'seoTitle',
  seoTitleSource  = 'seoTitleSource'
}

export enum ETitleSources {
  HEADER      = 'header',
  BREADCRUMBS = 'breadcrumbs',
  ENDPOINT    = 'endpoint',
  CONFIG      = 'config',
  DEFAULT     = 'default'
}

export declare type TitleSources =
  ETitleSources.HEADER
  | ETitleSources.BREADCRUMBS
  | ETitleSources.ENDPOINT
  | ETitleSources.CONFIG
  | ETitleSources.DEFAULT;

export interface ISeoPageData {
  titles?: string[];
  titleSource: TitleSources;
  description?: string;
  keywords?: string | string[];
  alternateUrls?: LinkDefinition[];
  canonicalUrl: LinkDefinition;
  robots?: ERobotsContent[] | string[];
}

export class SeoData {
  private titles: string[];
  private description: string;
  private keywords: string;
  private alternateUrls: LinkDefinition[];
  private canonicalUrl: LinkDefinition;
  private robots: ISeoPageData['robots'];

  constructor(pageData: ISeoPageData) {
    this.robots = pageData.robots || [ERobotsContent.INDEX, ERobotsContent.FOLLOW];
    this.titles = pageData.titles || [environment.defaultPageTitle];
    this.alternateUrls = pageData.alternateUrls || null;
    this.canonicalUrl = pageData.canonicalUrl || null;
    this.description = pageData.description || '';
    if (pageData.keywords) {
      this.keywords = typeof pageData.keywords === 'string' ? pageData.keywords : pageData.keywords.join(', ');
    } else {
      this.keywords = '';
    }
  }

  getMetaData(metaName: Exclude<EMetaTag, EMetaTag.ROBOTS>): MetaDefinition {
    return {
      name: metaName,
      content: this[metaName],
    };
  }

  getRobots(): MetaDefinition {
    return {
      name: EMetaTag.ROBOTS,
      content: this.robots.join(', '),
    };
  }

  getCanonicalURL(): LinkDefinition {
    return this.canonicalUrl;
  }

  getAlternateURLs(): LinkDefinition[] {
    return this.alternateUrls;
  }

  getTitles(): string[] {
    return this.titles;
  }
}
