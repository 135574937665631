import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { State } from '../reducers';
import { AuthService, I18nService } from '../services';
import { PageTypes } from './enums/pageTypes';
import { TrackingAccordionAction } from './tracking-action/tracking-accordion-action.interface';
import { TrackingCartAddAction } from './tracking-action/tracking-cart-add-action.interface';
import { TrackingCartCheckoutAction } from './tracking-action/tracking-cart-checkout-action.interface';
import { TrackingCartRemoveAction } from './tracking-action/tracking-cart-remove-action.interface';
import { TrackingFilter } from './tracking-action/tracking-filter.interface';
import { TrackingOrderApprovedAction } from './tracking-action/tracking-order-approved-action.interface';
import { TrackingOrderPlacedAction } from './tracking-action/tracking-order-placed-action.interface';
import { TrackingOrderRejectedAction } from './tracking-action/tracking-order-rejected-action.interface';
import { TrackingOrder } from './tracking-action/tracking-order.interface';
import { TrackingPageReadyAction } from './tracking-action/tracking-page-ready-action.interface';
import { TrackingPage } from './tracking-action/tracking-page.interface';
import { TrackingProductAction } from './tracking-action/tracking-product-action.interface';
import { TrackingProductDataQuantity } from './tracking-action/tracking-product-data-quantity.interface';
import { TrackingProductData } from './tracking-action/tracking-product-data.interface';
import { TrackingSearch } from './tracking-action/tracking-search.interface';
import { TrackingUser } from './tracking-action/tracking-user.interface';
import { CustomerFacade } from '../facades/customer.facade';
import { AppUtils } from '../utils/app.utils';
import { TrackingRfqPlacedAction } from './analytics.models';
import { TrackingGeneralAction } from './tracking-action/tracking-general-action.interface';
import { TrackingCartOrderAction } from './tracking-action/tracking-cart-order-action.interface';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { EFeatureToggles } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {

  private productData: any;
  private currentSearchQuery = '';
  private currentSearchCategory;
  private currentSearchSorting;
  private currentPageType: PageTypes;
  private currentPageTitle: string;
  private isLoggedIn = false;
  private customerLoggedData: any;
  private customerLoggedBU: any;
  private cartId: string;
  private loggedUserSubscription: Subscription;
  private userBusinessUnitNameSubscription: Subscription;
  private selectedCategory: string;
  private activeFilters: any[];
  private isGlobalHomePage = false;
  private globalHomePageCode = 'ww';
  private globalHomePageDefaultLanguage = 'en';
  private isAdobeAnalyticsEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<State>,
              private authService: AuthService,
              private i18nService: I18nService,
              private customerFacade: CustomerFacade,
              private configurationFacade: ConfigurationFacade,
  ) {
    this.authService.isAuthenticated$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.ADOBE_ANALYTICS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isAdobeAnalyticsEnabled = value;
    });

    this.loggedUserSubscription = this.getCustomerData();
  }

  ngOnDestroy(): void {
    this.userBusinessUnitNameSubscription.unsubscribe();
    this.loggedUserSubscription.unsubscribe();
  }

  getCustomerData(): Subscription {
    return this.customerFacade.selectCompanyBusinessUnit().subscribe(
      data => {
        if (data) {
          this.customerLoggedBU = data;
          this.userBusinessUnitNameSubscription = this.customerFacade.selectCustomerCompanyRoles().subscribe(
            state => {
              if (state) {
                this.customerLoggedData = state;
              }
            },
          );
        }
      },
    );
  }

  setFilters(activeFilters: any[]): void {
    this.activeFilters = activeFilters;
  }

  setCartId(cartId: string): void {
    this.cartId = cartId;
  }

  setProducts(productData): void {
    this.productData = productData;
  }

  setCategory(category: string): void {
    this.selectedCategory = category;
  }

  setCustomerLoggedBU(bu: any): void {
    this.customerLoggedBU = bu;
  }

  setCustomerLoggedData(data: string): void {
    this.customerLoggedData = data;
  }

  setSearchQuery(params: any): void {
    this.currentSearchQuery = params.q;
    if (params.category) {
      this.currentSearchCategory = params.category;
    } else {
      this.currentSearchCategory = undefined;
    }
    if (params.sort) {
      this.currentSearchSorting = params.sort;
    } else {
      this.currentSearchSorting = undefined;
    }
  }

  trackPageReady(title: string, pageType: PageTypes, type?: string, query: string = ''): void {
    this.authService.isAuthenticated$.pipe(take(1)).subscribe(isLoggedIn => {
      if (!type) {
        type = 'abstract-products';
      }
      this.isGlobalHomePage = pageType === PageTypes.GLOBAL_HOME_PAGE;
      this.currentPageTitle = title;
      this.currentPageType = pageType;
      this.currentSearchQuery = query; // reset search query
      this.pushToSte(this.createTrackingPageReadyAction(type, isLoggedIn));
    });
  }

  trackNavigation(navitem: string): void {
    const analyticsObject = this.createTrackingNavigation(navitem);
    this.pushToSte(analyticsObject);
  }

  trackSearch(title: string, pageType: PageTypes): void {
    this.trackPageReady(title, pageType);
  }

  trackProduct(action: string, product: any): void {
    const analyticsObject = this.createTrackingProductAction(action, product);
    this.pushToSte(analyticsObject);
  }

  trackInstalledBase(installedBaseName: string, installBaseId: string): void {
    const payload = {
      action: 'product.installbase',
      data: {
        products: {
          name: installedBaseName,
          id: installBaseId,
        },
      },
    };
    this.pushToSte(payload);
  }

  trackAccordion(accordionName: string): void {
    const analyticsObject = this.createTrackingAccordionAction(accordionName);
    this.pushToSte(analyticsObject);
  }

  trackCart(action: string): void {
    let analyticsObject;

    if (action === 'cart.add') {
      analyticsObject = this.createTrackingCartAddAction();
    }
    if (action === 'cart.remove') {
      analyticsObject = this.createTrackingCartRemoveAction();
    }
    if (action === 'cart.checkout') {
      analyticsObject = this.createTrackingCartCheckoutAction();
    }
    if (action === 'cart.order') {
      analyticsObject = this.createTrackingCartOrderAction();
    }
    if (action === 'rfq.checkout') {
      analyticsObject = this.trackCartToCheckout();
    }
    if (action === 'rfq.placed') {
      analyticsObject = this.trackRfqPlaced();
    }
    if (action === 'order.placed') {
      analyticsObject = this.createTrackingOrderForApprovalAction();
    }
    if (action === 'order.approved') {
      analyticsObject = this.createTrackingApprovedOrderAction();
    }
    this.pushToSte(analyticsObject);
  }

  trackRfqPlaced(): TrackingRfqPlacedAction {
    return {
      action: 'cart.order',
      data: {
        order: {
          status: 'placed',
          type: AppUtils.getCurrentStore().addOrderTypeToAdlytics ? 'quote' : undefined,
        },
        products: this.productData.map(item => {
          return {
            id: item.id,
            name: item.attributes.name,
            quantity: item.attributes.quantity,
          };
        }),
      },
    };
  }

  trackUserLoggedIn(): void {
    const analyticsObject = this.createTrackingUserLoginAction();
    this.pushToSte(analyticsObject);
  }

  trackUserRegistered(): void {
    const analyticsObject = this.createTrackingUserRegisterAction();
    this.pushToSte(analyticsObject);
  }

  trackCartToCheckout(): TrackingCartCheckoutAction {
    let body = {
      action: 'cart.checkout',
      data: {
        products: this.productData.reduce((acc, item) => {
          acc.push({
            id: item.id,
            name: item.attributes.name,
          });
          return acc;
        }, []),
      },
    };

    if (AppUtils.getCurrentStore().addOrderTypeToAdlytics) {
      body.data = {
        ...body.data, ...{order: {type: 'quote'}},
      };
    }
    return body as TrackingCartCheckoutAction;
  }

  trackOrderPlaced(title: string, pageType: PageTypes): void {
    this.currentPageTitle = title;
    this.currentPageType = pageType;
    const analyticsObject = this.createTrackingOrderPlacedAction();
    this.pushToSte(analyticsObject);
  }

  trackOrderApproved(title: string, pageType: PageTypes): void {
    this.currentPageTitle = title;
    this.currentPageType = pageType;
    const analyticsObject = this.createTrackingOrderApprovedAction();
    this.pushToSte(analyticsObject);
  }

  trackOrderRejected(): void {
    const analyticsObject = this.createTrackingOrderRejectedAction();
    this.pushToSte(analyticsObject);
  }

  pushToSte(element): void {
    if (window && this.isAdobeAnalyticsEnabled) {
      const anyWindow = window as any;
      anyWindow.ste_statistic = anyWindow.ste_statistic || [];
      anyWindow.ste_statistic.push(element);
    }
  }

  createTrackingUserLoginAction(): TrackingGeneralAction {
    return {
      action: 'user.login',
      data: {},
    };
  }

  createTrackingUserRegisterAction(): TrackingGeneralAction {
    return {
      action: 'user.register',
      data: {},
    };
  }

  private createTrackingNavigation(navitem: string): any {
    return {
      action: 'navigation.open',
      data: {
        navigation: {
          navitem: [navitem],
        },
      },
    };
  }

  createTrackingPageReadyAction(type: string, isLoggedIn: boolean): TrackingPageReadyAction {
    return {
      action: 'page.ready',
      data: {
        page: this.createTrackingPage(),
        user: {
          role: this.customerLoggedData,
          loginStatus: isLoggedIn ? 'logged_in' : 'logged_out',
          partner: this.customerLoggedBU ? this.customerLoggedBU?.name : undefined,
        },
        cartId: this.cartId,
        products: this.createTrackingProductDataArray(type),
        search: this.createTrackingSearch(),
      },
    };
  }


  private createTrackingAccordionAction(accordionName: string): TrackingAccordionAction {
    return {
      action: 'accordion.open',
      data: {
        products: this.createTrackingProductData(this.productData),
        accordion: {
          name: accordionName,
        },
      },
    };
  }

  private createTrackingCartAddAction(): TrackingCartAddAction {
    let navItem;
    switch (this.currentPageType) {
      case PageTypes.PRODUCT_LIST_PAGE:
      case PageTypes.PRODUCT_PAGE:
      case PageTypes.SEARCH_RESULT_PAGE:
        navItem = this.currentPageType;
    }

    return {
      action: 'cart.add',
      data: {
        products: {
          id: this.productData.sku,
          name: this.productData.name,
          quantity: this.productData.quantity,
          dept: this.productData.metaKeywords,
        },
        navItem,
      },
    };
  }

  private createTrackingCartCheckoutAction(): TrackingCartCheckoutAction {
    return {
      action: 'cart.checkout',
      data: {
        products: this.createTrackingProductDataArray('concrete-products'),
      },
    };
  }

  private createTrackingCartRemoveAction(): TrackingCartRemoveAction {
    return {
      action: 'cart.remove',
      data: {
        products: {
          id: this.productData.sku,
          name: this.productData.name,
          quantity: this.productData.quantity,
          dept: this.productData.metaKeywords,
        },
      },
    };
  }

  createTrackingCartOrderAction(): TrackingCartOrderAction {
    return {
      action: 'cart.order',
      data: {
        order: {
          status: 'placed',
        },
        products: this.createTrackingProductDataArray('order-review-page'),
      }
    };
  }

  createTrackingOrderForApprovalAction(): TrackingOrderPlacedAction {
    return {
      action: 'page.ready',
      data: {
        page: this.createTrackingPage(),
        order: this.createTrackingOrder('placed'),
      },
    };
  }

  createTrackingApprovedOrderAction(): TrackingOrderPlacedAction {
    return {
      action: 'order.approved',
      data: {
        page: this.createTrackingPage(),
        order: this.createTrackingOrder('approved'),
      },
    };
  }

  private createTrackingOrderApprovedAction(): TrackingOrderApprovedAction {
    return {
      action: 'page.ready',
      data: {
        page: this.createTrackingPage(),
        order: this.createTrackingOrder(),
        user: this.createTrackingUser(),
      },
    };
  }

  private createTrackingOrderPlacedAction(): TrackingOrderPlacedAction {
    return {
      action: 'page.ready',
      data: {
        page: this.createTrackingPage(),
        order: this.createTrackingOrder('placed'),
        user: this.createTrackingUser(),
      },
    };
  }

  private createTrackingOrderRejectedAction(): TrackingOrderRejectedAction {
    return {
      action: 'page.ready',
      data: {
        page: this.createTrackingPage(),
        order: this.createTrackingOrder('rejected'),
        user: this.createTrackingUser(),
      },
    };
  }

  private createTrackingProductAction(action: string, product: any): TrackingProductAction {
    return {
      action,
      data: {
        products: this.createTrackingProductData(product),
      },
    };
  }

  private createTrackingFilterArray(): TrackingFilter[] {
    // this seems to be hidden in the Catalog data
    const result = [];
    if (this.selectedCategory) {
      result.push({name: this.selectedCategory});
    }

    if (this.currentSearchSorting) {
      result.push({name: 'sort', values: [String(this.currentSearchSorting)]});
    }

    if (this.activeFilters) {
      this.activeFilters.forEach(
        item => {
          if (item.activeValue) {
            result.push({name: item.localizedName, values: item.activeValue});
          }
        },
      );
    }

    return result;
  }

  private createTrackingOrder(orderStatus?: 'placed' | 'rejected' | 'approved'): TrackingOrder {
    return {
      status: orderStatus,
      type: AppUtils.getCurrentStore().addOrderTypeToAdlytics ? 'regular' : undefined,
      id: this.cartId ? this.cartId : (this.productData)?.data.id,
      products: this.createTrackingProductDataQuantityArray(),
    };
  }

  private createTrackingPage(): TrackingPage {
    const currentLanguage = this.i18nService.getLangParamData(this.i18nService.getCurrentParam())[0];
    const cachedLanguage = this.i18nService.getCachedLanguage();
    let determinedLanguage;

    if (this.isGlobalHomePage) {
      determinedLanguage = this.globalHomePageDefaultLanguage;
    } else if (cachedLanguage) {
      determinedLanguage = currentLanguage;
    } else {
      determinedLanguage = this.i18nService.getDefaultLanguage();
    }

    return {
      title: this.currentPageTitle,
      template: this.currentPageType,
      country: this.isGlobalHomePage ? this.globalHomePageCode : AppUtils.getCurrentMarket(),
      language: determinedLanguage,
    };
  }

  private createTrackingProductDataArray(type: string): TrackingProductData[] {
    if (type === 'product-page') {
      return [{
        id: this.productData.sku,
        name: this.productData.name,
        dept: this.productData.metaKeywords,
      }];
    }

    if (this.productData?.data?.attributes && type === 'data-product') {
      return [{
        id: this.productData.data.attributes.sku,
        name: this.productData.data.attributes.name,
        dept: this.productData.data.attributes.metaKeywords,
      }];
    }

    if (type === 'shopping-cart' && this.productData) {
      if (!Array.isArray(this.productData)) {
        return [];
      }
      return this.productData.map(product => {
        return {
          id: product.attributes.sku,
          name: product.attributes.name,
          dept: product.attributes.metaKeywords,
        };
      });
    }

    if (type === 'order-review-page') {
      return this.productData.map(product => {
        return {
          id: product.attributes.sku,
          name: product.attributes.name,
          quantity: product.attributes.quantity
        };
      });
    }

    if (type === 'thank-you-page') {
      return this.productData.map(product => {
        return {
          id: product.sku,
          name: product.name,
          quantity: product.quantity
        };
      });
    }

    if (!(this.productData)?.included) {
      return [];
    }

    return (this.productData)?.included
      .filter(item => item.type === type)
      .map(item => {
        return {
          id: item.id,
          name: this.getProductName(item),
          dept: item?.attributes.metaKeywords,
          quantity: this.productData?.included.find(items => items.type === 'items' && items.id === item.id)?.attributes.quantity,
        };
      });
  }

  private getProductName(item): string {
    if (item.attributes.name !== undefined) {
      return item.attributes.name;
    }
    return (this.productData)?.included.find(it => it.id === item.id && it.attributes.name !== undefined)?.attributes.name;
  }

  private createTrackingProductData(product?: any): TrackingProductData {
    if (product !== undefined) {
      return {
        id: product.id ? product.id : product.sku,
        name: product?.attributes.name ? product.attributes.name : product.name,
        dept: product.metaKeywords ? product.metaKeywords : product.attributes.metaKeywords,
      };
    }
  }

  private createTrackingProductDataQuantityArray(): TrackingProductDataQuantity[] {
    if (!this.productData.included) {
      return this.productData.map(item => {
        return {
          id: item.sku,
          quantity: item.quantity,
          name: item.name,
        };
      });
    }

    return (this.productData)?.included
      .filter(item => item.type === 'items')
      .map(item => {
        return {
          id: item.id,
          quantity: item?.attributes.quantity,
          name: this.getProductName(item),
        };
      });
  }

  private createTrackingSearch(): TrackingSearch {
    if (this.currentSearchQuery !== '') {
      return {
        source: 'Site Search',        // this is static?
        term: this.currentSearchQuery,
        resultCount: String(this.productData.data[0].attributes.pagination.numFound),
        filters: this.createTrackingFilterArray(),
      };
    } else {
      return undefined;
    }
  }

  private createTrackingUser(): TrackingUser {
    return {
      role: this.customerLoggedData,
      loginStatus: this.isLoggedIn ? 'logged_in' : 'logged_out',
      partner: this.customerLoggedBU ? this.customerLoggedBU?.name : undefined,
    };
  }
}
