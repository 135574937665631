<div class="system-detail" *ngIf="form">
  <p class="under-text">{{ 'quote-summary.detail-subtext' | translate }}</p>
  <div class="system-detail__properties width-70" *ngFor="let property of properties">
    <div class="system-detail__property--title-container">
      <span class="system-detail__property--title">{{ property.title }}</span>
      <app-tooltip
          link="icon"
          text="{{ property.tooltip | translate }}">
      </app-tooltip>
      <p *ngIf="property.key === 'contractTerm'" class="end-of-support">
        {{ 'quote-summary.end-of-support' | translate }} {{ property.contractEndOfSupport }}
      </p>
    </div>
    <div class="system-detail__property--value-container">
      <div class="system-detail__property--input" [ngClass]="{'invalid' : !isFieldValid(property.key)}">
        <div class="system-detail__property--input-value">
          <span *ngIf="!isFieldValid(property.key)" class="icon icon-close__circle icon-close"></span>
          <span>{{ property.value }}</span>
          <span *ngIf="property.key === 'contractTerm'">
            {{ (property.value === 1 ? 'service-configuration.year' : 'service-configuration.years') | translate }}
          </span>
        </div>
        <div *ngIf="property.key !== 'shipToAddress'" class="system-detail__property--input-edit" (click)="edit()">
          <img class='icon' src="../../../../../assets/img/icons/icon-edit.svg"
               alt="{{ 'quote-summary.edit' | translate }}">
        </div>
      </div>
      <span *ngIf="!isFieldValid(property.key)" class="system-detail__property--input-warning">
        {{ property.title }} {{ 'quote-summary.is-not-valid' | translate }}
      </span>
      <div *ngIf="property.key === 'shipToAddress'" class="system-detail__property--report-wrong-address">
        <span class="icon icon-arrow-right-small"></span>
        <button class="text action" (click)="showReportWrongShipToAddressModal()">
          {{ 'quote-summary.report-wrong-address' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<app-report-wrong-address-modal
    [showModal]="reportWrongShipToAddressModalFlag"
    (closeModal)="reportWrongShipToAddressModalFlag = false"
    [addressesList]="shipToAddresses"
    [cpqOnly]="true"
    [cpqAddressType]="cpqAddressType"
    [functionalLocations]="functionalLocations"
    [quoteId]="cart?.id || ''"
    [cpqLabelName]="'quote-summary.ship-to-address' | translate"
    [roleSpecificMessage]="true">
</app-report-wrong-address-modal>

