import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppUtils } from '../../../utils/app.utils';
import { environment } from '../../../../environments/environment';
import { IStore } from '../../../models/settings.model';

@Component({
  selector: 'app-pending-orders-list',
  templateUrl: './pending-orders-list.component.html',
  styleUrls: ['./pending-orders-list.component.scss'],
})
export class PendingOrdersListComponent {
  @Input() loading: boolean;
  @Input() orders: any;
  @Input() tabsOpened: Array<string>;
  @Input() roleAccess: boolean;
  @Output() refreshOrdersEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openTabIdEmitter: EventEmitter<any> = new EventEmitter<string>();


  toggleTab(id: string): void {
    this.openTabIdEmitter.emit(id);
  }

  getUserFullName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName}`;
  }

  refreshOrders(): void {
    this.refreshOrdersEmitter.emit();
  }

  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
