<ng-container *ngIf="(customerState$ | async) as customerStateData">
  <ng-container *ngIf="customerStateData.isViewer || customerStateData.isGuest">
    <h3>{{ 'orders-page.page-title-buyer' | translate }}</h3>
  </ng-container>
</ng-container>
<app-access-control [notForViewer]="true" [description]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <div class="my-orders__container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <ng-container *ngIf="companyRoles && !checkRolesForAccess(); else approverBlock">
            <h3>{{ 'orders-page.page-title-buyer' | translate }}</h3>
          </ng-container>
          <ng-template #approverBlock>
            <h3>{{ 'orders-page.page-title' | translate }}</h3>
          </ng-template>
        </div>
      </div>
      <!--Tabbed navigation-->
      <div *ngIf="isAnyOrderTab && !showInitialLoader()" class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="bare-list tabs__list">
            <!--  originalOrderOfTabs: avoid of sorting tabs    -->
            <ng-container *ngFor="let tab of hospitalOrdersTabsMap | keyvalue: originalOrderOfTabs">
              <h6 *ngIf="tab.value"
                  class="tabs__item"
                  [attr.data-test]="'tab-' + tab.key"
                  [ngClass]="{'is-active is-active__bar': currentTab === tab.key}"
                  (click)="changeTab(tab.key)">
                {{ 'orders-page.' + tab.key | translate }}
              </h6>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="showInitialLoader()">
        <div class="loader__content">
          <div class="loading-animation"></div>
        </div>
      </div>
      <!--   If there is no previous orders or carts   -->
      <ng-container
          *ngIf="!showInitialLoader() && !isAnyOrderTab">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="col-12">
              <p>{{ 'orders-page.no-history-orders-message' | translate }}</p>
              <br/>
            </div>
          </div>
        </div>
      </ng-container>
      <!--  Pending orders tab    -->
      <ng-container
          *ngIf="!showInitialLoader()
                  && pendingOrders.length > 0
                  && currentTab === HospitalOrderTabs.PENDING_ORDERS">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <span class="section__title" *ngIf="companyRoles && checkRolesForAccess()">
                {{ 'orders-page.approver-pending-orders' | translate }}
            </span>
            <span class="section__title" *ngIf="companyRoles && !checkRolesForAccess()">
                {{ 'orders-page.pending-orders' | translate }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center pending-orders" [attr.data-test]="'desc-pendingOrders'">
          <app-access-control [description]="true">
            <ng-container ngProjectAs="[slot][content]">
              <div class="col-12 col-lg-10" *ngIf="pendingOrders.length > 0">
                <app-pending-orders-list
                    [orders]="pendingOrders"
                    [loading]="pendingOrdersLoading"
                    [tabsOpened]="openedTabsForPendingOrders"
                    [roleAccess]="checkRolesForAccess()"
                    (refreshOrdersEmitter)="reloadDataAfterDeletingItemFromPendingOrder()"
                    (openTabIdEmitter)="retrievePendingOrderOpenedTabId($event)"
                >
                </app-pending-orders-list>
              </div>
            </ng-container>
          </app-access-control>
        </div>
      </ng-container>
      <!--   Previous orders tab   -->
      <ng-container
          *ngIf="!showInitialLoader()
                 && (ordersHistoryList.length > 0 || onlyMineOrders)
                 && currentTab === HospitalOrderTabs.PREVIOUS_ORDERS">
        <div class="row justify-content-center">
          <div class="previous-orders__section col-12 col-lg-10">
            <span class="section__title" *ngIf="companyRoles && checkRolesForAccess()">
                {{ 'orders-page.approver-previous-orders' | translate }}
            </span>
            <span class="section__title" *ngIf="companyRoles && !checkRolesForAccess()">
                {{ 'orders-page.previous-orders' | translate }}
            </span>
            <ng-container *ngIf="!isGuest &&
                          (ordersHistoryList.length > 0 || onlyMineOrders)">
              <div
                  class="section__title--only-mine"
                  [ngClass]="{'filtering': filteringHistoryInProgress}"
                  *ngIf="companyRoles && checkRolesForAccess()">
                <input
                    id="show-mine-checkbox"
                    type="checkbox"
                    name="show-mine-checkbox"
                    class="checkbox"
                    [disabled]="orderHistoryListLoading"
                    [checked]="onlyMineOrders"
                    (change)="selectOnlyUserOrders();"/>
                <label for="show-mine-checkbox">{{ 'orders-page.only-my-orders-checkbox' | translate}}</label>
              </div>
              <div class="js-search-field search-field textfield textfield--with-prefix">
                <span class="textfield__prefix icon-search"></span>
                <input
                    id="search"
                    name="search"
                    type="search"
                    class="textfield__input search-field__input"
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="{{ 'orders-page.search' | translate }}"
                    [disabled]="orderHistoryListLoading"
                    [(ngModel)]="searchPreviousOrder"/>
                <label for="search" style="display: none"></label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row justify-content-center previous-orders" [attr.data-test]="'desc-previousOrders'">
          <app-access-control [description]="true">
            <ng-container ngProjectAs="[slot][content]">
              <div class="col-12 col-lg-10" *ngIf="ordersHistoryList.length > 0">
                <app-previous-orders-list
                    *ngIf="!orderHistoryListAtPageLoading else newPagePreviousOrdersLoading"
                    [loading]="orderHistoryListLoading"
                    [orders]="ordersHistoryList"
                    [roleAccess]="checkRolesForAccess()"
                    [searchValue]="searchPreviousOrder">
                </app-previous-orders-list>
                <ng-template #newPagePreviousOrdersLoading >
                  <div class="loader__content">
                    <div class="loading-animation"></div>
                  </div>
                </ng-template>
                <app-pagination-linkless
                    [pageCount]="ordersNumPages"
                    [currentPage]="ordersCurrentPage"
                    (pageChange)="goToOrdersHistoryPage($event)">

                </app-pagination-linkless>
              </div>
            </ng-container>
          </app-access-control>
        </div>
      </ng-container>

      <!--   Previous quotes tab   -->
      <ng-container *ngIf="
                    !showInitialLoader()
                    && previousQuotes.length > 0
                    && currentTab === HospitalOrderTabs.PREVIOUS_QUOTES">
        <div class="row justify-content-center">
          <div class="previous-orders__section col-12 col-lg-10">
            <span class="section__title">
                {{ 'orders-page.previous-quotes' | translate }}
            </span>
            <ng-container *ngIf="!isGuest && previousQuotes.length > 0">
              <div class="js-search-field search-field textfield textfield--with-prefix">
                <span class="textfield__prefix icon-search"></span>
                <input
                    id="search-quote"
                    name="searchQuotes"
                    type="search"
                    class="textfield__input search-field__input"
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="{{ 'orders-page.search-quotes' | translate }}"
                    [disabled]="previousRequestsLoading"
                    [(ngModel)]="searchPreviousQuotes"/>
                <label for="search-quote" style="display: none"></label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row justify-content-center previous-orders" [attr.data-test]="'desc-previousQuotes'">
          <app-access-control [description]="true">
            <ng-container ngProjectAs="[slot][content]">
              <div class="col-12 col-lg-10" *ngIf="previousQuotes.length > 0">
                <app-previous-quotes-list
                    [orders]="previousQuotes"
                    [loading]="previousRequestsLoading"
                    [searchValue]="searchPreviousQuotes"
                    [roleAccess]="checkRolesForAccess()">
                </app-previous-quotes-list>
              </div>
            </ng-container>
          </app-access-control>
        </div>
      </ng-container>
      <!--   Previous requests tab   -->
      <ng-container
          *ngIf="!showInitialLoader()
                  && previousRequests.length > 0
                  && currentTab === HospitalOrderTabs.PREVIOUS_REQUESTS">
        <div class="row justify-content-center">
          <div class="previous-orders__section col-12 col-lg-10">
            <span class="section__title">
                {{ 'orders-page.previous-request' | translate }}
            </span>
            <ng-container *ngIf="!isGuest && previousRequests.length > 0">
              <div class="js-search-field search-field textfield textfield--with-prefix">
                <span class="textfield__prefix icon-search"></span>
                <input
                    id="search-request"
                    name="searchRequest"
                    type="search"
                    class="textfield__input search-field__input"
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="{{ 'orders-page.search-request' | translate }}"
                    [disabled]="previousRequestsLoading"
                    [(ngModel)]="searchPreviousRequest"/>
                <label for="search-request" style="display: none"></label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row justify-content-center previous-orders" [attr.data-test]="'desc-previousRequests'">
          <app-access-control [description]="true">
            <ng-container ngProjectAs="[slot][content]">
              <div class="col-12 col-lg-10" *ngIf="previousRequests.length > 0">
                <app-previous-requests-list
                    [orders]="previousRequests"
                    [loading]="previousRequestsLoading"
                    [searchValue]="searchPreviousRequest"
                    [roleAccess]="checkRolesForAccess()">
                </app-previous-requests-list>
              </div>
            </ng-container>
          </app-access-control>
        </div>
      </ng-container>
      <div class="row justify-content-center" *ngIf="showOrdersOrRequests()">
        <div class="col-12 col-lg-10">
          <p>{{ 'orders-page.no-history' | translate }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</app-access-control>
