<div class="configuration-summary">
  <app-contract-steps-bar [stepNo]="3" [isConfigSummary]="true"></app-contract-steps-bar>
  <div class="configuration-summary__content" *ngIf="!loading; else loader">
    <ng-container *ngIf="isCpqConfigInUrl; else noRequiredDataInUrl">
      <div class="configuration-summary__overview">
        <div class="configuration-summary__description">
          <h4>{{ 'configuration-summary.title' | translate }}</h4>
          <div class="download-configuration">
            <ng-container *ngIf="!configurationGenerationInProgress; else loadingSpinner">
              <button
                  class="download-button"
                  [attr.data-test]="'button-downloadConfiguration'"
                  (click)="downloadConfiguration()">
                <span class="icon-download"></span>
                {{ 'configuration-summary.download-configuration' | translate }}
              </button>
            </ng-container>
            <ng-template #loadingSpinner>
              <div class="btn-spinner">
                <div class="loading-animation"></div>
              </div>
            </ng-template>
          </div>
          <p>{{ 'configuration-summary.subtitle' | translate }}</p>
        </div>
        <h6>{{ 'configuration-summary.contract-information' | translate }}</h6>
        <div class="configuration-summary__details">
          <div class="base-information" *ngFor="let item of contractSystemInformation.details">
            <p>{{ item.label | translate }}<span>{{ item.data }}</span></p>
          </div>
        </div>
        <div class="configuration-summary__address">
          <h6>{{ 'configuration-summary.sold-address' | translate }}
            <app-tooltip
                text="{{ 'configuration-summary.sold-address-info' | translate }}"
                link="icon">
            </app-tooltip>
          </h6>
          <span class="sold-to-address">{{ contractSystemInformation.address }}</span>
          <button
              type="button"
              class="report-wrong-address action" [attr.data-test]="'button-reportWrongAddress'"
              (click)="showModalWrongAddress()">
            {{ 'configuration-summary.report-button' | translate }}
            <span class="icon-arrow-right-small"></span>
          </button>
          <span *ngIf="!contractSystemInformation.address" class="sold-to-address-notice">
            <span class="icon-warning icon-warning__circle"></span>
            {{ 'configuration-summary.sold-to-address-notice' | translate }}
          </span>
        </div>
        <app-configuration-summary-list *ngIf="products" [products]="products">
        </app-configuration-summary-list>
        <button class="button edit-all-selections" [attr.data-test]="'button-editAllSelection'" (click)="editAllSections()">
          {{ 'configuration-summary.edit-button' | translate }}
        </button>
      </div>
      <div class="configuration-summary__summary" *ngIf="priceSummary">
        <h6>{{ 'configuration-summary.summary-annotation' | translate }}</h6>
        <button
            class="button button--primary" [attr.data-test]="'button-goToQuoteSummary'"
            (click)="goToQuoteSummary()">
          {{ 'configuration-summary.quote-summary-button' | translate }}
        </button>
        <div class="list-price">
          <span>{{ 'configuration-summary.price-label' | translate }}</span>
          <span>{{ priceSummary.ListPrice | currency: undefined: undefined: 0 }}</span>
        </div>
        <div class="discount-price" *ngIf="priceSummary.ContractReduction > 0">
          <span>{{ 'configuration-summary.discount-price-label' | translate }}</span>
          <span>- {{ priceSummary.ContractReduction  | currency: undefined: undefined: 0 }}</span>
        </div>
        <div class="discount-price" *ngIf="priceSummary.PromocodeReduction > 0">
          <span>{{ 'configuration-summary.promo-code-label' | translate }}</span>
          <span>- {{ priceSummary.PromocodeReduction  | currency: undefined: undefined: 0 }}</span>
        </div>
        <div class="discount-price" *ngIf="priceSummary.ContractReduction > 0">
          <div class="discount-notice">{{ 'configuration-summary.discount-info-2' | translate }}</div>
        </div>
        <div class="discount-info" *ngIf="priceSummary.ContractReduction > 0 && priceSummary.PromocodeReduction === 0; else discountAndPromo">
          {{ 'configuration-summary.discount-info-1' | translate }}
        </div>
        <ng-template #discountAndPromo>
          <div class="discount-info">
            {{ 'configuration-summary.discount-info-1-with-edit' | translate }}
          </div>
        </ng-template>
        <div class="list-price">
          <span>{{ 'configuration-summary.annual-price-label' | translate }}</span>
          <span>{{ priceSummary.NetPrice  | currency: undefined: undefined: 0 }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noRequiredDataInUrl>
      <div class="error">
        <div class="error__title">{{ 'configuration-summary.page-error-title' | translate }}</div>
        <div class="error__message">{{ 'configuration-summary.page-error-message' | translate }}</div>
      </div>
    </ng-template>
  </div>
</div>
<app-report-wrong-address-modal
    [userLoggedInRoles]="loggedUserRoles"
    [showModal]="showModalAddressWrong"
    (closeModal)="showModalAddressWrong = false"
    [addressesList]="businessAddresses"
    [cpqOnly]="true"
    [functionalLocations]="getMultipleFunctionalLocations(cart)"
    [quoteId]="cart?.id || ''"
    [cpqLabelName]="'quote-summary.sold-to-address' | translate"
    [cpqAddressType]="addressType"
    [roleSpecificMessage]="true">
</app-report-wrong-address-modal>

<ng-template #loader>
  <div class="configuration-summary__loader">
    <div class="loading-animation"></div>
  </div>
</ng-template>
