import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { MathUtils } from '../../../utils/math.utils';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { EProductDetails } from '../../../configurations/product-details';
import { environment } from '../../../../environments/environment';
import { AppUtils } from '../../../utils/app.utils';
import { PriceUtils } from '../../../utils/price.utils';
import { EFeatureToggles } from '../../../configurations/common';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-basic-product-pdp',
  templateUrl: './basic-product-component-pdp.component.html',
  styleUrls: ['./basic-product-component-pdp.component.scss'],
})
export class BasicProductPdpComponent implements OnInit {
  openZoomedPicture = false;
  isQuantityValid = true;
  priceUtils = PriceUtils;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  @Input() wishlists;
  @Input() isInStore;
  @Input() sku;
  @Input() defaultPrice;
  @Input() productInfoLoaded;
  @Input() secondPrice;
  @Input() concreteSku;
  @Input() abstractProduct;
  @Input() labels;
  @Input() defaultImg;
  @Input() largeUrls;
  @Input() addNewModalActive;
  @Input() productQuantity;
  @Input() isAddToCartInProgress$;
  @Input() smallUrls;
  @Input() mainPicture;
  @Input() productDetailsEnum;
  @Input() installBaseProducts;
  @Input() showPriceDisclaimer;
  @Input() displayConfig;
  @Input() loadingCartDataInProgress;
  @Output() addNewModalWishlist = new EventEmitter<any>();
  @Output() addProductToCartSubmit = new EventEmitter<any>();
  @Output() productQuantityChange = new EventEmitter<number>();

  constructor(
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
  }

  trackInstalledBase(installedBase): void {
    this.catalogFacade.setInstalledBase(installedBase);
    this.analyticsService.trackInstalledBase(installedBase.attributes.nameEnUs, installedBase.id);
  }

  tracking(name: string): void {
    this.analyticsService.setProducts(this.abstractProduct);
    this.analyticsService.trackAccordion(name);
  }

  addToWishlist(): void {
    this.addNewModalWishlist.emit();
  }

  displayProductDetail(section: EProductDetails): boolean {
    return this.displayConfig.includes(section);
  }

  setLargeUrl(index: number): void {
    this.mainPicture = this.largeUrls[index];
  }

  changeProductQuantity(productQuantity: number): void {
    this.checkIfPositiveInteger(productQuantity);
    if (this.isQuantityValid) {
      this.productQuantityChange.emit(productQuantity);
    }
  }

  private checkIfPositiveInteger(productQuantity: number): void {
    this.isQuantityValid = MathUtils.checkIfNumeric(productQuantity) && productQuantity > 0;
  }

  openZoomPicture(): void {
    if (this.mainPicture !== this.defaultImg) {
      this.openZoomedPicture = !this.openZoomedPicture;
      if (this.openZoomedPicture) {
        this.analyticsService.setProducts(this.abstractProduct);
        this.analyticsService.trackProduct('image.enlarge', this.abstractProduct);
      }
    }
  }

  addProductToCart(): void {
    this.addProductToCartSubmit.emit();
  }

  shouldShowAvailabilityDetails(): boolean {
    const currentStore = AppUtils.getCurrentStore();
    return !environment.features.excludeTax.includes(currentStore.storeId);
  }

  /**
   * Check if product is discontinued (sap_p40_sales_status is '01' or '02')
   *
   * @return {boolean}
   */
  isProductDiscontinued(): boolean {
    switch (this.abstractProduct?.attributes?.sap_p40_sales_status) {
      case '01':
        return true;
      case '02':
        return true;
      default:
        return false;
    }
  }

  /**
   * Check if product has subsequent materials
   *
   * @return {boolean}
   */
  areSubsequentMaterialsAvailable(): boolean {
    return !!(this.abstractProduct?.attributes?.sap_p40_sales_status === '02'
      && this.abstractProduct?.attributes?.sap_p40_subsequent_material);
  }

  /**
   * Get subsequent materials for product
   * Handle multiple cases: no subsequent material, one subsequent material, multiple subsequent materials
   * Add leading zeros to subsequent material number if needed
   *
   * @return {string[]}
   */
  getSubsequentMaterials(): string[] {
    if (this.areSubsequentMaterialsAvailable()) {
      if (this.abstractProduct.attributes.sap_p40_subsequent_material.includes(',')) {
        return this.abstractProduct.attributes.sap_p40_subsequent_material.split(',').map(subsequentMaterial =>
          subsequentMaterial.padStart(8, '0'),
        );
      }

      return [this.abstractProduct.attributes.sap_p40_subsequent_material.padStart(8, '0')];
    }

    return [];
  }
}
