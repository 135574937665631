export const TermConstants =
  {
    termKeys: {
      TERMS_AND_CONDITIONS: {
        cmsKey: 'TERMS_AND_CONDITIONS',
        termsKey: 'terms_and_conditions',
        urlKey: 'terms-and-conditions'
      },
      TERMS_OF_USE: {
        cmsKey: 'TERMS_OF_USE',
        termsKey: 'terms_of_use',
        urlKey: 'terms-of-use'
      },
      TERMS_OF_USE_GENERAL: {
        cmsKey: 'TERMS_OF_USE_GENERAL',
        termsKey: 'terms_of_use_general',
        urlKey: 'terms-of-use-general'
      },
      PRIVACY_POLICY: {
        cmsKey: 'PRIVACY_POLICY',
        termsKey: 'privacy_policy',
        urlKey: 'privacy-policy'
      },
      SIGNED_CONTRACT_SEND: {
        cmsKey: 'SIGNED_CONTRACT_SEND',
        termsKey: 'signed_contract_sent',
        urlKey: undefined
      },
      PURCHASE_ORDER_PENDING: {
        cmsKey: 'PURCHASE_ORDER_PENDING',
        termsKey: 'purchase_order_pending',
        urlKey: undefined
      },
      PURCHASE_ORDER_SUBMITTED: {
        cmsKey: 'PURCHASE_ORDER_SUBMITTED',
        termsKey: 'purchase_order_submitted',
        urlKey: undefined
      },
      PURCHASE_ORDER_NOT_REQUIRED: {
        cmsKey: 'PURCHASE_ORDER_NOT_REQUIRED',
        termsKey: 'purchase_order_not_required',
        urlKey: undefined
      }
    }
  };
