<form [formGroup]="ivkForm">
  <div class="row">
    <app-select-box
        class="col-12"
        [form]="ivkForm"
        [modelValue]="ivkForm.value.system"
        [options]="systems"
        [inputName]="'system'"
        label="{{ 'request-details.select-system' | translate }}"
        [isRequired]="true"
        [search]="true"
        [loading]="installBaseLoading"
        [searchPlaceholder]="'request-details.search-box-search-system-placeholder' | translate"
        (selected)="setSystemIdValue($event)">
    </app-select-box>
  </div>
  <div class="row">
    <div class="checkout__field col-md-12" *ngIf="notFoundSelected(ivkForm.value.system)">
      <app-textfield
          class="checkout__textfield col-12"
          [form]="ivkForm"
          [modelValue]="ivkForm.value.siemensEquipmentId"
          [cssStyles]="{wrapper: 'textfield-with-prefix textfield-siemensEquipmentId'}"
          [inputName]="'siemensEquipmentId'"
          label="{{ systemIdFieldLabel() | translate }}"
          [isRequired]="true"
          [ngClass]="{'is-invalid': ivkForm.get('siemensEquipmentId').errors && ivkForm.get('siemensEquipmentId').touched}"
          [readOnly]="!notFoundSelected(ivkForm.value.system)"
          (fieldFilled)="setOrderFormValue($event)"
          trim
          trimWhitespaces>
      </app-textfield>
    </div>
    <div class="checkout__field col-md-12"
         *ngIf="notFoundSelected(ivkForm.value.system) || notInstallBaseRelatedSelected(ivkForm.value.system)"
    >
      <app-select-box
          [form]="ivkForm"
          [modelValue]="ivkForm.value.businessUnit"
          [options]="businessUnits"
          [inputName]="'businessUnit'"
          label="{{ 'request-details.select-unit' | translate }}"
          [isRequired]="notFoundSelected(ivkForm.value.system) || notInstallBaseRelatedSelected(ivkForm.value.system)"
          [search]="true"
          [searchPlaceholder]="'request-details.search-box-search-system-placeholder' | translate"
          [loading]="businessUnitLoading"
          (selected)="setCompanyValues($event)">
      </app-select-box>
    </div>
    <div class="checkout__field col-md-12"
         *ngIf="!(notFoundSelected(ivkForm.value.system) || notInstallBaseRelatedSelected(ivkForm.value.system)) || notFoundSelected(ivkForm.value.businessUnit)"
    >
      <app-textfield
          class="checkout__textfield col-12"
          [form]="ivkForm"
          [modelValue]="ivkForm.value.companyBusinessUnit"
          [cssStyles]="{wrapper: 'textfield-with-prefix textfield-company'}"
          [inputName]="'companyBusinessUnit'"
          label="{{ 'request-details.enter-company-name' | translate }}"
          [isRequired]="notFoundSelected(ivkForm.value.businessUnit)"
          [ngClass]="{'is-invalid': ivkForm.get('companyBusinessUnit').errors && ivkForm.get('companyBusinessUnit').touched}"
          [readOnly]="!(notFoundSelected(ivkForm.value.system) || notInstallBaseRelatedSelected(ivkForm.value.system))"
          (fieldFilled)="setOrderFormValue($event)">
      </app-textfield>
    </div>
  </div>
</form>
