import { IStore } from '../app/models/settings.model';

export const environment = {
  defaultStore: 'BR',
  defaultLang: 'en',
  stores: [
    {
      marketCode: 'au',
      storeId: 'AU',
      storeDefaultCurrency: 'AUD',
      storeDefaultPriceMode: 'NET_MODE',
      name: 'Australia',
      dateFormat: 'dd-MM-yyyy',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'en-AU',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
        {
          id: 'terms_and_conditions',
          lang: 'en',
          value: 'https://marketing.webassets.siemens-healthineers.com/e2ca3b6f2c0ec912/cb20fea682b7/20220517-shs-au-conditions-of-supply-and-installation.pdf',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Healthcare Pty Ltd',
          address1: 'Level 3, 141 Camberwell Rd, Hawthorn East, Victoria, 3123, Australia',
          emailAddress: 'shop.au.func@siemens-healthineers.com',
        },
      },
      deliveryAddressCountries: [
        {name: 'AUSTRALIA', value: 'AU', isDefault: true},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [],
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_anatomic_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_system_area_mapped',
        'sap_p40_modality_id_mapped',
      ],
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: '',
      },
    },
    {
      marketCode: 'br',
      storeId: 'BR',
      storeDefaultCurrency: 'BRL',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'Brazil',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'pt-BR',
          code: 'pt',
          name: 'Portuguese',
          defaultCurrencyLocale: 'pt',
        },
        {
          id: 'en-BR',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
        {
          id: 'privacy_policy',
          lang: 'pt',
          value: 'https://www.siemens-healthineers.com/br/siemens-website-privacy-policy',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Healthcare Diagnósticos Ltda',
          address1: 'CNPJ 01.449.930/0001-90 Av. Mutinga 3800,',
          address2: ' São Paulo – SP 05110-902',
          phoneNumber: '08000554838',
          emailAddress: 'shop.br@siemens-healthineers.com',
        },
      },
      deliveryAddressCountries: [
        {name: 'BRAZIL', value: 'BR', isDefault: true},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [
        'shop-cart',
      ],
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_system_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_anatomic_area_mapped',
        'mcm_gg',
      ],
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: '',
      },
    },
    {
      marketCode: 'ca',
      storeId: 'CA',
      storeDefaultCurrency: 'CAD',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'Canada',
      dateFormat: 'yyyy-MM-dd',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'en-CA',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
        {
          id: 'fr-CA',
          code: 'fr',
          name: 'French',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
        {
          id: 'privacy_policy',
          lang: 'fr',
          value: 'https://www.siemens-healthineers.com/fr-ca/siemens-website-privacy-policy',
        },
        {
          id: 'cookie_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/cookie',
        },
        {
          id: 'cookie_policy',
          lang: 'fr',
          value: 'https://www.siemens-healthineers.com/fr/cookie',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Healthcare Limited |',
          address1: '1577 North Service Road East, Oakville, ',
          address2: 'ON, L6H 0H6, ',
          address3: 'Canada',
          emailAddress: 'teamplayfleet.ca@siemens-healthineers.com',
          phoneNumberFormat: '3|3|4',
          prefix: '+1',
          delimiter: '-',
          needHelp: {
            questionsAboutParts: {
              phoneNumber: '8003596709',
              phoneNumberAdditionalInfo: '(press 2 for parts)',
              directSalesEmailAddress: 'customercarecentre.ca@siemens-healthineers.com',
            },
            websiteAndTechnicalSupport: {
              emailAddress: 'teamplayfleet.ca@siemens-healthineers.com',
            },
          },
        },
        fr: {
          companyName: 'Siemens Santé limitée |',
          address1: '1577 North Service Road East, Oakville, ',
          address2: 'ON, L6H 0H6, ',
          address3: 'Canada',
          emailAddress: 'teamplayfleet.ca@siemens-healthineers.com',
          phoneNumberFormat: '3|3|4',
          prefix: '+1',
          delimiter: '-',
          needHelp: {
            questionsAboutParts: {
              phoneNumber: '8003596709',
              phoneNumberAdditionalInfo: '(appuyez sur 2 pour les pièces)',
              directSalesEmailAddress: 'customercarecentre.ca@siemens-healthineers.com',
            },
            websiteAndTechnicalSupport: {
              emailAddress: 'teamplayfleet.ca@siemens-healthineers.com',
            },
          },
        },
      },
      deliveryAddressCountries: [
        {name: 'CANADA', value: 'CA', isDefault: true},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [
        'my-orders',
      ],
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_anatomic_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_system_area_mapped',
        'sap_p40_modality_id_mapped',
      ],
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: 'Standard Delivery / Livraison standard',
      },
    },
    {
      marketCode: 'gb',
      storeId: 'GB',
      storeDefaultCurrency: 'GBP',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'Great Britain',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'en-GB',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Healthcare Limited |',
          address1: 'Parkview, Watchmoor Park, Camberley, ',
          address2: 'GU15 3YL, United Kingdom',
          emailAddress: 'csquotations.gb@siemens-healthineers.com',
        },
      },
      deliveryAddressCountries: [
        {name: 'UNITED KINGDOM', value: 'GB', isDefault: true},
        {name: 'IRELAND', value: 'IE'},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: true,
      hiddenStoreUserMenu: [
        'shop-cart',
      ],
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_system_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_anatomic_area_mapped',
        'mcm_gg',
      ],
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: '',
      },
    },
    {
      marketCode: 'in',
      storeId: 'IN',
      storeDefaultCurrency: 'INR',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'India',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'en-IN',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Healthcare Private Limited,',
          address1: '9th Floor North Tower, Godrej One, ',
          address2: 'Pirojshanagar, Off Eastern Express Highway, ',
          address3: 'Vikhroli East, Mumbai 400079, India',
          phoneNumber: '18002585828',
          phoneNumberFormat: '4|3|4',
          emailAddress: 'shop.in.func@siemens-healthineers.com',
        },
      },
      deliveryAddressCountries: [
        {name: 'India', value: 'IN', isDefault: true},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [
        'shop-cart',
      ],
      addOrderTypeToAdlytics: true,
      catalogFilters: [
        'mcm_webshop_system_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_anatomic_area_mapped',
        'mcm_gg',
      ],
      emailGrievancesOfficer: 'contactshop.func.in@siemens-healthineers.com',
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: '',
      },
    },
    {
      marketCode: 'us',
      storeId: 'US',
      storeDefaultCurrency: 'USD',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'United States',
      dateFormat: 'MM/dd/yyyy',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'en-US',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [
        {
          id: 'privacy_policy',
          lang: 'en',
          value: 'https://www.siemens-healthineers.com/siemens-website-privacy-policy',
        },
      ],
      address: {
        default: {
          companyName: 'Siemens Medical Solutions USA, Inc. |',
          address1: '40 Liberty Boulevard, Malvern, ',
          address2: 'PA 19355, ',
          address3: 'USA',
          phoneNumber: '8335271090',
          emailAddress: 'shop.us@siemens-healthineers.com',
          phoneNumberFormat: '3|3|4',
          prefix: '+1',
          delimiter: '-',
          needHelp: {
            questionsAboutParts: {
              phoneNumber: '8008887436',
              directSalesEmailAddress: 'usccdirectsalessporders.team@siemens-healthineers.com',
              partsClarificationEmailAddress: 'cccpartsclarification.team@siemens-healthineers.com',
            },
            questionsAboutContracts: {
              phoneNumber: '8335271090',
              emailAddress: 'shop.us@siemens-healthineers.com',
            },
            websiteAndTechnicalSupport: {
              phoneNumber: '8335271090',
              emailAddress: 'shop.us@siemens-healthineers.com',
            },
          },
        },
      },
      deliveryAddressCountries: [
        {name: 'UNITED STATES', value: 'US', isDefault: true},
      ],
      active: true,
      redirect: true,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [
        'my-orders',
      ],
      ecommerceTeamEmailAddress: 'shop.us@siemens-healthineers.com',
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_anatomic_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_system_area_mapped',
        'sap_p40_modality_id_mapped',
      ],
      defaultPriceDivisorValue: 100,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        // US Store: set to Standard for Clickdummy
        name: 'Standard',
      },
    },
    {
      marketCode: 'jp',
      storeId: 'JP',
      storeDefaultCurrency: 'JPY',
      storeDefaultPriceMode: 'GROSS_MODE',
      name: 'Japan',
      dateFormat: 'yyyy/MM/dd',
      timeFormat: 'HH:mm',
      languages: [
        {
          id: 'ja-JP',
          code: 'ja',
          name: 'Japanese',
          defaultCurrencyLocale: 'ja',
        },
        {
          id: 'en-JP',
          code: 'en',
          name: 'English',
          defaultCurrencyLocale: 'en',
        },
      ],
      externalUrls: [],
      address: {
        default: {
          companyName: 'Siemens Healthcare K.K.',
          address1: '〒141-8644 東京都品川区大崎1丁目11番1号 ゲートシティ大崎ウエストタワー',
          emailAddress: 'webshop.jp@siemens-healthineers.com',
        },
      },
      deliveryAddressCountries: [
        {name: 'JAPAN', value: 'JP', isDefault: true},
      ],
      active: true,
      redirect: false,
      showPriceDisclaimer: false,
      hiddenStoreUserMenu: [],
      addOrderTypeToAdlytics: false,
      catalogFilters: [
        'mcm_webshop_anatomic_area_mapped',
        'mcm_webshop_clinical_field_mapped',
        'mcm_webshop_system_area_mapped',
        'mcm_gg',
      ],
      defaultPriceDivisorValue: 1,
      paginationSettingsPerFeature: {
        orders: {limitPerPage: 20},
        serviceOrders: {limitPerPage: 20},
        sparePartOrders: {limitPerPage: 20},
      },
      defaultShipmentMethod: {
        name: '',
      },
    },
  ] as IStore[],
};
