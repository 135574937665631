import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MarketingFacade } from '../../../facades/marketing.facade';
import { IRfqCartItem } from '../../../models/rfq.models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-my-request-details',
  templateUrl: './my-request-details.component.html',
  styleUrls: ['./my-request-details.component.scss'],
})
export class MyRequestDetailsComponent implements OnInit, OnDestroy {
  requestItems: IRfqCartItem[];
  itemsInQueueToCart: Array<string>;
  itemsInQueueToCartSubscription: Subscription;
  taxNumber: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() request;
  @Input() isOpen: boolean;

  constructor(
    private marketingFacade: MarketingFacade,
  ) {
  }

  ngOnInit(): void {
    this.itemsInQueueToCartSubscription = this.selectItemsInQueueToCart();
    this.requestItems = this.request.attributes.shownVersion.cart.items;
    this.taxNumber = this.request.attributes.taxNumber;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectItemsInQueueToCart(): Subscription {
    return this.marketingFacade.selectItemsInQueueToCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.itemsInQueueToCart = state;
    });
  }

  addProductToCart(product: string, productName: string): void {
    this.marketingFacade.addProductToCart({sku: product, name: productName}, true);
  }

  renderEmptyAddressField(field: string): string {
    return field === 'empty' ? '' : field;
  }

  isAddressFieldEmpty(field: string): boolean {
    return field === 'empty' || field === '' || !field;
  }

  isItemInProgress(sku): boolean {
    return this.itemsInQueueToCart.includes(sku);
  }
}
