<div [appLoadingAnimation]="!isLoaded" class="cms-page__content">
  <div class="container">
    <ng-container *ngIf="isLoaded">
      <div class="logo logo--compact terms-logo"></div>
      <h2 class="logo-color" [innerHTML]="cmsPageData.attributes.title"></h2>
      <div [innerHTML]="pageContent"></div>
      <ng-container *ngIf="userLoggedIn && !userAccepted && isOnToU">
        <button id="btnAcceptToU" type="submit" (click)="onClickAccept()" class="button button--primary" [disabled]="!onScroll() || isAccepting">
          {{ 'terms.accept' | translate }}
        </button>
        <button id="btnRejectToU" type="submit" (click)="onClickReject()" class="button button--secondary">
          {{ 'terms.reject' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
