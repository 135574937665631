<ng-container *ngIf="links.length !== 0">
  <hr>
  <div class="social-media-footer">
    <div class="social-media-footer__item" *ngFor="let link of links">
      <ng-container *ngIf="link.nodeType === 'label'">
        <p class="social-media-footer__title" [ngClass]="link.cssClass">{{ link.title }}</p>
        <div class="social-media-footer__content">
          <div *ngFor="let child of link.children">
              <a *ngIf="child.isActive"
                 [ngClass]="!isIcon(child.cssClass) ? child.cssClass : 'circle'"
                 [href]="child.url"
                 [alt]="child.title"
                 [target]="'_blank'">
                <span [ngClass]="isIcon(child.cssClass) ? child.cssClass : ''"></span>
                {{ (isIcon(child.cssClass)) ? '' : child.title }}</a>
            </div>
         </div>
      </ng-container>
    </div>
  </div>
</ng-container>
