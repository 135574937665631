import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IHybrisOrder } from '../../models/order.models';
import { EMultiCartTabs, ESparePartsOrderHistoryTabs } from '../../configurations/common';
import { OrdersFacade } from '../../facades/orders.facade';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'app-spare-part-hybris-order-details',
  templateUrl: './spare-part-hybris-order-details.component.html',
  styleUrls: ['./spare-part-hybris-order-details.component.scss'],
})
export class SparePartHybrisOrderDetailsComponent implements OnInit, OnDestroy {
  hybrisOrderDetails: IHybrisOrder;
  orderDetailsAccordion: boolean = false;
  isLoaded: boolean = false;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ordersFacade: OrdersFacade
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getHybrisOrderById(params.orderId);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHybrisOrderById(hybrisOrderId: string): void {
    this.ordersFacade.selectHybrisOrderById(hybrisOrderId).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(hybrisOrder => {
      if (hybrisOrder) {
        this.hybrisOrderDetails = hybrisOrder;
        this.isLoaded = true;
      }
    });
  }

  getFormattedStatus(status: string): string {
    return StringUtils.replaceUnderscores(status, ' ').split(' ').map(statusPart => {
      return statusPart.charAt(0).toUpperCase() + statusPart.slice(1).toLowerCase();
    }).join(' ');
  }

  getTrackingNumbers(item: any): string[] {
    if (item.trackingNumber) {
      return item.trackingNumber.split(';');
    }

    return [];
  }

  toggleOrderDetailsAccordion(): void {
    this.orderDetailsAccordion = !this.orderDetailsAccordion;
  }

  redirectBackToPurchaseActivity(): void {
    this.router.navigate(
      ['/purchase-activity'],
      {
        queryParams: {
          tab: EMultiCartTabs.PARTS,
          subtab: ESparePartsOrderHistoryTabs.ARCHIVE
        }
      }
    );
  }
}
