<div class="accordion orders__table">
  <div class="orders-table__header"  *ngIf="(orders | searchFilter:searchValue).length > 0; else noOrders">
    <div class="width-15">{{ 'orders-page.order' | translate }}</div>
    <div class="width-25">{{ 'orders-page.date' | translate }}</div>
    <div class="width-25 padding-left-2 text-align-left">{{ 'orders-page.status' | translate }}</div>
    <div class="width-20 padding-left-3">{{ 'orders-page.total' | translate }}</div>
    <div class="width-10">&nbsp;</div>
  </div>
  <ng-template #noOrders>
    <span>{{'orders-page.no-orders-matching-filter-message' | translate}}</span>
  </ng-template>
  <div class="order-history__table">
    <div class="table-items">
      <app-accordion *ngFor="let order of orders | searchFilter:searchValue">
        <ng-container ngProjectAs="[slot][header]">
          <div class="accordion__title icon-arrow-down" (click)="toggleTab(order.id)">
            <div class="order-info width-15 order-id" *ngIf="order.type !== orderTypeCarts; else cartId">
              <span class="header__label" [attr.data-test]="'label-orderId'">{{ 'orders-page.order' | translate }}:</span>
              <span [attr.data-test]="'desc-orderId'">{{ order.id }}</span>
            </div>
            <ng-template #cartId>
              <div class="order-info width-15 order-id">
                <span class="header__label" [attr.data-test]="'label-orderId'">{{ 'orders-page.order' | translate }}:</span>
                <span [attr.data-test]="'desc-orderId'">#{{ order.id.split('-')[0] }}</span>
              </div>
            </ng-template>
            <div class="order-info width-25 order-date">
              <span class="header__label" [attr.data-test]="'label-orderDate'">{{ 'orders-page.date' | translate }}:</span>
              <span [attr.data-test]="'desc-orderDate'">{{ ((order.type === orderTypeCarts && order.attributes.orderedAt) ? order.attributes.orderedAt : order.attributes.createdAt) | formatDate }}</span>
              <small class="user-name" [attr.data-test]="'desc-customer'">
                {{ order.attributes.customer.firstName }} {{ order.attributes.customer.lastName }}
              </small>
            </div>
            <div class="order-info width-25 order-status">
              <span class="header__label" [attr.data-test]="'label-orderStatus'">{{ 'orders-page.status' | translate }}:</span>
              <span class="text-capitalize display-flex justify-content-center flex-column">
                  <ng-container *ngIf="order.type !== orderTypeCarts">
                    <div class="order-status__icon">
                      <span class="status__icon"
                            [attr.data-test]="'icon-orderStatus'"
                            [ngClass]="getIcon(order.attributes.stateDisplayName)">
                      </span>
                      <span class="status__name" [attr.data-test]="'desc-orderStatus'">
                        {{ 'orders-page.oms.state.' + getKey(order.attributes.stateDisplayName) | translate }}
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="order.type === orderTypeCarts">
                    <div class="order__status">
                      <span class="status__icon"
                            [attr.data-test]="'icon-orderStatus'"
                            [ngClass]="getIcon(order.attributes.orderStatus)">
                      </span>
                      <span class="status__name" [attr.data-test]="'desc-orderStatus'">
                        {{ 'orders-page.oms.state.' + getKey(order.attributes.orderStatus) | translate }}
                      </span>
                    </div>
                  </ng-container>
                  <small class="user-name" [attr.data-test]="'desc-approver'" *ngIf="order.attributes.approverDetails">
                    {{ order.attributes.approverDetails.firstName }} {{ order.attributes.approverDetails.lastName }}
                  </small>
              </span>
            </div>
            <div class="order-info width-20 total">
              <span class="header__label" [attr.data-test]="'label-totalPrize'">{{ 'orders-page.total' | translate }}:</span>
              <div class="price">
                <span [attr.data-test]="'desc-totalPrize'">
                  {{ order.attributes.totals.grandTotal | currency:order.attributes.currencyIsoCode }}
                  <span *ngIf="isExcludeTaxActive()" class="tax-disclaimer">({{ 'app-product-page.incl_gst' | translate }})</span>
                </span>
                <div class="order-items-count">
                  <ng-container *ngIf="order.attributes.itemsTotalNumber > 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'orders-page.item-plural' | translate:{number: order.attributes.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="order.attributes.itemsTotalNumber === 1">
                    <span [attr.data-test]="'desc-quantity'">
                      {{ 'orders-page.item-singular' | translate:{number: order.attributes.itemsTotalNumber} }}
                    </span>
                  </ng-container>
                </div>
              </div>
              <span class="additional-info "></span>
            </div>
            <div class="width-10"></div>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content">
            <app-my-order-hist-detail
                [order]="order"
                [isOpen]="tabsOpened.includes(order.id)">
            </app-my-order-hist-detail>
          </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
