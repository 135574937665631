<div class="filters" *ngIf="filters.length > 0">
    <div
        class="filter__container"
        [ngClass]="{'is-open': openedFilters.includes(filter.name)}"
        *ngFor="let filter of filters">
        <button
            type="button"
            class="filter__label"
            (click)="showOptions(filter.name)"
            [attr.data-test]="'button-filter-' + filter.name">
          {{ filter.localizedName }}
        </button>
        <ul class="filter__options">
            <li
                *ngFor="let option of filter.values"
                class="filter__option"
                [attr.data-test]="'desc-option-' + option.value">
                <input
                    [id]="filter.name+'-'+option.value"
                    type="checkbox"
                    [name]="filter.name+'-'+option.value"
                    class="checkbox"
                    [checked]="isOptionSelected(filter.name, option.value)"
                    (click)="filterResults(filter, option)">
                <label [for]="filter.name+'-'+option.value">
                  <span [attr.data-test]="'desc-optionName'">
                    {{ option.value }}
                  </span>
                  <span [attr.data-test]="'desc-optionCount'">
                    ({{ option.doc_count }})
                  </span>
                </label>
            </li>
        </ul>
    </div>
</div>
