import { Injectable, Injector } from '@angular/core';
import { WishlistsService } from '../services/wishlists.service';
import {
  IAddNewItemToWishlistRequest,
  IBaseWishlist,
  ICreateWishlistRequest,
  IWishlist, IWishlists,
  WISHLIST_ITEMS_TYPE,
  WISHLIST_TYPE,
} from '../models/wishlist.models';
import { Observable } from 'rxjs';
import { WishlistActions } from '../actions';
import { Store } from '@ngrx/store';
import * as fromState from '../reducers';
import * as WishlistSelector from '../reducers/wishlist.reducer';

@Injectable({
  providedIn: 'root',
})
export class WishlistFacade {
  private pWishlistService: WishlistsService;

  public getService(): WishlistsService {
    if (!this.pWishlistService) {
      this.pWishlistService = this.injector.get(WishlistsService);
    }
    return this.pWishlistService;
  }

  constructor(
    private injector: Injector,
    private store: Store<fromState.State>
  ) {}

  public createWishlist(wishlistName: string): Promise<IBaseWishlist> {
    const wishlistRequest: ICreateWishlistRequest = {
      data: {
        type: WISHLIST_TYPE,
        attributes: {
          name: wishlistName
        }
      }
    };

    return this.getService().createWishlist(wishlistRequest);
  }

  public getWishlists(): Observable<IWishlists> {
    return this.getService().getWishlists();
  }

  public getWishlist(id: string): Observable<IWishlist> {
    return this.getService().getWishlist(id);
  }

  public changeWishlistName(id: string, name: string): Promise<IBaseWishlist> {
    const wishlistRequest: ICreateWishlistRequest = {
      data: {
        type: WISHLIST_TYPE,
        id,
        attributes: {
          name
        }
      }
    };

    return this.getService().editWishlist(id, wishlistRequest);
  }

  public deleteWishlist(id: string): Promise<any> {
    return this.getService().deleteWishlist(id);
  }

  public addNewItemToWishlist(wishlistId: string, sku: string): Observable<IAddNewItemToWishlistRequest> {
    const request: IAddNewItemToWishlistRequest = {
      data: {
        type: WISHLIST_ITEMS_TYPE,
        attributes: {
          sku,
          quantity: 1
        }
      }
    };
    return this.getService().addNewItemToWishlist(wishlistId, request);
  }

  public addNewItem(wishlistId: string, sku: string): void {
    this.store.dispatch(WishlistActions.addNewProductToWishlist({wishlistId, sku}));
  }

  public removeItemFromWishlist(wishlistID: string, shoppingListItemId: string): Promise<any> {
    return this.getService().removeItemFromWishList(wishlistID, shoppingListItemId);
  }

  public updateItemFromWishList(wishlistID: string, shoppingListItemId: string, sku: string, quantity: number): Observable<any> {
    return this.getService().updateItemFromWishList(wishlistID, shoppingListItemId, sku, quantity);
  }

  public removeItem(wishlistId: string, shoppingListItemId: string, sku: string): void {
    this.store.dispatch(WishlistActions.removeProductFromWishlist({wishlistId, shoppingListItemId, sku}));
  }

  public updateItem(wishlistId: string, shoppingListItemId: string, sku: string, qty: number): void {
    this.store.dispatch(WishlistActions.updateProductFromWishlist({wishlistId, shoppingListItemId, sku, qty}));
  }

  selectLastUpdatedProduct(): Observable<any> {
    return this.store.select(WishlistSelector.getLastUpdatedProduct);
  }

  selectListInProgress(): Observable<any> {
    return this.store.select(WishlistSelector.getListInProgress);
  }

  selectLoaded(): Observable<any> {
    return this.store.select(WishlistSelector.getLoaded);
  }

  dispatchUsersWishlistRetrieve(): void {
    this.store.dispatch(WishlistActions.retrieveWishlists());
  }

  selectUsersWishlists(): Observable<IBaseWishlist[]> {
    return this.store.select(WishlistSelector.getWishlists);
  }

  createNewWishlist(wishlistName: string): void{
    this.store.dispatch(WishlistActions.addNewWishlist({wishlistName}));
  }

  deleteList(wishlistID: string): void{
    this.store.dispatch(WishlistActions.deleteWishlist({wishlistID}));
  }

  isNewWishlistCreated(): Observable<boolean>{
    return this.store.select(WishlistSelector.isNewWishlistCreated);
  }

  selectAddNewInProgress(): Observable<boolean>{
    return this.store.select(WishlistSelector.selectAddNewInProgress);
  }
}
