<div class="product-page" [ngClass]="'cpq-store'">
  <app-access-control [notForViewer]="true" [description]="false">
    <ng-container ngProjectAs="[slot][nonViewerContent]">
      <app-contract-steps-bar *ngIf="showContractStepsBar()" [stepNo]="stepNumber"></app-contract-steps-bar>
    </ng-container>
  </app-access-control>
  <div class="loader__overlay" *ngIf="!productInfoLoaded; else body">
    <div class="loader__content">
      <div class="loading-animation"></div>
    </div>
  </div>
  <ng-template #body>
    <h1 class="product__title mobile" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
    <div class="row justify-content-center">
      <div class="col-xl-7 product-image__wrapper no-padding">
        <div [appLoadingAnimation]="!productInfoLoaded">
          <div class="carousel-main row">
            <div class="col-sm-12 col-md-10 product-image__main"
                 [ngClass]="{'configurable pt-xl-2': isConfigurable()}">
              <img [src]="mainPicture"
                   (click)="openZoomPicture()"
                   class="image__main"
                   [alt]="productInfoLoaded ? abstractProduct.name : ''"
                   [attr.data-test]="'img-productImage'"/>
            </div>
          </div>
          <ng-container *ngIf="openZoomedPicture">
            <div class="modal-dialog is-open"
                 tabindex="-1"
                 id="modal-zoom-picture"
                 role="dialog"
                 aria-modal="true"
                 aria-labelledby="modal-confirm-decline_title"
                 aria-describedby="modal-confirm-decline_desc"
                 aria-hidden="true"
                 data-linked="#modal-zoom-picture" (click)="openZoomPicture()">
              <div class="modal-dialog__container" role="document">
                <img [src]="mainPicture"
                     style="cursor: zoom-out;"
                     [alt]="abstractProduct.name"/>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-xl-3">
        <ng-container *ngIf="productInfoLoaded">
          <h1 class="product__title desktop" [attr.data-test]="'title-productTitle'">{{ abstractProduct.name }}</h1>
          <p [innerHTML]="abstractProduct.description" [attr.data-test]="'desc-productDescription'"></p>
          <app-access-control>
            <ng-container ngProjectAs="[slot][content]">
              <app-access-control [notForViewer]="true">
                <ng-container ngProjectAs="[slot][nonViewerContent]">
                  <ng-container *ngIf="isConfigurable();">
                    <div class="message-container">
                      <ng-container *ngIf="this.isContactUsButtonDisplay()">
                        <span class="message">
                          {{ 'app-product-page.not_yet_available' | translate }}
                        </span>
                      </ng-container>
                      <button
                          type="button"
                          [ngClass]="{'select-contract' : !isContactUsButtonDisplay()}"
                          class="button button--primary"
                          (click)="cpqButtonAction(concreteProduct)"
                          [attr.data-test]="'button-cpq'">
                        {{ cpqButtonText() | translate }}
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </app-access-control>
            </ng-container>
          </app-access-control>
        </ng-container>
      </div>
      <div class="col-xl-10">
        <div class="product-details">
          <ng-container *ngIf="productInfoLoaded">
            <div class="accordion" role="presentation">
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.OVERVIEW)"
                             (panelOpened)="tracking(productDetailsEnum.OVERVIEW.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-overview-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-overview'">
                    {{ 'app-product-page.overview' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <p class="accordion__panel__text" [attr.data-test]="'desc-overview'"
                       [innerHTML]="abstractProduct.attributes.pdp_overview_description"></p>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion *ngIf="displayProductDetail(productDetailsEnum.DETAILS)"
                             (panelOpened)="tracking(productDetailsEnum.DETAILS.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-details-button"
                      class="accordion__title icon-arrow-down"
                      aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-details'">
                    {{ 'app-product-page.details' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content" [ngClass]="'accordion-mobile'">
                      <div class="accordion__category-details">
                        <ng-container *ngFor="let attribute of getCpqItemsAttributes()">
                          <span class="accordion__category-details__item"
                                *ngIf="concreteProduct.attributes.attributes[attribute]"
                                [ngClass]="'mobile-category-details-item'">
                              <p class="accordion__category-details__title"
                                 [ngClass]="'mobile-category-details-title'">
                                {{ concreteProduct.attributes.attributeNames[attribute] }}
                              </p>
                              <app-tooltip
                                  *ngIf="hasTranslation('app-product-page.' + attribute)"
                                  class="accordion__category-details__info-icon"
                                  link="icon"
                                  text="{{ 'app-product-page.' + attribute | translate }}"></app-tooltip>
                              <p class="accordion__category-details__spec"
                                 [innerHTML]="getDetailAttribute(concreteProduct.attributes.attributes[attribute])"
                                 [ngClass]="'mobile-category-details-description'">
                              </p>
                            </span>
                        </ng-container>
                      </div>
                  </div>
                </ng-container>
              </app-accordion>
              <app-accordion
                  *ngIf="displayProductDetail(productDetailsEnum.SUITABLE_FOR)
                    && installBaseProducts
                    && installBaseProducts.length > 0
                    && !isServiceUnderConfiguration()"
                  (panelOpened)="tracking(productDetailsEnum.SUITABLE_FOR.toString())" class="basic-product-link">
                <ng-container ngProjectAs="[slot][header]">
                  <h2 id="panel-suitable-button"
                      class="accordion__title icon-arrow-down" aria-controls="panel1"
                      aria-expanded="false"
                      [attr.data-test]="'panel-suitableFor'">
                    {{ 'app-product-page.suitable-for' | translate }}
                  </h2>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <div class="accordion__panel__text install-base-pointer">
                      <ng-container *ngFor="let installedBase of installBaseProducts">
                        <p>
                          <a [routerLink]="['/installed-base']"
                             [queryParams]="{'rel-product-sysivk': installedBase.attributes.materialNumber}"
                             (click)="trackInstalledBase(installedBase)"
                             [attr.data-test]="'desc-' + installedBase.attributes.nameEnUs">
                            {{ installedBase.attributes.nameEnUs }}
                          </a>
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </app-accordion>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
