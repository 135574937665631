import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICmsPage, IContentBanner } from '../models/cms';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IDynamicAttribute } from '../models/common.models';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CmsService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private cmsPageURI = '/cms-pages/';
  private contentBannerURI = '/content-banners-by-key/';

  private static handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getCMSPage(key: string): Observable<ICmsPage> {
    const url = this.glueUrl + this.cmsPageURI + key;
    return this.httpClient.get(url)
      .pipe(
        tap(),
        map((res: IDynamicAttribute) => res.data as ICmsPage),
        catchError(CmsService.handleError),
      );
  }

  getContentBannerByKey(contentBannerKey: string): Observable<IContentBanner> {
    const url = this.glueUrl + this.contentBannerURI + contentBannerKey;
    return this.httpClient.get(url)
      .pipe(
        tap(),
        map((res: IDynamicAttribute) => res.data as IContentBanner),
        catchError(CmsService.handleError),
      );
  }
}
