import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { DeliveryDetailsService } from '../services/delivery-details.service';
import * as OrderApproveSelectors from '../reducers/order-approve.reducer';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as OrderApproveActions from '../actions/order-approve.actions';
import { IAddressData, IAddressesDataIncluded, ICheckout, ICustomAddressData } from '../models/checkout.models';
import { RfqService } from '../services';
import {
  ICreateRfQ,
  IRfqBaseInfo,
  IRfqCancelDetails,
  IRfqCreateResponse,
  IRfqDetails,
  IRfqHistory,
} from '../models/rfq.models';
import { OrdersService } from '../services/orders.service';
import { DeliveryMockFacade } from './delivery-mock.facade';
import { selectRfqHistoryData } from '../reducers/checkout.reducer';
import { CheckoutActions } from '../actions/';

@Injectable({
  providedIn: 'root',
})
export class CheckoutFacade {

  private pDeliveryDetailsService: DeliveryDetailsService;
  private pRfqService: RfqService;
  private pOrderService: OrdersService;

  public get deliveryDetailsService(): DeliveryDetailsService {
    if (!this.pDeliveryDetailsService) {
      this.pDeliveryDetailsService = this.injector.get(DeliveryDetailsService);
    }
    return this.pDeliveryDetailsService;
  }

  public get ordersService(): OrdersService {
    if (!this.pOrderService) {
      this.pOrderService = this.injector.get(OrdersService);
    }
    return this.pOrderService;
  }

  public get rfqService(): RfqService {
    if (!this.pRfqService) {
      this.pRfqService = this.injector.get(RfqService);
    }
    return this.pRfqService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>,
    private deliveryMock: DeliveryMockFacade,
  ) {
  }

  getBusinessAddresses(): Observable<any> {
    return this.deliveryDetailsService.getBusinessAddresses();
  }

  postCustomerAddresses(customerId: string, body: any): Promise<any> {
    return this.deliveryDetailsService.postCustomerAddresses(customerId, body);
  }

  postWrongAddress(data: any): Promise<any> {
    return this.deliveryDetailsService.postWrongAddress(data);
  }

  postCheckoutData(data: any, idCart: string): Promise<any> {
    return this.deliveryDetailsService.postCheckoutData(data, idCart);
  }

  getCheckoutData(data: any): Observable<any> {
    return this.deliveryDetailsService.getCheckoutData(data);
  }

  getShipmentMethodsOrRecalculatePrice(data: any): Observable<any> {
    return this.deliveryDetailsService.getShipmentMethodsOrRecalculatePrice(data);
  }

  getShipmentMethodPrice(cartId: string): Observable<any> {
    return this.deliveryDetailsService.getShipmentMethodPrice(cartId);
  }

  getCompanyUsers(): Observable<any> {
    return this.deliveryDetailsService.getCompanyUsers();
  }

  getCompanyUsersForApprove(): Promise<any> {
    return this.deliveryDetailsService.getCompanyUsersForApprove();
  }

  postSharingCardWithAccess(data: any, idCart: string): Promise<any> {
    return this.deliveryDetailsService.postSharingCardWithAccess(data, idCart);
  }

  getCheckoutUpdateData(cartId: string): Promise<any> {
    return this.deliveryDetailsService.getCheckoutUpdateData(cartId);
  }

  unsharedCart(cartId): Promise<any> {
    return this.deliveryDetailsService.unsharedCart(cartId);
  }

  postCheckout(data: any): Observable<any> {
    return this.deliveryDetailsService.postCheckout(data);
  }

  postCheckoutMock(data: any): Observable<ICheckout> {
    return this.deliveryMock.postCheckout(data);
  }

  successSummaryPageData(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getOrderSuccessPageData);
  }

  successSummaryPageClearData(): void {
    this.store.dispatch(OrderApproveActions.successSummaryPageClearData());
  }

  actionPutCartIdOrderApprove(cartId: string): void {
    this.store.dispatch(OrderApproveActions.PutCartId({cartId}));
  }

  actionLoadCartItemsOrderApprove(cartId: string): void {
    this.store.dispatch(OrderApproveActions.loadCartItems({cartId}));
  }

  selectCartItemsOrderApprove(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getCartItems);
  }

  selectCartItemsLoadFail(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getCartItemsLoadFail);
  }

  convertDataToDisplayAddresses(addressToAddArray: Array<IAddressesDataIncluded> | Array<ICustomAddressData>,
                                addressesList: Array<IAddressData>): IAddressData[] {
    return this.deliveryDetailsService.getAddressesFromData(addressToAddArray, addressesList);
  }

  createRfq(quote: ICreateRfQ): Observable<IRfqCreateResponse> {
    return this.rfqService.createRequestForQuote(quote);
  }

  getQuoteHistory(): Observable<IRfqHistory> {
    return this.rfqService.getRfqHistory();
  }

  loadQuoteHistory() {
    this.store.dispatch(CheckoutActions.GetQuoteRfqHistoryStartDataAction());
  }

  selectQuoteHistory(): Observable<IRfqBaseInfo[]> {
    return this.store.select(selectRfqHistoryData);
  }

  selectQuoteDetails(quoteId: string): Observable<IRfqDetails> {
    return this.rfqService.getRfqDetails(quoteId);
  }

  cancelRequest(quoteId: string, quote: IRfqCancelDetails): Observable<any> {
    return this.rfqService.cancelRfq(quoteId, quote);
  }

  getResponseCheckoutData(): Observable<any> {
    return this.store.select(OrderApproveSelectors.getResponseCheckoutData);
  }

  getQuotePdfFile(quoteId: string): Observable<Blob> {
    return this.rfqService.getRfqPdfFile(quoteId);
  }

  getCartDetailPdfFile(cartId: string): Observable<Blob> {
    return this.ordersService.getCartDetailPdfFile(cartId);
  }

  getCartGenerateQuotePdfFile(cartId: string, isPreview: boolean): Observable<Blob> {
    return this.ordersService.getCartGenerateQuotePdfFile(cartId, isPreview);
  }
}
