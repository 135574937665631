<div class="service-configuration">
  <app-contract-steps-bar [stepNo]="2"></app-contract-steps-bar>
  <div class="service-configuration__content" *ngIf="!initializeInProgress && configuratorInitialized; else loader">
    <div class="service-configuration__steps">
      <h5 class="steps__title" *ngIf="selectedSystem">
        {{ selectedSystem.name }}
        {{ 'service-configuration.steps-service-name' | translate }}
      </h5>
      <app-configuration-steps-bar
          [currentStep]="currentStep"
          [steps]="configurationSteps"
          (selectStep)="changeStep($event)">
      </app-configuration-steps-bar>
    </div>
    <div class="service-configuration__options">
      <ng-container *ngFor="let config of serviceConfigurations">
        <ng-container *ngIf="config.description === configurationSteps[currentStep].value">
          <h4 class="service-configuration__options-title">{{ config.description }}</h4>
          <p class="service-configuration__options-tip">{{ 'service-configuration.config-tip' | translate }}</p>
          <app-configuration
              *ngFor="let configMember of config.members"
              [config]="configMember"
              [disableInput]="saveInProgress"
              (changed)="configChanged($event)">
          </app-configuration>
        </ng-container>
      </ng-container>
      <div
          *ngIf="'Contract details' !== configurationSteps[currentStep].value"
          class="service-configuration__actions">
        <button type="button" class="button button--primary" [attr.data-test]="'button-next'" [disabled]="saveInProgress" (click)="goToTheNextStep()">
          {{ 'service-configuration.next-step-btn' | translate }}
        </button>
        <span *ngIf="saveInProgress" class="service-configuration__actions-marker">
          <span class="loading-animation"></span>
          {{ 'service-configuration.saving' | translate }}
        </span>
        <span *ngIf="showSaveDone && !saveInProgress" class="service-configuration__actions-marker save-done">
          <span class="icon icon-check icon-check__circle"></span>
          <span>{{ 'service-configuration.save-done' | translate }}</span>
        </span>
        <button
            *ngIf="currentStep > 0"
            type="button"
            class="button" [attr.data-test]="'button-backToPreviousStep'"
            [disabled]="saveInProgress"
            (click)="goBackToPreviousStep()">
          {{ 'service-configuration.back-step-btn' | translate }}
        </button>
      </div>
      <app-contract-details
          *ngIf="'Contract details' === configurationSteps[currentStep].value"
          [configId]="configId"
          [saveInProgress]="saveInProgress"
          [showSaveDone]="showSaveDone"
          (changed)="handleContractChange($event)"
          (goBack)="goBackToPreviousStep()"
          (goToReviewSummary)="goToTheNextStep()">
      </app-contract-details>
    </div>
    <div class="service-configuration__summary">
      <div class="summary-navigation">
        <div
            class="summary-navigation__item"
            [ngClass]="{'active': activeSummary === 'price-list'}"
            (click)="toggleSummaryTab('price-list')">
          {{ 'service-configuration.summary-nav-option1' | translate}}
        </div>
        <div
            class="summary-navigation__item"
            [ngClass]="{'active': activeSummary === 'change-log'}"
            (click)="toggleSummaryTab('change-log')">
          <span class="summary-navigation__item-marker" [ngClass]="{'icon-dot': showNewChangeMarker}">
            {{ 'service-configuration.summary-nav-option2' | translate}}
          </span>
        </div>
      </div>
      <app-price-list
          *ngIf="activeSummary === 'price-list'"
          [priceList]="priceList"
          [priceSummary]="priceSummary">
      </app-price-list>
      <app-change-log
          *ngIf="activeSummary === 'change-log'"
          [changeLog]="changeLog">
      </app-change-log>
    </div>
  </div>
  <ng-template #loader>
    <div class="service-configuration__loader" [ngClass]="{'loader__fixed': initializeInProgress}">
      <div class="loading-animation"></div>
      <p *ngIf="showAlmostThere">{{ 'service-configuration.almost-there-loader' | translate }}</p>
    </div>
  </ng-template>
</div>

<app-conflict-modal
    [open]="conflictModalActive"
    [conflict]="conflict"
    (accept)="acceptConflict()"
    (undo)="conflictModalActive = false">
</app-conflict-modal>
