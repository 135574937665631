import { Pipe, PipeTransform } from '@angular/core';
import { ISystemDetails } from '../../models/common.models';
import { I18nService } from '../../services';
import { Observable, of, ReplaySubject } from 'rxjs';

@Pipe({
  name: 'selectedSystem'
})
export class SelectedSystemPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {
  }

  static generateSelectedSystem(siemensEquipmentId: string, name: string): string {
    return !siemensEquipmentId ? '-' : `${name} (${siemensEquipmentId})`;
  }

  transform(systemDetails: ISystemDetails): Observable<string> {
    if (!systemDetails) {
      return of('');
    }

    const selectedSystem$: ReplaySubject<string> = new ReplaySubject<string>();

    if (systemDetails.nameEnUs) {
      selectedSystem$.next(
        SelectedSystemPipe.generateSelectedSystem(systemDetails.siemensEquipmentId, systemDetails.nameEnUs)
      );
    } else {
      this.i18nService.getTranslationByKey('system-details.selected-system-name-na')
        .subscribe(translation =>
          selectedSystem$.next(SelectedSystemPipe.generateSelectedSystem(systemDetails.siemensEquipmentId, translation))
        )
        .unsubscribe();
    }

    return selectedSystem$;
  }
}
