export class CsvUtils {

  // TODO: Added basic functionality for now
  static parseCSV(csvData: string): any[] {
    const lines = csvData.split(/\r?\n|\r/);
    const headers = lines[0].split(',');
    const headersLowerCase =  headers.map((columnLine) => columnLine.toLowerCase());
    const data = [];

    lines.slice(1).forEach(line => {
      const row = line.split(',');
      if (row.length === headersLowerCase.length) {
        const rowData = {};
        headersLowerCase.forEach((header, index) => {
          rowData[header] = row[index];
        });
        data.push(rowData);
      }
    });
    return data;
  }
}
