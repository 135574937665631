<ng-container *ngIf="!hybrisOrdersLoading; else loader">
  <ng-container *ngIf="hybrisOrders.length > 0">
    <div class="advanced-search">
      <div class="advanced-search--header">
        <div class="advanced-search--button">
          <button class="button"
                  [ngClass]="advancedSearchDropdownIsOpened ? 'icon-arrow-top' : 'icon-arrow-down'"
                  [attr.data-test]="'button-advancedSearch'"
                  (click)="openOrCloseDropdown()">
            {{ 'spare-part-order-history.advanced-search' | translate }}
          </button>
        </div>
        <div class="other-options">
          <button
              (click)="generateOrdersHistoryExcel()"
              class="button button--link orders-list-download desktop">
            <ng-container *ngIf="!excelGenerationInProgress; else loadingSpinner">
              <span class="icon-download"></span>
              <span class="text">
                {{ 'spare-part-order-history.download-order-list' | translate }}
              </span>
            </ng-container>
            <ng-template #loadingSpinner>
              <div class="btn-spinner">
                <div class="loading-animation"></div>
              </div>
            </ng-template>
          </button>
          <div class="js-search-field search-field textfield textfield--with-prefix">
            <span class="textfield__prefix icon-search"></span>
            <input id="search"
                   name="search"
                   type="search"
                   class="textfield__input search-field__input"
                   spellcheck="false"
                   autocomplete="off"
                   placeholder="{{ 'spare-part-order-history.search' | translate }}"
                   [disabled]="hybrisOrdersLoading"
                   [(ngModel)]="searchOrders"/>
          </div>
        </div>
      </div>
      <div class="advanced-search--body"
           *ngIf="advancedSearchDropdownIsOpened">
        <div class="scroll">
          <div class="columns">
            <div class="column">
              <app-select-box
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.soldToAddress"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-soldToAddress'}"
                  [options]="getOptionsForSelectBox('soldToAddress')"
                  [inputName]="'soldToAddress'"
                  label="{{ 'spare-part-order-history.sold-to-address' | translate }}"
                  (selected)="setFormValue($event)">
              </app-select-box>
              <app-select-box
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.shipToAddress"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-shipToAddress'}"
                  [options]="getOptionsForSelectBox('shipToAddress')"
                  [inputName]="'shipToAddress'"
                  label="{{ 'spare-part-order-history.ship-to-address' | translate }}"
                  (selected)="setFormValue($event)">
              </app-select-box>
              <div class="datepickers">
                <app-datepicker
                    class="datepicker"
                    [form]="advancedFiltersForm"
                    [modelValue]="advancedFiltersForm.value.orderDateFrom"
                    [dateFormat]="'m/d/Y'"
                    [inputName]="'orderDateFrom'"
                    label="{{ 'spare-part-order-history.order-date-from' | translate }}"
                    (selected)="setFormValue($event)">
                </app-datepicker>
                <app-datepicker
                    class="datepicker"
                    [form]="advancedFiltersForm"
                    [modelValue]="advancedFiltersForm.value.orderDateTo"
                    [dateFormat]="'m/d/Y'"
                    [inputName]="'orderDateTo'"
                    label="{{ 'spare-part-order-history.order-date-to' | translate }}"
                    (selected)="setFormValue($event)">
                </app-datepicker>
              </div>
            </div>
            <div class="column">
              <app-select-box
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.orderStatus"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-orderStatus'}"
                  [options]="getOptionsForSelectBox('orderStatus')"
                  [inputName]="'orderStatus'"
                  label="{{ 'spare-part-order-history.order-status' | translate }}"
                  (selected)="setFormValue($event)">
              </app-select-box>
              <app-select-box
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.flNumber"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-flNumber'}"
                  [options]="getOptionsForSelectBox('flNumber')"
                  [inputName]="'flNumber'"
                  label="{{ 'spare-part-order-history.fl-number' | translate }}"
                  (selected)="setFormValue($event)">
              </app-select-box>
              <app-textfield
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.materialNumber"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-materialNumber'}"
                  [inputName]="'materialNumber'"
                  label="{{ 'spare-part-order-history.material-number' | translate }}">
              </app-textfield>
            </div>
            <div class="column">
              <app-textfield
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.orderNumber"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-orderNumber'}"
                  [inputName]="'orderNumber'"
                  label="{{ 'spare-part-order-history.order-number' | translate }}">
              </app-textfield>
              <app-textfield
                  [form]="advancedFiltersForm"
                  [modelValue]="advancedFiltersForm.value.purchaseOrder"
                  [cssStyles]="{wrapper: 'textfield-with-prefix textfield-purchaseOrder'}"
                  [inputName]="'purchaseOrder'"
                  label="{{ 'spare-part-order-history.purchase-order' | translate }}">
              </app-textfield>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="button button--standard"
                  (click)="resetFilters()"
                  [attr.data-test]="'button-reset'">
            {{ 'spare-part-order-history.reset-button' | translate }}
          </button>
          <button class="button button--standard"
                  (click)="clearSelectedFiltersOptions()"
                  [attr.data-test]="'button-cancel'">
            {{ 'spare-part-order-history.cancel-button' | translate }}
          </button>
          <button class="button button--primary"
                  (click)="applySelectedFiltersOptions()"
                  [attr.data-test]="'button-apply'">
            {{ 'spare-part-order-history.apply-button' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row justify-content-center previous-orders">
    <div class="col-12">
      <ng-container *ngIf="hybrisOrders.length > 0; else noOrders">
        <button
            (click)="generateOrdersHistoryExcel()"
            class="button button--link orders-list-download mobile">
          <ng-container *ngIf="!excelGenerationInProgress; else loadingSpinner">
            <span class="icon-download"></span>
            <span class="text">
              {{ 'spare-part-order-history.download-order-list' | translate }}
            </span>
          </ng-container>
          <ng-template #loadingSpinner>
            <div class="btn-spinner">
              <div class="loading-animation"></div>
            </div>
          </ng-template>
        </button>
        <span class="previous-orders__title" [attr.data-test]="'label-previousOrders'">
          {{ 'spare-part-order-history.previous-orders' | translate }}
        </span>
        <app-spare-part-hybris-list
            [hybrisOrders]="hybrisOrders"
            [searchValue]="searchOrders"
            [filters]="advancedFilters">
        </app-spare-part-hybris-list>
      </ng-container>
      <ng-template #noOrders>
        <span class="previous-orders__title" [attr.data-test]="'label-previousOrders'">
          {{ 'spare-part-order-history.previous-orders' | translate }}
        </span>
        <p class="no-orders" [attr.data-test]="'label-noOrders'">
          {{ 'spare-part-order-history.no-orders' | translate }}
        </p>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #loader>
  <div class="loader">
    <div class="loading-animation"></div>
  </div>
</ng-template>
