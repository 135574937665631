import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '../../services';

@Component({
  selector: 'app-confirm-remove-modal',
  templateUrl: './confirm-remove-modal.component.html',
  styleUrls: ['./confirm-remove-modal.component.scss'],
})
export class ConfirmRemoveModalComponent {
  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Input() cartId: string;

  constructor(
    private router: Router,
    private i18nService: I18nService,
  ) {
  }

  unShareCart(): void {
    this.closeModal.emit();
    this.router.navigate([this.i18nService.getCurrentLocale()]);
  }

  cancelDecline(): void {
    this.closeModal.emit();
  }
}
