import { StringUtils } from './string.utils';
import { environment } from '../../environments/environment';
import { IGlueUrlConfig } from '../models/settings.model';

export class UrlUtils {
  static getCategoryNameFromUrl(url: string): string {
    const chunks = url.split('/');
    return chunks.length > 0 ? chunks[chunks.length - 1] : '';
  }

  static serialize(obj, prefix = ''): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        str.push((v !== null && typeof v === 'object') ?
          this.serialize(v, k) :
          k + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }

  static getLocaleFromPathName(pathName: string): string {
    return StringUtils.substringFromChar(pathName.substring(1, pathName.length), '/');
  }

  static getGlueUrlForStore(storeId: string): string {
    const configuration = environment.glueApiUrl as IGlueUrlConfig[];
    const urlConfig = configuration.find(c => c.store === storeId) as IGlueUrlConfig;
    return urlConfig.url;
  }

  static isTheSameRoute(route: any): boolean {
    const previousPath = route[0].state._root.children[0].value.routeConfig.path;
    const newPath = route[1].state._root.children[0].value.routeConfig.path;
    return previousPath === newPath;
  }
}
