<ng-container>
  <app-modal
      [open]="showModalSubmit"
      [id]="'submitModal'"
      title="{{ 'contact-us.request-submitted' | translate }}"
      (crossClick)="closeModalSubmit()"
      (outsideClick)="closeModalSubmit()">
    <ng-container ngProjectAs="[slot][body]" *ngIf="(cartItemsLength === 0)
          else existingQuoteBody">
      <div class="row">
        <div class="col-12">
          <span>{{ 'contact-us.contact-you' | translate }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #existingQuoteBody>
      <div class="row">
        <div class="col-12">
          <span>{{ 'contact-us.continue-configuring' | translate }}</span>
        </div>
      </div>
    </ng-template>
    <ng-container ngProjectAs="[slot][footer]" *ngIf="(cartItemsLength === 0)
          else existingQuoteFooter">
      <div class="row">
        <div class="col-12">
          <button type="button"
                  class="button button--primary btn-submit"
                  data-dismiss="modal-dialog"
                  (click)="submitOnClick(false)">
            {{ 'contact-us.button-ok' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #existingQuoteFooter>
      <div class="row">
        <div class="col-12">
          <button type="button"
                  class="button btn-submit"
                  data-dismiss="modal-dialog"
                  (click)="submitOnClick(false)">
            {{ 'contact-us.button-cancel' | translate }}
          </button>
          <button type="button"
                  class="button button--primary btn-submit"
                  data-dismiss="modal-dialog"
                  (click)="submitOnClick(true)">
            {{ 'contact-us.button-proceed' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </app-modal>
</ng-container>
