import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { I18nService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PreventDirectGuard implements CanActivate {

  constructor(
    private router: Router, 
    private i18nService: I18nService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.router.navigated) {
      this.router.navigate([this.i18nService.getCurrentLocale()]);
    }
    return true;
  }
}
