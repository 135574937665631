import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUserAddress } from '../models/customer.models';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConfigurationFacade } from '../facades/configuration.facade';
import {
  IAddressData,
  IAddressesDataIncluded,
  ICustomAddressData,
} from '../models/checkout.models';
import { GlueConfigurationService } from './glue-configuration.service';
import { AddressUtils } from '../utils/address.utils';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDetailsService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private configurationFacade: ConfigurationFacade,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private httpParamsForGetCompanyUsersRoleItems = {
    params: new HttpParams()
      .set('include', 'company-roles,customers'),
  };

  private httpParamsForGetApprovers = {
    params: new HttpParams()
      .set('include', 'customers'),
  };

  private httpParamsForGetBusinessAddressesItems = {
    params: new HttpParams()
      .set('include', 'company-business-unit-addresses'),
  };

  private static handleError(error: any): Promise<IUserAddress> {
    return Promise.reject(error);
  }

  getBusinessAddresses(): Observable<any> {
    const businessAddressesUrl = this.glueUrl + '/company-business-units/mine';

    return this.http.get<any>(businessAddressesUrl, this.httpParamsForGetBusinessAddressesItems)
      .pipe(
        tap(),
        catchError(DeliveryDetailsService.handleError),
      );
  }

  postCustomerAddresses(customerId: string, body: any): Promise<any> {
    const newBody = JSON.stringify(body);
    const customerAddressesUrl = this.glueUrl + `/customers/${customerId}/addresses`;
    return this.http.post<any>(customerAddressesUrl, newBody).toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  postWrongAddress(dataToSend: any): Promise<any> {
    const newBody = JSON.stringify(dataToSend);
    const url = this.glueUrl + '/addresses-report-wrong';
    return this.http.post<any>(url, newBody).toPromise()
      .then(response => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  postCheckoutData(data: any, idCart: string): Promise<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-update';

    return this.http.post<any>(cartsInfoUrl, newBody)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  getCheckoutData(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-data?include=shipment-methods,shipments';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  getShipmentMethodsOrRecalculatePrice(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-data';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  getShipmentMethodPrice(cartId: string): Observable<any> {
    const cartsUrl = `${this.glueUrl}/carts/${cartId}?include=items`;

    return this.http.get<any>(cartsUrl).pipe(
      tap(),
      catchError(DeliveryDetailsService.handleError),
    );
  }

  unsharedCart(cartId: string): Promise<any> {
    const cartsInfoUrl = `${this.glueUrl}/shared-carts/${cartId} `;

    return this.http.delete<any>(cartsInfoUrl)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  postCheckout(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  postSharingCardWithAccess(data: any, idCart: string): Promise<any> {
    const newBody = JSON.stringify(data);
    const customerAddressesUrl = `${this.glueUrl}/carts/${idCart}/shared-carts`;

    return this.http.post<any>(customerAddressesUrl, newBody)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  getCompanyUsers(): Observable<any> {
    const companyUsersUrl = this.glueUrl + '/company-users/mine';

    return this.http.get<any>(companyUsersUrl, this.httpParamsForGetCompanyUsersRoleItems)
      .pipe(
        tap(),
        catchError(DeliveryDetailsService.handleError),
      );
  }

  getCompanyUsersForApprove(): Promise<any> {
    const companyUsersUrl = this.glueUrl + '/company-approvers';

    return this.http.get<any>(companyUsersUrl, this.httpParamsForGetApprovers)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  getCheckoutUpdateData(cartId: string): Promise<any> {
    const bodyForCheckout = {
      data: {
        type: 'checkout-update',
        attributes: {
          idCart: cartId,
        },
      },
    };
    const checkoutUrl = this.glueUrl + '/checkout-update';
    return this.http.post<any>(checkoutUrl, bodyForCheckout)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(DeliveryDetailsService.handleError);
  }

  getAddressesFromData(addressesToAdd: IAddressesDataIncluded[] | ICustomAddressData[], addressList: IAddressData[]): IAddressData[] {
    addressesToAdd.forEach(data => {
      if (data && data.attributes) {
        const newAddress: IAddressData = {id: data.id, name: AddressUtils.createAddressString(data.attributes), value: data.attributes};
        const addressFound = addressList.find(address => address.name === newAddress.name);
        if (!addressFound) {
          addressList.push(newAddress);
        }
      }
    });
    return addressList;
  }
}

export interface IDataModel {
  name: string;
  value: any;
}
