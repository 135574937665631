import { ISortOptions } from '../models/settings.model';



export const functionalLocationSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'siemensEquipmentId',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'siemensEquipmentId',
    reversed: true,
    time: false,
  },
];

export const modalitySortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'category',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'category',
    reversed: true,
    time: false,
  },
];

export const productNameSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'nameEnUs',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'nameEnUs',
    reversed: true,
    time: false,
  },
];

export const equipmentLocationSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'siemensEquipmentAddress.street',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'siemensEquipmentAddress.street',
    reversed: true,
    time: false,
  },
];

export const cityAndStateSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'siemensEquipmentAddress.city',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'siemensEquipmentAddress.city',
    reversed: true,
    time: false,
  },
];

export const contractEndDateSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.newest-to-oldest',
    value: 'contractExpirationDate',
    reversed: true,
    time: true,
  },
  {
    name: 'sort-by.oldest-to-newest',
    value: 'contractExpirationDate',
    reversed: false,
    time: true,
  },
];

export const contractNameSortOptions: ISortOptions[] = [
  {
    name: 'sort-by.a-to-z',
    value: 'contractName',
    reversed: false,
    time: false,
  },
  {
    name: 'sort-by.z-to-a',
    value: 'contractName',
    reversed: true,
    time: false,
  },
];

export const genericTextSortOption = (propName: string): ISortOptions[] => {
  return [
    {
      name: 'sort-by.a-to-z',
      value: propName,
      reversed: false,
    },
    {
      name: 'sort-by.z-to-a',
      value: propName,
      reversed: true,
    },
  ];
};

export const genericNumberSortOption = (propName: string): ISortOptions[] => {
  return [
    {
      name: 'sort-by.smallest-to-highest',
      value: propName,
      reversed: false,
      numeric: true,
    },
    {
      name: 'sort-by.highest-to-smallest',
      value: propName,
      reversed: true,
      numeric: true,
    },
  ];
};

export const genericDateSortOption = (propName: string): ISortOptions[] => {
  return [
    {
      name: 'sort-by.newest-to-oldest',
      value: propName,
      reversed: true,
      time: true,
    },
    {
      name: 'sort-by.oldest-to-newest',
      value: propName,
      reversed: false,
      time: true,
    },
  ];
};

