import { createAction, props } from '@ngrx/store';
import { IRfqHistory } from '../models/rfq.models';

export const UpdateSelectedDeliveryAddress = createAction(
  '[Checkout] - Update  Delivery Address',
  props<{payload: any}>(),
);

export const BeginApproversListAction = createAction(
  '[Checkout] - Begin Get Approver List',
);

export const UpdateCheckoutData = createAction(
  '[Checkout] - Update Checkout Data',
  props<{deliveryAddress: any, pointOfContact: any, approver: any, approverComment: string}>(),
);

export const UpdatePOCData = createAction(
  '[Checkout] - Update Checkout Data',
  props<{payload: any}>(),
);

export const SuccessGetApproversListAction = createAction(
  '[Checkout] - Success Get Approvers List',
  props<{payload: any}>(),
);

export const ErrorGetApproversListAction = createAction(
  '[Checkout] - Error Get Approvers List Action',
  props<Error>(),
);

export const PostCheckoutData = createAction(
  '[Checkout] - Begin Post Checkout Data)',
  props<{postCheckoutData: any, cartId: string}>(),
);

export const SuccessPostCheckoutDataAction = createAction(
  '[Checkout] - Success Post Checkout Data',
  props<{responseFromPostCheckout: any}>(),
);

export const GetQuoteRfqHistoryStartDataAction = createAction(
  '[Checkout-quote-history] - Start get quote rfq history Action',
);

export const SuccessGetQuoteRfqHistoryDataAction = createAction(
  '[Checkout-quote-history] - Success Get quote rfq history Action',
  props<{rfqBaseInfo: IRfqHistory}>(),
);
