<div class="my-contracts">
  <app-access-control [description]="true">
    <ng-container ngProjectAs="[slot][content]" *ngIf="isCpqEnabled; else cpqNotActive">
      <div class="service-agreements"
           [appLoadingAnimation]="installBaseLoading"
           [attr.data-test]="'desc-loadingForInstallBaseLoading'">
        <div class="advanced-search">
          <div class="advanced-search--header">
            <button
                class="button"
                [attr.data-test]="'button-filtersOptions'"
                [ngClass]="advancedSearchDropdownIsOpened ? 'icon-arrow-top' : 'icon-arrow-down'"
                (click)="clearSelectedFiltersOptions()">
              {{ 'my-contracts.advanced-search' | translate }}
            </button>
            <div class="advanced-search--search">
              <div class="search-field textfield textfield--with-prefix">
                <span class="textfield__prefix icon-search"></span>
                <input
                    id="search"
                    name="search"
                    type="search"
                    class="textfield__input search-field__input"
                    spellcheck="false"
                    autocomplete="off"
                    placeholder="{{ 'my-contracts.search' | translate }}"
                    [attr.data-test]="'input-searchMyContracts'"
                    [disabled]="searchLoading"
                    [(ngModel)]="searchValue">
                <label for="search" class="search-bar__label"></label>
              </div>
            </div>
          </div>
          <div class="advanced-search--body"
               *ngIf="advancedSearchDropdownIsOpened">
            <div class="scroll">
              <div class="filters">
                <div class="filter" *ngFor="let filter of contractFilters;  last as isLast">
                  <div [attr.data-test]="'desc-filterName'"> {{ 'advanced-filter.' + filter.name | translate }} </div>
                  <div class="search-field textfield textfield--with-prefix">
                    <span class="textfield__prefix icon-search"></span>
                    <input
                        [id]="'item_search' + filter.name"
                        [name]="'search' + filter.name"
                        type="search"
                        class="textfield__input search-field__input"
                        spellcheck="false"
                        autocomplete="off"
                        placeholder="{{ 'advanced-filter.search'  | translate }} {{ 'advanced-filter.' + filter.name | translate }}"
                        [attr.data-test]="'input-filterName-' + filter.name"
                        [(ngModel)]="filterSearchValue[filter.name]">
                    <label for="search" class="search-bar__label"></label>
                  </div>
                  <div class="selection" *ngIf="filter.options" [attr.data-test]="'desc-filterOptions'">
                    <div class="select-option">
                      <input
                          class="checkbox"
                          type="checkbox"
                          [id]="'select-all-' + filter.name"
                          [name]="'checkbox-all-' + filter.name"
                          [checked]="allOptionsAreSelected[filter.name]"
                          [attr.data-test]="'input-filterOptions'"
                          (change)="selectAllFilteredOptions(filter.name)">
                      <label [for]="'select-all-' + filter.name">{{ 'my-contracts.select-all' | translate }}</label>
                    </div>
                    <div *ngFor="let option of filter.options | searchFilter: filterSearchValue[filter.name]"
                         class="select-option">
                      <input
                          class="checkbox"
                          type="checkbox"
                          [id]="'select-' + option"
                          [name]="'checkbox-' + option"
                          [checked]="selectedOptions[filter.name].includes(option)"
                          (change)="selectOption(filter.name, option)"
                          [attr.data-test]="'input-filterOptions-' + option">
                      <label [for]="'select-' + option">{{ option }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
                <button class="button button--standard"
                        (click)="clearSelectedFiltersOptions()"
                        [attr.data-test]="'button-canceledSelectedFilter'">
                  {{ 'my-contracts.cancel-button-name' | translate }}
                </button>
                <button class="button button--primary"
                        (click)="applySelectedFiltersOptions()"
                        [attr.data-test]="'button-applySelectedFilter'">
                  {{ 'my-contracts.apply-button-name' | translate }}
                </button>
            </div>
          </div>
        </div>
        <div class="contract-filter-panel">
          <div class="contract-filter-panel--selected-filters mobile">
            <ng-container *ngFor="let filter of contractFilters">
              <p class="contract-filter-panel--selected-filter"
                 *ngFor="let selected of filter.selected"
                 [attr.data-test]="'desc-contractFilter'">
                {{ selected }}
                <span class="icon icon-close" (click)="unselectOptionForFilter(selected, filter.name)"></span>
              </p>
            </ng-container>
          </div>
          <div class="contract-filter-panel--filters">
            <button
                class="contract-filter-panel--filters-mobile mobile button"
                [attr.data-test]="'button-activeDropdown'"
                [ngClass]="active ? 'icon-arrow-up-small' : 'icon-arrow-down-small'"
                (click)="activeDropdown()">
              {{ "my-contracts.categories-and-filters" | translate}}
            </button>
            <div
                class="contract-filter-panel--filters-desktop"
                [ngClass]="active ? 'is-open' : ''"
                (window:resize)="onResize($event)">
              <app-dropdown
                  *ngFor="let filter of contractFilters;  last as isLast"
                  [cssStyles]="{dropdown: active ? 'filters':  'user-identifier', toggle: active ? 'filters-toggle': 'user-identifier__toggle-button'}"
                  [dropDownName]="filter.name"
                  [isCheckbox]=true
                  [hideOnMenuClick]=false
                  [keepActive]=false
                  [checkboxOptions]="filter.options"
                  [checkboxName]=filter.name
                  (filters)="setSelectedOptionsForFilter($event, filter.name)">
                <ng-container ngProjectAs="[slot][toggle]">
                  <div class="dropdown desktop">
                    <h6 class="dropdown--name" [attr.data-test]="'title-dropdown'">
                      {{ 'advanced-filter.' + filter.name | translate }}
                    </h6>
                    <h6 class="icon-arrow-down"></h6>
                  </div>
                  <div class="dropdown-toggle mobile" [ngClass]="isLast ? '' : 'dropdown-border'">
                    <span class="dropdown-toggle--name" [attr.data-test]="'desc-lastDropdown'">
                      {{ 'advanced-filter.' + filter.name | translate }}
                    </span>
                    <span class="icon-arrow-down"></span>
                  </div>
                </ng-container>
              </app-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 agreements-need-attention">
          <div class="agreements-info needs-attention">
            <div class="agreements-info__title">
              <h5 class="text" *ngIf="soldTo" [attr.data-test]="'title-currentQuote'">
                {{ 'my-contracts.add-to-current-quote' | translate }}
              </h5>
              <h5 class="text" *ngIf="!soldTo" [attr.data-test]="'title-needsAttention'">
                {{ 'my-contracts.needs-attention' | translate }}
              </h5>
              <span class="icon-warning icon-warning__circle"></span>
            </div>
            <div class="agreements-info__desc" *ngIf="agreementsNeedAttention?.length > 0">
              <ng-container *ngIf="!soldTo; else addToCurrent">
                <p class="text">{{ 'my-contracts.needs-attention-desc1' | translate }}</p>
                <p class="text">
                  {{ 'my-contracts.needs-attention-desc2' | translate }}
                  <app-tooltip
                      [useBothTogglers]="true"
                      linkCss="fl-text"
                      link="{{ 'my-contracts.find-functional-location' | translate }}"
                      text="{{ 'my-contracts.find-functional-location-tooltip-text' | translate }}">
                  </app-tooltip>
                  {{ 'my-contracts.needs-attention-desc3' | translate }}
                </p>
              </ng-container>
              <ng-template #addToCurrent>
                <p class="text">{{ 'my-contracts.add-to-current-quote-desc-1' | translate }}</p>
                <p class="text">{{ 'my-contracts.add-to-current-quote-desc-2' | translate }}
                  <app-tooltip
                      link="{{ 'my-contracts.add-quote-modal-why-tooltip-link' | translate }}"
                      text="{{ 'my-contracts.add-quote-modal-why-tooltip-text' | translate }}">
                  </app-tooltip>
                </p>
              </ng-template>
            </div>
            <p *ngIf="agreementsNeedAttention?.length === 0" class="agreements-info__no-agreements">
              {{ 'my-contracts.no-agreements-need-attention' | translate }}
            </p>
          </div>
          <ng-container *ngIf="agreementsNeedAttention?.length > 0">
            <app-functional-locations-list
                [agreements]="agreementsNeedAttention"
                [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
                [needsAttention]="true"
                [loading]="installBaseLoading"
                [searchValue]="searchValue"
                [filters]="contractFilters"
                [cartItems]="cartItems"
                [productSku]="productSku"
                [soldToId]="soldTo">
            </app-functional-locations-list>
          </ng-container>
        </div>
        <ng-container *ngIf="!soldTo">
          <div class="col-12 agreements-under-contract">
            <div class="agreements-info under-contract">
              <div class="agreements-info__title">
                <h5 class="text" [attr.data-test]="'title-underContract'">
                  {{ 'my-contracts.under-contract' | translate }}
                </h5>
                <span class="icon-check icon-check__circle"></span>
              </div>
              <div class="agreements-info__desc" *ngIf="agreementsUnderContract?.length > 0">
                <p class="text">{{ 'my-contracts.under-contract-desc1' | translate }}
                <p class="text">
                  {{ 'my-contracts.under-contract-desc2' | translate }}
                  <app-tooltip
                      [useBothTogglers]="true"
                      linkCss="fl-text"
                      link="{{ 'my-contracts.find-functional-location' | translate }}"
                      text="{{ 'my-contracts.find-functional-location-tooltip-text' | translate }}">
                  </app-tooltip>
                  {{ 'my-contracts.under-contract-desc3' | translate }}
                </p>
              </div>
              <p *ngIf="agreementsUnderContract?.length === 0" class="agreements-info__no-agreements">
                {{ 'my-contracts.no-agreements-under-contract' | translate }}
              </p>
            </div>
            <ng-container *ngIf="agreementsUnderContract?.length > 0">
              <app-functional-locations-list
                  [agreements]="agreementsUnderContract"
                  [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
                  [needsAttention]="false"
                  [loading]="installBaseLoading"
                  [searchValue]="searchValue"
                  [filters]="contractFilters"
                  [cartItems]="cartItems">
              </app-functional-locations-list>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #cpqNotActive>
      <h4 [attr.data-test]="'title-cpqNotActive'">{{ 'my-contracts.title' | translate }}</h4>
      <p>{{ 'my-contracts.page-not-available' | translate }}</p>
    </ng-template>
  </app-access-control>
</div>
