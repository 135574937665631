import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { AppUtils } from '../../utils/app.utils';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(date?: Date | string): string {
    const defaultDateFormat = 'yyyy-MM-dd';
    const dateFormat = environment.stores.find(
      store => store.storeId === AppUtils.getCurrentStore().storeId
    )?.dateFormat ?? defaultDateFormat;

    date = (typeof date === 'string') ? new Date(date) : date;

    return this.datePipe.transform(date, dateFormat);
  }
}
