import { AdvancedFilter } from '../../../../models/advanced-filter';

export let sparePartOrderHistoryFilters: AdvancedFilter[] = [
  {
    name: 'soldToAddress',
    attribute: 'attributes',
    firstAdditionalAttribute: 'soldToAddress',
    options: [],
    selected: []
  },
  {
    name: 'shipToAddress',
    attribute: 'attributes',
    firstAdditionalAttribute: 'shippingAddress',
    options: [],
    selected: []
  },
  {
    name: 'orderDateFrom',
    attribute: 'attributes',
    firstAdditionalAttribute: 'createdAt',
    options: [],
    selected: []
  },
  {
    name: 'orderDateTo',
    attribute: 'attributes',
    firstAdditionalAttribute: 'createdAt',
    options: [],
    selected: []
  },
  {
    name: 'orderStatus',
    attribute: 'mappedStateDisplayName',
    options: [],
    selected: []
  },
  {
    name: 'flNumber',
    attribute: 'attributes',
    firstAdditionalAttribute: 'systemDetails',
    secondAdditionalAttribute: 'siemensEquipmentId',
    options: [],
    selected: []
  },
  {
    name: 'materialNumber',
    attribute: 'attributes',
    firstAdditionalAttribute: 'items',
    options: [],
    selected: []
  },
  {
    name: 'orderNumber',
    attribute: 'id',
    options: [],
    selected: []
  },
  {
    name: 'purchaseOrder',
    attribute: 'attributes',
    firstAdditionalAttribute: 'sapPoNumber',
    options: [],
    selected: []
  },
  {
    name: 'rmaNumber',
    attribute: 'attributes',
    firstAdditionalAttribute: 'sapDetails',
    secondAdditionalAttribute: 'returnAuthorizationNumber',
    options: [],
    selected: []
  }
];
