import { Injectable } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { BreadCrumb } from '../models/common.models';
import { ESeoData, ETitleSources } from '../configurations/seo';
import { ERouteDataTypes } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {

  ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  DYNAMIC_DATA_BREADCRUMB = 'dynamicBreadCrumb';
  SHOW_DATA_BREADCRUMB = 'showBreadcrumb';
  IS_BREADCRUMB_DISABLED = 'breadcrumbDisabled';

  getBreadcrumbsParam(route: ActivatedRoute, param: ESeoData | ERouteDataTypes): ETitleSources | boolean {
    const children: ActivatedRoute[] = route.children;
    let result = false;
    if (children.length === 0) {
      return result;
    }
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (child.snapshot.data.hasOwnProperty(param)) {
        result = child.snapshot.data[param];
      }
    }
    return result;
  }

  getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    let isDynamic = false;
    let breadcrumbDisabled = false;

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length === 0) {
        continue;
      }

      if (child.snapshot.data.hasOwnProperty(this.DYNAMIC_DATA_BREADCRUMB)) {
        isDynamic = child.snapshot.data[this.DYNAMIC_DATA_BREADCRUMB];
      }

      if (child.snapshot.data.hasOwnProperty(this.IS_BREADCRUMB_DISABLED)) {
        breadcrumbDisabled = child.snapshot.data[this.IS_BREADCRUMB_DISABLED];
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      let label = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
      let param = '';
      if (isDynamic) {
        if (label.indexOf(':translateKey') !== -1) {
          param = 'translateKey';
          label = label.split('=')[1];
        } else {
          const paramToUse = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB].replace(':', '');
          param = paramToUse;

          const paramValue = child.snapshot.params[paramToUse];
          label = paramValue
            .replace(/[-]{3}/gi, ' / ')
            .replace(/[-]/gi, ' ');

          label = label.split(' ').map(labelPart => {
            labelPart = labelPart.charAt(0).toUpperCase() + labelPart.slice(1)
            return labelPart === 'De' ? labelPart.toLowerCase() : labelPart;
          }).join(' ');
        }
      }
      // append route URL to URL
      url += `/${routeURL}`;


      // add breadcrumb
      const breadcrumb: BreadCrumb = {
        label,
        url,
        param,
        breadcrumbDisabled,
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

}
