import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '../../../services';

@Component({
  selector: 'app-contact-us-submit-modal',
  templateUrl: './contact-us-submit-modal.component.html',
  styleUrls: ['./contact-us-submit-modal.component.scss'],
})
export class ContactUsSubmitModalComponent {
  @Input() showModalSubmit: boolean;
  @Input() cartItemsLength: any;
  @Output() closeModal = new EventEmitter<any>();

  constructor(private router: Router,
              private i18nService: I18nService
  ) {
  }

  submitOnClick(proceedBtn: boolean): void {
    this.showModalSubmit = false;

    if (proceedBtn) {
      this.router.navigate(['/quote-summary']).then();
    } else {
      this.router.navigate([this.i18nService.getCurrentLocale()]).then();
    }
  }

  closeModalSubmit(): void {
    this.closeModal.emit();
  }
}
