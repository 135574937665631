<div class="modal-select-sold-to-account">
  <form>
    <app-modal
        [open]="showModalConfirm"
        [id]="'modalConfirm'"
        [title]="(isMissingSoldTo ? 'equipment-selection.issue-with-the-customer-data' : modalTitle) | translate"
        (crossClick)="cancelOnClick()"
        (outsideClick)="cancelOnClick()">
      <ng-container ngProjectAs="[slot][body]">
        <p class="notifyText" [attr.data-test]="'desc-notifyText'" [innerText]="notifyText | translate"></p>
        <app-select-box
            (selected)="setSoldTo($event)"
            [form]="contactDetailsForm"
            [inputName]="'soldTo'"
            [isRequired]="true"
            [modelValue]="contactDetailsForm.value.soldTo"
            [ngClass]="{'is-invalid': contactDetailsForm.get('soldTo').errors}"
            [options]="remappedSoldToAccounts"
            [margin]="false"
            label="{{ 'select-sold-to.select-label' | translate }}">
        </app-select-box>
        <a
            [routerLink]="['/contact']" [queryParams]="{'selected-topic': 'general-request'}"
            [attr.data-test]="'report-wrong-address'"
            [title]="'my-profile.action-link.report-wrong-address' | translate"
            class="button button--link report-wrong-address"
        >
          <span>{{ 'my-profile.action-link.report-wrong-address' | translate }}</span>
          <span class="icon-arrow-right color-orange"></span>
        </a>
      </ng-container>
      <ng-container ngProjectAs="[slot][footer]">
        <button
            [attr.data-test]="'button-cancel'"
            type="reset"
            [innerText]="cancelBtnText | translate"
            class="button button"
            data-dismiss="modal-dialog"
            (click)="cancelOnClick()">
        </button>
        <button
            [attr.data-test]="'button-primaryAction'"
            type="button"
            [innerText]="primaryBtnText | translate"
            class="button button--primary"
            data-dismiss="modal-dialog"
            [disabled]="!contactDetailsForm.value.soldTo || disableConfirmButton"
            (click)="primaryActionOnClick()">
        </button>
      </ng-container>
    </app-modal>
  </form>
</div>
