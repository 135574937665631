import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import * as HomepageActions from '../actions/homepage.actions';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { INavigationResponse } from '../models/settings.model';
import { CmsFacade } from '../facades/cms.facade';
import { IContentBanner } from '../models/cms';
import { AppActions, CustomerActions } from '../actions';
import { EFullPageLoaderListOptions } from '../configurations/common';

@Injectable({
  providedIn: 'root',
})
export class HomepageEffects {

  constructor(
    private actions$: Actions,
    private configurationFacade: ConfigurationFacade,
    private cmsFacade: CmsFacade,
  ) {
  }

  initHomepageAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      map(() => HomepageActions.loadShopCategoryNavigation()),
    );
  });

  GetHomepageShopCategoryOptionsTiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.loadShopCategoryNavigation),
      mergeMap(
        () => this.configurationFacade.getShopCategoryTiles(
        ).pipe(
          map((data: INavigationResponse) =>
            HomepageActions.loadShopCategoryNavigationSuccess({
              navigationResponseNodes: data.data.attributes.nodes,
            }),
          ),
          catchError((error: string) => {
            return of(HomepageActions.loadShopCategoryNavigationFail({resError: error}));
          }),
        ),
      ),
    ));

  GetHomepageShopContentBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomepageActions.loadShopContentBanner),
      mergeMap(
        (action) => this.cmsFacade.getContentBannerByKey(action.bannerKey,
        ).pipe(
          concatMap((data: IContentBanner) => {
            return [
              HomepageActions.loadShopContentBannerSuccess({
                contentBannerResponse: data,
              }),
              AppActions.homePageBannerLoaded({name: EFullPageLoaderListOptions.HOMEPAGE_BANNER}),
            ];
          }),
          catchError((error: string) => {
            return of(HomepageActions.loadShopContentBannerFail({resError: error}));
          }),
        ),
      ),
    ));
}
