import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICatalogProduct } from 'src/app/models/abstract-product.models';
import { IContractParams } from 'src/app/models/catalog.models';
import { CpqUtils } from 'src/app/utils/cpq.utils';
import { ProductUtils } from 'src/app/utils/product.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-configurable-product',
  templateUrl: './configurable-product.component.html',
  styleUrls: ['./configurable-product.component.scss'],
})
export class ConfigurableProductComponent {
  defaultImg: string = environment.defaultImg;

  @Input() layoutType: string;
  @Input() product: ICatalogProduct;
  @Input() contractParams: IContractParams;
  @Input() itemsInQueueToCart: Array<string>;

  @Output() selectContract: EventEmitter<ICatalogProduct> = new EventEmitter<ICatalogProduct>();

  /**
   * Method to emit selection of contract
   * @param {ICatalogProduct} product
   */
  handleSelectContract(product: ICatalogProduct): void {
    this.selectContract.emit(product);
  }

  /**
   * Method to get configurable name
   * @param {ICatalogProduct} product
   * @returns {string}
   */
  getConfigurableName(product: ICatalogProduct): string {
    return this.isConfigurableAvailable(product) ? product.abstractName : `${product.abstractName}*`;
  }

  /**
   * method to check if configurable is available
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  isConfigurableAvailable(product: ICatalogProduct): boolean {
    return ProductUtils.isConfigurableAvailable(product);
  }

  /**
   * Method to get extended contract params
   * @param {ICatalogProduct} product
   * @returns {IContractParams}
   */
  getExtendedContractParams(product: ICatalogProduct): IContractParams {
    return {
      ...CpqUtils.filterOutEmptyParams(this.contractParams),
      selectedService: this.isConfigurableAvailable(product) ? product.abstractName : `${product.abstractName}*`
    };
  }

  /**
   * Method to get contract params
   * @returns {IContractParams | null}
   */
  getContractParams(): IContractParams | null {
    if (!this.contractParams || Object.keys(this.contractParams).find(key => this.contractParams[key] === '')) {
      return null;
    }

    return this.contractParams;
  }

  /**
   * Method to get item concrete sku
   * @param {ICatalogProduct} product
   * @returns {string | undefined}
   */
  getItemConcreteSku(product: ICatalogProduct): string | undefined {
    if (!product?.attributeMap?.product_concrete_ids) {
      return undefined;
    }

    return product.attributeMap.product_concrete_ids[0];
  }

  /**
   * Method to check if item is in progress
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  isItemInProgress(product: ICatalogProduct): boolean {
    const concreteSku: string = this.getItemConcreteSku(product);

    return this.itemsInQueueToCart.includes(concreteSku) || this.itemsInQueueToCart.includes(product.abstractSku);
  }

  /**
   * Method to check if product contains image
   * @param {ICatalogProduct} product
   * @returns {boolean}
   */
  hasImages(product: ICatalogProduct): boolean {
    if (!product?.images) {
      return false;
    }
    
    return !!product.images && product.images.length > 0;
  }
}
