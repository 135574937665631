<app-access-control [notForViewer]="true" [description]="true">
  <ng-container ngProjectAs="[slot][nonViewerContent]">
    <h4 [attr.data-test]="'title-purchaseActivityTitle'">{{ 'purchase-activity.title' | translate }}</h4>
    <div class="bare-list tabs__list" *ngIf="isSparePartsEnabled && isCpqEnabled">
      <ng-container *ngFor="let tab of tabs">
        <h6 class="tabs__item"
            [attr.data-test]="'tab-' + tab"
            [ngClass]="{'is-active is-active__bar': currentTab === tab}"
            (click)="changeTab(tab)">
          {{ 'purchase-activity.' + tab + '-tab-title' | translate }}
        </h6>
      </ng-container>
    </div>
    <app-spare-part-purchase-activity
        *ngIf="isSparePartsEnabled && currentTab === EMultiCartTabs.PARTS"
        (sparePartOrdersLoadedEmit)="sparePartOrdersLoaded = $event; trackPageReady()">
    </app-spare-part-purchase-activity>
    <app-service-purchase-activity
        *ngIf="isCpqEnabled && currentTab === EMultiCartTabs.SERVICES"
        (loadServiceOrdersEmit)="serviceOrdersLoaded = $event; trackPageReady()">
    </app-service-purchase-activity>
  </ng-container>
</app-access-control>
