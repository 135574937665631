import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { IContractParams } from '../../models/catalog.models';
import { CpqFacade } from '../../facades/cpq.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { FileType, FileUtils } from '../../utils/file.utils';
import {
  ICPQConfigurationSummary,
  IPricingSummary,
  ICPQResponseIncludedAttributes,
} from '../../models/cpq.models';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { I18nService } from '../../services';
import { CustomerFacade } from '../../facades/customer.facade';
import { AppUtils } from '../../utils/app.utils';
import { CpqUtils } from '../../utils/cpq.utils';
import { IAddressExtended } from '../../models/customer.models';
import { EAddressType } from '../../configurations/cpq';
import { EUserRoles } from '../../configurations/common';

@Component({
  selector: 'app-page-configuration-summary',
  templateUrl: './configuration-summary.component.html',
  styleUrls: ['./configuration-summary.component.scss'],
})

export class ConfigurationSummaryComponent implements OnInit, OnDestroy {
  contractSystemInformation;
  serviceParams: IContractParams = {
    selectedService: '',
    'fl-number': '',
    'rel-product-sysivk': '',
    configId: '',
  };
  cart: any;
  loading = true;
  itemsWithDetails: ICPQResponseIncludedAttributes;
  products = [];
  cartId: string;
  configId: string;
  priceSummary: IPricingSummary;
  businessAddresses: IAddressExtended[] = [];
  isCpqConfigInUrl = false;
  ecommerceTeamEmailAddress: string;
  customerBusinessUnitName: string;
  showModalAddressWrong = false;
  addressType = EAddressType.SoldTo;
  configurationGenerationInProgress: boolean = false;

  @Input() loggedUserRoles: EUserRoles[];

  private unsubscribe$ = new Subject<void>();
  private docDownloadErrorMsgKey = 'configuration-summary.doc-download-error-message';
  private docDownloadErrorMsg: string;
  private serviceConfigUrl = '/service-configuration';
  private quoteSummaryUrl = '/quote-summary';

  constructor(
    private router: Router,
    private cpqFacade: CpqFacade,
    private translate: I18nService,
    private activatedRoute: ActivatedRoute,
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
    private configFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.setDownloadErrorMessage();
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.serviceParams.selectedService = params.selectedService ?? '';
      this.serviceParams['fl-number'] = params['fl-number'] ?? '';
      this.serviceParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
      this.serviceParams.configId = params.configId ?? '';
      if (this.serviceParams.configId === '') {
        this.loading = false;
      } else {
        this.isCpqConfigInUrl = true;
        this.marketingFacade.selectCartId().pipe(takeUntil(this.unsubscribe$))
          .subscribe(cartId => {
            if (cartId !== undefined) {
              this.cartId = cartId;
              this.loadSystemContractInformation();
            }
          });
      }
    });
    this.getUserData();
    this.getStoreEmailAddress();
    this.selectCart();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  downloadConfiguration(): void {
    this.configurationGenerationInProgress = true;
    this.cpqFacade.getConfigurationSummaryPdfFile(this.cartId, this.itemsWithDetails.sku, this.serviceParams.configId)
      .pipe(take(1))
      .subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(
              file,
              FileType.PDF,
              `Configuration_summary_${this.serviceParams['fl-number']}_${this.cartId}`);
            this.configurationGenerationInProgress = false;
          },
          error: () => {
            this.configFacade.setAlert({
              type: 'error',
              message: this.docDownloadErrorMsg,
            });
            this.configurationGenerationInProgress = false;
          },
        },
      );
  }

  showModalWrongAddress(): void {
    this.showModalAddressWrong = true;
  }

  getMultipleFunctionalLocations(cart: any): string[] {
    const functionalLocationsInArray = [];
    functionalLocationsInArray.push(cart?.attributes?.systemDetails?.siemensEquipmentId);

    return functionalLocationsInArray;
  }

  editAllSections(): void {
    this.router.navigate([this.serviceConfigUrl], {queryParams: this.serviceParams}).then();
  }

  goToQuoteSummary(): void {
    this.router.navigate([this.quoteSummaryUrl], {queryParams: this.serviceParams}).then();
  }

  private getStoreEmailAddress(): void {
    this.ecommerceTeamEmailAddress = AppUtils.getCurrentStore().ecommerceTeamEmailAddress;
  }

  private getUserData(): void {
    this.customerFacade.selectCompanyBusinessUnit()
      .pipe(take(1))
      .subscribe(businessUnit => {
        this.customerBusinessUnitName = businessUnit?.name;
      });
  }

  private selectCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
      this.cart = cart;
    });
  }

  private loadSystemContractInformation(): void {
    this.marketingFacade.getCartItems(this.cartId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
      this.itemsWithDetails = cartItems.included.find(
        item => item.type === 'items' && item.attributes.cpqConfigId === this.serviceParams.configId)?.attributes;
      const skuName = cartItems.included.find(
        item => item.type === 'concrete-products' && item.attributes.sku === this.itemsWithDetails.sku)?.attributes.name;
      this.contractSystemInformation = {
        address: CpqUtils.cpqAddressToString(this.itemsWithDetails.customerSoldToAddress),
        details: [
          {
            label: 'configuration-summary.service-plan',
            data: skuName ?? '-',
          },
          {
            label: 'configuration-summary.contract-start-date',
            data: this.itemsWithDetails.systemDetails.contractStartDate ?
              formatDate(this.itemsWithDetails.systemDetails.contractStartDate, 'MM/dd/yyyy', 'en') : '-',
          },
          {
            label: 'configuration-summary.contract-end-date',
            data: this.itemsWithDetails.systemDetails.contractExpirationDate ?
              formatDate(this.itemsWithDetails.systemDetails.contractExpirationDate, 'MM/dd/yyyy', 'en') : '-',
          },
          {
            label: 'configuration-summary.end-of-support',
            data: this.itemsWithDetails.systemDetails.endOfSupport ?
              formatDate(this.itemsWithDetails.systemDetails.endOfSupport, 'MM/dd/yyyy', 'en') : '-',
          },
        ],
      };

      if (this.contractSystemInformation.address) {
        this.businessAddresses = [this.itemsWithDetails.customerSoldToAddress] as IAddressExtended[];
      } else {
        this.businessAddresses = [
          {
            address1: 'empty',
            address2: 'empty',
            city: 'empty',
            company: 'empty',
            country: 'empty',
            firstName: 'empty',
            id: 'empty',
            isDefaultBilling: false,
            isDefaultShipping: false,
            iso2Code: 'empty',
            lastName: 'empty',
            phone: 'empty',
            salutation: 'empty',
            zipCode: 'empty',
          },
        ];
      }
      this.loadConfigurationSummary();
    });
  }

  private loadConfigurationSummary(): void {
    this.cpqFacade.reconfigureService(this.cartId, this.itemsWithDetails.sku, this.serviceParams.configId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
        next: data => {
          const config: ICPQConfigurationSummary = data.data.attributes.cpqResponse;
          this.products = config.bom.reduce((acc, next) => {
            const itemPrice = config.pricing.rows.find(item => item.PartNumber === next.attributes.SKU);
            const groupIndex = acc.findIndex(group => group.name === next.attributes.groupDescription);
            const lineItem = {
              name: next.description,
              price: itemPrice.ListPrice,
              currency: itemPrice.Currency,
            };
            if (groupIndex !== -1) {
              acc[groupIndex].details.push(lineItem);
            } else {
              acc.push({
                name: next.attributes.groupDescription,
                details: [lineItem],
              });
            }
            return acc;
          }, []);
          this.priceSummary = config.pricing.summary;
          this.loading = false;
        },
        error: () => {
          this.isCpqConfigInUrl = false;
          this.loading = false;
        },
      },
    );
  }

  private setDownloadErrorMessage(): void {
    this.translate.getTranslationByKey(this.docDownloadErrorMsgKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        message => this.docDownloadErrorMsg = message,
      );
  }
}
