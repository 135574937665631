<ng-container>
  <div
      class="drag-drop-component"
      [appLoadingAnimation]="uploadingFileInProgress"
      [attr.data-test]="'drag-drop-file-component'">
    <h6>{{ 'drag-drop.upload-headline' | translate }}</h6>
    <div
        *ngIf="!files; else filesAdded"
        class="file-upload-container"
        appDragDropFileUpload
        (fileDropped)="handleDragDropFiles($event)"
        [appLoadingAnimation]="uploadingFileInProgress"
        [attr.data-test]="'drag-drop-file-container'">
      <div class="file-upload-text-wrapper">
        <span class="icon-upload" [attr.data-test]="'drag-drop-file-icon'"></span>
        <div class="file-upload-text">
          <span [attr.data-test]="'drag-drop-file-title'">
            <b> {{ 'drag-drop.upload-title' | translate }} </b>
          </span>
          <span [attr.data-test]="'drag-drop-file-desc'"> {{ 'drag-drop.upload-desc' | translate }}</span>
        </div>
      </div>
    </div>

    <ng-template class="files-added" #filesAdded [attr.data-test]="'file-uploaded'">
      <div class="files-added" [appLoadingAnimation]="uploadingFileInProgress">
        <span [attr.data-test]="'uploaded-file-name'"> {{ files[0].name }} </span>
        <span
            class="delete-file"
            (click)="deleteUploadedFiles()"
            [attr.data-test]="'delete-uploaded-file'"> <b>x</b></span>
      </div>
    </ng-template>

    <div
        class="invalid-file-format-container"
        *ngIf="notSupportedFilesFormatError !== ''"
        [attr.data-test]="'invalid-csv-file-container'">
      <span class="icon-close icon-close__circle" [attr.data-test]="'invalid-csv-file-circle'"></span>
      <span class="invalid-file" [attr.data-test]="'invalid-csv-file-text'"> {{ 'drag-drop.invalid-format' | translate }}</span>
    </div>

    <div class="file-upload-buttons-container">
      <button
          class="button button--link"
          (click)="createCsv('quick-order-template')"
          [attr.data-test]="'button-downloadCartItemsCsv'">
        <span class="icon-download" [attr.data-test]="'button-download-csv-template-icon'"></span>
        <span class="text download-csv" [attr.data-test]="'button-download-csv-template-text'">
            {{ 'drag-drop.download' | translate }}
        </span>
      </button>
      <button
          [disabled]="!files || disabledAddButton"
          class="button upload-csv"
          (click)="uploadFiles()"
          [attr.data-test]="'button-uploadCartItemsCsv'">
        {{ 'drag-drop.upload' | translate }}
      </button>
    </div>
  </div>
</ng-container>
