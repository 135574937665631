<ng-container>
  <div class="mini-cart__actions" *ngIf="currentCartId">
    <div class="mini-cart__name">
      <h5 *ngIf="usersCarts?.length === 1" class="current-cart-name">
        {{ currentCartName }}
      </h5>
      <app-dropdown [dropDownName]="'miniCart-name'" *ngIf="usersCarts?.length > 1">
        <ng-container ngProjectAs="[slot][toggle]">
          <button class="button button--link mini-cart__btn-name">
            <h5>{{ currentCartName }}</h5>
            <span class="icon-arrow-down"></span>
          </button>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <ng-container>
            <span *ngFor="let cart of usersCarts"
                class="menu__item"
                [ngClass]="{'hidden': cart.id === currentCartId || cart.approverId }"
                (click)="switchDefaultCart(cart.id)">
              {{ cart.name }}
            </span>
          </ng-container>
        </ng-container>
      </app-dropdown>
    </div>
    <div class="mini-cart__settings">
      <app-dropdown [dropDownName]="'miniCart-settings'">
        <ng-container ngProjectAs="[slot][toggle]">
          <button class="button button--link mini-cart__btn-settings">
            <h5>{{ 'mini-cart.cart-settings' | translate }}</h5>
            <span class="icon-arrow-down"></span>
          </button>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <span class="menu__item" (click)="showAddNicknameModalEmit()">
            {{ getAddOrEditNicknameLabel() | translate }}
          </span>
          <span class="menu__item" (click)="showDeleteCartModalEmit()">
            {{ 'mini-cart.delete-cart' | translate }}
          </span>
        </ng-container>
      </app-dropdown>
    </div>
  </div>
  <div class="mini-cart__items" *ngIf="itemsInCart?.length > 0">
    <div class="overflow-gradient"></div>
    <div class="items-list">
      <div class="item-row" *ngFor="let item of itemsInCart; let index = index;">
        <div class="item-col-img">
          <img [src]="getItemPicture(item)" [alt]="item.attributes.name">
        </div>
        <div class="item-col-desc" [attr.data-test]="'desc-minicartItemName-'+item.attributes.name">
          {{ item.attributes.name || item.attributes.attributes?.sap_name }}<br>
          <small>{{ item.attributes.materialNumber || item.attributes.attributes?.sap_material_number }}</small>
        </div>
        <div class="item-col-qty" [attr.data-test]="'desc-minicartItemQuantity-'+item.attributes.name">
          <form (submit)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)">
            <input #itemQuantity
                type="number"
                name="itemQuantity"
                class="item-quantity"
                [value]="item.attributes.quantity"
                min="1"
                step="1"
                required
                (change)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)"/>
          </form>
        </div>
        <div class="item-col-price" [attr.data-test]="'desc-minicartItemPrice-'+item.attributes.name"
             *ngIf="!!item.attributes?.calculations?.unitPrice && checkWorkflowInformation">
          {{ item.attributes.calculations.unitPrice | currency:currency }}
          <span *ngIf="isExcludeTaxActive" class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
