import { IInstalledBaseSystemDataEquipmentSelection } from '../models/equipmentselection.models';
import { IInstalledBaseSystemData } from '../models/installedbase.models';
import { ISystemDetails } from '../models/common.models';

export class EquipmentUtils {

  static getAttribute(
    agreement: IInstalledBaseSystemDataEquipmentSelection | IInstalledBaseSystemData,
    attribute: string,
    nestedAttribute?: string,
  ): string {
    if (nestedAttribute) {
      return agreement.attributes[attribute][nestedAttribute] ? agreement.attributes[attribute][nestedAttribute] : '-';
    } else {
      return agreement.attributes[attribute] ? agreement.attributes[attribute] : '-';
    }
  }

  /**
   * Convert response as returned by opal-systems-search to cart-compatible SystemDetails object.
   *
   * @param {IInstalledBaseSystemDataEquipmentSelection} equipment
   * @returns {ISystemDetails}
   */
  static convertEquipmentSelectionDataToSystemDetails(
    equipment: IInstalledBaseSystemDataEquipmentSelection
  ): ISystemDetails {
    const att = equipment.attributes;
    return {
      company: att?.customerName,
      companyBusinessUnit: att?.customerName,
      companyBusinessUnitNumber: att?.shipToAddress?.sapId,
      materialNumber: att?.materialNumber,
      nameEnUs: att?.equipmentName,
      namePtBr: att?.equipmentName,
      serialNumber: '',
      siemensEquipmentId: att?.functionalLocation,
      contractStartDate: att?.contractStartDate,
      contractExpirationDate: att?.contractExpirationDate,
      endOfSupport: att?.contractEndOfSupport,
      shipToAddress: att?.shipToAddress,
    };
  }
}
