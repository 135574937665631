import { Component, Input } from '@angular/core';
import { IPricingSummary } from '../../../models/cpq.models';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss'],
})
export class PriceListComponent {
  @Input() priceList: Array<any>;
  @Input() priceSummary: IPricingSummary;
}
