<div class="pa-page">
  <div class="pa-page__top">
    <div class="pa-page__search" *ngIf="quotesUnderReview.length > 0 || fulfilledOrders.length > 0">
      <div class="contract-filter-panel--search">
        <div class="search-field textfield textfield--with-prefix">
          <span class="textfield__prefix icon-search"></span>
          <input
              id="search"
              name="search"
              type="search"
              class="textfield__input search-field__input"
              spellcheck="false"
              autocomplete="off"
              placeholder="{{ 'service-purchase-activity.search-field-label' | translate }}"
              [disabled]="searchLoading"
              [(ngModel)]="searchValue">
          <label for="search" class="search-bar__label"></label>
        </div>
      </div>
    </div>
  </div>
  <div class="pa-page__main under-review" [ngClass]="isUnderReviewLoading ? 'loading-container-for-cpq' : ''">
    <div class="under-review-title">
      <h5>{{ 'service-purchase-activity.under-review-title' | translate }}</h5>
      <p>{{ 'service-purchase-activity.under-review-text' | translate }}</p>
    </div>
    <ng-container *ngIf="quotesUnderReview.length > 0 && !isUnderReviewLoading else noUnderReview">
      <app-service-list
          [purchaseData]="quotesUnderReview"
          [loading]="isUnderReviewLoading"
          [searchValue]="searchValue"
          [headers]="underReviewHeaders">
      </app-service-list>
      <app-pagination-linkless
          [pageCount]="underReviewOrdersNumPages"
          [currentPage]="underReviewOrdersCurrentPage"
          (pageChange)="goToCpqUnderReviewOrdersPage($event)">
      </app-pagination-linkless>
    </ng-container>
    <ng-template #noUnderReview>
      <p>{{ 'service-purchase-activity.under-review-empty' | translate }}</p>
    </ng-template>
    <div *ngIf="isUnderReviewLoading" class="loader__overlay">
      <div class="loader__content">
        <div class="loading-animation"></div>
      </div>
    </div>
  </div>
  <div class="pa-page__main fulfilled-orders" [ngClass]="isFulfilledLoading ? 'loading-container-for-cpq' : ''">
    <div class="fulfilled-orders-title">
      <h5>{{ 'service-purchase-activity.fulfilled-title' | translate }}</h5>
      <p>{{ 'service-purchase-activity.fulfilled-text' | translate }}</p>
    </div>
    <ng-container *ngIf="fulfilledOrders.length > 0 && !isFulfilledLoading else noFulfilled">
      <app-service-list
          [purchaseData]="fulfilledOrders"
          [loading]="isFulfilledLoading"
          [searchValue]="searchValue"
          [headers]="fulfilledHeaders">
      </app-service-list>
      <app-pagination-linkless
          [pageCount]="fulfilledOrdersNumPages"
          [currentPage]="fulfilledOrdersCurrentPage"
          (pageChange)="goToCpqFulfilledOrdersPage($event)">
      </app-pagination-linkless>
    </ng-container>
    <ng-template #noFulfilled>
      <p>{{ 'service-purchase-activity.fulfilled-empty' | translate }}</p>
    </ng-template>
    <div *ngIf="isFulfilledLoading" class="loader__overlay">
      <div class="loader__content">
        <div class="loading-animation"></div>
      </div>
    </div>
  </div>
</div>
