<div
    class="dropdown"
    [ngClass]="[active ? 'is-open':  '', 'dropdown-'+menuDirection, cssStyles.dropdown]"
    [attr.data-test]="'dropdown-' + dropDownName">
  <button
      class="dropdown__toggle"
      [ngClass]="cssStyles.toggle"
      (click)="activeDropdown()">
    <ng-content select="[slot][toggle]"></ng-content>
  </button>
  <div
      class="menu dropdown__panel"
      [attr.data-test]="'dropdown-' + dropDownName + '-menu'"
      (click)="select()">
    <ng-content *ngIf="!isCheckbox; else checkbox" select="[slot][panel]"></ng-content>
    <ng-template #checkbox>
      <div class="checkboxDropdown">
        <div class="dropdown-search">
          <div class="search-field textfield textfield--with-prefix">
            <span class="textfield__prefix icon-search"></span>
            <input
                id="search"
                name="search"
                type="search"
                class="textfield__input search-field__input"
                spellcheck="false"
                autocomplete="off"
                placeholder="{{ 'advanced-filter.search'  | translate }} {{ checkboxName }}"
                [(ngModel)]="searchValue">
            <label for="search" class="search-bar__label"></label>
          </div>
          <div class="selection" *ngIf="checkboxOptions">
            <div class="select-option">
              <input
                  class="checkbox"
                  type="checkbox"
                  [id]="'select-all-'+dropDownName"
                  [name]="'checkbox-all-'+dropDownName"
                  [checked]="selectAll"
                  (change)="selectAllFilteredOptions()">
              <label [for]="'select-all-'+dropDownName">{{ 'my-contracts.select-all' | translate}}</label>
            </div>
            <div *ngFor="let option of checkboxOptions | searchFilter: searchValue" class="select-option">
              <input
                  class="checkbox"
                  type="checkbox"
                  [id]="'select-'+option"
                  [name]="'checkbox-'+option"
                  [checked]="selectedOptions.includes(option)"
                  (change)="selectOption(option)">
              <label [for]="'select-'+option">{{ option }}</label>
            </div>
          </div>
          <div class="buttons">
            <button class="button button--standard" (click)="cancel()">Cancel</button>
            <button class="button button--primary" (click)="applyFilters()">Apply</button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
