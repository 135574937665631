import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-wishlist-remove',
  templateUrl: './wishlist-remove.component.html',
  styleUrls: ['./wishlist-remove.component.scss'],
})
export class WishlistRemoveComponent {
  @Input() wishlist: any;
  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();

  resetForm(): void {
    this.closeModal.emit();
  }

  remove(): void {
    this.removed.emit();
    this.resetForm();
  }
}
