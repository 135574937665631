import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as fromAuth from './reducers';
import { AuthActions } from './actions';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<fromAuth.State>,
    private authService: AuthService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => {
        if (!isAuthenticated) {
          const urlTree = this.router.parseUrl(state.url);

          this.store.dispatch(AuthActions.logIn({
            url: state.url.split('?')[0],
            queryParameters: urlTree.queryParams
          }));

          return false;
        }

        return true;
      })
    );
  }
}
