<div class="layout-options">
  <span
      [attr.data-test]="'button-selectLayoutGrid'"
    class="icon-layout-2x2"
    [ngClass]="{'active': currentLayoutType === 'grid'}"
    (click)="selectLayoutOption('grid')">
  </span>
  <span
      [attr.data-test]="'button-selectLayoutList'"
    class="icon-layout-list"
    [ngClass]="{'active': currentLayoutType === 'list'}"
    (click)="selectLayoutOption('list')">
  </span>
</div>
