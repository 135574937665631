import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IOrder, IOrderWithMessages } from '../../models/order.models';
import { EScenarioType } from '../../configurations/order-status';
import { environment } from '../../../environments/environment';
import { OrdersFacade } from '../../facades/orders.facade';
import { EFeatureToggles, EMultiCartTabs, ESparePartsOrderHistoryTabs } from '../../configurations/common';
import { IconUtils } from '../../utils/icon.utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-spare-part-order-details',
  templateUrl: './spare-part-order-details.component.html',
  styleUrls: ['./spare-part-order-details.component.scss'],
})
export class SparePartOrderDetailsComponent implements OnInit, OnDestroy {
  orderDetails: IOrder | IOrderWithMessages;
  orderDetailsAccordion: boolean = false;
  isLoaded: boolean = false;

  scenarioType: EScenarioType = EScenarioType.SPARE_PART_ORDER_DETAILS;
  returnDocumentUrl: string = '';
  sparePartsReturnDocumentUrlKey: string = environment.sparePartsReturnDocumentUrlKey;
  isReturnableDocumentEmailLinkEnable: boolean = false;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ordersFacade: OrdersFacade,
    private translateService: TranslateService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getOrderDetails(params.orderId);
      });

    this.configurationFacade.isFeatureEnabled(
      EFeatureToggles.RETURNABLE_DOCUMENT_EMAIL_LINK_FROM_PURCHASE_ACTIVITY_PAGE,
    ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isReturnableDocumentEmailLinkEnable = value;
    });

    this._getReturnDocumentUrl();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getOrderDetails(orderId: string): void {
    this.ordersFacade.getOrderById(orderId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(order => {
      this.orderDetails = order.data;
      this.isLoaded = true;
    });
  }

  getIcon(): string {
    return IconUtils.getStatusIcon(this.orderDetails.attributes.stateDisplayName);
  }

  getStatus(status: string): string {
    if (!status || status === '') {
      return '';
    }

    return status.replace(' ', '-');
  }

  getTrackingNumbers(item: any): string[] {
    if (item.sapDetails?.trackingNumber) {
      return item.sapDetails.trackingNumber.split(',');
    }

    return [];
  }

  getTotalItemTranslation(itemsNumber: number): string {
    return itemsNumber <= 1
      ? 'order-thank-you-page.total-item-number-singular'
      : 'order-thank-you-page.total-item-number-plural';
  }

  toggleOrderDetailsAccordion(): void {
    this.orderDetailsAccordion = !this.orderDetailsAccordion;
  }

  downloadDocumentation(): void {
    window.open(this.returnDocumentUrl, '_blank');
  }

  redirectBackToPurchaseActivity(): void {
    this.router.navigate(
      ['/purchase-activity'],
      {
        queryParams: {
          tab: EMultiCartTabs.PARTS,
          subtab: ESparePartsOrderHistoryTabs.ORDERS,
        },
      },
    );
  }

  private _getReturnDocumentUrl(): void {
    this.translateService.get(this.sparePartsReturnDocumentUrlKey).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.returnDocumentUrl = value;
    });
  }
}
