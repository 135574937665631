import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImageUtils } from '../../utils/image.utils';
import { IAdditionalItemField } from '../../models/common.models';

@Component({
  selector: 'app-quote-item-with-ivk-form',
  templateUrl: './quote-item-with-ivk-form.component.html',
  styleUrls: ['./quote-item-with-ivk-form.component.scss'],
})
export class QuoteItemWithIvkFormComponent {
  @Input() item: any;
  @Input() installBaseLoading;
  @Input() systems = [];
  @Input() businessUnits = [] as Array<{name: string, value: string}>;
  @Input() businessUnitLoading = false;
  @Input() additionalFields = [] as Array<IAdditionalItemField>;

  @Output() formChanged = new EventEmitter<any>();
  @Output() formHidden = new EventEmitter<any>();

  separateSystemChecked = false;

  getProductImageUrl(): string {
    return ImageUtils.getProductImageUrl(this.item);
  }

  toggleForm(event: any): void {
    this.separateSystemChecked = event.target.checked;

    if (!this.separateSystemChecked) {
      this.formHidden.emit(this.item.id);
    }
  }

  onFormChange(form: UntypedFormGroup): void {
    this.formChanged.emit({
      itemId: this.item.id,
      formGroup: form
    });
  }
}
