<p>{{ 'quote-summary.select-addresses' | translate }}</p>
<div class="bill-to-address" *ngIf="billToAndPayerAddressForm">
  <app-select-box
      label="{{ 'quote-summary.bill-to-address' | translate }}"
      [form]="billToAndPayerAddressForm"
      [options]="billToAddresses"
      [modelValue]="billToAndPayerAddressForm.value.billToAddress"
      [inputName]="'billToAddress'"
      (selected)=setFormValue($event)>
  </app-select-box>
  <div class="actions">
    <app-tooltip
        link="icon"
        text="{{ 'quote-summary.bill-to-address-tooltip' | translate }}">
    </app-tooltip>
    <div class="wrong-address" [attr.data-test]="'button-reportWrongAddressBillToAddress'">
      <span class="icon icon-arrow-right-small"></span>
      <button class="text action" (click)="showReportWrongAddressModal(billToAddresses, 'quote-summary.bill-to-address', typeBillTo)">
        {{ 'quote-summary.report-wrong-address' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="payer-address">
  <app-select-box
      label="{{ 'quote-summary.payer-address' | translate }}"
      [form]="billToAndPayerAddressForm"
      [options]="payerAddresses"
      [modelValue]="billToAndPayerAddressForm.value.payerAddress"
      [inputName]="'payerAddress'"
      (selected)=setFormValue($event)>
  </app-select-box>
  <div class="actions">
    <app-tooltip
        link="icon"
        text="{{ 'quote-summary.payer-address-tooltip' | translate }}">
    </app-tooltip>
    <div class="wrong-address" [attr.data-test]="'button-reportWrongAddressPayerAddress'">
      <span class="icon icon-arrow-right-small"></span>
      <button class="text action" (click)="showReportWrongAddressModal(payerAddresses,'quote-summary.payer-address', typePayer)">
        {{ 'quote-summary.report-wrong-address' | translate }}
      </button>
    </div>
  </div>
</div>
<button
    *ngIf="!quoteDocumentGenerationInProgress; else loadingSpinner"
    type="submit"
    class="button button--primary"
    [attr.data-test]="'button-confirmAddress'"
    data-dismiss="modal-dialog"
    [disabled]="!isFormValid()"
    (click)="submitBillToAndPayerAddress()">
  {{ 'quote-summary.generate-document-confirm' | translate }}
</button>
<ng-template #loadingSpinner>
  <div class="btn-spinner">
    <div class="loading-animation"></div>
  </div>
</ng-template>
<app-report-wrong-address-modal
    [roleSpecificMessage]="true"
    [cpqOnly]="true"
    [addressesList]="addressesToReport"
    [showModal]="reportWrongAddressModalFlag"
    [isQuoteSummaryComponent]="true"
    [billOrPayer]="true"
    [cpqAddressType]="addressType"
    [cpqLabelName]="labelName"
    [functionalLocations]="functionalLocations"
    [quoteId]="cart?.id || ''"
    (closeModal)="closeModals()">
</app-report-wrong-address-modal>

