import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageUtils } from '../../../../utils/image.utils';
import { CartUtils } from '../../../../utils/cart.utils';
import { UiUtils } from '../../../../utils/ui.utils';
import { CustomerFacade } from '../../../../facades/customer.facade';

@Component({
  selector: 'app-other-cart',
  templateUrl: './other-cart.component.html',
  styleUrls: ['./other-cart.component.scss'],
})
export class OtherCartComponent {
  @Input() isUsStore: boolean;
  @Input() isCaStore: boolean;
  @Input() loadingCart: boolean;
  @Input() cart: any;
  @Input() cartImages: any[];

  @Output() switchCartEmit = new EventEmitter<{cartId: string, redirect: string}>();
  @Output() deleteCartEmit = new EventEmitter<{cartId: string, totalItemsQuantity: number}>();

  constructor(
    private customerFacade: CustomerFacade
  ) {
  }

  cartName = CartUtils.getCartName;
  singularOrPlural = UiUtils.singularOrPlural;

  getImageForCart(itemId: string): string {
    return ImageUtils.getProductImageUrl(
      this.cartImages.find(imageSet => imageSet.id === itemId)
    );
  }

  switchCart(cartId: string, redirect?: string): void {
    this.customerFacade.clearPointOfContact();
    this.customerFacade.clearCustomCustomerShipToAddress();
    this.switchCartEmit.emit({cartId, redirect});
  }

  deleteCart(cartId: string, totalItemsQuantity: number): void {
    this.deleteCartEmit.emit({cartId, totalItemsQuantity});
  }
}
