import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  IAbstractProductIncluded,
} from '../../../models/abstract-product.models';
import { IContractParams } from '../../../models/catalog.models';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { environment } from '../../../../environments/environment';
import { EProductDetails } from '../../../configurations/product-details';
import { ProductUtils } from '../../../utils/product.utils';
import { CpqUtils } from '../../../utils/cpq.utils';
import { IAddToCartAdditionalFields } from '../../../models/cart.models';

@Component({
  selector: 'app-configurable-product-pdp',
  templateUrl: './configurable-product-pdp.component.html',
  styleUrls: ['./configurable-product-pdp.component.scss'],
})
export class ConfigurableProductPdpComponent {
  openZoomedPicture: boolean = false;

  @Input() sku;
  @Input() productInfoLoaded;
  @Input() abstractProduct;
  @Input() defaultImg;
  @Input() mainPicture;
  @Input() productDetailsEnum;
  @Input() installBaseProducts;
  @Input() displayConfig;
  @Input() contractParams;
  @Input() flNumber;
  @Input() materialNumber;
  @Input() stepNumber;
  @Input() concreteProduct;
  @Output() addProductToCartSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private catalogFacade: CatalogFacade,
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  isConfigurable(): boolean {
    return this.concreteProduct ? this.concreteProduct.attributes.productConfigurationInstance !== null : false;
  }

  isServiceUnderConfiguration(): boolean {
    return !!this.flNumber && !!this.materialNumber && this.isConfigurable();
  }

  addProductToCart(): void {
    this.addProductToCartSubmit.emit({addSystemDetails: true} as IAddToCartAdditionalFields);
  }

  openZoomPicture(): void {
    if (this.mainPicture !== this.defaultImg) {
      this.openZoomedPicture = !this.openZoomedPicture;
      if (this.openZoomedPicture) {
        this.analyticsService.setProducts(this.abstractProduct);
        this.analyticsService.trackProduct('image.enlarge', this.abstractProduct);
      }
    }
  }

  getDetailAttribute(attributeValue: string | null): string {
    return ProductUtils.getDetailAttribute(attributeValue);
  }

  tracking(name: string): void {
    this.analyticsService.setProducts(this.abstractProduct);
    this.analyticsService.trackAccordion(name);
  }

  trackInstalledBase(installedBase): void {
    this.catalogFacade.setInstalledBase(installedBase);
    this.analyticsService.trackInstalledBase(installedBase.attributes.nameEnUs, installedBase.id);
  }

  displayProductDetail(section: EProductDetails): boolean {
    return this.displayConfig.includes(section);
  }

  hasTranslation(key: string): boolean {
    const translation = this.translate.instant(key);
    return !!translation && translation !== key;
  }

  getCpqItemsAttributes(): string[] {
    return this.concreteProduct?.attributes.superAttributesDefinition.filter(el => {
      return environment.cpqItemAttributesToHide.indexOf(el) < 0;
    });
  }

  isContactUsButtonDisplay(): boolean {
    return this.isConfigurable() && this.concreteProduct?.attributes.attributes.available === '0';
  }

  cpqButtonText(): string {
    const selectOption = this.isServiceUnderConfiguration() ? 'app-product-page.configure-online' : 'app-product-page.select-equipment';
    return this.isContactUsButtonDisplay() ? 'catalog.purchase-offline' : selectOption;
  }

  cpqButtonAction(product: IAbstractProductIncluded): void {
    this.isContactUsButtonDisplay() ? this.contactUsForm(product) : this.cpqServiceAction();
  }

  getExtendedContractParams(product: IAbstractProductIncluded): IContractParams {
    return {
      ...CpqUtils.filterOutEmptyParams(this.contractParams),
      selectedService: product.attributes.name
    };
  }

  showContractStepsBar(): boolean {
    return this.productInfoLoaded && this.flNumber && this.materialNumber;
  }

  private cpqServiceAction(): void {
    this.isServiceUnderConfiguration() ? this.cpqStartConfiguration() : this.selectEquipment();
  }

  private contactUsForm(product: IAbstractProductIncluded): void {
    const url = this.router.createUrlTree(
      ['/contact-us'],
      {queryParams: this.getExtendedContractParams(product)},
    ).toString();

    this.router.navigateByUrl(url).then();
  }

  private cpqStartConfiguration(): void {
    this.marketingFacade.startContractConfiguration(this.sku, this.flNumber, this.materialNumber);
  }

  private selectEquipment(): void {
    this.router.navigate(['/my-installed-base'], {queryParams: {selectedService: this.sku, tab: 'my-contracts'}}).then();
  }
}
