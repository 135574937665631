<app-contract-steps-bar *ngIf="soldTo" [soldTo]="soldTo"></app-contract-steps-bar>
<ng-container *ngIf="productSku">
  <h6>{{ 'my-contracts.my-contracts-header-label' | translate }}</h6>
  <app-product-base-info [productSku]="productSku"></app-product-base-info>
</ng-container>
<h4 [attr.data-test]="'title-myInstalledBase'">{{ 'my-installed-base.title' | translate }}</h4>
<ng-container *ngIf="isCpqEnabled && isSparePartsEnabled && !soldTo; else tabsNotAvailable">
  <p [attr.data-test]="'desc-myInstalledBaseDesc'" class="description">{{ 'my-installed-base.description' | translate }}</p>
  <div class="bare-list tabs__list">
    <ng-container *ngFor="let tab of tabs">
      <h6 class="tabs__item" [attr.data-test]="'tab-' + tab" [ngClass]="{'is-active is-active__bar': currentTab === tab}" (click)="changeTab(tab)">
        {{ 'my-installed-base.' + tab + '-tab-title' | translate }}
      </h6>
    </ng-container>
  </div>
  <app-page-my-contracts
      *ngIf="currentTab === EInstalledBaseTabs.CONTRACTS"
      [installedBaseSystemData]="cpqEquipments"
      [installBaseLoading]="cpqEquipmentsLoading"
      [isCpqEnabled]="isCpqEnabled"
      [soldTo]="soldTo"
      [productSku]="productSku"
      [modalitiesAndMaterialNumbers]="cpqModalitiesAndMaterialNumbers">
  </app-page-my-contracts>
  <app-my-equipment
      *ngIf="currentTab === EInstalledBaseTabs.EQUIPMENT"
      [isCartOperationInProgress]="isCartOperationInProgress"
      [loadingCartDataInProgress]="loadingCartDataInProgress"
      [installedBaseSystemData]="sparePartsEquipments"
      [installBaseLoading]="sparePartsEquipmentsLoading"
      [soldTo]="soldTo"
      [productSku]="productSku"
      [isReorderPending]="isReorderPending"
      [companyRoles]="companyRoles"
      [isCaStore]="isCaStore">
  </app-my-equipment>
</ng-container>
<ng-template #tabsNotAvailable>
  <ng-container *ngIf="isCpqEnabled">
    <p class="description">{{ 'my-contracts.description' | translate }}</p>
    <app-page-my-contracts
        class="my-contracts"
        [installedBaseSystemData]="cpqEquipments"
        [installBaseLoading]="cpqEquipmentsLoading"
        [isCpqEnabled]="isCpqEnabled"
        [soldTo]="soldTo"
        [productSku]="productSku"
        [modalitiesAndMaterialNumbers]="cpqModalitiesAndMaterialNumbers">
    </app-page-my-contracts>
  </ng-container>
  <ng-container *ngIf="isSparePartsEnabled">
    <app-my-equipment
        class="my-equipment"
        [isCartOperationInProgress]="isCartOperationInProgress"
        [loadingCartDataInProgress]="loadingCartDataInProgress"
        [installedBaseSystemData]="sparePartsEquipments"
        [installBaseLoading]="sparePartsEquipmentsLoading"
        [soldTo]="soldTo"
        [productSku]="productSku"
        [isReorderPending]="isReorderPending"
        [companyRoles]="companyRoles"
        [isCaStore]="isCaStore">
    </app-my-equipment>
  </ng-container>
</ng-template>
