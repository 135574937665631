import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '../../analytics/analytics.service';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import * as fromAuth from '../../auth/reducers';
import { CustomerFacade } from '../../facades/customer.facade';
import { StoreSelectionFacade } from '../../facades/store-selection.facade';
import { UtilsService } from '../../services/utils.service';
import { EUserRoles } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { INavigationResponse } from '../../models/settings.model';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @ViewChild('navigationBarListElem') navigationBarListElem: ElementRef;
  @ViewChild('navigationHamburgerElem') navigationHamburgerElem: ElementRef;

  isGuest: boolean;
  navigationBarList: any;
  navigationBarListLoaded = false;
  active = false;
  companyRoles: EUserRoles[];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
    private renderer: Renderer2,
    private store: Store<fromAuth.State>,
    private analyticsService: AnalyticsService,
    private customerFacade: CustomerFacade,
    private storeSelectionFacade: StoreSelectionFacade,
    private utils: UtilsService,
  ) {
  }

  ngOnInit(): void {
    this.renderer.listen('window', 'click', (e) => {
      if (!this.navigationBarListElem?.nativeElement.contains(e.target)) {
        this.active = false;
      }
    });
    this.selectCompanyRole();
    this.selectIsGuest();
    this.selectCompanyRolesLoading();
    this.utils.getCustomRouteData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCompanyRole(): void {
    this.customerFacade.getCustomerCompanyRoles()?.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  selectIsGuest(): void {
    this.customerFacade.selectIsGuest().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isGuest = state;
    });
  }

  selectCompanyRolesLoading(): void {
    this.customerFacade.selectCompanyRolesLoading().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      if (!state) {
        this.getNavigationBarList();
      }
    });
  }

  getNavigationBarList(): void {
    this.configFacade.getNavigationBar(this.isGuest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((navigationResponse: INavigationResponse) => {
        const {nodes} = navigationResponse.data.attributes;
        if (nodes && nodes.length > 0) {
          this.navigationBarList = nodes.filter(node => node.isActive);
          this.navigationBarListLoaded = true;
        }
        if (!(this.companyRoles.includes(EUserRoles.Guest))) {
          this.navigationBarList = AppUtils.filterNodesByRole(this.navigationBarList, this.companyRoles);
        }
        this.configFacade.navBarLoaded();
      }, error => {
        this.configFacade.navBarLoaded();
      });
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  tracking(navitem: string): void {
    this.analyticsService.trackNavigation(navitem);
  }

  hideNavigation(): boolean {
    return !!this.utils.customData['hideSearchAndActions'] || this.storeSelectionFacade.isOnStoreSelectionPage();
  }
}
