import { HttpClient } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import { Observable } from 'rxjs';
import { IStoreConfiguration } from '../models/store-configuration.models';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandler } from '../error/GlobalErrorHandler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StoreConfigurationService {
  private storeConfigurationUrl = '/stores';
  private url = this.glueConfiguration.getEndpointUrl() + this.storeConfigurationUrl;

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  getStoreConfiguration(storeId: string): Observable<IStoreConfiguration> {
    return this.httpClient.get<IStoreConfiguration>(`${this.url}/${storeId}`).pipe(
      catchError(error => GlobalErrorHandler.handleError(error.error.errors[0])),
    );
  }
}
