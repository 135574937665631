import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICreateRfQ, IRfqCreateResponse, IRfqCancelDetails, IRfqDetails, IRfqHistory } from '../models/rfq.models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class RfqService {

  private rfqUri = '/quote-requests';
  private rfqUrl = `${this.glueConfiguration.getEndpointUrl()}${this.rfqUri}`;

  constructor(
    private httpClient: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  createRequestForQuote(quote: ICreateRfQ): Observable<IRfqCreateResponse> {
    return this.httpClient
      .post<any>(this.rfqUrl, quote)
      .pipe(
        catchError(RfqService.handleError));
  }

  getRfqHistory(): Observable<IRfqHistory> {
    return this.httpClient
      .get<IRfqHistory>(this.rfqUrl)
      .pipe(
        catchError(RfqService.handleError),
      );
  }

  getRfqDetails(rfqId: string): Observable<IRfqDetails> {
    return this.httpClient.get<IRfqDetails>(`${this.rfqUrl}/${rfqId}`)
      .pipe(catchError(RfqService.handleError));
  }

  cancelRfq(rfqId: string, rfqCancelData: IRfqCancelDetails): Observable<any> {
    return this.httpClient.post<any>(`${this.rfqUrl}/${rfqId}/quote-request-cancel`, rfqCancelData)
      .pipe(catchError(RfqService.handleError));
  }

  getRfqPdfFile(rfqId: string): Observable<Blob> {
    return this.httpClient.get(`${this.rfqUrl}/${rfqId}/generate-pdf`, { responseType: 'blob' })
      .pipe(catchError(RfqService.handleError));
  }

}
