<div class="price-list">
  <div class="price-list__items">
    <div class="price-list__group" *ngFor="let group of priceList">
      <span class="price-list__group-name">{{ group.name }}</span>
      <span class="price-list__group-price" *ngIf="group.totalPrice > 0">
        {{ group.totalPrice  | currency: undefined: undefined: 0 }}
      </span>
      <span class="price-list__item" *ngFor="let item of group.items">{{ item.name }}</span>
    </div>
  </div>
  <div class="price-list__summary list">
    <span class="price-list__summary-name">{{ 'service-configuration.list-price' | translate }}</span>
    <span class="price-list__summary-price">{{ priceSummary.ListPrice  | currency: undefined: undefined: 0 }}</span>
  </div>
  <div class="price-list__summary discount" *ngIf="priceSummary.ContractReduction > 0">
    <span class="price-list__summary-name">{{ 'service-configuration.discount' | translate }}</span>
    <span class="price-list__summary-price">- {{ priceSummary.ContractReduction  | currency: undefined: undefined: 0 }}</span>
  </div>
  <div class="price-list__summary discount" *ngIf="priceSummary.PromocodeReduction > 0">
    <span class="price-list__summary-name">{{ 'service-configuration.promo-code' | translate }}</span>
    <span class="price-list__summary-price">- {{ priceSummary.PromocodeReduction  | currency: undefined: undefined: 0 }}</span>
  </div>
  <div class="price-list__summary discount-desc" *ngIf="priceSummary.ContractReduction > 0">
    <p>{{ 'service-configuration.discount-desc' | translate }}</p>
    <p *ngIf="priceSummary.PromocodeReduction === 0">{{ 'service-configuration.discount-promo' | translate }}</p>
    <p *ngIf="priceSummary.PromocodeReduction > 0">{{ 'service-configuration.discount-promo-with-edit' | translate }}</p>
  </div>
  <div class="price-list__summary annual">
    <span class="price-list__summary-name">{{ 'service-configuration.annual-price' | translate }}</span>
    <span class="price-list__summary-price">{{ priceSummary.NetPrice  | currency: undefined: undefined: 0 }}</span>
  </div>
</div>
