import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SparePartActions } from '../actions';

export enum ReorderStatus {
  DEFAULT = 'default',
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export interface SparePartState {
  items: any[];
  status: ReorderStatus;
}

export const initialState: SparePartState = {
  items: [],
  status: ReorderStatus.DEFAULT
};

export const sparePartStoreName = 'sparePart';

const getSparePartFeatureState = createFeatureSelector<SparePartState>(sparePartStoreName);

export const getReorderState = createSelector(
  getSparePartFeatureState,
  state => state,
);

export const getReorderItems = createSelector(
  getSparePartFeatureState,
  state => state.items,
);

export const isReorderPending = createSelector(
  getSparePartFeatureState,
  state => state.status === ReorderStatus.PENDING,
);

export const isReorderConfirmed = createSelector(
  getSparePartFeatureState,
  state => state.status === ReorderStatus.CONFIRMED,
);

export const SparePartReducer = createReducer(
  initialState,
  on(SparePartActions.addItem, (state, {item}) => {
    return {
      ...state,
      items: [item],
      status: ReorderStatus.PENDING
    }
  }),
  on(SparePartActions.addAllItems, (state, {items}) => {
    return {
      ...state,
      items: items,
      status: ReorderStatus.PENDING
    }
  }),
  on(SparePartActions.updateReorderWithSystemDetails, (state, {updatedSystemDetails}) => {
    return {
      ...state,
      items: state.items.map(item => {
        return {
          ...item,
          data: {
            ...item.data,
            systemDetails: updatedSystemDetails,
            systemDetailsPerItem: [{
              itemId: item.data.sku,
              systemDetails: updatedSystemDetails
            }]
          }
        }
      }),
      status: ReorderStatus.PENDING
    }
  }),
  on(SparePartActions.confirmReorder, (state) => {
    return {
      ...state,
      items: state.items,
      status: ReorderStatus.CONFIRMED
    }
  }),
  on(SparePartActions.completeReorder, (state) => {
    return {
      ...state,
      items: [],
      status: ReorderStatus.COMPLETED
    }
  }),
  on(SparePartActions.cancelReorder, (state) => {
    return {
      ...state,
      items: [],
      status: ReorderStatus.CANCELED
    }
  }),
);
