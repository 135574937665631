import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as ValidationPatterns from '../../configurations/validations';
import { ISelectEvent } from '../../models/common.models';
import { notFoundValue, notInstallBaseRelated } from './IvkSelectionFormConstants';

@Component({
  selector: 'app-ivk-selection-form',
  templateUrl: 'ivk-selection-form.component.html',
  styleUrls: ['ivk-selection-form.component.scss']
})
export class IvkSelectionFormComponent implements OnInit {
  @Input() installBaseLoading;
  @Input() systems = [];
  @Input() businessUnits = [] as Array<{name: string, value: string}>;
  @Input() businessUnitLoading = false;

  @Output() systemIdChanged = new EventEmitter<ISelectEvent>();
  @Output() formChanged = new EventEmitter<UntypedFormGroup>();

  ivkForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  notFoundSelected(value): boolean {
    return value === notFoundValue;
  }

  notInstallBaseRelatedSelected(value): boolean {
    return value === notInstallBaseRelated;
  }

  systemIdFieldLabel(): string {
    return this.notFoundSelected(this.ivkForm.value.system)
      ? 'request-details.enter-system-id'
      : 'request-details.system-id';
  }

  setOrderFormValue(event: ISelectEvent): void {
    this.patchFormValue({
      [event.key]: event.value,
    });
  }

  setCompanyValues(event: ISelectEvent): void {
    const notFound = this.notFoundSelected(event.value);

    this.patchFormValue({
      ['businessUnit']: event.value,
      ['companyBusinessUnit']: notFound ? '' : event.name,
      ['companyBusinessUnitNumber']: notFound ? '' : event.value,
      ['company']: notFound ? '' : event.additional.institutionName,
    });

    this.updateValidators();
  }

  setSystemIdValue(event: ISelectEvent): void {
    this.systemIdChanged.emit(event);

    const notFound = this.notFoundSelected(event.value);
    const notInstallBaseRelated = this.notInstallBaseRelatedSelected(event.value);
    const noInstallBaseSelected = notFound || notInstallBaseRelated;
    const additional = event.additional;

    this.patchFormValue({
      [event.key]: noInstallBaseSelected ? event.value : event,
      ['serialNumber']: noInstallBaseSelected ? '' : additional.serialNumber,
      ['materialNumber']: noInstallBaseSelected ? '' : additional.materialNumber,
      ['siemensEquipmentId']: noInstallBaseSelected ? '' : additional.siemensEquipmentId,
      ['companyBusinessUnit']: noInstallBaseSelected ? '' : additional.companyBusinessUnit,
      ['companyBusinessUnitNumber']: noInstallBaseSelected ? '' : additional.companyBusinessUnitNumber,
      ['namePtBr']: noInstallBaseSelected ? '' : additional.namePtBr,
      ['nameEnUs']: noInstallBaseSelected ? '' : additional.nameEnUs,
      ['company']: noInstallBaseSelected ? '' : additional.company,
      ['businessUnit']: '',
    });

    this.updateValidators();
  }

  private updateValidators(): void {
    const installBaseNotFound = this.notFoundSelected(this.ivkForm.value.system);
    const notInstallBaseRelated = this.notInstallBaseRelatedSelected(this.ivkForm.value.system);
    const institutionNotFound = this.notFoundSelected(this.ivkForm.value.businessUnit);

    this.ivkForm.get('siemensEquipmentId').setValidators(
      notInstallBaseRelated ? [] : [Validators.required, ValidationPatterns.noEmptySpaceOnTheBeginning]
    );
    this.ivkForm.get('businessUnit').setValidators(
      installBaseNotFound || notInstallBaseRelated ? [Validators.required] : []
    );
    this.ivkForm.get('companyBusinessUnit').setValidators(
      institutionNotFound ? [Validators.required] : []
    );

    this.ivkForm.get('siemensEquipmentId').updateValueAndValidity();
    this.ivkForm.get('businessUnit').updateValueAndValidity();
    this.ivkForm.get('companyBusinessUnit').updateValueAndValidity();

    this.emitFormChanged();
  }

  private initializeForm(): void {
    this.ivkForm = this.formBuilder.group({
      system: ['', [Validators.required]],
      company: [''],
      companyBusinessUnit: [''],
      companyBusinessUnitNumber: [''],
      materialNumber: [''],
      businessUnit: [''],
      siemensEquipmentId: [''],
      namePtBr: [''],
      nameEnUs: [''],
      serialNumber: [''],
    });

    this.emitFormChanged();
  }

  private patchFormValue(value: any): void {
    this.ivkForm.patchValue(value);

    this.emitFormChanged();
  }

  private emitFormChanged(): void {
    this.formChanged.emit(this.ivkForm);
  }
}
