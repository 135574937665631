export const shopName = 'shopCart';

export class LocalStorageUtils {
  static getKeyValue(key: string): string {
    const localStorage = window.localStorage;

    if (!localStorage.hasOwnProperty(key)) {
      return '';
    }

    const value = localStorage[key];

    return value ?? '';
  }

  static getKeyValueNew(envKey: string): string {
    const localStorage = window.localStorage;
    const lang = LocalStorageUtils.getKeyValue(`lang`);

    let value = '';

    if (!localStorage.hasOwnProperty(`config-data-${lang}`)) {
      return '';
    }

    const data = JSON.parse(LocalStorageUtils.getKeyValue(`config-data-${lang}`));

    Object.keys(data).forEach(
      key => {
        const neededParentKey =  envKey.split(".")[0];
        if (key === neededParentKey) {
          Object.keys(data[key]).forEach(
            deepKey => {
              const neededChildKey =  envKey.split(".")[1];
              if (deepKey === neededChildKey) {
                value = data[key][deepKey];
              }
            }
          )
        }
      }
    )

    return value;
  }

  static setKeyValue(key: string, value: string): void {
    const localStorage = window.localStorage;
    localStorage.setItem(key, value);
  }

  static clearKey(key: string): void {
    const localStorage = window.localStorage;
    localStorage.removeItem(key);
  }

  static clearLocalStorage(exclude): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (exclude.indexOf(key) === -1) {
        localStorage.removeItem(key);
      }
    }
  }
}
