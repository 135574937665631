<div class="quote-summary">
  <app-contract-steps-bar [stepNo]="3" [isQuoteSummary]="true"></app-contract-steps-bar>
  <div class="quote-summary__content" *ngIf="!loadingPageData">
    <div class="quote-summary__details">
      <h4 class="quote-summary__details--title">{{ 'quote-summary.title' | translate }}</h4>
      <p class="quote-summary__details--subtitle">{{ 'quote-summary.subtitle' | translate }}</p>
      <div class="quote-summary__details--sold-to-address">
        <div class="details__sold-to-address--title">
          <span class="font-weight-bold">{{ 'quote-summary.sold-to' | translate }}</span>
          <app-tooltip
              link="icon"
              text="{{ 'quote-summary.sold-to-tooltip' | translate }}">
          </app-tooltip>
        </div>
        <div class="details__sold-to-address--value">
          <div class="input" [ngClass]="!isSoldToAddressValid ? 'invalid' : ''">
            <span>{{ cpqForm.value['soldToAddress'] }}</span>
            <span *ngIf="!isSoldToAddressValid" class="icon icon-close__circle icon-close"></span>
          </div>
          <div class="details__sold-to-address--wrong-address">
            <span class="icon icon-arrow-right-small"></span>
            <button class="text action" [attr.data-test]="'button-reportWrongAddressSummary'"
                    (click)="showReportWrongSoldToAddressModal()">
              {{ 'quote-summary.report-wrong-address' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="!isSoldToAddressValid" class="details__sold-to-address--invalid">
          <div class="icon">
            <span class="icon-close icon-close__circle"></span>
          </div>
          <div class="text">
            <span>{{ 'quote-summary.sold-to-error'| translate }}</span>
          </div>
        </div>
      </div>
      <div class="quote-summary__details--systems">
        <div class="system-info">
          <div class="col-lg-1 hidden-sm"></div>
          <div class="systems-actions">
            <button
                type="button"
                class="button icon-plus"
                [attr.data-test]="'button-configureAnotherFL'"
                (click)="configureAnotherFunctionalLocation()">
              {{ 'quote-summary.configure-another-fl' | translate }}
            </button>
            <app-tooltip
                link="{{ 'quote-summary.what-is-functional-location' | translate }}"
                text="{{ 'quote-summary.functional-location-tooltip' | translate }}">
            </app-tooltip>
          </div>
        </div>
        <div class="accordion">
          <h5 class="font-weight-bold">{{ 'quote-summary.systems' | translate }}</h5>
          <div class="systems__header accordion__title">
            <span class="systems__title width-80">{{ 'quote-summary.functional-location' | translate }}</span>
            <span class="systems__title--annual-price width-20">
              {{ 'quote-summary.your-annual-price' | translate }}</span>
          </div>
          <div class="systems__table">
            <div
                class="quote-summary__quote-item"
                [appLoadingAnimation]="deleteQuoteItemInProgress && item.attributes.cpqConfigId === quoteItemToRemove.configId"
                *ngFor="let item of cartItemsWithDetails">
              <app-accordion
                  [isActiveOnStart]="true"
                  [openOnIcon]="true"
                  [showIcon]="true"
                  (panelOpened)="toggleTab(item.attributes.systemDetails.siemensEquipmentId)">
                <ng-container ngProjectAs="[slot][header]">
                  <div class="accordion__title">
                    <app-system-preview
                        class="width-100"
                        [item]="item"
                        [cartId]="cartId"
                        [currency]="prices.Currency"
                        (itemDeleted)="itemDeleted($event)">
                    </app-system-preview>
                  </div>
                </ng-container>
                <ng-container ngProjectAs="[slot][panel]">
                  <div class="container accordion__content">
                    <app-system-detail
                        [form]="cpqForm"
                        [item]="item"
                        [cart]="cart"
                        [functionalLocations]="getMultipleFunctionalLocations(cartItemsWithDetails)"
                        [customerBusinessUnitName]="customerBusinessUnitName"
                        [ecommerceTeamEmailAddress]="ecommerceTeamEmailAddress">
                    </app-system-detail>
                  </div>
                </ng-container>
              </app-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-summary__overview">
      <p class="quote-summary__text">{{ 'quote-summary.review-quote' | translate }}</p>
      <button
          type="button"
          class="button button--primary width-100 quote-summary__overview--quote-doc"
          [attr.data-test]="'button-generateQuoteDocument'"
          [disabled]="!isFormValid(cpqForm)"
          (click)="generateQuoteDocument()">
        {{ 'quote-summary.generate-quote' | translate }}
      </button>
      <div class="quote-summary__overview--price">
        <span>{{ 'quote-summary.list-price' | translate }}</span>
        <span>{{ prices.cpqListPriceTotal | currency: currency }}</span>
      </div>
      <div class="quote-summary__overview--discount customer-discount" *ngIf="prices.cpqContractReductionTotal !== 0">
        <span>{{ 'quote-summary.your-discount' | translate }}</span>
        <span class="discount-value">-{{ prices.cpqContractReductionTotal | currency: currency }}</span>
      </div>
      <div class="quote-summary__overview--discount" *ngIf="activePromoCode">
        <div class="promotional-code">
          <span>{{ 'quote-summary.promo-code' | translate }}</span>
          <div class="promotional-code__amount">
            <span class="discount-value">-{{ activePromoCode.amount | currency: currency }}</span>
            <span class="icon-close" (click)="removePromoCode(activePromoCode.code)"></span>
          </div>
        </div>
      </div>
      <div class="quote-summary__overview--discount-code">
        <div class="discount-code__input" [attr.data-test]="'input-promoCode'">
          <app-textfield
              label="{{ 'quote-summary.promo-code' | translate }}"
              [form]="promoCodeForm"
              [modelValue]="promoCodeForm.value.promoCode"
              [inputName]="'promoCode'"
              [readOnly]="!!activePromoCode"
              [isRequired]="false">
          </app-textfield>
          <button
              type='button'
              class="button"
              [attr.data-test]="'button-applyPromoCode'"
              [disabled]="!!activePromoCode"
              (click)="applyPromoCode(promoCodeForm.value.promoCode)">
            {{ 'quote-summary.apply' | translate }}</button>
        </div>
        <div class="validation">
          <div class="success" *ngIf="promoCodeSuccess">
            <span class="validation--icon icon-check__circle icon-check"></span>
            <span class="validation--text">{{ 'quote-summary.code-success' | translate }}</span>
          </div>
          <div class="invalid" *ngIf="promoCodeInvalid">
            <span class="validation--icon icon-close__circle icon-close"></span>
            <span class="validation--text">{{ 'quote-summary.code-invalid' | translate }}</span>
          </div>
          <div class="combined" *ngIf="promoCodesCombined">
            <span class="validation--icon icon-close__circle icon-close"></span>
            <span class="validation--text">{{ 'quote-summary.code-invalid-not-combined' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="quote-summary__overview--annual-price">
        <p>{{ 'quote-summary.your-annual-price' | translate }}</p>
        <p>{{ prices.cpqNetPriceTotal | currency: currency }}</p>
      </div>
      <div class="quote-summary__overview--delete-quote" [attr.data-test]="'button-deleteQuoteUnderPrice'"
           (click)="showRemoveQuoteModal()">
        <span class="action">{{ 'quote-summary.delete-quote' | translate }}</span>
        <span class="icon-close"></span>
      </div>
    </div>
  </div>
  <div
      *ngIf="loadingPageData || showLoaderOverlay || isCartOperationInProgress"
      class="quote-summary__loader"
      [ngClass]="{'fixed': showLoaderOverlay || isCartOperationInProgress}">
    <div class="loading-animation"></div>
  </div>
</div>
<app-report-wrong-address-modal
    [addressesList]="soldToAddress"
    [cpqOnly]="true"
    [isQuoteSummaryComponent]="true"
    [cpqAddressType]="addressType"
    [showModal]="reportWrongSoldToAddressModalFlag"
    [functionalLocations]="getMultipleFunctionalLocations(cartItemsWithDetails)"
    [quoteId]="cart?.id || ''"
    [cpqLabelName]="'quote-summary.sold-to-address' | translate"
    (closeModal)="reportWrongSoldToAddressModalFlag = false">
</app-report-wrong-address-modal>
<app-modal
    *ngIf="generateQuoteDocumentModalFlag"
    title="{{ 'quote-summary.select-addresses-title' | translate }}"
    [open]="generateQuoteDocumentModalFlag"
    (crossClick)="backToQuoteSummary()"
    (outsideClick)="backToQuoteSummary()">
  <ng-container ngProjectAs="[slot][body]">
    <app-cpq-bill-and-payer-address
        [cart]="cart"
        [cartItemsWithDetails]="cartItemsWithDetails"
        [customerBusinessUnitName]="customerBusinessUnitName"
        [ecommerceTeamEmailAddress]="ecommerceTeamEmailAddress"
        [payerAddresses]="payerAddresses"
        [billToAddresses]="billToAddresses"
        [functionalLocations]="getMultipleFunctionalLocations(cartItemsWithDetails)"
        (showAlmostDone)="navigateToAlmostDone()"
        (closeReportWrongAddressModal)="backToQuoteSummary()">
    </app-cpq-bill-and-payer-address>
  </ng-container>
</app-modal>

<app-modal
    *ngIf="removeQuoteModalFlag"
    title="{{ 'quote-summary.delete-quote-title' | translate }}"
    [type]="iconType.WARNING"
    [open]="removeQuoteModalFlag"
    (crossClick)="backToQuoteSummary()"
    (outsideClick)="backToQuoteSummary()">
  <ng-container ngProjectAs="[slot][body]">
    <p class="cancel-order-modal-title">{{ 'quote-summary.delete-quote-warning' | translate }}</p>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button
        type="button"
        class="button"
        data-dismiss="modal-dialog"
        (click)="backToQuoteSummary()">
      {{ 'quote-summary.delete-quote-cancel' | translate }}
    </button>
    <button
        type="button"
        class="button button--primary"
        data-dismiss="modal-dialog"
        (click)="removeQuote()">
      {{ 'quote-summary.delete-quote-delete' | translate }}
    </button>
  </ng-container>
</app-modal>

<app-modal
    *ngIf="showRemoveConfigurationModal"
    title="{{ 'quote-summary.delete-quote-item-title' | translate }}"
    [type]="iconType.WARNING"
    [open]="showRemoveConfigurationModal"
    (crossClick)="showRemoveConfigurationModal = false"
    (outsideClick)="showRemoveConfigurationModal = false">
  <ng-container ngProjectAs="[slot][body]">
    <p class="cancel-order-modal-title">{{ 'quote-summary.delete-quote-item-warning' | translate }}</p>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button
        type="button"
        class="button button"
        data-dismiss="modal-dialog"
        (click)="showRemoveConfigurationModal = false">
      {{ 'quote-summary.delete-quote-item-cancel' | translate }}
    </button>
    <button
        type="button"
        class="button button--primary"
        data-dismiss="modal-dialog"
        (click)="removeQuoteItem()">
      {{ 'quote-summary.delete-quote-item-remove' | translate }}
    </button>
  </ng-container>
</app-modal>
