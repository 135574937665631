import { Injectable, Injector } from '@angular/core';
import { FeaturedProductsService } from '../services/featured-products.service';
import { Observable } from 'rxjs';
import { IFeatureProducts } from '../models/featured-products.models';

@Injectable({
  providedIn: 'root',
})
export class FeaturedProductsFacade {
  private pFeaturedProductsService: FeaturedProductsService;

  public getFeaturedProductsService(): FeaturedProductsService {
    if (!this.pFeaturedProductsService) {
      this.pFeaturedProductsService = this.injector.get(FeaturedProductsService);
    }
    return this.pFeaturedProductsService;
  }

  constructor(private injector: Injector) {
  }

  getFeaturedProducts(featureProductsKey: string): Observable<IFeatureProducts> {
    return this.getFeaturedProductsService().getFeaturedProducts(featureProductsKey);
  }
}
