import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlueConfigurationService } from './glue-configuration.service';
import { Router } from '@angular/router';
import { INavigationResponse } from '../models/settings.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OpalService {
  glueUrl = this.glueConfiguration.getEndpointUrl();
  localStorage = window.localStorage;

  constructor(private http: HttpClient,
              private glueConfiguration: GlueConfigurationService,
              private httpClient: HttpClient,
  ) {
  }

  private static handleOpalError(error: any): Promise<any> {
    return Promise.reject(error || error);
  }

  getToken(): string {
    let token;
    for (const [key, value] of Object.entries(localStorage)) {
      if (key.indexOf('@@auth0spajs@@') !== -1) {
        const authStorage = JSON.parse(value);
        token = authStorage.body.access_token;
      }
    }
    return token;
  }

  getOpal(fl: string): Observable<any> {
    const token = this.getToken();
    const navigationBarUrl = this.glueUrl + `/opal-systems-search/${fl}`;
    return this.httpClient.get<any>(navigationBarUrl, {headers: {Authorization: `Bearer ${token}`}})
     .pipe(catchError(OpalService.handleOpalError));
  }

}
