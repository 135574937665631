<div class="row contact-us">
  <div class="col-12 col-lg-12 contact-us__header">
    <h1 class="contact-us__header--title">{{ 'contact-us.page-title' | translate }}</h1>
    <div class="contact-us__fl-available">
      <p>{{ 'contact-us.get-recommendation' | translate }}</p>
      <p>{{ 'contact-us.contact-you' | translate }}</p>
      <div class="contact-us__fl-available-fl-item">
        <app-contract-steps-bar *ngIf="contractParams['fl-number']"></app-contract-steps-bar>
      </div>
      <div class="row container-row">
        <div class="col-12" [formGroup]="contactUsForm">
          <input
              type="checkbox"
              id="multiple-systems"
              name="multiple-systems"
              class="checkbox"
              [required]="true"
              formControlName="multipleSystems">
          <label for="multiple-systems">
            {{ 'contact-us.multiple-systems' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-12">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <div class="row">
          <div class="col-12">
            <span>{{ 'contact-us.interested-in' | translate }}</span>
            <app-dropdown
                [dropDownName]="'select-contract-types'"
                [cssStyles]="{dropdown: 'user-identifier', toggle: 'user-identifier__toggle-button'}"
                [isCheckbox]="true"
                [hideOnMenuClick]="false"
                [keepActive]="false"
                (filters)="contractTypesSelected($event)"
                checkboxName="{{ 'contact-us.contract-type' | translate }}"
                [checkboxOptions]="abstractProductOptions">
              <ng-container ngProjectAs="[slot][toggle]">
                <div class="dropdown">
                  <h6 class="dropdown--name">{{ 'contact-us.select-contract-type' | translate }}</h6>
                  <h6 class="icon-arrow-down"></h6>
                </div>
              </ng-container>
            </app-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>{{ 'contact-us.additional-information' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-textarea
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.message"
                [rows]="3"
                [cssStyles]="{wrapper: 'text-area-message'}"
                [inputName]="'message'"
                [maxLength]="maxLengthMessage"
                label="{{ 'contact-us.message-input' | translate }}"
                trim
                trimWhitespaces
                (areaFilled)="setContactUsFormValue($event)">
            </app-textarea>
            <div class="contact-us-form__alerts">
              <ng-container *ngIf="contactUsForm.value.message">
                <small class="textfield__hint">
                  {{ contactUsForm.value.message.length }}/{{ maxLengthMessage }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.firstName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-firstname'}"
                [inputName]="'firstName'"
                label="{{ 'contact-us.select-first-name' | translate }}"
                [isRequired]="true"
                [readOnly]="true"
                [ngClass]="{'is-invalid': contactUsForm.get('firstName').errors && contactUsForm.get('firstName').touched}"
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
          </div>
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.lastName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-lastname'}"
                [inputName]="'lastName'"
                label="{{ 'contact-us.select-last-name' | translate }}"
                [isRequired]="true"
                [readOnly]="true"
                [ngClass]="{'is-invalid': contactUsForm.get('lastName').errors && contactUsForm.get('lastName').touched}"
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
          </div>
        </div>
        <div class="row">
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                [inputType]="'email'"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.email"
                [cssStyles]="{wrapper: 'email-with-prefix email-telephone'}"
                [inputName]="'email'"
                label="{{ 'contact-us.input-email' | translate }}"
                [isRequired]="true"
                [readOnly]="true"
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
          </div>
          <div class="contact-us-form__field col-md-6">
            <app-textfield
                class="contact-us-form__textfield col-12 col-md-6"
                [form]="contactUsForm"
                [modelValue]="contactUsForm.value.phone"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-telephone'}"
                [inputName]="'phone'"
                label="{{ 'contact-us.select-phone-number' | translate }}"
                [isRequired]="false"
                trim
                [multiCharToTrim]="['-']"
                trimWhitespaces
                (fieldFilled)="setContactUsFormValue($event)">
            </app-textfield>
            <div class="contact-us-form__alerts">
              <ng-container
                  *ngIf="contactUsForm.get('phone').touched &&
                                      contactUsForm.get('phone').hasError('maxlength')">
                <small class="textfield__hint">
                  {{ 'contact-us.max-characters' | translate: {charNumber: maxLengthPhone} }}
                </small>
              </ng-container>
              <ng-container
                  *ngIf="contactUsForm.get('phone').hasError('pattern')">
                <small class="textfield__hint">
                  {{ 'contact-us.only-numbers' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12" [formGroup]="contactUsForm">
            <input
                type="checkbox"
                id="receive-mails"
                name="receive-mails"
                class="checkbox"
                [required]="false"
                (change)="checkboxReceiveEmails = !checkboxReceiveEmails"
                formControlName="receiveMails">
            <label for="receive-mails">
              {{ 'contact-us.checkbox-receive-mails' | translate }}
            </label>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12" [formGroup]="contactUsForm">
            <input
                type="checkbox"
                id="personal-information-consent"
                name="personal-information-consent"
                class="checkbox"
                [required]="false"
                (change)="checkboxPersonalData = !checkboxPersonalData"
                formControlName="personalInformationConsent">
            <label for="personal-information-consent">
              {{ 'contact-us.checkbox-personal-info-consent' | translate }}
              <span class="contact-email">
                <a href="mailto:{{ 'contact-us.checkbox-personal-info-consent-email' | translate }}">
                  {{ 'contact-us.checkbox-personal-info-consent-email' | translate }}
                </a>
              </span>
              &nbsp;*
            </label>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12">
            <span class="label">{{ 'contact-us.required' | translate }}</span>
          </div>
        </div>
        <div class="row container-row">
          <div class="col-12 form-buttons">
            <button type="button"
                    class="button"
                    data-dismiss="modal-dialog"
                    (click)="cancelOnClick()">
              {{ 'contact-us.button-cancel' | translate }}
            </button>
            <button type="button"
                    class="button button--primary"
                    data-dismiss="modal-dialog"
                    [disabled]="!checkboxPersonalData || !checkboxReceiveEmails"
                    (click)="submitForm()">
              {{ 'contact-us.button-submit' | translate }}
            </button>
          </div>
        </div>
        <app-contact-us-submit-modal
          [showModalSubmit]="showModalSubmit"
          [cartItemsLength]="cartItemsLength"
          (closeModal)="showModalSubmit = false">
        </app-contact-us-submit-modal>
      </div>
    </div>
  </div>
</div>
