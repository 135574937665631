import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { IOrder, IOrderItem, IOrderResponse, IOrderResponseWithMessages } from '../../models/order.models';
import * as OrderApproveActions from '../../actions/order-approve.actions';
import { OrdersFacade } from '../../facades/orders.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils } from '../../utils/array.utils';
import { MathUtils } from '../../utils/math.utils';
import { EOrderStatus } from '../../configurations/order-status';
import { Subscription } from 'rxjs';
import { CustomerFacade } from '../../facades/customer.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EStoreTypes, EUserRoles, HospitalOrdersTabs } from '../../configurations/common';
import { UiUtils } from '../../utils/ui.utils';
import { AppUtils } from '../../utils/app.utils';
import { IStore } from '../../models/settings.model';
import { TaxUtils} from '../../utils/tax.utils';

@Component({
  selector: 'app-order-tracking-history',
  templateUrl: './order-tracking-history.component.html',
  styleUrls: ['./order-tracking-history.component.scss'],
})
export class OrderTrackingHistoryComponent implements OnInit, OnDestroy {
  orderDetails: IOrder;
  totalQuantity: number = 0;
  estimatedArrivalDate: string;
  isLoaded: boolean;
  companyRoles: EUserRoles[];
  companyRolesSubscription$: Subscription;
  statusCompletedFinal: boolean = false;
  showModalCancelOrder: boolean;
  orderId: string;
  actualStore: IStore;
  taxUtils = TaxUtils;

  declineStatus: string = 'declined';
  canceledStatus: string = 'canceled';

  constructor(private store: Store<State>,
              private ordersFacade: OrdersFacade,
              private route: ActivatedRoute,
              private router: Router,
              private customerFacade: CustomerFacade,
              private analyticsService: AnalyticsService,
              ) {}

  ngOnInit(): void {
    this.companyRolesSubscription$ = this.selectCompanyRole();
    this.retrieveOrderDetails(this.route.snapshot.paramMap.get('orderId'));
    this.actualStore = AppUtils.getCurrentStore();
  }

  ngOnDestroy(): void {
    this.companyRolesSubscription$.unsubscribe();
  }

  retrieveOrderDetails(id: string): void {
    this.isLoaded = false;
    this.ordersFacade.getOrderById(id).subscribe({
          next: (data: IOrderResponse|IOrderResponseWithMessages) => {
            if (data) {
              this.orderDetails = data.data as IOrder;
              const date: number = Date.parse(this.orderDetails.attributes.createdAt);
              const resultDate: Date = new Date(date);
              resultDate.setDate(resultDate.getDate() + 2);
              this.estimatedArrivalDate = resultDate.toString();
              const items: IOrderItem[] = Array.from(Object.create(this.orderDetails.attributes.items)) as IOrderItem[];
              this.orderDetails.attributes.items = this.combineSameItems(items);
              this.analyticsService.setProducts(this.orderDetails.attributes.items);
              this.analyticsService.setCartId(this.orderDetails.id);
              this.analyticsService.trackOrderApproved('Order Details', PageTypes.ORDER_DETAILS);

              this.totalQuantity = items.reduce((prev: number, curr: IOrderItem) => {
                return prev + curr.quantity;
              }, 0);
              this.orderId = this.orderDetails.id;
            }
            this.store.dispatch(OrderApproveActions.getOrderDetailsSuccess({orderDetails: this.orderDetails}));
          },
          complete: (): void => {
            this.isLoaded = true;
          }
        }
    );
  }

  combineSameItems(items: IOrderItem[]): IOrderItem[] {
    return ArrayUtils.uniqueObjects(items, 'sku');
  }

  getVoucherDiscount(): number {
    return MathUtils.calculateDiscount(this.orderDetails.attributes.totals.discountTotal, this.orderDetails.attributes.totals.subtotal);
  }

  backToOrders(): void {
    this.router.navigate(
      ['/my-orders'],
      {queryParams: {tab: HospitalOrdersTabs.PREVIOUS_ORDERS}}
    );
  }

  getStatus(status: string): string {
    switch (status) {
      case null:
      case '':
        return '';
      case EOrderStatus.DECLINED:
        return EOrderStatus.DECLINED;
      case EOrderStatus.CANCELED:
        return EOrderStatus.CANCELED;
      case EOrderStatus.COMPLETED:
        this.statusCompletedFinal = true;
        return 'completed-final';
      default:
        this.statusCompletedFinal = false;
        const values = Object.keys(EOrderStatus)
            .map(key => EOrderStatus[key])
            .filter(k => !(parseInt(k, 10) >= 0));
        return values[values.findIndex(i => i === status)].replace(' ', '_');
    }
  }

  orderIcon(): string {
    const status: string = this.orderDetails.attributes?.stateDisplayName;
    return UiUtils.confirmOrCancelIcon(status, this.declineStatus, this.canceledStatus);
  }

  selectCompanyRole(): Subscription {
    return this.customerFacade.getCustomerCompanyRoles().subscribe((companyRoles: EUserRoles[]) => {
      this.companyRoles = companyRoles;
    });
  }

  isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver) || this.companyRoles.includes(EUserRoles.Admin);
  }

  isViewer(): boolean {
    return this.companyRoles.includes(EUserRoles.Viewer);
  }

  cancelOrderModal(): void {
    if (this.orderDetails.attributes.stateDisplayName === 'new'){
      this.showModalCancelOrder = true;
    }
  }

  cancelOrder(orderId: string): void {
    const data = {
      data: {
        type: 'orders-cancel',
        attributes: {
          orderReference: orderId
        }
      }
    };
    this.ordersFacade.postCancelOrder(orderId, data).subscribe( (): void => {
      this.showModalCancelOrder = false;
    });
  }

  restartShowModal(): void {
    this.showModalCancelOrder = false;
  }

  showStatusBarEstimatedArrival(): boolean {
    return this.orderDetails.attributes.stateDisplayName !== 'declined'
      && this.orderDetails.attributes.stateDisplayName !== 'canceled'
      && !this.statusCompletedFinal && !this.isAuStore()
      && (!this.isJpStore() || this.showStatusForJpStore());
  }

  isAuStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.AU;
  }

  isJpStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.JP;
  }

  isInStore(): boolean {
    return this.actualStore.storeId === EStoreTypes.IN;
  }

  showStatusForJpStore(): boolean {
    return this.isJpStore() && this.getStatus(this.orderDetails.attributes.stateDisplayName) !== EOrderStatus.INVOICE_SENT.replace(' ', '_');
  }
}
