import { Directive, ElementRef, HostListener, Inject, Input, Optional, Renderer2, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[trim]',
})
export class TrimDirective implements ControlValueAccessor {

  @Input() trim: string;
  @Input() trimWhitespaces: string;
  @Input() multiCharToTrim: string[];

  private value: string;

  private whiteSpaceRegEx = new RegExp(/[\s]{2,}/g);

  private sourceRenderer: Renderer2;
  private sourceElementRef: ElementRef;

  @HostListener('blur', ['$event.type', '$event.target.value'])
  onBlur(event: string, value: string): void {
    this.updateValue(event, value.trim());
    this.onTouched();
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(
    @Inject(Renderer2) renderer: Renderer2,
    @Inject(ElementRef) elementRef: ElementRef,
    @Optional() @Self() private ngControl: NgControl
  ) {
    this.sourceRenderer = renderer;
    this.sourceElementRef = elementRef;
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }

  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  public writeValue(value: string): void {
    this.value = value === '' ? '' : this.replaceChars(value) || null;
    this.sourceRenderer.setProperty(this.sourceElementRef.nativeElement, 'value', this.value);
    this.ngControl.control.patchValue(value);
  }

  private updateValue(event: string, value: string): void {
    if (this.trim !== undefined ) {
      value = this.trim !== '' && event !== this.trim ? value : value.trim();
      const previous = this.value;
      this.writeValue(value);
      if ((this.value || previous) && this.value.trim() !== previous) {
        this.onChange(this.value);
      }
    }
  }


  private replaceChars(value: string): string {
    if (this.trimWhitespaces === '') {
      value = value.replace(this.whiteSpaceRegEx, ' ');
    }
    if (this.multiCharToTrim) {
      this.multiCharToTrim.forEach(char => {
        const charRegexp = new RegExp(`[${char}]{2,}`, 'g');
        value = value.replace(charRegexp, char);
      });

    }
    return value;
  }

}
