import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../../services';
import { AppUtils } from '../../utils/app.utils';

@Pipe({name: 'currency'})
export class CustomerCurrencyPipe implements PipeTransform {

  constructor(private i18nService: I18nService) {
  }

  transform(value: number | string | null | undefined,
            currencyCode?: string,
            display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol-narrow',
            decimalDigit: number = -0.1,
            digitsInfo?: string,
            locale?: string,
  ): string | null {
    const newValue = (value as number) / (
      decimalDigit !== -0.1 ? Math.pow(10, decimalDigit) : AppUtils.getCurrentStore().defaultPriceDivisorValue
    );
    const formattedCurrency = new CurrencyPipe(this.i18nService.getCurrentLocale(), currencyCode).transform(
      newValue, currencyCode, display, digitsInfo, locale);

    if (display && display === 'code') {
      const firstDigit = formattedCurrency.search(/\d/);

      return formattedCurrency.substring(0, firstDigit) + ' ' + formattedCurrency.substr(firstDigit);
    }

    return formattedCurrency;
  }
}
