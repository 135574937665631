<div
    class="datepicker textfield width-100" [attr.data-test]="'desc-datepicker'"
    [ngClass]="{'is-active':isActive, 'is-invalid': isInvalid,'read-only': readOnly, 'has-value': modelValue !== '', 'disabled': disabled, 'icon-calendar': !isActive}">
  <ng2-flatpickr [config]="datepickerOptions"></ng2-flatpickr>
  <span *ngIf="modelValue" class="reset-button icon-close" (click)="resetDatepicker()">
  </span>
  <label *ngIf="!!label" class="textfield__label" [for]="'textfield'+inputName">
    {{ getLabel() }}
  </label>
  <div *ngIf="form" [formGroup]="form">
    <input formControlName="{{ inputName }}" [value]="modelValue" [name]="inputName" hidden [required]="isRequired">
  </div>
  <div class="disable-overlay" *ngIf="disabled"></div>
</div>
