import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../auth/reducers';
import { AuthActions } from '../auth/actions';
import { AuthService } from '../services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationFacade {

  isUserLoggedIn$ =  this.store.pipe(select(fromAuth.selectIsAuthenticated));
  loggedUser$ = this.store.pipe(select(fromAuth.selectUser));
  selectAccessToken$ = this.store.pipe(select(fromAuth.selectAccessToken));

  constructor(private store: Store<fromAuth.State>,
              private authService: AuthService) {
  }

  isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  authorization(): void{
    this.store.dispatch(AuthActions.logIn({url: '', queryParameters: {}}));
  }

  logout(skipRedirect: boolean = false): void{
    this.store.dispatch(AuthActions.logOut({skipRedirectToStore: skipRedirect}));
  }

  getAuthenticatedCookie(): boolean {
    return this.authService.getAuthenticatedCookie();
  }

  selectIsUserLoggedIn(): Observable<boolean> {
    return this.isUserLoggedIn$;
  }
}
