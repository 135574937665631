import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {

  active: boolean;
  @Input() disabled = false;
  @Input() link: string;
  @Input() useBothTogglers = false;
  @Input() text: string;
  @Input() linkCss: string;

  constructor(private tooltip: ElementRef) {}

  toggleTooltip(): void {
    if (this.disabled) {
      return;
    }
    this.active = !this.active;
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (!this.tooltip.nativeElement.contains(event.target)) {
      this.active = false;
    }
  }
}

