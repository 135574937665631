import { createAction, props } from '@ngrx/store';
import { INavigationNodes } from '../models/settings.model';
import { IContentBanner } from '../models/cms';
export const LOAD_SHOP_CATEGORY_NAVIGATION_TILES = '[Homepage data] Load homepage shop category navigation tiles';
export const LOAD_SHOP_CATEGORY_NAVIGATION_TILES_SUCCESS = '[Homepage data] Load homepage shop category navigation tiles SUCCESS';
export const LOAD_SHOP_CATEGORY_NAVIGATION_TILES_FAIL = '[Homepage data] Load homepage shop category navigation tiles FAILURE';
export const LOAD_SHOP_CONTENT_BANNER = '[Homepage data] Load content banner';
export const LOAD_SHOP_CONTENT_BANNER_SUCCESS = '[Homepage data] Load content banner SUCCESS';
export const LOAD_SHOP_CONTENT_BANNER_FAIL = '[Homepage data] Load content banner FAILURE';

export const loadShopCategoryNavigation = createAction(
  LOAD_SHOP_CATEGORY_NAVIGATION_TILES,
);

export const loadShopCategoryNavigationSuccess = createAction(
  LOAD_SHOP_CATEGORY_NAVIGATION_TILES_SUCCESS,
  props<{ navigationResponseNodes: INavigationNodes[] }>()
);

export const loadShopCategoryNavigationFail = createAction(
  LOAD_SHOP_CATEGORY_NAVIGATION_TILES_FAIL,
  props<{ resError: string }>()
);

export const loadShopContentBanner = createAction(
  LOAD_SHOP_CONTENT_BANNER,
  props<{bannerKey: string}>()
);

export const loadShopContentBannerSuccess = createAction(
  LOAD_SHOP_CONTENT_BANNER_SUCCESS,
  props<{ contentBannerResponse: IContentBanner }>()
);

export const loadShopContentBannerFail = createAction(
  LOAD_SHOP_CONTENT_BANNER_FAIL,
  props<{ resError: string }>()
);
