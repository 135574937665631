import { IBaseWishlist } from '../models/wishlist.models';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { WishlistActions } from '../actions';
import { PayloadUtils } from '../utils/payload.utils';

export const wishlistStoreName = 'wishlist';

export interface WishlistState {
  wishlists: IBaseWishlist[];
  loaded: boolean;
  error: any;
  newWishlistCreated: boolean;
  listInProgress: Array<{id: string, sku: string}>;
  listInEdit: Array<string>;
  lastUpdatedProduct: any;
  addNewInProgress: boolean;
}

export const initialState: WishlistState = {
  loaded: false,
  wishlists: [],
  error: null,
  newWishlistCreated: false,
  listInProgress: [],
  listInEdit: [],
  lastUpdatedProduct: undefined,
  addNewInProgress: false
};

const getWishlistFeatureState = createFeatureSelector<WishlistState>(wishlistStoreName);

export const getLoaded = createSelector(
  getWishlistFeatureState,
  state => state.loaded,
);

export const getWishlists = createSelector(
  getWishlistFeatureState,
  state => state.wishlists,
);

export const isNewWishlistCreated = createSelector(
  getWishlistFeatureState,
  state => state.newWishlistCreated,
);

export const getListInProgress = createSelector(
  getWishlistFeatureState,
  state => state.listInProgress,
);

export const getWishlistsItems = (id: string) => createSelector(
  getWishlistFeatureState,
  state => state.wishlists.find(value => value.id === id).items,
);

export const getLastUpdatedProduct = createSelector(
  getWishlistFeatureState,
  state => state.lastUpdatedProduct,
);

export const selectAddNewInProgress = createSelector(
  getWishlistFeatureState,
  state => state.addNewInProgress,
);

export const WishlistReducer = createReducer(
  initialState,

  on(WishlistActions.retrieveWishlistsSuccess, (state, action): WishlistState => {
    const wishlists = [];
    action.wishlists.data.forEach(wishlist => {
      const items = wishlist.relationships ? wishlist.relationships['shopping-list-items'].data : null;
      const itemsList = [];
      items?.forEach(item => {
        itemsList.push(PayloadUtils.getItemDetailsForWishlist(action.wishlists?.included, item.id));
      });
      wishlists.push(Object.assign({
        ...wishlist,
        items: itemsList,
      }));
    });
    return {
      ...state,
      wishlists,
      loaded: true,
    };
  }),

  on(WishlistActions.retrieveWishlistsFail, (state, action): WishlistState => {
    return {
      ...state,
      error: action.error,
      loaded: false,
    };
  }),

  on(WishlistActions.deleteWishlistSuccess, (state, action): WishlistState => {
    return {
      ...state,
      wishlists: state.wishlists.filter(item => item.id !== action.wishlistID),
    };
  }),

  on(WishlistActions.deleteWishlistFailed, (state, action): WishlistState => {
    return {
      ...state,
      error: action.error,
    };
  }),

  on(WishlistActions.addNewWishlistSuccess, (state, action): WishlistState => {
    return {
      ...state,
      wishlists: [action.newWishlist, ...state.wishlists],
      newWishlistCreated: true,
      addNewInProgress: false,
    };
  }),

  on(WishlistActions.addNewWishlist, (state) => {
    return {
      ...state,
      newWishlistCreated: false,
      addNewInProgress: true,
    };
  }),
  on(WishlistActions.addNewWishlistFailed, (state, action) => {
    return {
      ...state,
      newWishlistCreated: false,
      error: action.error,
      addNewInProgress: false,
    };
  }),

  on(WishlistActions.addNewProductToWishlist, (state, action): WishlistState => {
    return addListToInProgress(state, action);
  }),

  on(WishlistActions.addNewProductToWishlistSuccess, (state, action): WishlistState => {
    const wishlists = state.wishlists.map(list => {
      if (list.id === action.wishlistID) {
        const currentItems = list?.items || [];
        const data = action.wishlistResponse.data;
        const items = [...currentItems, {
          sku: data.attributes.sku,
          abstractSku: undefined,
          shoppingListItemId: data.id,
          qty: data.attributes.quantity,
        }];
        return {
          ...list,
          items,
        };
      }
      return list;
    });
    const inProgress = state.listInProgress.filter(list =>
      list.id !== action.wishlistID && list.sku !== action.wishlistResponse.data.attributes.sku);
    return {
      ...state,
      wishlists,
      listInProgress: inProgress,
    };
  }),

  on(WishlistActions.addNewProductToWishlistFailed, (state, action): WishlistState => {
    return modifyProductOnWishlistFailed(state, action);
  }),

  on(WishlistActions.removeProductFromWishlist, (state, action): WishlistState => {
    return addListToInProgress(state, action);
  }),

  on(WishlistActions.removeProductFromWishlistSuccess, (state, action): WishlistState => {
    const wishlists = state.wishlists.map(list => {
      if (list.id === action.wishlistId) {
        const items = list.items.filter(item => item.shoppingListItemId !== action.shoppingListItemId);
        return {
          ...list,
          items,
        };
      }
      return list;
    });
    const inProgress = state.listInProgress.filter(list =>
      list.id !== action.wishlistId && list.sku !== action.sku);
    return {
      ...state,
      wishlists,
      listInProgress: inProgress,
    };
  }),

  on(WishlistActions.removeProductFromWishlistFailed, (state, action): WishlistState => {
    return modifyProductOnWishlistFailed(state, action);
  }),

  on(WishlistActions.updateProductFromWishlist, (state, action): WishlistState => {
    return addListToInProgress(state, action);
  }),

  on(WishlistActions.updateProductFromWishlistSuccess, (state, action): WishlistState => {
    const inProgress = state.listInProgress.filter(list =>
      list.id !== action.wishlistId && list.sku !== action.sku);
    return {
      ...state,
      lastUpdatedProduct: action,
      listInProgress: inProgress
    };
  }),

  on(WishlistActions.updateProductFromWishlistFailed, (state, action): WishlistState => {
    return modifyProductOnWishlistFailed(state, action);
  }),
);

function modifyProductOnWishlistFailed(state, action): WishlistState {
  const inProgress = state.listInProgress.filter(list =>
    list.id !== action.wishlistId && list.sku !== action.sku);
  return {
    ...state,
    listInProgress: inProgress,
    error: action.error,
  };
}

function addListToInProgress(state, action): WishlistState {
  const isItemCurrentlyAdded = state.listInProgress.find(list => list.id === action.wishlistId && list.sku === action.sku);
  if (isItemCurrentlyAdded) {
    return {
      ...state,
    };
  }
  return {
    ...state,
    listInProgress: [...state.listInProgress, {id: action.wishlistId, sku: action.sku}],
  };
}
