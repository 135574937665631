import { Injectable } from '@angular/core';
import {  CanActivate, Router } from '@angular/router';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUtils } from '../utils/app.utils';

@Injectable({
  providedIn: 'root'
})
export class StoreAccessibilityGuard implements CanActivate {

  isStoreEnabled: boolean = true;

  constructor(
    private router: Router,
    private configFacade: ConfigurationFacade
  ) {
  }

  canActivate(): Observable<boolean> {
    if (this.isStoreEnabled) {
      return this.configFacade.getTranslationByKey(
        `stores.${AppUtils.getCurrentMarket().toUpperCase()}.enabled`
      ).pipe(map(isStoreAccessible => {
        this.isStoreEnabled = isStoreAccessible === 'true';
        if(!this.isStoreEnabled) {
          this.router.navigate(['']).then();
        }
        return this.isStoreEnabled;
      }));
    }
  }
}
