import { TermConstants } from '../../../shared/terms/TermConstants';
import { EGlueResource, EUserRoles } from '../../../configurations/common';
import { IAddressExtended } from '../../../models/customer.models';
import { ICheckout } from '../../../models/checkout.models';
import { IAddress } from '../../../models/common.models';
import {
  ISoldToAddressesResponse,
  ISoldToAddressResource, SoldToIncludedResource,
} from '../../../models/soldTo-selection.models';

// Static data used in component tests
// TODO: Refactor into data generators/something more generally usable in tests

// common cart/checkout fields:

const array = new Uint8Array(1);

export const Customer = {
  idCustomer: self.crypto.getRandomValues(array),
  customerReference: 'X00411EE',
  idCompanyUser: '1540b925-e7c7-5e3f-aa9a-d305408732b1',
  uuidCompanyUser: null,
  customerCommonAttrs: {
    email: 'thiago.doi@siemens-healthineers.com',
    salutation: 'Mr/Mrs',
    firstName: 'Thiago',
    lastName: 'Doi',
  },
  customerIncludedAttrs: {
    gender: 'N/A',
    dateOfBirth: null,
    createdAt: null,
    updatedAt: null,
  },
  consentedTerms: [
    TermConstants.termKeys.TERMS_OF_USE.termsKey,
    TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey,
  ],
};

export const CompanyBusinessUnit = {
  idCompanyBusinessUnit: 'fd0b2179-69a6-5ebe-a0fd-572dbdd2fe4c',
  uuid: 'fd0b2179-69a6-5ebe-a0fd-572dbdd2fe4c',
  name: 'DIAGNOSTICOS DA AMERICA S.A .',
  key: 'BR_40284611',
  attributes: {
    defaultBillingAddress: null,
    name: 'DIAGNOSTICOS DA AMERICA S.A .',
    email: '',
    phone: '',
    externalUrl: '',
    bic: '',
    iban: '61486650029599',
  },
};

export const SystemDetails = {
  serialNumber: '',
  siemensEquipmentId: '006-US00062100',
  materialNumber: '',
  namePtBr: '',
  nameEnUs: 'Test Name',
  company: 'GRUPO ALLIAR',
  companyBusinessUnit: 'GRUPO ALLIAR',
  companyBusinessUnitNumber: 'GRP_ALLIAR',
};
export const CustomerDerived = {
  // customer data as they appear when present in a dedicated top-level "customer" key in a BE response
  customerEmbedded: {
    idCustomer: Customer.idCustomer,
    customerReference: Customer.customerReference,
    uuidCompanyUser: null,
    ...Customer.customerCommonAttrs,
  },
  // customer data as they appear when present in the "include" array in a BE response
  customerIncluded: {
    customerReference: Customer.customerReference,
    ...Customer.customerCommonAttrs,
    ...Customer.customerIncludedAttrs,
    phone: null,
    consentedTerms: [...Customer.consentedTerms],
    companyBusinessUnit: {
      uuid: CompanyBusinessUnit.uuid,
      name: CompanyBusinessUnit.name,
      key: CompanyBusinessUnit.key,
    },
  },
};

export const Address = {
  idCompanyBusinessUnitAddress: '363ac88f-6cdf-5ba9-a61a-996e86dda4ad',
  addressBaseAttributes: {
    address1: 'R. BOCAIUVA 2013, PARTE',
    address2: '',
    address3: '',
    zipCode: '88015-530',
    city: 'FLORIANOPOLIS',
    phone: '',
    iso2Code: 'BR',
  },
};

export const AddressDerived = {
  shippingAddress: {
    ...Address.addressBaseAttributes,
    id: null,
    salutation: 'Ms',
    firstName: 'empty',
    lastName: 'empty',
    country: 'empty',
    company: 'empty',
    isDefaultBilling: false,
    isDefaultShipping: false,
    idCustomerAddress: null,
    idCompanyUnitAddress: Address.idCompanyBusinessUnitAddress,
  },
  billingAddress: {
    ...Address.addressBaseAttributes,
    id: null,
    salutation: 'Ms',
    firstName: 'empty',
    lastName: 'empty',
    country: 'empty',
    company: 'empty',
    isDefaultBilling: false,
    isDefaultShipping: false,
    idCustomerAddress: null,
    idCompanyUnitAddress: Address.idCompanyBusinessUnitAddress,
  },
};

export const Checkout = {
  approverDetails: {
    uuidQuoteApproval: null,
    uuidQuote: null,
    status: null,
    approverId: null,
    firstName: null,
    lastName: null,
    comment: null,
    dueDate: null,
    companyUserId: null,
  },
  pointOfContact: {
    department: 'Accounting / Finance',
    firstName: 'qwewqe',
    lastName: 'qweqwwq',
    phone: '21321321',
    deliveryTime: '',
    comment: '',
  },
  taxNumber: '123-456-789',
};

export const Product = {
  idConcreteProduct: '05516286',
  skuConcreteProduct: '05516286',
  images: {
    externalUrlLarge: `https://a1152ecommdigitalmedia.blob.core.windows.net/catalog/products/05516286_1_1280x1280.jpg`,
    externalUrlSmall: `https://a1152ecommdigitalmedia.blob.core.windows.net/catalog/products/05516286_1_1280x1280.jpg`,
  },
  productAttributes: {
    sku: '05516286',
    isDiscontinued: false,
    discontinuedNote: null,
    averageRating: null,
    reviewCount: 0,
    name: 'Wedge pad',
    description: '<p>Wedge shaped pad for a soft fixation of the patient\'s head during examination.</p>',
    attributes: {},
    attributeNames: {},
    superAttributesDefinition: [],
    metaTitle: 'Wedge pad - 05516286 - Siemens Healthineers Brazil',
    metaKeywords: 'Cushions_and_pads, Head, Positioning aids',
    metaDescription: 'Wedge shaped pad for a soft fixation of the patient\'s head during examination.. Discover more detailed information on our website.',
  },
  unitPrice: 24883,
};

export const Cart = {
  idCart: 'd31e8b48-7309-4c83-9884-4142efdf7271',
  attributes: {
    priceMode: 'GROSS_MODE',
    currency: 'BRL',
    store: 'BR',
    name: '1610101846979',
    isDefault: true,
    totalItemsQuantity: 1,
    createdAt: '2021-01-08 10:31:03.056392',
    orderedAt: '2021-01-08 10:39:39.000000',
    totals: {
      expenseTotal: 0,
      discountTotal: 0,
      taxTotal: 0,
      subtotal: Product.unitPrice,
      grandTotal: Product.unitPrice,
      priceToPay: Product.unitPrice,
    },
    customer: {...CustomerDerived.customerEmbedded},
    pointOfContact: {...Checkout.pointOfContact},
    approverDetails: {...Checkout.approverDetails},
    shippingAddress: {...AddressDerived.shippingAddress},
    discounts: [],
  },
};

// Stock backend responses
//// checkout data

export const checkoutData = {
  data: {
    type: 'checkout-update',
    id: null,
    attributes: {
      idCart: Cart.idCart,
      customer: {...CustomerDerived.customerEmbedded},
      shippingAddress: {...AddressDerived.shippingAddress},
      billingAddress: null,
      pointOfContact: {...Checkout.pointOfContact},
      approverDetails: {...Checkout.approverDetails},
      shipment: null,
      payments: [],
      taxNumber: Checkout.taxNumber,
    },
    links: {
      self: 'https://glue.br.b2b-vm-1.local/checkout-update',
    },
  },
};

//// current company user details
export const myCompanyUser = {
  data: [
    {
      type: 'company-users',
      id: Customer.idCompanyUser,
      attributes: {
        isActive: true,
        isDefault: false,
        consentedTerms: [...Customer.consentedTerms],
        companyBusinessUnit: {
          uuid: CompanyBusinessUnit.uuid,
          name: CompanyBusinessUnit.name,
          key: CompanyBusinessUnit.key,
        },
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/company-users/${Customer.idCompanyUser}?include=customers,company-roles`,
      },
      relationships: {
        'company-roles': {
          data: [
            {
              type: 'company-roles',
              id: 'd1bba67d-9628-507a-a95d-274f03f94dc2',
            },
          ],
        },
        customers: {
          data: [
            {
              type: 'customers',
              id: Customer.customerReference,
            },
          ],
        },
      },
    },
  ],
  links: {
    self: 'https://glue.br.b2b-vm-1.local/company-users/mine?include=customers,company-roles',
  },
  included: [
    {
      type: 'company-roles',
      id: 'd1bba67d-9628-507a-a95d-274f03f94dc2',
      attributes: {
        name: EUserRoles.Approver,
        isDefault: false,
      },
      links: {
        self: 'https://glue.br.b2b-vm-1.local/company-roles/d1bba67d-9628-507a-a95d-274f03f94dc2',
      },
    },
    {
      type: 'customers',
      id: Customer.customerReference,
      attributes: {
        ...CustomerDerived.customerIncluded,
        companyBusinessUnit: null,
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/customers/${Customer.customerReference}`,
      },
    },
  ],
};

//// current cart items
export const cartWithItems = {
  data: {
    type: 'carts',
    id: Cart.idCart,
    attributes: {
      ...Cart.attributes,
      systemDetails: {
        serialNumber: '2281',
        siemensEquipmentId: '400-530997',
        materialNumber: '10093902',
        namePtBr: 'AXIOM Luminos TF',
        nameEnUs: 'AXIOM Luminos TF',
        company: null,
        companyBusinessUnit: null,
        companyBusinessUnitNumber: null,
        contractStartDate: null,
        endOfSupport: '2022-12-31',
        contractExpirationDate: '',
        contractDurationYears: null,
        myEquipmentName: '',
        customerDesc: '',
        department: '',
        externalUrlSmall: 'https://a1152ecommdigitalmedia.blob.core.windows.net/catalog/products/10093902.jpg',
        contractName: '',
        contractNumber: '',
      },
    },
    links: {
      self: `https://glue.br.b2b-vm-1.local/carts/${Cart.idCart}?include=items,concrete-products,concrete-product-image-sets,vouchers,cart-rules`,
    },
    relationships: {
      items: {
        data: [
          {
            type: 'items',
            id: Product.idConcreteProduct,
          },
        ],
      },
    },
  },
  included: [
    {
      type: 'concrete-product-image-sets',
      id: Product.idConcreteProduct,
      attributes: {
        imageSets: [
          {
            name: 'default',
            images: [
              {...Product.images},
            ],
          },
        ],
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/concrete-products/${Product.idConcreteProduct}/concrete-product-image-sets`,
      },
    },
    {
      type: 'concrete-products',
      id: Product.idConcreteProduct,
      attributes: {...Product.productAttributes},
      links: {
        self: `https://glue.br.b2b-vm-1.local/concrete-products/${Product.idConcreteProduct}`,
      },
    },
    {
      type: 'items',
      id: Product.idConcreteProduct,
      attributes: {
        sku: Product.skuConcreteProduct,
        quantity: 1,
        groupKey: '05516286',
        abstractSku: `A_${Product.skuConcreteProduct}`,
        amount: null,
        calculations: {
          // Assuming a single price with no taxes/discounts/vouchers/etc. for simplicity
          unitPrice: Product.unitPrice,
          sumPrice: Product.unitPrice,
          taxRate: 0,
          unitNetPrice: 0,
          sumNetPrice: 0,
          unitGrossPrice: Product.unitPrice,
          sumGrossPrice: Product.unitPrice,
          unitTaxAmountFullAggregation: 0,
          sumTaxAmountFullAggregation: 0,
          sumSubtotalAggregation: Product.unitPrice,
          unitSubtotalAggregation: Product.unitPrice,
          unitProductOptionPriceAggregation: 0,
          sumProductOptionPriceAggregation: 0,
          unitDiscountAmountAggregation: 0,
          sumDiscountAmountAggregation: 0,
          unitDiscountAmountFullAggregation: 0,
          sumDiscountAmountFullAggregation: 0,
          unitPriceToPayAggregation: Product.unitPrice,
          sumPriceToPayAggregation: Product.unitPrice,
        },
        selectedProductOptions: [],
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/carts/${Cart.idCart}/items/${Product.idConcreteProduct}`,
      },
      relationships: {
        'concrete-products': {
          data: [
            {
              type: 'concrete-products',
              id: Product.idConcreteProduct,
            },
          ],
        },
      },
    },
  ],
};

//// Company business units (with addresses included)
export const companyBusinessUnitWithAddress = {
  data: [
    {
      type: 'company-business-units',
      id: CompanyBusinessUnit.idCompanyBusinessUnit,
      attributes: {
        ...CompanyBusinessUnit.attributes,
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/company-business-units/${CompanyBusinessUnit.idCompanyBusinessUnit}?include=company-business-unit-addresses`,
      },
      relationships: {
        'company-business-unit-addresses': {
          data: [
            {
              type: 'company-business-unit-addresses',
              id: Address.idCompanyBusinessUnitAddress,
            },
          ],
        },
      },
    },
  ],
  links: {
    self: 'https://glue.br.b2b-vm-1.local/company-business-units/mine?include=company-business-unit-addresses',
  },
  included: [
    {
      type: 'company-business-unit-addresses',
      id: Address.idCompanyBusinessUnitAddress,
      attributes: {
        ...Address.addressBaseAttributes,
        comment: '',
      },
      links: {
        self: `https://glue.br.b2b-vm-1.local/company-business-unit-addresses/${Address.idCompanyBusinessUnitAddress}`,
      },
    },
  ],
};

export const WrongAddressToReport = {
  name: 'R. Marselhesa, 500 São Paulo',
  key: 'deliveryAddress',
  value: {
    address1: 'R. Marselhesa, 500',
    address2: '',
    address3: '',
    city: 'São Paulo',
    comment: '',
    id: '718ccb84-cb36-553d-8489-a2d01be7c3a5',
    idCompanyUnitAddress: '718ccb84-cb36-553d-8489-a2d01be7c3a5',
    idCustomerAddress: null,
    iso2Code: 'BR',
    phone: '',
    zipCode: '',
  },
};


export const BillToAddressesResponseData: ISoldToAddressResource = {
  id: '35162',
  type: EGlueResource.SOLD_TO_ADDRESSES,
  attributes: {
    'name': 'TRINITY HEALTH MICHIGAN',
    'address1': '20555 VICTOR PKWY',
    'address2': null,
    'address3': null,
    'city': 'LIVONIA',
    'zipCode': '48152-7031',
    'state': 'MI',
    'iso2Code': 'us',
    'country': 'United States',
    'sapId': '35162',
  },
};

export const BillToAddressResponseIncluded: SoldToIncludedResource = {
  'type': EGlueResource.BILL_TO_ADDRESSES,
  'id': '30687',
  'attributes': {
    'name': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'address1': '1303 E HERNDON AVE',
    'address2': null,
    'address3': null,
    'city': 'FRESNO',
    'zipCode': '93720',
    'state': 'CA',
    'iso2Code': 'US',
    'country': 'United States',
    'sapId': '30687',
  },
};

export const ShipToAddressResponseIncluded: SoldToIncludedResource = {
  'type': EGlueResource.SHIP_TO_ADDRESSES,
  'id': '111111',
  'attributes': {
    'name': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'address1': '1303 E HERNDON AVE',
    'address2': null,
    'address3': null,
    'city': 'FRESNO',
    'zipCode': '93720',
    'state': 'CA',
    'iso2Code': 'US',
    'country': 'United States',
    'sapId': '30687',
  },
};

export const billToAddressResponse: ISoldToAddressesResponse = {
  data: BillToAddressesResponseData[EGlueResource.SOLD_TO_ADDRESSES],
  included: [BillToAddressResponseIncluded],
};

export const billToAddressAndShipToAddressResponse: ISoldToAddressesResponse = {
  data: BillToAddressesResponseData[EGlueResource.SOLD_TO_ADDRESSES],
  included: [BillToAddressResponseIncluded, ShipToAddressResponseIncluded],
};

export const shipToAddressResponse: ISoldToAddressesResponse = {
  data: BillToAddressesResponseData[EGlueResource.SOLD_TO_ADDRESSES],
  included: [ShipToAddressResponseIncluded],
};

export const BillToAddressEvent = {
  'value': {
    'sapId': '10114',
    'salutation': 'Ms',
    'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'lastName': '',
    'address1': '1303 E HERNDON AVE',
    'address2': '',
    'zipCode': '93720-9309',
    'city': 'FRESNO',
    'state': 'CA',
    'iso2Code': 'US',
    'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'phone': null,
    'country': 'United States',
    'isDefaultBilling': false,
    'isDefaultShipping': false,
  },
  'key': 'businessPartnerBillToAddress',
  'name': '10114 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720-9309)',
  'inputType': 'selectBox',
};

export const ShipToAddressEvent = {
  'value': {
    'sapId': '10114',
    'salutation': 'Ms',
    'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'lastName': '',
    'address1': '1303 E HERNDON AVE',
    'address2': '',
    'zipCode': '93720-9309',
    'city': 'FRESNO',
    'state': 'CA',
    'iso2Code': 'US',
    'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'phone': null,
    'country': 'United States',
    'isDefaultBilling': false,
    'isDefaultShipping': false,
  },
  'key': 'businessPartnerShipToAddress',
  'name': '10114 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720-9309)',
  'inputType': 'selectBox',
};

export const checkoutBillToAddress = {
  'sapId': '10114',
  'salutation': 'Ms',
  'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
  'lastName': '',
  'address1': '1303 E HERNDON AVE',
  'address2': '',
  'zipCode': '93720-9309',
  'city': 'FRESNO',
  'state': 'CA',
  'iso2Code': 'US',
  'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
  'phone': null,
  'country': 'United States',
  'isDefaultBilling': false,
  'isDefaultShipping': false,
  'idCustomerAddress': '0000',
  'idCompanyUnitAddress': '0001',
};

export const checkoutUpdatedBillToAddress = {
  'salutation': 'Ms',
  'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
  'lastName': 'empty',
  'address1': '1303 E HERNDON AVE',
  'address2': 'empty',
  'address3': '',
  'zipCode': '93720-9309',
  'city': 'FRESNO',
  'country': 'United States',
  'iso2Code': 'US',
  'phone': 'empty',
  'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
  'state': 'CA',
  'sapId': '10114',
  'isDefaultBilling': false,
  'isDefaultShipping': false,
  'idCustomerAddress': '0000',
  'idCompanyUnitAddress': '0001',
};


export const checkoutDataResponse = {
  data: {
    type: EGlueResource.CHECKOUT_DATA,
    id: null,
    attributes: {
      addresses: [],
      paymentProviders: [
        {
          paymentProviderName: 'dummyPayment',
          paymentMethods: [
            {
              paymentMethodName: 'Invoice',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
                'dummyPaymentInvoice.dateOfBirth',
              ],
            },
          ],
        },
        {
          paymentProviderName: 'ContractPayment',
          paymentMethods: [
            {
              paymentMethodName: 'contract',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
              ],
            },
          ],
        },
        {
          paymentProviderName: 'InvoicePayment',
          paymentMethods: [
            {
              paymentMethodName: 'Pay by invoice',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
                'payByInvoicePurchaseOrder.purchaseOrderNumber',
              ],
            },
          ],
        },
      ],
      shipmentMethods: [
        {
          id: 3,
          name: 'FedEx Priority Overnight',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
        {
          id: 5,
          name: 'FedEx Heavy Weight Shipment',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
        {
          id: 6,
          name: 'FedEx 2nd Day Delivery',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
      ],
      selectedShipmentMethods: [],
      selectedPaymentMethods: [],
    },
    links: {
      self: `https://dev.us.glue.backoffice.siemens-healthineers.com:443/${EGlueResource.CHECKOUT_DATA}`,
    },
  },
};

export const cartItemsWithTotals = {
  data: {
    attributes: {
      totals: {
        expenseTotal: 12800,
        discountTotal: 0,
        taxTotal: 0,
        subtotal: 1546800,
        grandTotal: 1559600,
        priceToPay: 1559600,
        cpqPromocodeReductionTotal: 0,
        cpqListPriceTotal: 0,
        cpqContractReductionTotal: 0,
        cpqNetPriceTotal: 0,
      },
    },
  },
};

export const checkoutDataWithShipmentsAndShipmentMethods = {
  data: {
    type: 'checkout-data',
    id: null,
    attributes: {
      addresses: [],
      paymentProviders: [
        {
          paymentProviderName: 'dummyPayment',
          paymentMethods: [
            {
              paymentMethodName: 'Invoice',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
                'dummyPaymentInvoice.dateOfBirth',
              ],
            },
          ],
        },
        {
          paymentProviderName: 'ContractPayment',
          paymentMethods: [
            {
              paymentMethodName: 'contract',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
              ],
            },
          ],
        },
        {
          paymentProviderName: 'InvoicePayment',
          paymentMethods: [
            {
              paymentMethodName: 'Pay by invoice',
              paymentProviderName: null,
              requiredRequestData: [
                'paymentMethod',
                'paymentProvider',
                'payByInvoicePurchaseOrder.purchaseOrderNumber',
              ],
            },
          ],
        },
      ],
      shipmentMethods: [
        {
          id: 2,
          name: 'FedEx Priority Overnight',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
        {
          id: 3,
          name: 'FedEx Saturday Delivery',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
        {
          id: 4,
          name: 'FedEx Heavy Weight Shipment',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
        {
          id: 5,
          name: 'FedEx 2nd Day Delivery',
          carrierName: 'FedEx',
          price: 0,
          taxRate: null,
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
      ],
      selectedShipmentMethods: [],
      selectedPaymentMethods: [],
    },
    links: {
      self: 'https://glue.us.shs-ecommerce.local:443/checkout-data?include=shipment-methods,shipments',
    },
    relationships: {
      'shipment-methods': {
        data: [
          {
            type: 'shipment-methods',
            id: '2',
          },
          {
            type: 'shipment-methods',
            id: '3',
          },
          {
            type: 'shipment-methods',
            id: '4',
          },
          {
            type: 'shipment-methods',
            id: '5',
          },
        ],
      },
      shipments: {
        data: [
          {
            type: 'shipments',
            id: 'e588db83e0f4496efae3d9f0b33e0e88',
          },
        ],
      },
    },
  },
  included: [
    {
      type: 'shipment-methods',
      id: '2',
      attributes: {
        name: 'FedEx Priority Overnight',
        carrierName: 'FedEx',
        deliveryTime: null,
        price: 0,
        currencyIsoCode: 'USD',
      },
      links: {
        self: 'https://glue.us.shs-ecommerce.local:443/shipment-methods/2',
      },
    },
    {
      type: 'shipment-methods',
      id: '3',
      attributes: {
        name: 'FedEx Saturday Delivery',
        carrierName: 'FedEx',
        deliveryTime: null,
        price: 0,
        currencyIsoCode: 'USD',
      },
      links: {
        self: 'https://glue.us.shs-ecommerce.local:443/shipment-methods/3',
      },
    },
    {
      type: 'shipment-methods',
      id: '4',
      attributes: {
        name: 'FedEx Heavy Weight Shipment',
        carrierName: 'FedEx',
        deliveryTime: null,
        price: 0,
        currencyIsoCode: 'USD',
      },
      links: {
        self: 'https://glue.us.shs-ecommerce.local:443/shipment-methods/4',
      },
    },
    {
      type: 'shipment-methods',
      id: '5',
      attributes: {
        name: 'FedEx 2nd Day Delivery',
        carrierName: 'FedEx',
        deliveryTime: null,
        price: 0,
        currencyIsoCode: 'USD',
      },
      links: {
        self: 'https://glue.us.shs-ecommerce.local:443/shipment-methods/5',
      },
    },
    {
      type: 'shipments',
      id: 'e588db83e0f4496efae3d9f0b33e0e88',
      attributes: {
        items: [
          '00908657',
        ],
        requestedDeliveryDate: null,
        shippingAddress: {
          id: null,
          salutation: 'Ms',
          firstName: 'empty',
          lastName: 'empty',
          address1: 'DUMMY COMPANY ADDRESS',
          address2: '',
          address3: '',
          zipCode: '',
          city: '',
          country: 'United States',
          iso2Code: 'US',
          company: 'empty',
          phone: '',
          isDefaultBilling: false,
          isDefaultShipping: false,
          idCompanyBusinessUnitAddress: null,
          idCustomerAddress: null,
          idCompanyUnitAddress: 'b9cf0019-b832-56b9-930b-539086c493c2',
          state: '',
          sapId: null,
        },
        selectedShipmentMethod: {
          id: 2,
          name: 'FedEx Priority Overnight',
          carrierName: 'FedEx',
          price: 0,
          taxRate: '0.00',
          deliveryTime: null,
          currencyIsoCode: 'USD',
        },
      },
      links: {
        self: 'https://glue.us.shs-ecommerce.local:443/shipments/e588db83e0f4496efae3d9f0b33e0e88',
      },
      relationships: {
        'shipment-methods': {
          data: [
            {
              type: 'shipment-methods',
              id: '2',
            },
            {
              type: 'shipment-methods',
              id: '3',
            },
            {
              type: 'shipment-methods',
              id: '4',
            },
            {
              type: 'shipment-methods',
              id: '5',
            },
          ],
        },
      },
    },
  ],
};

export const shipmentMethodsData = {
  data: {
    type: 'checkout-data',
    id: null,
    attributes: {
      addresses: [],
      paymentProviders: [
        {
          paymentProviderName: 'dummyPayment',
          'paymentMethods': [
            {
              paymentMethodName: 'Invoice',
              paymentProviderName: null,
              requiredRequestData: ['paymentMethod', 'paymentProvider', 'dummyPaymentInvoice.dateOfBirth'],
            },
          ],
        },
      ],
      shipmentMethods: [
        {
          'id': 5,
          'name': 'Standard Shipping',
          'carrierName': 'DHL',
          'price': 0,
          'taxRate': null,
          'deliveryTime': null,
          'currencyIsoCode': 'AUD',
        },
        {
          'id': 6,
          'name': 'Expedited Shipping',
          'carrierName': 'DHL',
          'price': 20000,
          'taxRate': null,
          'deliveryTime': null,
          'currencyIsoCode': 'AUD',
        },
      ],
      selectedShipmentMethods: [],
      selectedPaymentMethods: [],
    },
    links:
      {
        self: 'https:\/\/glue.au.shs-ecommerce.local:443\/checkout-data',
      },
  },
};

export const shipmentMethodsOrRecaulculatePriceData = {
  'data': {
    'type': 'carts',
    'id': 'f5b18279-5271-5251-adc3-53c45a1bc541',
    'attributes': {
      'priceMode': 'NET_MODE',
      'currency': 'AUD',
      'store': 'AU',
      'name': '1673433401521',
      'isDefault': true,
      'totalItemsQuantity': 4,
      'createdAt': '2023-01-11 10:36:51.000000',
      'orderedAt': '2023-01-11 10:37:34.000000',
      'validUntil': '2023-02-15 09:35:19.000000',
      'customerSoldToId': null,
      'hasContractInCart': false,
      'taxDisclaimer': null,
      'totals': {
        'expenseTotal': 20000,
        'discountTotal': 0,
        'taxTotal': 6200,
        'subtotal': 42003,
        'grandTotal': 68203,
        'priceToPay': 68203,
        'shipmentTotal': null,
        'cpqPromocodeReductionTotal': 0,
        'cpqListPriceTotal': 0,
        'cpqContractReductionTotal': 0,
        'cpqNetPriceTotal': 0,
      },
      'customer': {
        'email': 'frantisek.radacovsky@siemens-healthineers.com',
        'salutation': 'Mr/Mrs',
        'firstName': 'Frantisek',
        'lastName': 'Radacovsky',
        'idCustomer': 43,
        'customerReference': 'Z004KT9V',
        'idCompanyBusinessUnit': null,
        'idCompanyUser': null,
        'idCompany': null,
        'uuidCompanyUser': null,
        'companyUserTransfer': {
          'fkCompany': 28,
          'idCompanyUser': 41,
          'fkCompanyBusinessUnit': 655,
          'isDefault': false,
          'fkCustomer': 43,
          'key': 'Z004KT9V',
          'uuid': '76bc90ce-ee2c-557c-9da1-2ce623683d41',
          'isActive': true,
          'companyBusinessUnit': {
            'name': 'DUMMY SYDNEY HEALTH',
            'idCompanyBusinessUnit': 655,
            'fkCompany': 28,
            'defaultBillingAddress': null,
            'uuid': '53a9358a-e5db-556f-ab56-4834686390da',
            'key': 'AU_59596',
            'email': '',
            'phone': '',
            'externalUrl': '',
            'iban': '',
            'bic': '',
            'fkParentCompanyBusinessUnit': null,
            'businessUnitNumber': '59596',
            'taxNumber': '',
            'paymentTerm': '',
            'fkSalesOffice': null,
            'country': 'AU',
            'language': 'en',
            'active': true,
            'blockUsers': false,
            'customerAccountGroup': 'PY,BP,SP,SH',
            'isSyncDisabled': null,
            'addressCollection': {
              'pagination': null, 'companyUnitAddresses': [{
                'idCompanyUnitAddress': 656,
                'fkCompany': 28,
                'fkCompanyBusinessUnit': null,
                'isDefaultBilling': null,
                'iso2Code': 'AU',
                'address2': 'Dummy Sydney 2',
                'zipCode': '48952',
                'city': 'SYDNEY',
                'address1': 'Dummy Sydney 1',
                'uuid': '47261d78-8121-5356-b592-7eaf32beb504',
                'address3': 'Dummy Sydney 3',
                'phone': '8465254234',
                'comment': '',
                'key': 'AU_59596',
                'fkCountry': 15,
                'fkRegion': null,
                'state': 'AU',
                'companyBusinessUnits': {
                  'pagination': null,
                  'companyBusinessUnits': [{
                    'name': 'DUMMY SYDNEY HEALTH',
                    'idCompanyBusinessUnit': 655,
                    'fkCompany': 28,
                    'defaultBillingAddress': null,
                    'uuid': '53a9358a-e5db-556f-ab56-4834686390da',
                    'key': 'AU_59596',
                    'email': '',
                    'phone': '',
                    'externalUrl': '',
                    'iban': '',
                    'bic': '',
                    'fkParentCompanyBusinessUnit': null,
                    'businessUnitNumber': '59596',
                    'taxNumber': '',
                    'paymentTerm': '',
                    'fkSalesOffice': null,
                    'country': 'AU',
                    'language': 'en',
                    'active': true,
                    'blockUsers': false,
                    'customerAccountGroup': 'PY,BP,SP,SH',
                    'isSyncDisabled': null,
                    'addressCollection': null,
                    'company': null,
                    'parentCompanyBusinessUnit': null,
                    'salesOffice': null,
                    'newAddress': null,
                    'merchantRelationships': [],
                  }],
                },
                'company': null,
                'labelCollection': null,
                'country': {
                  'idCountry': 15,
                  'name': 'Australia',
                  'iso2Code': 'AU',
                  'iso3Code': 'AUS',
                  'postalCodeMandatory': true,
                  'postalCodeRegex': '\\d{4}',
                  'regions': [],
                },
              }],
            },
            'company': {
              'name': 'DUMMY SYDNEY HEALTH',
              'idCompany': 28,
              'isActive': true,
              'status': 'approved',
              'uuid': '5de17a48-0d7e-57b5-a242-841c75676d67',
              'key': 'AU_59596',
              'isSystem': null,
              'initialUserTransfer': null,
              'storeRelation': null,
            },
            'parentCompanyBusinessUnit': null,
            'salesOffice': null,
            'newAddress': {
              'idCompanyUnitAddress': 656,
              'fkCompany': 28,
              'fkCompanyBusinessUnit': null,
              'isDefaultBilling': null,
              'iso2Code': 'AU',
              'address2': 'Dummy Sydney 2',
              'zipCode': '48952',
              'city': 'SYDNEY',
              'address1': 'Dummy Sydney 1',
              'uuid': '47261d78-8121-5356-b592-7eaf32beb504',
              'address3': 'Dummy Sydney 3',
              'phone': '8465254234',
              'comment': '',
              'key': 'AU_59596',
              'fkCountry': 15,
              'fkRegion': null,
              'state': 'AU',
              'companyBusinessUnits': {
                'pagination': null,
                'companyBusinessUnits': [{
                  'name': 'DUMMY SYDNEY HEALTH',
                  'idCompanyBusinessUnit': 655,
                  'fkCompany': 28,
                  'defaultBillingAddress': null,
                  'uuid': '53a9358a-e5db-556f-ab56-4834686390da',
                  'key': 'AU_59596',
                  'email': '',
                  'phone': '',
                  'externalUrl': '',
                  'iban': '',
                  'bic': '',
                  'fkParentCompanyBusinessUnit': null,
                  'businessUnitNumber': '59596',
                  'taxNumber': '',
                  'paymentTerm': '',
                  'fkSalesOffice': null,
                  'country': 'AU',
                  'language': 'en',
                  'active': true,
                  'blockUsers': false,
                  'customerAccountGroup': 'PY,BP,SP,SH',
                  'isSyncDisabled': null,
                  'addressCollection': null,
                  'company': null,
                  'parentCompanyBusinessUnit': null,
                  'salesOffice': null,
                  'newAddress': null,
                  'merchantRelationships': [],
                }],
              },
              'company': null,
              'labelCollection': null,
              'country': {
                'idCountry': 15,
                'name': 'Australia',
                'iso2Code': 'AU',
                'iso3Code': 'AUS',
                'postalCodeMandatory': true,
                'postalCodeRegex': '\\d{4}',
                'regions': [],
              },
            },
            'merchantRelationships': [],
          },
          'company': {
            'name': 'DUMMY SYDNEY HEALTH',
            'idCompany': 28,
            'isActive': true,
            'status': 'approved',
            'uuid': '5de17a48-0d7e-57b5-a242-841c75676d67',
            'key': 'AU_59596',
            'isSystem': null,
            'initialUserTransfer': null,
            'storeRelation': null,
          },
          'customer': {
            'availabilityNotificationSubscriptionSkus': [],
            'customerReference': 'Z004KT9V',
            'email': 'frantisek.radacovsky@siemens-healthineers.com',
            'isOnBehalf': null,
            'isGuest': null,
            'fkUser': null,
            'sendPasswordToken': null,
            'lastName': 'Radacovsky',
            'firstName': 'Frantisek',
            'salutation': 'Mr/Mrs',
            'idCustomer': 43,
            'isActiveCompanyUserExists': null,
            'companyUserInvitationHash': null,
            'defaultBillingAddress': null,
            'defaultShippingAddress': null,
            'password': '$2y$10$BfK63PmaH/mYpHNfVjCIIuDwnMM.q2ZyjzUAjZTXPmAEiXPoqsXdy',
            'isDirty': null,
            'registrationKey': null,
            'registered': null,
            'username': null,
            'company': '',
            'gender': 'N/A',
            'dateOfBirth': null,
            'newPassword': null,
            'createdAt': '2022-12-16 14:25:55.000000',
            'updatedAt': '2022-12-16 15:03:37.000000',
            'restorePasswordKey': null,
            'restorePasswordLink': null,
            'restorePasswordDate': null,
            'confirmationLink': null,
            'message': null,
            'anonymizedAt': null,
            'phone': null,
            'uuidCompanyUser': null,
            'isEnabled': null,
            'country': null,
            'preferredLanguage': null,
            'state': null,
            'zip': null,
            'gradTitle': null,
            'consentedTerms': ['terms_of_use', 'terms_and_conditions'],
            'customerAddress': null,
            'city': null,
            'preferredPayment': null,
            'notifications': [],
            'preferredShipToId': null,
            'companyUserTransfer': null,
            'addresses': null,
            'permissions': null,
            'customerProductListCollection': null,
            'locale': null,
            'billingAddress': [],
            'shippingAddress': [],
          },
          'companyRoleCollection': {
            'pagination': null, 'roles': [{
              'idCompanyRole': 110,
              'fkCompany': 28,
              'isDefault': false,
              'name': 'RIGHT_WEBSHOPS_APPROVER_USER',
              'key': 'AU_59596_Approver',
              'uuid': '315feac0-79be-5cc0-b597-076641324bc1',
              'companyUserCollection': {
                'total': null, 'pagination': null, 'filter': null, 'companyUsers': [{
                  'fkCompany': 28,
                  'idCompanyUser': 41,
                  'fkCompanyBusinessUnit': 655,
                  'isDefault': false,
                  'fkCustomer': 43,
                  'key': 'Z004KT9V',
                  'uuid': '76bc90ce-ee2c-557c-9da1-2ce623683d41',
                  'isActive': true,
                  'companyBusinessUnit': null,
                  'company': null,
                  'customer': {
                    'availabilityNotificationSubscriptionSkus': [],
                    'customerReference': 'Z004KT9V',
                    'email': 'frantisek.radacovsky@siemens-healthineers.com',
                    'isOnBehalf': null,
                    'isGuest': null,
                    'fkUser': null,
                    'sendPasswordToken': null,
                    'lastName': 'Radacovsky',
                    'firstName': 'Frantisek',
                    'salutation': 'Mr/Mrs',
                    'idCustomer': 43,
                    'isActiveCompanyUserExists': null,
                    'companyUserInvitationHash': null,
                    'defaultBillingAddress': null,
                    'defaultShippingAddress': null,
                    'password': '$2y$10$BfK63PmaH/mYpHNfVjCIIuDwnMM.q2ZyjzUAjZTXPmAEiXPoqsXdy',
                    'isDirty': null,
                    'registrationKey': null,
                    'registered': null,
                    'username': null,
                    'company': '',
                    'gender': 'N/A',
                    'dateOfBirth': null,
                    'newPassword': null,
                    'createdAt': '2022-12-16 14:25:55.000000',
                    'updatedAt': '2022-12-16 15:03:37.000000',
                    'restorePasswordKey': null,
                    'restorePasswordLink': null,
                    'restorePasswordDate': null,
                    'confirmationLink': null,
                    'message': null,
                    'anonymizedAt': null,
                    'phone': null,
                    'uuidCompanyUser': null,
                    'isEnabled': null,
                    'country': null,
                    'preferredLanguage': null,
                    'state': null,
                    'zip': null,
                    'gradTitle': null,
                    'consentedTerms': [],
                    'customerAddress': null,
                    'city': null,
                    'preferredPayment': null,
                    'notifications': [],
                    'preferredShipToId': null,
                    'companyUserTransfer': null,
                    'addresses': null,
                    'permissions': null,
                    'customerProductListCollection': null,
                    'locale': null,
                    'billingAddress': [],
                    'shippingAddress': [],
                  },
                  'companyRoleCollection': null,
                }],
              },
              'permissionCollection': {
                'permissions': [{
                  'idPermission': 20,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'ApproveQuotePermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 6,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'AddCartItemPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 7,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'ChangeCartItemPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 8,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'RemoveCartItemPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 9,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'PlaceOrderWithAmountUpToPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 19,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'PlaceOrderPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 18,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'RequestQuoteApprovalPermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 21,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'CancelQuotePermissionPlugin',
                  'isInfrastructural': null,
                }, {
                  'idPermission': 24,
                  'configurationSignature': [],
                  'idCompanyRole': null,
                  'configuration': [],
                  'key': 'QuoteRequestHierarchyAccessPermissionPlugin',
                  'isInfrastructural': null,
                }],
              },
              'company': {
                'name': 'DUMMY SYDNEY HEALTH',
                'idCompany': 28,
                'isActive': true,
                'status': 'approved',
                'uuid': '5de17a48-0d7e-57b5-a242-841c75676d67',
                'key': 'AU_59596',
                'isSystem': null,
                'initialUserTransfer': null,
                'storeRelation': null,
              },
            }],
          },
        },
      },
      'pointOfContact': null,
      'approverDetails': {
        'uuidQuoteApproval': null,
        'uuidQuote': null,
        'status': null,
        'approverId': null,
        'firstName': null,
        'lastName': null,
        'comment': null,
        'dueDate': null,
        'companyUserId': null,
      },
      'shippingAddress': null,
      'billingAddress': null,
      'soldToAddress': null,
      'payerAddress': null,
      'systemDetails': null,
      'discounts': [],
    },
    'links': {'self': 'https://glue.au.shs-ecommerce.local:443/carts/f5b18279-5271-5251-adc3-53c45a1bc541?include=items'},
    'relationships': {
      'items': {
        'data': [{'type': 'items', 'id': '10509852'}, {
          'type': 'items',
          'id': '99999998',
        }, {'type': 'items', 'id': '99999999'}, {'type': 'items', 'id': '99999997'}],
      },
    },
  }, 'included': [{
    'type': 'items',
    'id': '10509852',
    'attributes': {
      'sku': '10509852',
      'quantity': 1,
      'groupKey': '10509852',
      'abstractSku': 'A_10509852',
      'amount': null,
      'modality': null,
      'customerSoldToId': null,
      'cpqConfigInCart': false,
      'cpqTabName': null,
      'cpqProductId': null,
      'cpqConfigId': null,
      'cpqNetPrice': null,
      'cpqListPrice': null,
      'cpqContractReduction': null,
      'cpqPromocodeReduction': null,
      'cpqDiscountPercentage': null,
      'deliveryAvailability': 'E',
      'deliveryTime': '07',
      'materialNumber': '12',
      'locMaterial': '',
      'calculations': {
        'unitPrice': 12000,
        'sumPrice': 12000,
        'taxRate': 10,
        'unitNetPrice': 12000,
        'sumNetPrice': 12000,
        'unitGrossPrice': 0,
        'sumGrossPrice': 0,
        'unitTaxAmountFullAggregation': 1200,
        'sumTaxAmountFullAggregation': 1200,
        'sumSubtotalAggregation': 12000,
        'unitSubtotalAggregation': 12000,
        'unitProductOptionPriceAggregation': 0,
        'sumProductOptionPriceAggregation': 0,
        'unitDiscountAmountAggregation': 0,
        'sumDiscountAmountAggregation': 0,
        'unitDiscountAmountFullAggregation': 0,
        'sumDiscountAmountFullAggregation': 0,
        'unitPriceToPayAggregation': 13200,
        'sumPriceToPayAggregation': 13200,
        'unitListPrice': null,
        'sumListPrice': null,
        'unitExchangePrice': null,
        'sumExchangePrice': null,
      },
      'productConfigurationInstance': null,
      'systemDetails': null,
      'customerSoldToAddress': null,
      'customerShipToAddress': null,
      'billToAddress': null,
      'payerAddress': null,
      'selectedProductOptions': [],
    },
    'links': {'self': 'https://glue.au.shs-ecommerce.local:443/carts/f5b18279-5271-5251-adc3-53c45a1bc541/items/10509852'},
  }, {
    'type': 'items',
    'id': '99999998',
    'attributes': {
      'sku': '99999998',
      'quantity': 1,
      'groupKey': '99999998',
      'abstractSku': 'A_99999998',
      'amount': null,
      'modality': null,
      'customerSoldToId': null,
      'cpqConfigInCart': false,
      'cpqTabName': null,
      'cpqProductId': null,
      'cpqConfigId': null,
      'cpqNetPrice': null,
      'cpqListPrice': null,
      'cpqContractReduction': null,
      'cpqPromocodeReduction': null,
      'cpqDiscountPercentage': null,
      'deliveryAvailability': 'Currently in stock',
      'deliveryTime': 'Up to 12 days',
      'materialNumber': '99999998',
      'locMaterial': '0',
      'calculations': {
        'unitPrice': 10001,
        'sumPrice': 10001,
        'taxRate': 10,
        'unitNetPrice': 10001,
        'sumNetPrice': 10001,
        'unitGrossPrice': 0,
        'sumGrossPrice': 0,
        'unitTaxAmountFullAggregation': 1000,
        'sumTaxAmountFullAggregation': 1000,
        'sumSubtotalAggregation': 10001,
        'unitSubtotalAggregation': 10001,
        'unitProductOptionPriceAggregation': 0,
        'sumProductOptionPriceAggregation': 0,
        'unitDiscountAmountAggregation': 0,
        'sumDiscountAmountAggregation': 0,
        'unitDiscountAmountFullAggregation': 0,
        'sumDiscountAmountFullAggregation': 0,
        'unitPriceToPayAggregation': 11001,
        'sumPriceToPayAggregation': 11001,
        'unitListPrice': null,
        'sumListPrice': null,
        'unitExchangePrice': null,
        'sumExchangePrice': null,
      },
      'productConfigurationInstance': null,
      'systemDetails': null,
      'customerSoldToAddress': null,
      'customerShipToAddress': null,
      'billToAddress': null,
      'payerAddress': null,
      'selectedProductOptions': [],
    },
    'links': {'self': 'https://glue.au.shs-ecommerce.local:443/carts/f5b18279-5271-5251-adc3-53c45a1bc541/items/99999998'},
  }, {
    'type': 'items',
    'id': '99999999',
    'attributes': {
      'sku': '99999999',
      'quantity': 1,
      'groupKey': '99999999',
      'abstractSku': 'A_99999999',
      'amount': null,
      'modality': null,
      'customerSoldToId': null,
      'cpqConfigInCart': false,
      'cpqTabName': null,
      'cpqProductId': null,
      'cpqConfigId': null,
      'cpqNetPrice': null,
      'cpqListPrice': null,
      'cpqContractReduction': null,
      'cpqPromocodeReduction': null,
      'cpqDiscountPercentage': null,
      'deliveryAvailability': 'Currently in stock',
      'deliveryTime': 'Up to 12 days',
      'materialNumber': '99999999',
      'locMaterial': '0',
      'calculations': {
        'unitPrice': 10000,
        'sumPrice': 10000,
        'taxRate': 10,
        'unitNetPrice': 10000,
        'sumNetPrice': 10000,
        'unitGrossPrice': 0,
        'sumGrossPrice': 0,
        'unitTaxAmountFullAggregation': 1000,
        'sumTaxAmountFullAggregation': 1000,
        'sumSubtotalAggregation': 10000,
        'unitSubtotalAggregation': 10000,
        'unitProductOptionPriceAggregation': 0,
        'sumProductOptionPriceAggregation': 0,
        'unitDiscountAmountAggregation': 0,
        'sumDiscountAmountAggregation': 0,
        'unitDiscountAmountFullAggregation': 0,
        'sumDiscountAmountFullAggregation': 0,
        'unitPriceToPayAggregation': 11000,
        'sumPriceToPayAggregation': 11000,
        'unitListPrice': null,
        'sumListPrice': null,
        'unitExchangePrice': null,
        'sumExchangePrice': null,
      },
      'productConfigurationInstance': null,
      'systemDetails': null,
      'customerSoldToAddress': null,
      'customerShipToAddress': null,
      'billToAddress': null,
      'payerAddress': null,
      'selectedProductOptions': [],
    },
    'links': {'self': 'https://glue.au.shs-ecommerce.local:443/carts/f5b18279-5271-5251-adc3-53c45a1bc541/items/99999999'},
  }, {
    'type': 'items',
    'id': '99999997',
    'attributes': {
      'sku': '99999997',
      'quantity': 1,
      'groupKey': '99999997',
      'abstractSku': 'A_99999997',
      'amount': null,
      'modality': null,
      'customerSoldToId': null,
      'cpqConfigInCart': false,
      'cpqTabName': null,
      'cpqProductId': null,
      'cpqConfigId': null,
      'cpqNetPrice': null,
      'cpqListPrice': null,
      'cpqContractReduction': null,
      'cpqPromocodeReduction': null,
      'cpqDiscountPercentage': null,
      'deliveryAvailability': 'Currently in stock',
      'deliveryTime': 'Up to 12 days',
      'materialNumber': '99999997',
      'locMaterial': '0',
      'calculations': {
        'unitPrice': 10002,
        'sumPrice': 10002,
        'taxRate': 10,
        'unitNetPrice': 10002,
        'sumNetPrice': 10002,
        'unitGrossPrice': 0,
        'sumGrossPrice': 0,
        'unitTaxAmountFullAggregation': 1000,
        'sumTaxAmountFullAggregation': 1000,
        'sumSubtotalAggregation': 10002,
        'unitSubtotalAggregation': 10002,
        'unitProductOptionPriceAggregation': 0,
        'sumProductOptionPriceAggregation': 0,
        'unitDiscountAmountAggregation': 0,
        'sumDiscountAmountAggregation': 0,
        'unitDiscountAmountFullAggregation': 0,
        'sumDiscountAmountFullAggregation': 0,
        'unitPriceToPayAggregation': 11002,
        'sumPriceToPayAggregation': 11002,
        'unitListPrice': null,
        'sumListPrice': null,
        'unitExchangePrice': null,
        'sumExchangePrice': null,
      },
      'productConfigurationInstance': null,
      'systemDetails': null,
      'customerSoldToAddress': null,
      'customerShipToAddress': null,
      'billToAddress': null,
      'payerAddress': null,
      'selectedProductOptions': [],
    },
    'links': {'self': 'https://glue.au.shs-ecommerce.local:443/carts/f5b18279-5271-5251-adc3-53c45a1bc541/items/99999997'},
  }],
};

export const addressMockData: IAddress = {
  salutation: 'test',
  firstName: 'test',
  lastName: 'test',
  address1: 'test',
  address2: 'test',
  address3: 'test',
  zipCode: 'test',
  city: 'test',
  state: 'test',
  iso2Code: 'test',
  company: 'test',
  phone: 'test',
  country: 'test',
  isDefaultBilling: true,
  isDefaultShipping: true,
  idCompanyBusinessUnitAddress: 'test',
  name: 'test',
};

export const preferredAddressShipToData: IAddress = {
  salutation: 'Ms',
  firstName: undefined,
  lastName: '',
  address1: undefined,
  address2: '',
  zipCode: undefined,
  city: undefined,
  state: undefined,
  iso2Code: undefined,
  company: undefined,
  phone: null,
  country: undefined,
  isDefaultBilling: false,
  isDefaultShipping: false,
  sapId: undefined,
};
export const preferredAddressShipToData2: IAddress = {
  salutation: 'Ms',
  firstName: "TRINITY HEALTH CLINICAL ENGINEERING",
  lastName: '',
  address1: "1303 E HERNDON AVE",
  address2: '',
  zipCode: '93720-9309',
  city: "FRESNO",
  state: "",
  iso2Code: "US",
  company: "TRINITY HEALTH CLINICAL ENGINEERING",
  phone: null,
  country: "United States",
  isDefaultBilling: false,
  isDefaultShipping: false,
  sapId: '10114',
};

export const customerCheckoutData = {
  'email': 'test@test.sk',
  'salutation': 'hi',
  'firstName': 'test',
  'customerReference': 'ref',
  'uuidCompanyUser': 'test123',
  'lastName': 'test',
};

export const postCheckoutData: ICheckout = {
  type: 'checkout',
  id: 'BR--359',
  attributes: {
    orderReference: 'BR--359',
    redirectUrl: null,
    isExternalRedirect: null,
    idCart: 'BR-359',
    isAddressSavingSkipped: false,
    taxNumber: null,
    customer: customerCheckoutData,
    billingAddress: addressMockData,
    shippingAddress: addressMockData,
    soldToAddress: addressMockData,
    payerAddress: addressMockData,
    payments: [],
    shipment: {
      idShipmentMethod: 123,
    },
    termsAccessTime: null,
    consentedTerms: [],
  },
};

export const billingAddress: IAddressExtended = {
  id: '1',
  salutation: 'Mr.',
  firstName: 'John',
  lastName: 'Doe',
  address1: 'Doe Street 1',
  address2: 'Building 1',
  zipCode: '12345',
  city: 'Doe City',
  iso2Code: 'A',
  company: 'Doe Company',
  phone: '0123456789',
  country: 'Doe Country',
  isDefaultBilling: true,
  isDefaultShipping: true,
};

export const preferredShipToAddress = {
  sapId: undefined,
  salutation: 'Ms',
  firstName: undefined,
  lastName: '',
  address1: undefined,
  address2: '',
  zipCode: undefined,
  city: undefined,
  state: undefined,
  iso2Code: undefined,
  company: undefined,
  phone: null,
  country: undefined,
  isDefaultBilling: false,
  isDefaultShipping: false,
};

export const availableBpShipToAddress = [
  {
    'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
    'value': {
      'sapId': '30687',
      'salutation': 'Ms',
      'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'lastName': '',
      'address1': '1303 E HERNDON AVE',
      'address2': '',
      'zipCode': '93720',
      'city': 'FRESNO',
      'state': 'CA',
      'iso2Code': 'US',
      'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'phone': null,
      'country': 'United States',
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
  {
    'name': '18389 - OHIO STATE UNIVERSITY HOSPITAL (410 W 10TH AVE, COLUMBUS, OH, 43210)',
    'value': {
      'sapId': '18389',
      'salutation': 'Ms',
      'firstName': 'OHIO STATE UNIVERSITY HOSPITAL',
      'lastName': '',
      'address1': '410 W 10TH AVE',
      'address2': '',
      'zipCode': '43210',
      'city': 'COLUMBUS',
      'state': 'OH',
      'iso2Code': 'US',
      'company': 'OHIO STATE UNIVERSITY HOSPITAL',
      'phone': null,
      'country': undefined,
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
  {
    'name': 'test (test, test, test, test)',
    'value': {
      'salutation': 'test',
      'firstName': 'test',
      'lastName': 'test',
      'address1': 'test',
      'address2': 'test',
      'address3': 'test',
      'zipCode': 'test',
      'city': 'test',
      'state': 'test',
      'iso2Code': 'test',
      'company': 'test',
      'phone': 'test',
      'country': 'test',
      'isDefaultBilling': true,
      'isDefaultShipping': true,
      'idCompanyBusinessUnitAddress': 'test',
      'name': 'test',
      'sapId': '9665',
    },
  },
];

export const selectBoxAddress = {
  'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
  'value': {
    'sapId': '30687',
    'salutation': 'Ms',
    'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'lastName': '',
    'address1': '1303 E HERNDON AVE',
    'address2': '',
    'zipCode': '93720',
    'city': 'FRESNO',
    'state': 'CA',
    'iso2Code': 'US',
    'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
    'phone': null,
    'country': 'United States',
    'isDefaultBilling': false,
    'isDefaultShipping': false,
  },
};

export const availableBpShipToAddress1 = [
  {
    'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
    'value': {
      'sapId': '30687',
      'salutation': 'Ms',
      'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'lastName': '',
      'address1': '1303 E HERNDON AVE',
      'address2': '',
      'zipCode': '93720',
      'city': 'FRESNO',
      'state': 'CA',
      'iso2Code': 'US',
      'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'phone': null,
      'country': 'United States',
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
  {
    'name': '18389 - OHIO STATE UNIVERSITY HOSPITAL (410 W 10TH AVE, COLUMBUS, OH, 43210)',
    'value': {
      'sapId': '18389',
      'salutation': 'Ms',
      'firstName': 'OHIO STATE UNIVERSITY HOSPITAL',
      'lastName': '',
      'address1': '410 W 10TH AVE',
      'address2': '',
      'zipCode': '43210',
      'city': 'COLUMBUS',
      'state': 'OH',
      'iso2Code': 'US',
      'company': 'OHIO STATE UNIVERSITY HOSPITAL',
      'phone': null,
      'country': undefined,
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
];

export const availableBpShipToAddress2 = [
  {
    'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
    'value': {
      'sapId': '10114',
      'salutation': 'Ms',
      'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'lastName': '',
      'address1': '1303 E HERNDON AVE',
      'address2': '',
      'zipCode': '93720',
      'city': 'FRESNO',
      'state': 'CA',
      'iso2Code': 'US',
      'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'phone': null,
      'country': 'United States',
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  }, {
    'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
    'value': {
      'sapId': '10114',
      'salutation': 'Ms',
      'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'lastName': '',
      'address1': '1303 E HERNDON AVE',
      'address2': '',
      'zipCode': '93720-9309',
      'city': 'FRESNO',
      'state': 'CA',
      'iso2Code': 'US',
      'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'phone': null,
      'country': 'United States',
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
];

export const availableBpBillToAddress = [
  {
    'name': '30687 - TRINITY HEALTH CLINICAL ENGINEERING (1303 E HERNDON AVE, FRESNO, CA, 93720)',
    'value': {
      'sapId': '30687',
      'salutation': 'Ms',
      'firstName': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'lastName': '',
      'address1': '1303 E HERNDON AVE',
      'address2': '',
      'zipCode': '93720',
      'city': 'FRESNO',
      'state': 'CA',
      'iso2Code': 'US',
      'company': 'TRINITY HEALTH CLINICAL ENGINEERING',
      'phone': null,
      'country': 'United States',
      'isDefaultBilling': false,
      'isDefaultShipping': false,
    },
  },
];
