import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StoreConfigurationActions } from '../actions';
import { mergeMap } from 'rxjs/operators';
import { StoreConfigurationFacade } from '../facades/store-configuration.facade';

@Injectable()
export class StoreConfigurationEffects {
  constructor(
    private actions$: Actions,
    private storeConfigurationFacade: StoreConfigurationFacade,
  ) {
  }

  retrieveStoreConfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StoreConfigurationActions.retrieveStoreConfiguration),
      mergeMap(action =>
        this.storeConfigurationFacade.getStoreConfiguration(action.store)
          .toPromise()
          .then(
            configuration => StoreConfigurationActions.retrieveStoreConfigurationSuccess(
              {storeConfiguration: configuration}
            ),
          )
          .catch(
            err => StoreConfigurationActions.retrieveStoreConfigurationFail({error: err}),
          ),
      ),
    );
  });
}
