import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  delta = 2;
  currentPage = 1;
  showedPages: number[];
  lengthOfShowedPages = 5;
  queryParamsSubscription: Subscription;

  @Input() itemsPerPage: number;
  @Input() numberOfPages: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfPages?.currentValue) {
      this.initPagination();
    }
  }

  ngOnInit(): void {
    this.initPagination();
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  initPagination(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('page')) {
        this.currentPage = parseInt(params.page, 10);
      } else {
        this.currentPage = 1;
      }

      this.showedPages = this.setShowedPages(this.currentPage, this.numberOfPages, this.lengthOfShowedPages, this.delta);
    });
  }

  setShowedPages(currentPage: number, lastPage: number, showedPages: number, delta: number): number[] {
    const range = [],
          left = currentPage - delta,
          right = currentPage + delta + 1;

    if (currentPage < showedPages - left) {
      if (lastPage > showedPages) {
        for (let i = 1; i <= showedPages; i++) {
          range.push(i);
        }
      } else {
        for (let i = 1; i <= lastPage; i++) {
          range.push(i);
        }
      }
    } else {
      for (let i = 1; i <= lastPage; i++) {
        if (i >= left && i < right) {
          range.push(i);
        }
      }
    }

    return range;
  }

  showFirstPageWithDots(): boolean {
    return this.currentPage > this.delta + 1;
  }

  showLastPageWithDots(): boolean {
    return this.numberOfPages > this.lengthOfShowedPages && this.currentPage < this.numberOfPages - this.delta;
  }

  pageUrl(): string {
    return decodeURIComponent(this.router.url.split('?')[0]);
  }

  queryParams(page: number): any {
    // eslint-disable-next-line prefer-const
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams.page = page;
    return urlTree.queryParams;
  }

  getPaginationItemWidth(page: number): string {
    return (page.toString().length <= 3 ? 30 : (page.toString().length * 10)) + 'px';
  }
}
