import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ICheckout } from '../models/checkout.models';
import { postCheckoutData } from '../testing/mocks/component/checkout.data';
import { IOrderResponseWithMessages } from '../models/order.models';
import { orderDetails } from '../testing/mocks/component/order-approve.data';

@Injectable({
  providedIn: 'root',
})
export class DeliveryMockFacade {

  constructor() {
  }

  postCheckout(data: any): Observable<ICheckout> {
    return of(postCheckoutData);
  }

  getOrderFromMock(): Observable<IOrderResponseWithMessages> {
    return of(orderDetails);
  }
}
