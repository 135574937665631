import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CheckoutActions } from '../actions';
import { IAddress } from '../models/common.models';
import { EUserRoles } from '../configurations/common';
import { IRfqBaseInfo } from '../models/rfq.models';

export interface CheckoutState {
  selectedDeliveryAddress: any;
  pointOfContacts: any;
  selectedApprover: any;
  approverComment: string;
  postCheckoutData: any;
  responseFromPostCheckout: any;
  cartId: string;
  Approvers: [];
  ApproversLoaded: boolean;
  NewCustomerAddressData: IAddress;
  userId: string;
  ApproversLoadedError: Error;
  loggedUserRoles: EUserRoles[];
  rfqHistoryInfo: IRfqBaseInfo[] | undefined;
}

export const initializeState = (): CheckoutState => {
  return {
    responseFromPostCheckout: undefined,
    cartId: '',
    postCheckoutData: undefined,
    approverComment: undefined,
    pointOfContacts: undefined,
    selectedApprover: undefined,
    selectedDeliveryAddress: undefined,
    Approvers: undefined,
    ApproversLoaded: false,
    userId: undefined,
    NewCustomerAddressData: undefined,
    ApproversLoadedError: undefined,
    loggedUserRoles: [EUserRoles.Buyer],
    rfqHistoryInfo: undefined
  };
};

const getCheckoutFeatureState = createFeatureSelector<CheckoutState>('checkout');

export const initialState = initializeState();

export const selectRfqHistoryData = createSelector(
  getCheckoutFeatureState,
  state => state.rfqHistoryInfo,
);

const reducer = createReducer(
  initialState,

  on(CheckoutActions.UpdateCheckoutData, (state: CheckoutState, {deliveryAddress, pointOfContact, approver, approverComment}) => {
    return {
      ...state,
      selectedDeliveryAddress: deliveryAddress,
      selectedApprover: approver,
      approverComment,
      pointOfContacts: pointOfContact,
    };
  }),
  on(CheckoutActions.UpdatePOCData, (state: CheckoutState, {payload}) => {
    return {...state, pointOfContacts: payload};
  }),
  on(CheckoutActions.UpdateSelectedDeliveryAddress, (state: CheckoutState, {payload}) => {
    return {...state, selectedDeliveryAddress: payload};
  }),
  on(CheckoutActions.PostCheckoutData, (state, action): CheckoutState => {
    return {
      ...state,
      postCheckoutData: action.postCheckoutData,
      cartId: action.cartId,
    };
  }),
  on(CheckoutActions.SuccessPostCheckoutDataAction, (state, action): CheckoutState => {
    return {
      ...state,
      responseFromPostCheckout: action.responseFromPostCheckout,
    };
  }),
  on(CheckoutActions.BeginApproversListAction, (state: CheckoutState) => {
    return {...state};
  }),
  on(CheckoutActions.SuccessGetApproversListAction, (state: CheckoutState, {payload}) => {
    return {...state, Approvers: payload, ApproversLoaded: true};
  }),
  on(CheckoutActions.ErrorGetApproversListAction, (state: CheckoutState, error: Error) => {
    return {...state, ApproversLoadedError: error};
  }),
  on(CheckoutActions.SuccessGetQuoteRfqHistoryDataAction, (state: CheckoutState, {rfqBaseInfo}) => {
    return {
      ...state,
      rfqHistoryInfo: rfqBaseInfo?.data as IRfqBaseInfo[]
    };
  })
);


export function CheckoutReducers(state: CheckoutState | undefined, action: Action): any {
  return reducer(state, action);
}
