<ng-container>
  <div class="mini-cart__items" *ngIf="itemsInCart?.length > 0">
    <div class="overflow-gradient"></div>
    <div class="items-list">
      <div class="item-row" *ngFor="let item of itemsInCart">
        <div class="item-col-img">
          <img [src]="getItemPicture(item)" [alt]="item.attributes.name">
        </div>
        <div class="item-col-desc" [attr.data-test]="'desc-minicartItemName-'+item.attributes.name">
          {{ item.attributes.name }}
        </div>
        <div class="item-col-stock">
          <div class="item-col-qty" [attr.data-test]="'desc-minicartItemQuantity-'+item.attributes.name">
            {{ item.attributes.quantity }}
          </div>
          <div class="item-col-price" [attr.data-test]="'desc-minicartItemPrice-'+item.attributes.name"
               *ngIf="!!item.attributes?.calculations?.unitPrice && checkWorkflowInformation">
            {{ item.attributes.calculations.unitPrice | currency:currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
