<div class="row catalog-products">
  <app-access-control [notForViewer]="true" *ngIf="showHelpMeChoose()">
    <ng-container ngProjectAs="[slot][content]">
      <div class="p-xs-0 mb-xs-1" [ngClass]="layoutType === 'grid' ? 'col-grid' : 'col-12'">
        <app-help-me-choose
            [layoutType]="layoutType"
            [contractParams]="contractParams">
        </app-help-me-choose>
      </div>
    </ng-container>
  </app-access-control>
  <div *ngFor="let product of products" 
       [ngClass]="{'p-xs-0 mb-xs-1': isUsStore && product?.productConfigurationInstance,
                   'col-grid': layoutType === 'grid', 
                   'col-12': layoutType !== 'grid'}">
    <app-basic-product
        *ngIf="!isUsStore && !isCaStore && !product?.productConfigurationInstance"
        [layoutType]="layoutType"
        [itemsInQueueToCart]="itemsInQueueToCart"
        [product]="product"
        [labels]="labels"
        [wishlists]="wishlists"
        [addNewModalActive]="addNewModalActive"
        [loadingCartDataInProgress]="loadingCartDataInProgress"
        (addNewWishlistModalActive)="addNewModalActive = $event"
        (addProductToCart)="addToCart($event)">
    </app-basic-product>
    <app-spare-part-product
        *ngIf="(isUsStore || isCaStore) && isSparePartsEnabled && !product?.productConfigurationInstance"
        [layoutType]="layoutType"
        [itemsInQueueToCart]="itemsInQueueToCart"
        [product]="product"
        [labels]="labels"
        [wishlists]="wishlists"
        [addNewModalActive]="addNewModalActive"
        [currentCart]="currentCart"
        [systemDetails]="systemDetails"
        [loadingCartDataInProgress]="loadingCartDataInProgress"
        (addNewWishlistModalActive)="addNewModalActive = $event"
        (addProductToCart)="addToCart($event)">
    </app-spare-part-product>
    <app-configurable-product
        *ngIf="isUsStore && isCpqEnabled && product?.productConfigurationInstance"
        [layoutType]="layoutType"
        [contractParams]="contractParams"
        [itemsInQueueToCart]="itemsInQueueToCart"
        [product]="product"
        (selectContract)="handleSelectContract($event)">
    </app-configurable-product>
  </div>
  <app-add-new-wishlist
      [showModal]="addNewModalActive"
      (closeModal)="addNewModalActive = false"
      (created)="wishlistCreated()">
  </app-add-new-wishlist>
  <app-add-item-modal
      [showModal]="addItemToCartModalActive"
      (closeModal)="addItemToCartModalActive = false"
      [items]="itemToAdd"
      [currentCartId]="currentCartId">
  </app-add-item-modal>
  <app-modal-confirm
      [type]="iconType.WARNING"
      modalTitle="{{ 'catalog.select-equipment-modal.title' | translate }}"
      notifyText="{{ 'catalog.select-equipment-modal.text' | translate }}"
      cancelBtnText="{{ 'catalog.select-equipment-modal.cancel-btn' | translate }}"
      primaryBtnText="{{ 'catalog.select-equipment-modal.submit-btn' | translate }}"
      [showModalConfirm]="showModalSelectEquipment"
      (closeModalConfirm)="showModalSelectEquipment = false"
      (primaryActionConfirm)="selectEquipment()">
  </app-modal-confirm>
</div>
