import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CartUtils } from '../../utils/cart.utils';
import { debounceTime, skipWhile, take, takeUntil } from 'rxjs/operators';
import { MarketingFacade } from '../../facades/marketing.facade';
import { combineLatest, Subject } from 'rxjs';
import { ICart, ICartUpdateRequestAttributes } from '../../models/cart.models';
import { AnalyticsService } from '../../analytics/analytics.service';
import { IconType } from '../../models/settings.model';
import { AppActions } from '../../actions';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { ISystemDetails } from '../../models/common.models';
import { CatalogFacade } from '../../facades/catalog.facade';
import { EInstalledBaseTabs } from '../../configurations/common';
import { CustomerFacade } from '../../facades/customer.facade';
import { OpalFacade } from '../../facades/opal.facade';
import { EquipmentUtils } from '../../utils/equipment.utils';

export enum ModalOptions {
  ExistingCart = 'existing-cart',
  NewCart = 'new-cart',
}

@Component({
  selector: 'app-add-item-modal',
  templateUrl: './add-item-modal.component.html',
  styleUrls: ['./add-item-modal.component.scss'],
})
export class AddItemModalComponent implements OnDestroy, OnChanges, OnInit {
  @Input() items;
  @Input() currentCartId: string;
  @Input() showModal: boolean;
  @Input() isModalInitialized: boolean = true;
  @Input() isReorderWorkflowSelected: boolean = false;
  @Input() flNumberOfReorder: string = '';
  @Output() isModalInProgress = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Input() isSapP40Enable = false;
  @Input() orderId: string;

  iconType = IconType;
  carts: any;
  isLoadingCarts: boolean = true;

  tabsOpened = [] as Array<string>;
  cartSelected: ICart;
  cartName = CartUtils.getCartName;

  isAddToNewCartBtn: boolean = false;
  isAddToExistingCartBtn: boolean = false;
  isAddToCartInProgress: boolean = false;
  showModalSelectEquipment: boolean = false;
  systemDetailsOfReorder: ISystemDetails;
  isBusinessPartnerRole: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private configurationFacade: ConfigurationFacade,
    private catalogFacade: CatalogFacade,
    private customerFacade: CustomerFacade,
    private opalFacade: OpalFacade,
  ) {}

  ngOnInit(): void {
    this.selectCartsWithItems();
    this.selectIsBPRole();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    if (this.isModalInitialized && this.isReorderWorkflowSelected) {
      this.selectSystemDetailsOfReorder();
    }
  }

  selectSystemDetailsOfReorder(): void {
    if (!this.isBusinessPartnerRole) {
      this.catalogFacade.getInstallBaseSystem(this.flNumberOfReorder).pipe(
        take(1)
      ).subscribe(installedBase => {
        if (installedBase) {
          this.systemDetailsOfReorder = installedBase.data[0].attributes;
        }
      });
    } else {
      this.opalFacade.getOpal(this.flNumberOfReorder).pipe(
        take(1),
      ).subscribe(equipment => {
        this.systemDetailsOfReorder = EquipmentUtils.convertEquipmentSelectionDataToSystemDetails(equipment.data);
      });
    }
  }

  close(): void {
    this.closeModal.emit();
  }

  setModalState(isInProgress: boolean, selectedOption?: string): void {
    this.isAddToCartInProgress = isInProgress;
    this.isModalInProgress.emit(isInProgress);

    if (!selectedOption) {
      this.isAddToExistingCartBtn = isInProgress;
      this.isAddToNewCartBtn = isInProgress;
      return;
    }

    switch (selectedOption) {
      case ModalOptions.ExistingCart:
        this.isAddToExistingCartBtn = isInProgress;
        break;
      case ModalOptions.NewCart:
        this.isAddToNewCartBtn = isInProgress;
        break;
      default:
        break;
    }
  }

  selectEquipment(): void {
    this.router.navigate(['/my-installed-base'], {
      queryParams: {
        tab : EInstalledBaseTabs.EQUIPMENT
      }
    }).then();
  }

  selectCart(cart: any): void {
    this.cartSelected = cart;
  }

  selectCartsWithItems(): void {
    combineLatest([
      this.marketingFacade.selectCarts(),
      this.marketingFacade.selectCartsItems(),
      this.marketingFacade.selectCartItemsWithDetails()
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([carts, cartsItems, cartItemsWithDetails]) => {
          this.carts = JSON.parse(JSON.stringify(carts));
          const concreteProducts = cartsItems?.filter(c => c.type === 'concrete-products');
          this.carts.forEach(cart => {
            cart.items = [];
            if (cart.relationships) {
              cart.relationships.items?.data?.forEach(data => {
                const relatedItem = cartItemsWithDetails.find(item => item.id === data.id);
                const hasConfigurableItems = !!relatedItem?.attributes.cpqConfigId;
                if (!hasConfigurableItems) {
                  cart.items.push({
                    details: concreteProducts.find(product => product.id === data.id),
                    quantity: relatedItem?.attributes.quantity
                  });
                }
                cart.hasConfigurableItems = hasConfigurableItems;
              });
            }
          });

          this.isLoadingCarts = false;
        },
        error: () => {
          this.isLoadingCarts = false;
        }
      });
  }

  isContractInCart(cart: any): boolean {
    return !!cart.hasConfigurableItems;
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  addItemToCart(items: any): void {
    items.forEach((item, index) => {
      this.addToCart(item);

      if (index === items.length - 1) {
        this.closeModalAfterAddToCartOperation();
      }
    });
  }

  private addToCart(item: any): void {
    this.analyticsService.setProducts(item.data);
    this.analyticsService.trackCart('cart.add');
    this.marketingFacade.addProductToCart(item.data, item.isConcrete, item.quantity);
  }

  // Will be rework for BP
  addItemToExistingCart(): void {
    const systemDetails = this.cartSelected?.attributes.systemDetails;

    if (systemDetails) {
      this.addToCartWithSystemDetails(systemDetails);
      // TODO: For BP users also set sold-to from previous order
    } else {
      if (this.isReorderWorkflowSelected) {
        this.addToCartWithoutSystemDetails();
      } else {
        this.showModalSelectEquipment = true;
      }
    }
  }

  private addToCartWithSystemDetails(systemDetails: ISystemDetails): void {
    this.setModalState(true, ModalOptions.ExistingCart);
    this.loadSelectedCartAndAddItem(systemDetails);
  }

  private addToCartWithoutSystemDetails(): void {
    if (this.systemDetailsOfReorder) {
      this.setModalState(true, ModalOptions.ExistingCart);
      this.loadSelectedCartAndAddItem(null);
    }
  }

  private loadSelectedCartAndAddItem(cartSystemDetails: ISystemDetails): void {
    let additionalAttributes: ICartUpdateRequestAttributes = {systemDetails: this.systemDetailsOfReorder};
    if (cartSystemDetails) {
      additionalAttributes = null;
    }
    if (this.cartSelected.id !== this.currentCartId) {
      this.marketingFacade.switchDefaultCart(this.cartSelected.id, additionalAttributes);
      this.marketingFacade.selectCartId().pipe(
        skipWhile(cartId => cartId != this.cartSelected.id),
        take(1),
      ).subscribe({
        next: _ => this.afterCartIsLoaded(this.getItemsWithUpdatedSystemDetails()),
      });
    } else {
      if (additionalAttributes) {
        // update cart with system details from original order
        this.marketingFacade.updateCartById(this.currentCartId, additionalAttributes);
      }
      this.marketingFacade.getCurrentCartItems().pipe(
        skipWhile(cart => !cart?.data?.attributes.systemDetails),
        take(1),
      ).subscribe({
        next: _ => this.afterCartIsLoaded(this.getItemsWithUpdatedSystemDetails()),
      });
    }
  }

  // Will be rework for BP
  private getItemsWithUpdatedSystemDetails(): any {
    if (this.isBusinessPartnerRole) {
      return this.items.map(item => {
        return {
          ...item,
          data: {
            ...item.data,
          },
        };
      });
    } else {
      return this.items.map(item => {
        return {
          ...item,
          data: {
            ...item.data,
          }
        }
      });
    }
  }

  afterCartIsLoaded(items: any): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      take(1)
    ).subscribe(isLoading => {
      if (!isLoading) {
        if (this.cartSelected.id !== this.currentCartId) {
          this.showNotificationYourCartWasChanged();
        }
        this.addItemToCart(items);
      }
    })
  }

  private showNotificationYourCartWasChanged(): void {
    this.configurationFacade.appendNotification({
      type: 'success',
      title: 'shop-cart.your-cart-was-changed-title',
      messages: [{
        key: 'shop-cart.your-cart-was-changed-text',
      }],
      actions: [
        {
          type: '',
          label: 'shop-cart.close',
          css: ['button', 'button--secondary']
        },
        {
          type: AppActions.redirectToShopCart.type,
          label: 'shop-cart.go-to-cart',
          css: ['button', 'button--primary']
        }
      ]
    });
  }

  addItemToNewCart(): void {
    if (this.isReorderWorkflowSelected) {
      this.addItemToNewCartWithReorderWorkflow();
    } else {
      this.addItemToNewCartWithDefaultWorkflow();
    }
  }

  private addItemToNewCartWithReorderWorkflow(): void {
    this.setModalState(true, ModalOptions.NewCart);
    this.marketingFacade.createEmptyCart();
    this.marketingFacade.selectCartId().pipe(
      skipWhile(id => !id || id === this.currentCartId),
      take(1),
    ).subscribe(() => {
      this.items.length > 1
        ? this.marketingFacade.reorderAllItems(this.items)
        : this.marketingFacade.reorderItem(this.items[0]);

      if (this.isBusinessPartnerRole) {
        this.router.navigate(['/equipment-selection'], {queryParams: {
            orderId: this.orderId,
          }}).then();
      } else {
        this.router.navigate(['/my-installed-base'], {
          queryParams: {
            tab: EInstalledBaseTabs.EQUIPMENT,
            orderId: this.orderId,
          }
        }).then();
      }
    });
  }

  private addItemToNewCartWithDefaultWorkflow(): void {
    this.marketingFacade.createEmptyCart();
    if (this.cartSelected?.attributes.systemDetails) {
      this.marketingFacade.selectCartId().pipe(
        skipWhile(id => !id || id === this.currentCartId),
        take(1),
      ).subscribe(() => {
        this.setModalState(true, ModalOptions.NewCart);
        this.addItemToCart(this.items);
      });
    } else {
      this.showModalSelectEquipment = true;
    }
  }

  private closeModalAfterAddToCartOperation(): void {
    this.marketingFacade.isAddItemOperationInProgress().pipe(
      debounceTime(100),
      skipWhile(inProgress => inProgress === true),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.setModalState(false);
      this.close()
    });
  }

  hasSelectedCartSystemDetails(): boolean {
    if (this.cartSelected) {
      if (this.isBusinessPartnerRole) {
        return true;
      }

      return (this.systemDetailsOfReorder && !this.cartSelected?.attributes.systemDetails)
             || !!this.cartSelected?.attributes.systemDetails;
    }
    return true;
  }

  private selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isBPRole => {
        this.isBusinessPartnerRole = isBPRole;
      })
  }
}
