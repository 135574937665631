<div class="container">
  <h3 class="page-title" [attr.data-test]="'title-yourCart'">
    {{ 'shop-cart.yourCart' | translate }}
  </h3>
  <app-access-control [notForViewer]="true" [description]="true">
    <ng-container ngProjectAs="[slot][nonViewerContent]">
      <div class="row" *ngIf="!loadingCartDataInProgress; else loading">
        <div class="col-12 col-lg-8">
          <div class="container">
            <ng-container>
              <div class="row shopping-cart-title">
                <ng-container *ngIf="totalItemsQuantity !== 0; else emptyCartTemplate">
                  <div class="col-2 animation-in-progress-content">
                    <ng-container *ngIf="updateCartOperationInProgress$ | async">
                      <div class="loading-animation animation-in-progress"></div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #emptyCartTemplate>
                  <div class="col-12 empty-cart">
                    <p class="margin-top-1">{{ 'shop-cart.empty-cart-message' | translate}}</p>
                  </div>
                </ng-template>
              </div>
              <ng-container *ngIf="totalItemsQuantity !== 0">
                <app-cart-items
                    [cartId]="cartId"
                    [items]="items"
                    [currency]="currency"
                    [updateItemInCartData]="updateItemInCartData"
                    [wishlists]="wishlists"
                    [addNewModalActive]="addNewModalActive"
                    [rfqActive]="rfqActive"
                    [displayPrices]="totalItemsQuantity !== 0 && (!rfqActive || (isRfqOnly && isSetPricesForAllItems()))"
                    (addNewWishlistEvent)="addNewModalActive = true"
                    (passQuantityToParent)="getQuantityFromItem($event)">
                </app-cart-items>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-lg-4 shopping-cart-side-menu">
          <div class="container">
            <div class="row justify-content-center">
              <ng-container>
                <button
                    class="button button--primary col-10 shopping-cart-proceed-to-delivery-details-button"
                    [disabled]="disableBtnProceedToNextStep()"
                    (click)="proceedToNextStep()"
                    [attr.data-test]="'button-proceedToNextStep'">
                  {{ proceedBtnLabel() | translate }}
                </button>
                <button
                    *ngIf="isExportCartActive && !rfqActive"
                    class="button col-10 button--generate-pdf"
                    [disabled]="addItemToCartOperationInProgress || totalItemsQuantity === 0 || !currentQuantity"
                    (click)="generatePdfFile()">
                  {{ 'generate-pdf.export-btn' | translate }}
                </button>
              </ng-container>
              <ng-container *ngIf="!currentQuantity">
                <small class="textfield__hint">{{'app-product-page.enter-quantity' | translate}}</small>
              </ng-container>
              <button
                  class="button col-10 empty-cart shopping-cart-continue-shopping-button"
                  (click)="redirectToProductLists()">
                {{ 'shop-cart.continueShopping' | translate }}
              </button>
            </div>
            <ng-container *ngIf="(!rfqActive || (isRfqOnly && isSetPricesForAllItems()))">
              <div class="row justify-content-center shopping">
                <div class="col-12 col-xl-10 min-order-value" *ngIf="!isMinimumOrderValue()">
                  <strong>
                    {{ 'shop-cart.minimum-order-value' | translate }}
                  </strong>
                </div>
                <div class="col-12 col-xl-7 shopping-cart-total-title">{{ 'shop-cart.total' | translate }}</div>
                <div class="col-12 col-xl-3 shopping-cart-total-price" [attr.data-test]="'desc-cartTotalPrice'">
                  {{ getTotalPrice() | currency: currency }}
                </div>
                <div class="col-12 col-xl-10 shopping-cart-total-additional-info">
                <ng-container *ngIf="showPriceDisclaimer; else totalAdditionalInformation">
                    {{ 'shop-cart.excludes-vat' | translate }}
                    <br/>
                    {{ 'shop-cart.pricing-validation' | translate }}
                </ng-container>
                <ng-template #totalAdditionalInformation>
                    {{ 'shop-cart.totalAdditionalInformation' | translate }}
                    <br/>
                    {{ 'shop-cart.free-shipping' | translate }}
                </ng-template>
                </div>
              </div>
              <div class="row justify-content-center shopping margin-bottom-2" *ngIf="cartRules?.length > 0">
                <div class="col-12 col-xl-10 padding-0">
                  <app-cart-rules
                      [cartRules]="cartRules"
                      [priceToPay]="currentCart.totals.priceToPay"
                      [currency]="currency">
                  </app-cart-rules>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-template #loading>
        <div class="row">
          <div class="col-12 loading__wrapper">
            <div class="loading-animation"></div>
          </div>
        </div>
      </ng-template>
      <app-add-new-wishlist
          [showModal]="addNewModalActive"
          (closeModal)="addNewModalActive = false">
      </app-add-new-wishlist>
    </ng-container>
  </app-access-control>
</div>
