<app-modal [open]="isModalOpen" (crossClick)="dismiss()" [title]="'cart-modal.welcome' | translate">
  <ng-container ngProjectAs="[slot][body]">
    <div class="cart-modal">
      <p>{{ 'cart-modal.pending-quotes' | translate }}</p>
      <div class="modal-actions">
        <button class="button button--primary" (click)="gotToQuotes()">{{ 'cart-modal.quotes' | translate }}</button>
        <button class="button" (click)="dismiss()">{{ 'cart-modal.dismiss' | translate }}</button>
      </div>
    </div>
  </ng-container>
</app-modal>
