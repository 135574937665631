import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EFeatureToggles, EMultiCartTabs } from '../../configurations/common';
import { MarketingFacade } from '../../facades/marketing.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { OrdersFacade } from '../../facades/orders.facade';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-page-purchase-activity',
  templateUrl: './page-purchase-activity.component.html',
  styleUrls: ['./page-purchase-activity.component.scss'],
})
export class PagePurchaseActivityComponent implements OnInit, OnDestroy, OnChanges {
  EMultiCartTabs = EMultiCartTabs;

  defaultTab: string;
  currentTab: string;
  tabs: string[] = [
    EMultiCartTabs.PARTS,
    EMultiCartTabs.SERVICES,
  ];

  isSparePartsEnabled: boolean = false;
  isCpqEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private pageReadyEventFired: boolean = false;
  @Input() serviceOrdersLoaded: boolean = false;
  @Input() sparePartOrdersLoaded: boolean = false;

  constructor(
    private marketingFacade: MarketingFacade,
    private orderFacade: OrdersFacade,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit() {
    this.selectUsFeatures();

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.defaultTab = !this.isSparePartsEnabled && this.isCpqEnabled
          ? EMultiCartTabs.SERVICES
          : EMultiCartTabs.PARTS;
        this.currentTab = params.tab
          ? params.tab
          : this.defaultTab;
      });

    this.reorderItems();
    this.loadCpqOrdersData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void {
    if (this.serviceOrdersLoaded && this.sparePartOrdersLoaded) {
      this.trackPageReady();
    }
  }

  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
      if (!this.isCpqEnabled) {
        this.serviceOrdersLoaded = true;
      }
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
      if (!this.isSparePartsEnabled) {
        this.sparePartOrdersLoaded = true;
      }
    });
  }

  reorderItems(): void {
    this.marketingFacade.isReorderConfirmed().pipe(
      take(1),
    ).subscribe(isReorderConfirmed => {
      if (isReorderConfirmed) {
        this.marketingFacade.getReorderItems().pipe(
          take(1),
        ).subscribe(items => {
          this.addToCart(items);
          this.marketingFacade.completeReorder();
        });
      }
    });
  }

  private addToCart(items: any[]): void {
    items.forEach(item => {
      this.analyticsService.setProducts(item.data);
      this.analyticsService.trackCart('cart.add');
      this.marketingFacade.addProductToCart(item.data, item.isConcrete, item.quantity);
    });
  }

  trackPageReady(): void {
    if (!this.pageReadyEventFired && (
      (this.currentTab === EMultiCartTabs.PARTS && this.sparePartOrdersLoaded) ||
      (this.currentTab === EMultiCartTabs.SERVICES && this.serviceOrdersLoaded)
    )) {
      this.analyticsService.trackPageReady('My Purchase Activity', PageTypes.MY_PURCHASE_ACTIVITY_PAGE);
      this.pageReadyEventFired = true;
    }
  }

  changeTab(tab: string): void {
    this.currentTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab: tab, subtab: null},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private loadCpqOrdersData() {
    if (this.isCpqEnabled) {
      this.orderFacade.loadCpqOrdersFulfilledData();
      this.orderFacade.loadCpqOrdersUnderReviewData();
    }
  }
}
