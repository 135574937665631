<div *ngIf="isSapP40Modal" class="sapP40Notification">
  <div class="sap-message">
    <span class="sap-message__label--gray"
          [attr.data-test]="'label-messageColor'">
    </span>
    <div class="sap-message--content">
      <div>
        <span aria-hidden="true"
              class="sap-message--content__icon icon-warning icon-warning__circle"
              [attr.data-test]="'sapMessage-icon'">
        </span>
      </div>
      <span class="sap-message--content__text" [attr.data-test]="'sapMessage-text'">
        {{ 'feature_toggle.sap_p40.disabled-text1' | translate }}
        <b>{{ 'feature_toggle.sap_p40.disabled-text2' | translate }}</b>
        {{ 'feature_toggle.sap_p40.disabled-text3' | translate }}
        <b>{{ 'feature_toggle.sap_p40.disabled-text4' | translate }}</b>
        {{ 'feature_toggle.sap_p40.disabled-text5' | translate }}
        <a href="mailto:{{ 'feature_toggle.sap_p40.e-mail' | translate }}">{{ 'feature_toggle.sap_p40.e-mail' | translate }}</a>.
        {{ 'feature_toggle.sap_p40.disabled-text2' | translate }}
        {{ 'feature_toggle.sap_p40.enabled-date' | translate }}
      </span>
      <span>
        <button
            type="button"
            class="sap-message--content__close"
            aria-label="Close notification"
            (close)="hideNotification($event)">
          <i aria-hidden="true" class="icon-close sap-message--content__close"></i>
        </button>
      </span>
    </div>
  </div>
</div>
