import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreadCrumb } from '../../models/common.models';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { ERouteDataTypes } from '../../configurations/common';
import { ESeoData, ETitleSources } from '../../configurations/seo';
import { SeoFacade } from '../../facades/seo.facade';
import { BREADCRUMB_SCHEME_CLASS } from '../../configurations/structured-data';
import { I18nService } from '../../services';
import { StringUtils } from '../../utils/string.utils';
import { firstValueFrom, Subject } from 'rxjs';
import { LocalStorageUtils } from '../../utils/localStorage.utils';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})

export class BreadcrumbsComponent implements OnInit, OnDestroy {
  currentRoute: NavigationEnd;
  breadcrumbs: BreadCrumb[];
  breadcrumbsUpdating: boolean = false;
  displayBreadCrumbs: boolean = false;
  breadcrumbsLoading: boolean = true;
  isBreadcrumbTitleSource: boolean = false;
  breadcrumb: BreadCrumb;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configFacade: ConfigurationFacade,
    private seoFacade: SeoFacade,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.breadcrumb = this.getHomePageForBreadcrumbs();
    this.setBreadcrumbs();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setBreadcrumbs(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (route) => {
      this.currentRoute = route as NavigationEnd;
      this.displayBreadCrumbs = false;
      this.breadcrumbsLoading = true;
      const root: ActivatedRoute = this.route.root;
      this.breadcrumbs = [this.getHomePageForBreadcrumbs(), ...this.configFacade.getBreadcrumbs(root)];
      const hasBreadcrumbs = this.configFacade.getBreadcrumbsParam(this.route, ERouteDataTypes.SHOW_DATA_BREADCRUMB) as boolean;
      this.displayBreadCrumbs = hasBreadcrumbs;
      this.isBreadcrumbTitleSource =
        this.configFacade.getBreadcrumbsParam(this.route, ESeoData.seoTitleSource) === ETitleSources.BREADCRUMBS;
      if (hasBreadcrumbs) {
        this.updateBreadcrumbsParams(this.breadcrumbs, route as NavigationEnd).then(() => {
          if (this.currentRoute === route) {
            this.displayBreadCrumbs = this.breadcrumbs.length > 1 ? hasBreadcrumbs : false;
            this.breadcrumbsLoading = this.breadcrumbsUpdating;
            if (this.isBreadcrumbTitleSource) {
              this.setTitleFromBreadcrumbs();
            }
          }
        });
      } else {
        this.seoFacade.removeStructuredData(BREADCRUMB_SCHEME_CLASS);
      }
    });
  }

  async updateBreadcrumbsParams(breadcrumbs: BreadCrumb[], route: NavigationEnd): Promise<any> {
    this.breadcrumbsUpdating = true;
    if (this.currentRoute === route) {
      for (const breadcrumb of breadcrumbs) {
        switch (breadcrumb.param) {
          case 'translateKey':
            await this.translateBreadcrumb(breadcrumbs, breadcrumb, route);
            break;
          case 'sku':
            await this.updateBreadcrumbWithCategoryTree(breadcrumbs, breadcrumb, route);
            break;
          default:
            this.updateBreadcrumb(breadcrumbs, route);
            break;
        }
      }
    } else {
      this.breadcrumbsUpdating = false;
    }
  }

  async translateBreadcrumb(breadcrumbs: any[], breadcrumb:any, route:any): Promise<any> {
    this.breadcrumbsUpdating = true;
    await firstValueFrom(this.configFacade.getTranslationByKey(breadcrumb.label)).then(
      (val) => {
        if (this.currentRoute === route) {
          breadcrumb.label = val;
          this.breadcrumbs = [...breadcrumbs];
        }
        this.breadcrumbsUpdating = false;
      }
    );
  }

  async updateBreadcrumbWithCategoryTree(breadcrumbs: any[], breadcrumb:any, route:any): Promise<any> {
    if (breadcrumb.label.match(/A_[a-zA-Z0-9]/)) {
      this.breadcrumbsUpdating = true;
      await this.configFacade.getProductWithCategoryTree(breadcrumb.label)
        .then(response => {
          if (this.currentRoute === route) {
            const categoryTree = response.included.filter(category => category.id > 2).sort((a, b) => +b.id - (+a.id)).reverse();
            const productName = response.data.attributes.name;
            const catBreadcrumb = this.getOneFromMultiCategoryBreadcrumbs(
              this.getMultiCategoriesBreadcrumbs(categoryTree)
            );
            breadcrumb.label = productName;
            this.breadcrumbs = breadcrumbs.slice(0, 1).concat(catBreadcrumb, breadcrumbs.slice(1));
          }
          this.breadcrumbsUpdating = false;
        })
        .catch(() => {
          this.breadcrumbsUpdating = false;
        });
    }
  }

  getMultiCategoriesBreadcrumbs(categoryTree): [][] {
    let multiCategoryBreadcrumbsOptions = [];
    categoryTree.forEach((category) => {
      let categoryIsUsed = false;
      let lastUsedNodeId = 0;
      multiCategoryBreadcrumbsOptions.forEach((option,index) => {
        option.forEach((catBreadcrumb, i) => {
          if (catBreadcrumb.children.find(child => child.nodeId === category.attributes.nodeId) !== undefined) {
            if (i + 1 === option.length) {
              multiCategoryBreadcrumbsOptions[index].push(this.createCategoryBreadcrumb(category));
            } else if (lastUsedNodeId != catBreadcrumb.nodeId) {
              let newCatBreadcrumb = option.slice(0, i + 1);
              newCatBreadcrumb.push(this.createCategoryBreadcrumb(category));
              multiCategoryBreadcrumbsOptions.push(newCatBreadcrumb);
            }
            lastUsedNodeId = catBreadcrumb.nodeId;
            categoryIsUsed = true;
          }
        });
      });
      if (!categoryIsUsed) {
        multiCategoryBreadcrumbsOptions.push([this.createCategoryBreadcrumb(category)])
      }
    });
    return multiCategoryBreadcrumbsOptions;
  }

  createCategoryBreadcrumb(category): BreadCrumb {
    return {
      label: category.attributes.name,
      url: `catalog/${StringUtils.toLowerCaseWithoutWhiteSpaceOrSlash(category.attributes.name, '-')}`,
      param: '',
      breadcrumbDisabled: false,
      children: category.attributes.children,
      nodeId: category.attributes.nodeId,
    };
  }

  getOneFromMultiCategoryBreadcrumbs(multiCategoryBreadcrumbsOptions): BreadCrumb[] {
    let lastPage = LocalStorageUtils.getKeyValue('previousPage');
    for (let option of multiCategoryBreadcrumbsOptions) {
      for (let catBreadcrumb of option) {
        if (lastPage && lastPage.includes(catBreadcrumb.url)) {
          return option;
        }
      }
    }
    return multiCategoryBreadcrumbsOptions.length > 0 ? multiCategoryBreadcrumbsOptions[0] : [];
  }

  updateBreadcrumb(breadcrumbs: any[], route:any): void {
    if (this.currentRoute === route) {
      this.breadcrumbs = [...breadcrumbs];
    }
    this.breadcrumbsUpdating = false;
  }

  getHomePageForBreadcrumbs(): BreadCrumb {
    const homeRouteConfig = this.router.config.find(route => route.path === ':lang/.');
    const homeParams = homeRouteConfig.data[ERouteDataTypes.ROUTE_DATA_BREADCRUMB].split('=');
    return {
      label: homeParams[1],
      url: homeRouteConfig.path.replace(':lang', this.i18nService.getCurrentLocale()),
      param: homeParams[0].replace(':', ''),
      breadcrumbDisabled: false,
    };
  }

  setTitleFromBreadcrumbs(): void {
    this.seoFacade.handleTitleFromBreadcrumb(this.breadcrumbs);
  }

  breadcrumbLabelDecoded(label): string {
    return decodeURIComponent(label);
  }
}
