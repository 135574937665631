import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

import { SearchFieldPipe } from '../pipes/search-field.pipe';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['dropdown.component.scss'],
  providers: [SearchFieldPipe],
})

export class DropdownComponent implements OnInit {
  active: boolean;
  defaultCssStyles: object = {
    toggle: '',
    dropdown: '',
  };
  selectedOptions: string[] = [];
  savedOptions: string[] = [];
  searchValue: string;
  selectAll = false;

  @Input() cssStyles: any;
  @Input() dropDownName = '';
  @Input() menuDirection = 'right';
  @Input() hideOnMenuClick = true;
  @Input() keepActive = false;
  @Input() isCheckbox = false;
  @Input() checkboxOptions?: string[] = [];
  @Input() checkboxName?: string;
  @Output() filters = new EventEmitter<string[]>();

  constructor(
    private dropdown: ElementRef,
    private searchFieldPipe: SearchFieldPipe) {
  }

  ngOnInit(): void {
    this.active = false;
    this.cssStyles = {...this.defaultCssStyles, ...this.cssStyles};
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  select(): void {
    if (this.hideOnMenuClick) {
      this.active = !this.active;
    }
  }

  selectOption(option: string): void {
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
    }
  }

  selectAllFilteredOptions(): void {
    if (!this.selectAll) {
      this.selectAll = true;
      const filteredOptions: string[] = this.searchFieldPipe.transform(this.checkboxOptions, this.searchValue);
      filteredOptions.forEach((option) => {
        this.selectedOptions.push(option);
      });
    } else {
      this.selectAll = false;
      this.selectedOptions = [];
    }
  }

  applyFilters(): void {
    this.savedOptions = [...this.selectedOptions];
    this.filters.emit(this.savedOptions);
    this.active = false;
  }

  cancel(): void {
    this.active = false;
    this.resetOptions();
  }

  resetOptions(): void {
    this.selectedOptions = [...this.savedOptions];
  }

  selectAndApply(option: string): void {
    this.selectOption(option);
    this.applyFilters();
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event): void {
    if (this.keepActive) {
      return;
    }
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.active = false;

      if (this.checkboxOptions?.length) {
        this.resetOptions();
      }
    }
  }
}
