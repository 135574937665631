import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IAddress, ISelectEvent } from '../../models/common.models';
import { ISelectSoldTo, ISoldToToISelectSoldTo } from '../../models/soldTo-selection.models';
import { ICart } from '../../models/cart.models';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modal-select-sold-to-account',
  templateUrl: './modal-select-sold-to-account.component.html',
  styleUrls: ['./modal-select-sold-to-account.component.scss'],
})
export class ModalSelectSoldToAccountComponent implements OnInit, OnChanges {
  @Input() showModalConfirm: boolean;
  @Input() currentCart: ICart;
  @Input() soldToAccounts: IAddress[];
  @Input() isEquipmentSelectionPage: boolean = false;
  @Input() isMissingSoldTo: boolean = false;

  @Output() closeModalConfirm = new EventEmitter<any>();
  @Output() primaryActionConfirm = new EventEmitter<any>();
  @Output() updatedPricesAfterChangeSoldTo = new EventEmitter<any>();

  modalTitle: string = 'select-sold-to.title';
  notifyText: string = 'select-sold-to.paragraph';
  cancelBtnText: string = 'select-sold-to.cancel';
  primaryBtnText: string = 'select-sold-to.confirm';

  contactDetailsForm: UntypedFormGroup;

  remappedSoldToAccounts: ISelectSoldTo[];
  changeSoldTo: boolean = false;
  disableConfirmButton: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
  ) {
  }

  cancelOnClick(): void {
    this.closeModalConfirm.emit();
  }

  ngOnInit(): void {
    this.remappedSoldToAccounts = this.soldToAccounts?.map(item => ISoldToToISelectSoldTo(
      item,
      this.translate.instant('spare-parts-order-details-page.missing-default-fl-sold-to-address')
    ));    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.remappedSoldToAccounts = this.soldToAccounts?.map(item => ISoldToToISelectSoldTo(
      item,
      this.translate.instant('spare-parts-order-details-page.missing-default-fl-sold-to-address')
    ));
    this.initializeForm();
  }

  private initializeForm(): void {
    const soldTo = !!this.currentCart?.attributes?.soldToAddress?.sapId && !this.isEquipmentSelectionPage
      ? this.soldToAccounts?.find(item => item.sapId === this.currentCart.attributes.soldToAddress.sapId)
      : this.soldToAccounts?.length > 0 ?
        this.soldToAccounts[0]
        : null;
    const selectSoldTo =  !!soldTo ? ISoldToToISelectSoldTo(
      soldTo,
      this.translate.instant('spare-parts-order-details-page.missing-default-fl-sold-to-address')
    ) : null;

    this.disableConfirmButton = selectSoldTo ? !selectSoldTo?.correct : false;
    this.contactDetailsForm = this.formBuilder.group({
      soldTo: [
        selectSoldTo,
        [Validators.required],
      ],
    });
  }

  primaryActionOnClick(): void {
    this.updatedPricesAfterChangeSoldTo.emit(this.changeSoldTo);
    this.changeSoldTo = false;
    this.primaryActionConfirm.emit(this.contactDetailsForm.value.soldTo);
    this.closeModalConfirm.emit();
  }

  setSoldTo(event: ISelectEvent): void {
    this.changeSoldTo = true;
    this.disableConfirmButton = !event?.correct;
    this.contactDetailsForm.patchValue({
      [event.key]: {id: event.id, name: event.name, value: event.value},
    });
  }
}
