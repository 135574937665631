import { Component, OnInit } from '@angular/core';
import { EStoreFeatures } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-page-shop-cart',
  templateUrl: './page-shop-cart.component.html',
  styleUrls: []
})
export class PageShopCartComponent implements OnInit {
  isMultiCartActive: boolean = false;
  isExportCartActive: boolean = false;

  constructor(
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isMultiCartActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.MULTI_CART);
    this.isExportCartActive = this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXPORT_CART);
  }
}
