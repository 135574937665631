import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-store-selection-map',
  templateUrl: './store-selection-map.component.html',
  styleUrls: ['./store-selection-map.component.scss'],
})
export class StoreSelectionMapComponent {

  @Input()
  stores: any[];

  redirect(store): void {
    window.location.href = `${window.location.origin}/${store}`;
  }
}
