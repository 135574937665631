<div class="configuration-steps-bar">
  <div
      class="step-item"
      [ngClass]="{'current': currentStep === i, 'completed': currentStep > i}"
      *ngFor="let step of steps; index as i">
    <div class="step-item__number">
      <div class="step">
      </div>
      <div class="step-progress" [attr.data-test]="'link-step' + step.value"></div>
    </div>
    <p
      class="step-item__title"
      (click)="selectStep.emit(i)">
      {{ step.value }}
    </p>
  </div>
</div>
