import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MarketingFacade } from '../../facades/marketing.facade';
import * as ValidationPatterns from '../../configurations/validations';
import { MathUtils } from '../../utils/math.utils';

@Component({
  selector: 'app-modal-add-cart-name',
  templateUrl: './modal-add-cart-name.component.html',
  styleUrls: ['./modal-add-cart-name.component.scss'],
})
export class ModalAddCartNameComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  maxNameLength = 30;
  minNameLength = 3;
  savingNickNameInProgress = false;

  private lastRetrievedValue = '';
  private unsubscribe$ = new Subject<void>();

  @Input() isContractInCart = true;
  @Input() showAddNicknameModal: boolean;
  @Input() hasNickname = false;
  @Input() cartId: string;
  @Input() newNameChange = new ReplaySubject<string>();
  @Output() closeModal = new EventEmitter<any>();

  constructor(
    private marketingFacade: MarketingFacade,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.checkIsCartOperationInProgress();
    this.checkForNameChanged();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveNewNickname(): void {
    if (this.form.value.name.length > 0) {
      if (MathUtils.checkIfNumeric(this.form.value.name)) {
        return;
      }
      const nameLength = this.form.value?.name.length;
      if (nameLength < this.minNameLength || nameLength > this.maxNameLength) {
        return;
      }
    }
    this.marketingFacade.updateCartById(
      this.cartId,
      {name: this.form.value.name ? this.form.value.name : new Date().getTime()},
    );
    this.emitCloseModalEvent();
  }

  validateField(name: string, errorCode: string): boolean {
    return this.form.get(name).touched && this.form.get(name).hasError(errorCode);
  }

  emitCloseModalEvent(): void {
    this.initializeForm();
    this.closeModal.emit();
  }

  resetNickname(): void {
    this.form.patchValue({
      name: '',
    });
  }

  private initializeForm(): void {
    this.form = this.formBuilder.group({
      name: [
        this.lastRetrievedValue,
        [
          Validators.required,
          Validators.maxLength(this.maxNameLength),
          Validators.minLength(this.minNameLength),
          ValidationPatterns.noEmptySpace,
          ValidationPatterns.valueHasAtLeastOneLetter,
        ],
      ],
    });
  }

  private checkIsCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(result => {
      this.savingNickNameInProgress = result;
    });
  }

  private checkForNameChanged(): void {
    this.newNameChange.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(newName => {
      this.lastRetrievedValue = newName;
      this.form.patchValue({
        name: newName,
      });
    });
  }
}
