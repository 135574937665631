import { createAction, props } from '@ngrx/store';
import { ICpqOrdersResponse, IOrder, IOrdersSapDetails } from '../models/order.models';

export const OrdersWithSapDetailsStartDataAction = createAction(
  '[Orders with details] - Start get orders with sap details Action',
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);

export const SuccessGetOrdersWithSapDetailsDataAction = createAction(
  '[Orders with details] - Success Get orders with sap details data Action',
  props<{ordersWithSapDetails: IOrdersSapDetails}>(),
);

export const OrdersWithSapDetailsClearData = createAction(
  '[Orders with details] Clear list orders data',
);

export const SuccessUpdateOrdersWithSapDetailsDataAction = createAction(
  '[Orders with details] - Success Update orders with sap details data Action',
  props<{orders: IOrder[], sapOrdersDetails: IOrder[]}>(),
);

export const CpqOrdersUnderReviewStartDataAction = createAction(
  '[CPQ Orders] - Start get cpq orders under review Action',
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);

export const CpqOrdersFulfilledStartDataAction = createAction(
  '[CPQ Orders] - Start get cpq orders fulfilled Action',
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);

export const SuccessGetCpqOrdersUnderReviewDataAction = createAction(
  '[CPQ Orders] - Success Get cpq orders under review data Action',
  props<{cpqOrders: ICpqOrdersResponse}>(),
);

export const SuccessGetCpqOrdersFulfilledDataAction = createAction(
  '[CPQ Orders] - Success Get cpq orders fulfilled data Action',
  props<{cpqOrders: ICpqOrdersResponse}>(),
);

export const OrdersWithCartsStartDataAction = createAction(
  '[Orders-with-carts] - Start get orders with carts Action',
);

export const SuccessGetOrdersWithCartsDataAction = createAction(
  '[Orders-with-carts] - Success Get orders with carts data Action',
  props<{carts: any}>(),
);

export const OrdersWithCartApprovalStartDataAction = createAction(
  '[Orders-with-cart-approval] - Start get orders with cart approval Action',
);

export const SuccessGetCartOrdersWithApprovalDataAction = createAction(
  '[Orders-with-cart-approval] - Success Get orders with cart approval data Action',
  props<{carts: any}>(),
);


export const OrdersHistoryStartDataAction = createAction(
  '[Orders-history] - Start get orders history data Action',
  (offset: number = undefined, limit: number = undefined) => ({offset, limit}),
);

export const SuccessGetOrdersHistoryDataAction = createAction(
  '[Orders-with-carts] - Success Get orders history data Action',
  props<{ordersHistory: any}>(),
);

export const OrdersHistoryClearDataAction = createAction(
  '[Orders-history] Clear list orders history data action',
);

