<form [formGroup]="wrongAddressForm" (ngSubmit)="reportAddress()" *ngIf="showModal">
  <app-modal
      [attr.data-test]="'button-reportWrongAddress'"
      [open]="showModal"
      [id]="'wrongAddressModal'"
      title="{{ 'checkout-delivery-details.report-wrong-address' | translate }}"
      (crossClick)="resetForm()"
      (outsideClick)="resetForm()">
    <ng-container ngProjectAs="[slot][body]">
      <div class="loader__overlay" *ngIf="loading">
        <div class="loader__content">
          <div class="loading-animation"></div>
        </div>
      </div>
      <div class="row">
        <app-select-box
            class="col-12"
            [form]="wrongAddressForm"
            [modelValue]="wrongAddressForm.value.deliveryAddress"
            [options]="addresses"
            [inputName]="'deliveryAddress'"
            label="{{ (cpqLabelName ? cpqLabelName : 'checkout-delivery-details.delivery-address') | translate }}"
            [isRequired]="true"
            [readOnly]="addresses?.length === 1"
            [loading]="addressesLoading"
            (selected)="selectWrongAddress($event)">
        </app-select-box>
      </div>
      <div class="row">
        <app-textarea
            class="col-12"
            [form]="wrongAddressForm"
            [modelValue]="wrongAddressForm.value.comment"
            [rows]="3"
            [cssStyles]="{wrapper: 'text-area-comment'}"
            [inputName]="'comment'"
            [maxLength]="maxLengthComment"
            label="{{ 'checkout-delivery-details.comment' | translate }}"
            [isRequired]="true"
            trim
            trimWhitespaces
            (areaFilled)="setCommentWrongAddress($event.value)">
        </app-textarea>
        <div class="form-alerts">
          <ng-container *ngIf="wrongAddressForm.value.comment">
            <small class="textfield__hint">
              {{ wrongAddressForm.value.comment.length }}/{{ maxLengthComment }}
            </small>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!cpqOnly">
        <p *ngIf="roleSpecificMessage; else roleSpecificBlock">
          {{ 'checkout-delivery-details.wrong-address-notification' | translate }}
        </p>
        <ng-template #roleSpecificBlock>
          <p *ngIf="!isApprover(); else approverBlock">
            {{ 'checkout-delivery-details.wrong-address-notification-buyer' | translate }}
          </p>
          <ng-template #approverBlock>
            {{ 'checkout-delivery-details.wrong-address-notification-approver' | translate }}
          </ng-template>
        </ng-template>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
          type="reset"
          class="button button"
          data-dismiss="modal-dialog"
          (click)="resetForm()">
        {{ 'checkout-delivery-details.cancel' | translate }}
      </button>
      <button
          type="submit"
          class="button button--primary"
          data-dismiss="modal-dialog"
          [disabled]="!formIsValid()"
          (click)="showLoader()">
        {{ 'checkout-delivery-details.send-wrong-address' | translate }}
      </button>
    </ng-container>
  </app-modal>
</form>
