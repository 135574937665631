<div class="container display-flex justify-content-center f-align-items-center" *ngIf="numberOfPages > 1">
  <div class="pagination">
    <a *ngIf="currentPage > 1" 
       class="pagination__item" 
       [routerLink]="pageUrl()"
       [queryParams]="queryParams(currentPage - 1)">
        <span class="icon-arrow-left"></span>
    </a>
    <ng-container *ngIf="showFirstPageWithDots()">
      <a class="pagination__item"
         [routerLink]="pageUrl()" 
         [queryParams]="queryParams(1)">
          1
      </a>
      <span class="mx">...</span>
    </ng-container>
    <ng-container *ngFor="let page of showedPages">
      <a class="pagination__item"
         [ngClass]="{'is-active': currentPage === page}"
         [ngStyle]="{'width': getPaginationItemWidth(page)}"
         [routerLink]="pageUrl()" 
         [queryParams]="queryParams(page)">
          {{ page }}
      </a>
    </ng-container>
    <ng-container *ngIf="showLastPageWithDots()">
      <span class="mx">...</span>
      <a class="pagination__item"
         [ngStyle]="{'width': getPaginationItemWidth(numberOfPages)}"
         [routerLink]="pageUrl()" 
         [queryParams]="queryParams(numberOfPages)">
          {{ numberOfPages }}
      </a>
    </ng-container>
    <a *ngIf="currentPage < numberOfPages" 
       class="pagination__item" 
       [routerLink]="pageUrl()"
       [queryParams]="queryParams(currentPage + 1)">
        <span class="icon-arrow-right"></span>
    </a>
  </div>
</div>
