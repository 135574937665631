import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, Subject, take, takeUntil } from 'rxjs';

import { ICmsPage, ICmsPageAttributes } from '../../models/cms';
import { CmsFacade } from '../../facades/cms.facade';
import { ISeoPageData } from '../../configurations/seo';
import { SeoFacade } from '../../facades/seo.facade';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page-cms',
  templateUrl: './page-cms.component.html',
  styleUrls: ['./page-cms.component.scss'],
})
export class PageCmsComponent implements OnInit, OnDestroy {
  isLoaded: boolean;
  pageUuid: string;
  cmsPageData: ICmsPage;
  excludedPage = environment.homePageBannerKey;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private cmsFacade: CmsFacade, 
    private seoFacade: SeoFacade,
  ) {
  }

  ngOnInit(): void {
    this.setCmsPage();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe((currentRouteEvent: RouterEvent) => {
      if (!currentRouteEvent.url.includes(this.pageUuid)) {
        this.setCmsPage();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCmsPage(): void {
    this.pageUuid = this.activatedRoute.snapshot.params.uuid;
    if (!this.pageUuid || (this.pageUuid && this.pageUuid === this.excludedPage)) {
      this.navigateTo404();
    }
    this.getCmsPageData();
  }

  getCmsPageData(): void {
    this.isLoaded = false;
    this.cmsFacade.getCmsPageData(this.pageUuid)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.cmsPageData = response;
          this.checkValidToDate(response.attributes.validTo);
          this.setMetaData(response.attributes);
          this.isLoaded = true;
        }, 
        error: () => {
          this.navigateTo404();
        }
      });
  }

  setMetaData(data: ICmsPageAttributes): void {
    this.seoFacade.setMetaFromComponent({
      description: data.metaDescription,
      keywords: data.metaKeywords,
      titles: [data.metaTitle],
      canonicalUrl: {
        rel: 'canonical',
        href: data.canonicalUrl
      },
      robots: this.seoFacade.parseRobots(data)
    } as ISeoPageData);
  }

  checkValidToDate(validTo: any): void {
    if (validTo) {
      const currentDate: Date = new Date();
      const date: Date = new Date(validTo);

      if (date < currentDate) {
        this.navigateTo404();
      }
    }
  }

  navigateTo404(): void {
    this.router.navigate(['/page-not-found']).then();
  }
}
