<app-accordion>
  <ng-container ngProjectAs="[slot][header]">
    <h2 id="panel-address-button"
        class="accordion__title icon-arrow-down" aria-controls="panel1"
        aria-expanded="false">
      {{ 'service-purchase-activity.address-information' | translate }}
    </h2>
  </ng-container>
  <ng-container ngProjectAs="[slot][panel]">
    <div class="container accordion__content">
      <table class="additional-details__table">
        <caption>{{ 'service-purchase-activity.address-information' | translate }}</caption>
        <thead>
          <tr>
            <th scope="col">{{ 'thank-you-page.sold-address' | translate }}</th>
            <th scope="col">{{ 'thank-you-page.bill-address' | translate }}</th>
            <th scope="col">{{ 'thank-you-page.payer-address' | translate }}</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            {{additionalData.attributes.soldToAddress.company}}<br />
            {{additionalData.attributes.soldToAddress.address1}}<br />
            {{additionalData.attributes.soldToAddress.city}}, {{additionalData.attributes.soldToAddress.zipCode}}
          </td>
          <td>
            {{additionalData.attributes.billingAddress.company}}<br />
            {{additionalData.attributes.billingAddress.address1}}<br />
            {{additionalData.attributes.billingAddress.city}}, {{additionalData.attributes.billingAddress.zipCode}}
          </td>
          <td>
            {{additionalData.attributes.payerAddress.company}}<br />
            {{additionalData.attributes.payerAddress.address1}}<br />
            {{additionalData.attributes.payerAddress.city}}, {{additionalData.attributes.payerAddress.zipCode}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</app-accordion>
<app-accordion>
  <ng-container ngProjectAs="[slot][header]">
    <h2 id="panel-functional-button"
        class="accordion__title icon-arrow-down" aria-controls="panel1"
        aria-expanded="false">
      {{ 'service-purchase-activity.functional-location' | translate }}
    </h2>
  </ng-container>
  <ng-container ngProjectAs="[slot][panel]">
    <div class="container accordion__content">
      <table class="additional-details__table">
        <caption>{{ 'service-purchase-activity.functional-location' | translate }}</caption>
        <thead>
        <tr>
          <ng-container *ngFor="let flHeader of functionalLocationTemplate">
            <th scope="col" class="width-{{flHeader.width}}">
              {{ flHeader.label | translate }}
            </th>
          </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fl of flData">
          <ng-container *ngFor="let flHeader of functionalLocationTemplate; index as i">
            <td *ngIf="flHeader.propName !== 'price' else priceColumn" class="width-{{flHeader.width}} {{i === 0 ? 'functional-location-id' : ''}}">{{fl[flHeader.propName]}}</td>
            <ng-template #priceColumn>
              <td class="width-{{flHeader.width}}">{{fl[flHeader.propName] | currency: additionalData.attributes.currencyIsoCode }}</td>
            </ng-template>
          </ng-container>
        </tr>
        </tbody>
        <tfoot>
          <tr>
            <td [colSpan]="functionalLocationTemplate.length - 1" class="total-price">{{ 'service-purchase-activity.total-price' | translate }}</td>
            <td>{{ additionalData.attributes.totals.grandTotal | currency: additionalData.attributes.currencyIsoCode }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </ng-container>
</app-accordion>
