<div class="order-thank-you-page">
  <ng-container>
    <div class="order-thank-you-page__content">
      <div class="order-thank-you-page-top">
        <div class="order-thank-you-page__container">
          <div class="order-thank-you-page__header">
            <div class="order-thank-you-page__header--details">
              <h4 [attr.data-test]="'title-thankYou'">{{ 'order-thank-you-page.title' | translate }}</h4>
              <p class="description" [attr.data-test]="'title-yourOrderHasBeenReceived'">
                {{ 'order-thank-you-page.description' | translate: {orderNumber: order.orderNumber | removeLeadingZero } }}
              </p>
            </div>
          </div>
          <app-sap-message-controller [messages]="this.orderData?.sapMessages"></app-sap-message-controller>
        </div>
        <div class="order-thank-you-page-question-side">
          <div class="order-thank-you-page-question-side__header">
            <h4 [attr.data-test]="'title-haveQuestions'">{{ 'order-thank-you-page.question.title' | translate }}</h4>
            <p class="description" [attr.data-test]="'desc-haveQuestions'">
              {{ 'order-thank-you-page.question.description' | translate }}
            </p>
          </div>
          <div class="order-thank-you-page-question-side__contact">
            <div>
              <p class="question-contact-phone" [attr.data-test]="'desc-haveQuestions-phone'">
                <span>{{ 'order-thank-you-page.question.phone-title' | translate }}</span>
                {{ 'order-thank-you-page.question.phone' | translate }}
              </p>
            </div>
            <div>
              <span [attr.data-test]="'desc-sendQuestionEmail'">{{ 'order-thank-you-page.question.email-title' | translate }}</span>
              <div class="question-contact-email">
                <a href="mailto:{{questionEmail}}" [attr.data-test]="'link-sendQuestionEmail'">{{ questionEmail }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-table">
        <h5>{{ 'order-thank-you-page.ordered-items' | translate }}</h5>
        <div class="ordered-items">
          <app-accordion [manualOpen]="orderedItemsAccordion">
            <ng-container ngProjectAs="[slot][header]">
              <div class="accordion__header accordion__title icon-arrow-down" (click)="toggleOrderedItemsAccordion()" [attr.data-test]="'desc-toggleOrderedItemsAccordion'">
                <div class="accordion__header__table">
                  <table class="accordion__header__table--ordered-items">
                    <thead>
                      <tr>
                        <th scope="col" [attr.data-test]="'label-orderNumber'">{{ 'order-thank-you-page.order' | translate }}</th>
                        <th scope="col" [attr.data-test]="'label-orderedOn'">{{ 'order-thank-you-page.ordered-on' | translate }}</th>
                        <th scope="col" [attr.data-test]="'label-totalQuantity'">{{ 'order-thank-you-page.total-quantity' | translate }}</th>
                        <th class="text-align-right" scope="col" [attr.data-test]="'label-shippingAndHandlingCost'">{{ 'order-thank-you-page.shipping-handling-cost' | translate }}</th>
                        <th class="text-align-right" scope="col" [attr.data-test]="'label-totalSum'">{{ 'order-thank-you-page.total-sum' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="orderData">
                        <td [attr.data-test]="'desc-orderNumber'">
                          {{ order.orderNumber | removeLeadingZero }}
                        </td>
                        <td [attr.data-test]="'desc-orderedOn'">
                          {{ orderData.createdAt | formatDate }}
                        </td>
                        <td [attr.data-test]="'desc-totalQuantity'">
                          {{ orderData.itemsTotalNumber }} {{ getTotalItemTranslationValue(orderData.itemsTotalNumber) | translate }}
                        </td>
                        <td class="text-align-right" *ngIf="orderData.shipments && orderData.shipments.length > 0" [attr.data-test]="'desc-shippingAndHandlingCost'">
                          {{ orderData.shipments[0].defaultGrossPrice | currency:orderData.currencyIsoCode }}
                        </td>
                        <td class="text-align-right" *ngIf="orderData.totals" [attr.data-test]="'desc-totalSum'">
                          {{ orderData.totals.grandTotal | currency: orderData.currencyIsoCode }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
            <ng-container ngProjectAs="[slot][panel]">
              <div class="container accordion__content">
                <div class="row">
                  <div class="col-12 col-lg-8 items-border">
                    <ng-container *ngFor="let item of orderData?.items">
                      <div class="item-border">
                        <div class="row item">
                          <div class="col-12 col-lg-2">
                            <img src="{{ item.metadata.image }}" alt="n/a" [attr.data-test]="'img-productImage'">
                          </div>
                          <div class="col-12 col-lg-10">
                            <div class="row">
                              <div class="col-12 item--container">
                                <h6 class="item--name" [attr.data-test]="'desc-item-name'">{{ item.name }}</h6>
                                <span *ngIf="item.sapDetails && item.sapDetails.exchangeableItem"
                                      class="label item--exchangeable-flag" [attr.data-test]="'desc-flag-exchangeable'">{{ 'order-thank-you-page.exchangeable-item' | translate }}
                                </span>
                                <span *ngIf="item.heavyweight"
                                      class="label heavyweight-item" [attr.data-test]="'desc-flag-heavyweight'">{{ 'order-thank-you-page.heavyweight-item' | translate }}
                                </span>
                              </div>
                              <div class="col-12">
                                <p [attr.data-test]="'desc-item-sku'">{{ item.sku }}</p>
                              </div>
                            </div>
                            <div class="row item--details">
                              <div class="col-12 col-lg-6">
                                <p [attr.data-test]="'label-item-quantity'">{{ 'order-thank-you-page.quantity' | translate }}</p>
                                <p [attr.data-test]="'label-item-totalPrice'">{{ 'order-thank-you-page.total-price' | translate }}</p>
                              </div>
                              <div class="col-12 col-lg-6">
                                <p class="text-align-right" [attr.data-test]="'desc-item-quantity'">{{ item.quantity }}</p>
                                <p class="text-align-right item--details-price" [attr.data-test]="'desc-item-totalPrice'">{{ item.sumPrice | currency: orderData.currencyIsoCode }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-12 col-lg-4 address">
                    <div *ngIf="orderData && orderData.shippingAddress" [attr.data-test]="'desc-deliveryAddress'">
                      <p class="address--title">{{ 'order-thank-you-page.delivery-address' | translate }}</p>
                      <p *ngIf="orderData.pointOfContact.attentionTo">{{ orderData.pointOfContact.attentionTo }}</p>
                      <span *ngIf="orderData.shippingAddress?.sapId">{{ orderData.shippingAddress.sapId }} - </span>
                      <span *ngIf="orderData.shippingAddress.company">{{ orderData.shippingAddress.company }}</span>
                      <p>{{ orderData.shippingAddress.address1 }}</p>
                      <p *ngIf="orderData.shippingAddress.address2 !== 'empty'">{{ orderData.shippingAddress.address2 }}</p>
                      <p *ngIf="orderData.shippingAddress.address3">{{ orderData.shippingAddress.address3 }}</p>
                      <p>{{ orderData.shippingAddress.zipCode }} {{ orderData.shippingAddress.city }} {{ orderData.shippingAddress.state }}</p>
                    </div>
                    <div class="address__container"
                         *ngIf="orderData.shipments && orderData.shipments.length > 0">
                      <p class="address--title" [attr.data-test]="'label-shippingMethod'">{{ 'order-thank-you-page.shipping-method' | translate }}</p>
                      <p [attr.data-test]="'desc-shippingMethod'">{{ orderData.shipments[0].shipmentMethodName }}</p>
                    </div>
                    <div class="address__container">
                      <div class="address__container-order-info" [attr.data-test]="'desc-orderInfo'">
                        <div class="col-7 no-padding">
                          <strong class="uppercase" [attr.data-test]="'label-purchaseOrder'">{{ 'order-thank-you-page.purchase-order' | translate }}</strong>
                        </div>
                        <div class="col-5 no-padding">
                          <span [attr.data-test]="'desc-purchaseOrder'">{{ orderData.sapPoNumber }}</span>
                        </div>
                      </div>
                      <div class="address__container-order-info order-number-container" *ngIf="hasExchangableItem">
                        <div class="col-7 no-padding">
                          <strong class="uppercase" [attr.data-test]="'label-returnOrderNumber'">{{ 'order-thank-you-page.return-order-no' | translate }}</strong>
                        </div>
                        <div class="col-5 no-padding">
                          <span *ngIf="orderData.sapDetails" [attr.data-test]="'desc-returnOrderNumber'">{{ orderData.sapDetails.returnAuthorizationNumber }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="address__container" *ngIf="hasExchangableItem">
                      <button
                          type="button"
                          class="download-documentation"
                          (click)="downloadDocumentation()"
                          [attr.data-test]="'button-downloadDocumentation'">
                        <span class="icon-arrow-right"></span> {{ 'order-thank-you-page.download-document' | translate }}
                      </button>
                    </div>
                    <span class="submit-return-doc">
      <a class="submit-return-doc__link" *ngIf="orderData.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable"
         [attr.data-test]="'link-submitReturnDocument'"
         href="mailto: {{ 'service-purchase-activity.return-document-recipient-email' | translate }}
       ?Subject=Return Document {{ orderData.sapDetails.returnAuthorizationNumber }}">
        <span class="icon-arrow-right"></span> {{ 'service-purchase-activity.submit-email-document' | translate }}
      </a>
      <a class="submit-return-doc__msg" *ngIf="orderData.sapDetails?.returnAuthorizationNumber && isReturnableDocumentEmailLinkEnable">
        <div>
          {{ 'service-purchase-activity.submit-return-document-text-line1' | translate }}<br>
          {{ 'service-purchase-activity.submit-return-document-text-line2' | translate }}
        </div>
     </a>
    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </app-accordion>
        </div>
      </div>
      <div class="order-thank-you-page-bottom">
        <div class="action-buttons">
          <button type="button" class="button button--secondary" [routerLink]="['/catalog/parts']" [attr.data-test]="'button-continueShopping'">
            {{ 'multi-cart.continue-shopping' | translate }}
          </button>
          <button type="submit" class="button button--primary " [routerLink]="['/purchase-activity']" [attr.data-test]="'button-viewPurchaseActivity'">
            {{ 'multi-cart.view-purchase-activity' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
