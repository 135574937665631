export const layout = {
  'default': [
    {
      'class': 'col-12',
      'order': 'order-0',
    },
    {
      'class': 'col-sm-8',
      'order': 'order-1',
    },
    {
      'class': 'col-sm-4',
      'order': 'order-2',
    },
    {
      'class': 'col-md-6',
      'order': 'order-3',
    },
    {
      'class': 'col-md-6',
      'order': 'order-4',
    },
    {
      'class': 'col-12',
      'order': 'order-5',
    },
  ],
  'JP': [
    {
      'class': 'col-12',
      'order': 'order-5',
    },
    {
      'class': 'col-sm-7',
      'order': 'order-4',
    },
    {
      'class': 'col-sm-4',
      'order': 'order-1',
    },
    {
      'class': 'col-md-5',
      'order': 'order-3',
    },
    {
      'class': 'col-md-8',
      'order': 'order-2',
    },
    {
      'class': 'col-12',
      'order': 'order-0',
    },
  ],
};
