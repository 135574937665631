<div class="guided-questions">
  <app-contract-steps-bar [stepNo]="1"></app-contract-steps-bar>
  <h4 class="progress__title">{{ 'guided-questions.progress-bar-title' | translate }}</h4>
  <app-progress
      *ngIf="currentQuestion"
      [showPercentProgress]="false"
      [current]="currentQuestionIndex + 1"
      [max]="questionsQuantity">
  </app-progress>
  <div class="guided-questions__content">
    <div class="question" *ngIf="showQuestion()">
      <h5 class="question__title">{{ currentQuestion.description }}</h5>
      <div class="question__answers">
        <div
            class="question__answer"
            [ngClass]="{'selected': answer.name === currentAnswer?.name}"
            *ngFor="let answer of currentQuestion.domain.elements"
            (click)="toggleAnswer(answer)"
            [attr.data-test]= "'button-selectAnswer-' + answer.description">
          {{ answer.description }}
        </div>
      </div>
      <div class="question__explanation">{{ answerExplanation }}</div>
    </div>
    <app-recommendations
        *ngIf="isRecommendationStep()"
        [recommendations]="recommendations"
        (startOver)="goBackToStart()"
        (goBackOneStep)="goBackToPreviousQuestion()"
        (selectedContract)="selectContract($event)"
        [contractParams]="contractParams">
    </app-recommendations>
    <div class="question__actions" *ngIf="currentQuestion">
      <button
          *ngIf="showBackButton()"
          type="button"
          class="button"
          (click)="goBackToPreviousQuestion()">
        {{ 'guided-questions.answer-back-btn' | translate }}
      </button>
      <div class="question__actions--next">
        <button
            *ngIf="showNextButton()"
            type="button"
            class="button button--primary" [attr.data-test]="'button-next'"
            [disabled]="!skipAnswer && !currentAnswer"
            (click)="goToTheNextQuestion()">
          {{ 'guided-questions.answer-next-btn' | translate }}
        </button>
        <button
            *ngIf="showSkipButton()"
            type="button"
            class="button" [attr.data-test]="'button-skip'"
            (click)="skipQuestion()">
          {{ 'guided-questions.answer-skip-btn' | translate }}
        </button>
      </div>
    </div>
    <div
        *ngIf="showLoader"
        class="guided-questions__loader loader__overlay"
        [ngClass]="{'fixed': isRecommendationStep()}">
      <span class="loading-animation"></span>
    </div>
  </div>
</div>
