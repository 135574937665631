<ng-container>
  <app-modal
    [open]="showModal"
    title="{{ 'order-approve.decline-modal-title' | translate }}"
    (crossClick)="unShareCart()"
    (outsideClick)="unShareCart()">
    <ng-container ngProjectAs="[slot][body]">
      <p>{{ 'order-approve.decline-modal-content' | translate }}</p>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
        type="button"
        (click)="cancelDecline()"
        class="button button"
        data-dismiss="modal-dialog">
        {{ 'order-approve.cancel-modal' | translate }}
      </button>
      <button
        type="button"
        (click)="unShareCart()"
        class="button button--primary"
        data-dismiss="modal-dialog">
        {{ 'order-approve.decline-modal' | translate }}
      </button>
    </ng-container>
  </app-modal>
</ng-container>
