import { createAction, props } from '@ngrx/store';

const storeAPIName = '[Store API]';

export const retrieveStoreConfiguration = createAction(
  `${storeAPIName} Load store configuration`,
  props<{store: string}>(),
);

export const retrieveStoreConfigurationSuccess = createAction(
  `${storeAPIName} Load store configuration Successful`,
  props<{storeConfiguration: any}>(),
);

export const retrieveStoreConfigurationFail = createAction(
  `${storeAPIName} Load store configuration Failed`,
  props<{error: string}>(),
);
