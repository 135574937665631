import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../utils/date.utils';

export const CONTRACT_START_LENGTH_MIN_DAYS = 8;
export const KEY_STATUS_DRAFT = 'cart-configuration-status.status-draft';
export const KEY_STATUS_CONFIGURED = 'cart-configuration-status.status-configured';
export const KEY_STATUS_INVALID = 'cart-configuration-status.status-invalid';

@Pipe({name: 'configurationStatus'})
export class ConfigurationStatusPipe implements PipeTransform {
  transform(contractStartDate: string): string {
    if (contractStartDate) {
      const startDateDaysDiff = DateUtils.countDaysFromToday(contractStartDate);

      return startDateDaysDiff < CONTRACT_START_LENGTH_MIN_DAYS ? KEY_STATUS_INVALID : KEY_STATUS_CONFIGURED;
    }

    return KEY_STATUS_DRAFT;
  }
}
