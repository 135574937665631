import { Injectable, Injector } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { Observable } from 'rxjs';
import {
  ICpqOrder,
  IHybrisOrder,
  IHybrisOrderResponse, IOrderHistory,
  IOrderResponse,
  IOrderResponseWithMessages,
  IOrderSearchFilter,
} from '../models/order.models';
import { DeliveryMockFacade } from './delivery-mock.facade';
import {
  IOrdersHistoryPage,
  IOrdersWithSapDetails,
  selectCpqOrdersFulfilledPagination,
  selectCpqOrdersUnderReviewPagination,
  selectFulfilledCpqOrdersHistoryAtPage,
  selectLoadedSapOrders,
  selectOrdersCanceledDeclinedCarts,
  selectOrdersHistoryAtPage,
  selectOrdersPendingCarts,
  selectSapOrdersAtPage,
  selectSapOrdersPagination,
  selectUnderReviewCpqOrdersHistoryAtPage,
} from '../reducers/orders.reducer';
import { Store } from '@ngrx/store';
import * as fromState from '../reducers';
import * as OrdersActions from '../actions/orders.actions';
import { selectHybrisOrderById, selectHybrisOrders } from '../reducers/hybris-orders.reducer';
import { EGlueResource } from '../configurations/common';
import { AppUtils } from '../utils/app.utils';
import { IPaginationCurrent } from '../models/settings.model';


@Injectable({providedIn: 'root'})
export class OrdersFacade {

  private pOrdersService: OrdersService;

  private getOrdersService(): OrdersService {
    if (!this.pOrdersService) {
      this.pOrdersService = this.injector.get(OrdersService);
    }
    return this.pOrdersService;
  }

  constructor(
    private injector: Injector,
    private deliveryMockFacade: DeliveryMockFacade,
    private store: Store<fromState.State>) {
  }

  getCarts(): Observable<any> {
    return this.getOrdersService().getCarts();
  }

  loadCarts() {
    this.store.dispatch(OrdersActions.OrdersWithCartsStartDataAction());
  }

  getCartsApproval(): Observable<any> {
    return this.getOrdersService().getCartsApproval();
  }

  loadCartsApproval() {
    this.store.dispatch(OrdersActions.OrdersWithCartApprovalStartDataAction());
  }

  //calling selector for pending carts from application store
  selectPendingCarts(): Observable<any> {
    return this.store.select(selectOrdersPendingCarts);
  }

  getCartForApprovalData(id: string): Observable<any> {
    return this.getOrdersService().getCartForApprovalData(id);
  }

  postDeclinePendingOrder(data: any): Observable<any> {
    return this.getOrdersService().postDeclinePendingOrder(data);
  }

  deleteCart(cartId: string): Observable<any> {
    return this.getOrdersService().deleteCart(cartId);
  }

  deleteItemFromOrder(cartId: string, itemId: string): Observable<any> {
    return this.getOrdersService().deleteItemFromOrder(cartId, itemId);
  }

  postCancelOrder(orderId: string, data: any): Observable<any> {
    return this.getOrdersService().postCancelOrder(orderId, data);
  }

  getOrders(
    includes: EGlueResource[] = [],
    offset: number = null,
    limit: number = null,
    orderFilter: Partial<IOrderSearchFilter> = {},
  ): Observable<any> {
    return this.getOrdersService().getOrders(includes, offset, limit, orderFilter);
  }

  loadOrdersHistory(
    offset: number = undefined,
    limit: number  = undefined,
    recallOnClearData: boolean = false,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.orders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    if (recallOnClearData) {
      this.store.dispatch(OrdersActions.OrdersHistoryClearDataAction());
    }
    this.store.dispatch(OrdersActions.OrdersHistoryStartDataAction(offset, limit));
  }

  selectOrdersHistoryAtPage(pageNumber: number): Observable<IOrdersHistoryPage> {
    return this.store.select(selectOrdersHistoryAtPage(pageNumber));
  }

  getHybrisOrders(): Observable<IHybrisOrderResponse> {
    return this.getOrdersService().getHybrisOrders();
  }

  selectHybrisOrders(): Observable<IHybrisOrder[]> {
    return this.store.select(selectHybrisOrders);
  }

  getOrderById(id: string): Observable<IOrderResponse | IOrderResponseWithMessages> {
    return this.getOrdersService().getOrderById(id);
  }

  getHybrisOrderById(id: string): Observable<IHybrisOrderResponse> {
    return this.getOrdersService().getHybrisOrderById(id);
  }

  selectHybrisOrderById(id: string): Observable<IHybrisOrder> {
    return this.store.select(selectHybrisOrderById(id));
  }

  getOrderDetailPdfFile(orderReference: string): Observable<Blob> {
    return this.getOrdersService().getOrderDetailPdfFile(orderReference);
  }

  postOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<Blob> {
    return this.getOrdersService().postOrdersHistoryExcelFile(activeOrdersReferences);
  }

  postHybrisOrdersHistoryExcelFile(activeOrdersReferences: string[]): Observable<Blob> {
    return this.getOrdersService().postHybrisOrdersHistoryExcelFile(activeOrdersReferences);
  }

  selectLoadedOrdersWithSapDetails(): Observable<IOrdersWithSapDetails> {
    return this.store.select(selectLoadedSapOrders);
  }

  selectOrdersWithSapDetailsAtPage(pageNumber: number): Observable<IOrdersWithSapDetails> {
    return this.store.select(selectSapOrdersAtPage(pageNumber));
  }

  selectOrdersWithSapDetailsPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectSapOrdersPagination);
  }

  recallAndClearOrdersWithDetailsDataList(
    offset: number = undefined,
    limit: number  = undefined,
  ) {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.sparePartOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsClearData());
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsStartDataAction(offset, limit));
  }

  loadOrdersWithSapDetails(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.OrdersWithSapDetailsStartDataAction(offset, limit));
  }

  loadCpqOrdersUnderReviewData(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.CpqOrdersUnderReviewStartDataAction(offset, limit));
  }

  loadCpqOrdersFulfilledData(
    offset: number = undefined,
    limit: number  = undefined,
  ): void {
    if (!offset || !limit) {
      // user must set both if they wish to override the default store-specific settings
      const paginationSettings = AppUtils.getCurrentStore().paginationSettingsPerFeature.serviceOrders;
      offset = 0;
      limit = paginationSettings.limitPerPage;
    }
    this.store.dispatch(OrdersActions.CpqOrdersFulfilledStartDataAction(offset, limit));
  }

  selectCpqUnderReviewOrdersAtPage(pageNumber: number): Observable<ICpqOrder[]> {
    return this.store.select(selectUnderReviewCpqOrdersHistoryAtPage(pageNumber));
  }

  selectCpqFulFilledOrdersAtPage(pageNumber: number): Observable<ICpqOrder[]> {
    return this.store.select(selectFulfilledCpqOrdersHistoryAtPage(pageNumber));
  }

  selectCpqFulFilledOrdersPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectCpqOrdersFulfilledPagination);
  }

  selectCpqUnderReviewOrdersPagination(): Observable<IPaginationCurrent> {
    return this.store.select(selectCpqOrdersUnderReviewPagination);
  }
}
