<div class="part-product-cart">
  <app-sap-message-controller [messages]="cartSapMessages">
  </app-sap-message-controller>
  <ng-container
      *ngIf="!isCartEmpty() || (isUsStore && currentCart.attributes.systemDetails?.siemensEquipmentId); else emptyCartTemplate">
    <div class="multi-cart__current-cart">
      <h5 [attr.data-test]="'title-selectedCart'">{{ 'multi-cart.selected-cart' | translate }}</h5>
      <div class="selected-cart">
        <div class="selected-cart-loader"
             *ngIf="(isAddingViaCsvInProgress || disabledAddToCartSearchBar) || loadingCart">
          <div class="loading-animation"></div>
        </div>
        <div class="selected-cart-container">
          <div class="selected-cart-header">
            <div class="selected-cart-details">
              <h6 class="selected-cart-name" [attr.data-test]="'title-selectedCartName'">
                {{ getCurrentCartName() }}
              </h6>
              <button type="submit" class="button button--link button--add-nickname button--auto-min-width"
                      (click)="showAddNicknameModalEmit()">
                {{ getLabelForAddNicknameButton() | translate }}
              </button>
              <div class="system-details" *ngIf="isCaStore && currentCart.attributes.systemDetails?.siemensEquipmentId">
                <h6 [attr.data-test]="'desc-systemName'">{{ currentCart.attributes.systemDetails?.nameEnUs }}</h6>
                <span
                    [attr.data-test]="'desc-functionalLocationNumber'">FL: {{ currentCart.attributes.systemDetails?.siemensEquipmentId }}</span>
              </div>
              <div class="system-details" *ngIf="isUsStore && currentCart.attributes.systemDetails?.siemensEquipmentId">
                <app-equipment-selection
                    [currentCart]="currentCart"
                    [systemDetails]="currentCart.attributes.systemDetails"
                    [loading]="false"
                    [isInCurrentCartComponent]="true">
                </app-equipment-selection>
              </div>
            </div>
            <button type="submit" class="button button--link button--delete-cart button--auto-min-width"
                    (click)="showDeleteCartModalEmit()" [attr.data-test]="'button-deleteCart'">
              {{ 'multi-cart.delete-cart' | translate }} <span class="icon-close"></span>
            </button>
          </div>
          <div *ngIf="isEquipmentAfterEos()" class="eos-message">
            <app-message [message]="setDataForEquipmentAfterEosMessage()"></app-message>
          </div>
          <div class="cart-items-list-container">
            <div class="list-header">
              <div class="header-col item-image blank"></div>
              <div class="header-col item-info blank"
                   [ngClass]="!isRfqFlowInactive() ? 'item-info__rfq-only-width' : 'item-info__width'"></div>
              <div *ngIf="isRfqFlowInactive()"
                   class="header-col item-price">{{ 'multi-cart.price-per-unit' | translate }}</div>
              <div class="header-col item-quantity">{{ 'multi-cart.quantity' | translate }}</div>
              <div *ngIf="isRfqFlowInactive()"
                   class="header-col item-total-price">{{ 'multi-cart.total-price' | translate }}</div>
            </div>
            <ng-container *ngFor="let item of currentCartItems; let index = index;">
              <div class="item-row">
                <div class="mobile-item-actions hidden-lg-up">
                  <div class="wishlist">
                    <app-add-to-wishlist
                        [wishlists]="wishlists"
                        [sku]="item.id"
                        [productName]="item.attributes.name"
                        [productKeywords]="item.attributes.attributes.mcm_keywords"
                        [addNewModalActive]="addWishlistModalActive"
                        [textNextToIcon]="true"
                        (createNew)="showAddWishlistModalEmit()">
                    </app-add-to-wishlist>
                  </div>
                  <button *ngIf="!isPriceDisputingActive"
                          class="button button--link button--delete-item"
                          (click)="removeCartItemEmit(item.id)">
                    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
                  </button>
                </div>
                <div class="item-col item-image">
                  <img [src]="getImageForCartItem(item)" alt="{{ item.attributes.name }}"
                       [attr.data-test]="'img-itemImage'">
                </div>
                <div class="item-col item-info"
                     [ngClass]="!isRfqFlowInactive() ? 'item-info__rfq-only-width' : 'item-info__width'">
                  <h6 class="item-name" [attr.data-test]="'title-itemName'">
                    {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                  </h6>
                  <ng-container *ngIf="isRfqFlowInactive()">
                    <div class="cart-items-tags">
                      <span *ngIf="item.attributes.availability?.isExchangeable"
                            class="label item-exchange-material"
                            [attr.data-test]="'desc-itemExchangeable'">
                        {{ 'spare-parts.exchangeable-item' | translate }}
                      </span>
                      <span *ngIf="(isUsStore || isCaStore) && item.attributes.heavyweight"
                            class="label heavyweight-item"
                            [attr.data-test]="'desc-heavyweightItem'">
                        {{ 'spare-parts.heavyweight-item' | translate }}
                      </span>
                    </div>
                    <p class="item-details">
                      <span class="item-material-number"
                            [attr.data-test]="'desc-itemMaterialNumber'">{{ item.attributes.materialNumber }}</span><br>
                      <span *ngIf="!isExcludeTaxActive()" class="item-delivery-availability">
                      <ng-container *ngIf="isUsStore || isCaStore; else otherStore">
                        <span *ngIf="item.attributes.availability?.isAvailable; else outOfStock">
                          {{ 'spare-parts.in-stock' | translate }}
                        </span>
                        <ng-template #outOfStock>
                          <span>
                            {{ 'spare-parts.out-of-stock' | translate }}
                          </span>
                        </ng-template>
                      </ng-container>
                      <ng-template #otherStore>
                        <span *ngIf="item.attributes.deliveryAvailability"
                              [attr.data-test]="'desc-itemDeliveryAvailability'">
                          {{ item.attributes.deliveryAvailability }}
                        </span>
                      </ng-template>
                    </span>
                    </p>
                  </ng-container>
                  <div class="wishlist" [ngClass]="{'wishlist__rfq': rfqActive}">
                    <app-add-to-wishlist
                        [wishlists]="wishlists"
                        [sku]="item.id"
                        [productName]="item.attributes.name"
                        [productKeywords]="item.attributes.attributes.mcm_keywords"
                        [addNewModalActive]="addWishlistModalActive"
                        [textNextToIcon]="true"
                        (createNew)="showAddWishlistModalEmit()">
                    </app-add-to-wishlist>
                  </div>
                </div>
                <div class="item-col item-price" *ngIf="isRfqFlowInactive()">
                  <span class="item-unit-price"
                        [attr.data-test]="'desc-itemUnitPrice'">{{ item.attributes.calculations.unitPrice | currency: currency }}
                    <span *ngIf="isExcludeTaxActive()"
                          class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span></span>
                </div>
                <div class="item-col item-quantity">
                  <form
                      (submit)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)">
                    <input #itemQuantity
                           type="number"
                           name="itemQuantity"
                           class="item-quantity"
                           [value]="item.attributes.quantity"
                           min="1"
                           step="1"
                           required
                           (change)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)"
                           [attr.data-test]="'desc-itemQuantity'"/>
                  </form>
                  <button *ngIf="!isRfqFlowInactive() && !isPriceDisputingActive"
                          class="button button--link button--delete-item__rfq-only"
                          (click)="removeCartItemEmit(item.id)">
                    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
                  </button>
                </div>
                <div class="item-col item-total-price" *ngIf="isRfqFlowInactive()">
                  <span class="item-total-price"
                        [attr.data-test]="'desc-itemTotalPrice'">{{ item.attributes.calculations.sumPrice | currency: currency }}
                    <span *ngIf="isExcludeTaxActive()"
                          class="tax-disclaimer">({{ 'app-product-page.ex_gst' | translate }})</span>
                  </span>
                  <button *ngIf="!isPriceDisputingActive"
                          class="button button--link button--delete-item"
                          (click)="removeCartItemEmit(item.id)"
                          [attr.data-test]="'button-deleteItem'">
                    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
                  </button>
                </div>
                <div class="mobile-item-header hidden-lg-up">
                  <div *ngIf="isRfqFlowInactive()" class="item-price">
                    <strong>{{ 'multi-cart.price-per-unit' | translate }}</strong></div>
                  <div class="item-quantity"><strong>{{ 'multi-cart.quantity' | translate }}</strong></div>
                  <div *ngIf="isRfqFlowInactive()" class="item-total-price">
                    <strong>{{ 'multi-cart.total-price' | translate }}</strong></div>
                </div>
                <div class="mobile-item-price hidden-lg-up">
                  <div *ngIf="isRfqFlowInactive()"
                       class="item-unit-price">{{ item.attributes.calculations.unitPrice | currency: currency }}</div>
                  <div class="item-quantity">
                    <form
                        (submit)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)">
                      <input #itemQuantity
                             type="number"
                             name="itemQuantity"
                             class="item-quantity"
                             [value]="item.attributes.quantity"
                             min="1"
                             step="1"
                             required
                             (change)="updateCartItemQuantityEmit(item.attributes.quantity, itemQuantity.value, item, index)"/>
                    </form>
                  </div>
                  <div *ngIf="isRfqFlowInactive()"
                       class="item-total-price">{{ item.attributes.calculations.sumPrice | currency: currency }}</div>
                </div>
              </div>
              <div *ngIf="isPriceDisputingActive"
                   class="item-price-disputing-row">
                <app-price-disputing
                    [currentCart]="currentCart"
                    [currentItem]="item"
                    [isSaturdayShipment]="isSaturdayShipment"
                    (removeCartItem)="removeCartItemEmit($event)">
                </app-price-disputing>
              </div>
              <hr>
            </ng-container>
            <p class="disclaimer--shipment-method" *ngIf="isSaturdayShipment">
              {{ 'checkout-delivery-details.saturday-delivery-method-price-disputing-disclaimer' | translate }}
            </p>
            <div class="no-items-in-current-cart" *ngIf="isCartEmpty() && isUsStore">
              <h6 class="empty-cart-header">
                {{'multi-cart.empty-cart-title-us-support-add-to-cart-from-file' | translate}}
              </h6>
            </div>
          </div>
        </div>
        <div class="selected-cart-summary">
          <div class="action-buttons">
            <button
                [attr.data-test]="'button-continueToCheckout'"
                type="submit" class="button button--primary"
                [disabled]="currentCartItems?.length === 0"
                (click)="proceedToNextStep()">
              {{ getLabelForContinueToCheckoutButton() | translate }}
            </button>
            <ng-container *ngIf="isExportCartActive && isRfqFlowInactive()">
              <button
                  [attr.data-test]="'button-exportShoppingCart'"
                  *ngIf="!fileGenerationInProgress; else loadingSpinner"
                  type="button"
                  class="button button--secondary"
                  (click)="generatePdfFileEmit()">
                {{ 'multi-cart.export-shopping-cart' | translate }}
              </button>
              <ng-template #loadingSpinner>
                <div class="btn-spinner">
                  <div class="loading-animation"></div>
                </div>
              </ng-template>
            </ng-container>
            <button
                [attr.data-test]="'button-continueShopping'"
                type="button" class="button button--secondary" [routerLink]="['/catalog/parts']">
              {{ 'multi-cart.continue-shopping' | translate }}
            </button>
          </div>
          <ng-container *ngIf="isUsStore || isCaStore; else otherStore">
            <div class="selected-cart-pricelist">
              <div class="price-disclaimer">
                <strong [attr.data-test]="'label-totalPrice'">{{ 'multi-cart.total-price' | translate }}</strong><br>
                <span>{{ 'multi-cart.exclude-tax' | translate }}</span>
              </div>
              <div class="current-cart-total-price" [attr.data-test]="'desc-subtotal-for-usStore'">
                <strong>{{ currentCart.attributes.totals.subtotal | currency: currency }}</strong>
              </div>
            </div>
          </ng-container>
          <ng-template #otherStore>
            <div *ngIf="isRfqFlowInactive()" class="selected-cart-pricelist other-store">
              <div class="price-disclaimer">
                <strong>{{ 'multi-cart.total-price' | translate }}</strong>
                <p [ngClass]="{'totals-tax-disclaimer': isExcludeTaxActive()}">
                  {{ 'shop-cart.totalAdditionalInformation' | translate }}<br>
                  <span *ngIf="!isExcludeTaxActive()">{{ 'shop-cart.free-shipping' | translate }}</span>
                </p>
              </div>
              <div class="current-cart-total-price" [attr.data-test]="'desc-subtotal-for-otherStore'">
                <strong>{{ currentCart.attributes.totals.subtotal | currency: currency }}</strong>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyCartTemplate>
    <div class="multi-cart__empty-cart">
      <div class="empty-cart-wrapper">
        <h6 class="empty-cart-header">
          {{ 'multi-cart.empty-cart-title' | translate }}
        </h6>
        <p class="empty-cart-text" *ngIf="isUsStore || isCaStore">
          {{ 'multi-cart.empty-cart-text' | translate }}
        </p>
        <button class="button" [routerLink]="['/catalog/parts']">
          {{ 'multi-cart.catalog-link' | translate }}
        </button>
        <button *ngIf="isUsStore || isCaStore"
                [attr.data-test]="'button-goToInstalledBase'"
                class="button button--installed-base"
                [routerLink]="[isBusinessPartnerRole ? '/equipment-selection' : '/my-installed-base']"
                [queryParams]="isBusinessPartnerRole ? {} : {tab : EInstalledBaseTabs.EQUIPMENT}">
          {{ (isBusinessPartnerRole ? 'multi-cart.equipment-link-bp' : 'multi-cart.equipment-link') | translate }}
        </button>
      </div>
      <div class="empty-cart-loader" *ngIf="loadingCart">
        <div class="loading-animation"></div>
      </div>
    </div>
  </ng-template>

  <ng-container
      *ngIf="currentCart.attributes.systemDetails?.siemensEquipmentId && (featureIsEnabledForCsv || featureIsEnabledForSearchBar)">
    <div class="add-directly-to-cart-container">
      <div class="add-via-searchbar-container">
        <app-add-parts-to-cart
            *ngIf="featureIsEnabledForSearchBar"
            [systemDetails]="currentCart.attributes.systemDetails"
            [disabledAddButton]="isAddingViaCsvInProgress"
            (uploadedFilesViaSearchBar)="addToCartViaSearchBar($event)">
        </app-add-parts-to-cart>
      </div>
      <div class="add-via-drag-drop-container">
        <app-drag-drop-upload
            *ngIf="featureIsEnabledForCsv"
            [supportedMaxAmountOfFiles]="1"
            [supportedFileFormat]="'.csv'"
            [uploadingFileInProgress]="isAddingViaCsvInProgress"
            [disabledAddButton]="disabledAddToCartSearchBar"
            (uploadedFiles)="addToCartViaCsv($event)">
        </app-drag-drop-upload>
      </div>
    </div>
  </ng-container>
</div>
