import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { CatalogActions } from '../actions';
import { ICurrentParams } from '../models/catalog.models';
import { AppUtils } from '../utils/app.utils';
import { CustomerState, customerState } from './customer.reducer';
import { ArrayUtils } from '../utils/array.utils';
import { IStore } from '../models/settings.model';
import { environment } from '../../environments/environment.defaults';

export const catalogFeatureKey: string = 'catalog';

export interface CatalogState {
  abstractProduct: any;
  abstractProductError: any;
  categoriesLoading: boolean;
  categoriesLoaded: boolean;
  categories: any;
  categoriesError: any;
  query: string;
  installedBase: any;
  queryParams: ICurrentParams;
}

export const initialState: CatalogState = {
  abstractProduct: null,
  abstractProductError: null,
  categoriesLoading: false,
  categoriesLoaded: false,
  categories: null,
  categoriesError: null,
  query: '',
  installedBase: null,
  queryParams: null,
};

export const selectCatalogState = createFeatureSelector<CatalogState>(
  catalogFeatureKey,
);

export const selectAbstractProduct = createSelector(
  selectCatalogState,
  state => state.abstractProduct,
);
export const selectAbstractProductError = createSelector(
  selectCatalogState,
  state => state.abstractProductError,
);

export const selectCategories = createSelector(
  selectCatalogState,
  state => state.categories,
);

export const selectMainCategoryName = createSelector(
  selectCatalogState,
  state => state.categories[0]?.name ? state.categories[0].name : '',
);

export const selectIsCategoriesLoading = createSelector(
  selectCatalogState,
  state => state.categoriesLoading,
);

export const selectIsCategoriesLoaded = createSelector(
  selectCatalogState,
  state => state.categoriesLoaded,
);

export const selectSearchQuery = createSelector(
  selectCatalogState,
  state => state.query,
);

export const selectInstalledBase = createSelector(
  selectCatalogState,
  state => state.installedBase,
);

export const selectQueryParams = createSelector(
  selectCatalogState,
  state => state.queryParams,
);

export const selectBlockedSortByOptions = createSelector(
  selectCatalogState,
  customerState,
  (catalog: CatalogState, customer: CustomerState): string[] => {
    const currentStore: IStore = AppUtils.getCurrentStore();
    const blockedOptionsPerRole: string[] = environment.blockedSortOptionsPerRole.find(sortBy => customer.companyRoles.includes(sortBy.role))?.options;
    const blockedOptionsPerStore: string[] =environment.blockedSortOptionsPerStore.find(sortBy => sortBy.store === currentStore.storeId)?.excluded;
    const blockedOptions: string[] = ArrayUtils.removeDuplicates(ArrayUtils.concatArrays(blockedOptionsPerRole, blockedOptionsPerStore));
    return blockedOptions ? blockedOptions : [];
  },
);

export const CatalogReducer = createReducer(
  initialState,

  on(CatalogActions.loadAbstractProductDataSuccess, (state: CatalogState, {payload}) => {
    return {
      ...state, abstractProduct: {
        ...payload.data.attributes,
        included: payload.included,
        relationships: payload.data?.relationships || undefined,
      },
    };
  }),

  on(CatalogActions.loadAbstractProductDataError, (state: CatalogState, {error}) => {
    return {...state, abstractProduct: null, abstractProductError: error};
  }),

  on(CatalogActions.clearAbstractProductData, (state: CatalogState) => {
    return {...state, abstractProduct: null, abstractProductError: null};
  }),

  on(CatalogActions.loadCategoriesStart, (state: CatalogState) => {
    return {
      ...state,
      categoriesLoading: true,
    };
  }),

  on(CatalogActions.loadCategoriesSuccess, (state: CatalogState, {payload}) => {
    const {categoryNodesStorage} = payload.data[0].attributes;
    const categories = categoryNodesStorage.map(parentCategory => {
      return {...parentCategory, hasProducts: parentCategory?.productsQuantity > 0};
    });
    return {
      ...state,
      categoriesLoading: false,
      categoriesLoaded: true,
      categories,
    };
  }),

  on(CatalogActions.loadCategoriesFail, (state: CatalogState, {error}) => {
    return {
      ...state,
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesError: error,
    };
  }),
  on(CatalogActions.setSearchQuery, (state: CatalogState, action) => {
    return {
      ...state,
      query: action.query,
    };
  }),
  on(CatalogActions.clearSearchQuery, (state: CatalogState) => {
    return {
      ...state,
      query: '',
    };
  }),
  on(CatalogActions.setInstalledBase, (state: CatalogState, action) => {
    return {
      ...state,
      installedBase: action.installedBase,
    };
  }),
  on(CatalogActions.setQueryParams, (state: CatalogState, action) => {
    return {
      ...state,
      queryParams: action.params,
    };
  }),
);
