<footer class="footer">
  <div class="container">
    <div class="row" *ngIf="phone || email">
      <div class="col-12">
        <app-social-media-footer *ngIf="!hideSocialMedia()"></app-social-media-footer>
        <hr>
        <ng-container *ngIf="showExtendedContactUs(); else defaultContactUs">
          <app-contact-us-footer></app-contact-us-footer>
        </ng-container>
        <ng-template #defaultContactUs>
          <div class="col-12 col-lg-5 col-xl-4">
            <p class="contact-support__title">{{ 'app-footer.contact' | translate }}</p>
            <div class="contact-support__container">
              <address>
                <p class="contact-support__item" *ngIf="phone">
                  <span class="icon-phone"></span>
                  <a href="tel:{{ phone }}">{{ phone | phoneNumber: phoneNumberFormat || '4|3|2|2' : delimiter || ' ' : prefix || ''}}</a>
                </p>
                <p class="contact-support__item" *ngIf="email">
                  <span class="icon-email"></span>
                  <a href="mailto:{{ email }}">{{ email }}</a>
                </p>
              </address>
            </div>
          </div>
          <div class="col-12" *ngIf="isInStore">
            <div class="contact-support__container">
              <p class="contact-support__item">
                {{ 'app-footer.contact-to-grievances-officer-desc1' | translate }}
                <a class="contact-support__item-link" href="mailto:{{ emailGrievancesOfficer }}">
                  {{ 'app-footer.contact-to-grievances-officer' | translate }}</a>{{ 'app-footer.contact-to-grievances-officer-desc2' | translate }}
              </p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <hr>
        <ul class="legal-links">
          <li class="legal-links__item">
            <address>{{ getAddress() }}</address>
          </li>
          <li class="legal-links__item" *ngFor="let link of links">
            <ng-container *ngIf="link.nodeType === 'link'; else external">
              <a [routerLink]="link.url" [target]="'_self'" class="legal-links__link">{{ link.title }}</a>
            </ng-container>
            <ng-template #external>
              <a [href]="link.url" [target]="'_blank'" class="legal-links__link">{{ link.title }}</a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
