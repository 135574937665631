<div class="conflict-modal" *ngIf="open">
  <app-modal
      title="{{ 'service-configuration.conflict-modal-title' | translate }}"
      [type]="iconType.WARNING"
      [open]="open"
      [showCrossCloseBtn]="false">
    <ng-container ngProjectAs="[slot][body]">
      <p class="cancel-order-modal-title">{{ 'service-configuration.conflict-modal-desc' | translate }}</p>
      <div class="conflicts">
        <div class="option" *ngFor="let option of conflict.changed">
          <h5 class="option__name">{{ option.description }}</h5>
          <div class="option__differences">
            <div class="option__old">
              {{ option.oldValueDescription }}
            </div>
            <span class="icon-arrow-long-right"></span>
            <div class="option__new">
              {{ option.newValueDescription }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
          type="button"
          class="button button"
          data-dismiss="modal-dialog"
          (click)="undo.emit(true)">
        {{ 'service-configuration.conflict-modal-cancel' | translate }}
      </button>
      <button
          type="button"
          class="button button--primary"
          data-dismiss="modal-dialog"
          (click)="accept.emit(true)">
        {{ 'service-configuration.conflict-modal-confirm' | translate }}
      </button>
    </ng-container>
  </app-modal>
</div>
