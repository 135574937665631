<div *ngIf="initializeInProgress; else almostDone">
  <div class="almost-done__loader">
    <div class="loading-animation"></div>
  </div>
</div>
<ng-template #almostDone>
  <div class="almost-done__content">
    <div class="almost-done__overview">
      <button
          type="button"
          class="back-to-quote-summary"
          (click)="backToQuoteSummary()">
        <span class="icon-arrow-left-small"></span>
        {{ 'cpq-almost-done.back-to-quote-btn' | translate }}
      </button>
      <div class="almost-done__description">
        <h4>{{ 'cpq-almost-done.page-title' | translate }}</h4>
        <h6>{{ 'cpq-almost-done.page-sneak-peak' | translate: {name: cart.id, nickname: cartName} }}</h6>
        <p>{{ 'cpq-almost-done.page-content-1' | translate }}</p>
        <button
            type="button"
            class="read-more"
            [ngClass]="{'hide': visible}"
            (click)="toggleReadMore()">
          {{ 'cpq-almost-done.read-more-btn' | translate }}
          <span class="icon-arrow-down"></span>
        </button>
        <div
            class="read-more-content"
            [ngClass]="{'show': visible}">
          <p>{{ 'cpq-almost-done.page-read-more-1' | translate }} -
            <span>{{ 'cpq-almost-done.page-read-more-2' | translate }}</span>
          </p>
          <p>{{ 'cpq-almost-done.page-read-more-3' | translate }} -
            <span>{{ 'cpq-almost-done.page-read-more-4' | translate }}</span>
          </p>
          <button
              type="button"
              class="read-less"
              (click)="toggleReadMore()">
            {{ 'cpq-almost-done.read-less-btn' | translate }}
            <span class="icon-arrow-up-large"></span>
          </button>
        </div>
      </div>
      <h4>{{ 'cpq-almost-done.send-us-title' | translate }}</h4>
      <p>{{ 'cpq-almost-done.send-us-subtitle' | translate }}</p>
      <div class="almost-done__form">
        <h4>{{ 'cpq-almost-done.send-us-point-1-title' | translate }}</h4>
        <ng-container *ngIf="!quoteDocumentInProgress; else loadingSpinner">
          <button
              type="button"
              class="download-documentation"
              [attr.data-test]="'button-downloadDocumentation'"
              (click)="downloadDocumentation()">
            <span class="icon-download"></span>
            {{ 'cpq-almost-done.download-label' | translate }}
          </button>
        </ng-container>
        <ng-template #loadingSpinner>
          <div class="btn-spinner">
            <div class="loading-animation progress-download-document"></div>
          </div>
        </ng-template>
        <h4>{{ 'cpq-almost-done.send-us-point-2-title' | translate }}</h4>
        <p>{{ 'cpq-almost-done.send-us-point-2-subtitle' | translate }}</p>
        <p class="contracts-email">
          <span class="icon-email"></span>&nbsp;
          <a href="mailto:{{ ecommerceTeamEmailAddress }}?subject={{ ('cpq-almost-done.email-subject' | translate: {
            quoteNumber: cpqQuote?.quoteId
          }) | encodeUriText }}&body={{ ('cpq-almost-done.email-body' | translate: {
            quoteNumber: cpqQuote?.quoteId,
            quoteDocumentationUrl: generateQuoteDocumentationUrl(),
            signedContractSubmitted: ('cpq-almost-done.' + (checked ? 'signed-contract-checked' : 'signed-contract-not-checked')) | translate,
            poStatusProvided: radioTermCode ? ('cpq-almost-done.purchase-order-value.' + radioTermCode | translate) : '',
            customerName: customerBusinessUnitName,
            userFullName: customerFullName,
            userEmail: customerEmail,
            soldToSapId: cpqQuote?.soldTo_id || '',
            soldToAddress: cpqQuote?.soldTo_address1 | formatAddress: cpqQuote?.soldTo_address2 : cpqQuote?.soldTo_zipCode : cpqQuote?.soldTo_city,
            billToSapId: cpqQuote?.billTo_id || '',
            payerSapId: cpqQuote?.payer_id || ''
          }) | encodeUriText }}">{{ ecommerceTeamEmailAddress }}</a>
        </p>
        <input
            id="confirm"
            type="checkbox"
            name="confirm"
            class="checkbox"
            [attr.data-test]="'checkbox-confirm'"
            [ngClass]="{'input-error': active && !checked}"
            (change)="confirmSendingContract($event)">
        <label for="confirm">
          {{ 'cpq-almost-done.send-us-point-2-checkbox-label-1' | translate }}
        </label>
        <p class="error"
           [ngClass]="{'show': active && !checked}">
          <span class="icon-close icon-close__circle"></span>
          {{ 'cpq-almost-done.error-1' | translate }}
        </p>
        <h4>{{ 'cpq-almost-done.send-us-point-3-title' | translate }}</h4>
        <p>{{ 'cpq-almost-done.send-us-point-3-subtitle' | translate }}</p>
        <p class="contracts-email">
          <span class="icon-email"></span>&nbsp;
          <a href="mailto:{{ ecommerceTeamEmailAddress }}?subject={{ ('cpq-almost-done.email-subject' | translate: {
            quoteNumber: cpqQuote?.quoteId
          }) | encodeUriText }}&body={{ ('cpq-almost-done.email-body' | translate: {
            quoteNumber: cpqQuote?.quoteId,
            quoteDocumentationUrl: generateQuoteDocumentationUrl(),
            signedContractSubmitted: ('cpq-almost-done.' + (checked ? 'signed-contract-checked' : 'signed-contract-not-checked')) | translate,
            poStatusProvided: radioTermCode ? ('cpq-almost-done.purchase_order_value.' + radioTermCode | translate) : '',
            customerName: customerBusinessUnitName,
            userFullName: customerFullName,
            userEmail: customerEmail,
            soldToSapId: cpqQuote?.soldTo_id || '',
            soldToAddress: cpqQuote?.soldTo_address1 | formatAddress: cpqQuote?.soldTo_address2 : cpqQuote?.soldTo_zipCode : cpqQuote?.soldTo_city,
            billToSapId: cpqQuote?.billTo_id || '',
            payerSapId: cpqQuote?.payer_id || ''
          }) | encodeUriText }}">{{ ecommerceTeamEmailAddress }}</a>
        </p>
        <p>
          <input
              id="radio-1"
              type="radio"
              name="answer"
              value="purchase_order_submitted"
              class="radio"
              [attr.data-test]="'radio-purchaseOrderSentNow'"
              [ngClass]="{'input-error': active && !selected}"
              (change)="confirmChoosingAnswer($event)">
          <label for="radio-1">
            {{ 'cpq-almost-done.send-us-point-3-radio-label-1' | translate }}
          </label>
        </p>
        <p>
          <input
              id="radio-2"
              type="radio"
              name="answer"
              value="purchase_order_pending"
              class="radio"
              [attr.data-test]="'radio-purchaseOrderSendLater'"
              [ngClass]="{'input-error': active && !selected}"
              (change)="confirmChoosingAnswer($event)">
          <label for="radio-2">
            {{ 'cpq-almost-done.send-us-point-3-radio-label-2' | translate }}
          </label>
        </p>
        <p>
          <input
              id="radio-3"
              type="radio"
              name="answer"
              value="purchase_order_not_required"
              class="radio"
              [attr.data-test]="'radio-purchaseOrderDoesntUse'"
              [ngClass]="{'input-error': active && !selected}"
              (change)="confirmChoosingAnswer($event)">
          <label for="radio-3">
            {{ 'cpq-almost-done.send-us-point-3-radio-label-3' | translate }}</label>
        </p>
        <p class="error"
           [ngClass]="{'show': active && !selected}">
          <span class="icon-close icon-close__circle"></span>
          {{ 'cpq-almost-done.error-2' | translate }}
        </p>
      </div>
    </div>
    <div class="almost-done__summary">
      <span> {{ 'cpq-almost-done.before-submit-info-1' | translate }}</span>
      <span> {{ 'cpq-almost-done.before-submit-info-2' | translate }}</span>
      <button
          class="button button--primary"
          [attr.data-test]="'button-submitOrder'"
          (click)="submitOrder()">
        {{ 'cpq-almost-done.submit-order-btn' | translate }}
      </button>
      <div class="order-price">
        <p>{{ 'cpq-almost-done.annual-price' | translate }}</p>
        <p>{{ totalAnnualPrice |  currency: undefined: undefined: 0  }}</p>
      </div>
    </div>
    <app-modal
        [open]="showModal"
        title="{{ 'cpq-almost-done.modal-title' | translate }}"
        (crossClick)="closeModal()">
      <ng-container ngProjectAs="[slot][body]">
        <p>{{ 'cpq-almost-done.modal-content' | translate }}</p>
      </ng-container>
      <ng-container ngProjectAs="[slot][footer]">
        <button
            type="button"
            class="button button--primary"
            [attr.data-test]="'button-closeModalAndSubmitOrder'"
            (click)="closeModalAndSubmitOrder()">
          {{ 'cpq-almost-done.modal-button' | translate }}
        </button>
      </ng-container>
    </app-modal>
  </div>
</ng-template>
