import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { State } from '../reducers';
import * as ShopCartSelectors from '../reducers/shop-cart.reducer';
import { I18nService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PreventAccessWithEmptyCartGuard implements CanActivate {
  loadingCartDataInProgress$ = this.store.select(ShopCartSelectors.selectLoadingCartDataInProgress);
  cartItems$ = this.store.select(ShopCartSelectors.selectCartItems);

  constructor(
    private router: Router,
    private store: Store<State>,
    private i18nService: I18nService,
  ) {
  }

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.loadingCartDataInProgress$,
      this.cartItems$
    ]).pipe(
      filter(([loadingCartDataInProgress, cartItems]) => !loadingCartDataInProgress && cartItems),
      map(([loadingCartDataInProgress, cartItems]) => {
        if (cartItems.data.attributes.totalItemsQuantity === 0) {
          this.router.navigate([this.i18nService.getCurrentLocale()]);
          return false;
        }
        return true;
      })
    );
  }
}
