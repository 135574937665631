import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { AppUtils } from '../../utils/app.utils';

@Pipe({
  name: 'formatDateTime',
})
export class FormatDateTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(date?: Date | string): string {
    const defaultDateTimeFormat = 'yyyy-MM-dd HH:mm';
    const store = environment.stores.find(
      store => store.storeId === AppUtils.getCurrentStore().storeId,
    );
    const dateTimeFormat = (store?.dateFormat && store?.timeFormat) ? store.dateFormat + ' ' + store.timeFormat : defaultDateTimeFormat;

    date = (typeof date === 'string') ? new Date(date) : date;

    return this.datePipe.transform(date, dateTimeFormat);
  }
}
