import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerFacade } from '../facades/customer.facade';
import { takeUntil } from 'rxjs/operators';
import { EUserRoles } from '../configurations/common';

@Injectable({
  providedIn: 'root'
})
export class RegularUserAccessGuard implements CanActivate {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.selectCustomerCompanyRoles()) {
      this.router.navigateByUrl(route.params.lang).then();
      return false;
    } else {
      return true;
    }
  }


  selectCustomerCompanyRoles(): boolean {
    let hasApprovalAccess;
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(roles => {
      hasApprovalAccess = this.checkRolesForAccess(roles);
    });
    return hasApprovalAccess;
  }

  checkRolesForAccess(roles: EUserRoles[]): boolean {
    return !roles.includes(EUserRoles.BusinessPartnerBuyer) && !roles.includes(EUserRoles.BusinessPartnerApprover);
  }
  
}
