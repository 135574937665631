<app-basic-product-pdp 
    *ngIf="!isUsStore && !isCaStore"
    (addNewModalWishlist)="addNewModalActive = true"
    (addProductToCartSubmit)="addProductToCart()"
    [labels]="labels"
    [installBaseProducts]="installBaseProducts"
    [abstractProduct]="abstractProduct"
    [concreteSku]="concreteSku"
    [loadingCartDataInProgress]="loadingCartDataInProgress"
    [productInfoLoaded]="productInfoLoaded"
    [(productQuantity)]="productQuantity"
    [isAddToCartInProgress$]="isAddToCartInProgress$"
    [isInStore]="isInStore"
    [addNewModalActive]="addNewModalActive"
    [sku]="sku"
    [displayConfig]="displayConfig"
    [showPriceDisclaimer]="showPriceDisclaimer"
    [secondPrice]="secondPrice"
    [defaultPrice]="defaultPrice"
    [largeUrls]="largeUrls"
    [smallUrls]="smallUrls"
    [defaultImg]="defaultImg"
    [mainPicture]="mainPicture"
    [productDetailsEnum]="productDetailsEnum"
    [wishlists]="wishlists">
</app-basic-product-pdp>
<app-configurable-product-pdp 
    *ngIf="isUsStore && isCpqEnabled && !isSparePart"
    (addProductToCartSubmit)="addProductToCart($event)"
    [installBaseProducts]="installBaseProducts"
    [abstractProduct]="abstractProduct"
    [productInfoLoaded]="productInfoLoaded"
    [sku]="sku"
    [displayConfig]="displayConfig"
    [defaultImg]="defaultImg"
    [mainPicture]="mainPicture"
    [productDetailsEnum]="productDetailsEnum"
    [contractParams]="contractParams"
    [flNumber]="flNumber"
    [materialNumber]="materialNumber"
    [stepNumber]="stepNumber"
    [concreteProduct]="concreteProduct">
</app-configurable-product-pdp>
<app-spare-part-product-pdp
    *ngIf="(isUsStore || isCaStore) && isSparePartsEnabled && isSparePart"
    (addNewModalWishlist)="addNewModalActive = true"
    (addProductToCartSubmit)="addProductToCart()"
    [abstractProduct]="abstractProduct"
    [concreteSku]="concreteSku"
    [productInfoLoaded]="productInfoLoaded"
    [isAddToCartInProgress$]="isAddToCartInProgress$"
    [sku]="sku"
    [currentCart]="currentCart"
    [displayConfig]="displayConfig"
    [defaultImg]="defaultImg"
    [mainPicture]="mainPicture"
    [systemDetails]="systemDetails"
    [equipmentFlNumber]="flNumber"
    [stepNumber]="stepNumber"
    [concreteProduct]="concreteProduct"
    [labels]="labels"
    [loadingCartDataInProgress]="loadingCartDataInProgress"
    [wasRedirectedFromEquipmentPage]="wasRedirectedFromEquipmentPage"
    [(productQuantity)]="productQuantity"
    [addNewModalActive]="addNewModalActive"
    [listPrice]="secondPrice"
    [yourPrice]="defaultPrice"
    [largeUrls]="largeUrls"
    [smallUrls]="smallUrls"
    [wishlists]="wishlists"
    [productDetailsEnum]="productDetailsEnum"
    [installBaseProducts]="installBaseProducts"
    [companyRoles]="companyRoles">
</app-spare-part-product-pdp>
<app-add-new-wishlist
    [showModal]="addNewModalActive"
    (closeModal)="addNewModalActive = false">
</app-add-new-wishlist>
<app-add-item-modal
    [showModal]="addItemToCartModalActive"
    (closeModal)="addItemToCartModalActive = false"
    [items]="itemToAdd"
    [currentCartId]="currentCartId">
</app-add-item-modal>
<app-modal-confirm
    [type]="iconType.WARNING"
    modalTitle="{{ 'spare-parts.not-selected-equipment-modal' | translate }}"
    notifyText="{{ 'spare-parts.not-selected-equipment-modal-content' | translate }}"
    cancelBtnText="{{ 'catalog.select-equipment-modal.cancel-btn' | translate }}"
    primaryBtnText="{{ 'catalog.select-equipment-modal.submit-btn' | translate }}"
    [showModalConfirm]="showModalSelectEquipment"
    (closeModalConfirm)="showModalSelectEquipment = false"
    (primaryActionConfirm)="selectEquipment()">
</app-modal-confirm>
