<div
  class="textarea width-100"
  [ngClass]="[isActive || modelValue ? 'is-active' : '', isInvalid ? 'is-invalid': '', readOnly ? 'read-only' : '', styles.wrapper]"
  [attr.data-test]="'textarea-' + inputName"
  [formGroup]="form">
  <textarea
    class="text-area__input"
    formControlName="{{ inputName }}"
    [rows]="rows"
    [id]="'textfield'+inputName"
    [value]="modelValue"
    [name]="inputName"
    [maxlength]="maxLength"
    [required]="isRequired"
    (keyup)="onKey($event)"
    (click)="activate()"
    (blur)="onBlur()"
    [readonly]="readOnly">
  </textarea>
  <span *ngIf="icon" [ngClass]="icon"></span>
  <label class="textarea__label" [for]="'textfield'+inputName">
    {{ getLabel() }}
  </label>
</div>
