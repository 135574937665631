<div class="install-base">
  <h4 [attr.data-test]="'title-installBase'">{{ getTitle() | translate }}</h4>
  <app-access-control [description]="true">
    <ng-container ngProjectAs="[slot][content]">
      <div [appLoadingAnimation]="installBaseProductsLoading$ | async">
        <ng-container *ngIf="hasProducts">
          <ng-container *ngIf="installBaseProductsCount <= 8">
            <div class="row">
              <div class="col-3 row-margin-bottom" *ngFor="let product of installBaseProducts">
                <a class="card card--horizontal-md-up"
                   [attr.data-test]="'link-catalogParts'"
                   [routerLink]="['/catalog/parts']"
                   [queryParams]="{'rel-product-sysivk': product.attributes.materialNumber}"
                   (click)="proceedToInstalledBase(product)">
                  <div class="card__image card--2x1">
                    <div
                        class="aspect-ratio-16x9 aspect-ratio-md-3x4 aspect-ratio-lg-4x3 aspect-ratio-xl-16x9 aspect-ratio--cover image__loader">
                      <img [alt]="product.attributes.nameEnUs"
                           src="{{product.attributes.externalUrlSmall}}">
                    </div>
                  </div>
                  <div class="card__body">
                    <h6 [attr.data-test]="'title-productAttributeName-' + product.attributes.nameEnUs"
                        class="card__title">{{ product.attributes.nameEnUs }}</h6>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="installBaseProductsCount > 8">
            <div class="tabs " data-label-more="More…">
              <p [attr.data-test]="'desc-installBase'" class="install-base__desc">
                {{ 'install-base.description' | translate }}
              </p>
              <button [attr.data-test]="'button-activateDropdown'"
                      class="button icon-arrow-down-small tab-list__dropdown" (click)="activeDropdown()">
                {{ 'install-base.install-base-btn' | translate }}
              </button>
              <div class="bare-list tabs__list" [ngClass]="[active ? 'is-open':  '']">
                <ng-container *ngFor="let cat of categories; let i = index">
                  <span [attr.data-test]="'button-changeTab'"
                        [ngClass]="currentTab === cat ? 'tabs__item is-active is-active__bar':'tabs__item'"
                        (click)="changeTab(cat)">
                    {{ 'install-base.categories.' + cat.toLowerCase() | translate }}
                  </span>
                </ng-container>
              </div>
              <div class="install-base__selected-tab">
                {{ currentTab }}
              </div>
              <ng-container *ngFor="let cat of categories; let i = index">
                <div [ngClass]="currentTab === cat?'tabs__panel is-active':'tabs__panel'"
                     class="tabs__panel">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 row-margin-bottom"
                         *ngFor="let product of currentTabInstallBaseProducts">
                      <a class="card card--horizontal-md-up"
                         [attr.data-test]="'link-proceedToInstallBase'"
                         [routerLink]="['/catalog/parts']"
                         [queryParams]="{'rel-product-sysivk': product.attributes.materialNumber}"
                         (click)="proceedToInstalledBase(product)">
                        <div class="card__image card--2x1">
                          <div
                              class="aspect-ratio-16x9 aspect-ratio-md-3x4 aspect-ratio-lg-4x3 aspect-ratio-xl-16x9 aspect-ratio--cover">
                            <img [alt]="product.attributes.nameEnUs"
                                 src="{{product.attributes.externalUrlSmall}}">
                          </div>
                        </div>
                        <div class="card__body">
                          <h6 [attr.data-test]="'title-productAttributeName-' + product.attributes.nameEnUs"
                              class="card__title">{{ product.attributes.nameEnUs }}</h6>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!hasProducts">
          <p class="empty-state__text">{{ 'install-base.no-base-installed' | translate }}</p>
        </ng-container>
      </div>
    </ng-container>
  </app-access-control>
</div>
