import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';

import { CpqFacade } from '../../facades/cpq.facade';
import { FileType, FileUtils } from '../../utils/file.utils';
import { I18nService } from '../../services';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-cpq-document-download',
  templateUrl: './cpq-document-download.component.html',
  styleUrls: ['./cpq-document-download.component.scss'],
})
export class CpqDocumentDownloadComponent implements OnInit, OnDestroy {
  cartId: string;
  loading = false;
  isLoggedIn = false;
  private docDownloadErrorMsg: string;
  private docDownloadErrorMsgKey = 'configuration-summary.doc-download-error-message';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
    private activatedRoute: ActivatedRoute,
    private translate: I18nService,
    private cpqFacade: CpqFacade,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setDownloadErrorMessage();
    this.activatedRoute.queryParams
      .pipe(take(1))
      .subscribe(data => {
        if (data?.cartId) {
          this.getCpqDownloadDocument(data.cartId);
        } else {
          this.router.navigate([this.translate.getCurrentLocale()]).then();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCpqDownloadDocument(cartId: string): void {
    this.loading = true;
    this.cpqFacade.getCpqQuotePdfFile(cartId)
      .pipe(take(1))
      .subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_${cartId}`);
            this.loading = false;
            this.router.navigate(['purchase-activity']).then();
          },
          error: () => {
            this.configFacade.setAlert({
              type: 'error',
              message: this.docDownloadErrorMsg,
            });
            this.loading = false;
            this.router.navigate([this.translate.getCurrentLocale()]).then();
          },
        },
      );
  }

  private setDownloadErrorMessage(): void {
    this.translate.getTranslationByKey(this.docDownloadErrorMsgKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(message => this.docDownloadErrorMsg = message);
  }
}
