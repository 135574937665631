import { IBaseModel } from './common.models';
import { Iiso2Codes } from './enviroment-delivery-details.model';
import { EUserRoles } from '../configurations/common';

export interface ILanguage {
  id: string;
  code: string;
  name: string;
  defaultCurrencyLocale: string;
}

export interface IEndpointConfig {
  name: string;
  methods: Array<string>;
  mode?: string;
}

export interface IHealthCheck extends IBaseModel {
  attributes: {
    status: string;
    statusCode: number;
    message: string;
    healthCheckServiceResponses: {
      name: string;
      status: boolean;
      message: string;
    }[]
  };
}

export interface INavigationResponse {
  data: INavigation;
}

export interface INavigation extends IBaseModel {
  attributes: {
    nodes: INavigationNodes[];
    name: string;
    isActive: boolean;
  };
  links: {
    self: string;
  };
}

export interface INavigationNodes {
  resourceId: number;
  nodeType?: string;
  isActive?: boolean;
  title?: string;
  url?: string;
  imageUrl?: string;
  description?: string;
  cssClass?: string;
  validFrom?: string;
  validTo?: string;
  queryParams?: { [key: string]: any }
  children?: INavigationNodes[];
  whitelistedRoles?: EUserRoles[];
}

export interface IExternalUrl {
  id: string;
  lang: string;
  value: string;
}

export interface IStore {
  timeZone?: string;
  storeDefaultCurrency: string;
  storeDefaultPriceMode: string;
  storeId: string;
  marketCode: string;
  name: string;
  dateFormat: string;
  timeFormat: string;
  address?: ILocalizedStoreAddress;
  currencies?: {
    code: string;
    name: string;
  }[];
  languages: ILanguage[];
  externalUrls: IExternalUrl[];
  countries?: {
    iso2Code: string;
    iso3Code: string;
    name: string;
    postalCodeMandatory: boolean;
    postalCodeRegex: string;
    regions: {
      iso2Code: string;
      name: string;
    }[];
  }[];
  deliveryAddressCountries: Iiso2Codes[];
  active: boolean;
  redirect: boolean;
  showPriceDisclaimer: boolean;
  hiddenStoreUserMenu: string[];
  addOrderTypeToAdlytics: boolean;
  catalogFilters: string[];
  ecommerceTeamEmailAddress?: string;
  emailGrievancesOfficer?: string;
  defaultPriceDivisorValue: number;
  paginationSettingsPerFeature: {
    orders: IPaginationBasic,
    sparePartOrders: IPaginationBasic,
    serviceOrders: IPaginationBasic,
  },
  defaultShipmentMethod: {
    name: string,
  },
}

export interface ILocalizedStoreAddress {
  [lang: string]: IStoreAddress;
}

export interface IStoreAddress {
  companyName: string;
  address1: string;
  address2?: string;
  address3?: string;
  phoneNumber?: string;
  phoneNumberFormat?: string;
  emailAddress?: string;
  prefix?: string;
  delimiter?: string;
  needHelp?: INeedHelp;
}

export interface INeedHelp {
  questionsAboutParts: {
    phoneNumber?: string,
    phoneNumberAdditionalInfo?: string,
    directSalesEmailAddress?: string,
    partsClarificationEmailAddress?: string
  },
  questionsAboutContracts: {
    phoneNumber?: string,
    phoneNumberAdditionalInfo?: string,
    emailAddress?: string
  },
  websiteAndTechnicalSupport: {
    phoneNumber?: string,
    phoneNumberAdditionalInfo?: string,
    emailAddress?: string
  }
}

export interface IUrlResolver extends IBaseModel {
  attributes: {
    entityType: string;
    entityId: string;
  };
}

export interface IPagination {
  numFound: number;
  currentPage: number;
  maxPage: number;
  currentItemsPerPage: number;
  config: {
    parameterName: string,
    itemsPerPageParameterName: string,
    defaultItemsPerPage: number,
    validItemsPerPageOptions: number[]
  };
}

export interface IPaginationBasic {
  limitPerPage: number,
}

export interface IPaginationCurrent extends IPaginationBasic {
  currentPage: number,
  currentOffset: number,
  prevPage: number,
  prevOffset: number
  nextPage: number,
  nextOffset: number
  lastPage: number,
  lastOffset: number,
  firstPage: number,
  firstOffset: number,
}

export interface ISortPage {
  sort: {
    sortParamNames: string[];
    sortParamLocalizedNames: {
      rating: null,
      name_asc: null,
      name_desc: null,
      price_asc: null,
      price_desc: null
    };
    currentSortParam: null;
    currentSortOrder: null;
  };
}

export interface IGlueUrlConfig {
  store: string;
  url: string;
}

export interface ISortOptions {
  name: string;
  value: string;
  reversed: boolean;
  time?: boolean;
  numeric?: boolean;
  translation?: string;
}

export interface ISortOption {
  name: string;
  value: string;
}

export interface IGenericHeader {
  label: string;
  width: string;
  propName: string;
  sortOptions: [
    {
      name: string;
      value: string;
      reversed: boolean;
      translation: string;
      time: boolean;
    }
  ];
}

export enum IconType {
  CHECK = 'check',
  CLOSE = 'close',
  WARNING = 'warning'
}
