import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CpqFacade } from '../../facades/cpq.facade';
import { OrdersFacade } from '../../facades/orders.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { EScenarioType } from '../../configurations/order-status';
import { FileType, FileUtils } from '../../utils/file.utils';
import { IStoreAddress } from '../../models/settings.model';
import { MathUtils } from '../../utils/math.utils';
import { I18nService } from '../../services';

@Component({
  selector: 'app-thank-you-for-quote',
  templateUrl: './thank-you-for-quote.component.html',
  styleUrls: ['./thank-you-for-quote.component.scss'],
})

export class ThankYouForQuoteComponent implements OnInit, OnDestroy {
  storeAddress: IStoreAddress;
  phone: string;
  email: string;
  phoneNumberFormat: string;
  prefix: string;
  delimiter: string;
  scenarioType = EScenarioType.CPQ;
  currentStatus: string;
  details: any;
  nickname: string;
  orderDetails: any;
  cartName: string;
  cpqQuotePrice: number;
  loadingPageData = true;
  quoteDocumentGenerationInProgress: boolean = false;

  private unsubscribe$ = new Subject<void>();
  private docDownloadErrorMsgKey = 'configuration-summary.doc-download-error-message';
  private docDownloadErrorMsg: string;

  constructor(
    private translate: I18nService,
    private cpqFacade: CpqFacade,
    private ordersFacade: OrdersFacade,
    private configFacade: ConfigurationFacade,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setDownloadErrorMessage();
    this.selectLastSubmittedOrder();
    this.getStoreAddressInfo();
    this.detectBrowserBackBtn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateEquType(items: any): number {
    const materialNumbers = [...new Set(items.map(item => item.systemDetails.materialNumber))];
    return materialNumbers ? materialNumbers.length : 0;
  }

  downloadDocumentation(): void {
    this.quoteDocumentGenerationInProgress = true;
    this.cpqFacade.getCpqQuotePdfFile(this.orderDetails.orderReference)
      .pipe(take(1))
      .subscribe({
          next: file => {
            FileUtils.saveAndOpenFile(file, FileType.PDF, `Quote_${this.orderDetails.orderReference}.pdf`);
            this.quoteDocumentGenerationInProgress = false;
          },
          error: () => {
            this.configFacade.setAlert({
              type: 'error',
              message: this.docDownloadErrorMsg,
            });
            this.quoteDocumentGenerationInProgress = false;
          },
        },
      );
  }

  startNewQuote(): void {
    this.cpqFacade.thankYouPageStartNewQuote();
  }

  viewPurchaseActivity(): void {
    this.cpqFacade.thankYouPageViewPurchaseActivity();
  }

  private detectBrowserBackBtn(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.viewPurchaseActivity();
        }
      });
  }

  private retrieveOrderDetails(id: string): void {
    this.ordersFacade.getOrderById(id)
      .pipe(take(1))
      .subscribe(response => {
        const {items, stateDisplayName, quoteName, totals} = response.data.attributes;
        this.details = items;
        this.currentStatus = stateDisplayName;
        this.cartName = quoteName;
        this.nickname = MathUtils.checkIfNumeric(this.cartName) ? '' : '(' + this.cartName + ')';
        this.cpqQuotePrice = totals.grandTotal;
        this.loadingPageData = false;
      });
  }

  private getStoreAddressInfo(): void {
    this.storeAddress = this.configFacade.getCurrentStoreAddress();
    if (this.storeAddress) {
      this.phone = this.storeAddress.phoneNumber;
      this.email = this.storeAddress.emailAddress;
      this.phoneNumberFormat = this.storeAddress.phoneNumberFormat;
      this.prefix = this.storeAddress.prefix;
      this.delimiter = this.storeAddress.delimiter;
    }
  }

  private selectLastSubmittedOrder(): void {
    this.cpqFacade.selectLastSubmittedOrder()
      .pipe(take(1))
      .subscribe(order => {
        if (order) {
          this.orderDetails = order;
          this.retrieveOrderDetails(this.orderDetails.orderReference);
        } else {
          this.cpqFacade.thankYouPageRedirectToLastPage();
        }
      });
  }

  private setDownloadErrorMessage(): void {
    this.translate.getTranslationByKey(this.docDownloadErrorMsgKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(message => this.docDownloadErrorMsg = message);
  }
}
