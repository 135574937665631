import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICpqResponseResponse } from '../../../models/cpq.models';
import { IconType } from '../../../models/settings.model';

@Component({
  selector: 'app-conflict-modal',
  templateUrl: './conflict-modal.component.html',
  styleUrls: ['./conflict-modal.component.scss']
})
export class ConflictModalComponent {
  iconType = IconType;
  @Input() open = false;
  @Input() conflict: ICpqResponseResponse;
  @Output() accept = new EventEmitter<any>();
  @Output() undo = new EventEmitter<any>();
}
