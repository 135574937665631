import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { I18nService } from '../services';
import { AppUtils } from '../utils/app.utils';
import { EFeatureToggles, EStoreTypes } from '../configurations/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SapP40Guard implements CanActivate {
  isUsStore: boolean = false;
  isCaStore: boolean = false;

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private configFacade: ConfigurationFacade
  ) {
  }

  canActivate(): Observable<boolean> {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);

    return this.configFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(map(featureToggle => {
      if (!featureToggle && (this.isUsStore || this.isCaStore)) {
        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
        return false;
      }
      return true;
    }));
  }
}
