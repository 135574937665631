<app-message *ngIf="priceDisputing?.isSet" [message]="itemDisputedPricingMessage"></app-message>
<div class="price-disputing__header item-row">
  <button class="button button--link button--price-disputing"
          (click)="toggle()"
          [attr.data-test]="'button-priceDisputing'"
          [disabled]="isSaturdayShipment">
    {{ 'price-disputing.toggle-button' | translate }} <span [ngClass]="isOpened ? 'icon-arrow-up-large' : 'icon-arrow-down'"></span>
  </button>
  <button class="button button--link button--delete-item"
          [attr.data-test]="'button-deleteItem'"
          (click)="removeCartItemEmit(currentItem.id)">
    {{ 'multi-cart.delete-item' | translate }} <span class="icon-close"></span>
  </button>
</div>
<div *ngIf="isOpened" class="price-disputing__component">
  <div class="price-disputing__label">
    {{ 'price-disputing.label' | translate }}
  </div>
  <app-textarea
      [form]="priceDisputingForm"
      [modelValue]="priceDisputingForm.value.message"
      [rows]="6"
      [inputName]="'message'"
      label=""
      [maxLength]="maxLengthMessage">
  </app-textarea>
  <div class="price-disputing__footer item-row">
    <div class="price-disputing__negative-btns">
      <button class="button button--clear" (click)="clearPriceDisputing()" [attr.data-test]="'button-clear'">
        {{ 'price-disputing.clear-button' | translate }}
      </button>
      <button class="button button--cancel" (click)="cancelPriceDisputing()" [attr.data-test]="'button-cancel'">
        {{ 'price-disputing.cancel-button' | translate }}
      </button>
    </div>
    <button class="button button--save" [attr.data-test]="'button-confirm'" (click)="setPriceDisputing()">
      {{ 'price-disputing.confirm-button' | translate }}
    </button>
  </div>
  <div class="price-disputing__alerts">
    <ng-container *ngIf="priceDisputingForm.value.message">
      <small class="textfield__hint">
        {{ priceDisputingForm.value.message.length }}/{{ maxLengthMessage }}
      </small>
    </ng-container>
  </div>
</div>
