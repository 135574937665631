import { Injectable, Injector } from '@angular/core';
import { INavigationResponse } from '../models/settings.model';
import { NavigationBarService } from '../services';
import { OpalService } from '../services/opal.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpalFacade {

  private pOpalService:  OpalService    ;
  constructor(
    private injector: Injector,
  ) {
  }
  public get opalService(): OpalService {
    if (!this.pOpalService) {
      this.pOpalService = this.injector.get(OpalService);
    }
    return this.pOpalService;
  }
  getOpal(fl:string): Observable<any> {
    return this.opalService.getOpal(fl);
  }

}
