import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { CustomerFacade } from '../facades/customer.facade';
import { TermConstants } from '../shared/terms/TermConstants';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private customerFacade: CustomerFacade,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return combineLatest([
      this.authService.isAuthenticated$,
      this.customerFacade.selectCustomerLoadedGuardData(),
    ]).pipe(
      filter(([isAuthenticated, customerLoadedGuardData]) =>
        isAuthenticated &&
        !customerLoadedGuardData.loadFailed ? customerLoadedGuardData.consentedTerms !== undefined : true,
      ),
      map(([isAuthenticated, customerLoadedGuardData]) => {
        if (isAuthenticated && !customerLoadedGuardData.isGuest) {
          const termsOfUseKeys = TermConstants.termKeys.TERMS_OF_USE;

          if (!customerLoadedGuardData.consentedTerms.includes(termsOfUseKeys.termsKey)) {
            this.router.navigate(['terms', termsOfUseKeys.urlKey]).then();
            return false;
          }
        }

        return true;
      })
    );
  }
}
