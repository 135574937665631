import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharedService {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _cartId: string;

  get cartId(): string {
    return this._cartId;
  }
  set cartId(val: string) {
    this._cartId = val;
  }

}
