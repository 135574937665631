export class UiUtils{

  static confirmOrCancelIcon(currentStatus: string, canceledStatus: string, declineStatus?: string, ): string {
    if (!currentStatus) {
      return '';
    }
    let icon;
    if ( (declineStatus && currentStatus.toLowerCase() === declineStatus.toLowerCase()) ||
      (currentStatus.toLowerCase() === canceledStatus.toLowerCase()) ){
      icon = 'close';
    } else {
      icon = 'check';
    }
    return `icon-${icon} icon-${icon}__circle icon-check-styling`;
  }

  static singularOrPlural(value: number, translateKey: string): string {
    return value > 1 ? `${translateKey}-plural` : `${translateKey}-singular`;
  }

  static scrollToTop(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }
}
