import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { EUserRoles } from '../../configurations/common';
import * as ValidationPatterns from '../../configurations/validations';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { CpqFacade } from '../../facades/cpq.facade';
import { IWrongAddress } from '../../models/checkout.models';
import { IAddress, ISelectEvent } from '../../models/common.models';
import { IWrongAddressForCpq } from '../../models/cpq.models';
import { IAddressExtended } from '../../models/customer.models';
import { AddressUtils } from '../../utils/address.utils';

@Component({
  selector: 'app-report-wrong-address-modal',
  templateUrl: './report-wrong-address-modal.component.html',
  styleUrls: ['./report-wrong-address-modal.component.scss'],
})
export class ReportWrongAddressModalComponent implements OnInit, OnChanges {
  @Input() showModal: boolean;
  @Input() userLoggedInRoles: EUserRoles[] = [];
  @Input() addressesList: IAddressExtended[] = [];
  @Input() roleSpecificMessage: boolean;
  @Input() cpqOnly: boolean;
  @Input() billOrPayer = false;
  @Input() functionalLocations: string[];
  @Input() cpqLabelName: string;
  @Input() isQuoteSummaryComponent = false;
  @Input() quoteId: string;
  @Input() cpqAddressType = '';
  @Input() resetAddressList: boolean = false;
  @Output() closeModal = new EventEmitter<any>();

  wrongAddressForm: UntypedFormGroup;
  dataToSend: IWrongAddress;
  dataToSendForCpq: IWrongAddressForCpq;
  addresses: IAddressExtended[] = [];
  loading: boolean;
  addressesLoading: boolean;
  maxLengthComment = 500;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private checkoutFacade: CheckoutFacade,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.addressesLoading = !this.isQuoteSummaryComponent;
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue !== changes.showModal.previousValue) {
      if (this.addressesList) {
        if (this.resetAddressList) {
          this.addresses = [];
        }

        if (this.cpqOnly) {
          AddressUtils.appendAddressesForCpq(this.addresses, this.addressesList, this.billOrPayer);
        } else {
          AddressUtils.appendAddresses(this.addresses, this.addressesList, 'business');
        }

        if (this.addresses?.length === 1) {
          this.wrongAddressForm?.patchValue({
            deliveryAddress: AddressUtils.isAddressValid(this.addressesList[0] as IAddress) 
              ? this.addresses[0] 
              : { name: `SAP ID - ${this.addressesList[0]?.sapId}`, value: this.addressesList[0] }
          });
        }
      }

      this.addressesLoading = false;
    }
  }

  initializeForm(): void {
    this.wrongAddressForm = this.formBuilder.group({
      deliveryAddress: ['', Validators.required],
      comment: ['', [Validators.required, Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  isApprover(): boolean {
    return this.userLoggedInRoles.includes(EUserRoles.Approver) || this.userLoggedInRoles.includes(EUserRoles.Admin);
  }

  reportAddress(): void {
    if (this.wrongAddressForm.status === 'VALID') {
      if (this.cpqOnly) {
        this.dataToSendForCpq = {
          type: 'cpq-address-report-wrong',
          attributes: {
            addressType: this.cpqAddressType,
            functionalLocations: this.functionalLocations,
            address: this.wrongAddressForm.value.deliveryAddress.name,
            quoteUuid: this.quoteId,
            comment: this.wrongAddressForm.value.comment
          }
        };

        this.cpqFacade.postCpqWrongAddress({data: this.dataToSendForCpq}).then(
          () => this.postDataActions()
        );
      } else {
        this.dataToSend = {
          type: 'addresses-report-wrong',
          attributes: {
            addressUuid: this.wrongAddressForm.value.deliveryAddress.value.id,
            comment: this.wrongAddressForm.value.comment,
          }
        };

        this.checkoutFacade.postWrongAddress({data: this.dataToSend}).then(
          () => this.postDataActions()
        );
      }

      this.wrongAddressForm.reset();
    }
  }

  showLoader(): void {
    this.loading = true;
  }

  resetForm(): void {
    this.closeModal.emit();
    this.wrongAddressForm.reset();
  }

  formIsValid(): boolean {
    return this.wrongAddressForm.status === 'VALID';
  }

  selectWrongAddress(event: ISelectEvent): void {
    this.wrongAddressForm.patchValue({
      deliveryAddress: event
    });
  }

  setCommentWrongAddress(value: string): void {
    this.wrongAddressForm.patchValue({
      comment: value
    });
  }

  private postDataActions(): void {
    this.loading = false;
    this.closeModal.emit();
    this.cpqFacade.showAdditionalDeliverableNotification(
      'success',
      'report-wrong-address.reported',
      [{
        key: 'report-wrong-address.reported-desc',
        params: {},
        css: [],
      }],
      []
    );
  }
}
