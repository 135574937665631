import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StoreConfigurationActions } from '../actions';
import { IStoreConfiguration } from '../models/store-configuration.models';
import * as fromState from '../reducers';
import * as StoreConfigurationSelector from '../reducers/store-configuration.reducer';
import { StoreConfigurationService } from '../services/store-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class StoreConfigurationFacade {
  private pStoreService: StoreConfigurationService;

  public get getService(): StoreConfigurationService {
    if (!this.pStoreService) {
      this.pStoreService = this.injector.get(StoreConfigurationService);
    }
    return this.pStoreService;
  }

  constructor(
    private injector: Injector,
    private store: Store<fromState.State>
  ) {
  }

  public getStoreConfiguration(storeId: string): Observable<IStoreConfiguration> {
    return this.getService.getStoreConfiguration(storeId);
  }

  public dispatchFetchStoreConfiguration(storeId: string): void {
    this.store.dispatch(StoreConfigurationActions.retrieveStoreConfiguration({store: storeId}));
  }

  public selectIsRfqOnly(): Observable<boolean> {
    return this.store.select(StoreConfigurationSelector.getIsRfqOnly);
  }

  public selectMinimumOrderValue(): Observable<number> {
    return this.store.select(StoreConfigurationSelector.getMinimumOrderValue);
  }
}
