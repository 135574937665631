import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationFacade } from '../facades/configuration.facade';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(
    private router: Router, 
    private configFacade: ConfigurationFacade
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const parsedUrl = this.router.parseUrl(state.url);
    const languageSegment = parsedUrl.root.children.primary ? parsedUrl.root.children.primary.segments[0] : undefined;

    if (languageSegment) {
      if (languageSegment.path.match(this.configFacade.getLanguageRegex())) {
        const [language, marketCode] = this.configFacade.getLangParamData(languageSegment.path);

        if (
          this.configFacade.validateMarket(marketCode) && 
          this.configFacade.validateLanguage(`${language}-${marketCode.toUpperCase()}`)
        ) {
          return true;
        }

        this.router.navigateByUrl('/page-not-found').then();
      }
    }

    return true;
  }
}
