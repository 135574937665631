<div *ngIf="!showAlmostDone; else almostDone">
  <app-quote-summary
      (showAlmostDone)="showAlmostDone=$event">
  </app-quote-summary>
</div>
<ng-template #almostDone>
  <app-almost-done
      [showAlmostDone]="showAlmostDone"
      (showQuoteSummary)="showAlmostDone=$event">
  </app-almost-done>
</ng-template>
