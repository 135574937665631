<div class="contract-details">
  <ng-container *ngIf="!loadingCartDataInProgress; else loading">
    <h4 class="contract-details__title">{{ 'service-configuration.contract-details' | translate }}</h4>
    <p class="contract-details__tip">{{ 'service-configuration.config-tip' | translate }}</p>
    <div class="configuration">
      <span class="configuration__title">
        {{ 'service-configuration.contract-start-date' | translate }}
        <app-tooltip
          class="accordion__category-details__info-icon"
          link="icon"
          text="{{ 'service-configuration.contract-start-date-desc' | translate }}">
        </app-tooltip>
      </span>
      <app-datepicker
          class="contract-details__datepicker" [attr.data-test]="'dropdown-date'"
          label="{{ 'service-configuration.date-label' | translate }}"
          [minimumDaysFromToday]="minimumDaysFromToday"
          [maxDate]="endOfSupportDate"
          [dateFormat]="'m/d/Y'"
          [inputName]="'contractStartDate'"
          [disabled]="saveInProgress"
          [modelValue]="contractStartDate"
          (selected)="emitStartDate($event)">
      </app-datepicker>
    </div>
    <div class="configuration">
      <span class="configuration__title">
        {{ 'service-configuration.choose-end-date' | translate }}
        <app-tooltip
          class="accordion__category-details__info-icon"
          link="icon"
          text="{{ 'service-configuration.choose-end-date-desc' | translate }}">
        </app-tooltip>
      </span>
      <app-select-box
          class="configuration__option"
          [options]="contractEndDateOptions"
          [inputName]="'contractDurationYears'"
          label="{{ 'service-configuration.choose-end-date-label' | translate }}"
          [modelValue]="contractDurationYears"
          [showLabelWhenSelected]="false"
          [disabled]="saveInProgress"
          (selected)="updateContractDurationYears($event)">
      </app-select-box>
      <span class="configuration__tip" *ngIf="!!endOfSupportDate">
        {{ 'service-configuration.end-of-support' | translate: {date: endOfSupportDate} }}</span>
    </div>
    <div class="configuration">
      <span class="configuration__title">
        {{ 'service-configuration.ship-to-address' | translate }}
        <app-tooltip
          class="accordion__category-details__info-icon"
          link="icon"
          text="{{ 'service-configuration.ship-to-address-desc' | translate }}">
        </app-tooltip>
      </span>
      <span class="configuration__option address">{{ shipAddress }}</span>
      <div class="report-wrong-address">
        <button class="text action" [attr.data-test]="'button-reportWrongAddressComponent'"
                (click)="showReportWrongShipToAddressModal()">
          {{ 'quote-summary.report-wrong-address' | translate }}
        </button>
        <span class="icon icon-arrow-right-small"></span>
      </div>
    </div>
    <app-report-wrong-address-modal
        [showModal]="showWrongAddressModal"
        (closeModal)="showWrongAddressModal = false"
        [addressesList]="shipToAddresses"
        [cpqAddressType]="cpqAddressType"
        [cpqOnly]="true"
        [functionalLocations]="getMultipleFunctionalLocations(cart)"
        [quoteId]="cart?.id || ''"
        [cpqLabelName]="'quote-summary.ship-to-address' | translate">
    </app-report-wrong-address-modal>

    <div class="contract-details__actions">
      <button type="button" class="button button--primary"  [attr.data-test]="'button-reviewSummary'"
              [disabled]="saveInProgress" (click)="goToReviewSummary.emit()">
        {{ 'service-configuration.review-summary' | translate }}
      </button>
      <span *ngIf="saveInProgress" class="contract-details__actions-marker">
          <span class="loading-animation"></span>
        {{ 'service-configuration.saving' | translate }}
        </span>
      <span *ngIf="showSaveDone && !saveInProgress" class="contract-details__actions-marker save-done">
          <span class="icon icon-check icon-check__circle"></span>
          <span>{{ 'service-configuration.save-done' | translate }}</span>
        </span>
      <button type="button" class="button" [disabled]="saveInProgress" (click)="goBack.emit()">
        {{ 'service-configuration.back-step-btn' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="configuration configuration__loader">
      <div class="loading-animation"></div>
    </div>
  </ng-template>
</div>
