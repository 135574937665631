<div class="category-tree">
  <ng-container *ngIf="showCategories()">
    <div class="category-tree__parent" *ngFor="let category of currentCategories">
      <app-category-tree-child
        [params]="params"
        [category]="category"
        [useNavigate]="useNavigate">
      </app-category-tree-child>
      <span [attr.data-test]="'button-toggleCategory-' + category.name"
          *ngIf="category.children.length > 0"
          class="category-tree__hide"
          [ngClass]="visibleCategories.includes(category.name) ? 'icon-arrow-up-large': 'icon-arrow-down'"
          (click)="toggleCategory(category)">
      </span>
      <ng-container *ngFor="let cat of category.children">
        <ul class="category-tree__children" [ngClass]="{'hidden': !visibleCategories.includes(category.name)}">
          <li>
            <app-category-tree-child
                [params]="params"
                [category]="cat"
                [useNavigate]="useNavigate">
            </app-category-tree-child>
            <ul class="category-tree__children nested">
              <li *ngFor="let child of cat.children">
                <app-category-tree-child
                    [params]="params"
                    [category]="child"
                    [useNavigate]="useNavigate">
                </app-category-tree-child>
                <ul class="category-tree__children nested">
                  <li *ngFor="let child of child.children">
                    <app-category-tree-child
                        [params]="params"
                        [category]="child"
                        [useNavigate]="useNavigate">
                    </app-category-tree-child>
                  </li>
                </ul>
            </ul>
          </li>
        </ul>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="showCategoriesLoader()">
    <span class="stripe stripe-short toplevel"></span>
    <ul class="category-tree__children">
      <li class="category-tree__title main">
        <span class="stripe stripe-half"></span>
        <ul class="category-tree__children nested">
          <li class="stripe stripe-long"></li>
          <li class="stripe stripe-long"></li>
          <li class="stripe stripe-long"></li>
        </ul>
      </li>
    </ul>
  </ng-container>
</div>
