import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Location } from '@angular/common';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';

if (environment.production) {
  enableProdMode();
}

if (window && environment.hideConsoleLogs){
  window.console.log = () => {};
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
const __stripTrailingSlash = (Location as any).stripTrailingSlash;
(Location as any).stripTrailingSlash = function _stripTrailingSlash(url: string): string {
  const queryString$ = url.match(/([^?]*)?(.*)/);
  // if (queryString$[2].length > 0) {
  //   return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
  // }
  return /^[a-z]{2}-[a-z]{2}$/.test(url) ? url + '.' : __stripTrailingSlash(url);
};

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
