<app-modal
    [id]="'modalEosEquipment'"
    [open]="showModalEosEquipment"
    [type]="iconType.WARNING"
    title="{{ modalTitle | translate }}"
    [showCrossCloseBtn]="false">
  <ng-container ngProjectAs="[slot][body]">
    <p>{{ modalText | translate }}</p>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button
        *ngIf="showCancelBtn"
        type="reset"
        class="button button"
        data-dismiss="modal-dialog"
        (click)="closeModal()">
      {{ cancelBtnText | translate }}
    </button>
    <button
        type="submit"
        class="button button--primary"
        data-dismiss="modal-dialog"
        (click)="confirmModal()">
      {{ confirmBtnText | translate }}
    </button>
  </ng-container>
</app-modal>
