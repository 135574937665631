import { createAction, props } from '@ngrx/store';
import { IInstalledBaseSystemDataEquipmentSelectionDetails, ISystemDetails } from '../models/common.models';

const sparePartAPIName = '[SparePart API]';

export const addItem = createAction(
  `${sparePartAPIName} Add new item`,
  props<{item: any}>(),
);

export const addAllItems = createAction(
  `${sparePartAPIName} Add all items`,
  props<{items: any[]}>(),
);

export const updateReorderWithSystemDetails = createAction(
  `${sparePartAPIName} Update reorder with system details`,
  props<{updatedSystemDetails: ISystemDetails}>(),
);

export const updateReorderWithSystemDetailsForFunctionalLocation = createAction(
  `${sparePartAPIName} Update reorder with system details`,
  props<{updatedSystemDetails: IInstalledBaseSystemDataEquipmentSelectionDetails}>(),
);

export const confirmReorder = createAction(
  `${sparePartAPIName} Confirm reorder`,
);

export const completeReorder = createAction(
  `${sparePartAPIName} Complete reorder`,
);

export const cancelReorder = createAction(
  `${sparePartAPIName} Cancel reorder`,
);
