import {
  genericDateSortOption,
  genericNumberSortOption,
  genericTextSortOption
} from '../../../../configurations/sort-options';

export const underReviewHeaders = [
  {
    label: 'service-purchase-activity.quote-id',
    width: '35',
    sortOptions: genericTextSortOption('id'),
    propName: 'id'
  },
  {
    label: 'service-purchase-activity.quote-nickname',
    width: '15',
    sortOptions: genericTextSortOption('quoteName'),
    propName: 'quoteName'
  },
  {
    label: 'service-purchase-activity.no-of-systems',
    width: '15',
    sortOptions: genericNumberSortOption('itemsTotalNumber'),
    propName: 'itemsTotalNumber'
  },
  {
    label: 'service-purchase-activity.submitted-on',
    width: '20',
    sortOptions: genericDateSortOption('createdAt'),
    propName: 'createdAt'
  },
  {
    label: 'service-purchase-activity.status',
    width: '15',
    sortOptions: genericTextSortOption('stateDisplayName'),
    propName: 'stateDisplayName'
  }
];

export const fulfilledHeaders = [
  {
    label: 'service-purchase-activity.contract-number',
    width: '20',
    sortOptions: genericTextSortOption('contractNumber'),
    propName: 'contractNumber',
  },
  {
    label: 'service-purchase-activity.quote-id',
    width: '35',
    sortOptions: genericTextSortOption('id'),
    propName: 'id'
  },
  {
    label: 'service-purchase-activity.quote-nickname',
    width: '15',
    sortOptions: genericTextSortOption('quoteName'),
    propName: 'quoteName'
  },
  {
    label: 'service-purchase-activity.no-of-systems',
    width: '15',
    sortOptions: genericNumberSortOption('itemsTotalNumber'),
    propName: 'itemsTotalNumber'
  }
];

export const functionalLocationTable = [
  {
    label: 'service-purchase-activity.functional-location',
    width: '15',
    propName: 'functionalLocation'
  },
  {
    label: 'service-purchase-activity.equipment-type',
    width: '20',
    propName: 'equipmentType'
  },
  {
    label: 'service-purchase-activity.service-plan',
    width: '10',
    propName: 'servicePlan'
  },
  {
    label: 'service-purchase-activity.contract-start',
    width: '15',
    propName: 'contractStartDate'
  },
  {
    label: 'service-purchase-activity.contract-end',
    width: '15',
    propName: 'contractEndDate'
  },
  {
    label: 'service-purchase-activity.annual-price',
    width: '15',
    propName: 'price'
  },
];
