import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppUtils } from '../utils/app.utils';
import { LocalStorageUtils } from '../utils/localStorage.utils';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    prefix?: string,
    suffix?: string,
  ) {
  }

  getTranslation(lang: string): Observable<Object> {
    this.clearUnusedCacheTranslationData();

    if (this.isSetToUpdate(lang)) {
      const arakhTranslations = this.arakhApiTranslationsCall(lang);
      const arakhsFeatureToggles = this.arakhApiFeatureTogglesCall(lang);
      const arakhConfigs = this.arakhApiConfigsCall(lang)

      return forkJoin([
        arakhTranslations,
        arakhsFeatureToggles,
        arakhConfigs,
      ]).pipe(
        map(([arakhTranslations, arakhsFeatureToggles, arakhConfigs]) => {
            return {
              ...arakhTranslations,
              ...arakhsFeatureToggles,
              ...arakhConfigs,
            };
          },
        ),
      );
    }

    return forkJoin([
      of(JSON.parse(LocalStorageUtils.getKeyValue(`lang-data-${lang}`))),
      of(JSON.parse(LocalStorageUtils.getKeyValue(`feature-toggles-data-${lang}`))),
      of(JSON.parse(LocalStorageUtils.getKeyValue(`config-data-${lang}`))),
    ]).pipe(
      map(([arakhTranslations, arakhsFeatureToggles, arakhConfigs]) => {
          return {
            ...arakhTranslations,
            ...arakhsFeatureToggles,
            ...arakhConfigs,
          };
        },
      ),
    );
  }

  private arakhApiTranslationsCall(lang: string): Observable<Object> {
    const store = this.getStore(`${lang}`);

    const arakhUrl = `${environment.arakhUrl}/i18n${this.setStore(store)}.json`;
    const localDefaultFileUrl = `/assets/arakh_i18n/i18n-en.json`;
    const localLocalizedFileUrl = `/assets/arakh_i18n/i18n${store}.json`;

    this.setUpdateDate(lang);

    return this.httpClient.get<Object>(arakhUrl).pipe(
      map(data => {
        LocalStorageUtils.setKeyValue(`lang-data-${lang}`, JSON.stringify(data));
        return data;
      }),
      catchError((): Observable<Object> => {
        return forkJoin([
          this.httpClient.get<Object>(localDefaultFileUrl),
          this.httpClient.get<Object>(localLocalizedFileUrl),
        ]).pipe(
          map(([defaultTranslations, localizedTranslations]) => {
            LocalStorageUtils.setKeyValue(`lang-data-${lang}`, JSON.stringify({
                ...defaultTranslations,
                ...localizedTranslations,
              }
            ));

            return {
              ...defaultTranslations,
              ...localizedTranslations,
            };
          }),
        );
      }),
    );
  }

  private arakhApiFeatureTogglesCall(lang: string): Observable<Object> {
    let store = this.getStoreForConfigs('');
    const arakhUrl = `${environment.arakhUrl}/toggles${store}.json`;

    return this.httpClient.get<Object>(arakhUrl).pipe(
      map(data => {
        LocalStorageUtils.setKeyValue(`feature-toggles-data-${lang}`, JSON.stringify(data));
        return data;
      }),
      catchError((): Observable<Object> => {
        store = this.getStoreForConfigs('local_env');
        const localDefaultFileUrl = `/assets/arakh_feature_toggles/toggles.json`;
        const localLocalizedFileUrl = `/assets/arakh_feature_toggles/toggles${store}.json`;

        return forkJoin([
          this.httpClient.get<Object>(localDefaultFileUrl),
          this.httpClient.get<Object>(localLocalizedFileUrl),
        ]).pipe(
          map(([defaultTranslations, localizedTranslations]) => {
            LocalStorageUtils.setKeyValue(`feature-toggles-data-${lang}`, JSON.stringify({
                ...defaultTranslations,
                ...localizedTranslations,
              }
            ));

            return {
              ...defaultTranslations,
              ...localizedTranslations,
            };
          }),
        );
      }),
    );
  }

  private arakhApiConfigsCall(lang: string): Observable<Object> {
    let store = this.getStoreForConfigs('');
    const arakhUrl = `${environment.arakhUrl}/config${store}.json`;

    return this.httpClient.get<Object>(arakhUrl).pipe(
      map(data => {
        LocalStorageUtils.setKeyValue(`config-data-${lang}`, JSON.stringify(data));
        return data;
      }),
      catchError((): Observable<Object> => {
        store = this.getStoreForConfigs('local_env');
        const localDefaultFileUrl = `/assets/arakh_configs/config.json`;
        const localLocalizedFileUrl = `/assets/arakh_configs/config${store}.json`;

        return forkJoin([
          this.httpClient.get<Object>(localDefaultFileUrl),
          this.httpClient.get<Object>(localLocalizedFileUrl),
        ]).pipe(
          map(([defaultTranslations, localizedTranslations]) => {
            LocalStorageUtils.setKeyValue(`config-data-${lang}`, JSON.stringify({
              ...defaultTranslations,
              ...localizedTranslations,
            }
            ));

            return {
              ...defaultTranslations,
              ...localizedTranslations,
            };
          }),
        );
      }),
    );
  }

  private setStore(store: string): string {
    const env = environment.name;
    const storeId = LocalStorageUtils.getKeyValue('storeId');

    if (storeId === '') {
      return store;
    }
    switch (env) {
      case 'production':
        return store;
      case 'local':
        return store;
      case 'defaults':
        return store;
      default:
        return store + '-' + env;
    }
  }

  private getStore(lang: string): string {
    const store = LocalStorageUtils.getKeyValue('storeId');
    const [language] = lang.split('-');

    switch (store) {
      case '':
        return '-default';
      default:
        return '-' + language + '_' + store;
    }
  }

  private getStoreForConfigs(localEnv: string): string {
    const store = LocalStorageUtils.getKeyValue('storeId');
    if (store !== '') {
      let env = environment.name;

      if (localEnv === 'local_env') {
        return '-' + store;
      }

      return '-' + store + '-' + env;
    } else {
      return '-default';
    }
  }

  private clearUnusedCacheTranslationData(): void {
    const allLanguages = [].concat(...environment.stores.map(store => store.languages.map(language => language.id)));
    const allLanguagesForCurrentStore = AppUtils.getCurrentStore().languages.map(language => language.id);
    const unusedLanguages = allLanguages.filter(language => {
      return !allLanguagesForCurrentStore.includes(language);
    });

    unusedLanguages.forEach(unusedLang => {
      LocalStorageUtils.clearKey(`lang-update-date-${unusedLang}`);
      LocalStorageUtils.clearKey(`lang-data-${unusedLang}`);
      LocalStorageUtils.clearKey(`feature-toggles-data-${unusedLang}`);
      LocalStorageUtils.clearKey(`config-data-${unusedLang}`);
    });
  }

  private setUpdateDate(lang: string): void {
    const nextUpdateDate = new Date();
    nextUpdateDate.setHours(nextUpdateDate.getHours() + 1, 0, 0, 0);

    LocalStorageUtils.setKeyValue(`lang-update-date-${lang}`, nextUpdateDate.getTime().toString());
  }

  private isSetToUpdate(lang: string): boolean {
    const updateDate = Number(LocalStorageUtils.getKeyValue(`lang-update-date-${lang}`));

    if (!LocalStorageUtils.getKeyValue(`lang-data-${lang}`) || !LocalStorageUtils.getKeyValue(`config-data-${lang}`) || !LocalStorageUtils.getKeyValue(`feature-toggles-data-${lang}`)) {
      return true;
    }

    return Date.now() - updateDate > 0;
  }
}
