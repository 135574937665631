import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, skipWhile, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';

import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { CustomerFacade } from '../../../facades/customer.facade';
import { ImageUtils } from '../../../utils/image.utils';
import { SparePartsUtils } from '../../../utils/spare-parts.utils';
import { addressValidation } from '../../../configurations/validations';
import { EAddressType } from '../../../configurations/cpq';
import {
  IAddress,
  IMessage,
  IPriceDisputingPerItem,
  ISelectEvent,
  ISystemDetails,
} from '../../../models/common.models';
import {
  IAddressExtended,
  IAddressReduced,
  IAddressSelectBox,
  ICompanyBusinessUnitAddresses,
  ICustomerPreferences,
} from '../../../models/customer.models';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../../models/cart.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { AddressUtils } from '../../../utils/address.utils';
import { EGlueResource, EMessageType, EUserRoles } from '../../../configurations/common';
import { environment } from '../../../../environments/environment.defaults';
import { SoldToAccountsFacade } from '../../../facades/sold-to.facade';
import { ISoldToAddressesResponse, SoldToIncludedResource } from '../../../models/soldTo-selection.models';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { ShopCartActions } from '../../../actions';
import { MarketingFacade } from '../../../facades/marketing.facade';

@Component({
  selector: 'app-spare-part-order-details-section',
  templateUrl: 'spare-part-order-details-section.component.html',
  styleUrls: ['spare-part-order-details-section.component.scss'],
})
export class SparePartOrderDetailsSectionComponent implements OnInit, OnDestroy {
  orderDetailsForm: UntypedFormGroup;
  addressType: EAddressType = EAddressType.ShipTo;
  preferredShipToAddress: IAddress;
  shipToAddresses: IAddressExtended[] = [];
  availableBpShipToAddresses: IAddressSelectBox[] = [];
  availableBpShipToAddressesLoading: boolean = true;
  availableBPBillToAddresses: IAddressSelectBox[] = [];
  availableBpBillToAddressesLoading: boolean = true;
  shipToAddress: IAddress;
  addressesLoading: boolean = false;
  addressChanged: boolean = false;
  showModalChangeAddress: boolean = false;
  showModalReportWrongAddress: boolean = false;
  reportWrongAddressType: EAddressType = EAddressType.ShipTo;
  isBusinessPartner: boolean = false;
  itemDetailsAdditionalFields = [] as Array<{
    name: string,
    getValFunc: (item: any) => string,
    shouldDisplayFunc: (item: any) => boolean
  }>;
  customerPreferences: ICustomerPreferences;
  prefilledBillToAddressId: string | null;
  prefilledShipToAddressId: string | null;
  itemMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };
  priceDisputingPerItem: IPriceDisputingPerItem[] = [];

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() cartId: string;
  @Input() cartAttributes: ICartAttributes;
  @Input() cartItemsWithDetails: Array<ICartItemWithDetail>;
  @Input() cartRules: Array<ICartRule>;
  @Input() systemDetails: ISystemDetails;
  @Input() loggedUserRoles: EUserRoles[];
  @Input() userLoggedData: any;
  @Input() isSaturdayShipment: boolean = false;
  @Input() isPriceDisputingActive: boolean = false;

  @Output() isSaturdayShipmentEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressChangedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
    private customerCurrencyPipe: CustomerCurrencyPipe,
    private configurationFacade: ConfigurationFacade,
    private soldToFacade: SoldToAccountsFacade,
    private translate: TranslateService,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.selectIsCustomerBusinessPartner();
    this.selectCustomerPreferences();
    this.initializeForm();
    this.beginCustomerAddressesAction();
    this.selectShipToAndBillToAddressesAndFillFormData();
    this.prepareItemAdditionalFields();
    this.isSaturdayShipmentAlreadySelected();
    this.selectPriceDisputingPerItem();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getProductImageUrl(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  isFormValid(): boolean {
    return this.orderDetailsForm.status === 'VALID';
  }

  proceedToContactDetails(): void {
    const orderDetailsData = {
      shipToAddress: this.shipToAddress,
      billToAddress: this.orderDetailsForm?.value?.businessPartnerBillToAddress?.value,
    };
    this.formSubmitted.emit(orderDetailsData);
  }

  private selectCustomerPreferences(): void {
    this.customerFacade.selectCustomerPreferences().pipe(
      skipWhile(res => res === null),
      takeUntil(this.unsubscribe$),
    ).subscribe(customerPreferences => {
      if (customerPreferences) {
        this.customerPreferences = customerPreferences;
      }
    });
  }

  private isSaturdayShipmentAlreadySelected(): void {
    this.customerFacade.selectMyProfileInformation()
      .pipe(take(1))
      .subscribe(state => {
        this.isSaturdayShipment = state?.pointOfContact?.shipmentMethodName === environment.sparePartSaturdayDeliveryKey;
        this.isSaturdayShipmentEmitter.emit(this.isSaturdayShipment);
      });
  }

  beginCustomerAddressesAction(): void {
    this.customerFacade.beginCustomerAddressesAction();
  }

  setCustomShipToAddress(event: ISelectEvent): void {
    const customShipToAddress: IAddress = {
      ...event.value,
      state: event.value.address2,
    };
    this.customerFacade.setCustomCustomerShipToAddress(customShipToAddress);
    this.shipToAddress = customShipToAddress;
    this.orderDetailsForm.patchValue({
      shipToAddress: SparePartsUtils.formatAddressToString(this.shipToAddress).toUpperCase(),
    });
    this.addressChanged = true;
    this.addressChangedEmitter.emit(this.addressChanged);
  }

  /**
   * If BP - get ship to and bill to address
   * If non-BP - select ship to address
   * @private
   */
  selectShipToAndBillToAddressesAndFillFormData(): void {
    this.addressesLoading = true;
    //subscribe and combine to two customer state properties
    combineLatest([
      this.isBusinessPartner
        ? this.soldToFacade.getSoldToAccountsById(this.cartAttributes?.soldToAddress?.sapId, [EGlueResource.SHIP_TO_ADDRESSES, EGlueResource.BILL_TO_ADDRESSES])
        : this.customerFacade.selectCustomerShipToAddresses(),
      this.customerFacade.selectCustomCustomerShipToAddress(),
    ]).pipe(
      filter(customerShipToAddresses => customerShipToAddresses !== null),
      takeUntil(this.unsubscribe$),
    ).subscribe(([customerAddresses, customShipToAddress]) => {
      this.processShipToAndBillToAddressesResponseAndFillFormData(customerAddresses, customShipToAddress);
    });
  }

  /**
   * Process ship to address and bill to address response
   * @param {ISoldToAddressesResponse | IAddress[]} customerAddresses
   * @param {IAddress} customShipToAddress
   * @private
   */
  processShipToAndBillToAddressesResponseAndFillFormData(customerAddresses: ISoldToAddressesResponse | IAddress[], customShipToAddress: IAddress): void {
    let customerShipToAddress: IAddress;
    const nameEnUs: string = `${this.systemDetails.nameEnUs} (${this.systemDetails.siemensEquipmentId})`;
    const selectedEquipment: string = this.systemDetails ? nameEnUs : '';
    const businessUnitName = this.userLoggedData?.businessUnitName;
    const soldToAccount: string = `${this.isBusinessPartner ? this.cartAttributes?.soldToAddress?.sapId + ' - ' : ''}${this.cartAttributes?.soldToAddress?.company ?? businessUnitName}`;
    const quoteShipToAddress: IAddress = this.cartAttributes?.shippingAddress;
    const customerShipToAddresses: SoldToIncludedResource[] = [];
    const customerBillToAddresses: SoldToIncludedResource[] = [];
    if ('included' in customerAddresses) {
      customerAddresses?.included?.forEach(customerAddress => {
        if (customerAddress.type === EGlueResource.SHIP_TO_ADDRESSES) {
          customerShipToAddresses.push(customerAddress);
        }
        if (customerAddress.type === EGlueResource.BILL_TO_ADDRESSES) {
          customerBillToAddresses.push(customerAddress);
        }
      });
    }

    const preferredShipToAddress: IAddress = this.getPreferredShipToAddress(customerShipToAddresses);
    this.preferredShipToAddress = {...preferredShipToAddress, company: preferredShipToAddress?.name};
    if (this.isBusinessPartner) {

      customerShipToAddress = this.getBpShipToAddress(customerShipToAddresses);
      this.getBpBillToAddress(customerBillToAddresses);
    } else {
      if (AddressUtils.isAddressValid(this.preferredShipToAddress, true)) {
        customerShipToAddress = this.preferredShipToAddress;
      } else {
        if (AddressUtils.isAddressValid(quoteShipToAddress, true)) {
          customerShipToAddress = quoteShipToAddress;
        }
      }
    }

    if (customerShipToAddress) {
      // check if customer set new current ship to address
      if (customShipToAddress) {

        // set shipToAddress to customer custom address
        if (this.isBusinessPartner) {
          this.setCustomBpShipToAddress(customShipToAddress);
        }
        this.shipToAddress = customShipToAddress;
      } else {
        // set shipToAddress to customer preferred ship to address
        this.shipToAddress = customerShipToAddress;
      }
      if (!this.isBusinessPartner) {
        this.shipToAddresses.push(this.shipToAddress as IAddressExtended);
      }

      this.orderDetailsForm.patchValue({
        selectedEquipment: selectedEquipment,
        soldToAccount: soldToAccount,
        customer: this.systemDetails.companyBusinessUnit ? this.systemDetails.companyBusinessUnit : businessUnitName,
        shipToAddress: SparePartsUtils.formatAddressToString(this.shipToAddress),
      });

      this.addressesLoading = false;
    } else {

      this.customerFacade.getBusinessUnitById('mine')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(businessUnit => {
          const businessUnitAddress = businessUnit.included.find(item => item.type === 'company-business-unit-addresses') as ICompanyBusinessUnitAddresses;
          this.shipToAddress = {
            company: businessUnit.data[0].attributes.name,
            isDefaultBilling: false,
            isDefaultShipping: false,
            phone: this.userLoggedData.customerData.phone,
            salutation: this.userLoggedData.customerData.salutation,
            firstName: this.userLoggedData.customerData.firstName,
            lastName: this.userLoggedData.customerData.lastName,
            address1: businessUnitAddress.attributes.address1,
            address2: businessUnitAddress.attributes.address2,
            address3: businessUnitAddress.attributes.address3,
            city: businessUnitAddress.attributes.city,
            country: businessUnitAddress.attributes.country,
            iso2Code: businessUnitAddress.attributes.iso2Code,
            state: businessUnitAddress.attributes.state,
            zipCode: businessUnitAddress.attributes.zipCode,
          };
          this.shipToAddresses.push(this.shipToAddress as IAddressExtended);

          this.orderDetailsForm.patchValue({
            selectedEquipment: selectedEquipment,
            soldToAccount: businessUnit.data[0].attributes.name ?? '',
            shipToAddress: SparePartsUtils.formatAddressToString(this.shipToAddress),
            customer: this.systemDetails?.companyBusinessUnit,
          });

          if (this.orderDetailsForm.get('soldToAccount').status === 'INVALID') {
            this.showNotificationSoldToProblem();
          }

          this.addressesLoading = false;
        });
    }

  }

   initializeForm(): void {
    this.orderDetailsForm = this.formBuilder.group({
      selectedEquipment: ['', [Validators.required]],
      soldToAccount: ['', [Validators.required]],
      customer: this.isBusinessPartner ? ['', [Validators.required]] : '',
      shipToAddress: ['', [Validators.required, addressValidation]],
      billToAddress: this.isBusinessPartner ? ['', [Validators.required, addressValidation]] : '',
      businessPartnerShipToAddress: this.isBusinessPartner ? ['', [Validators.required]] : '',
      businessPartnerBillToAddress: this.isBusinessPartner ? ['', [Validators.required]] : '',
    });
  }

  private showNotificationSoldToProblem(): void {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'checkout-delivery-details-notification.soldToProblem',
      messages: [{
        key: 'checkout-delivery-details-notification.contactSupport',
      }],
      actions: [
        {
          type: '',
          label: 'checkout-delivery-details-notification.close',
          css: ['button', 'button--secondary'],
        },
      ],
    });
  }

  private prepareItemAdditionalFields(): void {
    this.itemDetailsAdditionalFields = [
      {
        name: 'multi-cart.quantity',
        getValFunc: (item) => item.attributes.quantity,
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.price-per-unit',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.unitPrice,
          this.cartAttributes.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.total-price',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.sumPrice,
          this.cartAttributes.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
    ];
  }

  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Get preferred shipTo address.
   *
   * @param {any} customerShipToAddresses
   * @return {IAddress}
   */
  getPreferredShipToAddress(customerShipToAddresses: any): IAddress {
    if (this.isBusinessPartner) {
      return this.mapIAddressReducedToIAddress(
        customerShipToAddresses?.find(
          address => address?.attributes?.sapId === this.customerPreferences?.preferredShipToId
        )?.attributes,
      );
    } else {
      return customerShipToAddresses?.find(
        address => address?.sapId === this.customerPreferences?.preferredShipToId,
      );
    }
  }

  /**
   * Select business partner's shipTo address in orderDetailsForm.
   *
   * @param {ISelectEvent} event
   */
  selectBpShipToAddress(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      shipToAddress: SparePartsUtils.formatAddressToString(event?.value),
      businessPartnerShipToAddress: {
        name: `${event?.value?.sapId} - ${this.getFormattedAddress(event?.value)}`,
        value: event?.value,
      },
    });

    this.store.dispatch(ShopCartActions.setShipToAddressIdAction({shipToAddressSapId: event?.value?.sapId}));
    this.shipToAddress = event?.value;
  }

  selectBpBillToAddress(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      businessPartnerBillToAddress: {
        name: `${event?.value?.sapId} - ${this.getFormattedAddress(event?.value, EAddressType.BillTo)}`,
        value: event?.value,
      },
      billToAddress: SparePartsUtils.formatAddressToString((event?.value)),
    });
    this.store.dispatch(ShopCartActions.setBillToAddressIdAction({billToAddressSapId: event?.value?.sapId}));
  }


  /**
   * Get all business partner's shipTo addresses based on the preselected soldTo address and set one of those as a default address.
   *
   * @private
   *
   * @param {SoldToIncludedResource[]} addresses
   * @return {IAddress}
   */
  private getBpShipToAddress(addresses: SoldToIncludedResource[]): IAddress {
    this.availableBpShipToAddresses = [];

    if (addresses) {
      addresses.forEach(address => {
        if (address.type === EGlueResource.SHIP_TO_ADDRESSES) {
          const addressTitle: string = `${address?.attributes?.sapId} - ${this.getFormattedAddress(
            this.mapIAddressReducedToIAddress(address?.attributes),
          )}`;
          const addressBody: IAddress = this.mapIAddressReducedToIAddress(address?.attributes);
          this.availableBpShipToAddresses.push({name: addressTitle, value: addressBody});
        }
      });
    }

    const preferredBpShipToAddress: IAddressSelectBox = this.availableBpShipToAddresses.find(address => {
      return address?.value?.sapId === this.preferredShipToAddress?.sapId;
    });

    this.selectPrefilledShipToAddressId();
    let prefilledShip = null;
    if (this.prefilledShipToAddressId) {
      prefilledShip = addresses.find(address => address.id === this.prefilledShipToAddressId);
    }

    const defaultBpShipToAddress = {
      name: `${this.cartAttributes?.systemDetails?.shipToAddress?.sapId} - ${this.getFormattedAddress(
        this.mapIAddressReducedToIAddress(this.cartAttributes?.systemDetails?.shipToAddress),
      )}`,
      value: this.mapIAddressReducedToIAddress(this.cartAttributes?.systemDetails?.shipToAddress),
    };

    const prefilledBpShipToAddress = {
      name: `${prefilledShip?.attributes?.sapId} - ${this.getFormattedAddress(
        this.mapIAddressReducedToIAddress(prefilledShip?.attributes),
      )}`,
      value: this.mapIAddressReducedToIAddress(prefilledShip?.attributes),
    };

    this.availableBpShipToAddresses.push(defaultBpShipToAddress);

    let shipToAddress;
    if (this.preferredShipToAddress?.sapId && preferredBpShipToAddress) {
      this.orderDetailsForm.patchValue({
        businessPartnerShipToAddress: preferredBpShipToAddress,
      });

      shipToAddress = preferredBpShipToAddress.value;
    } else if (prefilledShip) {
      this.orderDetailsForm.patchValue({
        businessPartnerShipToAddress: prefilledBpShipToAddress,
      });
    } else {
      this.orderDetailsForm.patchValue({
        businessPartnerShipToAddress: defaultBpShipToAddress,
      });

      shipToAddress = defaultBpShipToAddress.value;
    }

    this.availableBpShipToAddressesLoading = false;
    return shipToAddress;
  }

  /**
   * Get all business partner's bill to addresses based on the preselected soldTo address and set one of those as a default address.
   *
   * @private
   *
   * @param {SoldToIncludedResource} addresses
   *
   */
  getBpBillToAddress(addresses: SoldToIncludedResource[]) {
    this.availableBPBillToAddresses = [];
    if (!!addresses) {
      if (addresses.length > 0) {
        addresses.forEach((address) => {
          address.type === EGlueResource.BILL_TO_ADDRESSES
          && this.availableBPBillToAddresses.push(this.mapAddressResourceToAddressSelectBox(address));
        });
        this.selectPrefilledBillToAddressId();
        const prefilledBill: SoldToIncludedResource = addresses.find(address => address.id === this.prefilledBillToAddressId);
        const defaultBpBillToAddress: IAddressSelectBox = this.mapAddressResourceToAddressSelectBox(prefilledBill ?? addresses[0]);
        this.orderDetailsForm.patchValue({
          businessPartnerBillToAddress: defaultBpBillToAddress,
          billToAddress: SparePartsUtils.formatAddressToString(defaultBpBillToAddress.value),
        });
      }
    }
    this.availableBpBillToAddressesLoading = false;
  }

  selectPrefilledBillToAddressId(): void {
    this.marketingFacade.selectPrefilledBillToAddressId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(billToAddressId => {
      this.prefilledBillToAddressId = billToAddressId;
    });
  }

  selectPrefilledShipToAddressId(): void {
    this.marketingFacade.selectPrefilledShipToAddressId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(shipToAddressId => {
      this.prefilledShipToAddressId = shipToAddressId;
    });
  }

  mapAddressResourceToAddressSelectBox(address: SoldToIncludedResource): IAddressSelectBox {
    const addressTitle = `${address?.attributes?.sapId} - ${this.getFormattedAddress(
      this.mapIAddressReducedToIAddress(address?.attributes),
      EAddressType.BillTo,
    )}`;
    const addressBody: IAddress = this.mapIAddressReducedToIAddress(address?.attributes);
    return {name: addressTitle, value: addressBody};
  }

  /**
   * Show change address modal if saturday delivery shipment method is not selected.
   *
   */
  showChangeAddressModal(): void {
    if (!this.isSaturdayShipment) {
      this.showModalChangeAddress = true;
    }
  }

  /**
   * Determine if price disputing is set for given item.
   *
   *
   * @param {string} itemId
   * @return {boolean}
   */
  isPriceDisputingSetForItem(itemId: string): boolean {
    return this.priceDisputingPerItem?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }

  /**
   * Set custom business partner's shipTo address.
   *
   * @private
   *
   * @param {IAddress} address
   *
   */
  private setCustomBpShipToAddress(address: IAddress): void {
    const addressTitle: string = `${SparePartsUtils.formatAddressToString(address)}`;
    const customBpShipToAddress = {
      name: addressTitle,
      value: {
        ...address,
        sapId: this.cartAttributes?.soldToAddress?.sapId,
      },
    };

    this.availableBpShipToAddresses.push(customBpShipToAddress);
    this.orderDetailsForm.patchValue({
      businessPartnerShipToAddress: customBpShipToAddress,
    });
  }

  /**
   * Map IAddressReduced type to IAddress type.
   *
   * @private
   *
   * @param {IAddressReduced} reducedAddress
   * @return {IAddress}
   */
  private mapIAddressReducedToIAddress(reducedAddress: IAddressReduced): IAddress {
    return {
      sapId: reducedAddress?.sapId,
      salutation: 'Ms',
      firstName: reducedAddress?.name,
      lastName: '',
      address1: reducedAddress?.address1,
      address2: '',
      zipCode: reducedAddress?.zipCode,
      city: reducedAddress?.city,
      state: reducedAddress?.state,
      iso2Code: reducedAddress?.iso2Code,
      company: reducedAddress?.name,
      phone: this.userLoggedData?.customerData?.phone,
      country: reducedAddress?.country,
      isDefaultBilling: false,
      isDefaultShipping: false,
    } as IAddress;
  }

  /**
   * Get formatted address.
   * If address is not valid, error msg (configurable in Arakh) is returned.
   *
   * @private
   *
   * @param {IAddress} address
   * @param {EAddressType} addressType
   * @return {string}
   */
  private getFormattedAddress(address: IAddress, addressType: EAddressType = EAddressType.ShipTo): string {
    if (!AddressUtils.isAddressValid(address, true)) {
      switch (addressType) {
        case EAddressType.ShipTo:
          return this.translate.instant('spare-parts-order-details-page.missing-default-fl-ship-to-address');
        default:
          return this.translate.instant('spare-parts-order-details-page.missing-default-fl-bill-to-address');
      }
    }

    return `${SparePartsUtils.formatAddressToString(address)}`;
  }

  /**
   * Select price disputing as array of all items
   * which had price disputing set (from ShopCart state).
   *
   * @private
   *
   */
  private selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItem => {
        this.priceDisputingPerItem = priceDisputingPerItem;
      });
  }

  addressValueForReport(): any {
    switch (this.reportWrongAddressType) {
      case EAddressType.BillTo:
        return this.orderDetailsForm.value.businessPartnerBillToAddress.name ?? ' ';
      case EAddressType.ShipTo:
        return this.orderDetailsForm.value.businessPartnerShipToAddress.name;
      default:
        return this.orderDetailsForm.value.soldToAccount;
    }
  }

  titleForReport(): string {
    switch (this.reportWrongAddressType) {
      case EAddressType.BillTo:
        return 'checkout-delivery-details.report-wrong-bill-to-address';
      case EAddressType.ShipTo:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
      case EAddressType.SoldTo:
        return 'checkout-delivery-details.report-wrong-sold-to-address';
      default:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
    }
  }

  protected readonly EAddressType = EAddressType;
}
