import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { ConfigurationFacade } from '../../facades/configuration.facade';
import { State } from '../../reducers';
import { IconUtils } from '../../utils/icon.utils';
import { IIndexedNotification } from '../../models/cpq.models';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications: IIndexedNotification[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configurationFacade: ConfigurationFacade,
    private store: Store<State>,
    private notificationModal: ElementRef,
    location: PlatformLocation,
  ) {
    location.onPopState(() => {
      if (this.isNotification()) {
        this.clearAction();
      }
    });
  }

  ngOnInit(): void {
    this.configurationFacade.getNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(notifications => {
        this.notifications = notifications;
      });
  }

  @HostListener('document:click', ['$event'])
  hideNotification(event): void {
    const target = event.target as HTMLElement;
    if (this.isNotification() &&
      !this.notificationModal.nativeElement.contains(event.target) &&
      !target.classList.contains('button') &&
      !target.classList.contains('notification-icon-close') // do not clear if clicking 'close' on a single message
    ) {
      this.clearAction();
    }
  }

  isNotification(): boolean {
    return this.notifications?.length > 0;
  }

  clearAction(): void {
    this.configurationFacade.clearNotifications();
  }

  closeAction(notificationId: number): void {
    this.configurationFacade.closeNotification(notificationId);
  }

  handleActions(type: string): void {
    this.store.dispatch({type});
    this.clearAction();
  }

  getIcon(notification: IIndexedNotification): string {
    return IconUtils.getStatusIcon(notification.type);
  }
}
