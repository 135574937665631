<ng-template #loadingTemplate>
  <div class="loading-animation position-center"></div>
</ng-template>
<div class="accordion service-agreements__table" [attr.data-test]="'desc-loadingForAccordion'" *ngIf="!loading else loadingTemplate">
  <div class="accordion__title service-agreements__header">
    <div
        *ngFor="let header of headers"
        class="{{header.class}} service-agreements__title"
        [ngClass]="!header.showOnMobile ? 'hidden-mobile': ''">
      {{ header.label | translate }}
      <app-sort-by *ngIf="!isBusinessPartnerRole"
          [cssClass]="'margin-0'"
          [current]="currentSort"
          [hasTitle]=false
          (selected)="sort($event)">
      </app-sort-by>
    </div>
  </div>
  <div class="agreements__table">
    <div class="table-items">
      <app-accordion
          [equipmentSelection]="true"
          *ngFor="let agreement of agreementsToWorkWith"
          [manualOpen]="tabsOpened.includes(agreement.attributes?.functionalLocation)">
        <ng-container ngProjectAs="[slot][header]">
          <div
              class="accordion__title icon-arrow-down"
              [ngClass]="{'disabled': isSoldToItemInArray(agreement.attributes?.functionalLocation)}"
              (click)="toggleTab(agreement.attributes?.functionalLocation)"
              [attr.data-test]="'desc-iconArrowDown'">
            <span class="agreement-info functional-location-id">
              <span class="header__label"
                    [attr.data-test]="'desc-myContractsFunctionalLocation'">{{ 'my-contracts.functional-location' | translate }}</span>
              {{ getAttribute(agreement, 'functionalLocation' ) }}
            </span>
            <span class="agreement-info modality">
              <span class="header__label"
                    [attr.data-test]="'desc-myContractsModality'">{{ 'my-contracts.modality' | translate }}</span>
              {{ getAttribute(agreement, 'modality') }}
            </span>
            <span class="agreement-info nameEnUs">
              <span class="header__label"
                    [attr.data-test]="'desc-myContractsProductName'">{{ 'my-contracts.product-name' | translate }}</span>
              {{ getAttribute(agreement, 'equipmentName') }}
            </span>
            <div class="agreement-info street">
              <span class="header__label"
                    [attr.data-test]="'desc-myContractsEquipmentLocation'">{{ 'my-contracts.equipment-location' | translate }}</span>
              {{ getAttribute(agreement,  'equipmentLocation') }}
            </div>
            <div class="agreement-info state">
              <span class="header__label" [attr.data-test]="'desc-myContractsState'">{{ 'my-contracts.state' | translate }}</span>
              {{ getAttribute(agreement,  'city') }}
            </div>
            <span class="agreement-info contractName">
              <span class="header__label"
                    [attr.data-test]="'desc-myContractsContractName'">{{ 'my-contracts.contract-name' | translate }}</span>
              {{ getAttribute(agreement, 'contractName') }}
            </span>
          </div>
        </ng-container>
        <ng-container ngProjectAs="[slot][panel]">
          <div class="container accordion__content"
               *ngIf="tabsOpened.includes( agreement.attributes?.functionalLocation)">
              <app-my-equipment-selection-contract-detail
                  [isCartOperationInProgress]="isCartOperationInProgress"
                  [loadingCartDataInProgress]="loadingCartDataInProgress"
                  [agreement]="agreement"
                  [userCarts]="userCarts"
                  [currentCartId]="currentCartId"
                  [cartItems]="cartItems"
                  [soldToIdFromParams]="soldToId"
                  [soldToInactive]="isSoldToItemInArray( agreement.attributes?.functionalLocation)"
                  [additionalData]="getAdditionalData(agreement.attributes?.functionalLocation)"
                  [modalitiesAndMaterialNumbers]="modalitiesAndMaterialNumbers"
                  [selectableEquipment]="selectableEquipment"
                  [isReorderPending]="isReorderPending"
                  [companyRoles]="companyRoles">
              </app-my-equipment-selection-contract-detail>
           </div>
        </ng-container>
      </app-accordion>
    </div>
  </div>
</div>
