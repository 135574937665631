import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { INavigationNodes } from '../../models/settings.model';
import { EUserRoles } from '../../configurations/common';
import { CustomerFacade } from '../../facades/customer.facade';
import { takeUntil } from 'rxjs/operators';
import { AppUtils } from '../../utils/app.utils';

@Component({
  selector: 'app-shop-categories',
  templateUrl: './shop-categories.component.html',
  styleUrls: ['./shop-categories.component.scss'],
})
export class ShopCategoriesComponent implements OnInit, OnDestroy {
  shopCategoryLoading: boolean = false;
  shopCategoryTiles: INavigationNodes[] = [];
  companyRoles: EUserRoles[];
  isBusinessPartnerRole: boolean = false;
  hasAnyTile: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCompanyRoles();
    this.selectShopCategoryLoading();
    this.selectShopCategoryTiles();
    this.selectIsBPRole();
  }

  selectShopCategoryLoading(): void {
    this.configFacade.selectShopCategoryTilesLoading()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        this.shopCategoryLoading = loading;
    })
  }

  selectShopCategoryTiles(): void {
    this.configFacade.selectShopCategoryTiles()?.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(navigationTiles => {
      this.hasAnyTile = !!navigationTiles.length;
      this.shopCategoryTiles = AppUtils.filterNodesByRole(navigationTiles, this.companyRoles);
    });
  }

  selectCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles()?.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isBPRole => {
        this.isBusinessPartnerRole = isBPRole;
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
