import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ImageUtils } from '../../../utils/image.utils';

@Component({
  selector: 'app-multi-mini-cart',
  templateUrl: './multi-mini-cart.component.html',
  styleUrls: ['./multi-mini-cart.component.scss'],
})
export class MultiMiniCartComponent {
  @Input() currency: string;
  @Input() currentCartId: string;
  @Input() currentCartName: string;
  @Input() usersCarts: any[];
  @Input() itemsInCart: any[];
  @Input() hasNickname: boolean;
  @Input() checkWorkflowInformation: boolean;
  @Input() isExcludeTaxActive: boolean;

  @Output() showAddNicknameModal = new EventEmitter<boolean>();
  @Output() showDeleteCartModal = new EventEmitter<boolean>();
  @Output() updateCartItemQuantity = new EventEmitter<{
    oldQuantity: string, newQuantity: string, itemId: string, itemName: string
  }>();
  @Output() redirectToHomepage = new EventEmitter<void>();

  @ViewChildren('itemQuantity') itemQuantityInputs: QueryList<ElementRef>;

  constructor(
    private marketingFacade: MarketingFacade,
  ) {
  }

  showAddNicknameModalEmit(): void {
    this.showAddNicknameModal.emit(true);
  }

  showDeleteCartModalEmit(): void {
    this.showDeleteCartModal.emit(true);
  }

  updateCartItemQuantityEmit(oldQuantity: string, newQuantity: string, item: any, index: number): void {
    const itemId = item.id || item.items[0]?.id || item.attributes?.sku;
    const itemName = item.attributes?.name || item.items[0]?.attributes?.name;
    let itemQuantity = this.itemQuantityInputs.get(index).nativeElement;
    if (itemQuantity.value != item.attributes.quantity) {
      this.updateCartItemQuantity.emit({oldQuantity, newQuantity, itemId, itemName});
      itemQuantity.value = item.attributes.quantity;
    }
  }

  switchDefaultCart(cartId: string): void {
    this.marketingFacade.switchDefaultCart(cartId);
    this.redirectToHomepage.emit();
  }

  getItemPicture(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  getAddOrEditNicknameLabel(): string {
    this.usersCarts = this.usersCarts.filter(cart => !cart.approverId);
    return this.hasNickname ? 'mini-cart.edit-nickname-label' : 'mini-cart.add-nickname-label';
  }
}
