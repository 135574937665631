<ng-container *ngIf="!isBusinessPartner; else isBusinessPartnerActive">
  <div class="marked-filters" *ngIf="activeFilters.length > 0">
    <ul class="marked-filters__list" *ngFor="let filter of activeFilters">
      <li class="filter__tile" *ngFor="let option of filter.entities">
      <span class="filter__link" [attr.data-test]="'link-filter-' + filter.name" (click)="filterResults(filter.name, option, router.url)">
        <span [attr.data-test]="'desc-filter-' + filter.name" class="filter__name">{{ option }}</span>
        <span class="icon-close"></span>
      </span>
      </li>
    </ul>
  </div>
</ng-container>
<ng-template #isBusinessPartnerActive>
  <div class="marked-filters" *ngIf="showBPRewoke">
    <ul class="marked-filters__list">
      <li class="filter__tile">
      <span class="filter__link" [attr.data-test]="'link-filter-' + systemDetails?.nameEnUs" (click)="filterResults(systemDetails?.nameEnUs, null, router.url)">
        <span [attr.data-test]="'desc-filter-' + systemDetails?.nameEnUs" class="filter__name">{{ systemDetails?.nameEnUs }}</span>
        <span class="icon-close"></span>
      </span>
      </li>
    </ul>
  </div>
  <div class="marked-filters" *ngIf="activeFilters.length > 0">
    <ul class="marked-filters__list" *ngFor="let filter of activeFilters">
      <li class="filter__tile" *ngFor="let option of filter.entities">
      <span class="filter__link" [attr.data-test]="'link-filter-' + filter.name" (click)="filterResults(filter.name, option, router.url)">
        <span [attr.data-test]="'desc-filter-' + filter.name" class="filter__name">{{ option }}</span>
        <span class="icon-close"></span>
      </span>
      </li>
    </ul>
  </div>
</ng-template>
