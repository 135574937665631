import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { AppUtils } from '../../utils/app.utils';

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(date?: Date | string): string {
    const defaultTimeFormat = 'HH:mm';
    const timeFormat = environment.stores.find(
      store => store.storeId === AppUtils.getCurrentStore().storeId
    )?.timeFormat ?? defaultTimeFormat;

    date = (typeof date === 'string') ? new Date(date) : date;

    return this.datePipe.transform(date, timeFormat);
  }
}
