<div *ngIf="tabs.length > 1" class="bare-list tabs__list">
  <ng-container *ngFor="let tab of tabs">
    <h6 class="tabs__item"
        [attr.data-test]="'tab-' + tab"
        [ngClass]="{'is-active is-active__bar': currentTab === tab}"
        (click)="changeTab(tab)">
      {{ 'spare-part-order-history.' + tab + '-tab-title' | translate }}
    </h6>
  </ng-container>
</div>
<div class="orders"
     [attr.data-test]="'div-orders'"
     *ngIf="currentTab === ESparePartsOrderHistoryTabs.ORDERS">
  <app-spare-part-order-history
      [orders]="orders"
      [allLoadedOrders]="allLoadedOrders"
      [ordersLoading]="ordersLoading">
  </app-spare-part-order-history>
  <app-pagination-linkless
    [pageCount]="ordersNumPages"
    [currentPage]="ordersCurrentPage"
    (pageChange)="goToOrdersPage($event)">
  </app-pagination-linkless>
</div>
<div class="archive"
     [attr.data-test]="'div-archive'"
     *ngIf="currentTab === ESparePartsOrderHistoryTabs.ARCHIVE">
  <app-spare-part-hybris-order-history
      [hybrisOrders]="hybrisOrders"
      [hybrisOrdersLoading]="hybrisOrdersLoading">
  </app-spare-part-hybris-order-history>
</div>
