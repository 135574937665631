import { AppUtils } from './app.utils';
import { environment } from '../../environments/environment';
import { ICartItem } from '../models/cart.models';

export class TaxUtils {
  static getTaxPercentage(cartItems: ICartItem[]): number {
    if (!cartItems?.length) {
      return 0;
    }

    return cartItems[0]?.attributes?.calculations?.taxRate ?? 0;
  }

  static isExcludeTaxActive(): boolean {
    const currentStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
