import { Pipe, PipeTransform } from '@angular/core';
import { ISystemDetails } from '../../models/common.models';

@Pipe({
  name: 'companyDetails'
})
export class CompanyDetailsPipe implements PipeTransform {
  transform(systemDetails: ISystemDetails): any {
    if (!systemDetails) {
      return '';
    }

    const companyPart = systemDetails.company ? `${systemDetails.company} - ` : '';
    const companyBuPart = systemDetails.companyBusinessUnit || '';
    const companyBuNumberPart = systemDetails.companyBusinessUnitNumber ?
      ` (${systemDetails.companyBusinessUnitNumber})` : '';

    return `${companyPart}${companyBuPart}${companyBuNumberPart}`;
  }
}
