<dl>
  <dt
      [ngClass]="[
        active ? 'is-open':  '',
        !showIcon ? 'clickable' : '',
        equipmentSelection ? 'accordion__header-for-es' : 'accordion__header',
        cssStyles.header,
      ]"
      (click)="activePanel()">
    <span *ngIf="showIcon" class="icon-arrow-down accordion__toggler clickable" (click)="activePanelOnIcon()"></span>
    <ng-content select="[slot][header]"></ng-content>
  </dt>
  <dd class="accordion__panel" [ngClass]="[active ? 'is-open':  '', cssStyles.panel]">
    <ng-content select="[slot][panel]"></ng-content>
  </dd>
</dl>
