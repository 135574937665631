<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <h3 class="checkout-label" [attr.data-test]="'title-1stDetailsPage'">
          {{ 'checkout-delivery-details.title' | translate }}
        </h3>
        <h6 [attr.data-test]="'subtitle-1stDetailsPage'">
          {{ 'checkout-delivery-details.select-details' | translate }}
        </h6>
        <br>
        <app-ivk-selection-form
            [installBaseLoading]="installBaseLoading"
            [systems]="systems"
            [businessUnitLoading]="businessUnitLoading"
            [businessUnits]="businessUnits"
            (formChanged)="ivkFormChanged($event)"
            (systemIdChanged)="systemIdValueChanged($event)">
        </app-ivk-selection-form>
        <div class="row">
          <app-select-box
              class="col-12"
              [form]="deliveryForm"
              [modelValue]="deliveryForm.value.deliveryAddress"
              [cssStyles]="{wrapper: 'selectbox-delivery-address'}"
              [options]="addresses"
              [inputName]="'deliveryAddress'"
              label="{{ 'checkout-delivery-details.delivery-address' | translate }}"
              [isRequired]="true"
              (selected)="selectDeliveryAddress($event)">
          </app-select-box>
        </div>
        <div class="address-buttons">
          <button
              type="button"
              class="button add-new-address"
              (click)="showModalNewAddress()"
              [attr.data-test]="'button-addNewAddress'">
            {{ 'checkout-delivery-details.add-new-address-btn' | translate }}
          </button>
          <button
              type="button"
              class="button add-new-address"
              [disabled]="businessAddressesLoading"
              (click)="showModalWrongAddress()"
              [attr.data-test]="'button-reportWrongAddress'">
            {{ 'checkout-delivery-details.report-wrong-address' | translate }}
          </button>
        </div>
        <hr>
        <ng-container *ngFor="let item of cartItemsWithDetails">
          <app-quote-item-with-ivk-form [item]="item"
                                        [installBaseLoading]="installBaseLoading"
                                        [systems]="systems"
                                        [businessUnitLoading]="businessAddressesLoading"
                                        [businessUnits]="businessUnits"
                                        [additionalFields]="additionalItemLevelFields"
                                        (formChanged)="itemFormChanged($event)"
                                        (formHidden)="itemFormHidden($event)">
          </app-quote-item-with-ivk-form>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="container order-summary col-12 col-lg-4">
    <div class="row">
      <div class="col-12">
        <button
            class="button button--primary button-request-quote"
            type="button"
            [disabled]="!formsAreValid"
            (click)="proceedToNextStep()"
            [attr.data-test]="'button-proceedToNextStep'">
          {{ 'request-details.next' | translate }}
        </button>
        <ng-container *ngIf="!isCartEmpty && !requestQuoteFeatureActive">
          <div *ngIf="!isCartEmpty" class="row justify-content-center shopping-cart">
            <div class="col-8 col-xl-8 shopping-cart__total-title" [attr.data-test]="'title-totalPrice'">
              {{ 'checkout-delivery-details.total-price' | translate  }}
            </div>
            <div class="col-4 col-xl-4 shopping-cart__total-price" [attr.data-test]="'desc-cartTotalPrice'">
              {{ cartItems.totals.subtotal | currency: cartItems.currency }}
            </div>
            <div class="col-xl-12 shopping-cart__total-additional-info">
              <span [attr.data-test]="'desc-priceInfo'" [ngClass]="{'totals-tax-disclaimer': excludeTax}">
                {{ 'checkout-delivery-details.price-info' | translate }}
              </span>
              <ng-container *ngIf="!excludeTax">
                <br/>
                <span [attr.data-test]="'desc-shipping'">
                  {{ 'checkout-delivery-details.free-shipping' | translate }}
                </span>
              </ng-container>
            </div>
          </div>
          <div class="clear-both" *ngIf="cartRules.length > 0">
            <app-cart-rules
                [cartRules]="cartRules"
                [priceToPay]="cartItems.totals.priceToPay"
                [currency]="cartItems.currency">
            </app-cart-rules>
          </div>
        </ng-container>
        <hr class="bottom-line">
        <div class="back-to-cart" (click)="backToCart()" [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left"></span>
          {{ 'checkout-delivery-details.back-to-card' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<app-new-delivery-address-modal
    [showModal]="showModalAddress"
    (closeModal)="showModalAddress = false"
    (newAddress)="selectDeliveryAddress($event)"
    (reloadAddresses)="beginCustomerAddressesAction()">
</app-new-delivery-address-modal>
<app-report-wrong-address-modal
    [userLoggedInRoles]="loggedUserRoles"
    [showModal]="showModalAddressWrong"
    (closeModal)="showModalAddressWrong = false"
    [addressesList]="businessAddresses"
    [roleSpecificMessage]="true">
</app-report-wrong-address-modal>

