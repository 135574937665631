<div class="contract-step-bar" *ngIf="soldTo || stepNo">
  <ng-container *ngIf="!isLoading; else loader">

    <ng-container *ngIf="stepNo === 1 && functionalLocationCount === 0">
      <div class="contract-product" *ngIf="functionalLocation">
        <div class="image__thumbnail">
          <img alt="{{ functionalLocation.nameEnUs }}" [src]="functionalLocation.externalUrlSmall"/>
        </div>
        <div class="contract-product-description desktop">
          <p class="contract-product-description__info font-weight-bold">{{ functionalLocation.nameEnUs }}</p>
          <p class="contract-product-description__info">{{ 'contract-steps-bar.fl' | translate }} {{ serviceParams['fl-number'] }}</p>
        </div>
        <div class="contract-product-description mobile">
          <p class="contract-product-description__info">{{ 'contract-steps-bar.quote-id' | translate }}</p>
          <p class="contract-product-description__info font-weight-bold">{{ cart?.id }}</p>
          <p class="contract-product-description__info nickname" [attr.data-test]="'button-addNickName'"
             (click)="showAddNicknameModal = true">
            {{ hasNickname ? nickname : 'contract-steps-bar.add-nickname' | translate }}</p>
          <p class="contract-product-description__info">{{ 'contract-steps-bar.fl' | translate }} {{ serviceParams['fl-number'] }}</p>
          <p class="contract-product-description__info">{{ functionalLocation.nameEnUs }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="stepNo === 2 || isConfigSummary || (stepNo === 1 && functionalLocationCount > 0)">
      <div class="contract-product" *ngIf="functionalLocation && cart">
        <div class="image__thumbnail">
          <img alt="{{ functionalLocation.nameEnUs }}" [src]="functionalLocation.externalUrlSmall"/>
        </div>
        <div class="contract-product-description">
          <p class="contract-product-description__info font-weight-bold">{{ cart.id }}</p>
          <p class="contract-product-description__info nickname" (click)="showAddNicknameModal = true">
            {{ hasNickname ? nickname : 'contract-steps-bar.add-nickname' | translate }}</p>
          <p class="contract-product-description__info">{{ 'contract-steps-bar.fl' | translate }} {{ serviceParams['fl-number'] }}</p>
          <p class="contract-product-description__info">{{ functionalLocation.nameEnUs }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isQuoteSummary || soldTo">
      <div class="contract-product" *ngIf="itemsInCart && cart">
        <div class="image__thumbnail">
          <img alt="{{ cartItemAlt }}" [src]="cartItemImage"/>
        </div>
        <div class="contract-product-description">
          <p class="contract-product-description__info font-weight-bold">{{ cart.id }}</p>
          <p class="contract-product-description__info nickname" (click)="showAddNicknameModal = true">
            {{ hasNickname ? nickname : 'contract-steps-bar.add-nickname' | translate }}</p>
          <p class="contract-product-description__info">{{ 'mini-cart.equType' | translate:{count: equipmentTypesCount} }}</p>
          <p class="contract-product-description__info">{{ 'mini-cart.fls' | translate:{count: functionalLocationCount} }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="steps-bar" *ngIf="stepNo">
    <div
        *ngFor="let step of steps"
        class="steps-bar__item" [attr.data-test]="'stepBarItem'"
        [ngClass]="{'current': step.index === stepNo, 'completed': step.index < stepNo}">
      <div class="steps-bar__number" [attr.data-test]="'desc-stepBarNumber'"><span>{{ step.index }}</span></div>
      <div class="steps-bar__title" [attr.data-test]="'desc-stepBarTitle'">{{ step.title | translate }}</div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="contract-product">
    <div class="image__thumbnail stripe">
    </div>
    <div class="contract-product-description__stripe">
      <p class="stripe"></p>
      <p class="stripe"></p>
      <p class="stripe"></p>
      <p class="stripe"></p>
    </div>
  </div>
</ng-template>

<app-modal-add-cart-name
    *ngIf="showAddNicknameModal"
    [cartId]="cart.id"
    [hasNickname]="hasNickname"
    [newNameChange]="currentCartName$"
    [showAddNicknameModal]="showAddNicknameModal"
    (closeModal)="showAddNicknameModal = false">
</app-modal-add-cart-name>
