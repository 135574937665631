import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AppUtils } from '../../utils/app.utils';
import { EFeatureToggles, EStoreTypes } from '../../configurations/common';
import { Subject } from 'rxjs';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-modal-sap-p40',
  templateUrl: './modal-sap-p40.component.html',
  styleUrls: ['./modal-sap-p40.component.scss'],
})
export class ModalSapP40Component implements OnInit, OnDestroy {

  isSapP40Enable: boolean = false;
  isUsStore: boolean = false;
  isCaStore: boolean = false;
  isSapP40Modal: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SAP_P40).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSapP40Enable = value;
        if ((this.isUsStore || this.isCaStore) && !this.isSapP40Enable) {
          this.isSapP40Modal = true;
        }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('document:click', ['$event'])
  hideNotification(event): void {
    if(event.target.classList.contains('sap-message--content__close')) {
      this.isSapP40Modal = false;
    }
  }
}
