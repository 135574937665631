import { IPrice } from '../models/common.models';
import { TaxUtils } from './tax.utils';
import { AppUtils } from './app.utils';

export class PriceUtils {

  /**
   * Retrieve default price from product prices array.
   *
   * If no such price exists, return undefined.
   *
   * @param {IPrice[]} abstractProductPrices
   * @returns {IPrice | undefined}
   */
  static getDefaultPrice(abstractProductPrices: IPrice[]): IPrice | undefined {
    return abstractProductPrices.find((price: IPrice) => price.priceTypeName === 'DEFAULT');
  }

  /**
   * Determine if guest price exists in the product prices array.
   *
   * @param {IPrice[]} abstractProductPrices
   * @returns {boolean}
   */
  static hasGuestPrice(abstractProductPrices: IPrice[]): boolean {
    return abstractProductPrices.find((price: IPrice) => price.priceTypeName === 'GUEST') !== undefined;
  }

  /**
   * Retrieve guest price from product prices array.
   *
   * If guest price doesn't exist, return undefined.
   *
   * @param {IPrice[]} abstractProductPrices
   * @returns {IPrice | undefined}
   */
  static getGuestPrice(abstractProductPrices: IPrice[]): IPrice | undefined {
    return abstractProductPrices.find((price: IPrice) => price.priceTypeName === 'GUEST');
  }

  /**
   * Retrieve correct price value from the given product price object based on the currently
   * active pricing strategy (net mode or gross mode).
   *
   * Price mode is determined based on the "exclude tax" feature setting of the current store.
   *
   * If the passed price object is empty, return undefined.
   *
   * @param {IPrice} price
   * @returns {number | undefined}
   */
  static getPriceValue(price: IPrice = undefined): number | undefined {
    if (!price) {
      return undefined;
    }
    const taxExcluded = TaxUtils.isExcludeTaxActive();
    if (taxExcluded) {
      return price.netAmount;
    } else {
      return price.grossAmount;
    }
  }

  /**
   * Gets the currency code for the currently active store.
   *
   * @returns {string}
   */
  static getStoreCurrencyCode(): string {
    return AppUtils.getCurrentStore().storeDefaultCurrency
  }

  /**
   * Gets the currency code for the provided price.
   *
   * If the price is not provided or a valid {@see IPrice} object, return undefined.
   *
   * @param {IPrice} price
   * @returns {string | undefined}
   */
  static getCurrencyCode(price: IPrice = undefined): string | undefined {
    return price?.currency.code ?? undefined;
  }
}
