import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconUtils } from '../../../utils/icon.utils';
import { AppUtils } from '../../../utils/app.utils';
import { environment } from '../../../../environments/environment';
import { EGlueResource } from '../../../configurations/common';
import { IStore } from '../../../models/settings.model';

@Component({
  selector: 'app-previous-orders-list',
  templateUrl: './previous-orders-list.component.html',
  styleUrls: ['./previous-orders-list.component.scss'],
})
export class PreviousOrdersListComponent {
  tabsOpened: string[] = [] as Array<string>;
  @Input() loading: boolean;
  @Input() orders: any;
  @Input() roleAccess: boolean;
  @Input() searchValue: string;
  @Output() refreshOrdersEmitter: EventEmitter<any> = new EventEmitter<any>();
  orderTypeCarts: EGlueResource = EGlueResource.CARTS;

  toggleTab(id: string): void {
    const exists: number = this.tabsOpened.findIndex(tab => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getIcon(status: string): string {
    return IconUtils.getStatusIcon(status);
  }

  getKey(status: string): string {
    return status.replace(' ', '_');
  }

  isExcludeTaxActive(): boolean {
    const currentStore: IStore = AppUtils.getCurrentStore();
    return environment.features.excludeTax.includes(currentStore.storeId);
  }
}
