import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IInstalledBaseSystemData } from '../../../models/installedbase.models';
import { AdvancedFilter } from '../../../models/advanced-filter';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { combineLatest, Subject } from 'rxjs';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ActivatedRoute } from '@angular/router';
import { CpqFacade } from '../../../facades/cpq.facade';
import { map, take, takeUntil } from 'rxjs/operators';
import { SearchFieldPipe } from '../../../shared/pipes/search-field.pipe';
import { EFeatureToggles, EUserRoles } from '../../../configurations/common';

@Component({
  selector: 'app-my-equipment',
  templateUrl: './my-equipment.component.html',
  styleUrls: ['./my-equipment.component.scss'],
  providers: [SearchFieldPipe],
})
export class MyEquipmentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCartOperationInProgress: boolean;
  @Input() loadingCartDataInProgress: boolean;
  @Input() installedBaseSystemData: IInstalledBaseSystemData[];
  @Input() installBaseLoading: boolean = true;
  @Input() productSku: string;
  @Input() soldTo: number;
  @Input() isReorderPending: boolean = false;
  @Input() companyRoles: EUserRoles[];
  @Input() isCaStore: boolean;

  searchValue: string;
  searchLoading: boolean = false;
  isInstallBaseAddressAnonymizeEnabled = false;
  agreements: IInstalledBaseSystemData[];
  agreementsSelectableEquipment: IInstalledBaseSystemData[] = [];
  agreementsOtherEquipment: IInstalledBaseSystemData[] = [];
  contractFilters: AdvancedFilter[] = [
    {
      name: 'modality',
      attribute: 'attributes',
      firstAdditionalAttribute: 'category',
      options: [],
      selected: [],
    },
    {
      name: 'equipment-name',
      attribute: 'attributes',
      firstAdditionalAttribute: 'nameEnUs',
      options: [],
      selected: [],
    },
    {
      name: 'city-and-state',
      attribute: 'attributes',
      firstAdditionalAttribute: 'siemensEquipmentAddress',
      secondAdditionalAttribute: 'city',
      options: [],
      selected: [],
    },
    {
      name: 'contract-name',
      attribute: 'attributes',
      firstAdditionalAttribute: 'contractName',
      options: [],
      selected: [],
    },
  ];

  currentCartId: string | null;
  cartItems = [];
  selectedSystem: any;
  active: boolean = false;
  advancedSearchDropdownIsOpened: boolean = false;
  allOptionsAreSelected: any;
  selectedOptions: any;
  filterSearchValue: any;

  @ViewChildren(DropdownComponent) dropdowns: QueryList<DropdownComponent>;

  private unsubscribe$ = new Subject<void>();
  private initWidth: number = 0;

  constructor(
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private route: ActivatedRoute,
    private cpqFacade: CpqFacade,
    private searchFieldPipe: SearchFieldPipe,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.productSku = params.selectedService;
        this.soldTo = +params.soldTo;
      });

    if (this.productSku) {
      this.selectSelectedSystem();
    }

    this.selectCartsData();
    this.initializeAllOptionsSelected();
    this.initializeSelectedOptions();
    this.initializeSearchValue();
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.INSTALL_BASE_ADDRESS_ANONYMIZE).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isInstallBaseAddressAnonymizeEnabled = value;
    });
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }
  }

  ngOnChanges() {
    if (!this.installBaseLoading && !this.agreements) {
      this.agreements = this.installedBaseSystemData;
      this.initializeAgreements();
      this.contractFilters.forEach(cf => {
        cf.options = [...new Set(this.agreements.map(agreement => {
          if (cf.secondAdditionalAttribute) {
            return agreement[cf.attribute][cf.firstAdditionalAttribute][cf.secondAdditionalAttribute];
          } else if (cf.firstAdditionalAttribute) {
            return agreement[cf.attribute][cf.firstAdditionalAttribute];
          } else {
            return agreement[cf.attribute];
          }
        }))].filter(item => item !== undefined);
      });
    }
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectSelectedSystem(): void {
    this.cpqFacade.selectSelectedSystem().pipe(take(1)).subscribe(selectedSystem => {
      this.selectedSystem = selectedSystem;
    });
  }

  applySelectedFiltersOptions(): void {
    this.contractFilters = this.contractFilters.map(filter => {
      filter.selected = [...this.selectedOptions[filter.name]];
      return filter;
    });
    this.openOrCloseDropdown();
  }

  clearSelectedFiltersOptions(): void {
    this.contractFilters.forEach(filter => {
      this.selectedOptions[filter.name] = [...filter.selected];
      this.allOptionsAreSelected[filter.name] = this.allOptionsAreSelectedForFilter(filter.name);
    });
    this.openOrCloseDropdown();
  }

  setSelectedOptionsForFilter(selectedOptions: string[], filterName: string): void {
    this.contractFilters = this.contractFilters.map(filter => {
      if (filter.name === filterName) {
        filter.selected = selectedOptions;
      }
      return filter;
    });
  }

  selectOption(filterName: string, option: string): void {
    const index = this.selectedOptions[filterName].indexOf(option);
    if (index > -1) {
      this.selectedOptions[filterName].splice(index, 1);
    } else {
      this.selectedOptions[filterName].push(option);
    }
    this.allOptionsAreSelected[filterName] = this.allOptionsAreSelectedForFilter(filterName);
  }

  selectAllFilteredOptions(filterName: string): void {
    if (!this.allOptionsAreSelected[filterName]) {
      this.allOptionsAreSelected[filterName] = true;
      const contractFilterOptions = this.contractFilters.find(filter => filter.name === filterName).options;
      let filteredOptions: string[] = this.searchFieldPipe.transform(contractFilterOptions, this.filterSearchValue[filterName]);
      filteredOptions = filteredOptions.filter(option => !this.selectedOptions[filterName].includes(option));
      this.selectedOptions[filterName] = [...this.selectedOptions[filterName], ...filteredOptions];
    } else {
      this.allOptionsAreSelected[filterName] = false;
      this.selectedOptions[filterName] = [];
    }
  }

  private allOptionsAreSelectedForFilter(filterName: string): boolean {
    const maxNumOfOptions = this.contractFilters.find(filter => filter.name === filterName).options.length;
    const actualNumOfSelectedOptions = this.selectedOptions[filterName].length;
    return actualNumOfSelectedOptions === maxNumOfOptions;
  }

  private openOrCloseDropdown(): void {
    if (this.advancedSearchDropdownIsOpened) {
      this.initializeSearchValue();
    }
    this.advancedSearchDropdownIsOpened = !this.advancedSearchDropdownIsOpened;
  }

  private initializeSearchValue(): void {
    this.filterSearchValue = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: ''}), ({}));
  }

  private initializeAllOptionsSelected(): void {
    this.allOptionsAreSelected = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: false}), ({}));
  }

  private initializeSelectedOptions(): void {
    this.selectedOptions = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: []}), ({}));
  }

  initializeAgreements(): void {
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }

    this.replaceCityWithCityAndState();
    this.setAgreementsWithUpdatedContractNames();
  }

  private anonymizeAddresses(): void {
    this.agreementsSelectableEquipment = this.agreementsSelectableEquipment.map(equipment =>
      MyEquipmentComponent.anonymizeEquipmentAddress(equipment));
    this.agreementsOtherEquipment = this.agreementsOtherEquipment.map(equipment =>
      MyEquipmentComponent.anonymizeEquipmentAddress(equipment));
  }

  private static anonymizeEquipmentAddress(equipment: IInstalledBaseSystemData): IInstalledBaseSystemData {
    return {
      ...equipment,
      attributes: {
        ...equipment.attributes,
        siemensEquipmentAddress: {
          ...equipment.attributes.siemensEquipmentAddress,
          street: '100 MAIN STREET',
          city: 'GERMANTOWN, MD',
        },
      },
    };
  }

  private replaceCityWithCityAndState(): void {
    this.agreements = this.agreements.map(agreement => {
      return {
        ...agreement,
        attributes: {
          ...agreement.attributes,
          siemensEquipmentAddress: {
            ...agreement.attributes.siemensEquipmentAddress,
            city: agreement.attributes.siemensEquipmentAddress.city + ', ' +
              agreement.attributes.siemensEquipmentAddress.state,
          },
        },
      };
    });
  }

  private setAgreementsWithUpdatedContractNames() {
    this.agreementsSelectableEquipment = [];
    this.agreementsOtherEquipment = [];
    this.agreements.forEach(item => {
      if (item.attributes.isSparePartsAvailable) {
        this.agreementsSelectableEquipment.push(item);
      } else {
        this.agreementsOtherEquipment.push(item);
      }
    });
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  unselectOptionForFilter(optionToUnselect: string, filterName: string): void {
    const selectedDropdown = this.dropdowns?.find(dropdown => dropdown.dropDownName === filterName);
    selectedDropdown.selectAndApply(optionToUnselect);
    this.active = false;
  }

  onResize(event): void {
    if (this.active && this.initWidth !== event.target.innerWidth) {
      this.initWidth = event.target.innerWidth;
      this.active = false;
    }
  }

  private selectCartsData(): void {
    combineLatest([
      this.marketingFacade.selectCartId(),
      this.marketingFacade.selectCartItemsWithDetails(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([currentCartId, cartItems]) => ({ currentCartId, cartItems}),
      )).subscribe( data => {
        if (data) {
          this.currentCartId = data.currentCartId;
          this.cartItems = data.cartItems;
        }
      },
    );
  }
}
