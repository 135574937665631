import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import * as CpqActions from '../actions/cpq.actions';
import { IChange } from '../models/cpq.models';

export interface CpqState {
  selectedInstalledBase: any;
  selectedSystem: any;
  error: any;
  changeLog: Array<IChange>;
  showChangeLogTab: boolean;
  undoLastOptionCommit: boolean;
  lastSubmittedOrder: any;
}

export const initialState: CpqState = {
  selectedInstalledBase: null,
  selectedSystem: null,
  error: null,
  changeLog: [],
  showChangeLogTab: false,
  undoLastOptionCommit: false,
  lastSubmittedOrder: undefined
};

const getCpqFeatureState = createFeatureSelector<CpqState>('cpq');

export const getSelectedInstalledBase = createSelector(
  getCpqFeatureState,
  state => state.selectedInstalledBase
);

export const getSelectedSystem = createSelector(
  getCpqFeatureState,
  state => state.selectedSystem
);

export const selectChangeLog = createSelector(
  getCpqFeatureState,
  state => state.changeLog
);

export const selectShowChangeLogTab = createSelector(
  getCpqFeatureState,
  state => state.showChangeLogTab
);

export const selectUndoLastOptionCommit = createSelector(
  getCpqFeatureState,
  state => state.undoLastOptionCommit
);

export const selectLastSubmittedOrder = createSelector(
  getCpqFeatureState,
  state => state.lastSubmittedOrder
);

export const CpqReducer = createReducer(
  initialState,
  on(CpqActions.setSelectedInstalledBase, (state, {selectedInstalledBase}) => {
    return {
      ...state,
      selectedInstalledBase
    };
  }),
  on(CpqActions.setSelectedSystem, (state, {selectedSystem}) => {
    return {
      ...state,
      selectedSystem: selectedSystem?.attributes ?? undefined
    };
  }),
  on(CpqActions.loadDataFailed, (state, {error}) => {
    return {
      ...state,
      error
    };
  }),
  on(CpqActions.updateChangeLog, (state, {changeLog}) => {
    return {
      ...state,
      changeLog
    };
  }),
  on(CpqActions.showChangeLogTab, (state) => {
    return {
      ...state,
      showChangeLogTab: !state.showChangeLogTab,
    };
  }),
  on(CpqActions.undoLastOptionCommit, (state) => {
    return {
      ...state,
      undoLastOptionCommit: !state.undoLastOptionCommit,
    };
  }),
  on(CpqActions.quoteSummaryPageActionAfterSubmittedOrder, (state, action) => {
    return {
      ...state,
      lastSubmittedOrder: action.order,
    };
  }),
  on(CpqActions.thankYouPageClearLastSubmittedOrder, (state) => {
    return {
      ...state,
      lastSubmittedOrder: undefined,
    };
  }),
);
