import {createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as OrderApproveActions from '../actions/order-approve.actions';

export interface OrderApproveState {
    cartId: string;
    cartItems: any;
    cartItemsLoadFail: boolean;
    itemId: string;
    selectedAddress: any;
    postCheckoutData: any;
    postCheckoutDataResponse: any;
    latestDeletedItemFromCart: any;
    voucherCode: string;
    latestAddedVoucher: string;
    latestRemovedVoucher: string;
    error: any;
    checkoutUpdateData: any;
    addVoucherToCartData: {
        data: {
            type: string,
            attributes: {
                code: string,
            }
        }
    };
    postCheckoutItems: any;
    orderDetails: any;
    userCartIdDuringCheckout: string;
    taxNumber: string;
}

export const initialState: OrderApproveState = {
    cartId: '',
    cartItems: undefined,
    cartItemsLoadFail: false,
    error: undefined,
    postCheckoutData: undefined,
    postCheckoutDataResponse: undefined,
    itemId: '',
    selectedAddress: undefined,
    latestAddedVoucher: '',
    latestDeletedItemFromCart: undefined,
    latestRemovedVoucher: '',
    voucherCode: '',
    checkoutUpdateData: undefined,
    addVoucherToCartData: {
        data: {
            type: 'vouchers',
            attributes: {
                code: ''
            }
        }
    },
    postCheckoutItems: null,
    orderDetails: null,
    userCartIdDuringCheckout: '',
    taxNumber: undefined,
};

const getOrderApproveFeatureState = createFeatureSelector<OrderApproveState>('orderApprove');

export const OrderApproveReducer = createReducer(
    initialState,
    on(OrderApproveActions.loadCartItems, (state, action): OrderApproveState => {
        return {
            ...state,
            cartItemsLoadFail: false,
        };
    }),
    on(OrderApproveActions.loadCartItemsSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            cartItems: action.cartItems,
            cartItemsLoadFail: false,
            error: null,
        };
    }),
    on(OrderApproveActions.loadCartItemsFail, (state, action): OrderApproveState => {
        return {
            ...state,
            cartItems: null,
            cartItemsLoadFail: true,
            error: 'Checkout - Error: ' + action.message,
        };
    }),
    on(OrderApproveActions.loadCartItemsReset, (state): OrderApproveState => {
        return {
            ...state,
            cartItemsLoadFail: false,
            error: null,
        };
    }),
    on(OrderApproveActions.getCheckoutUpdateDataSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            checkoutUpdateData: action.checkoutUpdateData,
            error: null,
        };
    }),
    on(OrderApproveActions.postCheckoutData, (state, action): OrderApproveState => {
        return {
            ...state,
            postCheckoutData: action.postCheckoutData,
            error: null,
        };
    }),
    on(OrderApproveActions.setPostCheckoutItems, (state, action): OrderApproveState => {
        return {
            ...state,
            postCheckoutItems: action.items,
        };
    }),
    on(OrderApproveActions.setUserCartIdDuringCheckout, (state, action): OrderApproveState => {
        return {
            ...state,
            userCartIdDuringCheckout: action.cartId,
        };
    }),
    on(OrderApproveActions.postCheckoutDataSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            postCheckoutDataResponse: action.postCheckoutDataResponse,
            error: null,
        };
    }),
    on(OrderApproveActions.deleteItemFromCart, (state): OrderApproveState => {
        return {
            ...state
        };
    }),
    on(OrderApproveActions.deleteItemFromCartSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            latestDeletedItemFromCart: action.latestDeletedItemFromCart,
            itemId: null,
            error: null,
        };
    }),
    on(OrderApproveActions.deleteItemFromCartFail, (state, action): OrderApproveState => {
        return {
            ...state,
            error: 'State - Error: ' + action.error,
        };
    }),
    on(OrderApproveActions.removeVoucherFromCart, (state): OrderApproveState => {
        return {
            ...state
        };
    }),
    on(OrderApproveActions.removeVoucherFromCartSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            latestRemovedVoucher: action.latestRemovedVoucher,
            voucherCode: null,
            error: null,
            latestAddedVoucher: null
        };
    }),
    on(OrderApproveActions.deleteItemFromCartFail, (state, action): OrderApproveState => {
        return {
            ...state,
            error: 'State - Error: ' + action.error,
        };
    }),
    on(OrderApproveActions.setAddVoucherToCartData, (state, action): OrderApproveState => {
        return {
            ...state,
            addVoucherToCartData: {
                data: {
                    type: 'vouchers',
                    attributes: {
                        code: action.voucherCode,
                    }
                },
            }
        };
    }),
    on(OrderApproveActions.PutCartId, (state, action): OrderApproveState => {
        return {
            ...state,
            cartId: action.cartId
        };
    }),
    on(OrderApproveActions.addVoucherToCart, (state, action): OrderApproveState => {
        return {
            ...state,
            voucherCode: action.voucherCode,
            addVoucherToCartData: {
                data: {
                    type: 'vouchers',
                    attributes: {
                        code: action.voucherCode,
                    }
                },
            }
        };
    }),
    on(OrderApproveActions.addVoucherToCartSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            latestAddedVoucher: action.latestAddedVoucher
        };
    }),
    on(OrderApproveActions.addVoucherToCart, (state, action): OrderApproveState => {
        return {
            ...state,
            voucherCode: action.voucherCode
        };
    }),
    on(OrderApproveActions.setShippingAddress, (state, action): OrderApproveState => {
        return {
            ...state,
            selectedAddress: action.address
        };
    }),
    on(OrderApproveActions.getOrderDetailsSuccess, (state, action): OrderApproveState => {
        return {
            ...state,
            orderDetails: action.orderDetails,
            error: null
        };
    }),
    on(OrderApproveActions.successSummaryPageClearData, (state, action): OrderApproveState => {
        return {
            ...initialState,
        };
    }),
);

export const getShippingAddress = createSelector(
    getOrderApproveFeatureState,
    state => state.selectedAddress
);
export const getCartId = createSelector(
    getOrderApproveFeatureState,
    state => state.cartId
);
export const getCartItems = createSelector(
    getOrderApproveFeatureState,
    state => state.cartItems
);
export const getCartItemsLoadFail = createSelector(
    getOrderApproveFeatureState,
    state => state.cartItemsLoadFail
);

export const getCheckoutUpdateData = createSelector(
    getOrderApproveFeatureState,
    state => state.checkoutUpdateData
);

export const getError = createSelector(
    getOrderApproveFeatureState,
    state => state.error
);

export const getVoucher = createSelector(
    getOrderApproveFeatureState,
    state => state.latestAddedVoucher
);

export const removedVoucher = createSelector(
    getOrderApproveFeatureState,
    state => state.latestRemovedVoucher
);

export const getResponseCheckoutData = createSelector(
    getOrderApproveFeatureState,
    state => state.postCheckoutDataResponse
);

export const getOrderSuccessPageData = createSelector(
    getOrderApproveFeatureState,
    state => {
        return {
            data: state.postCheckoutDataResponse,
            items: state.postCheckoutItems,
            cartId: state.userCartIdDuringCheckout,
            taxNumber: state.taxNumber
        };
    }
);

export const getOrderDetails = createSelector(
    getOrderApproveFeatureState,
    state => state.orderDetails
);

export const getUserCartIdDuringCheckout = createSelector(
    getOrderApproveFeatureState,
    state => state.userCartIdDuringCheckout
);
