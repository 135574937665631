<div class="modal-add-cart-name" *ngIf="showAddNicknameModal">
  <app-modal
      [open]="showAddNicknameModal"
      [id]="'changeQuoteName'"
      (crossClick)="emitCloseModalEvent()"
      (outsideClick)="emitCloseModalEvent()"
      title="{{ (hasNickname ? 'mini-cart.edit-nickname-label' : 'contract-steps-bar.quote-change-nickname') | translate }}">
    <ng-container ngProjectAs="[slot][body]">
      <div class="nickname-modal__content">
        <p class="modal-text">
          {{ (isContractInCart ? 'contract-steps-bar.quote-change-nickname-text' : 'contract-steps-bar.cart-change-nickname-text') | translate }}
        </p>
        <app-textfield
            class="nickname__input"
            [form]="form"
            [modelValue]="form.value.name"
            [inputName]="'name'"
            [readOnly]="savingNickNameInProgress"
            label="{{ (hasNickname ?
            'mini-cart.edit-nickname-label' :
            'contract-steps-bar.quote-change-nickname-label') | translate }}">
        </app-textfield>
        <div class="nickname__alerts">
          <small class="textfield__hint" *ngIf="validateField('name', 'maxlength')"
                 [attr.data-test]="'hint-modalMaxCharacters'">
            {{ 'checkout-delivery-details-modal.max-characters' | translate: {charNumber: maxNameLength} }}
          </small>
          <small class="textfield__hint" *ngIf="validateField('name', 'minlength')"
                 [attr.data-test]="'hint-modalMinCharacters'">
            {{ 'checkout-delivery-details-modal.min-characters' | translate: {charNumber: minNameLength} }}
          </small>
          <small class="textfield__hint" *ngIf="validateField('name', 'valueHasAtLeastOneLetter')"
                 [attr.data-test]="'hint-modalMissingLetter'">
            {{ 'mini-cart.missing-letter' | translate }}
          </small>
        </div>
      </div>
    </ng-container>
    <ng-container ngProjectAs="[slot][footer]">
      <button
          class="button button--standard"
          data-dismiss="modal-dialog"
          (click)="resetNickname()"
          [disabled]="form.value.name.length === 0">
        {{ 'contract-steps-bar.reset' | translate }}
      </button>
      <button
          type="submit"
          class="button button--primary"
          data-dismiss="modal-dialog"
          (click)="saveNewNickname()"
          [disabled]="form.value.name.length === 0 && !hasNickname">
        {{ 'contract-steps-bar.quote-change-nickname-confirm-button' | translate }}
      </button>
    </ng-container>
  </app-modal>
</div>
