import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { I18nService } from '../services';
import { AppUtils } from '../utils/app.utils';
import { LocalStorageUtils } from '../utils/localStorage.utils';
import { StringUtils } from '../utils/string.utils';
import { UrlUtils } from '../utils/url.utils';
import { TranslateHttpLoader } from './translate-http.loader';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService,
    private i18nService: I18nService,
  ) {
    const url = StringUtils.updatePathname(window.location.pathname);
    const defaultLanguage = this.i18nService.getDefaultLanguage();
    let allLanguages = this.i18nService.getShopAllLanguages();
    let language = '';
    let storeId = '';

    if (url.match(I18nService.languageUrlRegex)) {
      const locale = UrlUtils.getLocaleFromPathName(url);
      const store = this.i18nService.getStoreByLocale(locale);

      if (store) {
        const [languageCode] = locale.split('-');
        const storeLanguage = store.languages.find(lang => lang.code === languageCode);

        language = storeLanguage.id || defaultLanguage;
        storeId = store.storeId;
      } else {
        language = defaultLanguage;
      }
    } else {
      const storeIdFromStorage = AppUtils.getStoreIdFromLocalStorage();
      const cachedLanguage = translateCacheService.getCachedLanguage();

      language = !cachedLanguage || cachedLanguage === 'undefined' ? defaultLanguage : cachedLanguage;
      storeId = storeIdFromStorage;
    }

    allLanguages = allLanguages.length === 0 ? [language] : allLanguages;
    translate.addLangs(allLanguages);

    LocalStorageUtils.setKeyValue('storeId', storeId);
    LocalStorageUtils.setKeyValue('lang', language);

    translateCacheService.init();
  }
}

export function translateLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

export function translateCacheFactory(
  translateService: TranslateService,
  translateCacheSettings: TranslateCacheSettings
): TranslateCacheService {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
