<div *ngIf="message" class="message-container">
  <span class="message-container__label" [ngClass]="getMessageColor(EMessageType.WARNING)"></span>
  <div class="message__content">
    <div class="title-container">
      <span class="title">
        {{ message.title | translate }}
      </span>
      <span class="icon" [ngClass]="getSpecificIcon(message.type)"></span>
    </div>
    <span class="description">
      {{ message.description | translate: {descriptionData: message.descriptionData} }}
    </span>
  </div>
</div>
