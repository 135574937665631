import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as ShopCartActions from '../../../../actions/shop-cart.actions';
import { AnalyticsService } from '../../../../analytics/analytics.service';
import * as ShopCartSelectors from '../../../../reducers/shop-cart.reducer';
import { State } from '../../../../reducers';
import { MathUtils } from '../../../../utils/math.utils';
import { environment } from '../../../../../environments/environment';
import { ICartUpdateItemData } from '../../../../models/cart.models';
import { ImageUtils } from '../../../../utils/image.utils';

@Component({
  selector: 'app-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
})
export class CartItemsComponent {
  updateItemInCartData$: Observable<ICartUpdateItemData>;
  isQuantityValid = true;
  defaultImg = environment.defaultImg;
  @Input() rfqActive = false;
  @Input() displayPrices: boolean;
  @Input() cartId: string;
  @Input() items: any;
  @Input() updateItemInCartData: any;
  @Input() currentCart: any;
  @Input() currency: string;
  @Input() wishlists: Array<any>;
  @Input() addNewModalActive: boolean;
  @Output() passQuantityToParent = new EventEmitter<boolean>();
  @Output() addNewWishlistEvent = new EventEmitter<boolean>();

  constructor(
    private store: Store<State>,
    private analyticsService: AnalyticsService,
  ) {
  }

  removeFromCart(item: any): void {
    this.trackRemoveProduct(item);
    this.isQuantityValid = true;
    this.passQuantity(this.isQuantityValid);
    this.store.dispatch(ShopCartActions.deleteItemFromCart({cartId: this.cartId, itemId: item.id}));
  }

  updateCartItemLoadCartItems(oldQuantity: string, newQuantity: string, item: any): void {
    const updatedQty = parseInt(newQuantity, 10);
    if (!MathUtils.checkIfNumeric(updatedQty)) {
      this.isQuantityValid = false;
      this.passQuantity(this.isQuantityValid);
      return;
    }

    const quantityDiff = +newQuantity - +oldQuantity;
    if (quantityDiff > 0) {
      this.trackIncreaseQuantity(item, quantityDiff);
    } else if (quantityDiff < 0) {
      this.trackDecreaseQuantity(item, -quantityDiff);
    }

    this.isQuantityValid = true;
    this.passQuantity(this.isQuantityValid);
    this.store.dispatch(ShopCartActions.setUpdateItemInCartData({quantity: +newQuantity}));
    this.updateItemInCartData$ = this.store.select(ShopCartSelectors.getUpdateItemInCartData);
    this.updateItemInCartData$.subscribe(itemData => this.updateItemInCartData = itemData);
    this.store.dispatch(ShopCartActions
      .updateItemInCartLoadCartItems({
          cartId: this.cartId,
          itemId: item.id,
          updateItemInCartData: this.updateItemInCartData,
        },
      ),
    );
  }

  getItemPicture(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  passQuantity(quantity: boolean): void {
    this.passQuantityToParent.emit(quantity);
  }

  trackRemoveProduct(item: any): void {
    this.analyticsService.setProducts(item.attributes);
    this.analyticsService.trackCart('cart.remove');
  }

  addNewWishlist(): void {
    this.addNewWishlistEvent.emit();
  }

  private trackIncreaseQuantity(item: any, quantityDiff: number): void {
    this.analyticsService.setProducts({
      ...item.attributes,
      quantity: quantityDiff
    });

    this.analyticsService.trackCart('cart.add');
  }

  private trackDecreaseQuantity(item: any, quantityDiff: number): void {
    this.analyticsService.setProducts({
      ...item.attributes,
      quantity: quantityDiff
    });

    this.analyticsService.trackCart('cart.remove');
  }
}
