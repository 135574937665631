<div class="card">
  <div class="card-content">
    <div class="card__image">
      <div class="stripe aspect-ratio-16x9 aspect-ratio--cover"></div>
    </div>
    <div class="card__body">
      <h4 class="card__title stripe">
      </h4>
      <p class="card__price stripe stripe-long"></p>
      <div class='stripe stripe-half'></div>
    </div>
  </div>
</div>
