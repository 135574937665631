import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationFacade } from '../../../facades/authorization.facade';
import { EAppBarAlertType, REGISTER_URL_KEY } from '../../../configurations/common';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AnalyticsService } from '../../../analytics/analytics.service';

@Component({
  selector: 'app-bar-alert',
  templateUrl: './app-bar-alert.component.html',
  styleUrls: ['./app-bar-alert.component.scss']
})
export class AppBarAlertComponent implements OnInit, OnDestroy {

  @Input() alertDialogActive: boolean;
  @Input() message: string;
  @Input() alertType: EAppBarAlertType;
  @Input() isGuest: boolean;
  @Input() userLoggedIn$: Observable<boolean>;
  @Input() isCustomerDataLoaded: boolean = false;
  alertText: string;
  alertClosedByUser: boolean;
  registerUrl: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authorizationFacade: AuthorizationFacade,
    private configFacade: ConfigurationFacade,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.configFacade.getTranslationByKey(this.message)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(translation => this.alertText = translation);

    this.configFacade.getTranslationByKey(REGISTER_URL_KEY)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(translation => {
        this.registerUrl = translation;
      });

    this.alertClosedByUser = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  login(): void {
    this.analyticsService.trackUserLoggedIn();
    this.authorizationFacade.authorization();
  }

  register(): void {
    this.analyticsService.trackUserRegistered();
    window.open(this.registerUrl, '_blank');
  }

  toggleAlertDialog(): void {
    this.alertDialogActive = false;
    this.alertClosedByUser = true;
  }

  isNotLoggedMessage(): boolean {
    return this.alertType === EAppBarAlertType.NOT_LOGGED_USER;
  }

  isAuthenticatedGuest(): boolean {
    return this.alertType === EAppBarAlertType.AUTHENTICATED_GUEST;
  }
}

