import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }
  showFullError = false;

  public static handleError(err): Observable<any> {
    let errorMessage: string;
    if (Array.isArray(err)) {
      return throwError(err);
    } else if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      return throwError(err.status);
    }
    return throwError(errorMessage);
  }

  public static handleErrorPromise(error: any): Promise<any> {
    return (error);
  }

  handleError(error: any): void {
    const message = error.message ? error.message : error.toString();
    const errorToDisplay = `error: ${message}`;
    if (!environment.production && environment.showJsErrors) {
      const alert = this.injector.get(ConfigurationFacade);
      alert.setAlert({
        type: 'error',
        message: this.showFullError ? errorToDisplay : 'Something went wrong in JS! Please check the console.',
      });
      console.error('From Error Handler\n', error);
    } else {
      console.warn('Something went wrong', errorToDisplay);
      console.error(error);
    }
  }

}
