import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { EUserRoles } from '../../configurations/common';
import * as ValidationPatterns from '../../configurations/validations';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { CpqFacade } from '../../facades/cpq.facade';
import { IWrongAddressForCpq } from '../../models/cpq.models';
import { IAddressExtended } from '../../models/customer.models';

@Component({
  selector: 'app-report-wrong-bp-address-modal',
  templateUrl: './report-wrong-bp-address-modal.component.html',
  styleUrls: ['./report-wrong-bp-address-modal.component.scss']
})
export class ReportWrongBpAddressModalComponent implements OnInit, OnChanges {
  @Input() showModal: boolean;
  @Input() userLoggedInRoles: EUserRoles[] = [];
  @Input() billOrPayer = false;
  @Input() functionalLocations: string[];
  @Input() quoteId: string;
  @Input() cpqAddressType = '';
  @Input() resetAddressList: boolean = false;
  @Input() addressValue : any
  @Input() title: string
  @Input() isBusinessPartner: boolean = false;
  @Output() closeModal = new EventEmitter<any>();

  wrongAddressForm: UntypedFormGroup;
  dataToSendForCpq: IWrongAddressForCpq;
  addresses: IAddressExtended[] = [];
  loading: boolean;
  addressesLoading: boolean;
  maxLengthComment = 500;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private checkoutFacade: CheckoutFacade,
    private cpqFacade: CpqFacade,
  ) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.initializeForm();
  }

  initializeForm(): void {
    this.wrongAddressForm = this.formBuilder.group({
      deliveryAddress: '',
      comment: ['', [Validators.required, Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal && changes.showModal.currentValue !== changes.showModal.previousValue) {
      if (this.addressValue) {
        this.wrongAddressForm.patchValue({
          deliveryAddress: this.addressValue
        });
      }
    }
    this.addressesLoading = false;
  }

  isApprover(): boolean {
    return this.userLoggedInRoles.includes(EUserRoles.Approver) || this.userLoggedInRoles.includes(EUserRoles.Admin);
  }

  reportAddress(): void {
    if (this.wrongAddressForm.status === 'VALID') {
      this.dataToSendForCpq = {
        type: 'cpq-address-report-wrong',
        attributes: {
          addressType: this.cpqAddressType,
          functionalLocations: this.functionalLocations,
          address: this.wrongAddressForm.value.deliveryAddress,
          quoteUuid: this.quoteId,
          comment: this.wrongAddressForm.value.comment
        }
      };

      this.cpqFacade?.postCpqWrongAddress({data: this.dataToSendForCpq}).then(
        () => this.postDataActions()
      );

      this.wrongAddressForm.reset();
    }
  }

  showLoader(): void {
    this.loading = true;
  }

  resetForm(): void {
    this.closeModal.emit();
    this.wrongAddressForm.reset();
  }

  formIsValid(): boolean {
    return this.wrongAddressForm.status === 'VALID';
  }

  private postDataActions(): void {
    this.loading = false;
    this.closeModal.emit();
    this.cpqFacade.showAdditionalDeliverableNotification(
      'success',
      'report-wrong-address.reported',
      [{
        key: 'report-wrong-address.reported-desc',
        params: {},
        css: [],
      }],
      []
    );
  }
}
