export enum EProductDetails {
  PRODUCT_ORIGIN = 'product-origin',
  OVERVIEW = 'overview',
  DIMENSIONS = 'dimensions',
  DETAILS = 'details',
  SUITABLE_FOR = 'suitable-for'
}

export const EProductCategoryDetails = {
  '0': 'default',
  '1': 'consumable',
  '2': 'accessory',
  '3': 'option-and-upgrade',
  '4': 'service-part',
  '5': 'system',
  '6': 'license',
  '7': 'contract',
  '8': 'solution',
  '9': 'education-services',
}
