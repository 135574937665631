import { createAction, props } from '@ngrx/store';
import { IBaseWishlist } from '../models/wishlist.models';
import { IError } from '../models/common.models';

const wishlistAPIName = '[Wishlist API]';

export const retrieveWishlists = createAction(
  `${wishlistAPIName} Load user's Wishlists`,
);

export const retrieveWishlistsSuccess = createAction(
  `${wishlistAPIName} Load user's Wishlists Success`,
  props<{wishlists: any}>(),
);

export const retrieveWishlistsFail = createAction(
  `${wishlistAPIName} Load user's wishlists failed`,
  props<{error: string}>(),
);

export const deleteWishlist = createAction(
  `${wishlistAPIName} Remove wishlist`,
  props<{wishlistID: string}>(),
);

export const deleteWishlistSuccess = createAction(
  `${wishlistAPIName} Remove wishlist success`,
  props<{wishlistID: string}>(),
);

export const deleteWishlistFailed = createAction(
  `${wishlistAPIName} Remove wishlist failed`,
  props<{error: string}>(),
);

export const addNewWishlist = createAction(
  `${wishlistAPIName} Add new wishlist`,
  props<{wishlistName: string}>(),
);

export const addNewWishlistSuccess = createAction(
  `${wishlistAPIName} Add new wishlist success`,
  props<{newWishlist: IBaseWishlist}>(),
);

export const addNewWishlistFailed = createAction(
  `${wishlistAPIName} Add new wishlist failed`,
  props<{error: IError}>(),
);

export const addNewProductToWishlist = createAction(
  `${wishlistAPIName} Add new product to wishlist start`,
  props<{wishlistId: string, sku: string}>(),
);

export const addNewProductToWishlistSuccess = createAction(
  `${wishlistAPIName} Add new product to wishlist success`,
  props<{wishlistResponse: any, wishlistID: string}>(),
);

export const addNewProductToWishlistFailed = createAction(
  `${wishlistAPIName} Add new product to wishlist failed`,
  props<{wishlistId: string, sku: string, error: string}>(),
);

export const removeProductFromWishlist = createAction(
  `${wishlistAPIName} Remove product from wishlist start`,
  props<{wishlistId: string, shoppingListItemId: string, sku: string}>(),
);

export const removeProductFromWishlistSuccess = createAction(
  `${wishlistAPIName} Remove product from wishlist success`,
  props<{wishlistId: string, shoppingListItemId: string, sku: string}>(),
);

export const removeProductFromWishlistFailed = createAction(
  `${wishlistAPIName} Remove product from wishlist failed`,
  props<{wishlistId: string, sku: string, error: string}>(),
);

export const updateProductFromWishlist = createAction(
  `${wishlistAPIName} Update product from wishlist start`,
  props<{wishlistId: string, shoppingListItemId: string, sku: string, qty: number}>(),
);

export const updateProductFromWishlistSuccess = createAction(
  `${wishlistAPIName} Update product from wishlist success`,
  props<{wishlistId: string, shoppingListItemId: string, sku: string, qty: number}>(),
);

export const updateProductFromWishlistFailed = createAction(
  `${wishlistAPIName} Update product from wishlist failed`,
  props<{wishlistId: string, sku: string, error: string}>(),
);
