import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CatalogSearch, ICurrentParams } from '../models/catalog.models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UrlUtils } from '../utils/url.utils';
import { GlueConfigurationService } from './glue-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private static handleCatalogError(error: any): Promise<CatalogSearch> {
    console.error('An error occurred in getCatalogSearch()', error);
    return Promise.reject(error.message || error);
  }

  getProductByCategorySpecificPage(newParams: ICurrentParams): Observable<any> {
    const params = Object.assign({
      q: '',
      'page[limit]': 12,
      'page[offset]': 0,
      sort: '',
      include: 'abstract-items,abstract-products,abstract-product-prices,product-labels,concrete-products'
    }, newParams);
    const url = `${this.glueUrl}/catalog-search?${UrlUtils.serialize(params)}`;
    return this.http.get<CatalogSearch>(url).pipe(
      catchError(ProductListService.handleCatalogError),
    );
  }
}
