import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BreadCrumb } from '../models/common.models';
import {
  BREADCRUMB_SCHEME_CLASS,
  BreadCrumbSchema,
  IitemListElement, PRODUCT_SCHEME_CLASS,
  ProductSchema,
} from '../configurations/structured-data';
import { IAbstractProduct, IProductImage } from '../models/abstract-product.models';

@Injectable({
  providedIn: 'root',
})
export class StructuredDataService {
  private scriptType = 'application/ld+json';
  private selector = (className) => `script[type="${this.scriptType}"].${className}`;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  handleBreadcrumbSchema(breadcrumb: BreadCrumb[]): void {
    const listElement: IitemListElement[] = breadcrumb.map((item, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
        item: this.buildUrl(item.url)
      } as IitemListElement;
    });
    const schema = BreadCrumbSchema(listElement);
    this.insertSchema(schema, BREADCRUMB_SCHEME_CLASS);
  }

  handleProductSchema(product: IAbstractProduct, images: string[]): void {
    const schema = ProductSchema(product, images);
    this.insertSchema(schema, PRODUCT_SCHEME_CLASS);
  }

  removeStructuredData(className: string): void {
    const scripts: NodeListOf<HTMLScriptElement> = this.document.head.querySelectorAll(this.selector(className));
    scripts.forEach((s: HTMLScriptElement) => this.document.head.removeChild(s));
  }

  insertSchema(schema: Record<string, any>, className: string = 'structured-data'): void {
    let script: HTMLScriptElement;
    let shouldAppend = false;
    if (this.document.head.querySelector(this.selector(className))) {
      script = this.document.head.querySelector(this.selector(className));
    } else {
      script = this.document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = this.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.document.head.appendChild(script);
    }

  }
  private buildUrl(url: string): string {
    const path = !url.startsWith('/') && url !== '' ? `/${url}` : url;
    return `${window.location.origin}${path}`;
  }
}
