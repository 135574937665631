import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INavigationResponse } from '../models/settings.model';
import { GlueConfigurationService } from './glue-configuration.service';
import { AppUtils } from '../utils/app.utils';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationBarService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(private http: HttpClient,
              private glueConfiguration: GlueConfigurationService,
              private router: Router
  ) { }

  private static handleError(error: any): Promise<INavigationResponse> {
    console.error('An error occurred in getNavigationBar()', error);
    return Promise.reject(error.message || error);
  }

  getNavigationBar(isGuest: boolean): Observable<INavigationResponse> {
    const navigationBarUrl = this.glueUrl +  `/navigations/${isGuest ? 'GUEST_' : ''}MAIN_NAVIGATION`;

    return this.http.get<INavigationResponse>(navigationBarUrl).pipe(
      map((response: INavigationResponse) => {
        return response;
      }),
      catchError(NavigationBarService.handleError),
    )
  }

  getShopCategoryNavigationBar(): Observable<INavigationResponse> {
    const shopCategoryNavBarUrl = this.glueUrl + `/navigations/${AppUtils.getCurrentStore().storeId}_HOMEPAGE_TILES_BAR`

    return this.http.get<INavigationResponse>(shopCategoryNavBarUrl).pipe(
      map(response => {
        return this._resolveUrlQueryParams(response)
      }),
      catchError(NavigationBarService.handleError),
    )
  }

  private _resolveUrlQueryParams(shopCategoryResponse: INavigationResponse): INavigationResponse {
    shopCategoryResponse.data.attributes.nodes.forEach((url) => {
      url.queryParams = this.router.parseUrl(url.url).queryParams;
      url.url = url.url.indexOf('?') !== -1
        ? url.url.substring(0, url.url.indexOf('?'))
        : url.url;
    })
    return shopCategoryResponse;
  }

}
