import { createAction, props } from '@ngrx/store';
import { IChange } from '../models/cpq.models';

export const loadDataFailed = createAction(
  '[CPQ] Load data failed',
  props<{error: any}>(),
);

export const setSelectedInstalledBase = createAction(
  '[CPQ] Add or update selected installed base',
  props<{selectedInstalledBase: any}>(),
);

export const loadSelectedInstalledBase = createAction(
  '[CPQ] Load selected installed base',
  props<{flNumber: string, materialNumber: string}>(),
);

export const setSelectedSystem = createAction(
  '[CPQ] Add or update selected system',
  props<{selectedSystem: any}>(),
);

export const loadSelectedSystem = createAction(
  '[CPQ] Load selected system',
  props<{sku: string}>(),
);

export const updateChangeLog = createAction(
  '[CPQ] Update change log',
  props<{changeLog: Array<IChange>}>(),
);

export const showChangeLogTab = createAction(
  '[CPQ] Show change log tab on service configuration',
);

export const undoLastOptionCommit = createAction(
  '[CPQ] Undo last committed option',
);

export const quoteSummaryPageActionAfterSubmittedOrder = createAction(
  '[Quote Summary Page] Actions after order was submitted',
  props<{order: any}>(),
);

export const thankYouPageStartNewQuote = createAction(
  '[Thank You Page] Start new Quote',
);

export const thankYouPageViewPurchaseActivity = createAction(
  '[Thank You Page] View Purchase Activity',
);

export const thankYouPageClearLastSubmittedOrder = createAction(
  '[Thank You Page] Clear last submitted order',
);

export const thankYouPageRedirectToLastPage = createAction(
  '[Thank You Page] Redirect to last page',
);

export const serviceConfigPageShowFLAddedNotification = createAction(
  '[Service Configuration Page] Show FL added notification',
  props<{cartId: string, quantity: number}>(),
);

export const serviceConfigPageRedirectWhenCartEmpty = createAction(
  '[Service Configuration Page] Redirect when cart is empty',
);
